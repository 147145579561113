import { useAtom } from 'jotai';
import { MainContentHeader, MainContentWrapper } from '../../../components/DataCollectionLayout';
import {
  activeChecklistCategoryState,
  isConfirmChecklistBarActiveState,
  isMoveChecklistModeActiveState,
} from '../../../state/UIState';
import { useCallback, useState } from 'react';
import { EditCreateChecklistItem } from './EditCreateChecklistItem';
import { ChecklistItemPayload, useCreateChecklistItem } from '../../../queries/useChecklistItems';
import { getMaxOrderNum } from '../../../utils/getMaxOrderNum';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { ChecklistItemsTable } from './ChecklistItemsTable/ChecklistItemsTable';

export const ChecklistContent = () => {
  const [isConfirmMoveBarActive] = useAtom(isConfirmChecklistBarActiveState);
  const [isMoveModeActive] = useAtom(isMoveChecklistModeActiveState);
  const [activeCategory] = useAtom(activeChecklistCategoryState);

  const createChecklistItem = useCreateChecklistItem();

  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const handleOpenCreateModal = () => setIsCreateModalOpen(true);
  const handleCloseCreateModal = () => setIsCreateModalOpen(false);

  const onSubmit = useCallback(
    async (payload: Partial<ChecklistItemPayload>) => {
      if (!activeCategory) return;
      const maxOrderNum = getMaxOrderNum(activeCategory?.items);
      await createChecklistItem.mutateAsync(
        { ...payload, category: activeCategory.id, order: maxOrderNum + 1 },
        {
          onSuccess: async () => {
            if (!appConfigData?.data) {
              return;
            }
            await updateAppConfig.mutateAsync({
              data: { ...appConfigData?.data, areCheckListSurveysUpToDate: false },
            });
          },
        }
      );
      handleCloseCreateModal();
    },
    [activeCategory, appConfigData?.data, createChecklistItem, updateAppConfig]
  );

  return (
    <MainContentWrapper isActive={isConfirmMoveBarActive}>
      <MainContentHeader
        title={`${activeCategory?.name ?? ''} checklist`}
        onClickAdd={handleOpenCreateModal}
        isDisabled={isMoveModeActive}
        addCTAText='Add item'
      />

      <ChecklistItemsTable />

      {isCreateModalOpen && (
        <EditCreateChecklistItem
          title='Add item'
          confirmBtnText='Add item'
          onClose={handleCloseCreateModal}
          onSubmit={onSubmit}
        />
      )}
    </MainContentWrapper>
  );
};
