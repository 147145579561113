import { Row, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Table } from '../../../../../../../components/Table/Table';
import { KEY_TASK_GOAL_TYPE, KeyTask, REPORT_STATUS } from '../../../../../../../types';
import { useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Typography } from '../../../../../../../components/Typography/Typography';
import { RequestStatusCell } from '../../../../../../../components/Table/CellRenderers/RequestStatusCell';
import { RequestProgressCell } from '../../../../../../../components/Table/CellRenderers/RequestProgressCell';
import { formatDate } from '../../../../../../../utils/formatters';
import { ReactComponent as EditIcon } from '../../../../../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../assets/icons/delete.svg';

import { EditKeyTask } from './EditKeyTask';
import { DeleteKeyTask } from './DeleteKeyTask';

interface Props {
  keyTasks: KeyTask[];
}

type KeyTaskData = {
  name: string;
  status: REPORT_STATUS;
  progress: number;
  lastUpdated: string;
};

const actions = [
  {
    id: 'EDIT',
    value: 'Edit',
    icon: <EditIcon />,
  },
  {
    id: 'DELETE',
    value: 'Delete',
    icon: <DeleteIcon />,
  },
];

const columnHelper = createColumnHelper<KeyTaskData>();

export const KeyTasksTable = ({ keyTasks }: Props) => {
  const { colors } = useTheme();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [activeKeyTask, setActiveKeyTask] = useState<KeyTask | null>(null);

  const keyTasksTableData: KeyTaskData[] = useMemo(() => {
    const data = keyTasks.map((keyTask) => {
      let keyTaskName = '';

      if (keyTask.goalType === KEY_TASK_GOAL_TYPE.KPI) {
        keyTaskName = keyTask?.kpi?.name || '';
      }

      if (keyTask.goalType === KEY_TASK_GOAL_TYPE.CHECKLIST) {
        keyTaskName = keyTask?.checklistItem?.name || '';
      }

      if (keyTask.goalType === KEY_TASK_GOAL_TYPE.OTHER) {
        keyTaskName = keyTask.conditionValue;
      }

      return {
        ...keyTask,
        name: keyTaskName,
        status: keyTask.status,
        progress: keyTask.progress,
        lastUpdated: keyTask.updated_at,
      };
    });

    return data;
  }, [keyTasks]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Key Task
          </Typography>
        ),
        enableSorting: false,
        meta: {
          width: '671px',
          minWidth: '671px',
          maxWidth: '671px',
          tdStyles: {
            borderRight: `1px solid ${colors.primary[30]}`,
            paddingLeft: '64px',
          },
          thStyles: {
            borderRight: `1px solid ${colors.primary[30]}`,
            paddingLeft: '64px',
          },
        },
      }),
      columnHelper.accessor('status', {
        cell: (info) => <RequestStatusCell status={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Status
          </Typography>
        ),
        enableSorting: false,
        meta: {
          width: '130px',
          minWidth: '130px',
          maxWidth: '130px',
          tdStyles: {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
          thStyles: {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
      }),
      columnHelper.accessor('progress', {
        cell: (info) => <RequestProgressCell progress={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Progress
          </Typography>
        ),
        enableSorting: false,
        meta: {
          width: '176px',
          minWidth: '176px',
          maxWidth: '176px',
        },
      }),
      columnHelper.accessor('lastUpdated', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {formatDate(info.getValue())}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Last Updated
          </Typography>
        ),
        enableSorting: false,
        meta: {
          tdStyles: {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
          thStyles: {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
      }),
    ],
    [colors.primary]
  );

  const handleSelectAction = useCallback((actionId: string, row: Row<KeyTask> | null) => {
    if (actionId === 'EDIT') {
      setOpenEditModal(true);
      setActiveKeyTask(row?.original || null);
      return;
    }
    if (actionId === 'DELETE') {
      setOpenDeleteModal(true);
      setActiveKeyTask(row?.original || null);
    }
  }, []);

  const table = useReactTable({
    data: keyTasksTableData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Table
        table={table}
        height={'auto'}
        maxWidth='100%'
        thStyle={{ backgroundColor: colors.accent[1] }}
        tableStyle={{ tableLayout: 'fixed' }}
        actions={actions}
        onActionClick={handleSelectAction}
        actionsTdStyle={{ textAlign: 'right' }}
      />
      {openEditModal && (
        <EditKeyTask
          activeKeyTask={activeKeyTask}
          isModalOpen={openEditModal}
          setIsModalOpen={setOpenEditModal}
          setActiveKeyTask={setActiveKeyTask}
        />
      )}
      {openDeleteModal && (
        <DeleteKeyTask
          activeKeyTask={activeKeyTask}
          setActiveKeyTask={setActiveKeyTask}
          isModalOpen={openDeleteModal}
          setIsModalOpen={setOpenDeleteModal}
        />
      )}
    </>
  );
};
