import { ReactComponent as DeleteIcon } from '../../../../../../../assets/icons/delete.svg';
import { MouseEvent, useCallback, useState } from 'react';
import { ActionsPopupMenu } from '../../../../../../../components/ActionsPopupMenu/ActionsPopupMenu';
import { useUpdateExclusionCondition } from '../../../../../../../queries/useExclusionCondition';
import { useAppConfig, useUpdateAppConfig } from '../../../../../../../queries/useAppConfig';
import { useAtom } from 'jotai';
import {
  activeConditionIdState,
  activeExclusionCategoryState,
} from '../../../../../../../state/UIState';

const options = [
  {
    id: 'DELETE',
    value: 'Delete Condition',
    icon: <DeleteIcon />,
  },
];

interface Props {
  conditionId: number;
}

export const ActionMenu = ({ conditionId }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const [activeCategory] = useAtom(activeExclusionCategoryState);
  const [activeConditionId, setActiveConditionId] = useAtom(activeConditionIdState);

  const { mutate: updateCondition } = useUpdateExclusionCondition();

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const deleteCondition = useCallback(() => {
    updateCondition(
      { id: conditionId, isDeleted: true, category: activeCategory?.id },
      {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
          });
        },
      }
    );

    if (activeConditionId === conditionId && activeCategory?.conditions.length) {
      setActiveConditionId(activeCategory?.conditions[0].id);
    }
  }, [
    activeCategory?.conditions,
    activeCategory?.id,
    activeConditionId,
    appConfigData?.data,
    conditionId,
    setActiveConditionId,
    updateAppConfig,
    updateCondition,
  ]);

  const onMenuItemClick = useCallback(
    (id: string) => {
      handleCloseMenu();
      if (id === 'DELETE') {
        deleteCondition();
        return;
      }

      throw new Error('Unknown option');
    },
    [deleteCondition]
  );

  return (
    <ActionsPopupMenu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      actions={options}
      onClick={(e) => handleClick(e)}
      onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        handleCloseMenu();
      }}
      onMenuItemClick={onMenuItemClick}
      menuStyles={{
        transform: 'translate(25px, 5px)',
      }}
    />
  );
};
