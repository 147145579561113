import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { styled } from '@mui/material';
import { useAtom } from 'jotai';
import { activeExclusionCategoryState } from '../../../../state/UIState';
import { Condition } from './Condition/Condition';
import { useCallback } from 'react';
import { useUpdateExclusionConditions } from '../../../../queries/useExclusionCondition';
import { useAppConfig, useUpdateAppConfig } from '../../../../queries/useAppConfig';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ConditionsList = () => {
  const [activeCategory, setActiveCategory] = useAtom(activeExclusionCategoryState);

  const { mutate: updateExclusionConditions } = useUpdateExclusionConditions();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const conditions = activeCategory?.conditions;

      if (!active?.id || !over?.id || !conditions) return;

      const oldIndex = conditions?.findIndex((item) => item.id === Number(active.id));
      const newIndex = conditions?.findIndex((item) => item.id === Number(over.id));

      const reorderedItems = arrayMove(conditions, oldIndex, newIndex);

      const payload = {
        data: reorderedItems.map((item, i) => ({
          id: item.id,
          order: i + 1,
          category: activeCategory?.id,
        })),
      };

      updateExclusionConditions(payload, {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
          });
        },
      });

      const updatedCategory = {
        ...activeCategory,
        conditions: reorderedItems,
      };

      setActiveCategory(updatedCategory);
    },
    [
      activeCategory,
      appConfigData?.data,
      setActiveCategory,
      updateAppConfig,
      updateExclusionConditions,
    ]
  );

  return (
    <Wrapper>
      <DndContext sensors={sensors} onDragEnd={handleReorder} collisionDetection={closestCenter}>
        <SortableContext
          items={activeCategory?.conditions ?? []}
          strategy={verticalListSortingStrategy}
        >
          {activeCategory?.conditions.map((condition, i) => (
            <Condition
              key={condition.id}
              condition={condition}
              isDraggable={activeCategory?.conditions.length > 1}
              conditionOrder={i + 1}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};
