import { styled } from '@mui/material';

interface Props {
  progress: number;
  color: string;
  height?: string;
}

const ProgressWrapper = styled('div')<{ height: string }>`
  height: ${({ height }) => height};
  background-color: ${({ theme }) => theme.colors.primary[30]};
  width: 100%;
`;
const ProgressLine = styled('div')<{ progress: number; color: string; height: string }>`
  height: ${({ height }) => height};
  background-color: ${({ color }) => color};
  width: ${({ progress }) => `${progress}%`};
  transition: width 500ms ease-out;
`;

export const ProgressBar = ({ progress, color, height = '4px' }: Props) => {
  return (
    <ProgressWrapper height={height}>
      <ProgressLine progress={progress} color={color} height={height} />
    </ProgressWrapper>
  );
};
