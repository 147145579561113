import { styled, useTheme } from '@mui/material';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { Typography } from '../../../components/Typography/Typography';
import { useAtom } from 'jotai';
import { companyExclusionIdsState, selectedConditionState } from '../../../state/UIState';
import { useCompanyExclusions } from '../../SIngleCompany/hooks/useCompanyExclusions';
import { useMemo } from 'react';

const Container = styled('div')`
  display: flex;
  margin-top: 16px;
  align-items: center;
  gap: 8px;
  margin-left: 16px;
`;

export const CheckAll = () => {
  const { colors } = useTheme();
  const [companyExclusionIds, setCompanyExclusionIds] = useAtom(companyExclusionIdsState);
  const [selectedCondition] = useAtom(selectedConditionState);
  const { data: companyExclusions } = useCompanyExclusions();

  const existingCompanyExclusionIds = useMemo(() => {
    return companyExclusions?.map((exclusion) => exclusion.id) || [];
  }, [companyExclusions]);

  const selectableExclusionIds = useMemo(() => {
    return (
      selectedCondition?.exclusions
        ?.filter((exclusion) => !existingCompanyExclusionIds.includes(exclusion.id))
        ?.map((exclusion) => exclusion.id) || []
    );
  }, [existingCompanyExclusionIds, selectedCondition?.exclusions]);

  const areAllSelected = useMemo(() => {
    return Boolean(
      selectableExclusionIds?.filter((exclusionId) => companyExclusionIds?.includes(exclusionId))
        ?.length
    );
  }, [companyExclusionIds, selectableExclusionIds]);

  const onToggleSelectAll = () => {
    if (areAllSelected) {
      setCompanyExclusionIds((prev) =>
        prev.filter((prevExclusionId) => !selectableExclusionIds?.includes(prevExclusionId))
      );
      return;
    }

    setCompanyExclusionIds((prev) => [...prev, ...selectableExclusionIds]);
  };

  return (
    <Container onClick={onToggleSelectAll}>
      <Checkbox
        checked={areAllSelected}
        style={{ padding: 0 }}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 24,
          },
        }}
      />
      <Typography
        variant='overline'
        color={colors.primary[60]}
        style={{ textTransform: 'uppercase' }}
      >
        Exclusion
      </Typography>
    </Container>
  );
};
