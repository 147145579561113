import { useCompanies } from '../../queries/useCompanies';
import { useEffect, useMemo } from 'react';
import { COMPANY_STAGE, COMPANY_TYPE, USER_ROLE } from '../../types';
import { PortfolioLayout } from '../../components/PortfolioLayout/PortfolioLayout';
import { PortfolioPageSkeletonLoader } from '../../components/SkeletonLoader/PortfolioPage.SkeletonLoader';
import { ComposureCharts } from '../../components/ComposureCharts/ComposureCharts';
import { AssessmentSummaryTable } from '../../components/AssessmentSummaryTable/AssessmentSummaryTable';
import { useAtom, useAtomValue } from 'jotai';
import {
  activeRoleState,
  controlledAssessmentSummaryFiltersState,
  controlledAssessmentSummarySortingState,
} from '../../state/UIState';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../constants/routes';

export const Portfolio = () => {
  const { data: companies, isLoading } = useCompanies();
  const [sorting, setSorting] = useAtom(controlledAssessmentSummarySortingState);
  const [columnFilters, setColumnFilters] = useAtom(controlledAssessmentSummaryFiltersState);
  const activeRole = useAtomValue(activeRoleState);
  const navigate = useNavigate();

  const esgCompleteControlledCompanies = useMemo(() => {
    return (
      companies?.filter(
        (company) =>
          company.type === COMPANY_TYPE.CONTROLLED && company?.stage === COMPANY_STAGE.ESG_COMPLETE
      ) || []
    );
  }, [companies]);

  //redirect from base route depending on user role
  useEffect(() => {
    if (activeRole === USER_ROLE.ANALYST) {
      navigate(`/${ROUTES.ISSUERS}`);
    }
  }, [activeRole, navigate]);

  if (isLoading || !companies) return <PortfolioPageSkeletonLoader />;

  return (
    <PortfolioLayout title='Control Companies'>
      <ComposureCharts companies={esgCompleteControlledCompanies} />
      <AssessmentSummaryTable
        companies={esgCompleteControlledCompanies}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        prevRouteName='Control Companies'
        companiesType={COMPANY_TYPE.CONTROLLED}
      />
    </PortfolioLayout>
  );
};
