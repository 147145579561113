import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Announcements,
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DropAnimation,
  defaultDropAnimation,
  UniqueIdentifier,
  MouseSensor,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

import {
  buildTree,
  flattenTree,
  getProjection,
  getChildCount,
  removeItem,
  removeChildrenOf,
  setProperty,
  insertItem,
} from '../../../../../../utils/treeUtilities';
import type {
  FlattenedItem,
  SensorContext,
  SurveyQuestion,
  SurveySection,
  TreeItems,
} from '../../../../../../types';
import { SortableTreeItem } from './SortableTreeItem';
import { CSS } from '@dnd-kit/utilities';
import { useAtom } from 'jotai';
import {
  activeQuestionIdState,
  activeSectionIdState,
  flattenedSectionsState,
  isApplyingEvaluationRulesModeActiveState,
  isMultiActionMoveModeActiveState,
  isSingleActionMoveModeActiveState,
  sectionsState,
  selectedQuestionsState,
  selectedSectionToMoveQuestionsState,
} from '../../../../../../state/UIState';
import { doNothingFunc } from '../../../../../../utils/doNothingFunc';
import { styled } from '@mui/material';
import { getDefaultSurveySection } from '../../../../../../constants/defaultValues';
import { ConfirmDeleteModal } from '../../../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

interface Props {
  collapsible?: boolean;
  defaultItems?: TreeItems;
  indentationWidth?: number;
  indicator?: boolean;
  removable?: boolean;
}

export function SortableTree({ indicator = true, indentationWidth = 20 }: Props) {
  const [items, setItems] = useAtom(sectionsState);
  const [flattenedItems] = useAtom(flattenedSectionsState);
  const [activeSectionId, setActiveSectionId] = useAtom(activeSectionIdState);
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [isApplyingModeActive] = useAtom(isApplyingEvaluationRulesModeActiveState);
  const [isSingleActionMoveModeActive] = useAtom(isSingleActionMoveModeActiveState);
  const [isMultiActionMoveModeActive] = useAtom(isMultiActionMoveModeActiveState);
  const [selectedQuestions, setSelectedQuestions] = useAtom(selectedQuestionsState);
  const [selectedSection, setSelectedSection] = useAtom(selectedSectionToMoveQuestionsState);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false);
  const [deleteSectionId, setDeleteSectionId] = useState<UniqueIdentifier | null>(null);

  const filteredFlattenedItems = useMemo(() => {
    const collapsedItems = flattenedItems.reduce<string[]>(
      (acc, { subSections, collapsed, id }) =>
        collapsed && subSections.length ? ([...acc, id] as string[]) : acc,
      []
    );

    return removeChildrenOf(
      flattenedItems,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, flattenedItems]);

  const projected =
    activeId && overId
      ? getProjection(filteredFlattenedItems, activeId, overId, offsetLeft, indentationWidth)
      : null;
  const sensorContext: SensorContext = useRef({
    items: filteredFlattenedItems,
    offset: offsetLeft,
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const sortedIds = useMemo(
    () => filteredFlattenedItems.map(({ id }) => id),
    [filteredFlattenedItems]
  );
  const activeItem = activeId ? filteredFlattenedItems.find(({ id }) => id === activeId) : null;

  useEffect(() => {
    sensorContext.current = {
      items: filteredFlattenedItems,
      offset: offsetLeft,
    };
  }, [filteredFlattenedItems, offsetLeft]);

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  const isSelected = useCallback(
    (questions: SurveyQuestion[] = [], sectionId?: string) => {
      if (selectedSection && selectedSection.id === sectionId) return true;
      if (!selectedQuestions || !questions || !questions.length) return false;
      return questions.every((question) =>
        selectedQuestions.map((q) => q.id).includes(question.id)
      );
    },
    [selectedQuestions, selectedSection]
  );

  const isIndeterminate = useCallback(
    (questions: SurveyQuestion[] = []) => {
      if (!selectedQuestions || !questions || !questions.length || isSelected(questions))
        return false;
      return questions.some((question) => selectedQuestions.map((q) => q.id).includes(question.id));
    },
    [isSelected, selectedQuestions]
  );

  const handleRemove = useCallback(
    (id: UniqueIdentifier | null) => {
      if (!id) return;
      setItems((items) => removeItem(items, id));
    },
    [setItems]
  );

  const handleDeleteSection = useCallback(
    (id: UniqueIdentifier, subsections: SurveySection[], questions: SurveyQuestion[]) => {
      if (subsections?.length || questions?.length) {
        setShowDeleteSectionModal(true);
        setDeleteSectionId(id);
        return;
      }
      handleRemove(id);
    },
    [handleRemove]
  );

  const onCloseDeleteSectionModal = useCallback(() => {
    setShowDeleteSectionModal(false);
    setDeleteSectionId(null);
  }, []);

  const onDeleteSection = useCallback(() => {
    handleRemove(deleteSectionId);
    setShowDeleteSectionModal(false);
    setDeleteSectionId(null);
  }, [deleteSectionId, handleRemove]);

  return (
    <Wrapper>
      <ConfirmDeleteModal
        onClose={onCloseDeleteSectionModal}
        onConfirm={onDeleteSection}
        title='Delete the section?'
        note='All contained questions will be lost.'
        confirmLabel='Yes, Delete'
        cancelLabel='No, Cancel'
        isOpen={showDeleteSectionModal}
      />

      <DndContext
        accessibility={{ announcements }}
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
          {filteredFlattenedItems.map((section) => {
            const { id, name, subSections, collapsed, questions, depth } = section;

            return (
              <SortableTreeItem
                isActive={activeSectionId === id}
                key={id}
                id={id}
                name={name}
                value={id as string}
                depth={id === activeId && projected ? projected.depth : depth}
                indentationWidth={indentationWidth}
                indicator={indicator}
                collapsed={Boolean(collapsed && subSections.length)}
                onExpandCollapseSection={subSections.length ? () => handleCollapse(id) : undefined}
                childCount={getChildCount(items, id) + 1}
                onDeleteSection={() => handleDeleteSection(id, subSections, questions)}
                onClick={() => handleClick(section)}
                onSectionRename={(newName) => handleRename(id, newName)}
                onAddSubsection={() =>
                  handleAddSubsection(id, depth + 1, getChildCount(items, id) + 1)
                }
                isSelected={isSelected(questions, id)}
                isIndeterminate={isIndeterminate(questions)}
                disableControls={
                  isApplyingModeActive ||
                  isSingleActionMoveModeActive ||
                  isMultiActionMoveModeActive
                }
                isMultiSelectable={isApplyingModeActive}
                isSingleSelectable={
                  activeSectionId !== id &&
                  (isSingleActionMoveModeActive || isMultiActionMoveModeActive)
                }
                isDraggable={
                  !isApplyingModeActive &&
                  !isSingleActionMoveModeActive &&
                  !isMultiActionMoveModeActive
                }
              />
            );
          })}
          {createPortal(
            <DragOverlay dropAnimation={dropAnimationConfig}>
              {activeId && activeItem ? (
                <SortableTreeItem
                  isActive={false}
                  id={activeId}
                  depth={activeItem.depth}
                  name={activeItem.name}
                  clone
                  childCount={getChildCount(items, activeId) + 1}
                  value={activeId.toString()}
                  indentationWidth={indentationWidth}
                  onDeleteSection={doNothingFunc}
                  onSectionRename={doNothingFunc}
                />
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </SortableContext>
      </DndContext>
    </Wrapper>
  );

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = filteredFlattenedItems.find(({ id }) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const newItems = buildTree(sortedItems);
      setItems(() => {
        if (!parentId) return newItems;
        return setProperty(newItems, parentId, 'collapsed', () => {
          return false;
        });
      });
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  }

  function handleAddSubsection(id: UniqueIdentifier, depth: number, childCount: number) {
    const newItem = {
      ...getDefaultSurveySection(),
      name: `Sub-section ${childCount}`,
      depth: depth,
      parentId: id,
    };
    setItems((items) => {
      const updatedItems = insertItem(items, newItem);
      return setProperty(updatedItems, id, 'collapsed', () => {
        return false;
      });
    });
  }

  function handleClick(section: SurveySection) {
    const questions = section.questions ?? [];
    if (isSingleActionMoveModeActive && activeSectionId !== section.id) {
      setSelectedSection(section);
      return;
    }
    if (isMultiActionMoveModeActive && activeSectionId !== section.id) {
      setSelectedSection(section);
      return;
    }
    if (!isApplyingModeActive && selectedQuestions.length) {
      setSelectedQuestions([]);
    }

    setActiveSectionId(section.id as string);
    if (isApplyingModeActive) {
      setSelectedQuestions((prev) => {
        return isSelected(questions) || isIndeterminate(questions)
          ? prev.filter((question) => !questions.map((q) => q.id).includes(question.id))
          : [...prev, ...questions];
      });
      return;
    }
    questions.length && setActiveQuestionId(questions[0].id);
  }

  function handleCollapse(id: UniqueIdentifier) {
    setItems((items) =>
      setProperty(items, id, 'collapsed', (value) => {
        return !value;
      })
    );
  }
  function handleRename(id: UniqueIdentifier, newName: string) {
    setItems((items) =>
      setProperty(items, id, 'name', () => {
        return newName;
      })
    );
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier
  ) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }
}
