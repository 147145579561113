import { styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';
import { UserAvatar } from '../../UserAvatar/UserAvatar';

interface Props {
  name: string;
}

const Wrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AvatarCell = ({ name }: Props) => {
  const { colors } = useTheme();

  if (!name) return null;

  return (
    <Wrapper>
      <UserAvatar name={name} size='medium' />
      <Typography variant='body' color={colors.primary[70]}>
        {name}
      </Typography>
    </Wrapper>
  );
};
