import { styled } from '@mui/material';
import {
  APPLY_EVALUATION_RULES_HEADER_CONTROLS,
  PAGE_HEADER_HEIGHT,
} from '../../../constants/layoutSizes';
import { ReactNode } from 'react';

const Wrapper = styled('div')<{ isActive: boolean }>`
  padding: 24px 40px 0 40px;
  flex-grow: 1;
  height: ${({ isActive }) =>
    `calc(100vh - ${
      isActive ? PAGE_HEADER_HEIGHT + APPLY_EVALUATION_RULES_HEADER_CONTROLS : PAGE_HEADER_HEIGHT
    }px)`};
  overflow-y: auto;
  transition: height 0.3s ease-in-out;
`;

interface Props {
  isActive: boolean;
  children: ReactNode;
}

export const MainContentWrapper = ({ isActive, children }: Props) => {
  return <Wrapper isActive={isActive}>{children}</Wrapper>;
};
