import { Outlet, useNavigate, useParams } from 'react-router';
import { StepperLayout } from '../../components/StepperLayout/StepperLayout';
import { useQueryParams } from '../../hooks/useQueryParams';
import { CREATE_REQUEST_PARAMS } from '../../types';
import { useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import { createRequestCompletedProgressState } from '../../state/UIState';
import { ROUTES } from '../../constants/routes';
import { ControlButtons } from './components/ControlButtons';
import { useResetCreateRequestUiState } from './hooks/useResetCreateRequestUiState';

export const CreateRequestStepper = () => {
  const { params: queryParams, navigateAndPreserveParams } = useQueryParams();
  const isInitalStageMode = queryParams[CREATE_REQUEST_PARAMS.MODE] === 'initial';

  const resetState = useResetCreateRequestUiState();

  const navigate = useNavigate();

  const selectedFrequency = queryParams[CREATE_REQUEST_PARAMS.FREQUENCY];

  const surveyId = queryParams[CREATE_REQUEST_PARAMS.SURVEY_ID];

  const steps = useMemo(() => {
    const result = [
      {
        step: 1,
        text: 'Survey',
        isClickable: true,
      },
      {
        step: 2,
        text: 'Frequency',
        isClickable: !!surveyId,
      },
    ];
    if (!isInitalStageMode) {
      result.push({
        step: 3,
        text: 'Companies',
        isClickable: !!surveyId && !!selectedFrequency,
      });
    }
    return result;
  }, [isInitalStageMode, selectedFrequency, surveyId]);

  const params = useParams();
  const activeStep = Number(params.activeStep);
  const [progress] = useAtom(createRequestCompletedProgressState);

  const progressBarText =
    progress < 99
      ? 'Complete all steps to create the request.'
      : 'Congrats! All steps are completed.';

  const onStepClick = (step: number) => {
    navigateAndPreserveParams(`/${ROUTES.CREATE_REQUEST}/${step}`);
  };

  const onClick = useCallback(() => {
    resetState();
    navigate(`/${ROUTES.REQUESTS}`);
  }, [navigate, resetState]);

  return (
    <StepperLayout
      onBackClick={onClick}
      headerTitle='New Request'
      progressBarText={progressBarText}
      progress={progress}
      steps={steps}
      activeStep={activeStep}
      onClickStepNavigate={onStepClick}
      controlButtons={<ControlButtons />}
    >
      <Outlet />
    </StepperLayout>
  );
};
