import { FC, useMemo, useState } from 'react';
import { Company, SelectItem, USER_ROLE } from '../../../../types';
import { useUpdateCompany } from '../../../../queries/useCompanies';
import { AssignUserModal } from '../../../../components/AssignUserModal/AssignUserModal';
import { FormField } from '../../../../components/FormField/FormField';
import { SingleSelect } from '../../../../components/SingleSelect/SingleSelect';
import { useUsers } from '../../../../queries/useUsers';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';

interface Props {
  onClose: () => void;
  company: Company;
  isOpen: boolean;
}

export const AnalystModal: FC<Props> = ({ onClose, company, isOpen }) => {
  const updateCompany = useUpdateCompany();
  const { data: users } = useUsers();

  const isReassign = useMemo(() => Boolean(company?.analyst), [company?.analyst]);

  const options = useMemo(() => {
    return (
      users
        ?.filter((user) => user?.role?.name === USER_ROLE.ANALYST)
        ?.map((u) => ({
          id: u?.id,
          value: u?.fullName,
          icon: <UserAvatar size='medium' name={u?.fullName} />,
        })) || []
    );
  }, [users]);

  const initialAnalystOption = useMemo(() => {
    if (!company?.analyst) return null;
    return options?.find((option) => option.value === company?.analyst) || null;
  }, [company?.analyst, options]);

  const [selectedAnalyst, setSelectedAnalyst] = useState<SelectItem | null>(initialAnalystOption);

  const onSubmit = () => {
    updateCompany.mutate({ id: company.id, analyst: selectedAnalyst?.value });
  };

  return (
    <AssignUserModal
      isOpen={isOpen}
      title={
        isReassign ? 'Reassign ESG Analyst to the Company' : 'Assign ESG Analyst to the Company'
      }
      confirmBtnText={isReassign ? 'Reassign' : 'Assign'}
      onClose={onClose}
      isConfirmDisabled={!selectedAnalyst || company?.analyst === selectedAnalyst?.value}
      companyName={company?.name}
      companyLogo={company?.logoUrl}
      handleSubmit={onSubmit}
      assignedUser={company?.analyst}
    >
      <FormField label={isReassign ? 'New ESG Analyst' : 'ESG Analyst'}>
        <SingleSelect
          options={options}
          value={selectedAnalyst ?? null}
          onChange={(_, option) => setSelectedAnalyst(option)}
        />
      </FormField>
    </AssignUserModal>
  );
};
