import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';
import {
  Company,
  LoadingId,
  RawCompany,
  COMPANY_STAGE,
  CompanyKpisData,
  CoreKpiRequestFrequency,
  SURVEY_FREQUENCY,
  RISK_LEVEL,
  ChecklistData,
  AnalystRatingReview,
} from '../types';
import {
  generateAnnualDataForGivenYear,
  generateMonthlyDataForGivenYear,
  generateQuaterlyDataForGivenYear,
  getEsgDistributionOverTimeWithAverage,
} from '../utils/companyUtils';
import { REQUEST_GROUPS } from './useRequestGroups';
import { REPORTS } from './useReports';
import { useCallback } from 'react';

export const COMPANIES = 'companies';

export interface CompanyPayload {
  id?: number;
  name: string | null;
  logoUrl: string | null;
  sectors: number[];
  industry: number | null;
  region: number | null;
  managerEmail: string | null;
  esgContact?: string | null;
  isRecurringKpiAssigned?: boolean;
  isYearlyAssessmentAssigned?: boolean;
  exclusionScreeningSurvey?: number | null;
  exclusionDueDiligenceSurvey?: number | null;
  kpisData?: CompanyKpisData;
  kpisDataFrequency?: CoreKpiRequestFrequency;
  stage?: COMPANY_STAGE;
  esgRiskLevel?: RISK_LEVEL | null;
  esgOpportunitiesLevel?: RISK_LEVEL | null;
  analystRatingNotes?: string | null;
  description?: string | null;
  city?: string | null;
  country?: string | null;
  analyst?: string | null;
  isQuarterlyChecklistAssigned?: boolean;
  checklistData?: ChecklistData;
  exclusions: number[];
  violatedExclusionsIds: number[];
  areExclusionsExternallySaved?: boolean;
  areManagersNotified?: boolean;
  approvedByAdmin?: number;
  approvedByManager?: number;
  completedWorkflowSteps?: (string | number)[];
  analystRatingApprovedBy?: number[];
  analystRatingRejectedBy?: number[];
  governanceScore?: number | null;
  analystRatingReview?: AnalystRatingReview | null;
  screeningCompletedDate?: string;
}

export interface CompanyBulkPayload {
  name: string;
  externalId?: string;
  sector?: string | null;
  region?: string;
  kpisData?: CompanyKpisData;
  salesforceId?: string;
}

async function fetchCompanies(): Promise<Company[]> {
  const { data } = await Http.axios.get<RawCompany[]>(`/company`);
  data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  return data.map((company) => ({
    ...company,
    esgScoreData: {
      esgDistributionOverTime: company.esgScoreData?.esgDistributionOverTime
        ? getEsgDistributionOverTimeWithAverage(company.esgScoreData.esgDistributionOverTime)
        : [],
    },
  }));
}

export function useCompanies() {
  return useQuery(COMPANIES, () => fetchCompanies(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

export async function fetchCompanyById(id?: number): Promise<Company> {
  const { data } = await Http.axios.get<RawCompany>(`/company/${id}`);
  return {
    ...data,
    esgScoreData: {
      esgDistributionOverTime: data.esgScoreData?.esgDistributionOverTime
        ? getEsgDistributionOverTimeWithAverage(data.esgScoreData.esgDistributionOverTime)
        : [],
    },
  };
}

export function useCompanyById(id?: number) {
  return useQuery(`${COMPANIES}-${id}`, () => fetchCompanyById(id), {
    enabled: Boolean(id),
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function updateCompany(survey: Partial<CompanyPayload>): Promise<Company> {
  const { id, ...payload } = survey;
  const { data } = await Http.axios.patch<Partial<CompanyPayload>, Company>(
    `/company/${id}`,
    payload
  );
  return data;
}

export function useUpdateCompany() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateCompany, {
    onMutate: async () => {
      startLoading(LoadingId.updateCompany);
    },
    onError: (error: AxiosError & { response: { data: { message: string[] } } }) => {
      const message = error.response?.data?.message[0] ?? 'Failed to update company';
      pushErrorToast({ message });
    },
    onSettled: (company) => {
      queryClient.refetchQueries(COMPANIES);
      if (company) {
        queryClient.refetchQueries(`${COMPANIES}-${company.id}`);
      }
      stopLoading(LoadingId.updateCompany);
    },
  });
}

export function useUpdateCompanyWithouthRefetchData() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateCompany, {
    onMutate: async () => {
      startLoading(LoadingId.updateCompany);
    },
    onError: (error: AxiosError & { response: { data: { message: string[] } } }) => {
      const message = error.response?.data?.message[0] ?? 'Failed to update company';
      pushErrorToast({ message });
    },
    onSettled: () => {
      queryClient.refetchQueries(COMPANIES);
      stopLoading(LoadingId.updateCompany);
    },
  });
}

async function createCompanies(payload: { data: CompanyBulkPayload[] }): Promise<Company[]> {
  payload.data = payload.data.map((company) => {
    const currentYear = new Date().getFullYear();
    const currentYearMonthlyData = generateMonthlyDataForGivenYear(currentYear);
    const previousYearMonthlyData = generateMonthlyDataForGivenYear(currentYear - 1);
    const currentYearQuarterlyData = generateQuaterlyDataForGivenYear(currentYear);
    const previousYearQuarterlyData = generateQuaterlyDataForGivenYear(currentYear - 1);
    const currentYearAnnualData = generateAnnualDataForGivenYear(currentYear);
    const previousYearAnnualData = generateAnnualDataForGivenYear(currentYear - 1);
    const kpisData = {
      [SURVEY_FREQUENCY.MONTHLY]: [...previousYearMonthlyData, ...currentYearMonthlyData],
      [SURVEY_FREQUENCY.QUARTERLY]: [...previousYearQuarterlyData, ...currentYearQuarterlyData],
      [SURVEY_FREQUENCY.ANNUALLY]: [previousYearAnnualData, currentYearAnnualData],
    };
    return { ...company, kpisData };
  });
  const { data } = await Http.axios.post<{ data: CompanyBulkPayload[] }, Company[]>(
    `/company/bulk`,
    payload
  );
  return data;
}

export function useCreateCompanies() {
  const { startLoading, stopLoading } = useLoadingBar();
  const queryClient = useQueryClient();
  const { pushErrorToast, pushSuccessToast } = useToastMessage();

  return useMutation(createCompanies, {
    onMutate: async () => {
      startLoading(LoadingId.createCompanies);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create companies' });
    },
    onSuccess: (newCompanies) => {
      queryClient.setQueryData(COMPANIES, (oldCompanies: Company[] | undefined) =>
        oldCompanies ? [...newCompanies, ...oldCompanies] : [...newCompanies]
      );
      pushSuccessToast({ message: 'Companies were successfully imported' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(COMPANIES);
      stopLoading(LoadingId.createCompanies);
    },
  });
}

async function deleteCompany(id: number): Promise<number> {
  const { data } = await Http.axios.delete<number>(`/company/${id}`);
  return data;
}

export function useDeleteCompany() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(deleteCompany, {
    onMutate: async (companyId) => {
      startLoading(LoadingId.deleteCompany);
      await queryClient.cancelQueries(COMPANIES);
      const previousCompanies = queryClient.getQueryData(COMPANIES);
      queryClient.setQueryData(COMPANIES, (oldCompanies: Company[] | undefined) => {
        return (
          oldCompanies?.filter((company) => {
            return company.id !== companyId;
          }) ?? []
        );
      });
      return { previousCompanies };
    },
    onError: (error, _, context) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to delete company' });
      queryClient.setQueryData(COMPANIES, context?.previousCompanies);
    },
    onSettled: () => {
      queryClient.invalidateQueries(COMPANIES);
      queryClient.removeQueries(REQUEST_GROUPS);
      stopLoading(LoadingId.deleteCompany);
    },
  });
}

async function resetCompany(id: number): Promise<Company> {
  const { data } = await Http.axios.post<null, Company>(`/company/reset/${id}`);
  return data;
}

export function useResetCompany() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(resetCompany, {
    onMutate: async () => {
      startLoading(LoadingId.resetCompany);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to reset company' });
    },
    onSettled: (company) => {
      queryClient.invalidateQueries(COMPANIES);
      queryClient.removeQueries(REQUEST_GROUPS);
      if (company) {
        queryClient.refetchQueries(`${COMPANIES}-${company.id}`);
        queryClient.refetchQueries(`${REPORTS}-${company.id}`);
      }
      stopLoading(LoadingId.resetCompany);
    },
  });
}

async function syncCompanies(payload: { data: CompanyBulkPayload[] }): Promise<Company[]> {
  payload.data = payload.data.map((company) => {
    const currentYear = new Date().getFullYear();
    const currentYearMonthlyData = generateMonthlyDataForGivenYear(currentYear);
    const previousYearMonthlyData = generateMonthlyDataForGivenYear(currentYear - 1);
    const currentYearQuarterlyData = generateQuaterlyDataForGivenYear(currentYear);
    const previousYearQuarterlyData = generateQuaterlyDataForGivenYear(currentYear - 1);
    const currentYearAnnualData = generateAnnualDataForGivenYear(currentYear);
    const previousYearAnnualData = generateAnnualDataForGivenYear(currentYear - 1);
    const kpisData = {
      [SURVEY_FREQUENCY.MONTHLY]: [...previousYearMonthlyData, ...currentYearMonthlyData],
      [SURVEY_FREQUENCY.QUARTERLY]: [...previousYearQuarterlyData, ...currentYearQuarterlyData],
      [SURVEY_FREQUENCY.ANNUALLY]: [previousYearAnnualData, currentYearAnnualData],
    };
    return { ...company, kpisData };
  });
  const { data } = await Http.axios.post<{ data: CompanyBulkPayload[] }, Company[]>(
    `/company/sync`,
    payload
  );
  return data;
}

export function useSyncCompanies() {
  const queryClient = useQueryClient();

  return useMutation(syncCompanies, {
    onSettled: () => {
      queryClient.invalidateQueries(COMPANIES);
    },
  });
}

export function useInvalidateCompanyById() {
  const queryClient = useQueryClient();

  return useCallback(
    (companyId: number) => queryClient.invalidateQueries(`${COMPANIES}-${companyId}`),
    [queryClient]
  );
}

async function fetchCompanyByExternalId(id?: string): Promise<Company> {
  const { data } = await Http.axios.get<RawCompany>(`/company/external-id/${id}`);
  return data as Company;
}

export function useCompanyByExternalId(id?: string) {
  return useQuery(`${COMPANIES}-${id}`, () => fetchCompanyByExternalId(id), {
    enabled: Boolean(id),
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    retry: false,
  });
}
