import { KpiBarChartContainer, KpiBarChartWrapper } from './UI';
import { ReactComponent as CursorAdd } from '../../../../../assets/icons/cursor-add.svg';
import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 373px;
`;

export const NoKpisSelected = () => {
  const { colors } = useTheme();
  return (
    <KpiBarChartWrapper>
      <KpiBarChartContainer>
        <Container>
          <CursorAdd />
          <Typography variant='subtitle2' color={colors.primary[90]} style={{ marginTop: '24px' }}>
            Track Up to 3 KPIs Over Time
          </Typography>
          <Typography
            variant='body'
            color={colors.primary[70]}
            style={{ marginTop: '12px', maxWidth: '400px', textAlign: 'center' }}
          >
            Select up to 3 KPIs from the table that share the same metric to see their historical
            changes in a chart
          </Typography>
        </Container>
      </KpiBarChartContainer>
    </KpiBarChartWrapper>
  );
};
