import { CSSProperties } from 'react';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Item, Props as TreeItemProps } from './Item';
import { styled } from '@mui/material';

interface Props extends TreeItemProps {
  id: UniqueIdentifier;
  name: string;
}

const SortableWrapper = styled('div')``;

export function SortableItem({ id, ...props }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableWrapper
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ ...style, opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 2 : 1 }}
    >
      <Item {...props} />
    </SortableWrapper>
  );
}
