import { styled, Switch, useTheme } from '@mui/material';
import { Typography } from '../../../../../../components/Typography/Typography';

const SwitchWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

interface Props {
  disabled?: boolean;
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label: string;
  description?: string;
}

export const StepCardSwitch = ({ disabled, checked, onChange, label, description }: Props) => {
  const { colors } = useTheme();

  return (
    <SwitchWrapper>
      <Switch disabled={disabled} checked={checked} onChange={onChange} />
      <TextWrapper>
        <Typography variant='body' color={colors.primary[80]}>
          {label}
        </Typography>
        {description && (
          <Typography variant='caption' color={colors.primary[70]}>
            {description}
          </Typography>
        )}
      </TextWrapper>
    </SwitchWrapper>
  );
};
