import { useLocation, useNavigate } from 'react-router';
import { useMemo } from 'react';
import { MenuItemLabel } from './components/MenuItemLabel';
import { MenuItemBase } from './components/MenuItemBase';
import { TMenuItem } from '../../../types';

interface Props {
  item: TMenuItem;
  isExpanded: boolean;
}

export const MenuItem = ({ item, isExpanded }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = (route?: string) => {
    if (!route) return;
    navigate(`/${route}`);
  };

  const isActive = useMemo(() => {
    const activeRoute = location.pathname.split('/')[1];
    return activeRoute === item.id;
  }, [item.id, location.pathname]);

  return (
    <MenuItemBase
      onClick={() => onClick(item.route)}
      icon={isActive ? item.iconActive : item.icon}
      isExpanded={isExpanded}
      labelContent={<MenuItemLabel isActive={isActive} title={item.title} />}
    />
  );
};