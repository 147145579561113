import ContentLoader from 'react-content-loader';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')``;
const HeaderWrapper = styled('div')`
  padding: 0 40px;
  height: 58px;
  margin-bottom: 16px;
`;

const SectionLoader = styled('div')`
  padding: 0 40px;
`;

export function AssesmentPageSkeletonLoader(): ReactElement {
  return (
    <SkeletonWrapper>
      <HeaderWrapper>
        <ContentLoader height={58} width={'100%'}>
          <rect width='150' height='34' y='27' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </HeaderWrapper>
      <SectionLoader>
        <ContentLoader height={130} width={'100%'}>
          <rect width='200' height='36' rx='4' fill='#EEEFF1' />
          <rect width='300' height='30' y='90' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </SectionLoader>
      <SectionLoader>
        <ContentLoader height={550} width={'100%'}>
          <rect width='345' height='34' y='20' rx='4' fill='#EEEFF1' />
          <rect width='100%' height='24' y='70' rx='4' fill='#EEEFF1' />
          <rect y='102' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='143' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='184' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='225' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='266' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='307' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='348' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='389' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='430' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='471' width='100%' height='38' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </SectionLoader>
    </SkeletonWrapper>
  );
}
