import { styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as DragIcon } from '../../../../../../assets/icons/drag.svg';
import { ExclusionCondition } from '../../../../../../types';
import { useAtom } from 'jotai';
import {
  activeConditionIdState,
  activeExclusionCategoryState,
  expandedExclusionsConditionsState,
} from '../../../../../../state/UIState';

import { useUpdateExclusionCondition } from '../../../../../../queries/useExclusionCondition';
import { useAppConfig, useUpdateAppConfig } from '../../../../../../queries/useAppConfig';
import { Exclusions } from './Exclusions/Exclusions';
import { AccordionCard } from '../../../../../../components/AccordionCard/AccordionCard';
import { ConditionControls } from './ConditionControls/ConditionControls';

const DragWrapper = styled('div')`
  position: relative;
  padding: 0 25px;
`;

interface Props {
  condition: ExclusionCondition;
  conditionOrder: number;
  isDragging: boolean;
  isDraggable: boolean;
}

export const ConditionCard = ({ condition, conditionOrder, isDragging, isDraggable }: Props) => {
  const [showDragIcon, setShowDragIcon] = useState(false);
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const [expandedConditions, setExpandedConditions] = useAtom(expandedExclusionsConditionsState);
  const [conditionText, setConditionText] = useState(condition.condition);
  const [activeConditionId, setActiveConditionId] = useAtom(activeConditionIdState);
  const [activeCategory] = useAtom(activeExclusionCategoryState);

  const { mutate: updateCondition } = useUpdateExclusionCondition();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const isExpanded = useMemo(() => {
    return expandedConditions.includes(condition?.id);
  }, [expandedConditions, condition?.id]);

  const onExpandCollapseCondition = useCallback(() => {
    if (isExpanded) {
      setExpandedConditions(expandedConditions.filter((id) => id !== condition.id));
    } else {
      setExpandedConditions([...expandedConditions, condition.id]);
    }
  }, [condition.id, expandedConditions, isExpanded, setExpandedConditions]);

  const onConditionClick = useCallback(() => {
    setActiveConditionId(condition.id);
  }, [condition.id, setActiveConditionId]);

  const onMouseEnter = useCallback(() => {
    if (activeConditionId === condition.id) {
      return;
    }
    setShowDragIcon(true);
  }, [activeConditionId, condition.id]);

  const onMouseLeave = useCallback(() => {
    if (activeConditionId === condition.id) {
      return;
    }
    setShowDragIcon(false);
  }, [activeConditionId, condition.id]);

  const onChangeConditionText = useCallback(
    (value: string) => {
      updateCondition(
        { id: condition.id, condition: value || '', category: activeCategory?.id },
        {
          onSuccess: async () => {
            if (!appConfigData?.data) {
              return;
            }
            await updateAppConfig.mutateAsync({
              data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
            });
          },
        }
      );
    },
    [activeCategory?.id, appConfigData?.data, condition.id, updateAppConfig, updateCondition]
  );

  useEffect(() => {
    if (activeConditionId === condition.id) {
      isDraggable && setShowDragIcon(true);
      const top = wrapperRef?.current?.getBoundingClientRect()?.top;
      if (top && (top > window.innerHeight || top < 0)) {
        wrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setShowDragIcon(false);
    }
  }, [activeConditionId, condition.id, isDraggable]);

  const exclusionCountLabel = useMemo(() => {
    const count = condition?.exclusions?.length;
    if (count === 0) {
      return 'No exclusions yet';
    }
    if (count === 1) {
      return '1 exclusion';
    }
    return `${count} exclusions`;
  }, [condition?.exclusions?.length]);

  return (
    <DragWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {showDragIcon && (
        <DragIcon style={{ position: 'absolute', left: '7px', top: '5', cursor: 'pointer' }} />
      )}
      <AccordionCard
        onCardClick={onConditionClick}
        isInactive={!condition?.isActive}
        onExpandToggle={onExpandCollapseCondition}
        isExpanded={!isDragging && isExpanded}
        title={`Condition ${conditionOrder}`}
        chipLabel={exclusionCountLabel}
        controls={<ConditionControls condition={condition} />}
        textValue={conditionText}
        setTextValue={setConditionText}
        onTextValueChanged={onChangeConditionText}
      >
        {!isDragging && isExpanded && (
          <Exclusions exclusions={condition.exclusions} conditionId={condition.id} />
        )}
      </AccordionCard>
    </DragWrapper>
  );
};
