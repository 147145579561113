import { IconButton, Menu, MenuItem, PopoverOrigin, styled, useTheme } from '@mui/material';
import { ReactComponent as MoreIcon } from '../../assets/icons/more-horizontal.svg';
import { Typography } from '../Typography/Typography';
import { MouseEvent } from 'react';

const MoreButtonWrapper = styled('div')`
  position: relative;
  top: 0;
`;

const MenuItemDisabledOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  opacity: 0.5;
  z-index: 1;
  cursor: not-allowed;
`;

interface ActionOption {
  id: string;
  value: string;
  icon?: JSX.Element;
  isDisabled?: boolean;
}

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  actions: ActionOption[];
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMenuItemClick: (id: string) => void;
  menuStyles?: React.CSSProperties;
  transformOrigin?: PopoverOrigin;
}

export const ActionsPopupMenu = ({
  onClick,
  actions,
  open,
  anchorEl,
  onClose,
  onMenuItemClick,
  menuStyles,
  transformOrigin,
}: Props) => {
  const { colors } = useTheme();

  return (
    <MoreButtonWrapper>
      <IconButton
        onClick={onClick}
        style={{
          borderRadius: '4px',
        }}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        transformOrigin={transformOrigin || { horizontal: 'right', vertical: 'top' }}
        onClose={onClose}
        PaperProps={{
          style: {
            transform: 'translateX(20px) ',
            boxShadow: 'none',
            border: '1px solid #D8DEE3',
            borderRadius: '4px',
            ...menuStyles,
          },
        }}
      >
        {actions.map((c) => (
          <MenuItem
            key={c.id}
            onClick={(e) => {
              e.stopPropagation();
              if (c.isDisabled) return;
              onMenuItemClick(c.id);
            }}
            style={{ width: '100%', position: 'relative' }}
          >
            {c.isDisabled && <MenuItemDisabledOverlay />}
            {c.icon && c.icon}
            <Typography variant='body' color={colors.primary[90]} style={{ marginLeft: '4px' }}>
              {c.value}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </MoreButtonWrapper>
  );
};
