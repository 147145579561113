import { QuestionOption, SurveyQuestion } from '../../../../../types';
import { useAtom } from 'jotai';
import { responsesState } from '../../../../../state/UIState';

import { RadioOption } from './RadioOption';
import { OptionListWrapper } from '../common';

interface Props {
  question: SurveyQuestion;
}

export const SingleOptionQuestion = ({ question }: Props) => {
  const [responses] = useAtom(responsesState);

  return (
    <OptionListWrapper>
      {question.options.map((option) => {
        const optionResponse =
          (responses[question.id] as QuestionOption)?.id === option.id
            ? responses[question.id]
            : null;
        return (
          <div key={option.id}>
            <RadioOption
              key={option.id}
              isChecked={(responses[question.id] as QuestionOption)?.id === option.id}
              option={option}
              optionResponse={optionResponse as QuestionOption}
              question={question}
            />
          </div>
        );
      })}
    </OptionListWrapper>
  );
};
