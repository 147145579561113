import { styled } from '@mui/material';
import { Table as TTable, flexRender, Row as Trow } from '@tanstack/react-table';
import { useCallback } from 'react';
import { ColumnMeta } from '../../../types';

interface Props {
  table: TTable<any>;
  onRowClick?: (row: Trow<any>) => void;
  tHeaderStyle?: React.CSSProperties;
  tableStyle?: React.CSSProperties;
  tdStyle?: React.CSSProperties;
}

const Wrapper = styled('div')`
  // viewport width minus sidebar width minus padding
  overflow: auto;
  width: 100%;
`;

const StyledTable = styled('table')`
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;

  tr:first-of-type td {
    border-top: 2px solid ${({ theme }) => theme.colors.primary[30]};
  }
`;
const Tbody = styled('tbody')`
  padding: 0 19px;
`;
const Tr = styled('tr')`
  position: relative;
`;

const Td = styled('td')<{
  width?: string;
  minWidth?: string;
  maxWidth?: string;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
	border-bottom: 2px solid ${({ theme }) => theme.colors.primary[30]};
  border-right: 2px solid ${({ theme }) => theme.colors.primary[30]};
  text-align: left;
  position: relative;
  padding: 5px 8px;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  background-color: ${({ theme }) => theme.colors.primary[0]};
  &:first-of-type {
    background-color: ${({ theme }) => theme.colors.gray[20]};
    border-left: 2px solid ${({ theme }) => theme.colors.primary[30]};
  }
`;

export const TablePreview = ({ table, onRowClick, tableStyle, tdStyle }: Props) => {
  const onRowSelect = useCallback(
    (row: Trow<any>) => {
      if (onRowClick) onRowClick(row);
      if (row.getCanSelect()) {
        if (row.original.disabled) return;
        // not allowed unselecting for single select for now.
        if (row.getIsSelected() && !row.getCanMultiSelect()) return;
        const toggleSelected = row.getToggleSelectedHandler();
        toggleSelected(row);
      }
    },
    [onRowClick]
  );

  return (
    <Wrapper>
      <StyledTable style={tableStyle}>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              onClick={() => onRowSelect(row)}
              className={row.getIsSelected() && !row.getCanMultiSelect() ? 'selected-row' : ''}
            >
              {row.getVisibleCells().map((cell) => (
                <Td
                  style={tdStyle}
                  key={cell.id}
                  width={(cell.column.columnDef.meta as ColumnMeta)?.width}
                  minWidth={(cell.column.columnDef.meta as ColumnMeta)?.minWidth}
                  maxWidth={(cell.column.columnDef.meta as ColumnMeta)?.maxWidth}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </StyledTable>
    </Wrapper>
  );
};
