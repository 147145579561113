import { styled } from '@mui/material';
import { HeaderWrapper } from '../../components/Layout/Layout';
import { SurveysContent } from './components/SurveysContent';
import { SurveysHeader } from './components/SurveysHeader';

const Wrapper = styled('div')``;

export const Surveys = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <SurveysHeader />
      </HeaderWrapper>
      <SurveysContent />
    </Wrapper>
  );
};
