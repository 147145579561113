import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { REPORT_STATUS, Report } from '../types';

export const useOnReportClick = () => {
  const navigate = useNavigate();

  const onReportClick = (report?: Report) => {
    if (!report) return;
    if (
      report.status === REPORT_STATUS.REJECTED ||
      report.status === REPORT_STATUS.IN_PROGRESS ||
      report.status === REPORT_STATUS.NOT_STARTED ||
      report.status === REPORT_STATUS.SUBMITTED
    ) {
      navigate(`/${ROUTES.QUESTIONNAIRES}/${report.id}/progress`);
      return;
    }

    if (report.status === REPORT_STATUS.APPROVED || report.status === REPORT_STATUS.IN_REVIEW) {
      navigate(`/${ROUTES.REPORTS}/${report.id}`);
      return;
    }
  };

  return { onReportClick };
};
