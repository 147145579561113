import { Multiselect } from './Multiselect/Multiselect';
import { SelectItem } from '../types';

interface Props {
  sortedUniqueAnalysts: SelectItem[];
  selectedAnalysts: SelectItem[];
  onChangeAnalystsFilter: (analysts: SelectItem[]) => void;
}

export const AnalystFilter = ({
  sortedUniqueAnalysts,
  selectedAnalysts,
  onChangeAnalystsFilter,
}: Props) => {
  return (
    <Multiselect
      style={{ width: '220px' }}
      options={sortedUniqueAnalysts}
      value={selectedAnalysts}
      onChange={(_, val) => {
        onChangeAnalystsFilter(val as SelectItem[]);
      }}
      disablePortal
      optionName='Analyst'
      fieldPlaceholder='Filter by Analysts'
    />
  );
};
