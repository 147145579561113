import { useAtom } from 'jotai';
import { SurveyQuestion, QuestionOption } from '../../../../../../../../../../types';
import { activeQuestionIdState, questionState } from '../../../../../../../../../../state/UIState';
import { useCallback } from 'react';

export const useUpdateSubQuestion = () => {
  const [activeQuestionId] = useAtom(activeQuestionIdState);
  const [question, setQuestion] = useAtom(questionState(activeQuestionId));

  const updateSubQuestion = useCallback(
    (updatedSubQuestion: SurveyQuestion, option: QuestionOption) => {
      if (!updatedSubQuestion || !question) return;

      const updatedQuestion = {
        ...question,
        options: question?.options.map((o) => {
          if (o.id === option.id) {
            return {
              ...o,
              subQuestions: o?.subQuestions?.map((s) => {
                if (s.id === updatedSubQuestion.id) {
                  return updatedSubQuestion;
                }
                return s;
              }),
            };
          }
          return o;
        }),
      };
      setQuestion(updatedQuestion);
    },
    [question, setQuestion]
  );
  return { updateSubQuestion };
};
