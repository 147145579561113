import { styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { Typography } from '../../../../../components/Typography/Typography';
import { filteredSurveyTemplatesDataState } from '../../../../../state/UIState';
import { TemplateSectionList } from './TemplateSectionList';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CategoryWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TemplateCategoryList = () => {
  const [categories] = useAtom(filteredSurveyTemplatesDataState);
  const { colors } = useTheme();

  if (categories.length === 0) {
    return (
      <Wrapper>
        <Typography variant='h4' color={colors.primary[60]}>
          No results found
        </Typography>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {categories.map((category) => (
        <CategoryWrapper key={category.name}>
          <Typography variant='overline' color={colors.primary[60]}>
            {category.name.toUpperCase()}
          </Typography>
          <TemplateSectionList sections={category.sections} />
        </CategoryWrapper>
      ))}
    </Wrapper>
  );
};
