import { Switch, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../components/Typography/Typography';
import { RolesSelect } from '../RolesSelect';
import { Role, User, WorkflowStep } from '../../../../../../types';
import { NotifyUsersSelect } from './NotifyUsersSelect';
import { useIsApprovalDisabled } from './useIsApprovalDisabled';

const Container = styled('div')`
  margin-top: 16px;
`;
const SwitchWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const RolesWrapper = styled('div')`
  margin-top: 8px;
`;

interface Props {
  isApprovalRequired?: boolean;
  onToggleApprovalRequired: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  approvalRoles: Role[];
  updateStepApprovalRoles: (roles: Role[]) => void;
  selectedNotifyUsers?: User[];
  onUsersSelect: (users: User[]) => void;
  requiredUsersApproval: User[];
  onUpdateRequiredUsersApproval: (ids: number[]) => void;
  step: WorkflowStep;
}

export const ApprovalSettings = ({
  isApprovalRequired,
  onToggleApprovalRequired,
  approvalRoles,
  updateStepApprovalRoles,
  selectedNotifyUsers,
  onUsersSelect,
  requiredUsersApproval,
  onUpdateRequiredUsersApproval,
  step,
}: Props) => {
  const { colors } = useTheme();

  const isDisabled = useIsApprovalDisabled(step);

  return (
    <Container style={{ opacity: isDisabled ? 0.5 : 1, cursor: 'not-allowed' }}>
      <SwitchWrapper>
        <Switch
          disabled={isDisabled}
          checked={isApprovalRequired}
          onChange={onToggleApprovalRequired}
        />
        <Typography variant='body' color={colors.primary[80]}>
          Requires Approval
        </Typography>
      </SwitchWrapper>
      {isApprovalRequired && (
        <RolesWrapper>
          <RolesSelect initialRoles={approvalRoles} onRolesSelect={updateStepApprovalRoles} />
        </RolesWrapper>
      )}
      {Boolean(approvalRoles?.length) && isApprovalRequired && (
        <NotifyUsersSelect
        selectedNotifyUsers={selectedNotifyUsers}
          onUsersSelect={onUsersSelect}
          approvalRoles={approvalRoles}
          requiredUsersApproval={requiredUsersApproval}
          onUpdateRequiredUsersApproval={onUpdateRequiredUsersApproval}
        />
      )}
    </Container>
  );
};
