import { FC } from 'react';

import { SURVEY_STAGE, SurveyQuestion } from '../../../../../types';

import { CoreKpiTableQuestion } from './CoreKpiTableQuestion/CoreKpiTableQuestion';

interface Props {
  question: SurveyQuestion;
  stage?: SURVEY_STAGE | null;
}

export const TableQuestion: FC<Props> = ({ question, stage }) => {
  const isCoreKpisSurvey = stage === SURVEY_STAGE.CORE_KPI;

  if (isCoreKpisSurvey) {
    return <CoreKpiTableQuestion question={question} />;
  }

  return null;
};
