import { Dispatch, SetStateAction } from 'react';
import { ConfirmDeleteModal } from '../../../../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { useDeleteObjective } from '../../../../../../../queries/useObjectives';
import { useParams } from 'react-router';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  objectiveId: number;
}

export const DeleteObjectiveModal = ({ isModalOpen, setIsModalOpen, objectiveId }: Props) => {
  const params = useParams();

  const deleteObjective = useDeleteObjective(Number(params.id));

  const onDeleteObjective = () => {
    setIsModalOpen(false);
    deleteObjective.mutate(objectiveId);
  };

  return (
    <ConfirmDeleteModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title='Delete the objective?'
      onConfirm={onDeleteObjective}
      note='This will permanently delete all progress related to this objective.'
      confirmLabel='Yes, delete'
      cancelLabel='No, cancel'
    />
  );
};
