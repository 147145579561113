import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { SelectableCompaniesTable } from './SelectableCompaniesTable';

const Wrapper = styled('div')``;

export const ReviewStep = () => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '8px' }}>
        Add Following Companies
      </Typography>
      <SelectableCompaniesTable />
    </Wrapper>
  );
};
