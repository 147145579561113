import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../../../components/Typography/Typography';
import { SingleSelect } from '../../../../../../../../components/SingleSelect/SingleSelect';
import { Multiselect } from '../../../../../../../../components/Multiselect/Multiselect';
import { useCallback, useMemo } from 'react';
import { useExternalInvestors } from '../../../../../../../../queries/useInvestors';
import { Exclusion, SelectItem } from '../../../../../../../../types';
import { useUpdateExclusion } from '../../../../../../../../queries/useExclusions';
import { useAppConfig, useUpdateAppConfig } from '../../../../../../../../queries/useAppConfig';

const InputWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

interface Props {
  exclusion: Exclusion;
}

export const InvestorFunds = ({ exclusion }: Props) => {
  const { colors } = useTheme();

  const { data: investors } = useExternalInvestors();
  const { mutate: updateExclusion } = useUpdateExclusion();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const investorOptions = useMemo(() => {
    if (!investors) return [];
    return investors.map((investor) => ({
      id: investor.investorCloakedId,
      value: investor.investorCloaked,
    }));
  }, [investors]);

  const selectedInvestor = useMemo(() => {
    if (!investorOptions) return undefined;
    return investorOptions.find((option) => String(option.id) === exclusion.externalInvestorId);
  }, [exclusion.externalInvestorId, investorOptions]);

  const onChangeInvestor = useCallback(
    (e: React.SyntheticEvent<Element, Event>, option: SelectItem | null) => {
      if (!option && e.type === 'change') return;

      const newInvestor = investors?.find((investor) => investor.investorCloakedId === option?.id);

      updateExclusion(
        {
          id: exclusion.id,
          externalInvestorId: String(newInvestor?.investorCloakedId),
          externalInvestorName: newInvestor?.investorCloaked,
        },
        {
          onSuccess: async () => {
            if (!appConfigData?.data) {
              return;
            }
            await updateAppConfig.mutateAsync({
              data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
            });
          },
        }
      );
    },
    [appConfigData?.data, exclusion.id, investors, updateAppConfig, updateExclusion]
  );

  const fundsOptions = useMemo(() => {
    if (!selectedInvestor || !investors) return [];
    const investor = investors.find((i) => i.investorCloakedId === selectedInvestor.id);
    if (!investor) return [];
    return investor.investorFunds.map((fund) => ({
      id: fund.fundId,
      value: fund.fundName,
    }));
  }, [investors, selectedInvestor]);

  const selectedFunds = useMemo(() => {
    if (!fundsOptions) return [];
    return fundsOptions.filter((option) =>
      exclusion?.externalFunds?.map((fund) => fund.id)?.includes(option.id)
    );
  }, [exclusion?.externalFunds, fundsOptions]);

  const onChangeFunds = useCallback(
    (e: React.SyntheticEvent<Element, Event>, options: SelectItem[] | undefined) => {
      if (!options || e.type === 'change') return;

      updateExclusion(
        {
          id: exclusion.id,
          externalFunds: options.map((option) => ({
            id: String(option.id),
            name: option.value,
          })),
        },
        {
          onSuccess: async () => {
            if (!appConfigData?.data) {
              return;
            }
            await updateAppConfig.mutateAsync({
              data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
            });
          },
        }
      );
    },
    [appConfigData?.data, exclusion.id, updateAppConfig, updateExclusion]
  );

  return (
    <InputWrapper>
      <Typography variant='srOnly' color={colors.primary[90]}>
        Investor
      </Typography>
      <SingleSelect
        style={{ width: '234px' }}
        options={investorOptions}
        value={selectedInvestor ?? null}
        onChange={onChangeInvestor}
        fieldPlaceholder={selectedInvestor ? undefined : 'Select investor'}
        disablePortal={false}
        blurOnSelect
      />
      {Boolean(fundsOptions.length) && (
        <>
          <Typography variant='srOnly' color={colors.primary[90]}>
            Funds
          </Typography>
          <Multiselect
            style={{ width: '234px' }}
            options={fundsOptions}
            value={selectedFunds}
            onChange={onChangeFunds}
            disablePortal={false}
            fieldPlaceholder='Select funds'
          />
        </>
      )}
    </InputWrapper>
  );
};
