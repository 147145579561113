import { useCallback } from 'react';
import { useAtom } from 'jotai';
import {
  activeChecklistCategoryState,
  selectedChecklistCategoryState,
  selectedChecklistItemState,
} from '../../../state/UIState';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { getMaxOrderNum } from '../../../utils/getMaxOrderNum';
import { ReactComponent as UndoIcon } from '../../../assets/icons/undo-blue.svg';
import { useOnCancelConfirm } from './useOnCancelConfirm';
import { useUpdateChecklistItem } from '../../../queries/useChecklistItems';

export const useMoveChecklistItem = () => {
  const [selectedCategory] = useAtom(selectedChecklistCategoryState);
  const [selectedItem] = useAtom(selectedChecklistItemState);
  const [activeChecklistCategory] = useAtom(activeChecklistCategoryState);
  const updateChecklistItem = useUpdateChecklistItem();
  const { pushSuccessToast } = useToastMessage();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const { onCancel } = useOnCancelConfirm();

  const moveChecklistItem = useCallback(async () => {
    const maxOrderNum = getMaxOrderNum(selectedCategory?.items);

    await updateChecklistItem.mutateAsync(
      {
        id: selectedItem?.id,
        category: selectedCategory?.id,
        order: (maxOrderNum || 0) + 1,
      },
      {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, areCheckListSurveysUpToDate: false },
          });
          pushSuccessToast({
            message: `${selectedItem?.name}  was successfully moved to ${selectedCategory?.name}.`,
            action: () => {
              updateChecklistItem.mutate({
                id: selectedItem?.id,
                category: activeChecklistCategory?.id,
                order: selectedItem?.order,
              });
            },
            actionLabel: 'Undo',
            endIcon: <UndoIcon />,
          });
        },
      }
    );
    onCancel();
  }, [
    activeChecklistCategory?.id,
    appConfigData?.data,
    onCancel,
    pushSuccessToast,
    selectedCategory?.id,
    selectedCategory?.items,
    selectedCategory?.name,
    selectedItem?.id,
    selectedItem?.name,
    selectedItem?.order,
    updateAppConfig,
    updateChecklistItem,
  ]);

  return { moveChecklistItem };
};
