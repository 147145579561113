import { useState, useEffect } from 'react';
import { FeatureFlagKey } from '../constants/featureFlags';

const useFeatureFlag = (localStorageKey: FeatureFlagKey): boolean => {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    const storedValue = localStorage.getItem(localStorageKey);
    setIsEnabled(storedValue === 'true');
  }, [localStorageKey]);

  return isEnabled;
};

export default useFeatureFlag;
