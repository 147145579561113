import { styled, useTheme } from '@mui/material';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/arrow-up.svg';
import { ExclusionCategory } from '../../../../types';
import { useMemo } from 'react';
import { Typography } from '../../../../components/Typography/Typography';
import { ConditionsList } from './ConditionsList';
import { useAtom } from 'jotai';
import { expandedCategoryState } from '../../../../state/UIState';

const Container = styled('div')`
  &:not(:first-of-type) {
    margin-top: 28px;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

interface Props {
  category: ExclusionCategory;
}

export const CategoryDropdown = ({ category }: Props) => {
  const [expandedCategory, setExpandedCategory] = useAtom(expandedCategoryState);

  const { colors } = useTheme();

  const onToggle = (category: ExclusionCategory) => {
    if (expandedCategory?.id === category?.id) {
      setExpandedCategory(null);
      return;
    }
    setExpandedCategory(category);
  };

  const isExpanded = useMemo(
    () => expandedCategory?.id === category.id,
    [category.id, expandedCategory?.id]
  );

  return (
    <Container>
      <Wrapper onClick={() => onToggle(category)}>
        <Typography
          variant='overline'
          color={colors.primary[60]}
          style={{ textTransform: 'uppercase' }}
        >
          {category.name}
        </Typography>
        {isExpanded ? <ArrowUp /> : <ArrowDown />}
      </Wrapper>
      {isExpanded && <ConditionsList conditions={category.conditions} />}
    </Container>
  );
};
