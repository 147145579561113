import { useMemo } from 'react';
import { Typography } from '../../../../../components/Typography/Typography';
import { createColumnHelper } from '@tanstack/react-table';
import { ChecklistDataItem } from '../../../../../types';
import { Fade, useTheme } from '@mui/material';
import { LastChangedCell } from '../LastChangedCell';
import { Tooltip } from '../../../../../components/Tooltip/Tooltip';
import { AnswerSelectCell } from '../AnswerSelectCell/AnswerSelectCell';

const columnHelper = createColumnHelper<ChecklistDataItem & { lastChanged: string }>();

export const useChecklistTableColumns = () => {
  const { colors } = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor('itemName', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <div>
              <Typography variant='subtitle2' color={colors.primary[90]}>
                {info.getValue()}
              </Typography>
            </div>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Item
          </Typography>
        ),
        meta: {
          width: '40%',
          minWidth: '180px',
          maxWidth: '180px',
        },
        enableColumnFilter: true,
      }),
      columnHelper.accessor('categoryName', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <div>
              <Typography variant='subtitle2' color={colors.primary[90]}>
                {info.getValue()}
              </Typography>
            </div>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Category
          </Typography>
        ),
        meta: {
          width: '20%',
          minWidth: '120px',
          maxWidth: '120px',
        },
        enableColumnFilter: true,
        filterFn: (row: any, columnId, selectedFilters) => {
          const value: string = row.getValue(columnId) || '';
          return selectedFilters.includes(value);
        },
      }),
      columnHelper.accessor('checklistOption', {
        cell: (info) => (
          <AnswerSelectCell
            itemId={info.row.original.id}
            value={info.getValue().name}
            notes={info.row.original.notes}
            frequency={info.row.original.frequency}
          />
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Answer
          </Typography>
        ),
        meta: {
          width: '10%',
          minWidth: '200px',
          maxWidth: '200px',
        },
        enableColumnFilter: true,
        filterFn: (row: any, columnId, selectedFilters) => {
          const values: string[] = row.getValue(columnId)?.name;
          return selectedFilters.some((filter: string) => values.includes(filter));
        },
        sortingFn: (rowA: any, rowB: any, columnId: any): number =>
          rowA.getValue(columnId).name > rowB.getValue(columnId).name ? 1 : -1,
      }),
      columnHelper.accessor('lastChanged', {
        cell: (info) => (
          <LastChangedCell
            lastChanged={info.getValue()}
            selectedOption={info.row.original.checklistOption.name}
            frequency={info.row.original.frequency}
          />
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Last Changed
          </Typography>
        ),
        meta: {
          width: '10%',
          minWidth: '200px',
          maxWidth: '200px',
        },
        sortingFn: 'datetime',
      }),
    ],
    [colors.primary]
  );

  return columns;
};
