import { styled, useTheme } from '@mui/material';
import { CompanyLogo } from '../../CompanyLogo/CompanyLogo';
import { Typography } from '../../Typography/Typography';
import { UserAvatar } from '../../UserAvatar/UserAvatar';

interface Props {
  name: string;
  logo?: string | null;
  size?: 'small' | 'medium';
  labelColor?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Wrapper = styled('div')<{ size: 'small' | 'medium' }>`
  display: flex;
  align-items: center;
  gap: ${({ size }) => (size === 'medium' ? '8px' : '4px')};
`;

export const CompanyCell = ({ name, logo, size = 'medium', labelColor, onClick }: Props) => {
  const { colors } = useTheme();
  return (
    <Wrapper size={size} onClick={onClick}>
      {logo ? (
        <CompanyLogo size={size} hasBorder src={logo} />
      ) : (
        <UserAvatar size={'medium'} name={name} />
      )}
      <Typography
        variant={size === 'medium' ? 'subtitle2' : 'srOnly'}
        color={labelColor ?? colors.primary[90]}
      >
        {name}
      </Typography>
    </Wrapper>
  );
};
