import { styled, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { DatePicker } from '../../components/DatePicker/DatePicker';
import { RequestGroupPageSkeletonLoader } from '../../components/SkeletonLoader/RequestGroupPage.SkeletonLoader';
import { Typography } from '../../components/Typography/Typography';
import { useRequestGroupById } from '../../queries/useRequestGroups';
import { SURVEY_FREQUENCY } from '../../types';
import { ProgressStatistics } from './components/ProgressStatistics';
import { RequestsTable } from './components/RequestsTable';
import { SingleRequestGroupHeader } from './components/SingleRequestGroupHeader';

const Wrapper = styled('div')`
  padding: 24px 40px;
`;
const TitleWrapper = styled('div')`
  margin: 54px 0 18px 0;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SingleRequestGroup = () => {
  const params = useParams();
  const { isLoading, data: requestGroup } = useRequestGroupById(Number(params.requestGroupId));
  const { colors } = useTheme();

  const [date, setDate] = useState<Date | null>(null);

  const datePickerViews = useMemo<('year' | 'month' | 'day')[] | undefined>(() => {
    if (!requestGroup) return undefined;
    return requestGroup.frequency === SURVEY_FREQUENCY.ANNUALLY ? ['year'] : ['year', 'month'];
  }, [requestGroup]);

  if (isLoading || !requestGroup) return <RequestGroupPageSkeletonLoader />;
  return (
    <Wrapper>
      <SingleRequestGroupHeader name={requestGroup.requests[0].name} />
      <TitleWrapper>
        <Typography variant='h4' color={colors.primary[90]}>
          Responses
        </Typography>
        <DatePicker
          selectedDate={date?.toISOString() ?? null}
          setSelectedDate={setDate}
          views={datePickerViews}
          clearable
        />
      </TitleWrapper>
      <ProgressStatistics requests={requestGroup.requests} />
      <RequestsTable requests={requestGroup.requests} selectedDate={date} />
    </Wrapper>
  );
};
