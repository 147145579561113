import { useMemo } from 'react';

import { CompaniesPageContent } from './components/CompaniesPageContent';
import { useCompanies } from '../../queries/useCompanies';

export const Companies = () => {
  const { isLoading, data: companiesData } = useCompanies();

  const companiesNum = useMemo(() => {
    return companiesData?.length || 0;
  }, [companiesData?.length]);

  return (
    <CompaniesPageContent
      showButtons
      showDescription
      title='Companies'
      showCompaniesNum
      companiesNum={companiesNum}
      isLoading={isLoading}
      companiesData={companiesData}
    />
  );
};
