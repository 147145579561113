import { Autocomplete, TextField, SxProps, styled } from '@mui/material';
import { useTheme } from '@mui/material';
import { SelectItem } from '../../types';
import { ReactComponent as ClearIcon } from '../../assets/icons/close-gray.svg';
import { Typography } from '../Typography/Typography';

interface Props {
  options: SelectItem[];
  hideClearIcon?: boolean;
  componentsProps?: {
    clearIndicator?: object;
    paper?: object;
    popper?: object;
    popupIndicator?: object;
  };
  onChange: (e: React.SyntheticEvent, newValue: SelectItem | null) => void;
  value: SelectItem | null | undefined;
  style?: React.CSSProperties;
  disablePortal?: boolean;
  fieldName?: string; // for Formik
  fieldPlaceholder?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  openOnfocus?: boolean;
  blurOnSelect?: boolean;
  autofocus?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  inputVariant?: 'outlined' | 'filled' | 'standard';
  className?: string;
  onInputChange?: (e: React.ChangeEvent) => void;
}

const StyleContainer = styled('div')`
  & .MuiAutocomplete-popup {
    z-index: 999999 !important;
  }

  &&& .MuiInputBase-root.Mui-focused {
    z-index: 1000;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 0 0 9px;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 6px 4px 6px 6px;
  }
  .MuiAutocomplete-listbox {
    padding: 4px 8px;
  }
  .MuiFormControl-root.MuiTextField-root:hover .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    visibility: visible;
  }
  .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    visibility: hidden;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-uwj8o8-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 48px;
  }
`;
const OptionWrapper = styled('li')`
  &&&.MuiAutocomplete-option {
    padding: 6px 6px 6px 8px;
    height: 32px;
    margin: 4px 0;
    border-radius: 4px;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export function SingleSelect(props: Props): JSX.Element {
  const { colors } = useTheme();

  const {
    fieldName,
    disabled = false,
    fieldPlaceholder,
    disablePortal = true,
    openOnfocus = true,
    autofocus = false,
    hideClearIcon = false,
    blurOnSelect = false,
    inputVariant = 'outlined',
    sx,
    className,
    onInputChange,
    ...rest
  } = props;

  return (
    <StyleContainer>
      <Autocomplete
        clearIcon={!hideClearIcon ? <ClearIcon /> : null}
        className={className}
        autoHighlight
        disabled={disabled}
        getOptionLabel={function (option: SelectItem): string {
          return String(option?.value) ?? '';
        }}
        openOnFocus={openOnfocus}
        blurOnSelect={blurOnSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onInputChange || params.inputProps.onChange}
            fullWidth
            name={fieldName}
            variant={inputVariant}
            placeholder={fieldPlaceholder}
            autoFocus={autofocus}
          />
        )}
        renderOption={(props, option) => {
          return (
            <OptionWrapper {...props} key={option.id}>
              <Typography variant='body' color={colors.primary[90]}>
                {option?.value ?? ''}
              </Typography>
            </OptionWrapper>
          );
        }}
        disablePortal={disablePortal}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        sx={
          sx ?? {
            "& button[title='Clear'], & button[title='Open'], & button[title='Close']": {
              visibility: 'visible',
              color: colors.primary[60],
            },
          }
        }
        {...rest}
      />
    </StyleContainer>
  );
}
