import { Dispatch, FocusEvent, SetStateAction, useEffect } from 'react';
import { EditableTextFieldV2 } from '../EditableTextField/EditableTextFieldV2';
import { styled } from '@mui/material';

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
`;

interface Props {
  value: string;
  onValueChanged: (value: string) => void;
  inputPadding?: string;
  disabled?: boolean;
  autofocus?: boolean;
  onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
  style?: React.CSSProperties;
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  maxLength?: number;
  withBorder?: boolean;
  placeholder?: string;
}

export const EditableTextCell = ({
  value,
  setInputValue,
  inputValue,
  onValueChanged,
  autofocus,
  disabled,
  onBlur,
  style,
  maxLength,
  withBorder,
  placeholder,
}: Props) => {
  useEffect(() => {
    if (!value) return;
    setInputValue(value);
  }, [setInputValue, value]);

  return (
    <Wrapper style={style}>
      <EditableTextFieldV2
        value={inputValue}
        onChange={(e) => {
          const val = e.target.value;

          if (maxLength && val?.length > maxLength) return;

          setInputValue(val);
        }}
        placeholder={placeholder}
        onValueChanged={onValueChanged}
        autoFocus={autofocus}
        disabled={disabled}
        onBlur={onBlur}
        wrapperStyle={{ height: '30px' }}
        withBorder={withBorder}
      />
    </Wrapper>
  );
};
