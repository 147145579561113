import { styled } from '@mui/material';
import { HeaderWrapper } from '../../components/Layout/Layout';
import { UsersContent } from './components/UsersContent';
import { UsersHeader } from './components/UsersHeader';

const Wrapper = styled('div')``;

export const UserManagement = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <UsersHeader />
      </HeaderWrapper>
      <UsersContent />
    </Wrapper>
  );
};
