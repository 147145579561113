import { styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import { Typography } from '../../Typography/Typography';

interface Props {
  progress: number;
}

const Wrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const ProgressWrapper = styled('div')`
  width: 137px;
`;

export const RequestProgressCell = ({ progress }: Props) => {
  const { colors } = useTheme();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => setWidth(progress), 200);
  }, [progress]);

  return (
    <Wrapper>
      <ProgressWrapper>
        <ProgressBar
          progress={width}
          color={
            progress < 30
              ? colors.error[30]
              : progress === 100
              ? colors.success[30]
              : colors.accent[40]
          }
        />
      </ProgressWrapper>
      <Typography variant='numbers' color={colors.primary[90]}>
        {progress}%
      </Typography>
    </Wrapper>
  );
};
