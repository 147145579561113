import { styled, useTheme } from '@mui/material';

import { ReactNode, useEffect, useRef } from 'react';

import { QuestionResponse } from './QuestionResponse/QuestionResponse';
import { SurveyQuestion } from '../../../../../types';
import { Typography } from '../../../../../components/Typography/Typography';

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 16px 0 16px 0;
`;
const QuestionWrapper = styled('div')``;

interface Props {
  question: SurveyQuestion;
  onClick: (question: SurveyQuestion) => void;
  isCoreKpi?: boolean;
  order: string;
  isActive?: boolean;
  tabsComponent?: ReactNode;
}

export const ReviewQuestionContent = ({
  question,
  onClick,
  isCoreKpi,
  order,
  isActive,
  tabsComponent,
}: Props) => {
  const wrapperRef = useRef<null | HTMLDivElement>(null);

  const { colors } = useTheme();

  useEffect(() => {
    if (isActive) {
      const top = wrapperRef?.current?.getBoundingClientRect()?.top;
      if (top && (top > window.innerHeight || top < 0)) {
        wrapperRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }, [isActive]);

  return (
    <Wrapper onClick={() => onClick(question)} ref={wrapperRef}>
      <QuestionWrapper>
        <Typography
          variant='h4'
          color={colors.primary[90]}
          style={{ marginBottom: '12px', maxWidth: '700px' }}
        >
          {`${order}. ${question.name}`}
        </Typography>
        <QuestionResponse question={question} isCoreKpi={isCoreKpi} />
      </QuestionWrapper>
      {tabsComponent}
    </Wrapper>
  );
};
