import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { SelectItem } from '../../../../types';
import { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { Table } from '../../../../components/Table/Table';
import { SearchInput } from '../../../../components/SearchInput/SearchInput';
import { ChipsSelectorMultiselect } from '../../../../components/ButtonSelector/ChipsSelectorMultiselect';
import { Multiselect } from '../../../../components/Multiselect/Multiselect';
import { useChecklistTableColumns } from './hooks/useChecklistTableColumns';
import { useChecklistData } from './hooks/useChecklistData';

const Wrapper = styled('div')`
  padding: 20px 40px;
`;

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

const TableWrapper = styled('div')`
  margin-top: 20px;
`;

export const ChecklistTab = () => {
  const combinedChecklistItems = useChecklistData();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [selectedAnswers, setSelectedAnswers] = useState<SelectItem[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<SelectItem[]>([]);

  const columns = useChecklistTableColumns();

  const table = useReactTable({
    data: combinedChecklistItems || [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (combinedChecklistItem) => String(combinedChecklistItem.id),
  });

  const answerOptions = useMemo(() => {
    const allValues = Array.from(
      table.getColumn('checklistOption')?.getFacetedUniqueValues().keys() ?? []
    );

    const allAnswers = allValues?.map((answer) => answer.name);

    const map = allAnswers.reduce((acc, curr) => {
      if (!curr) return acc;
      acc[curr] = curr;
      return acc;
    }, {});

    const unique =
      Object.keys(map)
        .sort()
        .reverse()
        .map((value, id) => ({ id, value })) ?? [];

    if (!columnFilters.length) setSelectedAnswers(unique);

    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, combinedChecklistItems]);

  const onSelectAnswersFilter = useCallback(
    (answers: SelectItem[]) => {
      setSelectedAnswers(answers);
      table.getColumn('checklistOption')?.setFilterValue(answers.map((a) => a.value));
    },
    [table]
  );

  const sortedUniqueCategories = useMemo(() => {
    const allValues = Array.from(
      table.getColumn('categoryName')?.getFacetedUniqueValues().keys() ?? []
    );

    const map = allValues.reduce((acc, curr) => {
      if (!curr) return acc;
      acc[curr] = curr;
      return acc;
    }, {});

    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    if (!columnFilters.length) setSelectedCategories(unique);
    return unique;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, combinedChecklistItems]);

  const onChangeCategoriesFilter = useCallback(
    (countries: SelectItem[]) => {
      setSelectedCategories(countries);
      table.getColumn('categoryName')?.setFilterValue(countries.map((c) => c.value));
    },
    [table]
  );

  return (
    <Wrapper>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for an item'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('itemName')?.setFilterValue('')}
          onChange={(e) => table.getColumn('itemName')?.setFilterValue(e.target.value)}
          value={table.getColumn('itemName')?.getFilterValue() ?? ''}
        />
        <Multiselect
          style={{ width: '220px' }}
          options={sortedUniqueCategories}
          value={selectedCategories}
          onChange={(_, val) => {
            onChangeCategoriesFilter(val as SelectItem[]);
          }}
          disablePortal
          optionName='Category'
          optionsName='Categories'
          fieldPlaceholder='Filter by Categories'
        />
        <ChipsSelectorMultiselect
          options={answerOptions}
          onSelect={onSelectAnswersFilter}
          selectedItems={selectedAnswers}
        />
      </FiltersWrapper>
      <TableWrapper>
        <Table
          table={table}
          height={'calc(100vh - 114px - 138px)'}
          thStyle={{ minWidth: '115px', paddingRight: 0 }}
          tdStyle={{ width: 0 }}
          disableLayoutAnimation
        />
      </TableWrapper>
    </Wrapper>
  );
};
