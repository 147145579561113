import { styled, TextField, useTheme } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { EvaluationRulesConfig } from './EvaluationRulesConfig/EvaluationRulesConfig';
import { useAtom } from 'jotai';
import {
  activeSurveyState,
  includeEvaluationState,
  includeQuestionNumbersState,
  includeWeightState,
} from '../../../state/UIState';
import { Modal } from '../../../components/Modal/Modal';
import { SURVEY_TYPE } from '../../../types';
import { SurveySwitch } from './SurveySwitch';

interface Props {
  title: string;
  surveyName: string;
  onConfirm: (value: string, description: string) => void;
  confirmText?: string;
  onClose?: () => void;
  isOpen: boolean;
}

const Wrapper = styled('div')``;

const SwitchesContainer = styled('div')`
  margin-top: 16px;
`;

export const EditSurveyModal: FC<Props> = ({
  title,
  onConfirm,
  surveyName,
  confirmText = 'Confirm',
  onClose = () => undefined,
  isOpen,
}) => {
  const { colors } = useTheme();
  const [inputValue, setInputValue] = useState(surveyName);
  const [activeSurvey] = useAtom(activeSurveyState);
  const [description, setDescription] = useState(
    activeSurvey?.description ? activeSurvey.description : ''
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [includeEvaluation, setIncludeEvaluation] = useAtom(includeEvaluationState);
  const [includeWeight, setIncludeWeight] = useAtom(includeWeightState);
  const [includeQuestionNumbers, setIncludeQuestionNumbers] = useAtom(includeQuestionNumbersState);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title={title}
      onConfirm={() => onConfirm(inputValue, description)}
      cancelBtnText='Cancel'
      confirmBtnText={confirmText}
    >
      <Wrapper>
        <Typography variant='caption' color={colors.primary[90]} style={{ paddingBottom: '10px' }}>
          Name
        </Typography>
        <TextField
          value={inputValue}
          onChange={onChange}
          placeholder='Survey name'
          style={{ width: '100%' }}
          inputRef={inputRef}
        />
        <Typography
          variant='caption'
          color={colors.primary[90]}
          style={{ paddingBottom: '10px', marginTop: '16px' }}
        >
          Description
        </Typography>
        <TextField
          value={description}
          onChange={onDescriptionChange}
          placeholder='Description'
          style={{ width: '100%' }}
        />
        <SwitchesContainer>
          <SurveySwitch
            checked={!!includeQuestionNumbers}
            onChange={() => setIncludeQuestionNumbers((prev) => !prev)}
            label='Show Question Numbers'
          />
          {!activeSurvey ||
          (activeSurvey?.type === SURVEY_TYPE.USER && !activeSurvey?.isAssigned) ? (
            <>
              <EvaluationRulesConfig
                includeEvaluation={includeEvaluation}
                toggleIncludeEvaluation={() => setIncludeEvaluation((prev) => !prev)}
              />
              <SurveySwitch
                checked={!!includeWeight}
                onChange={() => setIncludeWeight((prev) => !prev)}
                label='Include question weights'
              />
            </>
          ) : null}
        </SwitchesContainer>
      </Wrapper>
    </Modal>
  );
};
