import { styled, TextField } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { CoreKpiSchema as validationSchema } from '../schemas/CoreKpiSchema';
import { FormField } from '../../../components/FormField/FormField';
import { CORE_KPI_FORMAT, CoreKpi } from '../../../types';
import { Textarea } from '../../../components/Textarea/Textarea';
import { ChipsSelectorV2 } from '../../../components/ButtonSelector/ChipsSelectorV2';
import { CoreKpiPayload } from '../../../queries/useCoreKpis';
import { Modal } from '../../../components/Modal/Modal';

interface Props {
  onClose: () => void;
  coreKpi?: CoreKpi | null;
  onSubmit: (payload: Partial<CoreKpiPayload>) => void;
  title: string;
  confirmBtnText: string;
}

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const EditCreateCoreKpi: FC<Props> = ({
  onClose,
  coreKpi,
  onSubmit,
  title,
  confirmBtnText,
}) => {
  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    dirty,
  } = useFormik<Partial<CoreKpiPayload>>({
    initialValues: {
      name: coreKpi?.name ?? '',
      description: coreKpi?.description ?? '',
      metric: coreKpi?.metric ?? '',
      format: coreKpi?.format ?? CORE_KPI_FORMAT.NUMBER,
    },
    validationSchema,
    onSubmit: (values) => {
      onClose();
      onSubmit(values);
    },
  });

  const handleError = (field: keyof CoreKpiPayload) => (touched[field] ? errors[field] : '');

  const formatOptions = useMemo(
    () => [
      { id: CORE_KPI_FORMAT.NUMBER, value: 'Number(#)' },
      { id: CORE_KPI_FORMAT.PERCENTAGE, value: 'Percentage(%)' },
      { id: CORE_KPI_FORMAT.TEXT, value: 'Text' },
    ],
    []
  );
  const selectedFormat = useMemo(
    () => formatOptions.find((o) => o.id === values.format),
    [values.format, formatOptions]
  );
  const onChangeFormat = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('format', '');
        return;
      }
      setFieldValue('format', option?.id);
    },
    [setFieldValue]
  );

  return (
    <Modal
      isOpen={true}
      title={title}
      onClose={onClose}
      onConfirm={handleSubmit}
      cancelBtnText='Cancel'
      confirmBtnText={confirmBtnText}
      isConfirmDisabled={!isValid || !dirty}
      contentContainerStyle={{ paddingBottom: '16px' }}
      infoText='This KPI is active and used in Surveys & Company Profile. Any modifications you make will have a global effect.'
    >
      <FormWrapper>
        <FormField label={'Name'} error={handleError('name')} required>
          <TextField
            name={'name'}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant='outlined'
          />
        </FormField>
        <FormField label={'Definition'} error={handleError('description')}>
          <Textarea
            style={{ height: '100px' }}
            name='description'
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder='Formula, description, etc.'
            maxLength={800}
          />
        </FormField>
        <FormField label={'Metric'} error={handleError('metric')} required>
          <TextField
            name={'metric'}
            value={values.metric}
            onChange={handleChange}
            onBlur={handleBlur}
            variant='outlined'
          />
        </FormField>
        <FormField label={'Unit'} error={handleError('format')} required>
          <ChipsSelectorV2
            options={formatOptions}
            selectedItem={selectedFormat ?? null}
            onSelect={onChangeFormat}
            buttonStyle={{
              width: '109px',
              minWidth: '109px',
              height: '32px',
            }}
          />
        </FormField>
      </FormWrapper>
    </Modal>
  );
};
