import { styled, useTheme } from '@mui/material';
import { ExclusionCondition } from '../../../../types';
import { Typography } from '../../../../components/Typography/Typography';
import { useAtom } from 'jotai';
import { companyExclusionIdsState, selectedConditionState } from '../../../../state/UIState';

const Container = styled('div')``;

const ConditionWrapper = styled('div')<{ isSelected: boolean }>`
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.accent[5] : '#fff')};
`;

const ConditionTextContainer = styled('div')`
  width: 86%;
`;

const ConditionContainer = styled('div')`
  margin-top: 8px;
  padding: 8px 24px 4px 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectedExclusionsNum = styled('div')`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.accent[50]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  conditions: ExclusionCondition[];
}

export const ConditionsList = ({ conditions }: Props) => {
  const { colors } = useTheme();
  const [selectedCondition, setSelectedCondition] = useAtom(selectedConditionState);

  const [companyExclusionIds] = useAtom(companyExclusionIdsState);

  return (
    <Container>
      {conditions?.map((condition) => {
        const exclusionsNum = condition?.exclusions?.length || 0;

        const exclusionsLabel = exclusionsNum === 1 ? 'exclusion' : 'exclusions';

        const conditionExclusionIds = condition?.exclusions?.map((exclusion) => exclusion.id);

        const selectedExclusionsNum =
          conditionExclusionIds?.filter((id) => companyExclusionIds?.includes(id))?.length || 0;

        return (
          <ConditionWrapper
            key={condition?.id}
            onClick={() => setSelectedCondition(condition)}
            isSelected={selectedCondition?.id === condition.id}
          >
            <ConditionContainer>
              <ConditionTextContainer>
                <Typography variant='subtitle2' color={colors.primary[90]}>
                  {condition.condition}
                </Typography>
                <Typography
                  variant='subtitle1'
                  color={colors.primary[70]}
                  style={{ marginTop: '4px' }}
                >
                  {exclusionsNum} {exclusionsLabel}
                </Typography>
              </ConditionTextContainer>
              {Boolean(selectedExclusionsNum) && (
                <SelectedExclusionsNum>
                  <Typography variant='tabLabel' color='#fff'>
                    {selectedExclusionsNum}
                  </Typography>
                </SelectedExclusionsNum>
              )}
            </ConditionContainer>
          </ConditionWrapper>
        );
      })}
    </Container>
  );
};
