import { CellContext } from '@tanstack/react-table';
import { CORE_KPI_FORMAT, CoreKpiRequestFrequency, KpisMatrixData } from '../../../../../types';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';
import { formatNumber } from '../../../../../utils/formatters';
import { NotesBoxPopup } from '../../../../../components/NotesBoxPopup/NotesBoxPopup';
import { CoreKpiInput } from './CoreKpiInput';

const CellWrapper = styled('div')<{ isTextFormat: boolean }>`
  display: flex;
  align-items: center;
  width: 124px;
  height: 30px;
  justify-content: ${({ isTextFormat }) => (isTextFormat ? 'flex-start' : 'flex-end')};
  &:hover {
    background-color: #f5f8ff;
  }
  position: relative;
`;

interface Props {
  isActive: boolean;
  info: CellContext<KpisMatrixData, any>;
  onDataCellChange: (
    val: string,
    id: number,
    period: string,
    format: CORE_KPI_FORMAT,
    notes?: string
  ) => void;
  period: string;
  setActiveCell: Dispatch<SetStateAction<string | null>>;
  companyKpiRequestFrequency: CoreKpiRequestFrequency | null;
  selectedFreqiuencyPreview: CoreKpiRequestFrequency | null;
}

export const KpiPeriodValueCell = ({
  isActive,
  info,
  onDataCellChange,
  period,
  setActiveCell,
  companyKpiRequestFrequency,
  selectedFreqiuencyPreview,
}: Props) => {
  const { colors } = useTheme();

  const [inputValue, setInputValue] = useState(info.getValue()?.value ?? '');

  const isTextFormat = useMemo(
    () => info.row.original.format === CORE_KPI_FORMAT.TEXT,
    [info.row.original.format]
  );

  if (isActive) {
    return (
      <CoreKpiInput
        value={info.getValue()?.value}
        onValueChange={(val) =>
          onDataCellChange(
            val,
            info.row.original.id,
            period,
            info.row.original.format,
            info.getValue()?.notes
          )
        }
        inputValue={inputValue}
        setInputValue={setInputValue}
        setActiveCell={setActiveCell}
        format={info.row.original.format}
      />
    );
  }

  return (
    <NotesBoxPopup
      notes={info.getValue()?.notes || ''}
      popupOffset={{ mainAxis: 8 }}
      popupStrategy='fixed'
    >
      <CellWrapper
        onMouseUp={() => {
          companyKpiRequestFrequency === selectedFreqiuencyPreview &&
            setActiveCell(() => `${period}-${info.row.original.id}`);
        }}
        isTextFormat={isTextFormat}
      >
        <Typography
          variant='numbers'
          color={colors.primary[90]}
          style={{
            textAlign: isTextFormat ? 'left' : 'right',
            paddingRight: '4px',
            paddingLeft: '4px',
            fontSize: '16px',
            background: info.getValue()?.notes ? colors.warning[20] : '',
          }}
        >
          {isTextFormat
            ? info.getValue()?.value
            : formatNumber(
                info.getValue()?.value === 0
                  ? '0'
                  : info.getValue()?.value
                  ? info.getValue()?.value
                  : '',
                {
                  suffix: info.row.original.format === CORE_KPI_FORMAT.PERCENTAGE ? '%' : undefined,
                }
              )}
        </Typography>
      </CellWrapper>
    </NotesBoxPopup>
  );
};
