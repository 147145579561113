import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

type QueryParamData = {
  param: string;
  value: any;
};

export const useQueryParams = () => {
  const navigate = useNavigate();

  const getParams = useCallback(
    () => qs.parse(new URLSearchParams(window.location.search).toString()),
    []
  );
  const getParam = useCallback((param: string) => getParams()[param], [getParams]);

  const setParam = useCallback(
    (paramData: QueryParamData, pathname?: string) => {
      const params = getParams();

      if (!paramData.value) {
        delete params?.[paramData.param];
        return navigate({
          pathname: pathname || window.location.pathname,
          search: qs.stringify(params),
        });
      }

      const newParamRecord = { [paramData.param]: paramData.value };

      return navigate({
        pathname: pathname || window.location.pathname,
        search: qs.stringify(!params ? newParamRecord : { ...params, ...newParamRecord }),
      });
    },
    [getParams, navigate]
  );

  const setMultipleParams = useCallback(
    (multipleParams: QueryParamData[], pathname?: string) => {
      let currentParams = getParams();

      multipleParams.forEach(({ param, value }) => {
        if (!value) {
          delete currentParams?.[param];
          return;
        }

        const newParamRecord = { [param]: value };
        currentParams = !currentParams ? newParamRecord : { ...currentParams, ...newParamRecord };
      });

      return navigate({
        pathname: pathname || window.location.pathname,
        search: qs.stringify(currentParams),
      });
    },
    [getParams, navigate]
  );

  const navigateAndPreserveParams = useCallback(
    (pathname?: string) => {
      const currentParams = getParams();

      navigate({
        pathname: pathname || window.location.pathname,
        search: qs.stringify(currentParams),
      });
    },
    [getParams, navigate]
  );

  return useMemo(
    () => ({
      params: getParams(),
      getParams,
      getParam,
      setParam,
      setMultipleParams,
      navigateAndPreserveParams,
    }),
    [getParam, getParams, navigateAndPreserveParams, setMultipleParams, setParam]
  );
};
