import { useMutation, useQuery } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';
import { LoadingId, Report } from '../types';
import { flattenTree } from '../utils/treeUtilities';
import { getReportProgressPercentage } from './useRequestGroups';

export const REPORTS = 'reports';

async function updateReport(report: Partial<Report>): Promise<Report> {
  const { id, ...payload } = report;
  const { data } = await Http.axios.patch<Partial<Report>, Report>(`/report/${id}`, payload);
  return data;
}

export function useUpdateReport() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateReport, {
    onMutate: () => {
      startLoading(LoadingId.updateReport);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update report' });
    },
    onSettled: () => {
      stopLoading(LoadingId.updateReport);
    },
  });
}

async function updateReportWithToken(report: Partial<Report>): Promise<Report> {
  const { data } = await Http.axios.patch<Partial<Report>, Report>(`/report/token`, report);
  return data;
}

export function useUpdateReportWithToken() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateReportWithToken, {
    onMutate: () => {
      startLoading(LoadingId.updateReport);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update report' });
    },
    onSettled: () => {
      stopLoading(LoadingId.updateReport);
    },
  });
}

export async function fetchReportByToken(token: string | null): Promise<Report | null> {
  if (!token) return null;
  const { data } = await Http.axios.get<Report>(`report/validate-report-token/${token}`);
  return data;
}

export async function fetchReportById(id: number): Promise<Report | null> {
  const { data } = await Http.axios.get<Report>(`report/${id}`);
  return data;
}

async function fetchReportsByCompanyId(companyId?: number): Promise<Report[]> {
  const { data } = await Http.axios.get<Report[]>(`/report/company/${companyId}`);
  return data.map((report) => {
    const { survey } = report;
    const { sections } = survey;
    const flatSurveys = flattenTree(sections);
    const sectionsMap = new Map(flatSurveys.map((section) => [section.id, section]));

    return {
      ...report,
      progress: report?.response ? getReportProgressPercentage(sectionsMap, report.response) : 0,
    };
  });
}

export function useReportsByCompanyId(companyId?: number) {
  return useQuery(
    [`${REPORTS}-${companyId}`, companyId],
    () => fetchReportsByCompanyId(companyId),
    {
      enabled: Boolean(companyId),
      staleTime: Infinity,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
}

export function useReportById(id: number) {
  return useQuery(`${REPORTS}-${id}`, () => fetchReportById(id), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}
