import { useMemo } from 'react';
import { useSurveys } from '../../../../../queries/useSurveys';
import {
  COMPANY_INFO_STEP,
  REQUEST_STEP,
  STEP_TYPE,
  SURVEY_STAGE,
  USER_ROLE,
  WorkflowStep,
} from '../../../../../types';
import {
  DUE_DILIGENCE_SURVEY_NAME,
  SASB_ASSESSMENT_NAME,
} from '../../../../../constants/constants';
import { useWorkflowStages } from '../../../../../queries/useWorkflowStages';
import { useUserRoles } from '../../../../../queries/useUsers';
import { REQUEST_STEPS } from './requestSteps';
import { COMPANY_INFO_STEPS } from './companyInfoSteps';

const requestSteps = [
  REQUEST_STEPS[REQUEST_STEP.RECURRING_KPI_UPDATE],
  REQUEST_STEPS[REQUEST_STEP.CHECKLIST_QUARTERLY],
  REQUEST_STEPS[REQUEST_STEP.YEARLY_ASSESSMENT],
];

const companyInfoSteps = [
  COMPANY_INFO_STEPS[COMPANY_INFO_STEP.COMPANY_INFORMATION],
  COMPANY_INFO_STEPS[COMPANY_INFO_STEP.ANALYST_RATING],
  COMPANY_INFO_STEPS[COMPANY_INFO_STEP.ESG_CONTACT],
];

export const useStepsByType = (stepType: STEP_TYPE) => {
  const { data: surveys } = useSurveys();

  const { data: stages } = useWorkflowStages();
  const { data: roles } = useUserRoles();

  const allCreatedSteps = useMemo(() => stages?.flatMap((stage) => stage.steps), [stages]);

  const allSteps: Partial<WorkflowStep>[] = useMemo(() => {
    const adminRole = roles?.find((role) => role.name === USER_ROLE.ADMIN);
    if (stepType === STEP_TYPE.ASSESSMENT) {
      const assessmentSteps =
        surveys
          ?.filter(
            (s) =>
              s.name !== DUE_DILIGENCE_SURVEY_NAME &&
              s.stage !== SURVEY_STAGE.CHECKLIST_ANNUALLY &&
              s.stage !== SURVEY_STAGE.CHECKLIST_QUARTERLY &&
              s.stage !== SURVEY_STAGE.CORE_KPI &&
              !s.includeWeight
          )
          ?.map((survey) => {
            return {
              type: STEP_TYPE.ASSESSMENT,
              name: survey.name,
              survey: survey,
              accessRoles: adminRole ? [adminRole] : [],
            };
          }) || [];

      const SASBAssessmentStep = {
        type: STEP_TYPE.ASSESSMENT,
        name: SASB_ASSESSMENT_NAME,
        accessRoles: adminRole ? [adminRole] : [],
      };

      return [...assessmentSteps, SASBAssessmentStep];
    }

    if (stepType === STEP_TYPE.REQUEST) {
      return requestSteps?.map((step) => {
        return {
          ...step,
          accessRoles: adminRole ? [adminRole] : [],
        };
      });
    }

    if (stepType === STEP_TYPE.COMPANY_INFO) {
      return companyInfoSteps?.map((step) => {
        return {
          ...step,
          accessRoles: adminRole ? [adminRole] : [],
        };
      });
    }

    if (stepType === STEP_TYPE.SCORE) {
      return (
        surveys
          ?.filter((s) => s.includeWeight)
          ?.map((survey) => {
            return {
              type: STEP_TYPE.SCORE,
              name: survey.name,
              survey: survey,
              accessRoles: adminRole ? [adminRole] : [],
            };
          }) || []
      );
    }

    return [];
  }, [roles, stepType, surveys]);

  const steps = useMemo(() => {
    const allStepsNames = allCreatedSteps?.map((s) => s.name) || [];

    return allSteps?.filter(
      (step) => !allStepsNames?.includes(step?.name || '')
    ) as Partial<WorkflowStep>[];
  }, [allCreatedSteps, allSteps]);

  return steps || [];
};
