import { Fade, styled, useTheme } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import { useMemo } from 'react';
import {
  HEADER_ROW_ID,
  KPI_FORMAT_COLUMN_ID,
  KPI_VALUE_COLUMN_ID,
} from '../../../../../../../constants/defaultValues';
import { CORE_KPI_FORMAT } from '../../../../../../../types';
import { Tooltip } from '../../../../../../../components/Tooltip/Tooltip';
import { NotesBoxPopup } from '../../../../../../../components/NotesBoxPopup/NotesBoxPopup';
import { Typography } from '../../../../../../../components/Typography/Typography';
import { getCoreKPICellValue } from '../../../../../../../utils/getCoreKpiCellValue';

const ValueWrapper = styled('div')`
  padding: 0 8px;
`;

interface Props {
  isCoreKpiValue: boolean;
  info: CellContext<Record<string, any>, any>;
  questionId: string;
}

export const CoreKpiValueCell = ({ isCoreKpiValue, info }: Props) => {
  const { colors } = useTheme();

  const isTextFormat = useMemo(
    () => info.row.original[KPI_FORMAT_COLUMN_ID] === CORE_KPI_FORMAT.TEXT,
    [info.row.original]
  );

  return (
    <Tooltip
      title={isCoreKpiValue ? '' : info.getValue()}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      placement='bottom-start'
    >
      <NotesBoxPopup notes={isCoreKpiValue ? info.getValue()?.notes : ''} popupStrategy='fixed'>
        <ValueWrapper style={{ textAlign: isCoreKpiValue && !isTextFormat ? 'right' : 'left' }}>
          <Typography
            variant={info.row.original.id === HEADER_ROW_ID ? 'overline' : 'body'}
            color={info.row.original.id === HEADER_ROW_ID ? colors.primary[70] : colors.primary[90]}
            style={{
              background:
                info.getValue()?.notes && isCoreKpiValue ? colors.warning[20] : 'transparent',
              display: isCoreKpiValue ? 'inline' : 'block',
            }}
          >
            {isCoreKpiValue
              ? getCoreKPICellValue(
                  info.getValue()?.value,
                  info.column.id === KPI_VALUE_COLUMN_ID,
                  info.row.original.kpi_format_column
                )
              : info.getValue()}
          </Typography>
        </ValueWrapper>
      </NotesBoxPopup>
    </Tooltip>
  );
};
