// import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';
import { useAppConfig } from './queries/useAppConfig';
import useInitUser from './hooks/useInitUser';

export const SecureRoutes = () => {
  useInitUser();
  useAppConfig();

  return <Outlet />;
};
