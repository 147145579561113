import { useEffect, useRef, useState } from 'react';

type OnIntersection = (isIntersecting: boolean, ob: IntersectionObserver) => boolean | void;

const DefaultOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: 0.15,
};

const DefaultOnIntersection: OnIntersection = (isIntersecting) => {
  if (isIntersecting) return false;
};

export function useIntersection(
  onIntersection: OnIntersection = DefaultOnIntersection,
  options: IntersectionObserverInit = DefaultOptions
) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const elemRef = useRef<null | Element | undefined>(null);
  const setElem = (elem: never) => (elemRef.current = elem);

  useEffect(() => {
    if (!elemRef.current) return;

    const ob = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (onIntersection(isIntersecting, ob) === false) {
          ob.disconnect();
        }

        setIsIntersecting(isIntersecting);
      },
      { ...options }
    );

    ob.observe(elemRef.current);

    return () => {
      ob.disconnect();
    };
  }, [onIntersection, options]);

  return [isIntersecting, setElem] as const;
}

export const useIntersection2 = (
  onIntersection: OnIntersection = DefaultOnIntersection,
  options: IntersectionObserverInit = DefaultOptions
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;

    const ob = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsIntersecting(entry.isIntersecting);
      },
      { ...options }
    );

    const currentRef = ref.current;

    if (currentRef) {
      ob.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        ob.disconnect();
      }
    };
  }, [isIntersecting, onIntersection, options, ref]);

  return [isIntersecting, ref];
};
