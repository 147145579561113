import { Dispatch, useCallback } from 'react';
import { ChipsSelectorV2 } from '../ButtonSelector/ChipsSelectorV2';
import { QuestionOption, Response, SelectItem, SurveyQuestion } from '../../types';
import { SetStateAction } from 'jotai';

interface Props {
  question: SurveyQuestion;
  response: QuestionOption | null;
  setResponses?: Dispatch<SetStateAction<Record<string, Response>>>;
}

export const YesNoInput = ({ question, response, setResponses }: Props) => {
  const options = question.options.map((o) => ({ value: o.name, name: o.name, id: o.id }));

  const selectedItem = response ? { value: response.name, id: response.id } : null;

  const onClick = useCallback(
    (selectedOption: SelectItem) => {
      if (!setResponses) return;
      const option = question.options.find((o) => o.id === selectedOption.id) as QuestionOption;

      setResponses((oldResponses) => {
        return {
          ...oldResponses,
          [question.id]: option,
        };
      });
    },
    [question.id, question.options, setResponses]
  );

  return <ChipsSelectorV2 options={options} selectedItem={selectedItem} onSelect={onClick} />;
};
