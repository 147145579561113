/* istanbul ignore file */
import * as yup from 'yup';
import { requiredMsg } from '../../../utils/validationMessages';

export const CoreKpiSchema = yup.object().shape({
  name: yup.string().required(requiredMsg('Name')),
  description: yup.string(),
  metric: yup.string().required(requiredMsg('Metric')),
  format: yup.string().required(requiredMsg('Format')),
});
