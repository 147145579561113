import { useAtom } from 'jotai';
import { useWorkflowStages } from '../../queries/useWorkflowStages';
import { ScreeningFlow } from '../../components/ScreeningFlow/ScreeningFlow';
import { activeWorkflowCompanyType } from '../../state/UIState';
import { useEffect } from 'react';
import { getCompanyTypeBasedOnPath } from '../../utils/getCompanyTypeBasedOnPath';
import { useNavigate, useParams } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { SingleCompanyHeader } from '../../components/SingleCompanyHeader';
import { companyDefaultData } from '../../mockedData/mockedData';
import { Button, styled } from '@mui/material';
import { ReactComponent as EyeCrossed } from '../../assets/icons/eye-crossed-blue.svg';
import { COMPANY_TYPE } from '../../types';

const Container = styled('div')`
  position: relative;
`;

const Overlay = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: not-allowed;
`;

const ButtonWrapper = styled('div')`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const EyeCrossedWhite = styled(EyeCrossed)`
  path {
    fill: #fff;
  }
`;

export const WorkflowPreview = () => {
  const [activeCompanyType, setActiveCompanyType] = useAtom(activeWorkflowCompanyType);
  const { data: stages } = useWorkflowStages();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setActiveCompanyType(getCompanyTypeBasedOnPath(params.companyType || ROUTES.CONTROL_WORKFLOW));

    return () => {
      setActiveCompanyType(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <SingleCompanyHeader company={companyDefaultData} />
      <ScreeningFlow
        stages={stages || []}
        companyType={activeCompanyType || COMPANY_TYPE.CONTROLLED}
      />
      <Overlay />
      <ButtonWrapper>
        <Button
          onClick={() => navigate(`/${ROUTES.WORKFLOWS}/${params.companyType}`)}
          startIcon={<EyeCrossedWhite />}
          variant='contained'
          style={{ width: '162px' }}
        >
          Close Preview
        </Button>
      </ButtonWrapper>
    </Container>
  );
};
