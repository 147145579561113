import { useAtom } from 'jotai';
import { FC, useMemo } from 'react';
import { ReviewTabConfig, SurveyQuestion } from '../../../../types';
import { responsesState } from '../../../../state/UIState';
import { QuestionInstructions } from '../../../../components/QuestionInstructions';
import { ReviewBox } from './ReviewBox';
import { ReviewReportTabs } from '../../../../components/ReviewReportTabs/ReviewReportTabs';

interface Props {
  question: SurveyQuestion;
}

export const ReviewTabs: FC<Props> = ({ question }) => {
  const [responses] = useAtom(responsesState);

  const tabsConfig: ReviewTabConfig[] = useMemo(() => {
    const evaluationTabConfig = [
      {
        tab: 'YOUR EVALUATION',
        tabComponent: <ReviewBox question={question} />,
      },
    ];

    if (question?.includeInstructions) {
      return [
        ...evaluationTabConfig,
        {
          tab: 'INSTRUCTIONS',
          tabComponent: <QuestionInstructions instructions={question.instructions} />,
        },
      ];
    }

    return evaluationTabConfig;
  }, [question]);

  if (!responses[question.id]) return null;

  return <ReviewReportTabs tabsConfig={tabsConfig} />;
};
