import { COMPANY_INFO_STEP, STEP_TYPE } from '../../../../../types';

export const COMPANY_INFO_STEPS = {
  [COMPANY_INFO_STEP.COMPANY_INFORMATION]: {
    type: STEP_TYPE.COMPANY_INFO,
    name: 'Enter Company Information',
    companyInfoStep: COMPANY_INFO_STEP.COMPANY_INFORMATION,
  },
  [COMPANY_INFO_STEP.ANALYST_RATING]: {
    type: STEP_TYPE.COMPANY_INFO,
    name: 'Analyst Rating',
    companyInfoStep: COMPANY_INFO_STEP.ANALYST_RATING,
  },
  [COMPANY_INFO_STEP.ESG_CONTACT]: {
    type: STEP_TYPE.COMPANY_INFO,
    name: 'Company’s ESG Contact',
    companyInfoStep: COMPANY_INFO_STEP.ESG_CONTACT,
  },
};
