import { styled } from '@mui/material';
import { useAtom } from 'jotai';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { QuestionOption, QUESTION_TYPE, SurveyQuestion } from '../../../../types';
import {
  activeQuestionIdState,
  activeSectionIdState,
  activeSurveyState,
  responsesState,
} from '../../../../state/UIState';

import { YesNoInput } from '../../../../components/YesNoInput/YesNoInput';
import { TableQuestion } from './TableQuestion/TableQuestion';
import { SingleOptionQuestion } from './SingleOptionQuestion/SingleOptionQuestion';
import { MultiOptionsQuestion } from './MultiOptionsQuestion/MultiOptionsQuestion';
import { OptionListWrapper } from './common';
import { useIsQuestionDisabled } from './useIsQuestionDisabled';

interface Props {
  question: SurveyQuestion;
}

const Textarea = styled('textarea')`
  width: 100%;
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  resize: none;
  background-color: transparent;
  outline: 0px none transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.text.font.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.primary[90]};
  padding-bottom: 5px;
  ::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: ${({ theme }) => theme.text.font.fontFamilyRegular};
    color: ${({ theme }) => theme.colors.primary[70]};
  }
`;

export const QuestionInput: FC<Props> = ({ question }) => {
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);
  const [activeSurvey] = useAtom(activeSurveyState);
  const [responses, setResponses] = useAtom(responsesState);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textValue, setTextValue] = useState<string>((responses[question.id] as string) ?? '');
  const isQuestionDisabled = useIsQuestionDisabled();

  const adjustHeight = () => {
    if (!textAreaRef.current) return;
    textAreaRef.current.style.height = 'auto'; // Reset the height to auto
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set the height to the scroll height
  };

  useEffect(() => {
    adjustHeight(); // Adjust the height initially when the component mounts
  }, []);

  const handleTextAreaChange = useCallback((evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    adjustHeight();
    const val = evt.target?.value;
    setTextValue(val);
  }, []);

  const handleTextAreaBlur = useCallback(() => {
    setResponses((oldResponses) => {
      return {
        ...oldResponses,
        [question.id]: textValue || null,
      };
    });
  }, [question.id, setResponses, textValue]);

  const onFocus = useCallback(() => {
    setActiveQuestionId(question.id);
    setActiveSectionId(question.sectionId);
  }, [question.id, question.sectionId, setActiveQuestionId, setActiveSectionId]);

  if (question.type === QUESTION_TYPE.TEXT) {
    return (
      <Textarea
        placeholder='Start typing here...'
        ref={textAreaRef}
        value={textValue}
        onChange={handleTextAreaChange}
        onFocus={onFocus}
        rows={1}
        onBlur={handleTextAreaBlur}
        disabled={isQuestionDisabled}
      />
    );
  }

  if (question.type === QUESTION_TYPE.SINGLE) {
    return <SingleOptionQuestion question={question} />;
  }

  if (question.type === QUESTION_TYPE.MULTI) {
    return <MultiOptionsQuestion question={question} />;
  }

  if (question.type === QUESTION_TYPE.TABLE) {
    return <TableQuestion question={question} stage={activeSurvey?.stage} />;
  }

  if (question.type === QUESTION_TYPE.YES_NO) {
    return (
      <OptionListWrapper>
        <YesNoInput
          question={question}
          response={responses[question.id] as QuestionOption | null}
          setResponses={setResponses}
        />
      </OptionListWrapper>
    );
  }

  return null;
};
