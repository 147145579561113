import { Chip, Menu, MenuItem, styled, useTheme } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Company } from '../../../types';
import { CompanyLogo } from '../../CompanyLogo/CompanyLogo';
import { Typography } from '../../Typography/Typography';

interface Props {
  companies: Partial<Company>[];
}

const Wrapper = styled('div')`
  display: flex;
  gap: 4px;
`;

export const CompaniesCell = ({ companies }: Props) => {
  const { colors } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const companiesToShow = companies.length > 3 ? companies.slice(0, 3) : companies;
  const navigate = useNavigate();
  const restCount = companies.length > 3 ? companies.length - 3 : 0;
  const restCompanies = useMemo(
    () => (restCount > 0 ? companies.slice(3) : []),
    [companies, restCount]
  );

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      {companiesToShow.map((c) => (
        <Chip
          avatar={
            c.logoUrl ? <CompanyLogo src={c.logoUrl} style={{ marginRight: '4px' }} /> : undefined
          }
          key={c.name}
          label={c.name}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/companies/${c.id}`);
          }}
        />
      ))}
      {Boolean(restCount) && (
        <>
          <Chip
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
            style={{
              color: colors.accent[50],
              backgroundColor: open ? colors.primary[30] : colors.primary[20],
            }}
            label={`+${restCount}`}
          />
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              handleCloseMenu();
            }}
            PaperProps={{ style: { width: '234px' } }}
          >
            {restCompanies.map((c) => (
              <MenuItem
                key={c.name}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/companies/${c.id}`);
                }}
              >
                {c.logoUrl && <CompanyLogo size='medium' hasBorder src={c.logoUrl ?? ''} />}
                <Typography variant='body' color={colors.primary[90]} style={{ marginLeft: '4px' }}>
                  {c.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Wrapper>
  );
};
