import { useMemo } from 'react';
import { COMPANY_TYPE } from '../types';
import { useWorkflowsByCompanyType } from '../queries/useWorkflows';

export const useAllStepsByCompanyType = (companyType?: COMPANY_TYPE | null) => {
  const { data: workflow } = useWorkflowsByCompanyType(companyType);

  return useMemo(() => {
    return workflow?.config?.stages?.flatMap((stage) => stage.steps);
  }, [workflow?.config?.stages]);
};
