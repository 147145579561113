import { styled } from '@mui/material';
import { Table as TTable, flexRender, Row as Trow } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { ColumnMeta } from '../../../../types';
import { useAtom } from 'jotai';
import { sidebarMenuPinnedState } from '../../../../state/sidebarMenuPinnedState';
import {
  MAIN_SIDEBAR_MENU_EXPANDED_WIDTH,
  MAIN_SIDEBAR_MENU_WIDTH,
} from '../../../../constants/layoutSizes';
import { internalSurveyState } from '../../../../state/internalSurveyState';

interface Props {
  table: TTable<any>;
  onRowClick?: (row: Trow<any>) => void;
  tHeaderStyle?: React.CSSProperties;
  tableStyle?: React.CSSProperties;
  tdStyle?: React.CSSProperties;
  customHeaderCTA?: React.ReactNode;
}

const Wrapper = styled('div')<{ width: string }>`
  // viewport width minus sidebar width minus padding
  max-width: ${({ width }) => `calc(100vw - 340px - 80px - ${width})`};
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled('table')`
  border-spacing: 0;
  border-collapse: separate;

  tr:not(:first-of-type) td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary[20]};
  }
  td:first-of-type {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  tr:first-of-type {
    position: sticky;
    top: 0;
    td {
      background-color: ${({ theme }) => theme.colors.primary[10]};
    }
    td:first-of-type {
      z-index: 101;
    }
  }
  tr:first-of-type {
    z-index: 10;
  }
`;
const Tbody = styled('tbody')`
  padding: 0 19px;
`;
const Tr = styled('tr')<{ selectable?: boolean; disabled?: boolean }>`
  height: 44px;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  ${({ selectable }) => (selectable ? 'cursor: pointer;' : '')}
  &.selected-row td {
    background-color: ${({ theme }) => theme.colors.accent[5]};
    border-top: 1px solid ${({ theme }) => theme.colors.accent[50]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.accent[50]};
  }
  &.selected-row td:first-of-type {
    border-left: 1px solid ${({ theme }) => theme.colors.accent[50]};
  }
  &.selected-row td:last-of-type {
    border-right: 1px solid ${({ theme }) => theme.colors.accent[50]};
  }
  &.no-data {
    background-color: ${({ theme }) => theme.colors.primary[5]};
    td {
      border-top: 1px solid ${({ theme }) => theme.colors.primary[20]};
    }
  }
`;

const Td = styled('td')<{
  width?: string;
  minWidth?: string;
  maxWidth?: string;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  text-align: left;
  position: relative;
  padding: 5px 8px;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  background-color: ${({ theme }) => theme.colors.primary[0]};
`;

export const TableInput = ({ table, onRowClick, tableStyle, tdStyle }: Props) => {
  const [isSideMenuPinned] = useAtom(sidebarMenuPinnedState);
  const [isInternalSurvey] = useAtom(internalSurveyState);

  const onRowSelect = useCallback(
    (row: Trow<any>) => {
      if (onRowClick) onRowClick(row);
      if (row.getCanSelect()) {
        if (row.original.disabled) return;
        // not allowed unselecting for single select for now.
        if (row.getIsSelected() && !row.getCanMultiSelect()) return;
        const toggleSelected = row.getToggleSelectedHandler();
        toggleSelected(row);
      }
    },
    [onRowClick]
  );

  const tableWidth = useMemo(() => {
    if (!isInternalSurvey) return '0px';

    return isSideMenuPinned
      ? `${MAIN_SIDEBAR_MENU_EXPANDED_WIDTH}px`
      : `${MAIN_SIDEBAR_MENU_WIDTH}px`;
  }, [isInternalSurvey, isSideMenuPinned]);

  return (
    <Wrapper width={tableWidth}>
      <StyledTable style={tableStyle}>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              disabled={(row.getCanSelect() || row.getCanMultiSelect()) && row.original.disabled}
              key={row.id}
              onClick={() => onRowSelect(row)}
              selectable={row.getCanSelect() || !!onRowClick}
              className={row.getIsSelected() && !row.getCanMultiSelect() ? 'selected-row' : ''}
            >
              {row.getVisibleCells().map((cell) => (
                <Td
                  style={tdStyle}
                  key={cell.id}
                  width={(cell.column.columnDef.meta as ColumnMeta)?.width}
                  minWidth={(cell.column.columnDef.meta as ColumnMeta)?.minWidth}
                  maxWidth={(cell.column.columnDef.meta as ColumnMeta)?.maxWidth}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </StyledTable>
    </Wrapper>
  );
};
