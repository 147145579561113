import { Text } from './structure';

export const text: Text = {
  font: {
    fontFamilyRegular: 'PPNeueMontreal-Regular',
    fontFamilyMedium: 'PPNeueMontreal-Medium',
    fontFamilySemiBold: 'PPNeueMontreal-SemiBold',
    fontInconsolataRegular: 'Inconsolata-Regular',
    fontInconsolataSemiBold: 'Inconsolata-SemiBold',
  },
  weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
  },
  size: {
    extraSmall: 10,
    small: 12,
    mediumSmall: 14,
    medium: 16,
    mediumLarge: 20,
    large: 24,
    extraLarge: 28,
    extraExtraLarge: 32,
  },
  lineHeight: {
    extraSmall: '15px',
    small: '18px',
    mediumSmall: '21px',
    medium: '24px',
    mediumLarge: '30px',
    large: '36px',
    extraLarge: '42px',
    extraExtraLarge: '48px',
  },
  letterSpace: {
    regular: '0px',
    large: '0.25px',
  },
};
