import { Button, styled } from '@mui/material';
import { Outlet, useNavigate } from 'react-router';
import { useAtom, useAtomValue } from 'jotai';
import { LoadingBar } from '../../components/LoadingBar/LoadingBar';
import { ToastMessage } from '../../components/ToastMessage/ToastMessage';
import {
  MAIN_SIDEBAR_MENU_EXPANDED_WIDTH,
  MAIN_SIDEBAR_MENU_WIDTH,
} from '../../constants/layoutSizes';
import {
  configToastMessageState,
  impersonateRoleState,
  isOpenToastMessageState,
  isUserLoadingState,
  userState,
} from '../../state/UIState';
import { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { sidebarMenuPinnedState } from '../../state/sidebarMenuPinnedState';
import useInitUser from '../../hooks/useInitUser';
import { Unauthorized } from '../Unauthorized/Unauthorized';
import { ROUTES } from '../../constants/routes';
import { EsgCompassSidebarMenu } from './EsgCompassSidebarMenu/EsgCompassSidebarMenu';

const Wrapper = styled('div')`
  display: flex;
  height: 100vh;
  position: relative;
`;

const Content = styled(motion.div)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary[5]};
  overflow: auto;
  padding-left: ${MAIN_SIDEBAR_MENU_WIDTH}px;
`;

const LoadingBarWrapper = styled('div')`
  width: calc(100% - ${MAIN_SIDEBAR_MENU_WIDTH}px);
  position: absolute;
  top: 0;
`;

export const Layout = () => {
  useInitUser();
  const [config] = useAtom(configToastMessageState);
  const [isOpen, setIsOpen] = useAtom(isOpenToastMessageState);
  const navigate = useNavigate();
  const isLoadingUser = useAtomValue(isUserLoadingState);
  const userLocalState = useAtomValue(userState);
  const [impersonateRole, setImpersonateRole] = useAtom(impersonateRoleState);

  const [isSideMenuExpanded, setIsSideMenuExpanded] = useState(false);
  const [isSideMenuPinned, setIsSideMenuPinned] = useAtom(sidebarMenuPinnedState);

  const controls = useAnimation();

  const onPinToggle = () => {
    if (isSideMenuPinned) {
      setIsSideMenuPinned(false);
      controls.start({
        paddingLeft: ` ${MAIN_SIDEBAR_MENU_WIDTH}px`,
        transition: { duration: 0.3 },
      });
      return;
    }

    setIsSideMenuPinned(true);

    controls.start({
      paddingLeft: ` ${MAIN_SIDEBAR_MENU_EXPANDED_WIDTH}px`,
      transition: { duration: 0.3 },
    });
  };

  const onStopImpersonation = () => {
    localStorage.removeItem('impersonate');
    setImpersonateRole(null);
    navigate(`/${ROUTES.USER_MANAGEMENT}`);
  };

  if (isLoadingUser) return null;
  if (userLocalState && !userLocalState.role) return <Unauthorized />;

  return (
    <Wrapper>
      <EsgCompassSidebarMenu
        setIsExpanded={setIsSideMenuExpanded}
        isExpanded={isSideMenuExpanded}
        onPinToggle={onPinToggle}
        isPinned={isSideMenuPinned}
      />
      <Content animate={controls}>
        {config && (
          <ToastMessage {...config} isOpen={isOpen} handleClose={() => setIsOpen(false)} />
        )}
        <LoadingBarWrapper>
          <LoadingBar />
        </LoadingBarWrapper>
        <Outlet />
      </Content>
      {impersonateRole && (
        <Button
          variant='contained'
          style={{
            width: '179px',
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            zIndex: 1000,
          }}
          onClick={onStopImpersonation}
        >
          Stop Impersonation
        </Button>
      )}
    </Wrapper>
  );
};