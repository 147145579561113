import { styled } from '@mui/material';
import { QUESTION_TYPE, Response } from '../../../types';
import { AnswerCell } from '../../../components/Table/CellRenderers/AnswerCell';

const AnswerCellWrapper = styled('div')`
  width: auto;
  min-width: 600px;
  display: flex;
  align-items: center;
  gap: 12px;
  &&& p {
    white-space: normal;
    text-overflow: clip;
  }
`;

const AnswerWrapper = styled('div')`
  flex: 1;
`;

interface Props {
  type: QUESTION_TYPE;
  overrideAnswer?: Response;
  answer: Response;
  overrideReason?: string;
}

export const AssessmentAnswerCell = ({ overrideAnswer, type, answer, overrideReason }: Props) => {
  return (
    <AnswerCellWrapper>
      <AnswerWrapper style={{ flex: overrideAnswer ? '0 1 auto' : 1 }}>
        <AnswerCell answer={answer} type={type} isOverridden={Boolean(overrideAnswer)} />
      </AnswerWrapper>
      {overrideAnswer && (
        <AnswerCell
          answer={overrideAnswer}
          type={type}
          isOverrideAnswer
          overrideReason={overrideReason}
        />
      )}
    </AnswerCellWrapper>
  );
};
