import { StepperLayoutSideProgress } from './StepperLayoutSideProgress';
import { Step } from '../../types';
import { ReactNode } from 'react';
import { LayoutWithActionsFooter } from '../LayoutWithActionsFooter/LayoutWithActionsFooter';

interface Props {
  onBackClick?: () => void;
  headerTitle: string;
  progressBarText: string;
  progress: number;
  steps: Step[];
  activeStep: number;
  onClickStepNavigate: (step: number) => void;
  children: ReactNode;
  controlButtons?: ReactNode;
}

export const StepperLayout = ({
  onBackClick,
  headerTitle,
  progressBarText,
  progress,
  steps,
  activeStep,
  onClickStepNavigate,
  children,
  controlButtons,
}: Props) => {
  return (
    <LayoutWithActionsFooter
      sideBar={
        <StepperLayoutSideProgress
          text={progressBarText}
          progress={progress}
          steps={steps}
          activeStep={activeStep}
          onClickStepNavigate={onClickStepNavigate}
        />
      }
      headerTitle={headerTitle}
      onBackClick={onBackClick}
      controlButtons={controlButtons}
    >
      {children}
    </LayoutWithActionsFooter>
  );
};
