import { ReviewOption } from '../../../../../../ReviewOption';
import { REVIEW_TYPE, TReviewOptionConfig } from '../../../../../../../types';
import { OverrideOption } from './OverrideOption';
import { useAtom, useSetAtom } from 'jotai';
import {
  esgOpportunitiesLevelOverrideState,
  esgRiskLevelOverrideState,
  selectedReviewOptionState,
} from '../../../../../../../state/UIState';

interface Props {
  reviewOptionConfig: TReviewOptionConfig;
  isEvaluated: boolean;
}

export const AnalystRatingReviewOption = ({ reviewOptionConfig, isEvaluated }: Props) => {
  const [selectedOption, setSelectedOption] = useAtom(selectedReviewOptionState);
  const isSelected = selectedOption === reviewOptionConfig.type;

  const setEsgRiskLevelOverride = useSetAtom(esgRiskLevelOverrideState);
  const setEsgOpportunitiesLevelOverride = useSetAtom(esgOpportunitiesLevelOverrideState);

  const onSelectReviewOption = () => {
    setEsgOpportunitiesLevelOverride(null);
    setEsgRiskLevelOverride(null);

    if (isSelected) {
      setSelectedOption(null);
      return;
    }

    setSelectedOption(reviewOptionConfig.type);
  };

  if (reviewOptionConfig.type === REVIEW_TYPE.OVERRIDE) {
    return (
      <OverrideOption
        isEvaluated={isEvaluated}
        reviewOption={reviewOptionConfig}
        isSelected={isSelected}
        onToggleSelected={onSelectReviewOption}
      />
    );
  }

  return (
    <ReviewOption
      isSelected={isSelected}
      isEvaluated={isEvaluated}
      onSelect={onSelectReviewOption}
      optionColor={reviewOptionConfig.color}
      label={reviewOptionConfig.name}
    />
  );
};
