import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ChecklistCategory, LoadingId } from '../types';
import { Http } from '../services/Http';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';

export const CHECKLIST_CATEGORIES = 'checklistCategories';

async function fetchChecklistCategories(): Promise<ChecklistCategory[]> {
  const { data } = await Http.axios.get<ChecklistCategory[]>(`/check-list-category`);
  return data.map((category) => ({
    ...category,
    items: category?.items?.sort((a, b) => a.order - b.order),
  }));
}

export function useChecklistCategories() {
  return useQuery(CHECKLIST_CATEGORIES, () => fetchChecklistCategories(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function createChecklistCategory(
  payload: Partial<ChecklistCategory>
): Promise<ChecklistCategory> {
  const { data } = await Http.axios.post<Partial<ChecklistCategory>, ChecklistCategory>(
    `/check-list-category`,
    payload
  );
  return data;
}

export function useCreateChecklistCategory() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  return useMutation(createChecklistCategory, {
    onMutate: async () => {
      startLoading(LoadingId.createChecklistCategory);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create checklist category' });
    },
    onSuccess: (newCoreKpiCategory) => {
      queryClient.setQueryData(
        CHECKLIST_CATEGORIES,
        (oldCategories: ChecklistCategory[] | undefined) =>
          oldCategories ? [...oldCategories, newCoreKpiCategory] : [newCoreKpiCategory]
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(CHECKLIST_CATEGORIES);
      stopLoading(LoadingId.createChecklistCategory);
    },
  });
}

async function updateCheckistCategory(
  coreKpiCategory: Partial<ChecklistCategory>
): Promise<ChecklistCategory> {
  const { id, ...payload } = coreKpiCategory;
  const { data } = await Http.axios.patch<Partial<ChecklistCategory>, ChecklistCategory>(
    `/check-list-category/${id}`,
    payload
  );
  return data;
}

export function useUpdateChecklistCategory() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateCheckistCategory, {
    onMutate: async () => {
      startLoading(LoadingId.updateChecklistCategory);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update checklist category' });
    },
    onSuccess: (updatedCoreKpiCategory) => {
      queryClient.setQueryData(
        CHECKLIST_CATEGORIES,
        (oldCategories: ChecklistCategory[] | undefined) =>
          oldCategories
            ? [
                ...oldCategories.map((category) =>
                  category.id === updatedCoreKpiCategory.id ? updatedCoreKpiCategory : category
                ),
              ]
            : [updatedCoreKpiCategory]
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(CHECKLIST_CATEGORIES);
      stopLoading(LoadingId.updateChecklistCategory);
    },
  });
}
