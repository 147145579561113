import { Button, styled } from '@mui/material';
import { useMemo } from 'react';
import { QUESTION_TYPE, QUESTION_MANDATORY_OPTIONS, SelectItem } from '../../../../../types';
import { ReactComponent as TextIcon } from '../../../../../assets/icons/text.svg';
import { ReactComponent as SingleIcon } from '../../../../../assets/icons/single.svg';
import { ReactComponent as MultiIcon } from '../../../../../assets/icons/multi.svg';
import { ReactComponent as ActiveTextIcon } from '../../../../../assets/icons/text-blue.svg';
import { ReactComponent as ActiveSingleIcon } from '../../../../../assets/icons/single-blue.svg';
import { ChipsSelectorV2 } from '../../../../../components/ButtonSelector/ChipsSelectorV2';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const TypeSelectorWrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

const ActiveMultiIcon = styled(MultiIcon)`
  path {
    fill: ${({ theme }) => theme.colors.accent[50]};
  }
`;

const ButtonChip = styled('div')<{ isSelected?: boolean }>`
  .MuiButton-outlined {
    border: 1px solid
      ${({ theme, isSelected }) =>
        isSelected ? theme.colors.accent[50] : theme.colors.primary[30]};
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.accent[50] : theme.colors.primary[90]};
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.accent[5] : 'transparent'};
  }
  .MuiButton-outlined:hover {
    border: 1px solid ${({ theme }) => theme.colors.accent[50]};
    color: ${({ theme }) => theme.colors.accent[50]};
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.accent[5] : 'transparent'};
  }
`;

type QuestionTypeItem = SelectItem & {
  icon: JSX.Element;
  activeIcon: JSX.Element;
};

interface Props {
  onChangeType: (item: SelectItem) => void;
  questionType?: QUESTION_TYPE;
  isSubQuestion?: boolean;
  isRequired?: boolean;
  onChangeQuestionMandatory: (item: SelectItem) => void;
}

export const TypeSelector = ({
  onChangeType,
  onChangeQuestionMandatory,
  questionType,
  isRequired,
}: Props) => {
  const options = useMemo<QuestionTypeItem[]>(() => {
    return [
      { id: QUESTION_TYPE.TEXT, value: 'Text', icon: <TextIcon />, activeIcon: <ActiveTextIcon /> },
      {
        id: QUESTION_TYPE.SINGLE,
        value: 'Single',
        icon: <SingleIcon />,
        activeIcon: <ActiveSingleIcon />,
      },
      {
        id: QUESTION_TYPE.MULTI,
        value: 'Multi',
        icon: <MultiIcon />,
        activeIcon: <ActiveMultiIcon />,
      },
    ];
  }, []);

  const requiredOrNotOptions = [
    {
      id: QUESTION_MANDATORY_OPTIONS.REQUIRED,
      value: QUESTION_MANDATORY_OPTIONS.REQUIRED,
    },
    {
      id: QUESTION_MANDATORY_OPTIONS.NOT_REQUIRED,
      value: QUESTION_MANDATORY_OPTIONS.NOT_REQUIRED,
    },
  ];

  return (
    <Wrapper>
      <TypeSelectorWrapper>
        {options.map((o) => (
          <ButtonChip key={o.id} isSelected={o.id === questionType}>
            <Button
              startIcon={o.id === questionType ? o.activeIcon : o.icon}
              style={{ height: '30px' }}
              variant='outlined'
              onClick={(e) => {
                e.stopPropagation();
                onChangeType(o);
              }}
            >
              {o.value}
            </Button>
          </ButtonChip>
        ))}
      </TypeSelectorWrapper>
      <ChipsSelectorV2
        options={requiredOrNotOptions}
        selectedItem={isRequired ? requiredOrNotOptions[0] : requiredOrNotOptions[1]}
        onSelect={onChangeQuestionMandatory}
      />
    </Wrapper>
  );
};
