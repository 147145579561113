import { useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';
import { NotesBoxPopup } from '../../NotesBoxPopup/NotesBoxPopup';

interface Props {
  answer?: string;
  isOverridden?: boolean;
  isOverrideAnswer?: boolean;
  overrideReason?: string;
}

export const AnswerText = ({ answer, isOverrideAnswer, overrideReason, isOverridden }: Props) => {
  const { colors } = useTheme();
  return (
    <NotesBoxPopup
      notes={overrideReason || ''}
      popupPlacement='bottom-start'
      containerStyle={{ display: 'inline' }}
      popupOffset={{ mainAxis: 8 }}
      popupStrategy='fixed'
    >
      <Typography
        variant='body'
        color={colors.primary[90]}
        style={{
          textDecoration: isOverridden ? 'line-through' : 'none',
          background: isOverrideAnswer && Boolean(overrideReason) ? '#CAF6FB' : 'transparent',
          flex: 1,
        }}
      >
        {answer as string}
      </Typography>
    </NotesBoxPopup>
  );
};
