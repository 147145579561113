import { styled } from '@mui/material';

export const OptionWrapper = styled('div')<{ disabled?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  min-height: 26px;
`;

export const OptionListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddNoteWrapper = styled('div')`
  margin-left: 8px;
  margin-top: -8px;
`;
