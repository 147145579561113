import { Dispatch, SetStateAction, useCallback } from 'react';
import { KeyTask } from '../../../../../../../types';
import { AddEditKeyTaskModal } from '../ObjectiveSummary/AddEditKeyTaskModal/AddEditKeyTaskModal';
import { KeyTaskPayload, useUpdateKeyTask } from '../../../../../../../queries/useKeyTasks';
import { useParams } from 'react-router';

interface Props {
  activeKeyTask: KeyTask | null;
  isModalOpen: boolean;
  setActiveKeyTask: Dispatch<SetStateAction<KeyTask | null>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditKeyTask = ({
  activeKeyTask,
  isModalOpen,
  setActiveKeyTask,
  setIsModalOpen,
}: Props) => {
  const params = useParams();

  const editKeyTask = useUpdateKeyTask(Number(params.id));

  const onSubmit = useCallback(
    (payload: Partial<KeyTaskPayload>) => {
      if (!activeKeyTask) return;
      editKeyTask.mutate({
        id: activeKeyTask.id,
        ...payload,
      });
    },
    [activeKeyTask, editKeyTask]
  );

  return (
    <AddEditKeyTaskModal
      keyTask={activeKeyTask}
      isOpen={isModalOpen}
      onClose={() => {
        setActiveKeyTask(null);
        setIsModalOpen(false);
      }}
      onSubmit={onSubmit}
      title='Edit Key Task'
      confirmBtnText='Edit Key Task'
    />
  );
};
