import {
  Row,
  RowSelectionState,
  SortingState,
  Updater,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  activeKpiCategoryState,
  isConfirmBarActiveState,
  isMoveModeActiveState,
  kpisTableRowSelectionState,
  selectedCoreKpiState,
  selectedCoreKpisState,
} from '../../../state/UIState';
import { useAtom } from 'jotai';
import { CORE_KPI_FORMAT, CoreKpi } from '../../../types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from '../../../components/Table/Table';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as MoveIcon } from '../../../assets/icons/corner-arrow.svg';
import { Checkbox, Fade, styled, useTheme } from '@mui/material';
import { EditCreateCoreKpi } from './EditCreateCoreKpi';
import { CoreKpiPayload, useUpdateCoreKpi, useUpdateCoreKpis } from '../../../queries/useCoreKpis';
import { ConfirmDeleteModal } from '../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { APPLY_EVALUATION_RULES_HEADER_CONTROLS } from '../../../constants/layoutSizes';
import { KpisSelectionBar } from './KpisSelectionBar';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Tooltip } from '../../../components/Tooltip/Tooltip';

const Wrapper = styled('div')``;
const CellWrapper = styled('div')<{ isTextTruncated?: boolean }>`
  max-width: 250px;
  width: 250px;
  cursor: ${({ isTextTruncated }) => (isTextTruncated ? 'pointer' : 'auto')};
  &&& p {
    white-space: ${({ isTextTruncated }) => (isTextTruncated ? 'nowrap' : 'normal')};
    text-overflow: ${({ isTextTruncated }) => (isTextTruncated ? 'ellipsis' : 'clip')};
  }
`;

const CheckboxCellWrapper = styled('div')`
  display: flex;
  align-items: center;
  max-width: 350px;
  gap: 8px;
  width: 350px;
`;

const columnHelper = createColumnHelper<CoreKpi>();

export const CoreKpisTable = () => {
  const { colors } = useTheme();
  const [activeCategory] = useAtom(activeKpiCategoryState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const handleOpenCreateModal = () => setIsCreateModalOpen(true);
  const handleCloseCreateModal = () => setIsCreateModalOpen(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const handleOpenConfirmDeleteModal = () => setIsConfirmDeleteModalOpen(true);
  const handleCloseConfirmDeleteModal = () => setIsConfirmDeleteModalOpen(false);
  const [isConfirmBarActive, setIsConfirmBarActive] = useAtom(isConfirmBarActiveState);
  const [isMoveModeActive, setIsMoveModeActive] = useAtom(isMoveModeActiveState);
  const [selectedKpiForAction, setSelectedKpiForAction] = useAtom(selectedCoreKpiState);
  const [selectedKpis, setSelectedKpis] = useAtom(selectedCoreKpisState);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const [rowSelection, setRowSelection] = useAtom(kpisTableRowSelectionState);

  const updateCoreKpi = useUpdateCoreKpi();

  const updateCoreKpis = useUpdateCoreKpis();

  const data = useMemo<CoreKpi[]>(() => {
    if (!activeCategory || !activeCategory.kpis) {
      return [];
    }
    return activeCategory.kpis;
  }, [activeCategory]);

  const [kpisList, setKpisList] = useState<CoreKpi[]>([]);

  useEffect(() => {
    setKpisList(data);
  }, [data]);

  const actions = useMemo(() => {
    return [
      {
        id: 'EDIT',
        value: 'Edit KPI',
        icon: <EditIcon />,
      },
      {
        id: 'MOVE',
        value: 'Move KPI to...',
        icon: <MoveIcon />,
      },
      {
        id: 'DELETE',
        value: 'Delete KPI',
        icon: <DeleteIcon />,
      },
    ];
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <CheckboxCellWrapper style={{ position: 'relative', top: '7px' }}>
            <Checkbox
              checked={
                selectedKpiForAction
                  ? selectedKpiForAction.id === info.row.original.id
                  : info.row.getIsSelected()
              }
              disabled={isMoveModeActive}
              style={{ padding: 0 }}
            />
            <Typography variant='subtitle2' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </CheckboxCellWrapper>
        ),
        header: () => (
          <CheckboxCellWrapper>
            <Checkbox
              disabled={isMoveModeActive}
              style={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                table.getToggleAllRowsSelectedHandler()(e);
              }}
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
              }}
            />
            <Typography variant='overline' color={colors.primary[70]}>
              KPI
            </Typography>
          </CheckboxCellWrapper>
        ),
        meta: {
          width: '25%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('description', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <CellWrapper isTextTruncated>
              <Typography variant='body' color={colors.primary[90]}>
                {info.getValue()}
              </Typography>
            </CellWrapper>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Definition
          </Typography>
        ),
        meta: {
          width: '25%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('metric', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Metric
          </Typography>
        ),
        meta: {
          width: '25%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('format', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {`${info.getValue()} (${info.getValue() === CORE_KPI_FORMAT.PERCENTAGE ? '%' : '#'})`}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Unit
          </Typography>
        ),
        meta: {
          width: '25%',
        },
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colors.primary, selectedKpiForAction, isMoveModeActive]
  );

  const handleSelectAction = useCallback(
    (actionId: string, row: Row<CoreKpi> | null) => {
      if (!row) return;
      switch (actionId) {
        case 'EDIT':
          setSelectedKpiForAction(row.original);
          handleOpenCreateModal();
          return;
        case 'MOVE':
          setIsConfirmBarActive(true);
          setIsMoveModeActive(true);
          setSelectedKpiForAction(row.original);
          return;
        case 'DELETE':
          setSelectedKpiForAction(row.original);
          handleOpenConfirmDeleteModal();
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [setIsConfirmBarActive, setIsMoveModeActive, setSelectedKpiForAction]
  );

  const onSubmit = useCallback(
    async (kpi: Partial<CoreKpiPayload>) => {
      if (!selectedKpiForAction) return;
      await updateCoreKpi.mutateAsync(
        { ...kpi, id: selectedKpiForAction.id },
        {
          onSuccess: async () => {
            if (!appConfigData?.data) {
              return;
            }
            await updateAppConfig.mutateAsync({
              data: { ...appConfigData?.data, isCoreKpiSurveyUpToDate: false },
            });
          },
        }
      );
      setSelectedKpiForAction(null);
      handleCloseCreateModal();
    },
    [
      appConfigData?.data,
      selectedKpiForAction,
      setSelectedKpiForAction,
      updateAppConfig,
      updateCoreKpi,
    ]
  );

  const handleDeleteKpi = useCallback(async () => {
    if (!selectedKpiForAction) return;
    handleCloseConfirmDeleteModal();
    await updateCoreKpi.mutateAsync(
      { id: selectedKpiForAction.id, isDeleted: true },
      {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isCoreKpiSurveyUpToDate: false },
          });
        },
      }
    );
    setSelectedKpiForAction(null);
  }, [
    appConfigData?.data,
    selectedKpiForAction,
    setSelectedKpiForAction,
    updateAppConfig,
    updateCoreKpi,
  ]);

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!active?.id || !over?.id) return;

      const oldIndex = kpisList.findIndex((k) => k.id === Number(active.id));
      const newIndex = kpisList?.findIndex((k) => k.id === Number(over.id));

      const reorderedKpis = arrayMove(kpisList, oldIndex, newIndex);

      setKpisList(reorderedKpis);

      const payload = {
        data: reorderedKpis.map((k, i) => ({
          id: k.id,
          order: i + 1,
        })),
      };

      updateCoreKpis.mutateAsync(payload, {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isCoreKpiSurveyUpToDate: false },
          });
        },
      });
    },
    [appConfigData?.data, kpisList, updateAppConfig, updateCoreKpis]
  );

  const table = useReactTable({
    data: kpisList,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: (newState: Updater<RowSelectionState>) => {
      return isMoveModeActive ? undefined : setRowSelection(newState);
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (kpi) => String(kpi.id),
  });

  useEffect(() => {
    if (!kpisList) return;
    const newKpis = Object.keys(rowSelection)
      .map((id) => kpisList.find((kpi) => kpi.id === Number(id)))
      .filter(Boolean) as CoreKpi[];
    setSelectedKpis(newKpis);
  }, [kpisList, rowSelection, setSelectedKpis]);

  return (
    <Wrapper>
      <KpisSelectionBar />
      <Table
        table={table}
        height={
          isConfirmBarActive
            ? `calc(100vh - ${234 + APPLY_EVALUATION_RULES_HEADER_CONTROLS}px)`
            : 'calc(100vh - 234px)'
        }
        trStyle={{ height: 'auto' }}
        tdStyle={{
          verticalAlign: 'baseline',
          paddingBottom: '8px',
        }}
        actions={isMoveModeActive || selectedKpis.length ? undefined : actions}
        onActionClick={handleSelectAction}
        disableLayoutAnimation
        isDraggable
        onDragEnd={handleReorder}
      />

      {isCreateModalOpen && (
        <EditCreateCoreKpi
          onClose={() => {
            setSelectedKpiForAction(null);
            handleCloseCreateModal();
          }}
          onSubmit={onSubmit}
          coreKpi={selectedKpiForAction}
          title='Edit Core KPI'
          confirmBtnText='Save Changes'
        />
      )}

      <ConfirmDeleteModal
        onClose={() => {
          setSelectedKpiForAction(null);
          handleCloseConfirmDeleteModal();
        }}
        onConfirm={handleDeleteKpi}
        title='Delete the KPI?'
        note='This KPI is active and used in Surveys & Company Profile. Any modifications you make will have a global effect.'
        confirmLabel='Yes, Delete'
        cancelLabel='No, Cancel'
        isOpen={isConfirmDeleteModalOpen}
      />
    </Wrapper>
  );
};
