import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useCreateRequest } from '../queries/useRequests';
import { Report, SURVEY_FREQUENCY } from '../types';
import { useQueryClient } from 'react-query';
import { ROUTES } from '../constants/routes';
import { REPORTS } from '../queries/useReports';
import { SURVEYS } from '../queries/useSurveys';

export const useReportTaskClickHandler = () => {
  const createRequest = useCreateRequest();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onReportTaskClickHandler = useCallback(
    (report?: Report | null, surveyId?: number, name?: string, companyId?: number) => {
      if (!companyId || !!report || !surveyId || !name) return;

      createRequest.mutate(
        {
          companyId: companyId,
          frequency: SURVEY_FREQUENCY.ONE_TIME,
          surveyId,
          name,
        },
        {
          onSuccess: (request) => {
            navigate(`/${ROUTES.QUESTIONNAIRES}/${request.reports[0].id}/progress`);
            queryClient.refetchQueries(`${REPORTS}-${request.company.id}`);
            queryClient.refetchQueries(SURVEYS);
          },
        }
      );
    },
    [createRequest, navigate, queryClient]
  );

  return { onReportTaskClickHandler };
};
