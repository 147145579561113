import { QUESTION_TYPE, QuestionOption as TQuestionOption } from '../../../../../../../types';
import { useCallback, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import {
  activeQuestionIdState,
  activeSurveyState,
  questionState,
} from '../../../../../../../state/UIState';
import { Option } from '../../components/Option';

interface Props {
  option: TQuestionOption;
  type: QUESTION_TYPE;
  showDragIcon: boolean;
  onUpdateFlag: () => void;
  onUpdateWeight: (weight: number) => void;
}

export const QuestionOption = ({
  option,
  type,
  showDragIcon,
  onUpdateFlag,
  onUpdateWeight,
}: Props) => {
  const [optionTitle, setOptionTitle] = useState(option?.name ?? '');

  const [activeQuestionId] = useAtom(activeQuestionIdState);
  const [question, setQuestion] = useAtom(questionState(activeQuestionId));

  const [activeSurvey] = useAtom(activeSurveyState);

  const isWeightIncluded = useMemo(() => {
    return activeSurvey?.includeWeight;
  }, [activeSurvey?.includeWeight]);

  const onOptionRename = useCallback(
    (value: string) => {
      if ((value === option?.name && !option.justCreated) || !value || !question) {
        setOptionTitle(option?.name ?? '');
        return;
      }
      const updatedQuestion = {
        ...question,
        options: question.options.map((o) => {
          if (o.id === option?.id) {
            return {
              id: o.id,
              name: value,
            };
          }
          return o;
        }),
      };
      setQuestion(updatedQuestion);
    },
    [option?.id, option.justCreated, option?.name, question, setQuestion]
  );

  const onDeleteOption = useCallback(() => {
    if (!question) return;
    const updatedQuestion = {
      ...question,
      options: question.options.filter((o) => o.id !== option?.id),
    };
    setQuestion(updatedQuestion);
  }, [option?.id, question, setQuestion]);

  return (
    <Option
      onOptionRename={onOptionRename}
      showDragIcon={showDragIcon}
      option={option}
      questionType={type}
      onUpdateFlag={onUpdateFlag}
      onDeleteOption={onDeleteOption}
      optionTitle={optionTitle}
      setOptionTitle={setOptionTitle}
      isWeightIncluded={isWeightIncluded}
      onUpdateWeight={onUpdateWeight}
    />
  );
};
