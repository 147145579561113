import { styled } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router';
import { LoadingBar } from '../../components/LoadingBar/LoadingBar';
import { SurveyEditorSkeletonLoader } from '../../components/SkeletonLoader/SurveyEditor.SkeletonLoader';
import { REPORTS, useReportById, useUpdateReport } from '../../queries/useReports';
import {
  activeQuestionIdState,
  activeReportState,
  activeSectionIdState,
  activeSurveyIdState,
  activeSurveyState,
  areShowedAllQuestionsState,
  evaluationState,
  isNameModalOpenState,
  responsesState,
  sectionsState,
  survayNameState,
} from '../../state/UIState';
import { ProgressHeader } from './components/ProgressHeader';
import { useQueryClient } from 'react-query';
import { REPORT_STATUS, SURVEY_TYPE } from '../../types';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const LoadingBarWrapper = styled('div')`
  width: 100%;
  position: absolute;
  top: 0;
`;

const Content = styled('div')``;

export const InternalQuestionnaire = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [, setSurveyName] = useAtom(survayNameState);
  const params = useParams();

  const [, setOpen] = useAtom(isNameModalOpenState);
  const [, setActiveSurveyId] = useAtom(activeSurveyIdState);
  const [, setActiveReport] = useAtom(activeReportState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [, setSections] = useAtom(sectionsState);
  const setAreShowedAllQuestions = useSetAtom(areShowedAllQuestionsState);
  const [, setResponses] = useAtom(responsesState);
  const [, setActiveSurvey] = useAtom(activeSurveyState);
  const updateReport = useUpdateReport();
  const queryClient = useQueryClient();
  const { data: report } = useReportById(Number(params?.reportId));
  const [, setEvaluation] = useAtom(evaluationState);

  const setReportInProgress = useCallback(async () => {
    if (
      !report ||
      report?.status === REPORT_STATUS.IN_REVIEW ||
      report?.status === REPORT_STATUS.SUBMITTED ||
      report?.status === REPORT_STATUS.IN_PROGRESS
    ) {
      return setIsLoading(false);
    }
    await updateReport.mutateAsync(
      { id: report.id, status: REPORT_STATUS.IN_PROGRESS },
      {
        onSuccess: () => {
          queryClient.refetchQueries(`${REPORTS}-${report.company.id}`);
          queryClient.refetchQueries(`${REPORTS}-${report.id}`);
        },
        onError: (e) => {
          console.error(e);
          setIsLoading(false);
        },
      }
    );
    setIsLoading(false);
  }, [queryClient, report, updateReport]);

  useEffect(() => {
    if (!report) return;
    setActiveReport(report);
    const { survey } = report;
    setSurveyName(survey?.name ?? '');
    const sections =
      survey?.type === SURVEY_TYPE.CORE
        ? survey?.sections
            .filter((s) => s.questions.some((q) => q.isActive))
            .map((s) => ({ ...s, questions: s.questions.filter((q) => q.isActive) }))
        : survey?.sections;
    setSections(sections ?? []);
    setActiveSurveyId(survey?.id ?? null);
    setActiveSectionId(survey?.sections?.[0]?.id ?? null);
    setActiveQuestionId(survey?.sections?.[0]?.questions?.[0]?.id ?? null);
    setActiveSurvey(survey);
    setEvaluation(report.evaluation);

    setReportInProgress();
    if (Object.keys(report?.response || {})?.length) setResponses(report.response);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      queryClient.refetchQueries(`${REPORTS}-${report?.id}`);
      setActiveSurveyId(null);
      setActiveSectionId(null);
      setActiveQuestionId(null);
      setActiveSurvey(null);
      setSections([]);
      setResponses({});
      setAreShowedAllQuestions(true);
      setSurveyName('');
      setOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  if (isLoading) return <SurveyEditorSkeletonLoader />;

  return (
    <Wrapper>
      <LoadingBarWrapper>
        <LoadingBar />
      </LoadingBarWrapper>
      <ProgressHeader isInternal />
      <Content>
        <Outlet />
      </Content>
    </Wrapper>
  );
};
