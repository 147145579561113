import { FC, useCallback, useState } from 'react';
import Papa from 'papaparse';
import { DownloadCSVLink } from './DownloadCSVLink';
import { QuestionTableData } from '../../../../types';
import { UploadCSVArea } from '../../../../components/UploadCSVArea/UploadCSVArea';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { FileItem } from '../../../../components/FileItem/FileItem';
import { Modal } from '../../../../components/Modal/Modal';
interface Props {
  onClose: () => void;
  tableData: QuestionTableData;
  onSynchronize: (uploadedRows: any[][]) => void;
}

export const ImportTableDataModal: FC<Props> = ({ onClose, tableData, onSynchronize }) => {
  const { pushErrorToast } = useToastMessage();
  const [uploadedRows, setUploadedRows] = useState<any[][]>([]);
  const [file, setFile] = useState<File | null>(null);

  const onDrop = useCallback(
    (files: File[]) => {
      if (!files.length) {
        pushErrorToast({ message: 'Invalid file type' });
        return;
      }

      const file = files[0];
      setFile(file);
      Papa.parse(file, {
        header: false,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: function (results) {
          if (results.data.length !== tableData.rows.length) {
            pushErrorToast({ message: 'Invalid CSV file' });
            return;
          }
          setUploadedRows(results.data as any[][]);
        },
        error: function (error) {
          console.error(error.message);
        },
      });
    },
    [pushErrorToast, tableData.rows]
  );

  const onConfirm = () => {
    if (!uploadedRows?.length) return;

    onSynchronize(uploadedRows);
    setUploadedRows([]);
    onClose();
  };

  const onClearFile = useCallback(() => {
    setFile(null);
    setUploadedRows([]);
  }, []);

  return (
    <Modal
      title='Synchronize tables'
      confirmBtnText='Synchronize'
      isConfirmDisabled={!uploadedRows?.length}
      onClose={onClose}
      onConfirm={onConfirm}
      isOpen
    >
      <DownloadCSVLink tableData={tableData} />
      {!file && <UploadCSVArea onDrop={onDrop} />}
      {file && (
        <FileItem file={file} onClear={onClearFile} style={{ margin: '20px 0', height: '90px' }} />
      )}
    </Modal>
  );
};
