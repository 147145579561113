import { styled } from '@mui/material';
import { ReactComponent as DragIcon } from '../../../../../../../../assets/icons/drag.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Exclusion } from '../../../../../../../../types';
import { ExclusionHeader } from './ExclusionHeader/ExclusionHeader';
import { EditableTextareaField } from '../../../../../../../../components/EditableTextField/EditableTextareaField';
import { useUpdateExclusion } from '../../../../../../../../queries/useExclusions';
import { useAppConfig, useUpdateAppConfig } from '../../../../../../../../queries/useAppConfig';
import { useAtom } from 'jotai';
import {
  activeConditionIdState,
  activeExclusionCategoryState,
  activeExclusionIdState,
} from '../../../../../../../../state/UIState';
import { InvestorFunds } from './InvestorFunds';

const DragWrapper = styled('div')`
  position: relative;
  padding: 0 32px;
`;

const Wrapper = styled('div')<{ isActive: boolean; cursor?: string; isIncluded: boolean }>`
  position: relative;
  padding: 16px 24px 16px 24px;
  flex-direction: column;
  gap: 20px;
  border-radius: 4px;
  background-color: ${({ theme, isIncluded }) =>
    isIncluded ? theme.colors.primary[0] : theme.colors.primary[5]};
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  border-left: 4px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.accent[30] : theme.colors.primary[30])};
`;

const QuestionNameWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-bottom: 14px;
`;

interface Props {
  exclusion: Exclusion;
  exclusionOrder: number;
  isDragging: boolean;
  isDraggable: boolean;
}

export const ExclusionContent = ({ exclusion, exclusionOrder, isDragging, isDraggable }: Props) => {
  const [showDragIcon, setShowDragIcon] = useState(false);
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const [exclusionText, setExclusionText] = useState(exclusion.exclusion);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const [activeExclusionId, setActiveExclusionId] = useAtom(activeExclusionIdState);
  const [activeCategory] = useAtom(activeExclusionCategoryState);
  const [activeConditionId] = useAtom(activeConditionIdState);

  const { mutate: updateExclusion } = useUpdateExclusion(activeCategory?.id);

  const isActive = !isDragging && activeExclusionId === exclusion.id;

  const onChangeExclusionText = useCallback(
    (value: string) => {
      if (!activeConditionId) return;
      updateExclusion(
        { id: exclusion.id, exclusion: value || '', condition: activeConditionId },
        {
          onSuccess: async () => {
            if (!appConfigData?.data) {
              return;
            }
            await updateAppConfig.mutateAsync({
              data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
            });
          },
        }
      );
    },
    [activeConditionId, appConfigData?.data, exclusion.id, updateAppConfig, updateExclusion]
  );

  const onMouseEnter = useCallback(() => {
    if (activeExclusionId === exclusion.id) {
      return;
    }
    setShowDragIcon(true);
  }, [activeExclusionId, exclusion.id]);

  const onMouseLeave = useCallback(() => {
    if (activeExclusionId === exclusion.id) {
      return;
    }
    setShowDragIcon(false);
  }, [activeExclusionId, exclusion.id]);

  const onExclusionClick = useCallback(() => {
    setActiveExclusionId(exclusion.id);
  }, [exclusion.id, setActiveExclusionId]);

  useEffect(() => {
    if (activeExclusionId === exclusion.id) {
      isDraggable && setShowDragIcon(true);
      const top = wrapperRef?.current?.getBoundingClientRect()?.top;
      if (top && (top > window.innerHeight || top < 0)) {
        wrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setShowDragIcon(false);
    }
  }, [activeExclusionId, exclusion.id, isDraggable]);

  return (
    <DragWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {showDragIcon && (
        <DragIcon style={{ position: 'absolute', left: '12px', top: '5', cursor: 'pointer' }} />
      )}

      <Wrapper
        ref={wrapperRef}
        onClick={onExclusionClick}
        isActive={isActive}
        isIncluded={!!exclusion.isActive}
        cursor={'pointer'}
      >
        <ExclusionHeader exclusion={exclusion} exclusionOrder={exclusionOrder} />

        <QuestionNameWrapper>
          <EditableTextareaField
            value={exclusionText}
            onChange={(e) => setExclusionText(e.target.value)}
            onValueChanged={onChangeExclusionText}
            placeholder='Enter exclusion text'
          />
        </QuestionNameWrapper>
        <InvestorFunds exclusion={exclusion} />
      </Wrapper>
    </DragWrapper>
  );
};
