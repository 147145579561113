import { styled, useTheme } from '@mui/material';
import { ReactComponent as FlagFilled } from '../../assets/icons/flag-red.svg';
import { Typography } from '../Typography/Typography';

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

const FlagIconWrapper = styled('div')<{ isGrey: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 4px;

  path {
    fill: ${({ theme, isGrey }) => (isGrey ? theme.colors.primary[40] : theme.colors.error[40])};
  }
`;

interface Props {
  flagsNum: number;
}

export const FlagsIndicator = ({ flagsNum }: Props) => {
  const { colors } = useTheme();

  const isGrey = flagsNum === 0;

  return (
    <Container>
      <FlagIconWrapper isGrey={isGrey}>
        <FlagFilled />
      </FlagIconWrapper>
      <Typography variant='srOnly' color={isGrey ? colors.primary[70] : colors.error[50]}>
        {flagsNum}
      </Typography>
    </Container>
  );
};
