import { useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { formatDate } from '../../utils/formatters';
import { useAppConfig } from '../../queries/useAppConfig';

export const CompaniesLastUpdate = () => {
  const { colors } = useTheme();

  const { data: appConfig } = useAppConfig();

  if (!appConfig?.data?.lastCompaniesSync) return null;

  return (
    <Typography variant='srOnly' color={colors.primary[70]}>
      LAST UPDATE:{' '}
      <Typography as='span' variant='srOnly' color={colors.primary[90]}>
        {formatDate(appConfig?.data.lastCompaniesSync ?? '').toLocaleUpperCase()}
      </Typography>
    </Typography>
  );
};
