import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AccordionCard } from '../../../../components/AccordionCard/AccordionCard';
import { WorkflowStage } from '../../../../types';
import { ReactComponent as DragIcon } from '../../../../assets/icons/drag.svg';
import { styled } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { StageActionMenu } from './StageActionMenu';

const DragWrapper = styled('div')`
  position: relative;
  padding: 0 25px;
`;

const ContentWrapper = styled('div')`
  padding: 0 32px;
`;

const SortableWrapper = styled('div')``;

interface Props {
  stage: WorkflowStage;
  children: ReactNode;
  activeStage: WorkflowStage | null;
  onCardClick: () => void;
  isDraggable: boolean;
  onTitleChanged: (value: string) => void;
  onDeleteStage: (stageId: number) => void;
}

export const StageCard = ({
  stage,
  children,
  activeStage,
  onCardClick,
  isDraggable,
  onTitleChanged,
  onDeleteStage,
}: Props) => {
  const [title, setTitle] = useState(stage.name);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDragIcon, setShowDragIcon] = useState(false);
  const wrapperRef = useRef<null | HTMLDivElement>(null);

  const { setNodeRef, transform, transition, attributes, listeners, isDragging } =
    useSortable(stage);
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const onExpandToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const onMouseEnter = useCallback(() => {
    if (activeStage?.id === stage.id) {
      return;
    }
    setShowDragIcon(true);
  }, [activeStage?.id, stage.id]);

  const onMouseLeave = useCallback(() => {
    if (activeStage?.id === stage.id) {
      return;
    }
    setShowDragIcon(false);
  }, [activeStage?.id, stage.id]);

  useEffect(() => {
    if (activeStage?.id === stage.id) {
      isDraggable && setShowDragIcon(true);
      const top = wrapperRef?.current?.getBoundingClientRect()?.top;
      if (top && (top > window.innerHeight || top < 0)) {
        wrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setShowDragIcon(false);
    }
  }, [activeStage?.id, isDraggable, stage.id]);

  const chipLabel = useMemo(() => {
    if (!stage?.steps?.length) return 'No steps yet';

    if (stage?.steps?.length === 1) return '1 step';

    return `${stage?.steps?.length} steps`;
  }, [stage?.steps?.length]);

  return (
    <SortableWrapper
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ ...style, opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 2 : 1 }}
    >
      <DragWrapper ref={wrapperRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {showDragIcon && (
          <DragIcon style={{ position: 'absolute', left: '7px', top: '5', cursor: 'pointer' }} />
        )}
        <AccordionCard
          key={stage.name}
          onCardClick={onCardClick}
          onExpandToggle={onExpandToggle}
          isExpanded={!isDragging && isExpanded}
          title={title}
          chipLabel={chipLabel}
          onTitleValueChanged={onTitleChanged}
          setTitleValue={setTitle}
          controls={<StageActionMenu stageId={stage.id} onDeleteStage={onDeleteStage} />}
        >
          {!isDragging && isExpanded && <ContentWrapper>{children}</ContentWrapper>}
        </AccordionCard>
      </DragWrapper>
    </SortableWrapper>
  );
};
