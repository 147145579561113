import { FC } from 'react';

import { QuestionOption, QUESTION_TYPE, SurveyQuestion } from '../../../../../../types';

import { SingleOption } from './components/SingleOption/SingleOption';
import { MultiOption } from './components/MultiOption';

interface Props {
  option: QuestionOption;
  type: QUESTION_TYPE;
  showDragIcon: boolean;
  questionNum: number;
  question: SurveyQuestion;
}

export const QuestionOptionItem: FC<Props> = ({
  option,
  type,
  showDragIcon,
  questionNum,
  question,
}) => {
  if (type === QUESTION_TYPE.SINGLE) {
    return (
      <SingleOption
        option={option}
        showDragIcon={showDragIcon}
        questionNum={questionNum}
        question={question}
      />
    );
  }

  return <MultiOption option={option} showDragIcon={showDragIcon} />;
};
