import { styled } from '@mui/material';
import { HeaderWrapper } from '../../components/Layout/Layout';
import { RequestsContent } from './components/RequestsContent';
import { RequestsHeader } from './components/RequestsHeader';

const Wrapper = styled('div')``;

export const Requests = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <RequestsHeader />
      </HeaderWrapper>
      <RequestsContent />
    </Wrapper>
  );
};
