import { useCallback } from 'react';
import { useUpdateCompany } from '../../queries/useCompanies';
import { useAtom } from 'jotai';
import { QuestionOption } from '../../types';
import { useInvalidateExclusionsByCompany } from '../../queries/useExclusionsByCompany';
import { activeReportState, reviewState } from '../../state/UIState';

export const useUpdateCompanyExclusions = () => {
  const updateCompany = useUpdateCompany();
  const [report] = useAtom(activeReportState);
  const [review] = useAtom(reviewState);

  const invalidateExclusionsByCompany = useInvalidateExclusionsByCompany();

  const updateCompanyExclusions = useCallback(async () => {
    if (!report) return;
    const { response, survey } = report;

    const exclusionIds: number[] = [];

    survey.sections.forEach((section) => {
      section.questions.forEach((question) => {
        const validResponse = review?.[question?.id]?.overrideResponse || response[question.id];
        if ((validResponse as QuestionOption)?.name === 'Yes') {
          exclusionIds.push(Number(question.id));
        }
      });
    });

    await updateCompany.mutateAsync({
      id: report.company.id,
      exclusions: exclusionIds,
      violatedExclusionsIds: exclusionIds,
    });
    invalidateExclusionsByCompany(report.company.id);
  }, [invalidateExclusionsByCompany, report, review, updateCompany]);

  return { updateCompanyExclusions };
};
