import { useToastMessage } from '../../../hooks/useToastMessage';
import { useSortedCoreKpiCategories } from './useSortedCoreKpiCategories';
import { useUpdateCoreKpisSurvey } from '../../../queries/useSurveys';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { useCallback } from 'react';
import { QUESTION_TYPE, QuestionTableRow, SurveyQuestion, SurveySection } from '../../../types';
import {
  HEADER_COLUMN_ID,
  HEADER_ROW_ID,
  KPI_DEFINITION_COLUMN_ID,
  KPI_FORMAT_COLUMN_ID,
  KPI_METRIC_COLUMN_ID,
  KPI_VALUE_COLUMN_ID,
} from '../../../constants/defaultValues';

export const useUpdateKpiSurvey = () => {
  const { pushSuccessToast } = useToastMessage();
  const { items: coreKpiCategories } = useSortedCoreKpiCategories();
  const updateCoreKpisSurvey = useUpdateCoreKpisSurvey();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const onUpdateKpiSurvey = useCallback(async () => {
    //generate survey sections from coreKpis
    const filteredCategories = coreKpiCategories.filter((c) => c.kpis.length > 0);
    const sections: SurveySection[] = filteredCategories.map((c) => {
      const columns = [
        HEADER_COLUMN_ID,
        KPI_DEFINITION_COLUMN_ID,
        KPI_METRIC_COLUMN_ID,
        KPI_FORMAT_COLUMN_ID,
        KPI_VALUE_COLUMN_ID,
      ];
      const rows: QuestionTableRow[] = [
        {
          id: HEADER_ROW_ID,
          [HEADER_COLUMN_ID]: 'KPI',
          [KPI_DEFINITION_COLUMN_ID]: 'Definition',
          [KPI_METRIC_COLUMN_ID]: 'Metric',
          [KPI_FORMAT_COLUMN_ID]: 'Unit',
          [KPI_VALUE_COLUMN_ID]: 'Value',
        },
      ];
      c.kpis.forEach((k) => {
        rows.push({
          [HEADER_COLUMN_ID]: k.name,
          [KPI_DEFINITION_COLUMN_ID]: k.description,
          [KPI_METRIC_COLUMN_ID]: k.metric,
          [KPI_FORMAT_COLUMN_ID]: k.format,
          id: k.id.toString(),
        });
      });
      const readOnlyColumns = [
        KPI_DEFINITION_COLUMN_ID,
        KPI_METRIC_COLUMN_ID,
        KPI_FORMAT_COLUMN_ID,
      ];
      const tableData = { columns, rows, readOnlyColumns };
      const question: SurveyQuestion = {
        type: QUESTION_TYPE.TABLE,
        id: c.id.toString(),
        name: 'Please provide KPIs values.',
        includeInstructions: false,
        instructions: '',
        isActive: true,
        sectionId: c.id.toString(),
        options: [],
        tableData,
        includeEvaluation: false,
        evaluationRules: null,
        isRequired: false,
      };
      return {
        id: c.id.toString(),
        collapsed: false,
        depth: 0,
        name: c.name,
        parentId: null,
        questions: [question],
        subSections: [],
      };
    });
    //update survey
    await updateCoreKpisSurvey.mutateAsync(
      { sections },
      {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync(
            { data: { ...appConfigData?.data, isCoreKpiSurveyUpToDate: true } },
            {
              onSuccess: () => {
                pushSuccessToast({
                  message: 'Core KPI Requests were successfully updated.',
                });
              },
            }
          );
        },
      }
    );
  }, [
    appConfigData?.data,
    coreKpiCategories,
    pushSuccessToast,
    updateAppConfig,
    updateCoreKpisSurvey,
  ]);

  return { onUpdateKpiSurvey };
};
