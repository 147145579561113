import { FocusEvent, MouseEvent, useCallback, useEffect, useRef } from 'react';
import { styled, TextField, TextFieldProps } from '@mui/material';

import { ReactComponent as EditIcon } from '../../assets/icons/edit-large.svg';

const Wrapper = styled('div')<{
  font?: string;
  fontSize?: string;
  lineHeight?: string;
  withBorder?: boolean;
}>`
  width: 100%;
  height: 26px;
  overflow: hidden;
  .read-write-input {
    height: 100%;
    width: 100%;
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border: transparent;
    }

    .MuiOutlinedInput-root.Mui-focused {
      background-color: ${({ theme, withBorder }) =>
        withBorder ? 'transparent' : theme.colors.accent[10]};
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: transparent;
    }

    .MuiInputBase-root {
      padding-left: 4px;

      border: ${({ theme, withBorder }) =>
        withBorder ? `1px solid ${theme.colors.primary[30]}` : 'none'};
      border-radius: ${({ withBorder }) => (withBorder ? '4px' : 0)};
    }
    .MuiOutlinedInput-notchedOutline {
      height: 26px;
      top: 0;
      border-color: transparent;
    }
    .MuiInputBase-root .MuiInputBase-input {
      height: 26px;
      font-family: ${({ theme, font }) => (font ? font : theme.text.font.fontFamilyMedium)};
      font-weight: ${({ theme }) => theme.text.weight.medium};
      font-size: ${({ theme, fontSize }) =>
        fontSize ? fontSize : `${theme.text.size.mediumSmall}px`};
      line-height: ${({ theme, lineHeight }) =>
        lineHeight ? lineHeight : theme.text.lineHeight.mediumSmall};
      color: ${({ theme }) => theme.colors.primary[90]};
      cursor: pointer;
      padding: 0 0;
    }
    .Mui-disabled fieldset {
      border-color: transparent;
    }
    .MuiInputBase-root .Mui-disabled {
      -webkit-text-fill-color: black;
      background: transparent;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    &:hover .Mui-disabled fieldset {
      border-color: 'transparent';
    }

    & .MuiInputBase-root.MuiOutlinedInput-root:hover {
      border: ${({ theme, withBorder }) =>
        withBorder ? `1px solid ${theme.colors.accent[50]}` : 'none'};
    }

    & .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
      border: ${({ theme, withBorder }) =>
        withBorder ? `1px solid ${theme.colors.accent[50]}` : 'none'};
    }

    & .MuiInputBase-input.MuiOutlinedInput-input {
      ::placeholder {
        font-family: ${({ theme }) => theme.text.font.fontFamilyRegular};
        font-weight: ${({ theme }) => theme.text.weight.regular};
        text-align: left;
        font-size: ${({ theme }) => `${theme.text.size.mediumSmall}px`};
        color: ${({ theme }) => theme.colors.primary[50]};
      }
    }
  }
`;

export type ReadWriteFieldProps = TextFieldProps & {
  onValueChanged?: (newValue: string) => void;
  showEditIcon?: boolean;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  wrapperStyle?: React.CSSProperties;
  focusOnMount?: boolean;
  inputStyle?: React.CSSProperties;
  font?: string;
  fontSize?: string;
  lineHeight?: string;
  withBorder?: boolean;
  placeholder?: string;
  
};

export function EditableTextFieldV2(props: ReadWriteFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    onValueChanged,
    showEditIcon = false,
    onClick,
    wrapperStyle,
    focusOnMount,
    inputStyle,
    font,
    fontSize,
    lineHeight,
    withBorder,
    placeholder,
    ...rest
  } = props;
  const triggerChangeIfValueChanged = useCallback(
    (newValue: string) => {
      if (newValue !== props.defaultValue) {
        onValueChanged?.(newValue);
      }
    },
    [onValueChanged, props.defaultValue]
  );

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      triggerChangeIfValueChanged(event.target.value);
      rest.onBlur?.(event);
    },
    [rest, triggerChangeIfValueChanged]
  );

  const onEnterPressed = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  }, []);

  const onClickHandler = useCallback(
    (event: MouseEvent<HTMLInputElement>) => {
      onClick?.(event);
      if (!(event.target as HTMLInputElement)?.select) return;
      (event.target as HTMLInputElement)?.focus();
      (event.target as HTMLInputElement)?.select();
    },
    [onClick]
  );

  useEffect(() => {
    if (focusOnMount) {
      // (inputRef.current as HTMLInputElement)?.blur();
      (inputRef.current as HTMLInputElement)?.focus();
      (inputRef.current as HTMLInputElement)?.select();
    }
  }, [focusOnMount]);

  return (
    <Wrapper
      onClick={onClickHandler}
      style={wrapperStyle}
      font={font}
      fontSize={fontSize}
      lineHeight={lineHeight}
      withBorder={withBorder}
    >
      <TextField
        {...rest}
        className={'read-write-input'}
        inputProps={{
          ...props.inputProps,
          onKeyPress: onEnterPressed,
          ref: inputRef,
          style: inputStyle,
        }}
        placeholder={placeholder || ''}
        InputProps={{
          startAdornment: showEditIcon ? (
            <EditIcon style={{ marginRight: '6px', transform: 'scale(0.9)' }} />
          ) : null,
        }}
        onBlur={onBlur}
        onKeyPress={onEnterPressed}
      />
    </Wrapper>
  );
}
