import { SelectItem } from '../../types';
import { Multiselect } from '../Multiselect/Multiselect';
import { Checkbox, styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';

const GroupWrapper = styled('li')`
  padding-bottom: 16px;
  margin: 0 12px;

  &:not(:last-of-type) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  }
`;

const GroupOptionWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

interface Props {
  groupedOptions: SelectItem[];
  selectedItems: SelectItem[];
  onSelectItems: (val: SelectItem[]) => void;
  placeholder?: string;
  isDisabled?: boolean;
}

export const GroupedOptionsMultiselect = ({
  groupedOptions,
  selectedItems,
  onSelectItems,
  placeholder,
  isDisabled,
}: Props) => {
  const { colors } = useTheme();
  return (
    <Multiselect
      isDisabled={isDisabled}
      style={{ width: '400px' }}
      options={
        groupedOptions?.sort((a, b) => {
          if (!a.group || !b.group) return 1;
          return -b?.group?.localeCompare?.(a?.group);
        }) || []
      }
      groupBy={(option) => option?.group || ''}
      value={selectedItems}
      onChange={(e, val) => {
        onSelectItems(val as SelectItem[]);
      }}
      disablePortal={false}
      optionsName='selected'
      fieldPlaceholder={placeholder}
      noPaddingOnOption
      renderGroup={(params) => {
        const allItemsInGroup =
          groupedOptions?.filter((option) => option.group === params.group) || [];

        const selectedItemsIds = selectedItems?.map((option) => option.id);
        const allItemsInGroupIds = allItemsInGroup?.map((option) => option.id);

        const isAllSelected = allItemsInGroup?.every((item) => selectedItemsIds?.includes(item.id));

        const onToggleAll = () => {
          if (isAllSelected) {
            const sItems = selectedItems?.filter((item) => !allItemsInGroupIds?.includes(item.id));
            onSelectItems(sItems);

            return;
          }
          onSelectItems([...selectedItems, ...allItemsInGroup]);
        };
        if (!params.group) return;

        return (
          <GroupWrapper key={params.key}>
            <GroupOptionWrapper>
              {params.group && <Checkbox checked={isAllSelected} onClick={onToggleAll} />}
              <Typography
                variant='overline'
                color={colors.primary[70]}
                style={{ textTransform: 'uppercase' }}
              >
                {params.group}
              </Typography>
            </GroupOptionWrapper>
            <ul>{params.children}</ul>
          </GroupWrapper>
        );
      }}
    />
  );
};
