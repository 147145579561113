import { Chip, styled, useTheme } from '@mui/material';
import { REPORT_STATUS } from '../../../types';
import { ReactComponent as ReviewIcon } from '../../../assets/icons/request-review.svg';
import { ReactComponent as PauseIcon } from '../../../assets/icons/request-pause.svg';
import { ReactComponent as InProgressIcon } from '../../../assets/icons/request-loader.svg';
import { ReactComponent as ApprovedIcon } from '../../../assets/icons/request-approved.svg';
import { ReactComponent as RejectedIcon } from '../../../assets/icons/request-rejected.svg';
import { ReactElement, useCallback, useMemo } from 'react';

interface Props {
  status: REPORT_STATUS | null;
  style?: React.CSSProperties;
  chipStyle?: React.CSSProperties;
}

const Wrapper = styled('div')`
  display: flex;
  gap: 4px;
`;

export const RequestStatusCell = ({ status, style, chipStyle }: Props) => {
  const { colors } = useTheme();

  const renderIcon = useCallback(() => {
    switch (status) {
      case REPORT_STATUS.APPROVED:
        return <ApprovedIcon style={{ marginRight: '4px' }} />;
      case REPORT_STATUS.SUBMITTED:
        return <ApprovedIcon style={{ marginRight: '4px' }} />;
      case REPORT_STATUS.IN_REVIEW:
        return <ReviewIcon style={{ marginRight: '4px' }} />;
      case REPORT_STATUS.IN_PROGRESS:
        return <InProgressIcon style={{ marginRight: '4px' }} />;
      case REPORT_STATUS.NOT_STARTED:
        return <PauseIcon style={{ marginRight: '4px' }} />;
      case REPORT_STATUS.REJECTED:
        return <RejectedIcon style={{ marginRight: '4px' }} />;
      default:
        return null;
    }
  }, [status]);

  const bgColor = useMemo(() => {
    switch (status) {
      case REPORT_STATUS.APPROVED:
        return colors.success[5];
      case REPORT_STATUS.SUBMITTED:
        return colors.success[5];
      case REPORT_STATUS.IN_REVIEW:
        return colors.accent[1];
      case REPORT_STATUS.IN_PROGRESS:
        return colors.warning[5];
      case REPORT_STATUS.NOT_STARTED:
      case REPORT_STATUS.REJECTED:
        return colors.error[5];
    }
  }, [colors, status]);
  return (
    <Wrapper style={{ ...style }}>
      <Chip
        icon={renderIcon() as ReactElement}
        style={{
          backgroundColor: bgColor,
          width: '97px',
          ...chipStyle,
        }}
        label={status}
      />
    </Wrapper>
  );
};
