import { styled, useTheme } from '@mui/material';
import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { Typography } from '../Typography/Typography';

const DropArea = styled('div')<{ isDragActive: boolean }>`
  background-color: ${({ theme, isDragActive }) =>
    isDragActive ? theme.colors.primary[10] : theme.colors.primary[5]};
  border: 1px dashed
    ${({ theme, isDragActive }) =>
      isDragActive ? theme.colors.primary[90] : theme.colors.primary[30]};
  border-radius: 4px;
  padding: 16px;
  margin: 20px 0;
  height: 90px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[10]};
    border: 1px dashed ${({ theme }) => theme.colors.primary[90]};
  }
`;

const LabelWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  onDrop: (acceptedFiles: File[]) => void;
}
export const UploadCSVArea: FC<Props> = ({ onDrop }) => {
  const { colors } = useTheme();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { 'text/csv': ['.csv'] },
  });

  return (
    <DropArea {...getRootProps()}>
      <input {...getInputProps()} accept='.csv' />
      <UploadIcon />
      <LabelWrapper>
        <Typography variant='body' color={colors.primary[90]}>
          Drag and drop or
        </Typography>
        <Typography variant='body' color={colors.accent[50]}>
          Choose CSV File to Upload
        </Typography>
      </LabelWrapper>
    </DropArea>
  );
};
