import { useMemo } from 'react';
import { ChipsSelector } from '../../../components/ButtonSelector/ChipsSelector';
import { FormField } from '../../../components/FormField/FormField';
import { RISK_LEVEL, SelectItem } from '../../../types';

const options: SelectItem[] = [
  {
    id: RISK_LEVEL.LOW,
    value: RISK_LEVEL.LOW,
  },
  {
    id: RISK_LEVEL.MEDIUM,
    value: RISK_LEVEL.MEDIUM,
  },
  {
    id: RISK_LEVEL.HIGH,
    value: RISK_LEVEL.HIGH,
  },
];

interface Props {
  esgOpportunitiesLevel?: RISK_LEVEL | null;
  onChangeEsgOpportunitiesLevel: (val: SelectItem | null) => void;
}

export const ESGOpportunitiesSelector = ({
  esgOpportunitiesLevel,
  onChangeEsgOpportunitiesLevel,
}: Props) => {
  const selectedEsgOpportunitiesLevel = useMemo(
    () => options.find((o) => o.id === esgOpportunitiesLevel) || null,
    [esgOpportunitiesLevel]
  );

  return (
    <FormField label='ESG Opportunities' style={{ marginBottom: '16px' }}>
      <ChipsSelector
        options={options}
        onSelect={(item) => onChangeEsgOpportunitiesLevel(item)}
        selectedItem={selectedEsgOpportunitiesLevel}
        noCheckIcon
      />
    </FormField>
  );
};
