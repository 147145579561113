import { styled, useTheme } from '@mui/material';
import { Checkbox } from '../../../../../components/Checkbox/Checkbox';
import { QuestionOption } from '../../../../../types';
import { Typography } from '../../../../../components/Typography/Typography';
import { useState } from 'react';
import { NotesBoxPopup } from '../../../../../components/NotesBoxPopup/NotesBoxPopup';
import { AddNoteWrapper, OptionWrapper } from '../common';
import { ConfirmDeleteModal } from '../../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { AddNote } from '../../../../../components/AddNote/AddNote';

const OptionTextWrapper = styled('div')`
  position: relative;
  display: flex;
`;

interface Props {
  option: QuestionOption;
  onCheckboxClick: (option: QuestionOption) => void;
  optionResponse?: QuestionOption;
  onFocus: () => void;
  onChangeNote: (notes: string, option: QuestionOption) => void;
  onDeleteNote: (option: QuestionOption) => void;
  isDisabled?: boolean;
}

export const CheckboxOption = ({
  option,
  onCheckboxClick,
  optionResponse,
  onFocus,
  onChangeNote,
  onDeleteNote,
  isDisabled,
}: Props) => {
  const { colors } = useTheme();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <OptionWrapper
      key={option.id}
      onClick={() => onCheckboxClick(option)}
      disabled={isDisabled}
      tabIndex={0}
    >
      <Checkbox
        inputProps={{ onFocus: onFocus }}
        checked={Boolean(optionResponse) ?? false}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 18,
          },
        }}
        disabled={isDisabled}
      />
      <OptionTextWrapper>
        <NotesBoxPopup
          notes={optionResponse?.notes || ''}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          onChangeNotes={(notes) => onChangeNote(notes, option)}
          withActionButtons
          popupPlacement='right-start'
          popupOffset={{ mainAxis: 8, crossAxis: -8 }}
          isDisabled={isDisabled}
        >
          <Typography
            variant='h5'
            color={colors.primary[70]}
            style={{
              lineHeight: 1.2,
              background: optionResponse?.notes ? colors.warning[20] : 'transparent',
            }}
          >
            {option.name}
          </Typography>
        </NotesBoxPopup>
        {Boolean(optionResponse) && !optionResponse?.notes && !isDisabled && (
          <AddNoteWrapper onClick={(e) => e.stopPropagation()}>
            <AddNote
              onNotesChange={(notes) => onChangeNote(notes, option)}
              popupPlacement='bottom-end'
            />
          </AddNoteWrapper>
        )}
        <ConfirmDeleteModal
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onConfirm={() => {
            onDeleteNote(option);
            setIsDeleteModalOpen(false);
          }}
          title='Delete this note?'
          note='This action cannot be undone.'
          confirmLabel='Yes, Delete'
          cancelLabel='No, Cancel'
          isOpen={isDeleteModalOpen}
        />
      </OptionTextWrapper>
    </OptionWrapper>
  );
};
