import { styled, useTheme } from '@mui/material';
import { Exclusion } from '../../../../types';
import { ExclusionItem } from './ExclusionItem';
import { Typography } from '../../../../components/Typography/Typography';
import { useCompanyExclusions } from '../../../SIngleCompany/hooks/useCompanyExclusions';

import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { companyExclusionIdsState } from '../../../../state/UIState';

const ExclusionsContainer = styled('div')`
  background-color: #fff;
  margin-top: 8px;
  overflow: auto;
  height: ${() => `calc(100% - 122px)`};
`;

const NoExclusionsWrapper = styled('div')`
  margin-top: 8px;
`;

interface Props {
  exclusions: Exclusion[];
}

export const ExclusionsList = ({ exclusions }: Props) => {
  const { colors } = useTheme();
  const { data: companyExclusions } = useCompanyExclusions();
  const [companyExclusionIds, setCompanyExclusionIds] = useAtom(companyExclusionIdsState);

  const existingCompanyExclusionIds = useMemo(() => {
    return companyExclusions?.map((exclusion) => exclusion.id);
  }, [companyExclusions]);

  const totalExclusionIds = useMemo(() => {
    return [...existingCompanyExclusionIds, ...companyExclusionIds];
  }, [companyExclusionIds, existingCompanyExclusionIds]);

  const onToggleCheck = (exclusionId: number) => {
    if (companyExclusionIds?.includes(exclusionId)) {
      setCompanyExclusionIds((prev) => {
        return prev.filter((prevItem) => prevItem !== exclusionId);
      });
      return;
    }

    setCompanyExclusionIds((prev) => {
      return [...prev, exclusionId];
    });
  };

  if (!exclusions?.length) {
    return (
      <NoExclusionsWrapper>
        <Typography variant='body' color={colors.primary[90]} style={{ maxWidth: '600px' }}>
          No exclusions
        </Typography>
      </NoExclusionsWrapper>
    );
  }

  return (
    <ExclusionsContainer>
      {exclusions?.map((exclusion) => {
        return (
          <ExclusionItem
            isChecked={totalExclusionIds?.includes(exclusion.id)}
            isDisabled={existingCompanyExclusionIds?.includes(exclusion.id)}
            key={exclusion.id}
            exclusion={exclusion}
            onToggleCheck={onToggleCheck}
          />
        );
      })}
    </ExclusionsContainer>
  );
};
