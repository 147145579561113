import { styled } from '@mui/material';
import { FC } from 'react';

type Props = {
  src: string;
  size?: 'small' | 'medium';
  hasBorder?: boolean;
  style?: React.CSSProperties | undefined;
};

const Img = styled('img')<{ size: 'small' | 'medium'; hasBorder?: boolean }>`
  width: ${({ size }) => (size === 'small' ? '16px' : '24px')};
  height: ${({ size }) => (size === 'small' ? '16px' : '24px')};
  border-radius: 50%;
  object-fit: contain;
  ${({ hasBorder, theme }) =>
    hasBorder ? `border: 0.5px solid ${theme.colors.primary[20]};` : ''};
`;

export const CompanyLogo: FC<Props> = ({ src, size = 'small', hasBorder, style }) => {
  return <Img src={src} size={size} hasBorder={hasBorder} style={style} />;
};
