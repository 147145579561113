import { useMemo } from 'react';
import { useSurveys } from '../queries/useSurveys';
import { INVESTOR_EXCLUSIONS_SURVEY_ID } from '../constants/constants';

export const useExclusionScreeningSurvey = () => {
  const { data: surveys } = useSurveys();

  return useMemo(() => {
    if (!surveys) return;
    return surveys.find((survey) => survey.id === INVESTOR_EXCLUSIONS_SURVEY_ID);
  }, [surveys]);
};
