import { Button, useTheme } from '@mui/material';
import { CSVLink } from 'react-csv';
import { Typography } from '../../../../components/Typography/Typography';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-file.svg';
import { QuestionTableData } from '../../../../types';
import { FC, useMemo } from 'react';

interface Props {
  tableData: QuestionTableData;
}

export const DownloadCSVLink: FC<Props> = ({ tableData }) => {
  const { colors } = useTheme();

  const csvTemplateData = useMemo(() => {
    const { rows, columns } = tableData;
    const [headerColumn, ...restColumns] = columns;

    return rows.map((row) => {
      return [row[headerColumn], ...restColumns.map((column) => row[column] ?? null)];
    });
  }, [tableData]);

  return (
    <CSVLink data={csvTemplateData} filename={'template.csv'}>
      <Button variant='text' startIcon={<DownloadIcon />} style={{ minWidth: 'auto' }}>
        <Typography variant='subtitle2' color={colors.accent[50]}>
          Download as CSV Template
        </Typography>
      </Button>
    </CSVLink>
  );
};
