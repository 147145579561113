import { styled, useTheme } from '@mui/material';
import { USER_APPROVAL_REQUIREMENT_OPTIONS } from '../../../../../../types';
import { Typography } from '../../../../../../components/Typography/Typography';

const ItemWrapper = styled('div')<{ hasNotes?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  icon: JSX.Element;
  option: USER_APPROVAL_REQUIREMENT_OPTIONS;
}

export const RequirementOption = ({ icon, option }: Props) => {
  const { colors } = useTheme();

  return (
    <ItemWrapper>
      {icon}
      <Typography variant='body' color={colors.primary[90]}>
        {option}
      </Typography>
    </ItemWrapper>
  );
};
