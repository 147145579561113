import { styled } from '@mui/material';
import { useCoreKpiCategories } from '../../../../../../../../../queries/useCoreKpiCategories';
import { useMemo } from 'react';
import { CORE_KPI_FORMAT, KEY_TASK_CONDITION_OPERATOR } from '../../../../../../../../../types';
import { ConditionOperatorSelect } from './ConditionOperatorSelect';
import { KpiSelect } from './KpiSelect';
import { ConditionValue } from './ConditionValue';

const Container = styled('div')`
  display: flex;
`;

interface Props {
  kpi?: number | null;
  conditionValue?: string;
  conditionOperator?: KEY_TASK_CONDITION_OPERATOR;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const KpiGoalValue = ({ kpi, conditionValue, conditionOperator, setFieldValue }: Props) => {
  const { data: coreKpiCategories } = useCoreKpiCategories();

  const kpis = useMemo(() => {
    const kpis = coreKpiCategories
      ?.flatMap((category) => category?.kpis || [])
      ?.filter(
        (kpi) => kpi.format === CORE_KPI_FORMAT.NUMBER || kpi.format === CORE_KPI_FORMAT.PERCENTAGE
      );

    return kpis;
  }, [coreKpiCategories]);

  const selectedKPIFormat = useMemo(() => kpis?.find((o) => o.id === kpi)?.format, [kpi, kpis]);

  return (
    <Container>
      <KpiSelect kpi={kpi} setFieldValue={setFieldValue} />
      <ConditionOperatorSelect
        conditionOperator={conditionOperator}
        setFieldValue={setFieldValue}
      />
      <ConditionValue
        conditionValue={conditionValue}
        setFieldValue={setFieldValue}
        selectedKPIFormat={selectedKPIFormat}
      />
    </Container>
  );
};
