import { FC, useCallback } from 'react';
import { QUESTION_TYPE, QUESTION_MANDATORY_OPTIONS, SelectItem } from '../../../../types';

import { useAtom } from 'jotai';
import { activeQuestionIdState, questionState, responsesState } from '../../../../state/UIState';
import {
  getDefaultQuestionOption,
  getDefaultSingleTypeQuestionOptions,
  getDefaultTableData,
} from '../../../../constants/defaultValues';
import { TypeSelector } from './components/TypeSelector';

export const QuestionTypeSelector: FC = () => {
  const [activeQuestionId] = useAtom(activeQuestionIdState);
  const [activeQuestion, setQuestion] = useAtom(questionState(activeQuestionId));
  const [, setResponses] = useAtom(responsesState);

  const onChangeQuestionMandatory = useCallback(
    (item: SelectItem) => {
      if (!activeQuestion) return;
      const updatedQuestion = {
        ...activeQuestion,
        isRequired: item.id === QUESTION_MANDATORY_OPTIONS.REQUIRED,
      };
      setQuestion(updatedQuestion);
    },
    [activeQuestion, setQuestion]
  );

  const onClick = useCallback(
    (item: SelectItem) => {
      if (!activeQuestion) return;
      const updatedQuestion = {
        ...activeQuestion,
        type: item.id as QUESTION_TYPE,
        isRequired: activeQuestion?.isRequired,
        tableData: activeQuestion.tableData ? activeQuestion.tableData : getDefaultTableData(),
      };
      if (item.id === QUESTION_TYPE.SINGLE) {
        updatedQuestion.options = getDefaultSingleTypeQuestionOptions();
      }
      if (item.id === QUESTION_TYPE.MULTI) {
        updatedQuestion.options = [getDefaultQuestionOption()];
      }
      setQuestion(updatedQuestion);
      setResponses((prev) => {
        return {
          ...prev,
          [activeQuestionId as string]: null,
        };
      });
    },
    [activeQuestion, activeQuestionId, setQuestion, setResponses]
  );

  return (
    <TypeSelector
      onChangeType={onClick}
      onChangeQuestionMandatory={onChangeQuestionMandatory}
      isRequired={!!activeQuestion?.isRequired}
      questionType={activeQuestion?.type}
    />
  );
};
