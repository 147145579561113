import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';
import {
  LoadingId,
  RequestGroup,
  Response,
  SurveySection,
  SURVEY_FREQUENCY,
  QUESTION_TYPE,
  QuestionTableData,
  KPISValueResponse,
} from '../types';
import { SURVEYS } from './useSurveys';
import { flattenTree } from '../utils/treeUtilities';

export const REQUEST_GROUPS = 'requestGroups';

export const getReportProgressPercentage = (
  sectionsMap: Map<string, SurveySection>,
  response: Record<string, Response>
) => {
  const allSectionsArr = Array.from(sectionsMap.values());
  const stats = allSectionsArr.reduce(
    (acc: Record<'all' | 'answered', number>, section) => {
      const answeredQuestions = section.questions.filter((question) => {
        if (question.type === QUESTION_TYPE.TABLE) {
          const tableData = question.tableData as QuestionTableData;
          const readOnlyColumns = tableData?.readOnlyColumns ?? [];
          return isTableQuestionAnswered(
            response[question.id] as QuestionTableData,
            readOnlyColumns
          );
        }
        return response[question.id] !== null && response[question.id] !== undefined;
      });
      acc.all += section.questions.length;
      acc.answered += answeredQuestions.length;
      return acc;
    },
    { all: 0, answered: 0 }
  );
  const percentage = (stats.answered / stats.all) * 100;
  return Math.round(isNaN(percentage) ? 0 : percentage);
};

export const isTableQuestionAnswered = (response: QuestionTableData, readOnlyColumns: string[]) => {
  let allEditableCells = 0;
  let allAnsweredCells = 0;

  for (let i = 0; i < response?.rows?.length; i++) {
    if (i === 0) continue; // skip header row (first row)
    const row = response?.rows[i];
    for (let j = 0; j < response?.columns.length; j++) {
      if (j === 0) continue; // skip header column (first column)
      const column = response?.columns[j];
      if (readOnlyColumns.includes(column)) continue; // skip read only columns
      allEditableCells++;
      const columnValue =
        typeof row[column] === 'object' ? (row[column] as KPISValueResponse)?.value : row[column];

      if (columnValue) {
        allAnsweredCells++;
      }
    }
  }

  return allEditableCells === allAnsweredCells;
};

const enrichData = (requestGroup: RequestGroup) => {
  const { survey } = requestGroup.requests[0];
  const { sections } = survey;
  const flatSurveys = flattenTree(sections);
  const sectionsMap = new Map(flatSurveys.map((section) => [section.id, section]));
  return {
    ...requestGroup,
    survey: requestGroup.requests[0].survey,
    frequency: requestGroup.requests[0].frequency,
    requests: requestGroup.requests.map((request) => {
      return {
        ...request,
        reports: request.reports.map((report) => {
          return {
            ...report,
            progress: report?.response
              ? getReportProgressPercentage(sectionsMap, report.response)
              : 0,
          };
        }),
      };
    }),
  };
};

async function fetchRequestGroups(): Promise<RequestGroup[]> {
  const { data } = await Http.axios.get<RequestGroup[]>(`/request-group`);
  data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  return data.map((requestGroup) => {
    return enrichData(requestGroup);
  });
}

export function useRequestGroups() {
  return useQuery(REQUEST_GROUPS, () => fetchRequestGroups(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function fetchRequestGroupById(id: number): Promise<RequestGroup> {
  const { data } = await Http.axios.get<RequestGroup>(`/request-group/${id}`);
  return enrichData(data);
}

export function useRequestGroupById(id: number) {
  return useQuery(`${REQUEST_GROUPS}-${id}`, () => fetchRequestGroupById(id), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

export interface RequestGroupPayload {
  id?: number;
  name: string | null;
  frequency: SURVEY_FREQUENCY | null;
  companyIds: number[];
  survey: number | null;
}

async function createRequestGroup(payload: RequestGroupPayload): Promise<RequestGroup> {
  const { data } = await Http.axios.post<RequestGroupPayload, RequestGroup>(
    `/request-group`,
    payload
  );
  return data;
}

export function useCreateRequestGroup() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast, pushSuccessToast } = useToastMessage();
  const queryClient = useQueryClient();

  return useMutation(createRequestGroup, {
    onMutate: async () => {
      startLoading(LoadingId.createRequestGroup);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create request group' });
    },
    onSuccess: (newRequestGroup) => {
      const requestGroup = enrichData(newRequestGroup);
      queryClient.setQueryData(REQUEST_GROUPS, (oldRequestGroups: RequestGroup[] | undefined) =>
        oldRequestGroups
          ? [requestGroup as RequestGroup, ...oldRequestGroups]
          : [requestGroup as RequestGroup]
      );
      queryClient.refetchQueries(SURVEYS);
      pushSuccessToast({
        message: `Request Group ${newRequestGroup.requests[0].name} created successfully`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(REQUEST_GROUPS);
      stopLoading(LoadingId.createRequestGroup);
    },
  });
}

async function updateRequestGroup(requestGroupPayload: RequestGroupPayload): Promise<RequestGroup> {
  const { id, ...payload } = requestGroupPayload;
  const { data } = await Http.axios.patch<RequestGroupPayload, RequestGroup>(
    `/request-group/${id}`,
    payload
  );
  return data;
}

export function useUpdateRequestGroup() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast, pushSuccessToast } = useToastMessage();

  return useMutation(updateRequestGroup, {
    onMutate: async () => {
      startLoading(LoadingId.updateRequestGroup);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update request group' });
    },
    onSuccess: (updatedRequestGroup) => {
      pushSuccessToast({
        message: `Request Group ${updatedRequestGroup.requests[0].name} was updated successfully`,
      });
    },
    onSettled: (updatedRequestGroup) => {
      queryClient.invalidateQueries(REQUEST_GROUPS);
      queryClient.removeQueries(`${REQUEST_GROUPS}-${updatedRequestGroup?.id}`);
      stopLoading(LoadingId.updateRequestGroup);
    },
  });
}

async function deleteRequestGroup(id: number): Promise<number> {
  const { data } = await Http.axios.delete<number>(`/request-group/${id}`);
  return data;
}

export function useDeleteRequestGroup() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(deleteRequestGroup, {
    onMutate: async (requestGroupId) => {
      startLoading(LoadingId.deleteRequestGroup);
      await queryClient.cancelQueries(REQUEST_GROUPS);
      const previousRequestGroups = queryClient.getQueryData(REQUEST_GROUPS);
      queryClient.setQueryData(REQUEST_GROUPS, (oldRequestGroups: RequestGroup[] | undefined) => {
        return (
          oldRequestGroups?.filter((requestGroup) => {
            return requestGroup.id !== requestGroupId;
          }) ?? []
        );
      });
      return { previousRequestGroups };
    },
    onError: (error, _, context) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to delete request group' });
      queryClient.setQueryData(REQUEST_GROUPS, context?.previousRequestGroups);
    },
    onSettled: () => {
      queryClient.invalidateQueries(REQUEST_GROUPS);
      queryClient.refetchQueries(SURVEYS);
      stopLoading(LoadingId.deleteRequestGroup);
    },
  });
}
