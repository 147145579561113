import { Button, styled } from '@mui/material';
import { ReactComponent as AddIcon } from '../../../../../../../assets/icons/add-blue.svg';
import { useCreateExclusion } from '../../../../../../../queries/useExclusions';
import { Exclusion } from '../../../../../../../types';
import { ExclusionItems } from './ExclusionItems';
import { useAppConfig, useUpdateAppConfig } from '../../../../../../../queries/useAppConfig';

const DetailsWrapper = styled('div')``;

interface Props {
  exclusions: Exclusion[];
  conditionId: number;
}

export const Exclusions = ({ exclusions, conditionId }: Props) => {
  const { mutate: createExclusion } = useCreateExclusion();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const onCreateExclusion = () => {
    const payload = {
      isActive: false,
      isDeleted: false,
      order: exclusions?.length ? exclusions?.length + 1 : 0,
      condition: conditionId,
      exclusion: 'Exclusion text',
    };
    createExclusion(payload, {
      onSuccess: async () => {
        if (!appConfigData?.data) {
          return;
        }
        await updateAppConfig.mutateAsync({
          data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
        });
      },
    });
  };

  return (
    <DetailsWrapper>
      {Boolean(exclusions?.length) && <ExclusionItems exclusions={exclusions} />}
      <Button
        variant='outlined'
        style={{ height: '32px', width: 'calc(100% - 64px)', marginLeft: '32px' }}
        startIcon={<AddIcon style={{ transform: 'scale(0.7)' }} />}
        onClick={onCreateExclusion}
      >
        Add Investor Exclusion
      </Button>
    </DetailsWrapper>
  );
};
