import { styled, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';

import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { doNothingFunc } from '../../../utils/doNothingFunc';
import { useKpiMatrix } from '../hooks/useKpiMatrix';
import { Modal } from '../../../components/Modal/Modal';

interface Props {
  onClose: () => void;
  startYear: string;
  endYear: string;
  isOpen: boolean;
}

const FormWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
`;

const FieldWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Delimiter = styled('div')`
  width: 8px;
  height: 27px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[70]};
`;

export const KpisMatrixTimeframeModal: FC<Props> = ({ onClose, startYear, endYear, isOpen }) => {
  const { colors } = useTheme();
  const [startYearValue, setStartYearValue] = useState(startYear);
  const { onTimeframeChange } = useKpiMatrix();

  const onSubmit = useCallback(() => {
    onTimeframeChange(parseInt(startYearValue));
    onClose();
  }, [onClose, onTimeframeChange, startYearValue]);

  return (
    <Modal
      isOpen={isOpen}
      title='Manage Timeframe'
      infoText='The start year will determine the column values and their segmentation, based on your chosen frequency.'
      onClose={onClose}
      onConfirm={onSubmit}
      isConfirmDisabled={startYear === startYearValue}
    >
      <FormWrapper>
        <FieldWrapper>
          <Typography variant={'subtitle1'} color={colors.primary[90]} className='Label'>
            Start Year
          </Typography>
          <DatePicker
            selectedDate={startYearValue}
            setSelectedDate={(date) =>
              date?.getFullYear() && setStartYearValue(date.getFullYear().toString())
            }
            width='230px'
            views={['year']}
            shouldDisableYear={(year) => {
              return year.getFullYear() > parseInt(endYear) || year.getFullYear() < 2000;
            }}
          />
        </FieldWrapper>
        <Delimiter />
        <FieldWrapper>
          <Typography variant={'subtitle1'} color={colors.primary[90]} className='Label'>
            End Year
          </Typography>
          <DatePicker
            selectedDate={endYear}
            setSelectedDate={doNothingFunc}
            width='230px'
            views={['year']}
            disabled
          />
        </FieldWrapper>
      </FormWrapper>
    </Modal>
  );
};
