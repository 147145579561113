import { useAtom } from 'jotai';
import { useCallback } from 'react';
import {
  exportCompaniesProgressState,
  exportColumnsState,
  exportCompaniesState,
  exportTypeState,
} from '../../../state/UIState';

export function useResetExportCompaniesState() {
  const [, setProgress] = useAtom(exportCompaniesProgressState);
  const [, setSelectedColumns] = useAtom(exportColumnsState);
  const [, setSelectedCompanies] = useAtom(exportCompaniesState);
  const [, setExportType] = useAtom(exportTypeState);

  const resetState = useCallback(() => {
    setProgress(0);
    setSelectedColumns([]);
    setSelectedCompanies([]);
    setExportType(null);
  }, [setExportType, setProgress, setSelectedColumns, setSelectedCompanies]);

  return resetState;
}
