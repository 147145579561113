import { styled, TextField, useTheme } from '@mui/material';
import { Typography } from '../../../../../../components/Typography/Typography';
import { useState } from 'react';

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  weightValue: number | string;
  onUpdateWeight: (weight: number) => void;
}

export const WeightInput = ({ weightValue, onUpdateWeight }: Props) => {
  const { colors } = useTheme();
  const [value, setValue] = useState(String(weightValue));

  return (
    <Container>
      <Typography variant='srOnly' color={colors.primary[90]}>
        Weight:
      </Typography>
      <TextField
        type='number'
        name={'weight'}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => onUpdateWeight(Number(value))}
        variant='outlined'
        style={{ width: '129px' }}
      />
    </Container>
  );
};
