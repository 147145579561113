import { useWorkflowStepForReport } from './useWrokflowStepForReport';

export const useIsStepCompleted = () => {
  const workflowStep = useWorkflowStepForReport();

  const getIsStepCompleted = (reportApprovedBy: number[]) => {
    if (workflowStep?.requiredUsersApproval?.length) {
      const requiredUsersApprovalIds = workflowStep?.requiredUsersApproval?.map((user) => user.id);

      return requiredUsersApprovalIds?.every((id) => reportApprovedBy?.includes(id));
    }

    return reportApprovedBy?.length;
  };
  return getIsStepCompleted;
};
