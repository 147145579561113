import { styled } from '@mui/material';
import { ReactComponent as EditBox } from '../../assets/icons/edit-box.svg';

const Container = styled('div')<{ isCTADisabled?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: ${({ theme, isCTADisabled }) =>
    isCTADisabled ? theme.colors.gray[20] : theme.colors.primary[0]};
  border-radius: 4px;
  cursor: ${({ isCTADisabled }) => (isCTADisabled ? 'not-allowed' : 'pointer')};
`;

export const AddNoteCTA = () => {
  return (
    <Container>
      <EditBox />
    </Container>
  );
};
