import { Chip, styled, useTheme } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Sector, SelectableCompanyTableData } from '../../types';
import { Checkbox } from '../Checkbox/Checkbox';
import { CompanyCell } from '../Table/CellRenderers/CompanyCell';
import { ReactComponent as CheckIcon } from '../../assets/icons/checked-blue.svg';
import { Typography } from '../Typography/Typography';
import { SectorsCell } from '../Table/CellRenderers/SectorsCell';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
  gap: 8px;
`;

const CompanyLeftSection = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const CompanyRightSection = styled('div')`
  display: flex;
  align-items: center;
`;

const columnHelper = createColumnHelper<SelectableCompanyTableData>();

export const useTableColumns = (disabledText?: string) => {
  const { colors } = useTheme();

  return useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <CellWrapper>
            <CompanyLeftSection>
              <Checkbox
                checked={info.row.original.disabled ? false : info.row.getIsSelected()}
                disabled={!!info.row.original.disabled}
              />
              <CompanyCell name={info.getValue()} logo={info.row.original.logoUrl} />
            </CompanyLeftSection>
            {!!info.row.original.disabled && (
              <CompanyRightSection>
                <CheckIcon style={{ marginRight: '4px', marginTop: '-2px' }} />
                <Typography variant='srOnly' color={colors.primary[90]}>
                  {disabledText || ''}
                </Typography>
              </CompanyRightSection>
            )}
          </CellWrapper>
        ),
        header: ({ table }) => (
          <CellWrapper>
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                table.getToggleAllRowsSelectedHandler()(e);
              }}
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
              }}
            />
            <Typography variant='overline' color={colors.primary[70]}>
              Company
            </Typography>
          </CellWrapper>
        ),
        meta: {
          width: '26%',
        },
        enableColumnFilter: true,
      }),
      columnHelper.accessor('type', {
        cell: (info) => (info.getValue() ? <Chip label={info.getValue()} /> : null),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Type
          </Typography>
        ),
        meta: {
          width: '12%',
        },
        enableSorting: false,
        filterFn: 'equalsString',
        enableColumnFilter: true,
      }),
      columnHelper.accessor('sectors', {
        cell: (info) => <SectorsCell sectors={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Sector
          </Typography>
        ),
        meta: {
          width: '37%',
        },
        enableSorting: false,
        filterFn: (row: any, columnId, selectedFilters) => {
          const values: string[] = row.getValue(columnId).map((s: Sector) => s.name);
          return selectedFilters.some((filter: string) => values.includes(filter));
        },
        enableColumnFilter: true,
      }),
      columnHelper.accessor('country', {
        cell: (info) => (info.getValue() ? <Chip label={info.getValue()} /> : null),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Country
          </Typography>
        ),
        meta: {
          width: '25%',
        },
        filterFn: (row: any, columnId, selectedFilters) => {
          const value: string = row.getValue(columnId);
          return selectedFilters.includes(value);
        },
        enableColumnFilter: true,
      }),
    ],
    [colors.primary, disabledText]
  );
};
