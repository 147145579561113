import { useState } from 'react';
import { Company } from '../../../../types';
import { AssignEmailCellContent } from '../AssignEmailCellContent/AssignEmailCellContent';
import { EsgContactModal } from '../../../../components/EsgContactModal/EsgContactModal';
import { ConfirmDeleteModal } from '../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { DeleteModalWarning } from '../AssignEmailCellContent/DeleteModalWarning';
import { useUpdateCompany } from '../../../../queries/useCompanies';

interface Props {
  company: Company;
}

export const AssignESGContactCell = ({ company }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const updateCompany = useUpdateCompany();

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const onDeleteAnalyst = () => {
    onDeleteModalClose();
    updateCompany.mutate({ id: company.id, esgContact: null });
  };

  return (
    <>
      <AssignEmailCellContent
        isAssigned={Boolean(company?.externalEsgContact?.email)}
        onOpenEditModal={onOpenModal}
        onOpenModal={onOpenModal}
        onOpenDeleteModal={() => setIsDeleteModalOpen(true)}
        assignedEmail={company?.externalEsgContact?.email || ''}
        unassignedEmailText='Assign ESG Contact'
      />
      <EsgContactModal
        isOpen={isModalOpen}
        company={company}
        onClose={() => setIsModalOpen(false)}
      />
      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        title='Delete ESG Contact?'
        onConfirm={onDeleteAnalyst}
        infoContent={<DeleteModalWarning email={company?.externalEsgContact?.email || ''} />}
        isInfoContentWarning
      />
    </>
  );
};
