import { Tab, Tabs, useTheme } from '@mui/material';
import { Typography } from './Typography/Typography';
import { useLocation, useNavigate } from 'react-router';
import { ChangeEvent } from 'react';

type TTab = {
  label: string;
  path: string;
};

interface Props {
  tabValue: string;
  setTabValue: (val: string) => void;
  tabs: TTab[];
}

export const PageTabs = ({ tabValue, setTabValue, tabs }: Props) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleChange = (_: ChangeEvent<object>, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Tabs
      value={tabValue}
      onChange={handleChange}
      aria-label='simple tabs example'
      sx={{
        minHeight: '0',
        height: '42px',
        padding: '0 16px',
        borderBottom: `1px solid ${colors.primary[30]}`,
        '.MuiTabs-flexContainer': {
          height: '100%',
          gap: '16px',
          textTransform: 'none',
          paddingLeft: '24px',
          position: 'relative',
        },
      }}
    >
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.path}
            className={`mui-custom-tab`}
            onClick={() => navigate(tab.path, { state })}
            value={tab.path}
            sx={{
              '&&.mui-custom-tab.Mui-selected p': {
                color: colors.accent[50],
              },
            }}
            label={
              <Typography
                variant='subtitle2'
                color={colors.primary[70]}
                style={{ textTransform: 'none' }}
              >
                {tab.label}
              </Typography>
            }
          />
        );
      })}
    </Tabs>
  );
};
