import { styled, Switch, useTheme } from '@mui/material';
import { FC } from 'react';
import { Typography } from '../../../../../components/Typography/Typography';

import { SurveyQuestion } from '../../../../../types';

interface Props {
  question: SurveyQuestion;
  toggleCustomEvaluation: () => void;
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 7px;
`;
const SwitchWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: -9px;
`;

export const EvaluationControls: FC<Props> = ({ question, toggleCustomEvaluation }) => {
  const { colors } = useTheme();

  return (
    <Wrapper>
      <SwitchWrapper>
        <Switch checked={!!question.includeEvaluation} onChange={toggleCustomEvaluation} />
        <Typography variant='body' color={colors.primary[80]}>
          Include evaluation
        </Typography>
      </SwitchWrapper>
    </Wrapper>
  );
};
