import { useMemo } from 'react';
import { CompanyPageSkeletonLoader } from '../../components/SkeletonLoader/CompanyPage.SkeletonLoader';
import { CompanyExclusionsHeader } from './components/CompanyExclusionsHeader';
import { InvestorExclusionsTable } from './components/InvestorExclusionsTable';
import { Typography } from '../../components/Typography/Typography';
import { ButtonBase, styled, useTheme } from '@mui/material';
import { useCompanyExclusions } from '../SIngleCompany/hooks/useCompanyExclusions';
import { ROUTES } from '../../constants/routes';
import { useNavigate } from 'react-router';

const Wrapper = styled('div')``;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 0 20px 40px;
  gap: 21px;
`;

export const CompanyExclusions = () => {
  const { colors } = useTheme();
  const { isLoading, data: exclusions } = useCompanyExclusions();
  const navigate = useNavigate();

  const company = useMemo(() => {
    if (!exclusions) return null;
    return exclusions?.[0]?.company;
  }, [exclusions]);

  if (isLoading || !exclusions || !company) return <CompanyPageSkeletonLoader />;
  return (
    <Wrapper>
      <CompanyExclusionsHeader company={company} />
      <TitleWrapper>
        <Typography variant='h4' color={colors.primary[90]}>
          Exclusions
        </Typography>
        <ButtonBase
          onClick={() => navigate(`/${ROUTES.COMPANIES}/${company.id}/${ROUTES.ADD_EXCLUSIONS}`)}
        >
          <Typography variant='subtitle2' color={colors.accent[50]}>
            + Add Exclusion
          </Typography>
        </ButtonBase>
      </TitleWrapper>
      <InvestorExclusionsTable exclusions={exclusions} />
    </Wrapper>
  );
};
