import { styled, useTheme } from '@mui/material';
import { ExclusionCategory } from '../../../../types';
import { CategoryDropdown } from './CategoryDropdown';
import { Typography } from '../../../../components/Typography/Typography';

const CategoriesContainer = styled('div')`
  padding-bottom: 16px;
  height: 100%;
  overflow: auto;
`;

const NoCategoriesContainer = styled('div')`
  padding: 0 24px;
`;

interface Props {
  categoriesList: ExclusionCategory[];
}

export const CategoriesList = ({ categoriesList }: Props) => {
  const { colors } = useTheme();

  if (!categoriesList?.length) {
    return (
      <NoCategoriesContainer>
        <Typography variant='body' color={colors.primary[90]} style={{ maxWidth: '600px' }}>
          No categories
        </Typography>
      </NoCategoriesContainer>
    );
  }

  return (
    <CategoriesContainer>
      {categoriesList?.map((category) => {
        return <CategoryDropdown key={category.id} category={category} />;
      })}
    </CategoriesContainer>
  );
};
