import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useCompanyById } from '../../../../../queries/useCompanies';

export const useHasAnalystRating = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));

  return useMemo(
    () => !!company?.esgRiskLevel && !!company?.esgOpportunitiesLevel,
    [company?.esgOpportunitiesLevel, company?.esgRiskLevel]
  );
};
