import { useParams } from 'react-router';
import { useExclusionScreeningSurvey } from '../../../hooks/useExclusionScreeningSurvey';
import { useInvestorExclusionScreeningReport } from '../../../hooks/useInvestorExclusionScreeningReport';
import { useOnReportClick } from '../../../hooks/useOnReportClick';
import { Task } from '../TaskGroup';
import { TaskReportIndicator } from '../TaskGroup/TaskReportIndicator';
import { useInvestorExclusionClick } from '../../../pages/SIngleCompany/hooks/useInvestorExclusionClick';
import { REPORT_STATUS } from '../../../types';
import { useMemo } from 'react';
import { useReportStatusIndicator } from '../../../pages/SIngleCompany/hooks/useReportStatusIndicator';

interface Props {
  hasAccess?: boolean;
  isBlocked?: boolean;
  hasEvaluationAccess?: boolean;
  isStepCompleted?: boolean;
}

export const ScreeningExclusionStep = ({
  hasAccess,
  isBlocked,
  hasEvaluationAccess,
  isStepCompleted,
}: Props) => {
  const params = useParams();
  const { onInvestorExclusionsScreeningClickHandler } = useInvestorExclusionClick();

  const exclusionScreeningSurvey = useExclusionScreeningSurvey();
  const investorExclusionScreeningReport = useInvestorExclusionScreeningReport(Number(params.id));
  const { onReportClick } = useOnReportClick();

  const reportStatus = useReportStatusIndicator(investorExclusionScreeningReport);

  const isInitialAccess = useMemo(() => {
    return (
      !investorExclusionScreeningReport ||
      investorExclusionScreeningReport?.status === REPORT_STATUS.NOT_STARTED ||
      investorExclusionScreeningReport?.status === REPORT_STATUS.IN_PROGRESS ||
      investorExclusionScreeningReport?.status === REPORT_STATUS.REJECTED ||
      investorExclusionScreeningReport?.status === REPORT_STATUS.SUBMITTED
    );
  }, [investorExclusionScreeningReport]);

  const hasInitialAccess = hasAccess && isInitialAccess;

  const isEvaluationAccess = useMemo(
    () => investorExclusionScreeningReport?.status === REPORT_STATUS.IN_REVIEW,
    [investorExclusionScreeningReport?.status]
  );

  const hasEvalAccess = isEvaluationAccess && hasEvaluationAccess && !isStepCompleted;

  const isDisabled = isBlocked || (!hasInitialAccess && !hasEvalAccess);

  return (
    <Task
      taskName={`${exclusionScreeningSurvey?.name}`}
      onTaskNameClick={
        investorExclusionScreeningReport
          ? () => onReportClick(investorExclusionScreeningReport)
          : undefined
      }
      isHighlighted={
        (hasInitialAccess || hasEvalAccess) &&
        reportStatus !== REPORT_STATUS.APPROVED &&
        reportStatus !== REPORT_STATUS.SUBMITTED
      }
      disabled={isDisabled}
    >
      <TaskReportIndicator
        onCreateReport={() =>
          onInvestorExclusionsScreeningClickHandler(
            investorExclusionScreeningReport,
            exclusionScreeningSurvey
          )
        }
        report={investorExclusionScreeningReport}
        buttonText='Proceed to Assessment'
        disabled={isDisabled}
      />
    </Task>
  );
};
