import { styled } from '@mui/material';
import { HEADER_HEIGHT, PORTAL_HEADER_HEIGHT } from '../../../constants/layoutSizes';
import { FC } from 'react';

import { ReactComponent as StarcierLogo } from '../../../assets/icons/starcier.svg';
import { ReactComponent as SVPLogo } from '../../../assets/icons/svpLogo.svg';
import { InternalQuestionnaireBreadcrumbs } from './InternalQuestionnaireBreadcrumbs';

const Wrapper = styled('div')<{ isInternal?: boolean }>`
  height: ${({ isInternal }) => (isInternal ? `${HEADER_HEIGHT}px` : `${PORTAL_HEADER_HEIGHT}px`)};
  display: flex;
  align-items: center;
  padding: ${({ isInternal }) => (isInternal ? '0 40px' : '0 20px')};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[30]};
  position: sticky;
  top: 0;
`;

const LogoWrapper = styled('div')`
  width: 450px;
  display: flex;
  align-items: center;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PortalLogosWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const StarcierLogoWrapper = styled('div')`
  height: 20px;
  margin-right: 16px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

interface Props {
  isInternal?: boolean;
}

export const ProgressHeader: FC<Props> = ({ isInternal }) => {
  return (
    <Wrapper isInternal={isInternal}>
      <LogoWrapper>
        {isInternal ? (
          <InternalQuestionnaireBreadcrumbs />
        ) : (
          <PortalLogosWrapper>
            <StarcierLogoWrapper>
              <StarcierLogo />
            </StarcierLogoWrapper>
            <SVPLogo />
          </PortalLogosWrapper>
        )}
      </LogoWrapper>
    </Wrapper>
  );
};
