import { styled } from '@mui/material';
import { useParams } from 'react-router';
import { OverviewTab } from './components/OverviewTab';
import { PerformanceTab } from './components/PerformanceTab/PerformanceTab';
import { ROUTES } from '../../constants/routes';
import { ChecklistTab } from './components/ChecklistTab/ChecklistTab';

const Wrapper = styled('div')`
  padding-top: 16px;
`;

export const TabsContent = () => {
  const { activeTab } = useParams();
  return (
    <Wrapper>
      {activeTab === ROUTES.COMPANY_OVERVIEW && <OverviewTab />}
      {activeTab === ROUTES.COMPANY_PERFORMANCE && <PerformanceTab />}
      {activeTab === ROUTES.COMPANY_CHECKLIST && <ChecklistTab />}
    </Wrapper>
  );
};
