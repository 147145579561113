import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { SurveySection } from '../../../../types';
import { HtmlText } from './HtmlText';
import { QuestionnaireQuestion } from './QuestionnaireQuestion';
import { Fragment } from 'react';
import { flattenTree } from '../../../../utils/treeUtilities';

const SectionWrapper = styled('div')`
  padding: 12px 0;
  margin-top: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[30]};
`;
const SubsectionWrapper = styled('div')`
  margin-top: 11px;
`;
const SectionTitleWrapper = styled('div')`
  padding-bottom: 32px;
  width: 48%;
`;

interface SubsectionProps {
  section: SurveySection;
}
const Subsections = ({ section }: SubsectionProps) => {
  const { colors } = useTheme();
  const [, ...flatSubsections] = flattenTree([section]);
  if (flatSubsections && flatSubsections.length) {
    return (
      <Fragment>
        {flatSubsections.map((subsection) => (
          <SubsectionWrapper key={subsection.id}>
            <SectionTitleWrapper>
              <Typography variant='h3' color={colors.primary[90]} style={{ fontSize: 22 }}>
                {subsection.name}
              </Typography>
              {subsection?.includeDescription && subsection.description && (
                <HtmlText htmlText={subsection.description} />
              )}
            </SectionTitleWrapper>
            {subsection.questions.map((question, i) => (
              <QuestionnaireQuestion key={question.id} question={question} order={i + 1} />
            ))}
          </SubsectionWrapper>
        ))}
      </Fragment>
    );
  }

  return null;
};

interface Props {
  sections: SurveySection[];
}

export const QuestionnaireSections = ({ sections }: Props) => {
  const { colors } = useTheme();
  if (!sections?.length) {
    return (
      <Typography variant='body' color={colors.primary[60]} style={{ marginTop: '20px' }}>
        No questions
      </Typography>
    );
  }
  return (
    <>
      {sections.map((section) => (
        <SectionWrapper key={section.id}>
          <SectionTitleWrapper>
            <Typography variant='h3' color={colors.primary[90]}>
              {section.name}
            </Typography>
            {section?.includeDescription && section.description && (
              <HtmlText htmlText={section.description} />
            )}
          </SectionTitleWrapper>
          {section.questions.map((question, i) => (
            <QuestionnaireQuestion key={question.id} question={question} order={i + 1} />
          ))}
          <Subsections section={section} />
        </SectionWrapper>
      ))}
    </>
  );
};
