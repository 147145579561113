import { styled } from '@mui/material';
import { useParams } from 'react-router';
import { CompaniesStep } from './CompaniesStep/CompaniesStep';
import { FrequencyStep } from './FrequencyStep';
import { SurveyStep } from './SurveyStep/SurveyStep';

const Wrapper = styled('div')`
  padding: 24px 40px;
`;

export const StepContent = () => {
  const params = useParams();
  const activeStep = Number(params.activeStep);
  return (
    <Wrapper>
      {activeStep === 1 && <SurveyStep />}
      {activeStep === 2 && <FrequencyStep />}
      {activeStep === 3 && <CompaniesStep />}
    </Wrapper>
  );
};
