import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { styled } from '@mui/material';
import { Exclusion } from '../../../../../../../types';
import { ExclusionItem } from './ExclusionItem/ExclusionItem';
import { useCallback } from 'react';
import { useAtom } from 'jotai';
import {
  activeConditionIdState,
  activeExclusionCategoryState,
} from '../../../../../../../state/UIState';
import { useUpdateExclusions } from '../../../../../../../queries/useExclusions';
import { useAppConfig, useUpdateAppConfig } from '../../../../../../../queries/useAppConfig';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
`;

interface Props {
  exclusions: Exclusion[];
}

export const ExclusionItems = ({ exclusions }: Props) => {
  const [activeCategory, setActiveCategory] = useAtom(activeExclusionCategoryState);
  const [activeConditionId] = useAtom(activeConditionIdState);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const { mutate: updateExclusions } = useUpdateExclusions();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const activeCondition = activeCategory?.conditions?.find(
        (condition) => condition.id === activeConditionId
      );

      const exclusions = activeCondition?.exclusions;

      if (!active?.id || !over?.id || !activeCondition || !exclusions || !activeCategory) return;

      const oldIndex = exclusions?.findIndex((item) => item.id === Number(active.id));
      const newIndex = exclusions?.findIndex((item) => item.id === Number(over.id));

      const reorderedItems = arrayMove(exclusions, oldIndex, newIndex);

      const payload = {
        data: reorderedItems.map((item, i) => ({
          id: item.id,
          order: i + 1,
          condition: activeCondition?.id,
        })),
      };

      updateExclusions(payload, {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
          });
        },
      });

      const updatedCategory = {
        ...activeCategory,
        conditions: activeCategory?.conditions?.map((condition) => {
          if (condition.id === activeConditionId) {
            return {
              ...condition,
              exclusions: reorderedItems,
            };
          }
          return condition;
        }),
      };

      setActiveCategory(updatedCategory);
    },
    [
      activeCategory,
      activeConditionId,
      appConfigData?.data,
      setActiveCategory,
      updateAppConfig,
      updateExclusions,
    ]
  );

  return (
    <Wrapper>
      <DndContext sensors={sensors} onDragEnd={handleReorder} collisionDetection={closestCenter}>
        <SortableContext items={exclusions ?? []} strategy={verticalListSortingStrategy}>
          {exclusions?.map((exclusion, i) => (
            <ExclusionItem
              exclusion={exclusion}
              key={exclusion.id}
              exclusionOrder={i + 1}
              isDraggable={exclusions ? exclusions.length > 1 : false}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};
