import { useAtom } from 'jotai';
import { SelectionBar } from '../../../../components/SelectionBar/SelectionBar';
import {
  isApplyingEvaluationRulesModeActiveState,
  isMultiActionMoveModeActiveState,
  isSingleActionMoveModeActiveState,
  sectionsState,
  selectedQuestionsState,
} from '../../../../state/UIState';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-white.svg';
import { ReactComponent as MoveIcon } from '../../../../assets/icons/corner-arrow-white.svg';
import { ConfirmDeleteModal } from '../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { buildTree, flattenTree } from '../../../../utils/treeUtilities';

export const QuestionsSelectionBar = () => {
  const [isApplyModeActive] = useAtom(isApplyingEvaluationRulesModeActiveState);
  const [selectedQuestions, setSelectedQuestions] = useAtom(selectedQuestionsState);
  const [isSingleMoveActionActive] = useAtom(isSingleActionMoveModeActiveState);
  const [, setSections] = useAtom(sectionsState);
  const [isMultiMoveActionActive, setIsMultiMoveActionActive] = useAtom(
    isMultiActionMoveModeActiveState
  );
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const handleOpenConfirmDeleteModal = () => setIsConfirmDeleteModalOpen(true);
  const handleCloseConfirmDeleteModal = () => setIsConfirmDeleteModalOpen(false);
  const actions = useMemo(() => {
    return [
      {
        id: 'MOVE',
        value: 'Move Questions to...',
        icon: <MoveIcon />,
      },
      {
        id: 'DELETE',
        value: 'Delete Questions',
        icon: <DeleteIcon />,
      },
      {
        id: 'CANCEL',
        value: 'Cancel',
      },
    ];
  }, []);
  const handleSelectAction = useCallback(
    (actionId: string) => {
      if (isMultiMoveActionActive) return;
      switch (actionId) {
        case 'MOVE':
          setIsMultiMoveActionActive(true);
          return;
        case 'DELETE':
          handleOpenConfirmDeleteModal();
          return;
        case 'CANCEL':
          setSelectedQuestions([]);
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [isMultiMoveActionActive, setIsMultiMoveActionActive, setSelectedQuestions]
  );

  const handleDeleteQuestions = useCallback(async () => {
    setSections((prev) => {
      const flattenedSections = flattenTree(prev);
      const updatedFlattenedSections = flattenedSections.map((section) => {
        const updatedQuestions = section.questions.filter(
          (question) => !selectedQuestions.map((q) => q.id).includes(question.id)
        );
        return {
          ...section,
          questions: updatedQuestions,
        };
      });
      return buildTree(updatedFlattenedSections);
    });
    setSelectedQuestions([]);
    handleCloseConfirmDeleteModal();
  }, [selectedQuestions, setSections, setSelectedQuestions]);

  return (
    <>
      <SelectionBar
        selectedItemsLength={selectedQuestions.length}
        actions={actions}
        onSelectAction={handleSelectAction}
        isActive={!isApplyModeActive && !isSingleMoveActionActive && !!selectedQuestions.length}
        style={{ position: 'sticky', top: '0' }}
        isDisabled={isMultiMoveActionActive}
      />

      <ConfirmDeleteModal
        onClose={() => {
          handleCloseConfirmDeleteModal();
        }}
        onConfirm={handleDeleteQuestions}
        title='Delete these questions?'
        note='This action cannot be undone.'
        confirmLabel='Yes, Delete'
        cancelLabel='No, Cancel'
        isOpen={isConfirmDeleteModalOpen}
      />
    </>
  );
};
