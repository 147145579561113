import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { SingleSelect } from '../SingleSelect/SingleSelect';
import { SelectItem } from '../../types';

interface Props {
  location: SelectItem | null;
  setLocation: (value: SelectItem | null) => void;
}

export const LocationAutocomplete = ({ location, setLocation }: Props) => {
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: ['(cities)'],
    },
    language: 'en',
  });

  const options =
    placePredictions?.map((p) => ({
      value: p.description,
      id: p.description,
    })) || null;

  return (
    <SingleSelect
      onInputChange={(e) => getPlacePredictions({ input: (e.target as HTMLInputElement).value })}
      options={options || []}
      onChange={(e, newValue) => setLocation(newValue)}
      value={location}
      openOnfocus={false}
      fieldPlaceholder='Search for location'
    />
  );
};
