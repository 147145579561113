import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../../components/Typography/Typography';
import { formatDate } from '../../../../../../../utils/formatters';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 13px;
  margin-right: 135px;
`;

interface Props {
  lastUpdated: string;
}

export const LastUpdated = ({ lastUpdated }: Props) => {
  const { colors } = useTheme();

  return (
    <Container>
      <Typography
        variant='overline'
        color={colors.primary[70]}
        style={{ textTransform: 'uppercase' }}
      >
        Last Updated
      </Typography>
      <Typography variant='subtitle2' color={colors.primary[90]}>
        {formatDate(lastUpdated)}
      </Typography>
    </Container>
  );
};
