import { useMemo } from 'react';
import { useCompanyById } from '../queries/useCompanies';
import { useReportsByCompanyId } from '../queries/useReports';

export const useInvestorExclusionScreeningReport = (companyId?: number) => {
  const { data: company } = useCompanyById(companyId);

  const { data: companyReports } = useReportsByCompanyId(companyId);

  return useMemo(() => {
    if (!company?.exclusionScreeningSurvey || !companyReports) return;
    const report = companyReports.find(
      (report) => report.survey.id === company.exclusionScreeningSurvey?.id
    );
    return report;
  }, [company?.exclusionScreeningSurvey, companyReports]);
};
