import { FC, useMemo } from 'react';
import { EvaluationBox } from './EvaluationBox';
import { ReviewTabConfig, SurveyQuestion } from '../../../../../types';
import { QuestionInstructions } from '../QuestionInstructions';
import { ReviewReportTabs } from '../../../../../components/ReviewReportTabs/ReviewReportTabs';
import { useAtom } from 'jotai';
import { activeSurveyState } from '../../../../../state/UIState';

interface Props {
  question: SurveyQuestion;
}

export const EvaluateTabs: FC<Props> = ({ question }) => {
  const [activeSurvey] = useAtom(activeSurveyState);

  const tabsConfig: ReviewTabConfig[] = useMemo(() => {
    const tabs = [];
    const evaluationTabConfig = {
      tab: 'YOUR EVALUATION',
      tabComponent: <EvaluationBox question={question} />,
    };

    const instructionTabConfig = {
      tab: 'INSTRUCTIONS',
      tabComponent: <QuestionInstructions instructions={question.instructions} />,
    };

    if (activeSurvey?.includeEvaluation && question?.includeEvaluation) {
      tabs.push(evaluationTabConfig);
    }

    if (question?.includeInstructions) {
      tabs.push(instructionTabConfig);
    }

    return tabs;
  }, [activeSurvey?.includeEvaluation, question]);

  return <ReviewReportTabs tabsConfig={tabsConfig} />;
};
