import { Button, IconButton, styled, Tab, Tabs, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left-blue.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';
import { StepIdicator } from '../../../components/StepIndicator/StepIndicator';
import { Typography } from '../../../components/Typography/Typography';
import { HEADER_HEIGHT } from '../../../constants/layoutSizes';
import { ROUTES } from '../../../constants/routes';
import {
  activeSurveyState,
  createRequestCompletedProgressState,
  isNameModalOpenState,
  survayNameState,
} from '../../../state/UIState';
import {
  CREATE_REQUEST_PARAMS,
  SelectItem,
  Survey,
  SURVEY_FREQUENCY,
  SURVEY_STAGE,
  SURVEY_TYPE,
} from '../../../types';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { useQueryParams } from '../../../hooks/useQueryParams';

const Wrapper = styled('div')`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[30]};
  position: sticky;
  top: 0;
`;

const StepIndicatorsWrapper = styled('div')`
  display: flex;
  gap: 44px;
  align-items: center;
  height: 100%;
`;
const TabWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 450px;
  justify-content: center;
  height: 100%;
`;

const BackButtonWrapper = styled('div')`
  width: 450px;
  display: flex;
  align-items: center;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const EditIconWrapper = styled(IconButton)`
  margin-left: 16px;
  path {
    fill: ${({ theme }) => theme.colors.accent[50]};
  }
`;

export const ProgressHeader = () => {
  const params = useParams<{ activeStep: string }>();
  const { colors } = useTheme();
  const navigate = useNavigate();
  const activeStep = params.activeStep;
  const [value, setValue] = useState(activeStep);
  const [activeSurvey] = useAtom(activeSurveyState);
  const [surveyName] = useAtom(survayNameState);
  const [, setOpenModal] = useAtom(isNameModalOpenState);
  const [, setProgress] = useAtom(createRequestCompletedProgressState);

  const { setMultipleParams } = useQueryParams();

  const steps = useMemo(
    () => [
      {
        step: 1,
        param: 'build',
        text: 'Build',
      },
      {
        step: 2,
        param: 'review',
        text: 'Review',
      },
    ],
    []
  );

  const isStepDisabled = useCallback(
    (step: string) => {
      if (activeSurvey?.isAssigned && step === 'build') {
        return true;
      }
      if (activeSurvey?.type === SURVEY_TYPE.CORE && step === 'build') {
        return true;
      }
      return false;
    },
    [activeSurvey?.isAssigned, activeSurvey?.type]
  );

  const onTabChange = useCallback(
    (_: SyntheticEvent, newValue: string) => {
      if (isStepDisabled(newValue)) {
        return;
      }
      navigate(`${newValue}`);
    },
    [isStepDisabled, navigate]
  );

  const createChecklistRequest = useCallback(
    (frequency: SelectItem, activeSurvey: Survey) => {
      setProgress((prev) => (prev > 33 ? prev : 66));
      setMultipleParams(
        [
          { param: CREATE_REQUEST_PARAMS.SURVEY_ID, value: String(activeSurvey.id) },
          { param: CREATE_REQUEST_PARAMS.FREQUENCY, value: String(frequency.id) },
          { param: CREATE_REQUEST_PARAMS.REQUEST_NAME, value: activeSurvey?.name },
          { param: CREATE_REQUEST_PARAMS.SURVEY_NAME, value: activeSurvey?.name },
        ],
        `/${ROUTES.CREATE_REQUEST}/3`
      );
    },
    [setProgress, setMultipleParams]
  );

  const onCreateRequest = useCallback(() => {
    if (!activeSurvey) return;
    if (activeSurvey?.stage === SURVEY_STAGE.CHECKLIST_QUARTERLY) {
      const frequency = { id: SURVEY_FREQUENCY.QUARTERLY, value: 'Quarterly' };
      createChecklistRequest(frequency, activeSurvey);
      return;
    }

    if (activeSurvey?.stage === SURVEY_STAGE.CHECKLIST_ANNUALLY) {
      const frequency = { id: SURVEY_FREQUENCY.ANNUALLY, value: 'Annually' };
      createChecklistRequest(frequency, activeSurvey);
      return;
    }

    setMultipleParams(
      [
        { param: CREATE_REQUEST_PARAMS.SURVEY_ID, value: String(activeSurvey.id) },
        { param: CREATE_REQUEST_PARAMS.SURVEY_NAME, value: activeSurvey?.name },
        { param: CREATE_REQUEST_PARAMS.SURVEY_STAGE, value: activeSurvey?.stage },
      ],
      `/${ROUTES.CREATE_REQUEST}/2`
    );
  }, [activeSurvey, createChecklistRequest, setMultipleParams]);

  useEffect(() => {
    if (isStepDisabled(activeStep ?? '')) {
      navigate(`/${ROUTES.SURVEYS}/${activeSurvey?.id}/review`);
      return;
    }
    setValue(activeStep);
  }, [activeStep, activeSurvey?.id, activeSurvey?.isAssigned, isStepDisabled, navigate]);

  return (
    <Wrapper>
      <BackButtonWrapper>
        <Button
          onClick={() => navigate(`/${ROUTES.SURVEYS}`)}
          variant='text'
          startIcon={<ArrowLeftIcon />}
          style={{ minWidth: 'auto' }}
        ></Button>
        <Typography variant='h3' color={colors.primary[90]}>
          {surveyName}
        </Typography>
        <EditIconWrapper onClick={() => setOpenModal(true)}>
          <EditIcon />
        </EditIconWrapper>
      </BackButtonWrapper>
      <TabWrapper>
        <StepIndicatorsWrapper>
          <Tabs
            value={value ? value : false}
            onChange={onTabChange}
            sx={{
              minHeight: '0',
              height: '100%',
              '.MuiTabs-flexContainer': { height: '100%', gap: '38px', textTransform: 'none' },
            }}
          >
            {steps.map(({ step, text, param }) => (
              <Tab
                key={step}
                value={param}
                className={`mui-custom-tab`}
                disableRipple={isStepDisabled(param)}
                style={{
                  cursor: isStepDisabled(param) ? 'not-allowed' : 'pointer',
                  opacity: isStepDisabled(param) ? 0.5 : 1,
                }}
                label={
                  <StepIdicator
                    text={text}
                    step={step}
                    isActive={activeStep === param}
                    isCompleted={false}
                    style={{ textTransform: 'none' }}
                  />
                }
              />
            ))}
          </Tabs>
        </StepIndicatorsWrapper>
      </TabWrapper>
      <Button
        startIcon={<AddIcon />}
        variant='contained'
        style={{ width: '179px' }}
        onClick={onCreateRequest}
      >
        Create Request
      </Button>
    </Wrapper>
  );
};
