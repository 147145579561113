import { useMutation, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import { CORE_KPI_FORMAT, CoreKpi, LoadingId } from '../types';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { CORE_KPI_CATEGORIES } from './useCoreKpiCategories';

export interface CoreKpiPayload {
  id?: number;
  name: string;
  description: string;
  category: number;
  metric: string;
  format: CORE_KPI_FORMAT;
  order: number;
  isDeleted: boolean;
}

async function createCoreKpi(payload: Partial<CoreKpiPayload>): Promise<CoreKpi> {
  const { data } = await Http.axios.post<Partial<CoreKpiPayload>, CoreKpi>(`/core-kpi`, payload);
  return data;
}

export function useCreateCoreKpi() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  return useMutation(createCoreKpi, {
    onMutate: async () => {
      startLoading(LoadingId.createCoreKpi);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create core KPI' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(CORE_KPI_CATEGORIES);
      stopLoading(LoadingId.createCoreKpi);
    },
  });
}

async function updateCoreKpi(coreKpi: Partial<CoreKpiPayload>): Promise<CoreKpi> {
  const { id, ...payload } = coreKpi;
  const { data } = await Http.axios.patch<Partial<CoreKpiPayload>, CoreKpi>(
    `/core-kpi/${id}`,
    payload
  );
  return data;
}

export function useUpdateCoreKpi() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateCoreKpi, {
    onMutate: async () => {
      startLoading(LoadingId.updateCoreKpi);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update core KPI' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(CORE_KPI_CATEGORIES);
      stopLoading(LoadingId.updateCoreKpi);
    },
  });
}

async function updateCoreKpis(coreKpi: { data: Partial<CoreKpiPayload>[] }): Promise<CoreKpi[]> {
  const { data } = await Http.axios.patch<{ data: Partial<CoreKpiPayload>[] }, CoreKpi[]>(
    `/core-kpi/bulk`,
    coreKpi
  );
  return data;
}

export function useUpdateCoreKpis() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateCoreKpis, {
    onMutate: async () => {
      startLoading(LoadingId.updateCoreKpis);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update core KPIs' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(CORE_KPI_CATEGORIES);
      stopLoading(LoadingId.updateCoreKpis);
    },
  });
}
