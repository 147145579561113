import { Chip, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { useMemo } from 'react';

interface Props {
  companiesNum?: number;
}

export const NumberOfCompanies = ({ companiesNum }: Props) => {
  const { colors } = useTheme();

  const companiesNumLabel = useMemo(() => {
    if (!companiesNum) return '';
    if (companiesNum === 1) {
      return `${companiesNum} company`;
    }

    return `${companiesNum} companies`;
  }, [companiesNum]);

  return (
    <Chip
      style={{
        backgroundColor: colors.primary[20],
        height: '26px',
        width: 'max-content',
      }}
      label={
        <Typography variant='caption' color={colors.primary[90]}>
          {companiesNumLabel}
        </Typography>
      }
    />
  );
};
