import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { SelectableCompaniesTable } from '../../../components/SelectableCompaniesTable/SelectableCompaniesTable';
import { useCompanies } from '../../../queries/useCompanies';
import { useEffect, useState } from 'react';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { useAtom } from 'jotai';
import { exportCompaniesProgressState, exportCompaniesState } from '../../../state/UIState';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { Company, EXPORT_COMPANIES_PARAMS } from '../../../types';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const CompaniesStep = () => {
  const { colors } = useTheme();
  const { isLoading, data: companies } = useCompanies();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedCompanies, setSelectedCompanies] = useAtom(exportCompaniesState);
  const [, setProgress] = useAtom(exportCompaniesProgressState);

  const { params, setParam } = useQueryParams();

  const companyIds = params[EXPORT_COMPANIES_PARAMS.COMPANY_IDS] as string[];

  const [rowSelection, setRowSelection] = useState(() =>
    selectedCompanies.reduce((acc: Record<number, boolean>, company) => {
      acc[company.id] = true;
      return acc;
    }, {})
  );

  useEffect(() => {
    if (!companies) return;

    if (!selectedCompanies?.length) {
      const companiesFromParams = companies?.filter((company) =>
        companyIds?.includes?.(String(company.id))
      );

      setRowSelection(() =>
        companiesFromParams.reduce((acc: Record<number, boolean>, company) => {
          acc[company.id] = true;
          return acc;
        }, {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  useEffect(() => {
    if (!companies) return;
    const newCompanies = Object.keys(rowSelection)
      .map((id) => companies.find((company) => company.id === Number(id)))
      .filter((company) => Boolean(company)) as Company[];
    newCompanies.length === 0 ? setProgress(50) : setProgress(100);

    setSelectedCompanies(newCompanies);
    const companyIds = newCompanies.map((c) => c.id);

    setParam({ param: EXPORT_COMPANIES_PARAMS.COMPANY_IDS, value: companyIds });
  }, [companies, rowSelection, setParam, setProgress, setSelectedCompanies]);

  return (
    <Wrapper>
      <Typography variant='h4' color={colors.primary[90]}>
        Companies
      </Typography>
      <SelectableCompaniesTable
        companiesTableData={companies || []}
        sorting={sorting}
        setSorting={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        selectedCompanies={selectedCompanies}
        isLoading={isLoading || !companies}
        tableHeight='calc(100vh - 340px)'
      />
    </Wrapper>
  );
};
