import { useCallback, useMemo, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { Table } from '../../../components/Table/Table';
import { SURVEY_TYPE, Survey } from '../../../types';

import { ReactComponent as DuplicateIcon } from '../../../assets/icons/duplicate.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive.svg';

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getFacetedUniqueValues,
  Row,
} from '@tanstack/react-table';
import { Chip, styled, useTheme } from '@mui/material';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import {
  useArchiveSurvey,
  useCreateSurvey,
  useDeleteSurvey,
  useSurveys,
} from '../../../queries/useSurveys';
import { SurveysSkeletonLoader } from '../../../components/SkeletonLoader/Surveys.SkeletonLoader';
import { useNavigate } from 'react-router';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { ConfirmDeleteModal } from '../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { SurveyStatusCell } from '../../../components/Table/CellRenderers/SurveyStatusCell';
import { PAGE_HEADER_HEIGHT } from '../../../constants/layoutSizes';
import { formatDate } from '../../../utils/formatters';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`;
const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
`;
const CellWrapper = styled('div')`
  display: flex;
  max-width: 400px;
`;

const columnHelper = createColumnHelper<Survey>();

export const SurveysTable = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { isLoading, data: surveys } = useSurveys();
  const createSurvey = useCreateSurvey();
  const deleteSurvey = useDeleteSurvey();
  const archiveSurvey = useArchiveSurvey();
  const [selectedSurvey, setSelectedSurvey] = useState<Survey | null>(null);

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const handleOpenConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(true), []);
  const handleCloseConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(false), []);

  const handleDeleteSurvey = useCallback(() => {
    if (!selectedSurvey) return;
    handleCloseConfirmDeleteModal();
    deleteSurvey.mutate(selectedSurvey.id);
  }, [selectedSurvey, handleCloseConfirmDeleteModal, deleteSurvey]);

  const { pushSuccessToast } = useToastMessage();

  const actions = useMemo(() => {
    return [
      {
        id: 'DUPLICATE',
        value: 'Duplicate',
        icon: <DuplicateIcon />,
        getIsDisabled: (row: Row<Survey> | null) => row?.original.type === SURVEY_TYPE.CORE,
      },
      {
        id: 'ARCHIVE',
        value: 'Archive',
        icon: <ArchiveIcon />,
        getIsDisabled: (row: Row<Survey> | null) => row?.original.type === SURVEY_TYPE.CORE,
      },
      {
        id: 'DELETE',
        value: 'Delete',
        icon: <DeleteIcon />,
        getIsDisabled: (row: Row<Survey> | null) =>
          row?.original.type === SURVEY_TYPE.CORE || !!row?.original.isAssigned,
      },
    ];
  }, []);

  const handleSelectAction = useCallback(
    (actionId: string, row: Row<Survey> | null) => {
      if (!row) return;
      switch (actionId) {
        case 'DUPLICATE':
          createSurvey.mutate(
            {
              sections: row.original.sections,
              name: `${row.original.name} copy`,
              type: SURVEY_TYPE.USER,
              includeWeight: row.original.includeWeight,
              includeEvaluation: row.original.includeEvaluation,
              evaluationRules: row.original.evaluationRules,
              evaluationScale: row.original.evaluationScale,
              description: row.original.description,
              includeQuestionNumbers: row.original.includeQuestionNumbers,
            },
            {
              onSuccess: () => {
                pushSuccessToast({
                  message: `Survey ${row.original.name} was duplicated successfully`,
                });
              },
            }
          );
          return;
        case 'ARCHIVE':
          archiveSurvey.mutate(row.original.id);
          return;
        case 'DELETE':
          handleOpenConfirmDeleteModal();
          setSelectedSurvey(row.original);
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [archiveSurvey, createSurvey, handleOpenConfirmDeleteModal, pushSuccessToast]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.accent[50]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Survey
          </Typography>
        ),
        meta: {
          width: '30%',
          minWidth: '235px',
        },
      }),
      columnHelper.accessor('type', {
        cell: (info) => <Chip label={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Type
          </Typography>
        ),
        meta: {
          width: '5%',
          minWidth: '120px',
          maxWidth: '120px',
        },
      }),

      columnHelper.accessor('isAssigned', {
        cell: (info) => <SurveyStatusCell isAssigned={!!info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Status
          </Typography>
        ),
        meta: {
          width: '7%',
          minWidth: '150px',
          maxWidth: '150px',
        },
      }),
      columnHelper.accessor('description', {
        cell: (info) => (
          <CellWrapper>
            <Typography variant='body' color={colors.primary[70]}>
              {info.getValue()}
            </Typography>
          </CellWrapper>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Description
          </Typography>
        ),
        meta: {
          width: '50%',
          minWidth: '250px',
        },
      }),
      columnHelper.accessor('created_at', {
        cell: (info) => (
          <CellWrapper>
            <Typography variant='body' color={colors.primary[90]}>
              {info.getValue() ? formatDate(info.getValue() as string) : null}
            </Typography>
          </CellWrapper>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Date Added
          </Typography>
        ),
        meta: {
          width: '8%',
          minWidth: '150px',
        },
        sortingFn: 'datetime',
      }),
    ],
    [colors.accent, colors.primary]
  );

  const table = useReactTable({
    data: surveys ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (survey) => String(survey.id),
  });

  const onRowClick = useCallback(
    (row: Row<Survey>) => {
      const surveyId = row.id;
      const url = `/surveys/${surveyId}/${
        row.original.isAssigned || row.original.type === SURVEY_TYPE.CORE ? 'review' : 'build'
      }`;
      navigate(url);
    },
    [navigate]
  );

  if (isLoading || !surveys) return <SurveysSkeletonLoader />;

  return (
    <Wrapper>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a survey'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
      </FiltersWrapper>
      <Table
        table={table}
        onRowClick={onRowClick}
        actions={actions}
        onActionClick={handleSelectAction}
        height={`calc(100vh - ${PAGE_HEADER_HEIGHT}px - 138px)`}
      />

      <ConfirmDeleteModal
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleDeleteSurvey}
        title='Delete the survey?'
        note='Deleting this survey will also delete the associated request and all incomplete reports.'
        confirmLabel='Yes, Delete'
        cancelLabel='No, Cancel'
        isOpen={isConfirmDeleteModalOpen}
      />
    </Wrapper>
  );
};
