import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { SelectableSurveysTable } from './SelectableSurveysTable';

const Wrapper = styled('div')``;

export const SurveyStep = () => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <Typography variant='h2' color={colors.primary[90]} style={{ marginBottom: '8px' }}>
        What survey do you need?
      </Typography>
      <Typography variant='body' color={colors.primary[70]} style={{ marginBottom: '32px' }}>
        Surveys offer specific metrics and questions. Choose the right one for your needs.
      </Typography>
      <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '8px' }}>
        Surveys
      </Typography>
      <SelectableSurveysTable />
    </Wrapper>
  );
};
