import { useParams } from 'react-router';
import { useCompanyById } from '../../../queries/useCompanies';
import { useCallback } from 'react';
import { WorkflowStep } from '../../../types';

export const useIsTaskBlocked = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));

  const getIsBlocked = useCallback(
    (step: WorkflowStep) => {
      if (!step?.includeBlockingSteps) return false;

      return !step?.blockingSteps?.every((blockingStep) =>
        company?.completedWorkflowSteps?.includes(blockingStep.id)
      );
    },
    [company?.completedWorkflowSteps]
  );

  return { getIsBlocked };
};
