import { useCallback } from 'react';
import { ConfirmDeleteModal } from '../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { useUpdateChecklistItem } from '../../../../queries/useChecklistItems';
import { useAtom } from 'jotai';
import { selectedChecklistItemState } from '../../../../state/UIState';
import { useAppConfig, useUpdateAppConfig } from '../../../../queries/useAppConfig';

interface Props {
  handleCloseConfirmDeleteModal: () => void;
  isConfirmDeleteModalOpen: boolean;
}

export const DeleteSingleItemModal = ({
  handleCloseConfirmDeleteModal,
  isConfirmDeleteModalOpen,
}: Props) => {
  const [selectedItemForAction, setSelectedItemForAction] = useAtom(selectedChecklistItemState);

  const updateChecklistItem = useUpdateChecklistItem();
  const updateAppConfig = useUpdateAppConfig();
  const { data: appConfigData } = useAppConfig();

  const handleDeleteKpi = useCallback(async () => {
    if (!selectedItemForAction) return;
    handleCloseConfirmDeleteModal();
    await updateChecklistItem.mutateAsync(
      { id: selectedItemForAction.id, isDeleted: true },
      {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, areCheckListSurveysUpToDate: false },
          });
        },
      }
    );
    setSelectedItemForAction(null);
  }, [
    appConfigData?.data,
    handleCloseConfirmDeleteModal,
    selectedItemForAction,
    setSelectedItemForAction,
    updateAppConfig,
    updateChecklistItem,
  ]);

  return (
    <ConfirmDeleteModal
      onClose={() => {
        setSelectedItemForAction(null);
        handleCloseConfirmDeleteModal();
      }}
      onConfirm={handleDeleteKpi}
      title='Delete the checklist item?'
      note='This checklist item is active and used in Surveys & Company Profile. Any modifications you make will have a global effect.'
      confirmLabel='Yes, Delete'
      cancelLabel='No, Cancel'
      isOpen={isConfirmDeleteModalOpen}
    />
  );
};
