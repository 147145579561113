import { useAtom } from 'jotai';
import {
  activeKpiCategoryState,
  isConfirmBarActiveState,
  isMoveModeActiveState,
} from '../../../state/UIState';
import { useCallback, useState } from 'react';

import { CoreKpisTable } from './CoreKpisTable';
import { EditCreateCoreKpi } from './EditCreateCoreKpi';
import { CoreKpiPayload, useCreateCoreKpi } from '../../../queries/useCoreKpis';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { getMaxOrderNum } from '../../../utils/getMaxOrderNum';
import { MainContentHeader, MainContentWrapper } from '../../../components/DataCollectionLayout';

export const KpisContent = () => {
  const [activeCategory] = useAtom(activeKpiCategoryState);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const handleOpenCreateModal = () => setIsCreateModalOpen(true);
  const handleCloseCreateModal = () => setIsCreateModalOpen(false);
  const createCoreKpi = useCreateCoreKpi();
  const [isConfirmMoveBarActive] = useAtom(isConfirmBarActiveState);
  const [isMoveModeActive] = useAtom(isMoveModeActiveState);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const onSubmit = useCallback(
    (payload: Partial<CoreKpiPayload>) => {
      if (!activeCategory) return;
      const maxOrderNum = getMaxOrderNum(activeCategory?.kpis);
      createCoreKpi.mutate(
        { ...payload, category: activeCategory.id, order: maxOrderNum + 1 },
        {
          onSuccess: async () => {
            if (!appConfigData?.data) {
              return;
            }
            await updateAppConfig.mutateAsync({
              data: { ...appConfigData?.data, isCoreKpiSurveyUpToDate: false },
            });
          },
        }
      );
    },
    [activeCategory, appConfigData?.data, createCoreKpi, updateAppConfig]
  );

  return (
    <MainContentWrapper isActive={isConfirmMoveBarActive}>
      <MainContentHeader
        title={`${activeCategory?.name ?? ''} KPIs`}
        onClickAdd={handleOpenCreateModal}
        isDisabled={isMoveModeActive}
        addCTAText='Add KPI'
      />

      <CoreKpisTable />

      {isCreateModalOpen && (
        <EditCreateCoreKpi
          onClose={handleCloseCreateModal}
          onSubmit={onSubmit}
          title='Add Core KPI'
          confirmBtnText='Add KPI'
        />
      )}
    </MainContentWrapper>
  );
};
