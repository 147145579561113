import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps,
  styled,
  useTheme,
} from '@mui/material';
import { FC } from 'react';

type Props = CircularProgressProps & {
  wrapperStyle?: React.CSSProperties | undefined;
};

const Wrapper = styled('div')`
  position: relative;
  display: flex;
`;

export const CircularProgress: FC<Props> = (props) => {
  const { colors } = useTheme();
  const { value, wrapperStyle, ...rest } = props;
  return (
    <Wrapper style={wrapperStyle}>
      <MuiCircularProgress
        sx={{ color: colors.primary[30] }}
        variant='determinate'
        value={100}
        {...rest}
      />
      <MuiCircularProgress
        sx={{ color: colors.accent[50], position: 'absolute', left: 0 }}
        variant='determinate'
        value={value}
        {...rest}
      />
    </Wrapper>
  );
};
