import { Checkbox as MuiCheckbox, CheckboxProps, useTheme } from '@mui/material';
import { FC } from 'react';

export const Checkbox: FC<CheckboxProps> = (props) => {
  const { colors } = useTheme();
  const { sx, disabled, ...rest } = props;
  return (
    <MuiCheckbox
      sx={{
        padding: '0',
        color: colors.primary[40],
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
        '&.Mui-checked': {
          color: disabled ? colors.primary[60] : colors.accent[50],
        },
        ...sx,
      }}
      disabled={disabled}
      {...rest}
    />
  );
};
