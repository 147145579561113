import { forwardRef, HTMLAttributes, MouseEvent, useCallback, useState } from 'react';
import classNames from 'classnames';

import { Handle } from './Handle';
import { Checkbox, styled, Fade, IconButton } from '@mui/material';
import { EditableTextFieldV2 } from '../../../../../../components/EditableTextField/EditableTextFieldV2';
import { ReactComponent as AddIcon } from '../../../../../../assets/icons/add-gray.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close-gray.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/arrow-right.svg';
import { Radio } from '../../../../../../components/Radio/Radio';
import { Tooltip } from '../../../../../../components/Tooltip/Tooltip';

const Wrapper = styled('li')<{ spacing: string }>`
  list-style: none;
  box-sizing: border-box;
  padding-left: ${(props) => props.spacing};
  margin-bottom: -1px;

  &.clone {
    display: inline-block;
    pointer-events: none;
    padding: 0;
    padding-top: 5px;

    .tree-item {
      background-color: ${({ theme }) => theme.colors.primary[0]};
      box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);
      width: 200px;
    }
  }

  &.disable-interaction {
    pointer-events: none;
  }

  &.ghost {
    &.indicator {
      opacity: 1;
      position: relative;
      z-index: 1;
      margin-bottom: -1px;

      .tree-item {
        position: relative;
        padding: 0;
        margin-left: 20px;
        height: 4px;
        border-color: ${({ theme }) => theme.colors.accent[50]};
        background-color: ${({ theme }) => theme.colors.accent[40]};

        &:before {
          position: absolute;
          left: -8px;
          top: -6px;
          display: block;
          content: '';
          width: 11px;
          height: 11px;
          border-radius: 50%;
          border: ${({ theme }) => `2px solid ${theme.colors.accent[40]}`};
          background-color: #ffffff;
        }

        > * {
          /* Items are hidden using height and opacity to retain focus */
          opacity: 0;
          height: 0;
        }
      }
    }

    &:not(.indicator) {
      opacity: 0.5;
    }

    .tree-item > * {
      box-shadow: none;
      background-color: transparent;
    }
  }
`;

const TreeItemWrapper = styled('div')<{ isActive: boolean }>`
  ${({ isActive, theme }) => isActive && `background-color: ${theme.colors.accent[5]};`}
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 0 12px;
  &:hover {
    .controls {
      opacity: 1;
    }
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.accent[5] : theme.colors.gray[20]};
  }
  cursor: pointer;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-grow: 1;
`;

const ControlsWrapper = styled('div')`
  display: flex;
`;
const HoverControlsWrapper = styled('div')`
  opacity: 0;
  display: flex;
`;

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  name: string;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  value: string;
  isMultiSelectable?: boolean;
  isSingleSelectable?: boolean;
  isDraggable?: boolean;
  isSelected?: boolean;
  isIndeterminate?: boolean;
  disableInput?: boolean;
  disableControls?: boolean;
  isActive: boolean;
  onSectionRename: (newName: string) => void;
  onClickSection?: () => void;
  onExpandCollapseSection?: () => void;
  onAddSubsection?: () => void;
  onCollapse?(): void;
  onDeleteSection: () => void;
  wrapperRef?(node: HTMLLIElement): void;
}

const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      style,
      name,
      wrapperRef,
      onSectionRename,
      onAddSubsection,
      onDeleteSection,
      onClickSection,
      onExpandCollapseSection,
      isActive,
      disableInput = false,
      disableControls = false,
      isMultiSelectable = false,
      isSingleSelectable = false,
      isDraggable = false,
      isSelected = false,
      isIndeterminate = false,
      ...props
    },
    ref
  ) => {
    const [sectionTitle, setSectionTitle] = useState(name);
    const onRename = useCallback(
      (value: string) => {
        if (value === name || !value) {
          setSectionTitle(name);
          return;
        }
        onSectionRename(value);
      },
      [name, onSectionRename]
    );

    const onAddSubSetionClick = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onAddSubsection && onAddSubsection();
      },
      [onAddSubsection]
    );

    const onDeleteSectionClick = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onDeleteSection();
      },
      [onDeleteSection]
    );

    const onExpandCollapseSectionClick = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onExpandCollapseSection && onExpandCollapseSection();
      },
      [onExpandCollapseSection]
    );

    return (
      <Wrapper
        className={classNames(
          clone && 'clone',
          ghost && 'ghost',
          indicator && 'indicator',
          disableSelection && 'disable-selection',
          disableInteraction && 'disable-interaction'
        )}
        spacing={`${indentationWidth * depth}px`}
        ref={wrapperRef}
        {...props}
      >
        <TreeItemWrapper
          className={'tree-item'}
          ref={ref}
          style={style}
          isActive={isActive}
          onClick={onClickSection}
        >
          <TitleWrapper>
            {isMultiSelectable && <Checkbox checked={isSelected} indeterminate={isIndeterminate} />}
            {isSingleSelectable && <Radio checked={isSelected} />}
            {isDraggable && <Handle {...handleProps} />}
            <EditableTextFieldV2
              value={sectionTitle}
              onChange={(e) => setSectionTitle(e.target.value)}
              onValueChanged={onRename}
              placeholder='Enter section name'
              disabled={!!disableInput}
            />
          </TitleWrapper>
          {!disableControls && (
            <ControlsWrapper>
              <HoverControlsWrapper className='controls'>
                <Tooltip
                  title='Add New Sub-Section'
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [70, -13],
                        },
                      },
                    ],
                  }}
                >
                  <IconButton onClick={onAddSubSetionClick}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title='Delete This Section'
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [65, -13],
                        },
                      },
                    ],
                  }}
                >
                  <IconButton onClick={onDeleteSectionClick}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </HoverControlsWrapper>
              {Boolean(childCount && childCount > 1 && !clone) && (
                <IconButton
                  onClick={onExpandCollapseSectionClick}
                  style={{ width: '30px', height: '30px' }}
                >
                  {!collapsed ? <ArrowDownIcon /> : <ArrowRightIcon />}
                </IconButton>
              )}
            </ControlsWrapper>
          )}
        </TreeItemWrapper>
      </Wrapper>
    );
  }
);

TreeItem.displayName = 'TreeItem';
export { TreeItem };
