import { useMemo } from 'react';
import { ChipsSelector } from '../../../components/ButtonSelector/ChipsSelector';
import { FormField } from '../../../components/FormField/FormField';
import { RISK_LEVEL, SelectItem } from '../../../types';

const options: SelectItem[] = [
  {
    id: RISK_LEVEL.LOW,
    value: RISK_LEVEL.LOW,
  },
  {
    id: RISK_LEVEL.MEDIUM,
    value: RISK_LEVEL.MEDIUM,
  },
  {
    id: RISK_LEVEL.HIGH,
    value: RISK_LEVEL.HIGH,
  },
];

interface Props {
  esgRiskLevel?: RISK_LEVEL | null;
  onChangeEsgRiskLevel: (val: SelectItem | null) => void;
}

export const ESGRiskLevelSelector = ({ esgRiskLevel, onChangeEsgRiskLevel }: Props) => {
  const selectedEsgRiskLevel = useMemo(
    () => options.find((o) => o.id === esgRiskLevel) || null,
    [esgRiskLevel]
  );

  return (
    <FormField label='ESG Risk Level' style={{ marginBottom: '16px' }}>
      <ChipsSelector
        options={options}
        onSelect={(item) => onChangeEsgRiskLevel(item)}
        selectedItem={selectedEsgRiskLevel}
        noCheckIcon
      />
    </FormField>
  );
};
