import { Button, Chip, styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as DangerIcon } from '../../../assets/icons/danger-red-round.svg';
import { ReactComponent as ShieldIcon } from '../../../assets/icons/shield-green.svg';
import { EXCLUSION_STATUS } from '../../../types';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { useCompanyExclusions } from '../hooks/useCompanyExclusions';

const ExclusionsWrapper = styled('div')`
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-radius: 2px;
  padding: 16px 24px;
  position: relative;
  min-height: 160px;
`;
const TagWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 20px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const NoExclusionNoteWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CountChip = styled('div')<{ isDisabled?: boolean }>`
  margin-top: -2px;
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.primary[50] : theme.colors.accent[50]};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExclusionSection = () => {
  const { id } = useParams();
  const { colors } = useTheme();
  const navigate = useNavigate();

  const { data: exclusions } = useCompanyExclusions();

  const uniqueCategories = useMemo(() => {
    const categories = exclusions
      ?.filter((exclusion) => exclusion?.status === EXCLUSION_STATUS.VIOLATED)
      ?.map((exclusion) => exclusion?.category);
    return [...new Set(categories)];
  }, [exclusions]);

  const violatedExclusions = useMemo(() => {
    return exclusions?.filter((exclusion) => exclusion?.status === EXCLUSION_STATUS.VIOLATED);
  }, [exclusions]);

  const { state } = useLocation();

  const prevRoute = useMemo(() => {
    if (!state?.prevRoute) return `/${ROUTES.COMPANIES}`;

    return state?.prevRoute;
  }, [state?.prevRoute]);

  const prevRouteName = useMemo(() => {
    if (!state?.prevRouteName) return 'Companies';

    return state?.prevRouteName;
  }, [state?.prevRouteName]);

  return (
    <ExclusionsWrapper>
      <TitleWrapper>
        <Typography variant='subtitle2' color={colors.primary[90]}>
          Investor Exclusions Violated
        </Typography>
        <CountChip isDisabled={!violatedExclusions?.length}>
          <Typography
            variant='numbers'
            color={colors.primary[0]}
            style={{ fontSize: '12px', lineHeight: '12px' }}
          >
            {violatedExclusions.length}
          </Typography>
        </CountChip>
      </TitleWrapper>
      <TagWrapper>
        {uniqueCategories.map((exclusion) => (
          <Chip
            key={exclusion}
            icon={<DangerIcon />}
            style={{
              backgroundColor: colors.error[5],
              height: '26px',
            }}
            label={
              <Typography variant='caption' color={colors.primary[90]}>
                {exclusion}
              </Typography>
            }
          />
        ))}
        {!violatedExclusions?.length && (
          <NoExclusionNoteWrapper>
            <ShieldIcon />
            <Typography variant='body' color={colors.primary[70]}>
              No exclusions violated
            </Typography>
          </NoExclusionNoteWrapper>
        )}
      </TagWrapper>
      {Boolean(exclusions?.length) && (
        <Button
          variant='text'
          style={{ minWidth: 'auto', position: 'absolute', bottom: '16px', left: '24px' }}
          onClick={() =>
            navigate(`/${ROUTES.COMPANIES}/${id}/${ROUTES.INVESTOR_EXCLUSIONS}`, {
              state: { prevRoute, prevRouteName },
            })
          }
        >
          <Typography variant='srOnly' color={colors.accent[50]}>
            View All Exclusions
          </Typography>
        </Button>
      )}
      {!exclusions?.length && (
        <Button
          variant='text'
          style={{ minWidth: 'auto', position: 'absolute', bottom: '16px', left: '24px' }}
          onClick={() => navigate(`/${ROUTES.COMPANIES}/${id}/${ROUTES.ADD_EXCLUSIONS}`)}
        >
          <Typography variant='srOnly' color={colors.accent[50]}>
            Manage Company Exclusions
          </Typography>
        </Button>
      )}
    </ExclusionsWrapper>
  );
};
