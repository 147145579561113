import { useAtom } from 'jotai';
import { FC, useCallback, useMemo } from 'react';
import {
  activeQuestionIdState,
  activeSectionIdState,
  responsesState,
} from '../../../../state/UIState';
import { QUESTION_TYPE, QuestionOption, SurveyQuestion } from '../../../../types';
import { ReviewTabs } from '../ReviewTabs/ReviewTabs';
import { ReviewQuestionContent } from './ReviewQuestionContent/ReviewQuestionContent';

interface Props {
  question: SurveyQuestion;
  isCoreKpi?: boolean;
  order: number;
}

export const ReviewQuestion: FC<Props> = ({ question, isCoreKpi, order }) => {
  const [activeQuestionId, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);

  const [responses] = useAtom(responsesState);

  const subQuestions = useMemo(() => {
    if (question?.type !== QUESTION_TYPE.SINGLE) return [];
    const selectedOption = question.options.find(
      (o) => (responses[question.id] as QuestionOption)?.id === o.id
    );

    return selectedOption?.subQuestions || [];
  }, [question.id, question.options, question?.type, responses]);

  const onClick = useCallback(
    (question: SurveyQuestion) => {
      setActiveQuestionId(question.id);
      setActiveSectionId(question.sectionId);
    },
    [setActiveQuestionId, setActiveSectionId]
  );

  return (
    <>
      <ReviewQuestionContent
        question={question}
        isCoreKpi={isCoreKpi}
        onClick={onClick}
        order={String(order)}
        isActive={activeQuestionId === question?.id}
        tabsComponent={<ReviewTabs question={question} />}
      />
      {subQuestions.map((subQuestion, i) => (
        <ReviewQuestionContent
          key={subQuestion.id}
          onClick={onClick}
          question={subQuestion}
          order={`${order}.${i + 1}`}
          isActive={activeQuestionId === subQuestion?.id}
          tabsComponent={<ReviewTabs question={subQuestion} />}
        />
      ))}
    </>
  );
};
