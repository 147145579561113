import { styled, useTheme } from '@mui/material';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';
import { ReactElement } from 'react';
import { InfoContent } from './InfoContent';

const InfoWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

const Img = styled('img')`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: contain;
`;

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface Props {
  isOpen: boolean;
  title: string;
  confirmBtnText: string;
  onClose: () => void;
  handleSubmit: () => void;
  isConfirmDisabled: boolean;
  companyLogo?: string;
  companyName: string;
  assignedUser?: string;
  children: ReactElement;
}

export const AssignUserModal = ({
  isOpen,
  title,
  confirmBtnText,
  onClose,
  handleSubmit,
  isConfirmDisabled,
  companyLogo,
  companyName,
  assignedUser,
  children,
}: Props) => {
  const { colors } = useTheme();

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      confirmBtnText={confirmBtnText}
      onClose={onClose}
      onConfirm={handleSubmit}
      isConfirmDisabled={isConfirmDisabled}
      infoContent={assignedUser ? <InfoContent assignedUser={assignedUser} /> : null}
    >
      <InfoWrapper>
        {companyLogo && <Img src={companyLogo} />}
        <Typography variant='h4' color={colors.primary[90]}>
          {companyName}
        </Typography>
      </InfoWrapper>
      <FormWrapper>{children}</FormWrapper>
    </Modal>
  );
};
