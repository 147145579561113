import { useAtom } from 'jotai';
import { useCallback } from 'react';
import {
  activeChecklistCategoryState,
  checklistTableRowSelectionState,
  selectedChecklistCategoryState,
  selectedChecklistItemsState,
} from '../../../state/UIState';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { getMaxOrderNum } from '../../../utils/getMaxOrderNum';
import { useOnCancelConfirm } from './useOnCancelConfirm';
import { ReactComponent as UndoIcon } from '../../../assets/icons/undo-blue.svg';
import { useUpdateChecklistItems } from '../../../queries/useChecklistItems';

export const useMoveChecklistItems = () => {
  const [selectedCategory] = useAtom(selectedChecklistCategoryState);
  const [selectedItems] = useAtom(selectedChecklistItemsState);
  const [activeChecklistCategory] = useAtom(activeChecklistCategoryState);
  const [, setRowSelection] = useAtom(checklistTableRowSelectionState);
  const updateChecklistItems = useUpdateChecklistItems();
  const { pushSuccessToast } = useToastMessage();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const { onCancel } = useOnCancelConfirm();

  const moveChecklistItems = useCallback(async () => {
    const maxOrderNum = getMaxOrderNum(selectedCategory?.items);
    const payload = {
      data: selectedItems.map((item, i) => ({
        id: item.id,
        category: selectedCategory?.id,
        order: maxOrderNum + i + 1,
      })),
    };
    await updateChecklistItems.mutateAsync(payload, {
      onSuccess: async () => {
        if (!appConfigData?.data) {
          return;
        }
        await updateAppConfig.mutateAsync({
          data: { ...appConfigData?.data, areCheckListSurveysUpToDate: false },
        });
        pushSuccessToast({
          message: `${selectedItems.length} items were successfully moved to ${selectedCategory?.name}.`,
          action: () => {
            const payload = {
              data: selectedItems.map((item) => ({
                id: item.id,
                category: activeChecklistCategory?.id,
                order: item.order,
              })),
            };
            updateChecklistItems.mutateAsync(payload);
          },
          actionLabel: 'Undo',
          endIcon: <UndoIcon />,
        });
      },
    });
    setRowSelection({});
    onCancel();
  }, [
    activeChecklistCategory?.id,
    appConfigData?.data,
    onCancel,
    pushSuccessToast,
    selectedCategory?.id,
    selectedCategory?.items,
    selectedCategory?.name,
    selectedItems,
    setRowSelection,
    updateAppConfig,
    updateChecklistItems,
  ]);

  return { moveChecklistItems };
};
