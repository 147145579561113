import { Divider, styled } from '@mui/material';
import { ReactNode } from 'react';
import { CardHeader } from './CardHeader/CardHeader';
import { EditableTextareaField } from '../EditableTextField/EditableTextareaField';

const SectionWrapper = styled('div')<{ isActive: boolean }>`
  padding: 32px 0;
  max-width: 850px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.accent[5] : theme.colors.primary[10]};
  border-radius: 4px;
`;

const CardTextWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  padding: 0 32px;
  margin-bottom: 8px;
`;

interface Props {
  onCardClick: () => void;
  isInactive?: boolean;
  children: ReactNode;
  onExpandToggle: () => void;
  isExpanded?: boolean;
  title: string;
  chipLabel: string;
  controls?: ReactNode;
  textValue?: string;
  setTextValue?: (value: string) => void;
  onTextValueChanged?: (newValue: string) => void;
  setTitleValue?: (value: string) => void;
  onTitleValueChanged?: (newValue: string) => void;
}

export const AccordionCard = ({
  onCardClick,
  isInactive,
  children,
  onExpandToggle,
  isExpanded,
  title,
  chipLabel,
  controls,
  textValue,
  setTextValue,
  onTextValueChanged,
  setTitleValue,
  onTitleValueChanged,
}: Props) => {
  return (
    <SectionWrapper onClick={onCardClick} isActive={!isInactive}>
      <CardHeader
        onExpandToggle={onExpandToggle}
        isExpanded={isExpanded}
        title={title}
        chipLabel={chipLabel}
        controls={controls}
        isActive={!isInactive}
        setTitleValue={setTitleValue}
        onTitleValueChanged={onTitleValueChanged}
      />

      {onTextValueChanged && (
        <CardTextWrapper>
          <EditableTextareaField
            value={textValue}
            onChange={(e) => setTextValue?.(e.target.value)}
            onValueChanged={onTextValueChanged}
            placeholder='Enter question'
          />
        </CardTextWrapper>
      )}
      {isExpanded && (
        <>
          <Divider
            style={{
              marginTop: '16px',
              marginBottom: '16px',
              width: 'calc(100% - 64px)',
              marginLeft: '32px',
            }}
          />
          {children}
        </>
      )}
    </SectionWrapper>
  );
};
