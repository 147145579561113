import { styled } from '@mui/material';

export const ReviewReportTabWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.primary[20]};
`;
