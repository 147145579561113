import React, { forwardRef, CSSProperties } from 'react';
import { styled } from '@mui/material';

const Button = styled('div')<{ cursor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: ${({ cursor }) => cursor || 'pointer'};
  border: none;
  outline: none;
  appearance: none;
`;

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  cursor?: CSSProperties['cursor'];
}

const Action = forwardRef<HTMLDivElement, Props>(({ cursor, style, ...props }, ref) => {
  return <Button ref={ref} {...props} tabIndex={0} cursor={cursor} style={{ ...style }} />;
});

Action.displayName = 'Action';

export { Action };
