import { Button, styled, useTheme } from '@mui/material';
import { ReactComponent as Recipe } from '../../assets/icons/recipe.svg';
import { Typography } from '../Typography/Typography';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { COMPANY_TYPE } from '../../types';
import { useMemo } from 'react';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled('div')`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonWrapper = styled('div')`
  margin-top: 20px;
`;

interface Props {
  companyType: COMPANY_TYPE;
}

export const NoSteps = ({ companyType }: Props) => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  const workflowTab = useMemo(() => {
    if (companyType === COMPANY_TYPE.CONTROLLED) return ROUTES.CONTROL_WORKFLOW;

    return ROUTES.NOT_CONTROL_WORKFLOW;
  }, [companyType]);

  const onButtonClick = () => {
    navigate(`/${ROUTES.WORKFLOWS}/${workflowTab}`);
  };

  return (
    <Container>
      <Recipe />
      <ContentWrapper>
        <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '4px' }}>
          No Screening Steps
        </Typography>
        <Typography variant='body' color={colors.primary[70]}>
          Update the workflow to enhance your screening process.
        </Typography>
        <ButtonWrapper>
          <Button variant='contained' onClick={onButtonClick} style={{ width: '195px' }}>
            Go to Workflows
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </Container>
  );
};
