import { styled, useTheme } from '@mui/material';
import { RISK_LEVEL } from '../../types';
import { Typography } from '../Typography/Typography';
import { useMemo } from 'react';

const Container = styled('div')<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  height: 100%;
  padding: 9px 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.primary[0]}`};
`;

interface Props {
  riskLevel?: RISK_LEVEL;
}

export const RiskLevelCell = ({ riskLevel }: Props) => {
  const { colors } = useTheme();

  const backgroundColor = useMemo(() => {
    if (riskLevel === RISK_LEVEL.HIGH) {
      return colors.error[10];
    }

    if (riskLevel === RISK_LEVEL.MEDIUM) {
      return '#FDEDB4';
    }

    if (riskLevel === RISK_LEVEL.LOW) {
      return colors.success[20];
    }

    return '';
  }, [colors.error, colors.success, riskLevel]);

  if (!riskLevel) return null;

  return (
    <Container bgColor={backgroundColor}>
      <Typography variant='subtitle1' color={colors.primary[90]}>
        {riskLevel}
      </Typography>
    </Container>
  );
};
