import { styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { EVALUATION_SCALE } from '../../types';
import { EvaluationRangeScoreIcon } from '../../pages/SIngleCompany/components/EvaluationRangeScoreIcon';
import { ReactComponent as Checkmark } from '../../assets/icons/checkmark.svg';
import { ReactComponent as Cross } from '../../assets/icons/no-answer.svg';

const Wrapper = styled('div')`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const BinaryEvaluationIconWrapper = styled('div')<{ color: string }>`
  path {
    fill: ${({ color }) => color};
  }
`;

interface Props {
  color: string;
  score?: number;
  label: string;
  evaluationScale?: EVALUATION_SCALE;
}

export const EvaluationScorePreview = ({ color, label, evaluationScale, score }: Props) => {
  const { colors } = useTheme();

  if (score === null || score === undefined) return null;
  return (
    <Wrapper>
      {evaluationScale === EVALUATION_SCALE.RANGE ? (
        <EvaluationRangeScoreIcon evaluationRangeScore={score} />
      ) : (
        <BinaryEvaluationIconWrapper color={color}>
          {score === 0 ? <Cross color={color} /> : <Checkmark color={color} />}
        </BinaryEvaluationIconWrapper>
      )}

      <Typography variant='srOnly' color={colors.primary[70]}>
        {label}
      </Typography>
    </Wrapper>
  );
};
