import { Button, styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-blue.svg';

const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

interface Props {
  title: string;
  onClickAdd?: () => void;
  isDisabled?: boolean;
  addCTAText: string;
}

export const MainContentHeader = ({ title, onClickAdd, isDisabled, addCTAText }: Props) => {
  const { colors } = useTheme();
  return (
    <HeaderWrapper>
      <Typography variant='h4' color={colors.accent[90]}>
        {title}
      </Typography>
      <Button
        variant='text'
        startIcon={<AddIcon />}
        style={{ minWidth: 'auto', marginBottom: '-1px' }}
        onClick={onClickAdd}
        disabled={isDisabled}
      >
        <Typography
          variant='subtitle2'
          color={colors.accent[50]}
          style={{ marginLeft: '-2px', marginBottom: '-1px' }}
        >
          {addCTAText}
        </Typography>
      </Button>
    </HeaderWrapper>
  );
};
