/* istanbul ignore file */
import * as yup from 'yup';
import { requiredMsg } from '../../../utils/validationMessages';

export const CompanySchema = yup.object().shape({
  sectors: yup.array().of(yup.number()).min(1, requiredMsg('Sector')),
  industry: yup.number().typeError(requiredMsg('Industry')).required(requiredMsg('Industry')),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  description: yup.string(),
});
