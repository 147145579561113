import { useQuery } from 'react-query';
import { Http } from '../services/Http';
import { ExternalInvestor, Investor } from '../types';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';

export const INVESTORS = 'investors';
export const EXTERNAL_INVESTORS = 'externalInvestors';

async function fetchInvestors(): Promise<Investor[]> {
  const { data } = await Http.axios.get<Investor[]>(`/investor`);
  return data;
}

export function useInvestors() {
  return useQuery(INVESTORS, () => fetchInvestors(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function fetchExternalInvestors(token: string | undefined): Promise<ExternalInvestor[]> {
  if (!token) {
    return [];
  }
  const { data } = await axios.get(`/investors`, {
    baseURL: process.env.REACT_APP_SVP_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SVP_API_SUBSCRIPTION_KEY,
    },
  });
  return data.data;
}

export function useExternalInvestors() {
  const auth = useOktaAuth();
  const token = auth.authState?.accessToken?.accessToken;
  return useQuery(EXTERNAL_INVESTORS, () => fetchExternalInvestors(token), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}
