import { useAtom } from 'jotai';
import { Breadcrumbs } from '../../../components/Breadcrumbs/Breadcrumbs';
import { ROUTES } from '../../../constants/routes';
import { activeReportState, survayNameState } from '../../../state/UIState';
import { useCompaniesPageRouteName } from '../../../hooks/useCompaniesPageRouteName';

export const InternalQuestionnaireBreadcrumbs = () => {
  const [activeReport] = useAtom(activeReportState);
  const [surveyName] = useAtom(survayNameState);

  const prevRouteCompanyName = useCompaniesPageRouteName();

  return (
    <Breadcrumbs
      currentRouteName={surveyName}
      prevRoutes={[
        { prevRoute: `/${ROUTES.COMPANIES}`, prevRouteName: prevRouteCompanyName },
        {
          prevRoute: `/${ROUTES.COMPANIES}/${activeReport?.company.id}/company-overview`,
          prevRouteName: activeReport?.company?.name || '',
        },
      ]}
    />
  );
};
