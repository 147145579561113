import { styled } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';
import { HtmlText } from './HtmlText';

interface Props {
  instructions: string;
}
const InstructionsWrapper = styled('div')`
  padding: 16px;
  width: 41%;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  border: 1px solid ${({ theme }) => theme.colors.primary[20]};
  border-radius: 2px;
  display: flex;
  gap: 8px;
`;
const IconWrapper = styled('div')`
  margin-top: 1px;
`;

export const QuestionInstructions: FC<Props> = ({ instructions }) => {
  return (
    <InstructionsWrapper>
      <IconWrapper>
        <InfoIcon />
      </IconWrapper>
      <HtmlText htmlText={instructions} />
    </InstructionsWrapper>
  );
};
