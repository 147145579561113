import { styled, TextField, useTheme } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { Typography } from '../../../components/Typography/Typography';
import { RequestGroupPayload, useUpdateRequestGroup } from '../../../queries/useRequestGroups';
import { RequestGroupSchema as validationSchema } from './RequestGroupSchema';
import { FormField } from '../../../components/FormField/FormField';
import { RequestGroup, SURVEY_FREQUENCY } from '../../../types';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';
import { useSurveys } from '../../../queries/useSurveys';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { useCompanies } from '../../../queries/useCompanies';
import { CompanyLogo } from '../../../components/CompanyLogo/CompanyLogo';
import { RequestGroupEditModalSkeletonLoader } from '../../../components/SkeletonLoader/RequestGroupEditModal.SkeletonLoader';
import { Modal } from '../../../components/Modal/Modal';

interface Props {
  onClose: () => void;
  requestGroup: RequestGroup | null;
}

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const EditRequestGroupModal: FC<Props> = ({ onClose, requestGroup }) => {
  const { colors } = useTheme();

  const { isLoading: areSurveysLoaing, data: surveys } = useSurveys();
  const { isLoading: areCompaniesLoading, data: companies } = useCompanies();
  const isLoading = areSurveysLoaing || areCompaniesLoading;
  const updateRequestGroup = useUpdateRequestGroup();


  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    setTouched,
    setFieldValue,
    isValid,
    handleSubmit,
    dirty,
  } = useFormik<Partial<RequestGroupPayload>>({
    initialValues: {
      name: requestGroup?.requests[0].name,
      frequency: requestGroup?.requests[0].frequency,
      survey: requestGroup?.requests[0].survey?.id,
      companyIds: requestGroup?.requests.map((c) => c.company.id as number) || [],
    },
    validationSchema,
    onSubmit: (values) => {
      onClose();
      updateRequestGroup.mutate({
        id: requestGroup?.id as number,
        name: values.name ?? null,
        frequency: values.frequency ?? null,
        survey: values.survey ?? null,
        companyIds: values.companyIds ?? [],
      });
    },
  });

  const handleError = (field: keyof RequestGroupPayload) => (touched[field] ? errors[field] : '');

  const frequencyOptions = useMemo(
    () => [
      { id: SURVEY_FREQUENCY.ONE_TIME, value: 'One-time' },
      { id: SURVEY_FREQUENCY.MONTHLY, value: 'Monthly' },
      { id: SURVEY_FREQUENCY.QUARTERLY, value: 'Quarterly' },
      { id: SURVEY_FREQUENCY.ANNUALLY, value: 'Annually' },
    ],
    []
  );
  const selectedFrequency = useMemo(
    () => frequencyOptions.find((o) => o.id === values.frequency),
    [values.frequency, frequencyOptions]
  );
  const onChangeFrequency = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('frequency', '');
        return;
      }
      setFieldValue('frequency', option?.id);
    },
    [setFieldValue]
  );

  const surveyOptions = useMemo(() => {
    return (
      surveys?.map((survey) => ({
        id: survey.id,
        value: survey.name,
      })) ?? []
    );
  }, [surveys]);

  const selectedSurvey = useMemo(
    () => surveyOptions.find((o) => o.id === values.survey),
    [values.survey, surveyOptions]
  );

  const onChangeSurvey = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('survey', '');
        return;
      }
      setFieldValue('survey', option?.id);
    },
    [setFieldValue]
  );

  const companyOptions = useMemo(() => {
    return (
      companies?.map((company) => ({
        id: company.id,
        value: company.name,
        icon: <CompanyLogo src={company.logoUrl} />,
      })) ?? []
    );
  }, [companies]);

  const selectedCompanies = useMemo(
    () => companyOptions.filter((o) => values.companyIds?.includes(o.id)),
    [values.companyIds, companyOptions]
  );

  const onChangeCompanies = useCallback(
    (options: any) => {
      if (!options) {
        setFieldValue('companyIds', []);
        return;
      }
      setFieldValue(
        'companyIds',
        options.map((o: any) => o.id)
      );
    },
    [setFieldValue]
  );

  const generateLabel = useCallback((numberOfSelectedCompanies: number) => {
    if (numberOfSelectedCompanies === 1) {
      return `1 company assigned`;
    }
    return `${numberOfSelectedCompanies} companies assigned`;
  }, []);

  return (
    <Modal
      isOpen
      title='Edit Request'
      isLoading={isLoading}
      loadingSkeleton={<RequestGroupEditModalSkeletonLoader />}
      infoContent={
        <>
          <Typography variant='body' color={colors.accent[60]}>
            This request is active and pending approval.
          </Typography>
          <Typography variant='body' color={colors.accent[60]}>
            Your changes will take effect next period.
          </Typography>
        </>
      }
      onClose={onClose}
      onConfirm={handleSubmit}
      contentContainerStyle={{ height: '511px' }}
      isConfirmDisabled={!isValid || !dirty}
    >
      <FormWrapper>
        <FormField label={'Name'} error={handleError('name')}>
          <TextField
            name={'name'}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant='outlined'
          />
        </FormField>
        <FormField label={'Survey'} error={handleError('survey')}>
          <SingleSelect
            options={surveyOptions}
            value={selectedSurvey ?? null}
            onChange={(_, option) => onChangeSurvey(option)}
            onBlur={() => setTouched({ ...touched, survey: true })}
            hideClearIcon
          />
        </FormField>
        <FormField label={'Frequency'} error={handleError('frequency')}>
          <SingleSelect
            options={frequencyOptions}
            value={selectedFrequency ?? null}
            onChange={(_, option) => onChangeFrequency(option)}
            onBlur={() => setTouched({ ...touched, frequency: true })}
            hideClearIcon
          />
        </FormField>
        <FormField label={'Companies'} error={handleError('companyIds')}>
          <Multiselect
            selectedTextMaxWidth='10rem'
            generateLabel={generateLabel}
            optionName='Company'
            options={companyOptions}
            value={selectedCompanies ?? []}
            onChange={(_, options) => onChangeCompanies(options)}
            onBlur={() => setTouched({ ...touched, companyIds: true })}
            hideClearIcon
          />
        </FormField>
      </FormWrapper>
    </Modal>
  );
};
