import Papa from 'papaparse';
import { IconButton, styled, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-gray-circle.svg';
import { Typography } from '../../../components/Typography/Typography';
import { useAtom } from 'jotai';
import {
  CSVCompaniesState,
  rowSelectionUploadCompaniesState,
  uploadedFileState,
} from '../../../state/UIState';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { COMPANY_CSV_HEADERS, REQUIRED_COMPANY_CSV_FIELDS } from '../../../constants/defaultValues';
import { UploadCSVArea } from '../../../components/UploadCSVArea/UploadCSVArea';

const Wrapper = styled('div')``;

const UploadedFileWrapper = styled('div')`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary[20]};
`;
const DetailsWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const NameWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const UploadArea = () => {
  const { colors } = useTheme();
  const { pushErrorToast } = useToastMessage();
  const [file, setFile] = useAtom(uploadedFileState);
  const [, setCSVData] = useAtom(CSVCompaniesState);
  const [, setRowSelection] = useAtom(rowSelectionUploadCompaniesState);

  const resetState = useCallback(() => {
    setFile(null);
    setCSVData([]);
    setRowSelection({});
  }, [setCSVData, setFile, setRowSelection]);

  const onDrop = useCallback(
    (files: File[]) => {
      resetState();
      if (!files.length) {
        pushErrorToast({ message: 'Invalid file type' });
        return;
      }
      const file = files[0];

      Papa.parse(file, {
        header: false,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rows: any[][] = results.data as any[][];
          if (rows.length < 2) {
            pushErrorToast({ message: 'Invalid CSV file' });
            resetState();
            return;
          }
          const requiredHeaders = COMPANY_CSV_HEADERS.map((header) => header.label);

          const [headers, ...rest] = rows;
          if (headers.length !== requiredHeaders.length) {
            pushErrorToast({ message: 'Invalid CSV file' });
            resetState();
            return;
          }

          requiredHeaders.forEach((header) => {
            if (!headers.includes(header)) {
              pushErrorToast({ message: `Invalid CSV file. Missing required header ${header}` });
              resetState();
              return;
            }
          });

          let isValid = true;
          const data = rest.map((values, i) => {
            const company = headers.reduce(
              (obj: Record<string, string | number>, header, index) => {
                const value = values[index]?.replace(/\r/g, '') || '';
                if (REQUIRED_COMPANY_CSV_FIELDS.includes(header) && !value) {
                  pushErrorToast({ message: `Invalid CSV file. Missing required field ${header}` });
                  isValid = false;
                  return obj;
                }
                obj[header.toLowerCase()] = value;
                return obj;
              },
              {}
            );
            company.id = i;
            return company;
          });
          if (!isValid) {
            resetState();
            return;
          }
          setFile(file);
          setCSVData(data);
        },
        error: function (error) {
          console.error(error.message);
        },
      });
    },
    [pushErrorToast, resetState, setCSVData, setFile]
  );

  const getSizeLabel = (size: number) => {
    const sizeInKB = size / 1024;
    const sizeInMB = sizeInKB / 1024;
    if (sizeInMB > 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB > 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${size} Bytes`;
    }
  };

  return (
    <Wrapper>
      <UploadCSVArea onDrop={onDrop} />
      {file && (
        <UploadedFileWrapper>
          <DetailsWrapper>
            <NameWrapper>
              <FileIcon />
              <Typography variant='srOnly' color={colors.primary[90]}>
                {file.name}
              </Typography>
            </NameWrapper>
            <Typography variant='caption' color={colors.primary[70]}>
              {getSizeLabel(file.size)}
            </Typography>
          </DetailsWrapper>
          <IconButton onClick={resetState}>
            <CloseIcon />
          </IconButton>
        </UploadedFileWrapper>
      )}
    </Wrapper>
  );
};
