import { useMemo } from 'react';
import { CHECKLIST_ITEM_FREQUENCY, CHECKLIST_OPTION, SURVEY_FREQUENCY } from '../../../../types';
import { getPeriodByFrequencyAndReportDate } from '../../../../utils/companyUtils';
import { Typography } from '../../../../components/Typography/Typography';
import { useTheme } from '@mui/material';

interface Props {
  lastChanged: string;
  frequency: CHECKLIST_ITEM_FREQUENCY;
  selectedOption: CHECKLIST_OPTION;
}

export const LastChangedCell = ({ lastChanged, frequency, selectedOption }: Props) => {
  const { colors } = useTheme();

  const lastChangedText = useMemo(() => {
    const month = new Date(lastChanged).getMonth() + 1;
    const year = new Date(lastChanged).getFullYear();

    if (selectedOption !== 'Yes') return '';

    if (frequency === CHECKLIST_ITEM_FREQUENCY.QUARTERLY) {
      const periodQuarterly = getPeriodByFrequencyAndReportDate(
        SURVEY_FREQUENCY.QUARTERLY,
        month,
        year
      );

      return `Since ${periodQuarterly}`;
    }
    if (frequency === CHECKLIST_ITEM_FREQUENCY.ANNUALLY) {
      const periodAnnually = getPeriodByFrequencyAndReportDate(
        SURVEY_FREQUENCY.ANNUALLY,
        month,
        year
      );

      return `Since ${periodAnnually}`;
    }

    return '';
  }, [frequency, lastChanged, selectedOption]);

  return (
    <Typography variant='subtitle2' color={colors.primary[90]}>
      {lastChangedText}
    </Typography>
  );
};
