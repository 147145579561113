import { useParams } from 'react-router';
import {
  useCompanyById,
  useUpdateCompanyWithouthRefetchData,
} from '../../../../../queries/useCompanies';
import { useEffect, useMemo } from 'react';
import { useChecklistQuarterlySurvey } from '../../../../../hooks/useChecklistQuarterlySurvey';
import { CHECKLIST_ITEM_FREQUENCY, ChecklistData } from '../../../../../types';
import { useChecklistAnnuallySurvey } from '../../../../../hooks/useChecklistAnnuallySurvey';
import { getFormattedChecklistData } from '../../../../../utils/getFormattedChecklistData';
import { useAtom } from 'jotai';
import { companyChecklistDataState } from '../../../../../state/UIState';
import { useDebouncedValue } from '../../../../../hooks/useDebouncedValue';

export const useChecklistData = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));

  const checklistQuarterlySurvey = useChecklistQuarterlySurvey();
  const checklistAnnuallySurvey = useChecklistAnnuallySurvey();

  const [companyChecklistData, setCompanyChecklistData] = useAtom(companyChecklistDataState);
  const debouncedCompanyChecklistData = useDebouncedValue(companyChecklistData, 500);

  const updateCompany = useUpdateCompanyWithouthRefetchData();

  useEffect(() => {
    const quarterlyData = getFormattedChecklistData(
      checklistQuarterlySurvey,
      CHECKLIST_ITEM_FREQUENCY.QUARTERLY
    );

    const annuallyData = getFormattedChecklistData(
      checklistAnnuallySurvey,
      CHECKLIST_ITEM_FREQUENCY.ANNUALLY
    );

    const defaultChecklistData = {
      quarterly: quarterlyData,
      annually: annuallyData,
    } as ChecklistData;

    if (!company?.checklistData) {
      setCompanyChecklistData(defaultChecklistData);
      return;
    }

    setCompanyChecklistData({
      ...defaultChecklistData,
      ...company.checklistData,
    });
  }, [
    checklistAnnuallySurvey,
    checklistQuarterlySurvey,
    company?.checklistData,
    setCompanyChecklistData,
  ]);

  useEffect(() => {
    if (!debouncedCompanyChecklistData) return;
    updateCompany.mutate({ id: Number(params.id), checklistData: debouncedCompanyChecklistData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCompanyChecklistData]);

  const combinedChecklistItems = useMemo(
    () =>
      [
        ...(companyChecklistData?.[CHECKLIST_ITEM_FREQUENCY.QUARTERLY] || []),
        ...(companyChecklistData?.[CHECKLIST_ITEM_FREQUENCY.ANNUALLY] || []),
      ].map((item) => ({
        ...item,
        lastChanged: item?.checklistOption?.updatedPeriod || '',
      })),
    [companyChecklistData]
  );

  return combinedChecklistItems;
};
