import { styled, useTheme } from '@mui/material';
import { CSSProperties, FC, useMemo } from 'react';
import { ReactComponent as CompletedIcon } from '../../assets/icons/checked.svg';
import { text } from '../../theme/text';
import { Typography } from '../Typography/Typography';

type Props = {
  text: string;
  step: number;
  isActive: boolean;
  isCompleted: boolean;
  style?: CSSProperties;
  onClick?: () => void;
};

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 37px;
`;
const Indicator = styled('div')<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.accent[50] : theme.colors.primary[50]};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-family: ${text.font.fontInconsolataRegular};
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.primary[0]};
`;

export const StepIdicator: FC<Props> = ({ step, isActive, isCompleted, text, style, onClick }) => {
  const { colors } = useTheme();
  const labelColor = useMemo(() => {
    if (isCompleted) return colors.primary[80];
    return isActive ? colors.accent[50] : colors.primary[60];
  }, [colors, isActive, isCompleted]);
  return (
    <Wrapper style={style} onClick={onClick}>
      {isCompleted ? <CompletedIcon /> : <Indicator isActive={isActive}>{step}</Indicator>}
      <Typography variant='subtitle2' color={labelColor} style={{ marginBottom: '-2px' }}>
        {text}
      </Typography>
    </Wrapper>
  );
};
