import { useAtom } from 'jotai';
import { activeReportState } from '../../../../state/UIState';
import { REPORT_STATUS } from '../../../../types';

export const useIsQuestionDisabled = () => {
  const [activeReport] = useAtom(activeReportState);

  if (!activeReport) return false;

  return (
    activeReport?.status !== REPORT_STATUS.IN_PROGRESS &&
    activeReport?.status !== REPORT_STATUS.NOT_STARTED
  );
};
