import { styled, TextField, TextFieldProps } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as ClearIcon } from '../../assets/icons/close-gray.svg';

const Wrapper = styled('div')<{ isFocused: boolean }>`
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid
    ${({ theme, isFocused }) => (isFocused ? theme.colors.accent[50] : theme.colors.primary[30])};
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.accent[50]};
  }
  .search-input {
    flex-grow: 1;
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiInputBase-root .MuiInputBase-input {
      padding-left: 10px;
      padding-right: 0;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    .Mui-disabled fieldset {
      border-color: transparent;
    }
    &:hover .Mui-disabled fieldset {
      border-color: 'transparent';
    }
  }
`;

export type ReadWriteFieldProps = TextFieldProps & {
  style?: React.CSSProperties;
  onClear?: () => void;
};

export function SearchInput(props: ReadWriteFieldProps) {
  const { style, onClear, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Wrapper isFocused={isFocused} style={style}>
      <SearchIcon />
      <TextField
        className='search-input'
        {...rest}
        inputProps={{
          ...rest.inputProps,
        }}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
      />
      {props.value && onClear ? (
        <ClearIcon style={{ cursor: 'pointer' }} onClick={onClear} />
      ) : null}
    </Wrapper>
  );
}
