import { Button, styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as Logo } from '../../../assets/starcier-logo-white.svg';
import frameImg1 from '../../../assets/image.jpg';
import { useNavigate } from 'react-router';

const Background = styled('div')`
  height: 100vh;
  position: relative;
`;

const Header = styled('div')`
  padding: 18px 40px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Wrapper = styled('div')`
  padding-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled('div')`
  width: 545px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  gap: 24px;
`;

export const InvalidToken = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  return (
    <Background
      style={{
        backgroundImage: `url(${frameImg1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Header>
        <Logo />
        <Typography variant='h5' color={colors.primary[0]} style={{ letterSpacing: '3px' }}>
          ESG
        </Typography>
      </Header>
      <Wrapper>
        <Content>
          <Typography
            variant='h2'
            color={colors.primary[40]}
            style={{ fontSize: '70px', marginBottom: '24px' }}
          >
            Page not found
          </Typography>
          <Typography variant='h1' color={'#F5F8FF'} style={{ marginBottom: '8px' }}>
            {"Don't worry, we'll help you find your way."}
          </Typography>
          <Typography variant='h4' color={colors.primary[40]} style={{ marginBottom: '24px' }}>
            {'The link is expired, or the report is already submitted.'}
          </Typography>
          <ButtonWrapper>
            <Button
              href='mailto:office@starcier.com'
              variant='contained'
              color='primary'
              style={{ height: '46px', flexGrow: '1' }}
            >
              Contact Support
            </Button>
            <Button
              variant='contained'
              color='primary'
              style={{
                height: '46px',
                flexGrow: '1',
                backgroundColor: colors.accent[5],
                color: colors.accent[50],
              }}
              onClick={() => navigate('/')}
            >
              Go to ESG Home
            </Button>
          </ButtonWrapper>
        </Content>
      </Wrapper>
    </Background>
  );
};
