import { styled } from '@mui/material';
import { PAGE_HEADER_HEIGHT } from '../../constants/layoutSizes';

export const HeaderWrapper = styled('div')<{ height?: number }>`
  height: ${({ height }) => height || PAGE_HEADER_HEIGHT}px;
  padding: 24px 40px;
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  justify-content: space-between;
`;
