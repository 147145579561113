import { Outlet, useNavigate, useParams } from 'react-router';
import { useCompanyById } from '../../queries/useCompanies';
import { useCallback, useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import {
  CSVCompaniesState,
  companyKpisDataState,
  rowSelectionUploadCompaniesState,
  uploadCompanyKpisProgressState,
  uploadedCompanyKpisFileState,
  uploadedFileState,
} from '../../state/UIState';
import { StepperLayout } from '../../components/StepperLayout/StepperLayout';
import { ROUTES } from '../../constants/routes';
import { ControlButtons } from './components/ControlButtons';

export const UploadCompanyKpisStepper = () => {
  const { companyId } = useParams();
  const { data: company } = useCompanyById(Number(companyId));
  const [, setCompanyKpisData] = useAtom(companyKpisDataState);

  const navigate = useNavigate();
  const [, setFile] = useAtom(uploadedFileState);
  const [, setCSVData] = useAtom(CSVCompaniesState);
  const [, setRowSelection] = useAtom(rowSelectionUploadCompaniesState);
  const [file] = useAtom(uploadedCompanyKpisFileState);
  const steps = useMemo(
    () => [
      {
        step: 1,
        text: 'Upload',
        isClickable: true,
      },
      {
        step: 2,
        text: 'Review',
        isClickable: !!file,
      },
    ],
    [file]
  );
  const params = useParams();
  const activeStep = Number(params.activeStep);
  const [progress] = useAtom(uploadCompanyKpisProgressState);

  const onStepClick = (step: number) => {
    navigate(`${step}`);
  };

  const onClick = useCallback(() => {
    navigate(`/${ROUTES.COMPANIES}/${companyId}/${ROUTES.COMPANY_PERFORMANCE}`);

    setFile(null);
    setCSVData([]);
    setRowSelection({});
  }, [navigate, companyId, setFile, setCSVData, setRowSelection]);

  useEffect(() => {
    if (company) {
      setCompanyKpisData(company.kpisData);
    }
  }, [company, setCompanyKpisData]);

  useEffect(() => {
    return () => {
      setCompanyKpisData(null);
    };
  }, [setCompanyKpisData]);

  return (
    <StepperLayout
      onBackClick={onClick}
      headerTitle={`Upload KPI Data ${company ? `for ${company.name}` : ''}`}
      progressBarText='Complete all steps to upload KPIs data.'
      progress={progress}
      steps={steps}
      activeStep={activeStep}
      onClickStepNavigate={onStepClick}
      controlButtons={<ControlButtons />}
    >
      <Outlet />
    </StepperLayout>
  );
};
