import { styled, useTheme } from '@mui/material';

const TextWrapper = styled('div')<{ color: string }>`
  color: ${({ color }) => color};
  ol,
  ul {
    padding: 8px 0 4px 16px;
  }
  p,
  ol,
  ul {
    font-family: ${({ theme }) => theme.text.font.fontFamilyRegular};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  h1 {
    font-family: ${({ theme }) => theme.text.font.fontFamilyMedium};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  h2 {
    font-family: ${({ theme }) => theme.text.font.fontFamilyMedium};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  a {
    color: ${({ theme }) => theme.colors.accent[50]};
    text-decoration: none;
  }
  img {
    max-width: 100%;
    object-fit: contain;
  }
`;

interface Props {
  htmlText: string;
  color?: string;
}

export const HtmlText = ({ htmlText, color }: Props) => {
  const { colors } = useTheme();
  return (
    <TextWrapper
      dangerouslySetInnerHTML={{ __html: htmlText }}
      color={color || colors.primary[90]}
    />
  );
};
