import { MouseEvent, useCallback, useState } from 'react';
import { ActionsPopupMenu } from '../../../../components/ActionsPopupMenu/ActionsPopupMenu';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { DeleteStageModal } from './DeleteStageModal';

const options = [
  {
    id: 'DELETE',
    value: 'Delete',
    icon: <DeleteIcon />,
  },
];

interface Props {
  onDeleteStage: (stageId: number) => void;
  stageId: number;
}

export const StageActionMenu = ({ onDeleteStage, stageId }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const onMenuItemClick = useCallback((id: string) => {
    handleCloseMenu();
    if (id === 'DELETE') {
      setIsDeleteModalOpen(true);
      return;
    }

    throw new Error('Unknown option');
  }, []);

  const onConfirmDelete = () => {
    onDeleteStage(stageId);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <ActionsPopupMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        actions={options}
        onClick={(e) => handleClick(e)}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseMenu();
        }}
        onMenuItemClick={onMenuItemClick}
        menuStyles={{
          transform: 'translate(5px, 0)',
          width: '158px',
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      />
      <DeleteStageModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        onDeleteStage={onConfirmDelete}
      />
    </>
  );
};
