import { useTheme } from '@mui/material';

export const useBarChartColor = () => {
  const { colors } = useTheme();

  const getBarChartColor = (index?: number) => {
    if (index === undefined) return 'transparent';

    if (index === 0) return colors.accent[30];
    if (index === 1) return colors.primary[40];
    if (index === 2) return colors.primary[60];

    return 'transparent';
  };

  return getBarChartColor;
};
