import { styled } from '@mui/material';
import { useParams } from 'react-router';
import { UploadStep } from './UploadStep';
import { ReviewStep } from './ReviewStep';

const Wrapper = styled('div')`
  padding: 24px 40px;
`;

export const StepContent = () => {
  const params = useParams();
  const activeStep = Number(params.activeStep);
  return (
    <Wrapper>
      {activeStep === 1 && <UploadStep />}
      {activeStep === 2 && <ReviewStep />}
    </Wrapper>
  );
};
