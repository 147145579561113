import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { DownloadCSVButton } from './DownloadCSVButton';
import { UploadArea } from './UploadArea';
import { WarningMessage } from './WarningMessage';

const Wrapper = styled('div')``;
const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WarningMessageWrapper = styled('div')`
  margin-top: 20px;
`;

export const UploadStep = () => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <HeaderWrapper>
        <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '8px' }}>
          Upload CSV File
        </Typography>
        <DownloadCSVButton />
      </HeaderWrapper>
      <WarningMessageWrapper>
        <WarningMessage />
      </WarningMessageWrapper>
      <UploadArea />
    </Wrapper>
  );
};
