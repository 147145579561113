import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../components/Typography/Typography';
import { useBlockingSteps } from './useBlockingSteps';
import { useCallback, useMemo, useState } from 'react';
import { SelectItem, WorkflowStep } from '../../../../../../types';
import { GroupedOptionsMultiselect } from '../../../../../../components/GroupedOptionsMultiselect/GroupedOptionsMultiselect';
import { SASB_ASSESSMENT_NAME, STEP_TYPE_LABELS } from '../../../../../../constants/constants';
import { useIsBlockingStepsDisabled } from './useIsBlockingStepsDisabled';
import { StepCardSwitch } from '../components/StepCardSwitch';

const Container = styled('div')``;

const SwitchWrapper = styled('div')`
  margin-bottom: 12px;
`;

interface Props {
  includeBlockingSteps: boolean;
  onToggleIncludeBlockingSteps: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  initialBlockingSteps: WorkflowStep[];
  onUpdateBlockingSteps: (steps: WorkflowStep[]) => void;
  step: WorkflowStep;
}

export const BlockingSteps = ({
  includeBlockingSteps,
  onToggleIncludeBlockingSteps,
  initialBlockingSteps,
  onUpdateBlockingSteps,
  step,
}: Props) => {
  const { colors } = useTheme();

  const isDisabled = useIsBlockingStepsDisabled(step);

  const initBlockingSteps = useMemo(
    () =>
      initialBlockingSteps?.map((step) => {
        return {
          id: step.id,
          value: step.name,
          group: STEP_TYPE_LABELS[step.type],
        } as SelectItem;
      }),
    [initialBlockingSteps]
  );

  const [selectedSteps, setSelectedSteps] = useState<SelectItem[]>(initBlockingSteps || []);

  const { stepGroups, allSteps } = useBlockingSteps(step);

  const onSelectSteps = useCallback(
    (selectedSteps: SelectItem[]) => {
      setSelectedSteps(selectedSteps);

      const steps = allSteps?.filter((s) => selectedSteps?.map((step) => step.id)?.includes(s.id));

      onUpdateBlockingSteps(steps || []);
    },
    [allSteps, onUpdateBlockingSteps]
  );

  if (step.name === SASB_ASSESSMENT_NAME) {
    return (
      <Container>
        <Typography variant='caption' color={colors.primary[70]}>
          Company information is required for this step
        </Typography>
      </Container>
    );
  }

  if (!allSteps?.length) {
    return (
      <Container>
        <Typography variant='caption' color={colors.primary[70]}>
          There are no possible blocking steps available
        </Typography>
      </Container>
    );
  }

  return (
    <Container style={{ opacity: isDisabled ? 0.5 : 1, cursor: 'not-allowed' }}>
      <SwitchWrapper>
        <StepCardSwitch
          disabled={isDisabled}
          checked={includeBlockingSteps}
          onChange={onToggleIncludeBlockingSteps}
          label='Include blocking steps'
          description='Ensure that steps are only triggered when certain conditions are met.'
        />
      </SwitchWrapper>

      {includeBlockingSteps && (
        <GroupedOptionsMultiselect
          groupedOptions={stepGroups || []}
          selectedItems={selectedSteps}
          onSelectItems={onSelectSteps}
          placeholder='Select blocking steps'
          isDisabled={isDisabled}
        />
      )}
    </Container>
  );
};
