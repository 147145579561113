import { useAtom } from 'jotai';
import { FC, useCallback } from 'react';
import { questionState } from '../../../../state/UIState';
import { SurveyQuestion } from '../../../../types';
import { EvaluationControls } from './components/EvaluationControls';

interface Props {
  question: SurveyQuestion;
}

export const QuestionEvaluationControls: FC<Props> = ({ question }) => {
  const [, setQuestion] = useAtom(questionState(question.id));

  const toggleCustomEvaluation = useCallback(() => {
    const updatedQuestion = {
      ...question,
      includeEvaluation: !question.includeEvaluation,
    };
    setQuestion(updatedQuestion);
  }, [question, setQuestion]);

  return <EvaluationControls question={question} toggleCustomEvaluation={toggleCustomEvaluation} />;
};
