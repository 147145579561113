import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { activeRoleState } from '../state/UIState';
import { USER_ROLE } from '../types';

export const useCompaniesPageRouteName = () => {
  const activeRole = useAtomValue(activeRoleState);

  return useMemo(() => {
    if (activeRole === USER_ROLE.ANALYST) return 'Pending Evaluation';

    return 'Companies';
  }, [activeRole]);
};
