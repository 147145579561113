import { Fade, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';
import { HEADER_ROW_ID } from '../../../../../constants/defaultValues';
import { CellContext } from '@tanstack/react-table';
import { Tooltip } from '../../../../../components/Tooltip/Tooltip';

const CellWrapper = styled('div')<{ isTextTruncated?: boolean }>`
  max-width: 250px;
  width: 250px;
  cursor: ${({ isTextTruncated }) => (isTextTruncated ? 'pointer' : 'auto')};
  &&& p {
    white-space: ${({ isTextTruncated }) => (isTextTruncated ? 'nowrap' : 'normal')};
    text-overflow: ${({ isTextTruncated }) => (isTextTruncated ? 'ellipsis' : 'clip')};
  }
`;

interface Props {
  info: CellContext<Record<string, any>, any>;
  isTextTruncated?: boolean;
}

const Text = ({ info }: Props) => {
  const { colors } = useTheme();
  return (
    <Typography
      variant={info.row.original.id === HEADER_ROW_ID ? 'overline' : 'body'}
      color={info.row.original.id === HEADER_ROW_ID ? colors.primary[70] : colors.primary[90]}
      style={{
        padding: '0 8px',
        textTransform: info.row.original.id === HEADER_ROW_ID ? 'uppercase' : 'none',
      }}
    >
      {info.getValue()}
    </Typography>
  );
};

export const TableReadOnlyCell = ({ info, isTextTruncated }: Props) => {
  if (isTextTruncated) {
    return (
      <Tooltip
        title={info.getValue()}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
      >
        <CellWrapper isTextTruncated>
          <Text info={info} />
        </CellWrapper>
      </Tooltip>
    );
  }

  return (
    <CellWrapper>
      <Text info={info} />
    </CellWrapper>
  );
};
