// import { useAtom } from 'jotai';
// import { showEsgHeatmapState } from '../../../state/UIState';
// import { styled } from '@mui/material';
// import { ScoreSection } from './ScoreSection';
// import { RatingHistory } from './RatingHistory';
// import { EsgScoreHeatmap } from './EsgScoreHeatmap';
// import { SwotSection } from './SwotSection';
// import { companyData } from '../../../mockedData/mockedData';
import { useParams } from 'react-router';
import { useCompanyById, useUpdateCompany } from '../../../queries/useCompanies';
import { ExclusionSection } from './ExclusionSection';
import { COMPANY_STAGE } from '../../../types';
import { useEffect } from 'react';
import { AnalystRating } from './AnalystRating';
import { CompanySection } from './CompanySection';
import { Objectives } from './Objectives/Objectives';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { FeatureFlagKey } from '../../../constants/featureFlags';

export const OverviewTab = () => {
  const params = useParams();
  // const [showEsgHeatmap] = useAtom(showEsgHeatmapState);
  const { isLoading: isLoadingCompany, data: company } = useCompanyById(Number(params.id));
  const updateCompany = useUpdateCompany();
  const showObjectives = useFeatureFlag(FeatureFlagKey.OBJECTIVES);

  useEffect(() => {
    if (company?.stage !== COMPANY_STAGE.ESG_COMPLETE) {
      updateCompany.mutate({
        id: company?.id,
        stage: COMPANY_STAGE.ESG_COMPLETE,
        screeningCompletedDate: new Date().toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!company || isLoadingCompany) return null;

  return (
    <>
      <CompanySection>
        <AnalystRating />
        <ExclusionSection />
      </CompanySection>
      {showObjectives && <Objectives />}
    </>
  );
};
