import { Button, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';

interface Props {
  onResetFilters: () => void;
  isResetDisabled?: boolean;
}

export const ResetFiltersButton = ({ onResetFilters, isResetDisabled }: Props) => {
  const { colors } = useTheme();

  return (
    <Button variant='text' onClick={onResetFilters} disabled={isResetDisabled}>
      <Typography
        variant='subtitle2'
        color={isResetDisabled ? colors.accent[20] : colors.accent[50]}
        style={{ whiteSpace: 'nowrap' }}
      >
        Reset Filters
      </Typography>
    </Button>
  );
};
