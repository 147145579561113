import { useMemo } from 'react';
import { useWorkflowStages } from '../../../../queries/useWorkflowStages';
import { DUE_DILIGENCE_SURVEY_NAME } from '../../../../constants/constants';

export const useFindDependentSteps = () => {
  const { data: stages } = useWorkflowStages();

  const allCreatedSteps = useMemo(() => stages?.flatMap((stage) => stage.steps), [stages]);

  return allCreatedSteps?.filter((step) => step.name === DUE_DILIGENCE_SURVEY_NAME) || [];
};
