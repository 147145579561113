import { useAtom, useAtomValue } from 'jotai';
import { REVIEW_TYPE, SurveyQuestion, TReviewOptionConfig } from '../../../../../types';
import { reviewState, userState } from '../../../../../state/UIState';
import { Dispatch, SetStateAction } from 'react';
import { OverrideOption } from './OverrideOption/OverrideOption';
import { ReviewOption } from '../../../../../components/ReviewOption';

interface Props {
  reviewOption: TReviewOptionConfig;
  question: SurveyQuestion;
  isEvaluated: boolean;
  selectedOption: REVIEW_TYPE | null;
  setSelectedOption: Dispatch<SetStateAction<REVIEW_TYPE | null>>;
}

export const ReviewTabOption = ({
  reviewOption,
  question,
  isEvaluated,
  selectedOption,
  setSelectedOption,
}: Props) => {
  const [review, setReview] = useAtom(reviewState);
  const isSelected = selectedOption === reviewOption.type;
  const user = useAtomValue(userState);

  const onSelectReviewOption = () => {
    if (isSelected) {
      const newReview = { ...review };
      delete newReview[question.id];
      setSelectedOption(null);
      setReview(newReview);
      return;
    }
    const newReview = {
      ...review,
      [question.id]: {
        type: reviewOption.type,
        name: reviewOption.name,
        reviewedBy: user,
        reviewDate: new Date().toISOString(),
      },
    };
    setSelectedOption(reviewOption.type);
    setReview(newReview);
  };

  if (reviewOption?.type === REVIEW_TYPE.OVERRIDE) {
    return (
      <OverrideOption
        reviewOption={reviewOption}
        question={question}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        isEvaluated={isEvaluated}
      />
    );
  }

  return (
    <ReviewOption
      isSelected={isSelected}
      isEvaluated={isEvaluated}
      onSelect={onSelectReviewOption}
      optionColor={reviewOption.color}
      label={reviewOption.name}
    />
  );
};
