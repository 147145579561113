import { TextField, useTheme } from '@mui/material';
import { FormField } from './FormField/FormField';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  isDisabled?: boolean;
  onBlur?: () => void;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

export const ReasonField = ({ isDisabled, value, setValue, onBlur }: Props) => {
  const { colors } = useTheme();
  return (
    <FormField label={'Reason'}>
      <TextField
        name='reason'
        value={value}
        onChange={(e) => {
          setValue(e?.target?.value);
        }}
        onBlur={onBlur}
        variant='outlined'
        style={{ backgroundColor: isDisabled ? colors.primary[10] : '#fff' }}
        disabled={isDisabled}
      />
    </FormField>
  );
};
