import { useAtom, useAtomValue } from 'jotai';
import { reviewState, userState } from '../../../../../../../../state/UIState';
import {
  QuestionOption,
  QuestionReview,
  SurveyQuestion,
  TReviewOptionConfig,
} from '../../../../../../../../types';
import { styled, useTheme } from '@mui/material';
import { Checkbox } from '../../../../../../../../components/Checkbox/Checkbox';
import { Typography } from '../../../../../../../../components/Typography/Typography';
import { useCallback } from 'react';

const OptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

interface Props {
  question: SurveyQuestion;
  reviewOption: TReviewOptionConfig;
}

export const OverrideMultiType = ({ question, reviewOption }: Props) => {
  const [review, setReview] = useAtom(reviewState);
  const user = useAtomValue(userState);

  const onChangeOption = useCallback(
    (option: QuestionOption) => {
      setReview((prevReview) => {
        const prevOverrideResponse = prevReview?.[question.id]?.overrideResponse as
          | QuestionOption[]
          | null;
        let overrideResponse = prevOverrideResponse;

        if (prevOverrideResponse?.some((o) => o.id === option.id)) {
          overrideResponse = prevOverrideResponse.filter((o) => o.id !== option.id);
        } else {
          overrideResponse = prevOverrideResponse?.length
            ? [...prevOverrideResponse, option]
            : [option];
        }
        return {
          ...prevReview,
          [question.id]: {
            type: reviewOption.type,
            name: reviewOption.name,
            overrideResponse,
            reviewedBy: user,
            reviewDate: new Date().toISOString(),
          } as QuestionReview,
        };
      });
    },
    [question.id, reviewOption.name, reviewOption.type, setReview, user]
  );

  const { colors } = useTheme();
  return (
    <>
      {question?.options?.map((option) => {
        const optionResponse = (review?.[question.id]?.overrideResponse as QuestionOption[])?.find(
          (o) => o.id === option.id
        );
        return (
          <OptionWrapper
            key={option.id}
            onClick={(e) => {
              e.stopPropagation();
              onChangeOption(option);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Checkbox
              checked={Boolean(optionResponse)}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 24,
                },
              }}
            />
            <Typography variant='subtitle2' color={colors.primary[90]}>
              {option.name}
            </Typography>
          </OptionWrapper>
        );
      })}
    </>
  );
};
