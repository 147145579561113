import { QuestionOption, Response, SurveyQuestion } from '../../../../../../../types';
import { OptionListWrapper } from '../common';
import { CheckboxOption } from './CheckboxOption';

interface Props {
  question: SurveyQuestion;
  responses: Record<string, Response>;
}

export const MultiQuestion = ({ question, responses }: Props) => {
  return (
    <OptionListWrapper>
      {question.options.map((option) => (
        <CheckboxOption
          key={option.id}
          option={option}
          isChecked={
            (responses[question.id] as QuestionOption[] | null)?.some((o) => o.id === option.id) ??
            false
          }
          selectedOption={(responses[question.id] as QuestionOption[]).find(
            (op) => op.id === option.id
          )}
        />
      ))}
    </OptionListWrapper>
  );
};
