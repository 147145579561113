import { CompanyExclusion, ExportCompanyExclusionsColumn } from '../../../types';
import { formatDate } from '../../../utils/formatters';

export const getExclusionsData = (
  companyName: string,
  companyExclusions?: CompanyExclusion[],
  selectedColumns?: ExportCompanyExclusionsColumn[]
) => {
  if (!selectedColumns?.length) return [];

  if (!companyExclusions?.length)
    return [
      {
        name: companyName,
      },
    ];

  return companyExclusions?.map((exclusion) => {
    let formattedExc = {
      name: companyName,
    };

    selectedColumns.forEach((column) => {
      const val = exclusion[column.value] as string;
      let exclusionText =
        val?.endsWith?.('"') && val?.startsWith?.('"') ? val?.slice?.(1, val.length - 1) : val;

      if (column.value === 'updated_at') {
        exclusionText = val ? formatDate(val) : '';
      }

      if (column.value === 'investorName') {
        exclusionText = exclusion.investorName || '';
      }

      if (column.value === 'funds') {
        const funds = exclusion.funds?.map((f) => f.name || '');

        exclusionText = funds?.join(', ') || '';
      }

      formattedExc = {
        ...formattedExc,
        [column.value]: exclusionText || '',
      };
    });
    return formattedExc;
  }) as any;
};
