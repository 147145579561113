import { DataCollectionHeader } from '../../../../components/DataCollectionLayout';
import { PreviewSurveyDropdown } from './PreviewSurveyDropdown';

export const ChecklistHeader = () => {
  return (
    <DataCollectionHeader title='Company Checklist' description='Used for all companies’ data display'>
      <PreviewSurveyDropdown />
    </DataCollectionHeader>
  );
};
