import { Button, styled, useTheme } from '@mui/material';
import {
  ACTION_BAR_HEIGHT,
  SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH,
} from '../../../constants/layoutSizes';
import { ActionButtonsFooter } from '../../../components/ActionButtonsFooter/ActionButtonsFooter';
import { useAtom } from 'jotai';
import { activeReportState } from '../../../state/UIState';
import { useCallback, useMemo, useState } from 'react';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { useQueryClient } from 'react-query';
import { useCompanyById, useUpdateCompany } from '../../../queries/useCompanies';
import { REPORTS, useUpdateReport } from '../../../queries/useReports';
import { useInternalQuestionnaireSubmit } from './hooks/useInternalQuestionnaireSubmit';
import { useScreeningFlowSurveys } from './hooks/useScreeningFlowSurveys';
import { AssessmentStepData, LoadingId, REPORT_STATUS } from '../../../types';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/arrow-right-blue.svg';
import { ReactComponent as LeftArrow } from '../../../assets/icons/arrow-left-blue.svg';
import { useSurveyFlowData } from './hooks/useSurveyFlowData';
import { useReportTaskClickHandler } from '../../../hooks/useReportTaskClickHandler';
import { useOnReportClick } from '../../../hooks/useOnReportClick';
import { useIsSubmitDisabled } from './hooks/useIsSubmitDisabled';
import {
  DUE_DILIGENCE_SURVEY_NAME,
  INVESTOR_EXCLUSIONS_SURVEY_ID,
} from '../../../constants/constants';

const ActionsWrapper = styled('div')`
  margin-left: ${SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH}px;
  position: relative;
  height: ${ACTION_BAR_HEIGHT}px;
`;

const EditButtonIcon = styled(EditIcon)`
  path {
    fill: ${({ theme }) => theme.colors.accent[50]};
  }
`;

export const InternalQuestionnaireFooter = () => {
  const { colors } = useTheme();

  const [activeReport] = useAtom(activeReportState);
  const [isLoading, setIsLoading] = useState(false);
  const { startLoading, stopLoading } = useLoadingBar();

  const queryClient = useQueryClient();

  const { data: company } = useCompanyById(activeReport?.company?.id);
  const { mutateAsync: updateCompany } = useUpdateCompany();

  const { onSubmitInternal } = useInternalQuestionnaireSubmit();

  const { onReportTaskClickHandler } = useReportTaskClickHandler();
  const { onReportClick } = useOnReportClick();

  const { nextSurveyData, prevSurveyData } = useSurveyFlowData({ companyId: company?.id });

  const updateReport = useUpdateReport();

  const { allAssessmentsStepsData, allAssessmentsSteps } = useScreeningFlowSurveys(
    activeReport?.company?.id
  );

  const onSubmit = useCallback(async () => {
    if (!activeReport) return;
    setIsLoading(true);
    startLoading(LoadingId.completeReport);

    await onSubmitInternal();
    setIsLoading(false);
    return;
  }, [activeReport, startLoading, onSubmitInternal]);

  const isSubmitDisabled = useIsSubmitDisabled();

  const submitText = useMemo(() => {
    if (!nextSurveyData) return 'Submit Report';

    return `Submit & Continue`;
  }, [nextSurveyData]);

  const onEditSubmission = async () => {
    startLoading(LoadingId.editSubmission);
    setIsLoading(true);
    await updateReport.mutateAsync(
      { id: activeReport?.id, status: REPORT_STATUS.IN_PROGRESS },
      {
        onSuccess: async () => {
          const currentStep = allAssessmentsStepsData?.find(
            (stepData) => activeReport?.survey.id === stepData?.surveyId
          )?.step;

          let newCompletedSteps = company?.completedWorkflowSteps?.filter(
            (step) => step !== currentStep?.id
          );

          if (currentStep?.survey?.id === INVESTOR_EXCLUSIONS_SURVEY_ID) {
            const exclusionsDueDiligenceStep = allAssessmentsSteps?.find(
              (step) => step?.name === DUE_DILIGENCE_SURVEY_NAME
            );

            newCompletedSteps = company?.completedWorkflowSteps?.filter(
              (step) => step !== currentStep?.id && step !== exclusionsDueDiligenceStep?.id
            );
          }

          await updateCompany({
            id: company?.id,
            completedWorkflowSteps: newCompletedSteps,
          });
          queryClient.refetchQueries(`${REPORTS}-${activeReport?.company.id}`);
          queryClient.refetchQueries(`${REPORTS}-${activeReport?.id}`);
        },
        onError: (e) => {
          console.error(e);
        },
        onSettled: () => {
          stopLoading(LoadingId.editSubmission);
          setIsLoading(false);
        },
      }
    );
  };

  const handleFlowClick = (surveyData: AssessmentStepData | null) => {
    if (!surveyData) return;
    if (!surveyData.report) {
      onReportTaskClickHandler(
        surveyData.report,
        surveyData?.surveyId,
        surveyData?.surveyName,
        surveyData?.companyId
      );

      return;
    }

    onReportClick(surveyData.report);
  };

  const onNextClick = () => {
    handleFlowClick(nextSurveyData);
  };

  const onPrevClick = () => {
    handleFlowClick(prevSurveyData);
  };

  return (
    <ActionsWrapper>
      <ActionButtonsFooter bgColor={colors.primary[0]}>
        <>
          {activeReport?.status === REPORT_STATUS.SUBMITTED ? (
            <Button
              variant='outlined'
              style={{ width: '173px' }}
              onClick={onEditSubmission}
              startIcon={<EditButtonIcon />}
            >
              Edit Submission
            </Button>
          ) : (
            <Button
              variant='contained'
              onClick={onSubmit}
              disabled={isLoading || isSubmitDisabled}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {submitText}
            </Button>
          )}
          {(nextSurveyData || prevSurveyData) && (
            <>
              <Button
                variant='outlined'
                style={{ width: '104px' }}
                onClick={onPrevClick}
                startIcon={<LeftArrow />}
                disabled={!prevSurveyData}
              >
                Back
              </Button>
              <Button
                variant='outlined'
                style={{ width: '104px' }}
                onClick={onNextClick}
                endIcon={<RightArrow />}
                disabled={!nextSurveyData}
              >
                Next
              </Button>
            </>
          )}
        </>
      </ActionButtonsFooter>
    </ActionsWrapper>
  );
};
