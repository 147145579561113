import { Button, styled, useTheme } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock-blue.svg';
import { Typography } from '../../../components/Typography/Typography';
import { Company } from '../../../types';
import { Breadcrumbs } from '../../../components/Breadcrumbs/Breadcrumbs';

import { useLocation } from 'react-router';

interface Props {
  company: Company;
}

const HeaderWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 20px 40px 36px 40px;
  gap: 20px;
`;

const MainDetailsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 12px;
`;
const Img = styled('img')`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
`;

export const CompanyExclusionsHeader: FC<Props> = ({ company }) => {
  const { colors } = useTheme();

  const { state } = useLocation();

  return (
    <HeaderWrapper>
      <Breadcrumbs
        currentRouteName={'Exclusions'}
        prevRoutes={[
          { prevRoute: state?.prevRoute, prevRouteName: state?.prevRouteName || '' },
          {
            prevRoute: -1,
            prevRouteName: company?.name,
          },
        ]}
      />

      <MainDetailsWrapper>
        <TitleWrapper>
          {company.logoUrl && <Img src={company.logoUrl} />}
          <Typography variant='h1' color={colors.primary[90]}>
            {company.name}
          </Typography>
        </TitleWrapper>
        <Button
          variant='contained'
          startIcon={<ClockIcon style={{ marginTop: '-2px' }} />}
          style={{
            minWidth: 'auto',
            backgroundColor: colors.accent[5],
            border: `1px solid ${colors.accent[20]}`,
            boxShadow: 'none',
          }}
        >
          <Typography variant='subtitle2' color={colors.accent[50]}>
            History
          </Typography>
        </Button>
      </MainDetailsWrapper>
    </HeaderWrapper>
  );
};
