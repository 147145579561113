import { styled } from '@mui/material';
import { Objective } from '../../../../../../../types';
import { ObjectiveName } from './ObjectiveName';
import { RequestStatusCell } from '../../../../../../../components/Table/CellRenderers/RequestStatusCell';
import { RequestProgressCell } from '../../../../../../../components/Table/CellRenderers/RequestProgressCell';
import { LastUpdated } from './LastUpdated';
import { AddKeyTask } from './AddKeyTask';
import { ActionsPopupMenu } from '../../../../../../../components/ActionsPopupMenu/ActionsPopupMenu';
import { Dispatch, MouseEvent, SetStateAction, useCallback, useState } from 'react';
import { ReactComponent as EditIcon } from '../../../../../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../assets/icons/delete.svg';
import { EditObjectiveModal } from './EditObjectiveModal';
import { DeleteObjectiveModal } from './DeleteObjectiveModal';

const Container = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
`;

const StatusWrapper = styled('div')`
  width: 129px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressWrapper = styled('div')`
  width: 176px;
  display: flex;
  align-items: center;
`;

const ActionsWrapper = styled('div')`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

interface Props {
  objective: Objective;
  setExpandedObjectiveId: Dispatch<SetStateAction<number | null>>;
}

const actions = [
  {
    id: 'ADD_KEY_TASK',
    value: '+ Add Key Task',
  },
  {
    id: 'EDIT',
    value: 'Edit',
    icon: <EditIcon />,
  },
  {
    id: 'DELETE',
    value: 'Delete',
    icon: <DeleteIcon />,
  },
];

export const ObjectiveSummary = ({ objective, setExpandedObjectiveId }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openAddKeyTaskModal, setOpenAddKeyTaskModal] = useState(false);
  const [openEditObjectiveModal, setOpenEditObjectiveModal] = useState(false);
  const [openDeleteObjectiveModal, setOpenDeleteObjectiveModal] = useState(false);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (action: string) => {
    if (action === 'ADD_KEY_TASK') {
      setOpenAddKeyTaskModal(true);
      setAnchorEl(null);
      return;
    }
    if (action === 'EDIT') {
      setOpenEditObjectiveModal(true);
      setAnchorEl(null);
      return;
    }

    if (action === 'DELETE') {
      setOpenDeleteObjectiveModal(true);
      setAnchorEl(null);
      return;
    }
  };

  return (
    <Container>
      <ObjectiveName name={objective?.name} />
      <StatusWrapper>
        <RequestStatusCell status={objective.status} />
      </StatusWrapper>
      <ProgressWrapper>
        <RequestProgressCell progress={0} />
      </ProgressWrapper>
      <LastUpdated lastUpdated={objective?.updated_at} />
      <ActionsWrapper>
        <ActionsPopupMenu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          actions={actions}
          onClick={(e) => handleClick(e)}
          onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            handleCloseMenu();
          }}
          onMenuItemClick={onMenuItemClick}
        />
      </ActionsWrapper>
      {openAddKeyTaskModal && (
        <AddKeyTask
          isModalOpen={openAddKeyTaskModal}
          setIsModalOpen={setOpenAddKeyTaskModal}
          objectiveId={objective?.id}
          setExpandedObjectiveId={setExpandedObjectiveId}
        />
      )}
      {openEditObjectiveModal && (
        <EditObjectiveModal
          isModalOpen={openEditObjectiveModal}
          setIsModalOpen={setOpenEditObjectiveModal}
          objective={objective}
        />
      )}
      {openDeleteObjectiveModal && (
        <DeleteObjectiveModal
          isModalOpen={openDeleteObjectiveModal}
          setIsModalOpen={setOpenDeleteObjectiveModal}
          objectiveId={objective.id}
        />
      )}
    </Container>
  );
};
