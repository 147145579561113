import { REQUEST_STEP, STEP_TYPE } from '../../../../../types';

export const REQUEST_STEPS = {
  [REQUEST_STEP.RECURRING_KPI_UPDATE]: {
    type: STEP_TYPE.REQUEST,
    name: 'Recurring KPIs update',
    requestStep: REQUEST_STEP.RECURRING_KPI_UPDATE,
  },
  [REQUEST_STEP.CHECKLIST_QUARTERLY]: {
    type: STEP_TYPE.REQUEST,
    name: 'Checklist Quarterly',
    requestStep: REQUEST_STEP.CHECKLIST_QUARTERLY,
  },
  [REQUEST_STEP.YEARLY_ASSESSMENT]: {
    type: STEP_TYPE.REQUEST,
    name: 'Yearly Assessment',
    requestStep: REQUEST_STEP.YEARLY_ASSESSMENT,
  },
};
