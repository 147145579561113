import {
  ColumnFiltersState,
  RowSelectionState,
  Updater,
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { Table } from '../../../components/Table/Table';
import { Typography } from '../../../components/Typography/Typography';
import { styled, useTheme } from '@mui/material';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { useAtom } from 'jotai';
import { exportColumnsState, exportCompaniesProgressState } from '../../../state/UIState';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { useQueryParams } from '../../../hooks/useQueryParams';
import {
  EXPORT_COMPANIES_PARAMS,
  ExportCompanyColumn,
  ExportCompanyExclusionsColumn,
} from '../../../types';

const CheckboxCellWrapper = styled('div')`
  display: flex;
  align-items: center;
  max-width: 350px;
  gap: 8px;
  width: 350px;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const SearchFilterWrapper = styled('div')`
  margin-top: 20px;
  margin-bottom: 8px;
`;

const columnHelper = createColumnHelper<ExportCompanyColumn | ExportCompanyExclusionsColumn>();

interface Props {
  columnsData: (ExportCompanyColumn | ExportCompanyExclusionsColumn)[];
}

export const ColumnsTable = ({ columnsData }: Props) => {
  const { colors } = useTheme();
  const [, setSelectedColumns] = useAtom(exportColumnsState);

  const { params, setParam } = useQueryParams();
  const columnsFromParams = params[EXPORT_COMPANIES_PARAMS.COLUMNS] as string[];

  const initialRowsSelected = useMemo(() => {
    const savedColumns = columnsData?.filter((column) => columnsFromParams?.includes(column.value));

    if (savedColumns?.length) {
      return savedColumns?.reduce((acc: Record<string, boolean>, column) => {
        acc[column.value] = true;
        return acc;
      }, {});
    }

    return columnsData?.reduce((acc: Record<string, boolean>, column) => {
      acc[column.value] = true;
      return acc;
    }, {});
  }, [columnsData, columnsFromParams]);

  const [rowSelection, setRowSelection] = useState(initialRowsSelected);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [, setProgress] = useAtom(exportCompaniesProgressState);

  useEffect(() => {
    setRowSelection(initialRowsSelected);
  }, [initialRowsSelected]);

  useEffect(() => {
    if (!rowSelection) return;
    const items = Object.keys(rowSelection);

    const newColumns = columnsData.filter((column) => items.includes(column.value));

    newColumns.length === 0 ? setProgress(0) : setProgress(50);

    setSelectedColumns(newColumns);

    setParam({
      param: EXPORT_COMPANIES_PARAMS.COLUMNS,
      value: newColumns?.map((column) => column.value),
    });
  }, [columnsData, rowSelection, setParam, setProgress, setSelectedColumns]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('column', {
        cell: (info) => (
          <CheckboxCellWrapper style={{ position: 'relative', top: '7px' }}>
            <Checkbox checked={info.row.getIsSelected()} style={{ padding: 0 }} />
            <Typography variant='subtitle2' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </CheckboxCellWrapper>
        ),
        header: () => (
          <CheckboxCellWrapper>
            <Checkbox
              style={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                table.getToggleAllRowsSelectedHandler()(e);
              }}
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
              }}
            />
            <Typography variant='overline' color={colors.primary[70]}>
              Column
            </Typography>
          </CheckboxCellWrapper>
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colors.primary]
  );

  const table = useReactTable({
    data: columnsData,
    columns,
    state: {
      columnFilters,
      rowSelection,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: (newState: Updater<RowSelectionState>) => {
      return setRowSelection(newState);
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (companyColumn) => String(companyColumn.value),
  });

  return (
    <Wrapper>
      <Typography variant='h4' color={colors.primary[90]}>
        Columns
      </Typography>
      <SearchFilterWrapper>
        <SearchInput
          placeholder='Search for a column'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('column')?.setFilterValue('')}
          onChange={(e) => table.getColumn('column')?.setFilterValue(e.target.value)}
          value={table.getColumn('column')?.getFilterValue() ?? ''}
        />
      </SearchFilterWrapper>
      <Table table={table} height='467px' />
    </Wrapper>
  );
};
