import { useAtom } from 'jotai';
import { useCallback } from 'react';
import {
  createRequestCompletedProgressState,
  selectedFrequencyState,
  selectedSurveyState,
  requestNameState,
  selectedCompaniesState,
} from '../../../state/UIState';

export function useResetCreateRequestUiState() {
  const [, setProgress] = useAtom(createRequestCompletedProgressState);
  const [, setSelectedFrequency] = useAtom(selectedFrequencyState);
  const [, setSelectedSurvey] = useAtom(selectedSurveyState);
  const [, setSelectedCompanies] = useAtom(selectedCompaniesState);
  const [, setRequestName] = useAtom(requestNameState);

  const resetState = useCallback(() => {
    setProgress(0);
    setSelectedFrequency(null);
    setSelectedSurvey(null);
    setRequestName('');
    setSelectedCompanies([]);
  }, [setProgress, setRequestName, setSelectedCompanies, setSelectedFrequency, setSelectedSurvey]);

  return resetState;
}