import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLoadingBar } from '../../hooks/useLoadingBar';

const LoadingBarPlaceholder = styled('div')`
  height: 4px;
  width: 100%;
`;

export function LoadingBar() {
  const { isLoading } = useLoadingBar();

  if (isLoading) {
    return <LinearProgress variant='indeterminate' style={{ zIndex: 10000 }} />;
  }

  return <LoadingBarPlaceholder />;
}
