import { Fade, styled, useTheme } from '@mui/material';
import { useMemo } from 'react';
import {
  HEADER_ROW_ID,
  KPI_FORMAT_COLUMN_ID,
  KPI_VALUE_COLUMN_ID,
} from '../../../../constants/defaultValues';
import { getCoreKPICellValue } from '../../../../utils/getCoreKpiCellValue';
import { CellContext } from '@tanstack/react-table';
import { Typography } from '../../../../components/Typography/Typography';
import { NotesBoxPopup } from '../../../../components/NotesBoxPopup/NotesBoxPopup';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import { CORE_KPI_FORMAT } from '../../../../types';

const ValueWrapper = styled('div')`
  padding: 0 8px;
  position: relative;
`;

interface Props {
  isCoreKpiValue?: boolean;
  info: CellContext<Record<string, any>, any>;
  questionId: string;
}

export const CoreKpiValue = ({ isCoreKpiValue, info }: Props) => {
  const { colors } = useTheme();

  const isTextFormat = useMemo(
    () => info.row.original[KPI_FORMAT_COLUMN_ID] === CORE_KPI_FORMAT.TEXT,
    [info.row.original]
  );

  return (
    <NotesBoxPopup
      notes={isCoreKpiValue ? info.getValue()?.notes : ''}
      popupPlacement='bottom-end'
      popupStrategy='fixed'
    >
      <ValueWrapper style={{ textAlign: isCoreKpiValue && !isTextFormat ? 'right' : 'left' }}>
        <Tooltip
          title={isCoreKpiValue ? '' : info.getValue()}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [65, -13],
                },
              },
            ],
          }}
        >
          <Typography
            variant={info.row.original.id === HEADER_ROW_ID ? 'overline' : 'body'}
            color={info.row.original.id === HEADER_ROW_ID ? colors.primary[70] : colors.primary[90]}
            style={{
              background:
                info.getValue()?.notes && isCoreKpiValue ? colors.warning[20] : 'transparent',
              display: isCoreKpiValue ? 'inline' : 'block',
              cursor: info.getValue()?.notes && isCoreKpiValue ? 'pointer' : 'auto',
            }}
          >
            {isCoreKpiValue
              ? getCoreKPICellValue(
                  info.getValue()?.value,
                  info.column.id === KPI_VALUE_COLUMN_ID,
                  info.row.original.kpi_format_column
                )
              : info.getValue()}
          </Typography>
        </Tooltip>
      </ValueWrapper>
    </NotesBoxPopup>
  );
};
