import { MenuItem, SelectChangeEvent, styled, useTheme } from '@mui/material';
import { BasicSelect } from '../../../../../../../../../components/BasicSelect/BasicSelect';
import { KEY_TASK_CONDITION_OPERATOR } from '../../../../../../../../../types';
import { Typography } from '../../../../../../../../../components/Typography/Typography';
import { useMemo } from 'react';

const OperatorWrapper = styled('div')`
  flex: 1;
  & .MuiOutlinedInput-root {
    height: 32px;
    border-radius: 0;
    outline: none;
    width: 100%;
  }
`;

const conditionOperatorOptions = [
  {
    id: KEY_TASK_CONDITION_OPERATOR.EQUAL,
    value: 'equal to',
  },
  {
    id: KEY_TASK_CONDITION_OPERATOR.GREATER_THAN,
    value: 'greater than',
  },
  {
    id: KEY_TASK_CONDITION_OPERATOR.LESS_THAN,
    value: 'less than',
  },
];

interface Props {
  conditionOperator?: KEY_TASK_CONDITION_OPERATOR;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const ConditionOperatorSelect = ({ conditionOperator, setFieldValue }: Props) => {
  const { colors } = useTheme();

  const onChange = (event: SelectChangeEvent) => {
    const selectedItem =
      conditionOperatorOptions.find((o) => o.value === event.target.value)?.id || '';
    setFieldValue('conditionOperator', selectedItem as KEY_TASK_CONDITION_OPERATOR);
  };

  const selectedOperator = useMemo(() => {
    return conditionOperatorOptions.find((o) => o.id === conditionOperator)?.value || '';
  }, [conditionOperator]);

  return (
    <OperatorWrapper>
      <BasicSelect
        value={selectedOperator}
        onChange={onChange}
        renderValue={(selected) => {
          return (
            <Typography variant={'subtitle2'} color={colors.primary[90]}>
              {selected}
            </Typography>
          );
        }}
      >
        {conditionOperatorOptions.map((option) => (
          <MenuItem key={option.id} value={option.value}>
            <Typography variant={'subtitle2'} color={colors.primary[90]}>
              {option.value}
            </Typography>
          </MenuItem>
        ))}
      </BasicSelect>
    </OperatorWrapper>
  );
};
