import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { CoreKpiValueCell } from './CoreKpiValueCell';
import { TableInput } from './TableInput';
import { QuestionTableData, SurveyQuestion } from '../../../../../../../types';
import { responsesState } from '../../../../../../../state/UIState';
import { HEADER_ROW_ID, KPI_VALUE_COLUMN_ID } from '../../../../../../../constants/defaultValues';

interface Props {
  question: SurveyQuestion;
}

const columnHelper = createColumnHelper<Record<string, any>>();

export const CoreKpiTableQuestion = ({ question }: Props) => {
  const [responses] = useAtom(responsesState);
  const { columns: columnsData } = question.tableData;

  const columns = useMemo(
    () =>
      columnsData.map((column) => {
        return columnHelper.accessor(column, {
          cell: (info) => {
            const isCoreKpiValue =
              column === KPI_VALUE_COLUMN_ID && info.row.original.id !== HEADER_ROW_ID;
            return (
              <CoreKpiValueCell
                isCoreKpiValue={isCoreKpiValue}
                info={info}
                questionId={question.id}
              />
            );
          },
          meta: {
            width: '140px',
            minWidth: '140px',
            hideTooltip: true,
          },
        });
      }),
    [columnsData, question.id]
  );

  const tableData = useMemo(() => {
    return (responses[question.id] as QuestionTableData)?.rows ?? [];
  }, [question.id, responses]);

  const table = useReactTable({
    data: tableData,
    columns,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => String(row.id),
  });

  return <TableInput table={table} />;
};
