import { useCallback } from 'react';
import {
  getDefaultSingleTypeQuestionOptions,
  getDefaultTableData,
} from '../../../constants/defaultValues';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { ExclusionCategory, QUESTION_TYPE, SurveyQuestion, SurveySection } from '../../../types';
import { useSortedExclusionCategories } from '../../../hooks/useSortedExclusionCategories';
import { useUpdateExclusionsScreeningSurvey } from '../../../queries/useSurveys';
import { useToastMessage } from '../../../hooks/useToastMessage';

export const useOnUpdateExclusionsSurvey = () => {
  const { items } = useSortedExclusionCategories();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const updateExclusionsSurvey = useUpdateExclusionsScreeningSurvey();
  const { pushSuccessToast, pushErrorToast } = useToastMessage();

  const getQuestions = (category: ExclusionCategory): SurveyQuestion[] => {
    return category.conditions
      ?.filter(
        (condition) =>
          condition?.isActive && condition?.exclusions?.some((exclusion) => exclusion?.isActive)
      )
      ?.map((condition) => {
        return {
          id: String(condition.id),
          type: QUESTION_TYPE.SINGLE,
          options: getDefaultSingleTypeQuestionOptions(),
          tableData: getDefaultTableData(),
          includeInstructions: false,
          includeEvaluation: false,
          evaluationRules: null,
          instructions: '',
          sectionId: String(category.id),
          isActive: condition.isActive,
          isRequired: true,
          name: condition.condition,
          followUpQuestions: condition.exclusions
            ?.filter((exclusion) => exclusion?.isActive)
            ?.map((exclusion) => {
              return {
                id: String(exclusion?.id),
                name: exclusion?.exclusion,
                type: QUESTION_TYPE.SINGLE,
                options: getDefaultSingleTypeQuestionOptions(),
                tableData: getDefaultTableData(),
                includeInstructions: false,
                includeEvaluation: false,
                evaluationRules: null,
                instructions: '',
                sectionId: String(condition?.id),
                isActive: exclusion.isActive,
                condition: condition.condition,
                isRequired: true,
                investorId: Number(exclusion.externalInvestorId),
                funds: exclusion?.externalFunds?.map((fund) => fund.id),
              };
            }),
        };
      });
  };

  const onUpdateSurvey = useCallback(async () => {
    try {
      const activeCategories =
        items?.filter((item) =>
          item.conditions?.some(
            (condition) =>
              condition?.isActive && condition?.exclusions?.some((exclusion) => exclusion?.isActive)
          )
        ) || [];

      const sections: SurveySection[] = activeCategories?.map((item) => {
        return {
          id: String(item.id),
          name: item.name,
          collapsed: false,
          depth: 0,
          parentId: null,
          subSections: [],
          questions: getQuestions(item),
        };
      });

      await updateExclusionsSurvey.mutateAsync({
        sections,
      });
      if (!appConfigData?.data) {
        return;
      }

      await updateAppConfig.mutateAsync(
        { data: { ...appConfigData?.data, isExclusionSurveyUpToDate: true } },
        {
          onSuccess: () => {
            pushSuccessToast({
              message: 'Investor Exclusions survey is successfully updated.',
            });
          },
        }
      );
    } catch (e) {
      pushErrorToast({ message: 'Failed to update the survey' });
    }
  }, [
    appConfigData?.data,
    items,
    pushErrorToast,
    pushSuccessToast,
    updateAppConfig,
    updateExclusionsSurvey,
  ]);

  return { onUpdateSurvey };
};
