import { Dispatch, SetStateAction } from 'react';
import { ConfirmDeleteModal } from '../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  onDeleteStep: () => void;
}

export const DeleteStepModal = ({ isModalOpen, setIsModalOpen, onDeleteStep }: Props) => {
  return (
    <ConfirmDeleteModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title='Delete the step?'
      onConfirm={onDeleteStep}
      confirmLabel='Yes, delete'
      cancelLabel='No, cancel'
    />
  );
};
