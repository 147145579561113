import { FC } from 'react';

import { CoreKpiTableQuestion } from './CoreKpiTableQuestion';
import { SurveyQuestion } from '../../../../../../../types';

interface Props {
  question: SurveyQuestion;
  isCoreKpi?: boolean;
}

export const TableQuestion: FC<Props> = ({ question, isCoreKpi }) => {
  if (isCoreKpi) return <CoreKpiTableQuestion question={question} />;

  return null;
};
