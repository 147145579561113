import { useMemo } from 'react';
import { useCompanies } from '../../queries/useCompanies';
import { COMPANY_STAGE, USER_ROLE } from '../../types';
import { PortfolioLayout } from '../../components/PortfolioLayout/PortfolioLayout';
import { PortfolioPageSkeletonLoader } from '../../components/SkeletonLoader/PortfolioPage.SkeletonLoader';
import { AssessmentSummaryTable } from '../../components/AssessmentSummaryTable/AssessmentSummaryTable';
import { useAtom, useAtomValue } from 'jotai';
import {
  activeRoleState,
  issuersAssessmentSummaryFiltersState,
  issuersAssessmentSummarySortingState,
} from '../../state/UIState';

export const Issuers = () => {
  const { data: companies, isLoading } = useCompanies();

  const activeRole = useAtomValue(activeRoleState);

  const [sorting, setSorting] = useAtom(issuersAssessmentSummarySortingState);
  const [columnFilters, setColumnFilters] = useAtom(issuersAssessmentSummaryFiltersState);

  const esgCompleteIssuers = useMemo(() => {
    return companies?.filter((company) => company?.stage === COMPANY_STAGE.ESG_COMPLETE) || [];
  }, [companies]);

  const title = useMemo(() => {
    if (activeRole === USER_ROLE.ANALYST) {
      return 'Evaluated Issuers';
    }
    return 'Issuers';
  }, [activeRole]);

  if (isLoading || !companies) return <PortfolioPageSkeletonLoader />;

  return (
    <PortfolioLayout title={title}>
      <AssessmentSummaryTable
        companies={esgCompleteIssuers}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        prevRouteName='Issuers'
        wrapperHeight='calc(100vh - 267px)'
      />
    </PortfolioLayout>
  );
};
