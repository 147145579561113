import { styled, useTheme } from '@mui/material';
import { SurveySection } from '../../../types';

import { Typography } from '../../../components/Typography/Typography';
import { HtmlText } from '../../SurveyEditor/components/QuestionnaireStep/HtmlText';
import { ReactNode } from 'react';

const SectionWrapper = styled('div')<{ isSubsection?: boolean }>`
  padding: ${({ isSubsection }) => (isSubsection ? 0 : '12px 0')};
  margin-top: ${({ isSubsection }) => (isSubsection ? '11px' : '20px')};
  border-bottom: ${({ isSubsection, theme }) =>
    isSubsection ? 'none' : `1px solid ${theme.colors.primary[30]} `};
`;

interface Props {
  section: SurveySection;
  children: ReactNode;
  isSubsection?: boolean;
}

export const MainContentSection = ({ section, children, isSubsection }: Props) => {
  const { colors } = useTheme();

  return (
    <SectionWrapper key={section.id} isSubsection={isSubsection}>
      <Typography
        variant={isSubsection ? 'h3' : 'h1'}
        color={colors.primary[90]}
        style={{
          paddingBottom: '8px',
        }}
      >
        {section.name}
      </Typography>
      {section?.includeDescription && section.description && (
        <HtmlText htmlText={section.description} />
      )}
      {children}
    </SectionWrapper>
  );
};
