import { styled } from '@mui/material';
import { CORE_KPI_FORMAT } from '../../../../../../../../types';
import { useEffect, useMemo, useState } from 'react';
import { AddNote } from '../../../../../../../../components/AddNote/AddNote';
import { NotesBoxPopup } from '../../../../../../../../components/NotesBoxPopup/NotesBoxPopup';
import { ConfirmDeleteModal } from '../../../../../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { CoreKpiInput } from './CoreKpiInput/CoreKpiInput';
import { formatNumber } from '../../../../../../../../utils/formatters';

const CellWrapper = styled('div')`
  display: flex;
`;

const InputWrapper = styled('div')`
  width: 200px;
  position: relative;
`;

const CellValue = styled('div')<{ isTextFormat: boolean; hasValue?: boolean }>`
  padding-right: 20px;
  padding-left: 4px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: ${({ isTextFormat, hasValue }) =>
    isTextFormat || !hasValue ? 'flex-start' : 'flex-end'};
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  border-radius: 4px;

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.accent[50]}`};
  }
`;

const ValueText = styled('div')<{ isTextFormat?: boolean; hasValue?: boolean; hasNotes?: boolean }>`
  text-align: ${({ isTextFormat, hasValue }) => (isTextFormat || !hasValue ? 'left' : 'right')};
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.primary[90] : theme.colors.primary[50]};
  font-family: ${({ theme, isTextFormat, hasValue }) =>
    isTextFormat || !hasValue
      ? theme.text.font.fontFamilyRegular
      : theme.text.font.fontInconsolataRegular};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  font-size: ${({ theme, isTextFormat, hasValue }) =>
    isTextFormat || !hasValue ? `${theme.text.size.mediumSmall}px` : `${theme.text.size.medium}px`};
  background: ${({ hasNotes, theme }) => (hasNotes ? theme.colors.warning[20] : 'transparent')};
`;

interface Props {
  value: string;
  notes: string;
  onNotesChange: (notes: string) => void;
  onValueChange: (value: string) => void;
  onDeleteNote: () => void;
  format: CORE_KPI_FORMAT;
}

export const CoreKpiValueCell = ({
  value,
  notes,
  format,
  onNotesChange,
  onValueChange,
  onDeleteNote,
}: Props) => {
  const [showAddNotesCTA, setShowAddNotesCTA] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value ?? '');

  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    setShowAddNotesCTA(Boolean(inputValue) && !notes);
  }, [inputValue, notes]);

  const isTextFormat = useMemo(() => format === CORE_KPI_FORMAT.TEXT, [format]);

  const cellValue = useMemo(() => {
    if (!value) return 'Enter value';

    return isTextFormat ? value : formatNumber(value);
  }, [isTextFormat, value]);

  return (
    <CellWrapper>
      <InputWrapper>
        {!showInput ? (
          <CellValue
            onClick={() => setShowInput(true)}
            isTextFormat={isTextFormat}
            hasValue={Boolean(value)}
          >
            <NotesBoxPopup
              notes={notes}
              withActionButtons
              onChangeNotes={onNotesChange}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              popupStrategy='fixed'
            >
              <ValueText
                isTextFormat={isTextFormat}
                hasValue={Boolean(value)}
                hasNotes={Boolean(notes)}
                // style={{
                //   textAlign: isTextFormat ? 'left' : 'right',
                // fontFamily:
                //   value && !isTextFormat
                //     ? text.font.fontInconsolataRegular
                //     : text.font.fontFamilyRegular,
                //   background: notes ? colors.warning[20] : 'transparent',
                // }}
              >
                {cellValue}
              </ValueText>
            </NotesBoxPopup>
          </CellValue>
        ) : (
          <CoreKpiInput
            value={value}
            format={format}
            inputValue={inputValue}
            setInputValue={setInputValue}
            onValueChange={onValueChange}
            withBorder
            setShowInput={setShowInput}
          />
        )}

        <ConfirmDeleteModal
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onConfirm={() => {
            onDeleteNote();
            setIsDeleteModalOpen(false);
          }}
          title='Delete this note?'
          note='This action cannot be undone.'
          confirmLabel='Yes, Delete'
          cancelLabel='No, Cancel'
          isOpen={isDeleteModalOpen}
        />
      </InputWrapper>
      <AddNote
        setShowAddNotesCTA={setShowAddNotesCTA}
        showAddNotesCTA={showAddNotesCTA}
        onNotesChange={onNotesChange}
        popupStrategy='fixed'
        popupWrapperStyles={{
          zIndex: 10,
        }}
      />
    </CellWrapper>
  );
};
