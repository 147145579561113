import { styled, Switch, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { FC, useCallback } from 'react';
import { Typography } from '../../../../components/Typography/Typography';
import { questionState } from '../../../../state/UIState';
import { SurveyQuestion } from '../../../../types';

interface Props {
  question: SurveyQuestion;
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 7px;
`;
const SwitchWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: -9px;
`;

export const ConditionsControls: FC<Props> = ({ question }) => {
  const [, setQuestion] = useAtom(questionState(question.id));
  const { colors } = useTheme();

  const toggleConditions = useCallback(() => {
    const updatedQuestion = {
      ...question,
      includeConditions: !question.includeConditions,
    };
    setQuestion(updatedQuestion);
  }, [question, setQuestion]);

  return (
    <Wrapper>
      <SwitchWrapper>
        <Switch checked={!!question.includeConditions} onChange={toggleConditions} />
        <Typography variant='body' color={colors.primary[80]}>
          Include conditions
        </Typography>
      </SwitchWrapper>
    </Wrapper>
  );
};
