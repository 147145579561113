import { useAtom } from 'jotai';
import { EditCreateChecklistItem } from '../EditCreateChecklistItem';
import { selectedChecklistItemState } from '../../../../state/UIState';
import { useCallback } from 'react';
import {
  ChecklistItemPayload,
  useUpdateChecklistItem,
} from '../../../../queries/useChecklistItems';
import { useAppConfig, useUpdateAppConfig } from '../../../../queries/useAppConfig';

interface Props {
  handleCloseCreateModal: () => void;
}

export const EditItemModal = ({ handleCloseCreateModal }: Props) => {
  const [selectedItemForAction, setSelectedItemForAction] = useAtom(selectedChecklistItemState);

  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const updateChecklistItem = useUpdateChecklistItem();

  const onSubmit = useCallback(
    async (item: Partial<ChecklistItemPayload>) => {
      if (!selectedItemForAction) return;
      await updateChecklistItem.mutateAsync(
        { ...item, id: selectedItemForAction.id },
        {
          onSuccess: async () => {
            if (!appConfigData?.data) {
              return;
            }
            await updateAppConfig.mutateAsync({
              data: { ...appConfigData?.data, areCheckListSurveysUpToDate: false },
            });
          },
        }
      );
      setSelectedItemForAction(null);
      handleCloseCreateModal();
    },
    [
      appConfigData?.data,
      handleCloseCreateModal,
      selectedItemForAction,
      setSelectedItemForAction,
      updateAppConfig,
      updateChecklistItem,
    ]
  );

  return (
    <EditCreateChecklistItem
      onClose={() => {
        setSelectedItemForAction(null);
        handleCloseCreateModal();
      }}
      onSubmit={onSubmit}
      checklistItem={selectedItemForAction}
      title='Edit checklist item'
      confirmBtnText='Save Changes'
    />
  );
};
