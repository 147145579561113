import { Button, styled, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { useUsersTableColumns } from '../hooks/useUsersTableColumns';
import { Table } from '../../../components/Table/Table';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-blue.svg';
import {
  ColumnFiltersState,
  Row,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  USERS,
  useCreateUser,
  useDeleteUser,
  useUpdateUser,
  useUsers,
} from '../../../queries/useUsers';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { LoadingId, User, UserPayload } from '../../../types';
import { ConfirmDeleteModal } from '../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { UsersSkeletonLoader } from '../../../components/SkeletonLoader/Users.SkeletonLoader';
import { CreateEditUsersModal } from './CreateEditUsersModal';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { queryClient } from '../../..';

const Wrapper = styled('div')`
  padding: 24px 40px;
`;

const Title = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const UsersContent: React.FC = () => {
  const { colors } = useTheme();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushSuccessToast } = useToastMessage();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const columns = useUsersTableColumns();
  const { data: users, isLoading } = useUsers();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const handleOpenConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(true), []);
  const handleCloseConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(false), []);
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const handleOpenCreateEditModal = useCallback(() => setIsCreateEditModalOpen(true), []);
  const handleCloseCreateEditModal = useCallback(() => setIsCreateEditModalOpen(false), []);
  const deleteUser = useDeleteUser();
  const updateUser = useUpdateUser();
  const createUser = useCreateUser();

  const table = useReactTable({
    data: users ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (user) => String(user.id),
  });

  const actions = useMemo(() => {
    return [
      {
        id: 'EDIT',
        value: 'Edit',
        icon: <EditIcon />,
      },
      {
        id: 'DELETE',
        value: 'Delete',
        icon: <DeleteIcon />,
      },
    ];
  }, []);

  const handleSelectAction = useCallback(
    (actionId: string, row: Row<User> | null) => {
      if (!row) return;
      switch (actionId) {
        case 'EDIT':
          setSelectedUser(row.original);
          handleOpenCreateEditModal();
          return;
        case 'DELETE':
          setSelectedUser(row.original);
          handleOpenConfirmDeleteModal();
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [handleOpenConfirmDeleteModal, handleOpenCreateEditModal]
  );

  const handleCreateUpdateUser = useCallback(
    async (user: Partial<UserPayload>) => {
      if (!selectedUser) {
        startLoading(LoadingId.createUser);
        await createUser.mutateAsync(user, {
          onSuccess: (user) => {
            pushSuccessToast({ message: `User ${user.fullName} was created successfully` });
            queryClient.refetchQueries(USERS);
          },
          onSettled: () => {
            stopLoading(LoadingId.createUser);
          },
        });
        return;
      }
      const payload = { ...user, id: selectedUser.id };
      await updateUser.mutateAsync(payload);
      setSelectedUser(null);
      handleCloseCreateEditModal();
    },
    [
      createUser,
      handleCloseCreateEditModal,
      pushSuccessToast,
      selectedUser,
      startLoading,
      stopLoading,
      updateUser,
    ]
  );

  const handleDeleteUser = useCallback(async () => {
    if (!selectedUser) return;
    await deleteUser.mutateAsync(selectedUser.id);
    setSelectedUser(null);
    handleCloseConfirmDeleteModal();
  }, [deleteUser, handleCloseConfirmDeleteModal, selectedUser]);

  const onClickAdd = useCallback(() => {
    setSelectedUser(null);
    handleOpenCreateEditModal();
  }, [handleOpenCreateEditModal]);

  if (isLoading || !users) return <UsersSkeletonLoader />;

  return (
    <Wrapper>
      <Title>
        <Typography variant='h4' color={colors.primary[90]}>
          Users
        </Typography>
        <Button
          variant='text'
          startIcon={<AddIcon />}
          style={{ minWidth: 'auto', marginBottom: '-1px' }}
          onClick={onClickAdd}
        >
          <Typography
            variant='subtitle2'
            color={colors.accent[50]}
            style={{ marginLeft: '-2px', marginBottom: '-1px' }}
          >
            Add User
          </Typography>
        </Button>
      </Title>
      <Table table={table} actions={actions} onActionClick={handleSelectAction} />
      <ConfirmDeleteModal
        onClose={() => {
          handleCloseConfirmDeleteModal();
          setSelectedUser(null);
        }}
        onConfirm={handleDeleteUser}
        title={`Delete ${selectedUser?.fullName ?? ''}?`}
        note={`${selectedUser?.email ?? ''} access to the platform will be removed immediately.`}
        confirmLabel='Delete'
        cancelLabel='Cancel'
        isOpen={isConfirmDeleteModalOpen}
      />
      {isCreateEditModalOpen && (
        <CreateEditUsersModal
          onClose={() => {
            setSelectedUser(null);
            handleCloseCreateEditModal();
          }}
          onSubmit={handleCreateUpdateUser}
          user={selectedUser}
          title={selectedUser ? 'Edit User' : 'Add User'}
          confirmBtnText={selectedUser ? 'Save Changes' : 'Add User'}
        />
      )}
    </Wrapper>
  );
};
