import { useCallback, useMemo } from 'react';
import { SingleSelect } from '../../../../../components/SingleSelect/SingleSelect';
import { Table } from '@tanstack/react-table';
import { Company, SelectItem } from '../../../../../types';
import { useCompanies } from '../../../../../queries/useCompanies';
import { useAtom } from 'jotai';
import { selectedCompanyStageFilterState } from '../../../../../state/UIState';

interface Props {
  table: Table<Company>;
}

export const StageFilter = ({ table }: Props) => {
  const { data: companies } = useCompanies();
  const [selectedStage, setSelectedStage] = useAtom(selectedCompanyStageFilterState);

  const sortedUniqueStages = useMemo(() => {
    const unique = Array.from(table.getColumn('stage')?.getFacetedUniqueValues().keys() ?? [])
      .sort()
      .map((value, id) => ({ id, value }));
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const onChangeStageFilter = useCallback(
    (stage: SelectItem | null) => {
      setSelectedStage(stage);
      table.getColumn('stage')?.setFilterValue(stage?.value ?? '');
    },
    [setSelectedStage, table]
  );

  return (
    <SingleSelect
      style={{ width: '200px' }}
      options={sortedUniqueStages}
      value={selectedStage}
      onChange={(_, stage) => onChangeStageFilter(stage)}
      disablePortal
      fieldPlaceholder='Filter by Stage'
    />
  );
};
