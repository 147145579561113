import { Menu, MenuItem, styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-green-round-small.svg';
import { ReactComponent as DangerIcon } from '../../../assets/icons/danger-red-round-small.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-down-dropdown.svg';

import { EXCLUSION_STATUS } from '../../../types';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { COMPANIES, useCompanyById, useUpdateCompany } from '../../../queries/useCompanies';
import { useParams } from 'react-router';

interface Props {
  status: EXCLUSION_STATUS;
  exclusionId: number;
  companyId: number;
}

const StatusWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StatusSelect = styled('div')<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 128px;
  height: 24px;
  border-radius: 2px;
  padding: 0 4px;
  ${({ isActive, theme }) => isActive && `background-color: ${theme.colors.gray[20]};`}
  cursor: pointer;
`;

const Status = ({ status }: { status: EXCLUSION_STATUS }) => {
  const { colors } = useTheme();
  const Icon = useMemo(() => {
    if (status === EXCLUSION_STATUS.VIOLATED) return <DangerIcon />;
    if (status === EXCLUSION_STATUS.SATISFIED) return <SuccessIcon />;
    return null;
  }, [status]);
  return (
    <StatusWrapper>
      {Icon}
      <Typography variant='body' color={colors.primary[90]}>
        {status}
      </Typography>
    </StatusWrapper>
  );
};

export const ExclusionStatusCell: FC<Props> = ({ status, exclusionId, companyId }) => {
  const { colors } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const params = useParams();

  const open = Boolean(anchorEl);
  const updateCompany = useUpdateCompany();
  const queryClient = useQueryClient();

  const { data: company } = useCompanyById(Number(params.id));

  const options = useMemo(() => {
    return [EXCLUSION_STATUS.SATISFIED, EXCLUSION_STATUS.VIOLATED];
  }, []);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = useCallback(
    (e: MouseEvent<HTMLLIElement>, option: EXCLUSION_STATUS) => {
      e.stopPropagation();

      let violatedExclusionsIds = company?.violatedExclusionsIds || [];

      if (option === EXCLUSION_STATUS.VIOLATED && !violatedExclusionsIds?.includes(exclusionId)) {
        violatedExclusionsIds = [...violatedExclusionsIds, exclusionId];
      }

      if (option === EXCLUSION_STATUS.SATISFIED) {
        violatedExclusionsIds = violatedExclusionsIds?.filter((id) => id !== exclusionId);
      }

      updateCompany.mutate(
        {
          id: companyId,
          violatedExclusionsIds,
        },
        {
          onSuccess: () => {
            queryClient.setQueryData(`${COMPANIES}-${companyId}`, (old) => {
              if (!old) return;
              return {
                ...old,
                violatedExclusionsIds,
              };
            });
          },
        }
      );

      handleCloseMenu();
    },
    [company?.violatedExclusionsIds, companyId, exclusionId, queryClient, updateCompany]
  );

  return (
    <>
      <StatusSelect
        isActive={open}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <Status status={status} />
        <ArrowIcon />
      </StatusSelect>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseMenu();
        }}
        sx={{
          '& .MuiList-root .MuiButtonBase-root:hover': { backgroundColor: colors.accent[5] },
          '& .MuiList-root .MuiButtonBase-root': { backgroundColor: 'transparent' },
        }}
        PaperProps={{
          style: {
            width: '128px',
            transform: 'translateY(4px)',
            boxShadow: 'none',
            border: `1px solid ${colors.primary[30]}`,
          },
        }}
      >
        {options.map((o) => (
          <MenuItem key={o} onClick={(e) => handleSelectOption(e, o)} style={{ width: '100%' }}>
            <Status status={o} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
