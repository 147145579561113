import { useCallback, useMemo } from 'react';
import { SingleSelect } from '../../../../../../../../../components/SingleSelect/SingleSelect';
import { useChecklistCategories } from '../../../../../../../../../queries/useChecklistCategories';

interface Props {
  checklistItem?: number | null;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const ChecklistItemSelect = ({ checklistItem, setFieldValue }: Props) => {
  const { data: checklistCategories } = useChecklistCategories();

  const checklistItems = useMemo(() => {
    return checklistCategories?.flatMap((category) => category?.items || []);
  }, [checklistCategories]);

  const checklistOptions = useMemo(() => {
    return (
      checklistItems?.map((item) => ({
        id: item?.id,
        value: item.name,
      })) || []
    );
  }, [checklistItems]);

  const selectedItem = useMemo(
    () => checklistOptions.find((o) => o.id === checklistItem),
    [checklistItem, checklistOptions]
  );

  const onChangeChecklistItem = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('checklistItem', '');
        return;
      }
      setFieldValue('checklistItem', option?.id);
    },
    [setFieldValue]
  );

  return (
    <SingleSelect
      value={selectedItem || null}
      options={checklistOptions}
      onChange={(e, newValue) => onChangeChecklistItem(newValue)}
      fieldPlaceholder='Select Item'
    />
  );
};
