import { useMutation } from 'react-query';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';

interface NotifyPayload {
  companyId: number;
  userIds: number[];
  roleIds?: number[];
}

async function notifyUsers(payload: NotifyPayload) {
  await Http.axios.post(`/company/notify/${payload.companyId}`, {
    userIds: payload.userIds,
    roleIds: payload.roleIds,
  });
}

export function useNotifyUsers() {
  const { pushErrorToast } = useToastMessage();

  return useMutation(notifyUsers, {
    onError: () => {
      pushErrorToast({ message: 'Failed to notify users' });
    },
  });
}
