import { styled } from '@mui/material';
import { FC, useMemo } from 'react';
import { Request, REPORT_STATUS } from '../../../types';
import { ProgressKpi } from './ProgressKpi';

interface Props {
  requests: Request[];
}
const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 1rem;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-radius: 2px;
  padding: 16px 24px;
`;

export const ProgressStatistics: FC<Props> = ({ requests }) => {
  const kps = useMemo(() => {
    const reports = requests.map((request) => request.reports).flat();
    const total = reports.length;
    const notStarted = reports.filter(
      (report) => report.status === REPORT_STATUS.NOT_STARTED
    ).length;
    const notStartedPercentage = Math.round((notStarted / total) * 100);
    const inProgress = reports.filter(
      (report) => report.status === REPORT_STATUS.IN_PROGRESS
    ).length;
    const inProgressPercentage = Math.round((inProgress / total) * 100);
    const review = reports.filter((report) => report.status === REPORT_STATUS.IN_REVIEW).length;
    const reviewPercentage = Math.round((review / total) * 100);
    const approved = reports.filter((report) => report.status === REPORT_STATUS.APPROVED).length;
    const approvedPercentage = Math.round((approved / total) * 100);
    return [
      {
        label: 'Sent',
        value: total,
      },
      {
        label: 'Not Started',
        value: notStarted,
        percentage: notStartedPercentage,
      },
      {
        label: 'In Progress',
        value: inProgress,
        percentage: inProgressPercentage,
      },
      {
        label: 'In Review',
        value: review,
        percentage: reviewPercentage,
      },
      {
        label: 'Approved',
        value: approved,
        percentage: approvedPercentage,
      },
    ];
  }, [requests]);
  return (
    <Wrapper>
      {kps.map((kpi) => (
        <ProgressKpi key={kpi.label} {...kpi} />
      ))}
    </Wrapper>
  );
};
