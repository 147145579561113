import { Button, styled, useTheme } from '@mui/material';
import { APPLY_EVALUATION_RULES_HEADER_CONTROLS } from '../../../constants/layoutSizes';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-blue.svg';
import { ReactNode } from 'react';

const PAGE_HEADER_HEIGHT = 143;

const Wrapper = styled('div')<{ isConfirmationBarShown?: boolean }>`
  padding: 24px 40px 153px 40px;
  flex-grow: 1;
  height: ${({ isConfirmationBarShown }) =>
    `calc(100vh - ${
      isConfirmationBarShown
        ? PAGE_HEADER_HEIGHT + APPLY_EVALUATION_RULES_HEADER_CONTROLS
        : PAGE_HEADER_HEIGHT
    }px)`};
  overflow-y: auto;
  transition: height 0.3s ease-in-out;
`;

const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

interface Props {
  isConfirmationBarShown?: boolean;
  children: ReactNode;
  onAddStage: () => void;
}

export const StagesManagement = ({ isConfirmationBarShown, children, onAddStage }: Props) => {
  const { colors } = useTheme();

  return (
    <Wrapper isConfirmationBarShown={isConfirmationBarShown}>
      <HeaderWrapper>
        <Typography variant='h4' color={colors.accent[90]}>
          Stages
        </Typography>
        <Button
          variant='text'
          startIcon={<AddIcon />}
          style={{ minWidth: 'auto', marginBottom: '-1px' }}
          onClick={() => ''}
          disabled={false}
        >
          <Typography
            variant='subtitle2'
            color={colors.accent[50]}
            style={{ marginLeft: '-2px', marginBottom: '-1px' }}
            onClick={onAddStage}
          >
            Add Stage
          </Typography>
        </Button>
      </HeaderWrapper>
      {children}
    </Wrapper>
  );
};
