import { Button, styled, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left-blue.svg';
import { HEADER_HEIGHT } from '../../constants/layoutSizes';
import { useCallback } from 'react';
import { ROUTES } from '../../constants/routes';
import { Typography } from '../../components/Typography/Typography';
import { useReportById } from '../../queries/useReports';

const Wrapper = styled('div')`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[30]};
  position: sticky;
  top: 0;
`;

const BackButtonWrapper = styled('div')`
  width: 450px;
  display: flex;
  align-items: center;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Img = styled('img')`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: contain;
`;

export const ReviewReportHeader = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  const params = useParams();

  const { data: report } = useReportById(Number(params?.reportId));

  const onBack = useCallback(() => {
    if (window.history.state.idx === 0) {
      if (!report?.company) return;
      navigate(`/${ROUTES.COMPANIES}/${report.company.id}/`);
    } else {
      navigate(-1);
    }
  }, [navigate, report?.company]);

  return (
    <Wrapper>
      <BackButtonWrapper>
        <Button
          onClick={onBack}
          variant='text'
          startIcon={<ArrowLeftIcon />}
          style={{ minWidth: 'auto' }}
        >
          <HeaderWrapper>
            {report?.company.logoUrl && <Img src={report?.company.logoUrl} />}
            <Typography variant='h3' color={colors.primary[90]}>
              {`${report?.company.name} Response`}
            </Typography>
          </HeaderWrapper>
        </Button>
      </BackButtonWrapper>
    </Wrapper>
  );
};
