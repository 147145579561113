import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { useParams } from 'react-router';
import { useReportById } from '../../../../queries/useReports';
import { useMemo } from 'react';
import { EvaluationScorePreview } from '../../../../components/EvaluationScorePreview/EvaluationScorePreview';
import { EVALUATION_SCALE } from '../../../../types';
import { getEvaluationRules } from '../../../../utils/getEvaluationRules';
import { useAtom } from 'jotai';
import { evaluationState } from '../../../../state/UIState';

const ScoreWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

interface Props {
  questionId: string;
  evaluationScale?: EVALUATION_SCALE;
}

export const EvaluationScore = ({ questionId, evaluationScale }: Props) => {
  const { colors } = useTheme();
  const params = useParams();

  const { data: report } = useReportById(Number(params?.reportId));
  const [evaluation] = useAtom(evaluationState);

  const score = useMemo(() => {
    return evaluation?.[questionId]?.weight;
  }, [questionId, evaluation]);

  const color = useMemo(() => {
    const evaluationConfig = getEvaluationRules(evaluationScale);

    return (
      evaluationConfig?.find((evaluationRule) => evaluationRule?.weight === score)?.color ||
      'success'
    );
  }, [evaluationScale, score]);

  return (
    <ScoreWrapper>
      <Typography variant='srOnly' color={colors.primary[90]}>
        Evaluation Score
      </Typography>
      <EvaluationScorePreview
        score={score}
        label={String(score)}
        color={colors[color][40]}
        evaluationScale={report?.survey.evaluationScale}
      />
    </ScoreWrapper>
  );
};
