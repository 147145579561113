import { useMemo } from 'react';
import { useCompanyById } from '../queries/useCompanies';

export const useSASBSurvey = (companyId?: number) => {
  const { data: company } = useCompanyById(companyId);

  const SASBSurvey = useMemo(() => {
    if (!company) return null;

    return company?.industry?.survey;
  }, [company]);

  return SASBSurvey;
};
