import { useToastMessage } from '../../../hooks/useToastMessage';
import {
  useUpdateChecklistAnnuallySurvey,
  useUpdateChecklistQuarterlySurvey,
} from '../../../queries/useSurveys';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { useCallback } from 'react';
import {
  CHECKLIST_ITEM_FREQUENCY,
  CHECKLIST_OPTION,
  ChecklistItem,
  QUESTION_TYPE,
  SurveyQuestion,
  SurveySection,
} from '../../../types';

import { useSortedChecklistCategories } from './useSortedChecklistCategories';
import { generateId } from '../../../utils/generateId';

const getQuestions = (items: ChecklistItem[], sectionId: number) => {
  const questions: SurveyQuestion[] = items.map((item) => {
    const options = [
      { id: generateId(), name: CHECKLIST_OPTION.YES },
      { id: generateId(), name: CHECKLIST_OPTION.NO },
      { id: generateId(), name: CHECKLIST_OPTION.NA },
    ];
    return {
      type: QUESTION_TYPE.SINGLE,
      id: item.id.toString(),
      name: item.name,
      includeInstructions: Boolean(item.instructions),
      instructions: item.instructions,
      isActive: true,
      sectionId: sectionId.toString(),
      options,
      tableData: { columns: [], rows: [] },
      includeEvaluation: false,
      evaluationRules: null,
      isRequired: true,
    };
  });

  return questions;
};

export const useUpdateChecklistSurveys = () => {
  const updateChecklistQuarterlySurvey = useUpdateChecklistQuarterlySurvey();
  const updateChecklistAnnuallySurvey = useUpdateChecklistAnnuallySurvey();

  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { items: checklistCategories } = useSortedChecklistCategories();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const onUpdateChecklistSurveys = useCallback(async () => {
    const filteredCategories = checklistCategories.filter((c) => c.items.length > 0);

    const quarterlyCategories = filteredCategories.filter((c) =>
      c.items.some((item) => item.frequency === CHECKLIST_ITEM_FREQUENCY.QUARTERLY)
    );

    const quarterlySections: SurveySection[] = quarterlyCategories.map((c) => {
      const quarterlyItems = c.items.filter(
        (item) => item.frequency === CHECKLIST_ITEM_FREQUENCY.QUARTERLY
      );
      const questions = getQuestions(quarterlyItems, c.id);

      return {
        id: c.id.toString(),
        collapsed: false,
        depth: 0,
        name: c.name,
        parentId: null,
        questions: questions,
        subSections: [],
      };
    });

    const annuallyCategories = filteredCategories.filter((c) =>
      c.items.some((item) => item.frequency === CHECKLIST_ITEM_FREQUENCY.ANNUALLY)
    );

    const annuallySections: SurveySection[] = annuallyCategories.map((c) => {
      const annuallyItems = c.items.filter(
        (item) => item.frequency === CHECKLIST_ITEM_FREQUENCY.ANNUALLY
      );
      const questions = getQuestions(annuallyItems, c.id);

      return {
        id: c.id.toString(),
        collapsed: false,
        depth: 0,
        name: c.name,
        parentId: null,
        questions: questions,
        subSections: [],
      };
    });

    try {
      await updateChecklistQuarterlySurvey.mutateAsync({
        sections: quarterlySections,
      });

      await updateChecklistAnnuallySurvey.mutateAsync({
        sections: annuallySections,
      });
      if (!appConfigData?.data) {
        return;
      }

      await updateAppConfig.mutateAsync(
        { data: { ...appConfigData?.data, areCheckListSurveysUpToDate: true } },
        {
          onSuccess: () => {
            pushSuccessToast({
              message: 'Checklist Requests were successfully updated.',
            });
          },
        }
      );
    } catch (e) {
      pushErrorToast({ message: 'Failed to update surveys' });
    }
  }, [
    appConfigData?.data,
    checklistCategories,
    pushErrorToast,
    pushSuccessToast,
    updateAppConfig,
    updateChecklistAnnuallySurvey,
    updateChecklistQuarterlySurvey,
  ]);

  return { onUpdateChecklistSurveys };
};
