import { useCallback, useState } from 'react';
import { Textarea } from '../../../../../../../../components/Textarea/Textarea';
import { useAtomValue, useSetAtom } from 'jotai';
import { reviewState, userState } from '../../../../../../../../state/UIState';
import { QuestionReview, SurveyQuestion, TReviewOptionConfig } from '../../../../../../../../types';

interface Props {
  question: SurveyQuestion;
  reviewOption: TReviewOptionConfig;
}

export const OverrideTextType = ({ question, reviewOption }: Props) => {
  const [value, setValue] = useState('');
  const setReview = useSetAtom(reviewState);
  const user = useAtomValue(userState);

  const onBlur = useCallback(() => {
    setReview((prevReview) => {
      return {
        ...prevReview,
        [question.id]: {
          type: reviewOption.type,
          name: reviewOption.name,
          overrideResponse: value,
          reviewedBy: user,
          reviewDate: new Date().toISOString(),
        } as QuestionReview,
      };
    });
  }, [question.id, reviewOption.name, reviewOption.type, setReview, user, value]);

  return (
    <Textarea
      style={{ height: '100px', backgroundColor: '#fff' }}
      name='yourAnswer'
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      placeholder='Enter your answer'
      maxLength={500}
      onBlur={onBlur}
    />
  );
};
