import { CORE_KPI_FORMAT } from '../types';
import { formatNumber } from './formatters';

export const getCoreKPICellValue = (
  value: string,
  isValueCell?: boolean,
  format?: CORE_KPI_FORMAT
) => {
  if (!isValueCell || format === CORE_KPI_FORMAT.TEXT) return value;

  const suffix = format === CORE_KPI_FORMAT.PERCENTAGE ? '%' : undefined;

  return formatNumber(value, { suffix });
};
