import { useAtom, useAtomValue } from 'jotai';
import {
  QuestionOption,
  QuestionReview,
  SurveyQuestion,
  TReviewOptionConfig,
} from '../../../../../../../../types';
import { responsesState, reviewState, userState } from '../../../../../../../../state/UIState';
import { styled, useTheme } from '@mui/material';
import { Radio } from '../../../../../../../../components/Radio/Radio';
import { Typography } from '../../../../../../../../components/Typography/Typography';
import { useCallback } from 'react';

interface Props {
  question: SurveyQuestion;
  reviewOption: TReviewOptionConfig;
}

const OptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

export const OverrideSingleType = ({ question, reviewOption }: Props) => {
  const [responses] = useAtom(responsesState);
  const [review, setReview] = useAtom(reviewState);
  const user = useAtomValue(userState);

  const onChangeOption = useCallback(
    (option: QuestionOption) => {
      setReview((oldResponses) => {
        return {
          ...oldResponses,
          [question.id]: {
            type: reviewOption.type,
            name: reviewOption.name,
            overrideResponse: option,
            reviewedBy: user,
            reviewDate: new Date().toISOString(),
          } as QuestionReview,
        };
      });
    },
    [question.id, reviewOption.name, reviewOption.type, setReview, user]
  );

  const { colors } = useTheme();
  return (
    <>
      {question?.options?.map((option) => {
        const isDisabled = (responses[question.id] as QuestionOption)?.id === option.id;
        return (
          <OptionWrapper
            key={option.id}
            onClick={(e) => {
              e.stopPropagation();
              onChangeOption(option);
            }}
            style={{ opacity: isDisabled ? 0.2 : 1, cursor: isDisabled ? 'auto' : 'pointer' }}
          >
            <Radio
              checked={
                (review?.[question.id]?.overrideResponse as QuestionOption)?.id === option.id
              }
              disabled={isDisabled}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 24,
                },
              }}
            />
            <Typography variant='subtitle2' color={colors.primary[90]}>
              {option.name}
            </Typography>
          </OptionWrapper>
        );
      })}
    </>
  );
};
