import { EVALUATION_SCALE } from '../../../types';

export const evaluationScaleConfig = [
  {
    id: EVALUATION_SCALE.BINARY,
    value: 'Binary (0-1)',
  },
  {
    id: EVALUATION_SCALE.RANGE,
    value: 'Range (1-7)',
  },
];
