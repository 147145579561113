import { CSSObject, styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';
import { useMemo } from 'react';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';

const Container = styled('div')<{ isHighlighted?: boolean; disabled?: boolean }>`
  width: 100%;
  padding: 20px 12px 20px 20px;
  background-color: ${({ isHighlighted, theme, disabled }) => {
    if (disabled) return theme.colors.gray[10];
    return isHighlighted ? theme.colors.accent[5] : theme.colors.primary[0];
  }};
  margin-bottom: 16px;
  border-radius: 4px;
  border-left: ${({ isHighlighted, theme, disabled }) => {
    if (disabled) return `4px solid ${theme.colors.primary[20]}`;
    return `4px solid ${isHighlighted ? theme.colors.accent[50] : theme.colors.primary[30]}`;
  }};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

const InfoIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 4px;
  path {
    fill: #c3cad0;
  }
`;

interface Props {
  taskName: string;
  isHighlighted?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  onTaskNameClick?: () => void;
  taskNameStyle?: CSSObject;
  infoText?: string;
}

export const Task = ({
  taskName,
  isHighlighted,
  children,
  disabled,
  onTaskNameClick,
  infoText,
}: Props) => {
  const { colors } = useTheme();

  const taskNameColor = useMemo(() => {
    if (onTaskNameClick && !disabled) return colors.accent[50];

    if (disabled) return colors.primary[60];

    return colors.primary[90];
  }, [colors.accent, colors.primary, disabled, onTaskNameClick]);

  return (
    <Container isHighlighted={isHighlighted} disabled={disabled}>
      <TitleWrapper>
        <Typography
          variant='h5'
          color={taskNameColor}
          onClick={() => {
            if (disabled) return;
            onTaskNameClick?.();
          }}
          style={{ cursor: onTaskNameClick && !disabled ? 'pointer' : 'auto' }}
        >
          {taskName}
        </Typography>
        {disabled && infoText && (
          <InfoContainer>
            <InfoIconWrapper>
              <InfoIcon />
            </InfoIconWrapper>
            <Typography variant='body' color={colors.primary[70]}>
              {infoText}
            </Typography>
          </InfoContainer>
        )}
      </TitleWrapper>
      {children}
    </Container>
  );
};
