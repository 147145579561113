import { styled } from '@mui/material';
import { useAtom } from 'jotai';
import { Fragment, useEffect } from 'react';

import {
  surveyCompletedPercentageState,
  sectionsState,
  notCompletedSectionsState,
  areShowedAllQuestionsState,
} from '../../../../state/UIState';
import { QuestionnaireSectionItem } from './QuestionnaireSectionItem';
import { SurveySection } from '../../../../types';
import { flattenTree } from '../../../../utils/treeUtilities';
import { SidebarSectionsWithProgressBar } from '../../../../components/SidebarSectionsWithProgressBar/SidebarSectionsWithProgressBar';

const SectionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const QuestionnaireSideSection = () => {
  const [completedPercentage] = useAtom(surveyCompletedPercentageState);
  const [allSections] = useAtom(sectionsState);
  const [notCompletedSections] = useAtom(notCompletedSectionsState);
  const [showAllSections, setShowAllSections] = useAtom(areShowedAllQuestionsState);
  const buttonLabel = showAllSections ? 'Show Unanswered' : 'Show All';
  const sections = showAllSections ? allSections : notCompletedSections;

  useEffect(() => {
    if (!notCompletedSections.length) setShowAllSections(true);
  }, [allSections, notCompletedSections.length, setShowAllSections]);

  function renderSubsections(section: SurveySection): JSX.Element | null {
    const [, ...flatSubsections] = flattenTree([section]);
    if (flatSubsections && flatSubsections.length) {
      return (
        <Fragment>
          {flatSubsections.map((subsection, i) => (
            <QuestionnaireSectionItem
              key={subsection.id}
              section={subsection}
              isLastSubsection={i === flatSubsections.length - 1}
            />
          ))}
        </Fragment>
      );
    }

    return null;
  }

  return (
    <SidebarSectionsWithProgressBar
      completedPercentage={completedPercentage}
      setShowAllSections={setShowAllSections}
      showAllSectionsCTADisabled={!notCompletedSections.length}
      showSectionsButtonText={buttonLabel}
    >
      {sections.map((section) => (
        <SectionWrapper key={section.id}>
          <QuestionnaireSectionItem key={section.id} section={section} />
          {!section.collapsed && renderSubsections(section)}
        </SectionWrapper>
      ))}
    </SidebarSectionsWithProgressBar>
  );
};
