import { Button, styled, useTheme } from '@mui/material';
import {
  HEADER_HEIGHT,
  SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH,
} from '../../constants/layoutSizes';
import { Typography } from '../Typography/Typography';
import { Dispatch, ReactNode, useEffect, useState } from 'react';
import { SetStateAction } from 'jotai';
import { ProgressBar } from '../ProgressBar/ProgressBar';

const Wrapper = styled('div')`
  padding: 24px 0;
  width: ${SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH}px;
  min-width: ${SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH}px;
  border-right: 1px solid ${({ theme }) => theme.colors.primary[20]};
  height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 68px;
  z-index: 2;
`;

const ProgressWrapper = styled('div')`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const ProgressDetailsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .MuiButtonBase-root.MuiButton-root.Mui-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SectionListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

interface Props {
  completedPercentage: number;
  setShowAllSections: Dispatch<SetStateAction<boolean>>;
  showAllSectionsCTADisabled: boolean;
  showSectionsButtonText: string;
  children: ReactNode;
}

export const SidebarSectionsWithProgressBar = ({
  completedPercentage,
  setShowAllSections,
  showAllSectionsCTADisabled,
  showSectionsButtonText,
  children,
}: Props) => {
  const { colors } = useTheme();

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => setWidth(completedPercentage), 200);
  }, [completedPercentage]);

  return (
    <Wrapper>
      <ProgressWrapper>
        <ProgressDetailsWrapper>
          <Typography variant='body' color={colors.primary[70]}>
            {completedPercentage}%
          </Typography>
          <Button
            onClick={() => setShowAllSections((prev) => !prev)}
            variant='text'
            disabled={showAllSectionsCTADisabled}
          >
            <Typography variant='subtitle1' color={colors.accent[50]}>
              {showSectionsButtonText}
            </Typography>
          </Button>
        </ProgressDetailsWrapper>
        <ProgressBar progress={width} color={colors.accent[40]} />
      </ProgressWrapper>
      <SectionListWrapper>{children}</SectionListWrapper>
    </Wrapper>
  );
};
