import {
  Row,
  RowSelectionState,
  SortingState,
  Updater,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChecklistItem } from '../../../../types';
import { Checkbox, Fade, styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import {
  activeChecklistCategoryState,
  checklistTableRowSelectionState,
  isConfirmChecklistBarActiveState,
  isMoveChecklistModeActiveState,
  selectedChecklistItemState,
  selectedChecklistItemsState,
} from '../../../../state/UIState';
import { Typography } from '../../../../components/Typography/Typography';
import { Table } from '../../../../components/Table/Table';
import { APPLY_EVALUATION_RULES_HEADER_CONTROLS } from '../../../../constants/layoutSizes';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as MoveIcon } from '../../../../assets/icons/corner-arrow.svg';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useAppConfig, useUpdateAppConfig } from '../../../../queries/useAppConfig';
import { useUpdateChecklistItems } from '../../../../queries/useChecklistItems';
import { EditItemModal } from './EditItemModal';
import { DeleteSingleItemModal } from './DeleteSingleItemModal';
import { ChecklistItemsSelectionBar } from './ChecklistItemsSelectionBar';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';

const CheckboxCellWrapper = styled('div')`
  display: flex;
  align-items: center;
  max-width: 350px;
  gap: 8px;
  width: 350px;
`;

const CellWrapper = styled('div')<{ isTextTruncated?: boolean }>`
  max-width: 250px;
  width: 250px;
  cursor: ${({ isTextTruncated }) => (isTextTruncated ? 'pointer' : 'auto')};
  &&& p {
    white-space: ${({ isTextTruncated }) => (isTextTruncated ? 'nowrap' : 'normal')};
    text-overflow: ${({ isTextTruncated }) => (isTextTruncated ? 'ellipsis' : 'clip')};
  }
`;

const columnHelper = createColumnHelper<ChecklistItem>();

export const ChecklistItemsTable = () => {
  const { colors } = useTheme();

  const [selectedItemForAction, setSelectedItemForAction] = useAtom(selectedChecklistItemState);
  const [isMoveModeActive, setIsMoveModeActive] = useAtom(isMoveChecklistModeActiveState);
  const [activeCategory] = useAtom(activeChecklistCategoryState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useAtom(checklistTableRowSelectionState);
  const [isConfirmBarActive, setIsConfirmBarActive] = useAtom(isConfirmChecklistBarActiveState);
  const [selectedItems, setSelectedItems] = useAtom(selectedChecklistItemsState);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const handleOpenCreateModal = () => setIsCreateModalOpen(true);
  const handleCloseCreateModal = () => setIsCreateModalOpen(false);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const handleOpenConfirmDeleteModal = () => setIsConfirmDeleteModalOpen(true);
  const handleCloseConfirmDeleteModal = () => setIsConfirmDeleteModalOpen(false);

  const updateChecklistItems = useUpdateChecklistItems();

  const data = useMemo<ChecklistItem[]>(() => {
    if (!activeCategory || !activeCategory.items) {
      return [];
    }
    return activeCategory.items;
  }, [activeCategory]);

  const [itemsList, setItemsList] = useState<ChecklistItem[]>([]);

  useEffect(() => {
    setItemsList(data);
  }, [data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <CheckboxCellWrapper style={{ position: 'relative', top: '7px' }}>
            <Checkbox
              checked={
                selectedItemForAction
                  ? selectedItemForAction.id === info.row.original.id
                  : info.row.getIsSelected()
              }
              disabled={isMoveModeActive}
              style={{ padding: 0 }}
            />
            <Typography variant='subtitle2' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </CheckboxCellWrapper>
        ),
        header: () => (
          <CheckboxCellWrapper>
            <Checkbox
              disabled={isMoveModeActive}
              style={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                table.getToggleAllRowsSelectedHandler()(e);
              }}
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
              }}
            />
            <Typography variant='overline' color={colors.primary[70]}>
              Item
            </Typography>
          </CheckboxCellWrapper>
        ),
        meta: {
          width: '40%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('frequency', {
        cell: (info) => (
          <Typography
            variant='body'
            color={colors.primary[90]}
            style={{ textTransform: 'capitalize' }}
          >
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Frequency
          </Typography>
        ),
        meta: {
          width: '30%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('instructions', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <CellWrapper isTextTruncated>
              <Typography variant='body' color={colors.primary[90]}>
                {info.getValue()}
              </Typography>
            </CellWrapper>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Instructions
          </Typography>
        ),
        meta: {
          width: '30%',
        },
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colors.primary, isMoveModeActive, selectedItemForAction]
  );

  const table = useReactTable({
    data: itemsList,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: (newState: Updater<RowSelectionState>) => {
      return isMoveModeActive ? undefined : setRowSelection(newState);
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (item) => String(item.id),
  });

  useEffect(() => {
    if (!itemsList) return;
    const newItems = Object.keys(rowSelection)
      .map((id) => itemsList.find((item) => item.id === Number(id)))
      .filter(Boolean) as ChecklistItem[];
    setSelectedItems(newItems);
  }, [itemsList, rowSelection, setSelectedItems]);

  const actions = useMemo(() => {
    return [
      {
        id: 'EDIT',
        value: 'Edit item',
        icon: <EditIcon />,
      },
      {
        id: 'MOVE',
        value: 'Move item to...',
        icon: <MoveIcon />,
      },
      {
        id: 'DELETE',
        value: 'Delete item',
        icon: <DeleteIcon />,
      },
    ];
  }, []);

  const handleSelectAction = useCallback(
    (actionId: string, row: Row<ChecklistItem> | null) => {
      if (!row) return;
      switch (actionId) {
        case 'EDIT':
          setSelectedItemForAction(row.original);
          handleOpenCreateModal();
          return;
        case 'MOVE':
          setIsConfirmBarActive(true);
          setIsMoveModeActive(true);
          setSelectedItemForAction(row.original);
          return;
        case 'DELETE':
          setSelectedItemForAction(row.original);
          handleOpenConfirmDeleteModal();
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [setIsConfirmBarActive, setIsMoveModeActive, setSelectedItemForAction]
  );

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!active?.id || !over?.id) return;

      const oldIndex = itemsList.findIndex((item) => item.id === Number(active.id));
      const newIndex = itemsList?.findIndex((item) => item.id === Number(over.id));

      const reorderedItems = arrayMove(itemsList, oldIndex, newIndex);

      setItemsList(reorderedItems);

      const payload = {
        data: reorderedItems.map((item, i) => ({
          id: item.id,
          order: i + 1,
        })),
      };

      updateChecklistItems.mutateAsync(payload, {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, areCheckListSurveysUpToDate: false },
          });
        },
      });
    },
    [appConfigData?.data, itemsList, updateAppConfig, updateChecklistItems]
  );

  return (
    <>
      <ChecklistItemsSelectionBar />
      <Table
        table={table}
        height={
          isConfirmBarActive
            ? `calc(100vh - ${234 + APPLY_EVALUATION_RULES_HEADER_CONTROLS}px)`
            : 'calc(100vh - 234px)'
        }
        trStyle={{ height: 'auto' }}
        tdStyle={{
          verticalAlign: 'baseline',
          paddingBottom: '8px',
        }}
        actions={isMoveModeActive || selectedItems.length ? undefined : actions}
        onActionClick={handleSelectAction}
        disableLayoutAnimation
        isDraggable
        onDragEnd={handleReorder}
      />

      {isCreateModalOpen && <EditItemModal handleCloseCreateModal={handleCloseCreateModal} />}
      <DeleteSingleItemModal
        isConfirmDeleteModalOpen={isConfirmDeleteModalOpen}
        handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
      />
    </>
  );
};
