import { styled } from '@mui/material';
import { BuildMainContent } from './BuildMainContent';
import { BuildSideSection } from './BuildSideSection/BuildSideSection';
import { TemplateSideMenu } from './TemplateSection/TemplateSideMenu';

const Wrapper = styled('div')`
  display: flex;
`;

export const BuildStep = () => {
  return (
    <Wrapper>
      <BuildSideSection />
      <BuildMainContent />
      <TemplateSideMenu />
    </Wrapper>
  );
};
