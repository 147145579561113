import {
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Company, Sector, SelectItem, SelectableCompanyTableData } from '../../types';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { ButtonBase, styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { SearchInput } from '../SearchInput/SearchInput';
import { Multiselect } from '../Multiselect/Multiselect';
import { Table } from '../Table/Table';
import { CompaniesSkeletonLoader } from '../SkeletonLoader/Companies.SkeletonLoader';
import { useTableColumns } from './useTableColumns';
import { SingleSelect } from '../SingleSelect/SingleSelect';
import { ResetFiltersButton } from '../ResetFiltersButton/ResetFiltersButton';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

const SelectedOverviewWrapper = styled('div')`
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.primary[80]};
  border-radius: 2px;
  padding: 0 16px;
  margin-top: 16px;
`;

interface Props {
  disabledText?: string;
  companiesTableData: SelectableCompanyTableData[];
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  rowSelection: RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<Record<number, boolean>>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  selectedCompanies: Company[];
  isLoading: boolean;
  tableHeight?: string;
}

export const SelectableCompaniesTable = ({
  disabledText,
  companiesTableData,
  sorting,
  setSorting,
  rowSelection,
  setRowSelection,
  columnFilters,
  setColumnFilters,
  selectedCompanies,
  isLoading,
  tableHeight,
}: Props) => {
  const { colors } = useTheme();

  const [selectedSectors, setSelectedSectors] = useState<SelectItem[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<SelectItem[]>([]);
  const [selectedType, setSelectedType] = useState<SelectItem | null>(null);

  const columns = useTableColumns(disabledText);

  const table = useReactTable({
    data: companiesTableData,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (company) => String(company.id),
  });

  const sortedUniqueSectors = useMemo(() => {
    const allValues = Array.from(table.getColumn('sectors')?.getFacetedUniqueValues().keys() ?? []);
    const map = allValues.reduce((acc, curr) => {
      curr.forEach((element: Sector) => {
        acc[element.name] = element.name;
      });
      return acc;
    }, {});
    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    setSelectedSectors(unique);
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companiesTableData]);

  const onChangeSectorsFilter = useCallback(
    (sectors: SelectItem[]) => {
      setSelectedSectors(sectors);
      table.getColumn('sectors')?.setFilterValue(sectors.map((s) => s.value));
    },
    [table]
  );

  const sortedUniqueCountries = useMemo(() => {
    const allValues = Array.from(table.getColumn('country')?.getFacetedUniqueValues().keys() ?? []);
    const map = allValues.reduce((acc, curr) => {
      if (!curr) return acc;
      acc[curr] = curr;
      return acc;
    }, {});

    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    if (!columnFilters.length) setSelectedCountries(unique);
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companiesTableData]);

  const sortedUniqueTypes = useMemo(() => {
    const unique = Array.from(table.getColumn('type')?.getFacetedUniqueValues().keys() ?? [])
      .sort()
      .map((value, id) => ({ id, value }));
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companiesTableData]);

  const onChangeTypeFilter = useCallback(
    (type: SelectItem | null) => {
      setSelectedType(type);
      table.getColumn('type')?.setFilterValue(type?.value ?? '');
    },
    [setSelectedType, table]
  );

  const onChangeCountriesFilter = useCallback(
    (countries: SelectItem[]) => {
      setSelectedCountries(countries);
      table.getColumn('country')?.setFilterValue(countries.map((c) => c.value));
    },
    [table]
  );

  const onCancelSelection = useCallback(() => {
    setRowSelection({});
  }, [setRowSelection]);

  const getSelectedOverviewLabel = useCallback(() => {
    const itemsCount = selectedCompanies.length;
    return `${itemsCount} ${itemsCount > 1 ? 'Items' : 'Item'} selected`;
  }, [selectedCompanies.length]);

  const onResetFilters = useCallback(() => {
    setSelectedSectors(sortedUniqueSectors);
    setSelectedCountries(sortedUniqueCountries);
    setSelectedType(null);
    setColumnFilters([]);
  }, [
    setColumnFilters,
    setSelectedCountries,
    setSelectedSectors,
    setSelectedType,
    sortedUniqueCountries,
    sortedUniqueSectors,
  ]);

  const isResetDisabled = useMemo(() => {
    if (!columnFilters.length) return true;
  }, [columnFilters.length]);

  if (isLoading) return <CompaniesSkeletonLoader />;

  return (
    <Wrapper>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a company'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
        <SingleSelect
          style={{ width: '200px' }}
          options={sortedUniqueTypes}
          value={selectedType}
          onChange={(_, type) => onChangeTypeFilter(type)}
          disablePortal
          fieldPlaceholder='Filter by Type'
        />
        <Multiselect
          style={{ width: '200px' }}
          options={sortedUniqueSectors}
          value={selectedSectors}
          onChange={(_, val) => {
            onChangeSectorsFilter(val as SelectItem[]);
          }}
          disablePortal
          optionName='Sector'
          fieldPlaceholder='Filter by Sectors'
        />
        <Multiselect
          style={{ width: '200px' }}
          options={sortedUniqueCountries}
          value={selectedCountries}
          onChange={(_, val) => {
            onChangeCountriesFilter(val as SelectItem[]);
          }}
          disablePortal
          optionsName='Countries'
          fieldPlaceholder='Filter by Countries'
        />
        <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
      </FiltersWrapper>

      <SelectedOverviewWrapper
        style={{ visibility: Object.keys(rowSelection).length ? 'visible' : 'hidden' }}
      >
        <Typography variant='body' color={colors.primary[0]}>
          {getSelectedOverviewLabel()}
        </Typography>
        <ButtonBase onClick={() => onCancelSelection()}>
          <Typography variant='body' color={colors.primary[0]} style={{ cursor: 'pointer' }}>
            Cancel
          </Typography>
        </ButtonBase>
      </SelectedOverviewWrapper>
      <Table table={table} height={tableHeight} />
    </Wrapper>
  );
};
