import { WorkflowStage } from '../../../../types';

import { StagesList } from '../../components/StagesList';
import { useCallback } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useUpdateStages } from '../../../../queries/useWorkflowStages';
import { useAppConfig, useUpdateAppConfig } from '../../../../queries/useAppConfig';
import { IssuersStageCard } from './IssuersStageCard';

interface Props {
  stages: WorkflowStage[];
}

export const IssuersStagesList = ({ stages }: Props) => {
  const { mutate: updateStages } = useUpdateStages();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!active?.id || !over?.id || !stages?.length) return;

      const oldIndex = stages?.findIndex((item) => item.id === active.id);
      const newIndex = stages?.findIndex((item) => item.id === over.id);

      const reorderedItems = arrayMove(stages, oldIndex, newIndex);

      const payload = {
        data: reorderedItems.map((item, i) => ({
          name: item.name,
          order: i + 1,
          id: item.id,
        })),
      };

      updateStages(payload, {
        onSuccess: async () => {
          if (!appConfigData) return;

          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isNotControlledWorkflowUpToDate: false },
          });
        },
      });
    },
    [appConfigData, stages, updateAppConfig, updateStages]
  );
  return (
    <StagesList stages={stages} handleReorder={handleReorder}>
      {stages?.map((stage) => {
        return <IssuersStageCard stage={stage} key={stage.id} isDraggable={stages.length > 1} />;
      })}
    </StagesList>
  );
};
