import { BasicSelect } from '../../../../../../components/BasicSelect/BasicSelect';
import { ReactComponent as LockLocked } from '../../../../../../assets/icons/lock-locked.svg';
import { ReactComponent as LockUnlocked } from '../../../../../../assets/icons/lock-unlocked.svg';
import { useMemo, useState } from 'react';
import { USER_APPROVAL_REQUIREMENT_OPTIONS, User } from '../../../../../../types';
import { MenuItem, SelectChangeEvent, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../components/Typography/Typography';
import { RequirementOption } from './RequirementOption';

const Wrapper = styled('div')`
  & .MuiOutlinedInput-root .MuiSelect-select {
    padding: 4px 36px 4px 12px;
  }
`;

type TMenuItem = {
  name: USER_APPROVAL_REQUIREMENT_OPTIONS;
  icon: JSX.Element;
};

const requirementOptions: TMenuItem[] = [
  {
    name: USER_APPROVAL_REQUIREMENT_OPTIONS.REQUIRED,
    icon: <LockLocked />,
  },
  {
    name: USER_APPROVAL_REQUIREMENT_OPTIONS.OPTIONAL,
    icon: <LockUnlocked />,
  },
];

interface Props {
  requiredUsersApproval: User[];
  userId: number;
  onUpdateRequiredUsersApproval: (ids: number[]) => void;
}

export const RequirementOptions = ({
  requiredUsersApproval,
  userId,
  onUpdateRequiredUsersApproval,
}: Props) => {
  const initSelectedOption = useMemo(() => {
    return requiredUsersApproval?.find((u) => u.id === userId)
      ? USER_APPROVAL_REQUIREMENT_OPTIONS.REQUIRED
      : USER_APPROVAL_REQUIREMENT_OPTIONS.OPTIONAL;
  }, [requiredUsersApproval, userId]);
  const [selectedOption, setSelectedOption] = useState(initSelectedOption);

  const { colors } = useTheme();

  const onChange = (event: SelectChangeEvent) => {
    const option = event.target.value as USER_APPROVAL_REQUIREMENT_OPTIONS;
    setSelectedOption(option);

    const requiredUsersIds = requiredUsersApproval?.map((u) => u.id) || [];

    if (option === USER_APPROVAL_REQUIREMENT_OPTIONS.REQUIRED) {
      onUpdateRequiredUsersApproval([...requiredUsersIds, userId]);
      return;
    }

    if (option === USER_APPROVAL_REQUIREMENT_OPTIONS.OPTIONAL) {
      onUpdateRequiredUsersApproval(
        requiredUsersIds?.filter((requiredUserId) => requiredUserId !== userId) || []
      );
    }
  };

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <BasicSelect
        value={selectedOption}
        onChange={onChange}
        renderValue={(selected) => {
          const selectedItem = requirementOptions.find((option) => selected === option.name);
          if (!selectedItem)
            return (
              <Typography variant={'subtitle2'} color={colors.accent[50]}>
                Select an option
              </Typography>
            );
          return <RequirementOption icon={selectedItem?.icon} option={selectedItem.name} />;
        }}
      >
        {requirementOptions.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            <RequirementOption icon={option.icon} option={option.name} />
          </MenuItem>
        ))}
      </BasicSelect>
    </Wrapper>
  );
};
