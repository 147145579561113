import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { User } from '../../../types';
import { Chip, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { queryClient } from '../../..';
import { ROLES, fetchUserRoles } from '../../../queries/useUsers';

const columnHelper = createColumnHelper<User>();

export const useUsersTableColumns = () => {
  queryClient.prefetchQuery({
    queryKey: ROLES,
    queryFn: () => fetchUserRoles(),
  });
  const { colors } = useTheme();
  const columns = useMemo(
    () => [
      columnHelper.accessor('fullName', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Full Name
          </Typography>
        ),
        meta: {
          width: '300px',
          minWidth: '300px',
        },
      }),
      columnHelper.accessor('email', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Email
          </Typography>
        ),
        meta: {
          width: '300px',
          minWidth: '300px',
        },
      }),

      columnHelper.accessor('role', {
        cell: (info) => (info.getValue() ? <Chip label={info.getValue()?.name} /> : null),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Role
          </Typography>
        ),
        meta: {
          width: '100%',
          minWidth: '150px',
        },
      }),
    ],
    [colors.primary]
  );

  return columns;
};
