import { useMemo } from 'react';
import { CoreKpiRequestFrequency, SelectItem, SURVEY_FREQUENCY } from '../../../../../types';
import { useAtom } from 'jotai';
import {
  companyKpiRequestFrequencyState,
  selectedFrequencyPreviewState,
} from '../../../../../state/UIState';
import { Typography } from '../../../../../components/Typography/Typography';
import { styled, useTheme } from '@mui/material';
import { ChipsSelectorV2 } from '../../../../../components/ButtonSelector/ChipsSelectorV2';

const FrequencySelectorContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const FrequencySelector = () => {
  const { colors } = useTheme();
  const [companyKpiRequestFrequency] = useAtom(companyKpiRequestFrequencyState);
  const [selectedFrequencyPreview, setSelectedFrequencyPreview] = useAtom(
    selectedFrequencyPreviewState
  );

  const frequencyPreviewOptions: SelectItem[] = useMemo(() => {
    if (!companyKpiRequestFrequency) return [];
    const annuallyOption: SelectItem = {
      id: SURVEY_FREQUENCY.ANNUALLY,
      value: 'Annually',
    };
    const quarterlyOption: SelectItem = {
      id: SURVEY_FREQUENCY.QUARTERLY,
      value: 'Quarterly',
    };
    const monthlyOption: SelectItem = {
      id: SURVEY_FREQUENCY.MONTHLY,
      value: 'Monthly',
    };
    if (companyKpiRequestFrequency === SURVEY_FREQUENCY.QUARTERLY) {
      return [quarterlyOption, annuallyOption];
    }
    if (companyKpiRequestFrequency === SURVEY_FREQUENCY.MONTHLY) {
      return [monthlyOption, quarterlyOption, annuallyOption];
    }
    return [];
  }, [companyKpiRequestFrequency]);

  if (!frequencyPreviewOptions.length) return null;

  return (
    <FrequencySelectorContainer>
      <Typography variant='body' color={colors.primary[90]}>
        Display
      </Typography>
      <ChipsSelectorV2
        buttonStyle={{ height: '32px' }}
        options={frequencyPreviewOptions}
        selectedItem={
          frequencyPreviewOptions.find((o) => o.id === selectedFrequencyPreview) as SelectItem
        }
        onSelect={(item) => setSelectedFrequencyPreview(item.id as CoreKpiRequestFrequency)}
      />
    </FrequencySelectorContainer>
  );
};
