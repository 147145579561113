/* istanbul ignore file */
import { useEffect, useRef } from 'react';
import type { ECharts } from 'echarts/core';
import { ComposeOption, init as echartsInit, use as echartsUse } from 'echarts/core';
import {
  BarSeriesOption,
  DatasetComponentOption,
  GridComponentOption,
  LegendComponentOption,
  LineSeriesOption,
  TitleComponentOption,
  TooltipComponentOption,
} from 'echarts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import { BarChart as EBarChart, LineChart } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useIsInViewport } from '../../../hooks/useIsInViewport';
import { styled, useTheme } from '@mui/material';
import { Legend } from './Legend';

export type ChartFormatData = Record<string, string | number | null> & { period: string };

export const Chart = styled('div')<{
  width: number | string;
  height?: string;
}>`
  min-width: ${({ width }) => `${width}`};
  height: ${({ height }) => (height ? `${height}` : '340px')};
  position: relative;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export type ECTrendOptions = ComposeOption<
  | BarSeriesOption
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
>;

// Register the required components
echartsUse([
  EBarChart,
  CanvasRenderer,
  LineChart,
  DatasetComponent,
  GridComponent,
  LabelLayout,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  UniversalTransition,
]);

const axisTickTextStyle = {
  fontFamily: 'Inconsolata-Regular',
  fontSize: 14,
};

const tooltipTextStyle = {
  fontFamily: 'PPNeueMontreal-Regular',
  fontSize: 14,
};

export interface BarchartProps {
  seriesData: (BarSeriesOption | LineSeriesOption)[];
  tooltipValueFormatter?: (val: number) => string;
  yAxisLabelFormatter?: (val: number) => string;
  showLegend?: boolean;
  onResetSelection?: () => void;
}

export function BarLineChart({
  seriesData,
  tooltipValueFormatter = (val) => String(val),
  yAxisLabelFormatter,
  showLegend,
  onResetSelection,
}: BarchartProps) {
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const trendChart = useRef<ECharts | null>(null);
  const { colors } = useTheme();
  const isInViewport = useIsInViewport(
    {
      threshold: 0.75,
    },
    chartContainer
  );

  useEffect(() => {
    const container = chartContainer.current;

    if (!container) {
      return;
    }

    if (!trendChart.current) {
      trendChart.current = echartsInit(container);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          trendChart.current?.resize();
        }
      }
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainer]);

  useEffect(() => {
    if (!(trendChart.current && isInViewport)) {
      return;
    }

    const options: ECTrendOptions = {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
        valueFormatter: (value) => {
          if (value !== 0 && !value) {
            return '-';
          }
          return tooltipValueFormatter(value as number);
        },
        trigger: 'axis',
        ...tooltipTextStyle,
      },
      grid: {
        containLabel: true,
        top: 32,
        bottom: 0,
        left: 0,
        right: 0,
      },
      legend: { show: false },
      xAxis: {
        axisLabel: {
          ...axisTickTextStyle,
        },
        axisLine: {
          show: false,
        },
        type: 'category',
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          ...axisTickTextStyle,
          formatter: yAxisLabelFormatter,
        },
      },
      series: seriesData,
    };

    if (trendChart.current) {
      trendChart.current.setOption(options, true);
    }
  }, [
    colors.accent,
    colors.primary,
    isInViewport,
    seriesData,
    tooltipValueFormatter,
    yAxisLabelFormatter,
  ]);

  return (
    <Wrapper>
      <Chart width={'100%'} ref={chartContainer} />
      {showLegend && trendChart?.current && (
        <Legend chartRef={trendChart} seriesData={seriesData} onResetSelection={onResetSelection} />
      )}
    </Wrapper>
  );
}
