import { QUESTION_TYPE, QuestionOption, Report } from '../types';
import { flattenTree } from './treeUtilities';

export const getNumberOfFlaggedAnswers = (report?: Report) => {
  if (!report) return null;

  const sections = flattenTree(report.survey.sections);
  const questions = sections.map((section) => section.questions).flat();
  const optionalQuestions = questions.filter(
    (question) => question.type === QUESTION_TYPE.SINGLE || question.type === QUESTION_TYPE.MULTI
  );

  const flagsNum = optionalQuestions
    .map((question) => {
      let num = 0;
      question.options.forEach((o) => {
        const answer = report.response?.[question?.id] as QuestionOption | QuestionOption[];
        if (!answer) return;
        const isAnswerMulti = Array.isArray(answer);

        const isOptionFlagged = isAnswerMulti
          ? answer?.find((a) => o.id === a.id && a.isFlagged)
          : answer?.id === o.id && answer?.isFlagged;

        if (o.isFlagged && isOptionFlagged) {
          num += 1;
        }
      });

      return num;
    })
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  return flagsNum;
};
