import { useAtom } from 'jotai';
import {
  DataCollectionSideSection,
  SideSectionList,
} from '../../../components/DataCollectionLayout';
import { useSortedExclusionCategories } from '../../../hooks/useSortedExclusionCategories';
import {
  activeExclusionCategoryState,
  isConfirmExclusionsBarActiveState,
  isMoveExclusionModeActiveState,
  selectedExclusionCategoryState,
} from '../../../state/UIState';
import { useCallback } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { CategoryItem, ExclusionCategory } from '../../../types';

export const SideSection = () => {
  const { onAddCategory, items, onReorder, onRemoveCategory, onRenameCategory } =
    useSortedExclusionCategories();

  const [isConfirmMoveBarActive] = useAtom(isConfirmExclusionsBarActiveState);
  const [activeCategory, setActiveCategory] = useAtom(activeExclusionCategoryState);
  const [selectedExclsuionCategory, setSelectedExclusionCategory] = useAtom(
    selectedExclusionCategoryState
  );

  const [isMoveModeActive] = useAtom(isMoveExclusionModeActiveState);

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (items && over?.id && active.id !== over.id) {
        const oldIndex = items.findIndex((s) => s.id === active.id);
        const newIndex = items.findIndex((s) => s.id === over.id);
        const reorderedCategories = arrayMove(items, oldIndex, newIndex);
        onReorder(reorderedCategories);
      }
    },
    [items, onReorder]
  );

  function handleClick(item: CategoryItem) {
    if (isMoveModeActive && item.id === activeCategory?.id) {
      return;
    }
    if (isMoveModeActive) {
      setSelectedExclusionCategory(item as ExclusionCategory);
      return;
    }
    setActiveCategory(item as ExclusionCategory);
  }

  return (
    <DataCollectionSideSection
      title='Exclusion Categories'
      isActive={isConfirmMoveBarActive}
      onAddCategory={onAddCategory}
    >
      <SideSectionList
        items={items}
        handleReorder={handleReorder}
        handleClick={handleClick}
        activeCategory={activeCategory}
        onRenameCategory={onRenameCategory}
        onRemoveCategory={onRemoveCategory}
        selectedCategory={selectedExclsuionCategory}
        isMoveModeActive={isMoveModeActive}
        deleteModalTitle='Delete the exclusion category?'
        deleteModalNote='All conditions and exclusions inside will be lost.'
      />
    </DataCollectionSideSection>
  );
};
