import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { ReactComponent as DropdownButton } from '../../../../assets/icons/arrow-down-blue.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye-blue.svg';
import { useCallback, useRef, useState } from 'react';
import { useOutsideClick } from '../../../../utils/useOusideClick';
import { SURVEY_STAGE } from '../../../../types';
import { useSurveys } from '../../../../queries/useSurveys';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../../constants/routes';

type Option = {
  label: string;
  value: SURVEY_STAGE;
};

const options: Option[] = [
  {
    label: 'Quarterly',
    value: SURVEY_STAGE.CHECKLIST_QUARTERLY,
  },
  {
    label: 'Annually',
    value: SURVEY_STAGE.CHECKLIST_ANNUALLY,
  },
];

const Container = styled('div')`
  position: relative;
  height: 32px;
  display: flex;
`;

const LabelContainer = styled('div')`
  border-top: ${({ theme }) => `1px solid ${theme.colors.accent[20]}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.accent[20]}`};
  border-left: ${({ theme }) => `1px solid ${theme.colors.accent[20]}`};
  border-radius: 4px 0 0 4px;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.colors.accent[5]};
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ButtonContainer = styled('div')<{ isOpen: boolean }>`
  border: ${({ theme, isOpen }) =>
    `1px solid ${isOpen ? theme.colors.accent[50] : theme.colors.accent[20]}`};
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.colors.accent[20] : theme.colors.accent[5]};
  border-radius: 0 4px 4px 0;
  padding: 0 8px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const OptionsContainer = styled('div')`
  border: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  position: absolute;
  top: calc(4px + 100%);
  left: 0;
  width: 100%;
  padding: 8px 12px;
  z-index: 1;
`;

const ArrowWrapper = styled('div')<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionItem = styled('div')`
  cursor: pointer;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

export const PreviewSurveyDropdown = () => {
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const { data: surveys } = useSurveys();

  const { colors } = useTheme();
  const [open, setOpen] = useState<React.RefObject<HTMLDivElement> | null>(null);

  const onDropdownClose = () => {
    setOpen(null);
  };

  const onToggle = () => {
    if (open) {
      setOpen(null);
      return;
    }

    setOpen(ref);
  };

  const onOptionClick = useCallback(
    (option: Option) => {
      const chosenSurvey = surveys?.find((survey) => option.value === survey.stage);

      navigate(`/${ROUTES.SURVEYS}/${chosenSurvey?.id}/review`);
    },
    [navigate, surveys]
  );

  useOutsideClick(open, onDropdownClose);

  return (
    <Container ref={ref}>
      <LabelContainer>
        <EyeIcon />
        <Typography variant='subtitle2' color={colors.accent[50]}>
          Preview Survey
        </Typography>
      </LabelContainer>
      <ButtonContainer isOpen={Boolean(open)} onClick={onToggle}>
        <ArrowWrapper isOpen={Boolean(open)}>
          <DropdownButton />
        </ArrowWrapper>
      </ButtonContainer>
      {Boolean(open) && (
        <OptionsContainer>
          {options.map((option) => (
            <OptionItem key={option.value} onClick={() => onOptionClick(option)}>
              <Typography variant='caption' color={colors.primary[90]}>
                {option.label}
              </Typography>
            </OptionItem>
          ))}
        </OptionsContainer>
      )}
    </Container>
  );
};
