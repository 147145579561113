import { styled, useTheme } from '@mui/material';
import { HeaderWrapper } from '../Layout/Layout';
import { Typography } from '../Typography/Typography';
import { ReactNode } from 'react';

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

interface Props {
  title: string;
  description: string;
  children?: ReactNode;
}

export const DataCollectionHeader = ({ title, description, children }: Props) => {
  const { colors } = useTheme();

  return (
    <HeaderWrapper>
      <Wrapper>
        <TitleWrapper>
          <Typography variant='h3' color={colors.primary[90]}>
            {title}
          </Typography>
          <Typography variant='body' color={colors.primary[70]}>
            {description}
          </Typography>
        </TitleWrapper>
        {children}
      </Wrapper>
    </HeaderWrapper>
  );
};
