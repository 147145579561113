import { useParams } from 'react-router';
import { useCompanyById } from '../../../queries/useCompanies';
import { useState } from 'react';
import { Button, useTheme } from '@mui/material';
import { Task } from '../TaskGroup';
import { Typography } from '../../Typography/Typography';
import { EsgContactModal } from '../../EsgContactModal/EsgContactModal';

interface Props {
  hasAccess?: boolean;
  isBlocked?: boolean;
  isStepCompleted?: boolean;
}

export const ESGContactStep = ({ hasAccess, isBlocked, isStepCompleted }: Props) => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const [isEsgContactModalOpen, setIsEsgContactModalOpen] = useState(false);

  const { colors } = useTheme();

  const isDisabled = isBlocked || (!hasAccess && !isStepCompleted);

  return (
    <>
      <Task
        taskName={`Company’s ESG Contact`}
        isHighlighted={hasAccess && !isStepCompleted}
        disabled={isDisabled}
      >
        {company?.externalEsgContact?.email ? (
          <Typography variant='body' color={colors.primary[90]}>
            {company?.externalEsgContact?.email}
          </Typography>
        ) : (
          <Button
            onClick={() => setIsEsgContactModalOpen(true)}
            variant='contained'
            style={{ backgroundColor: isDisabled ? colors.accent[20] : colors.accent[50] }}
            disabled={isDisabled}
          >
            Assign ESG Contact
          </Button>
        )}
      </Task>

      <EsgContactModal
        onClose={() => setIsEsgContactModalOpen(false)}
        company={company}
        isOpen={isEsgContactModalOpen}
      />
    </>
  );
};
