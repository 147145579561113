import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
  closestCenter,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { styled } from '@mui/material';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import {
  activeSectionIdState,
  isApplyingEvaluationRulesModeActiveState,
  sectionState,
} from '../../../../state/UIState';
import { SurveyQuestion } from '../../../../types';
import { Question } from './Question';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SortableWrapper = styled('div')``;

const SortableItem = ({
  question,
  isDraggable,
  order,
}: {
  question: SurveyQuestion;
  isDraggable: boolean;
  order: number;
}) => {
  const { setNodeRef, transform, transition, attributes, listeners, isDragging } =
    useSortable(question);
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  return (
    <SortableWrapper
      ref={setNodeRef}
      style={{ ...style, opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 2 : 1 }}
    >
      <Question
        question={question}
        isDraggable={isDraggable}
        isDragging={isDragging}
        order={order}
        attributes={attributes}
        listeners={listeners}
      />
    </SortableWrapper>
  );
};

export const QuestionList = () => {
  const [activeSectionId] = useAtom(activeSectionIdState);
  const [activeSection, setActiveSection] = useAtom(sectionState(activeSectionId));
  const [isApplyingModeActive] = useAtom(isApplyingEvaluationRulesModeActiveState);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (activeSection?.questions && over?.id && active.id !== over.id) {
        const oldIndex = activeSection?.questions.findIndex((s) => s.id === active.id);
        const newIndex = activeSection?.questions.findIndex((s) => s.id === over.id);
        const reorderedQuestions = arrayMove(activeSection?.questions, oldIndex, newIndex);
        const updatedSection = {
          ...activeSection,
          questions: reorderedQuestions,
        };
        setActiveSection(updatedSection);
      }
    },
    [activeSection, setActiveSection]
  );

  return (
    <Wrapper>
      <DndContext sensors={sensors} onDragEnd={handleReorder} collisionDetection={closestCenter}>
        <SortableContext
          items={activeSection?.questions ?? []}
          strategy={verticalListSortingStrategy}
          disabled={isApplyingModeActive}
        >
          {activeSection?.questions.map((question, i) => (
            <SortableItem
              key={question.id}
              question={question}
              isDraggable={activeSection?.questions.length > 1}
              order={i + 1}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};
