import { FC, useCallback } from 'react';
import { useUpdateSubQuestion } from '../hooks/useUpdateSubquestion';
import { SurveyQuestion, QuestionOption } from '../../../../../../../../../../types';
import { InstructionsControls } from '../../../../../../components/InstructionControls';

interface Props {
  question: SurveyQuestion;
  option: QuestionOption;
}

export const SubQuestionInstructionsControls: FC<Props> = ({ question, option }) => {
  const { updateSubQuestion } = useUpdateSubQuestion();

  const toggleInstructions = useCallback(() => {
    const updatedSubQuestion = {
      ...question,
      includeInstructions: !question.includeInstructions,
    };
    updateSubQuestion(updatedSubQuestion, option);
  }, [option, question, updateSubQuestion]);

  const onInstructionsChange = useCallback(
    (value: string) => {
      const updatedSubQuestion = {
        ...question,
        instructions: value,
      };
      updateSubQuestion(updatedSubQuestion, option);
    },
    [option, question, updateSubQuestion]
  );

  return (
    <InstructionsControls
      question={question}
      onInstructionsChange={onInstructionsChange}
      toggleInstructions={toggleInstructions}
    />
  );
};
