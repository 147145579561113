import { Button, styled, useTheme } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left-blue.svg';
import { Typography } from '../../../components/Typography/Typography';
import { Company } from '../../../types';

interface Props {
  company: Company;
  surveyName: string;
}

const HeaderWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 20px 40px 36px 40px;
`;

const BreadcrumbsWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 12px;
  margin-bottom: 27px;
`;

const Img = styled('img')`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
`;

export const SingleAssessmentHeader: FC<Props> = ({ company, surveyName }) => {
  const navigate = useNavigate();
  const { colors } = useTheme();

  return (
    <HeaderWrapper>
      <BreadcrumbsWrapper>
        <Button
          onClick={() => navigate(-1)}
          variant='text'
          startIcon={<ArrowLeftIcon />}
          style={{ minWidth: 'auto' }}
        >
          <Typography variant='subtitle1' color={colors.accent[50]}>
            Company Profile
          </Typography>
        </Button>
      </BreadcrumbsWrapper>
      <TitleWrapper>
        {company.logoUrl && <Img src={company.logoUrl} />}
        <Typography variant='h1' color={colors.primary[90]}>
          {company.name}
        </Typography>
      </TitleWrapper>
      <Typography variant='h4' color={colors.primary[80]}>
        {surveyName}
      </Typography>
    </HeaderWrapper>
  );
};
