import { Button, styled } from '@mui/material';
import { ROUTES } from '../../constants/routes';
import { PageTabs } from '../../components/PageTabs';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { activeWorkflowCompanyType } from '../../state/UIState';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye-blue.svg';
import { getCompanyTypeBasedOnPath } from '../../utils/getCompanyTypeBasedOnPath';

const TabsWrapper = styled('div')`
  position: relative;
`;

const ButtonWrapper = styled('div')`
  position: absolute;
  right: 24px;
  bottom: 15px;
`;

const tabs = [
  { label: 'Control', path: ROUTES.CONTROL_WORKFLOW },
  { label: 'Not Control', path: ROUTES.NOT_CONTROL_WORKFLOW },
];

export const WorkflowTabs = () => {
  const params = useParams();
  const [, setActiveCompanyType] = useAtom(activeWorkflowCompanyType);
  const navigate = useNavigate();

  const [value, setValue] = useState(params.activeTab || ROUTES.CONTROL_WORKFLOW);

  useEffect(() => {
    setActiveCompanyType(getCompanyTypeBasedOnPath(value));

    return () => {
      setActiveCompanyType(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setActiveTab = (val: string) => {
    setValue(val);
    setActiveCompanyType(getCompanyTypeBasedOnPath(val));
  };

  return (
    <TabsWrapper>
      <PageTabs tabValue={value} setTabValue={setActiveTab} tabs={tabs} />
      <ButtonWrapper>
        <Button
          onClick={() => navigate(`/${ROUTES.WORKFLOW_PREVIEW}/${value}`)}
          startIcon={<EyeIcon />}
          variant='outlined'
          style={{ width: '123px' }}
        >
          Preview
        </Button>
      </ButtonWrapper>
    </TabsWrapper>
  );
};
