import { useParams } from 'react-router';
import { useCompanyById } from '../../queries/useCompanies';
import { TaskGroup } from './TaskGroup';
import { COMPANY_TYPE, STEP_TYPE, WorkflowStage } from '../../types';
import { AssessmentStep } from './AssessmentStep/AssessmentStep';
import { styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { CompanyInfoStep } from './CompanyInfoStep/CompanyInfoStep';
import { RequestStep } from './RequestStep/RequestStep';
import { useIsTaskBlocked } from './hooks/useIsTaskBlocked';
import { useHasAccessToTask } from '../../hooks/useHasAccessToTask';
import { useHasAccessToEvaluate } from './hooks/useHasAccessToEvaluate';
import { NoSteps } from './NoSteps';
import { useMemo } from 'react';

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 90px;
`;

const Container = styled('div')`
  width: 742px;
`;

interface Props {
  stages: WorkflowStage[];
  companyType: COMPANY_TYPE;
}

export const ScreeningFlow = ({ stages, companyType }: Props) => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const { getIsBlocked } = useIsTaskBlocked();
  const { getHasAccess } = useHasAccessToTask();
  const { getHasEvaluationAccess } = useHasAccessToEvaluate();
  const { colors } = useTheme();

  const governanceScore = useMemo(() => {
    return company?.governanceScore;
  }, [company?.governanceScore]);

  if (!stages?.length) {
    return (
      <Wrapper>
        <Container>
          <NoSteps companyType={companyType} />
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Typography variant='h4' color={colors.primary[90]}>
          Company Screening
        </Typography>
        <Typography variant='subtitle1' color={colors.primary[70]} style={{ marginBottom: 24 }}>
          To add the company to a portfolio, please complete all screening steps.
        </Typography>
        {stages?.map((stage) => {
          return (
            <TaskGroup key={stage.id} groupName={stage.name}>
              {stage?.steps?.map((step) => {
                const isBlocked = getIsBlocked(step);
                const hasAccess = getHasAccess(step);
                const hasEvaluationAccess = getHasEvaluationAccess(step);
                const isStepCompleted = company?.completedWorkflowSteps?.includes(step.id);

                if (step.type === STEP_TYPE.ASSESSMENT || step?.type === STEP_TYPE.SCORE) {
                  return (
                    <AssessmentStep
                      key={step.id}
                      step={step}
                      isBlocked={isBlocked}
                      hasAccess={hasAccess}
                      hasEvaluationAccess={hasEvaluationAccess}
                      isStepCompleted={isStepCompleted}
                      governanceScore={step?.type === STEP_TYPE.SCORE ? governanceScore : null}
                    />
                  );
                }

                if (step.type === STEP_TYPE.COMPANY_INFO) {
                  return (
                    <CompanyInfoStep
                      key={step.id}
                      step={step}
                      hasAccess={hasAccess}
                      isBlocked={isBlocked}
                      hasEvaluationAccess={hasEvaluationAccess}
                      isStepCompleted={isStepCompleted}
                    />
                  );
                }

                if (step.type === STEP_TYPE.REQUEST) {
                  return (
                    <RequestStep
                      key={step.id}
                      step={step}
                      hasAccess={hasAccess}
                      isBlocked={isBlocked}
                      isStepCompleted={isStepCompleted}
                    />
                  );
                }

                return <></>;
              })}
            </TaskGroup>
          );
        })}
      </Container>
    </Wrapper>
  );
};
