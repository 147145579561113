import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import {
  QUESTION_TYPE,
  QuestionOption,
  QuestionReview,
  REVIEW_TYPE,
  SurveyQuestion,
  TReviewOptionConfig,
} from '../../../../../../types';
import { FieldsContainer } from '../components/FieldsContainer';
import { ReasonField } from '../../../../../../components/ReasonField';
import { OverrideAnswerField } from './OverrideAnswerField/OverrideAnswerField';
import { useAtom } from 'jotai';
import { reviewState } from '../../../../../../state/UIState';
import { ReviewOption } from '../../../../../../components/ReviewOption';
import { useParams } from 'react-router';
import { useReportById } from '../../../../../../queries/useReports';
import { OverrideEvaluation } from './OverrideEvaluation';
import { Typography } from '../../../../../../components/Typography/Typography';
import { useTheme } from '@mui/material';

interface Props {
  reviewOption: TReviewOptionConfig;
  question: SurveyQuestion;
  selectedOption: REVIEW_TYPE | null;
  setSelectedOption: Dispatch<SetStateAction<REVIEW_TYPE | null>>;
  isEvaluated: boolean;
}

export const OverrideOption = ({
  reviewOption,
  selectedOption,
  setSelectedOption,
  isEvaluated,
  question,
}: Props) => {
  const [review, setReview] = useAtom(reviewState);
  const [reasonValue, setReasonValue] = useState('');
  const { colors } = useTheme();
  const params = useParams();

  const { data: report } = useReportById(Number(params?.reportId));

  const isSelected = useMemo(() => {
    return reviewOption?.type === selectedOption;
  }, [reviewOption?.type, selectedOption]);

  const onToggleSelected = () => {
    if (isSelected) {
      setSelectedOption(null);
      const newReview = { ...review };
      delete newReview[question.id];
      setReview(newReview);
      return;
    }

    setSelectedOption(reviewOption.type);
  };

  const isReasonDisabled = useMemo(() => {
    if (question?.type === QUESTION_TYPE.SINGLE) {
      return !review?.[question.id]?.overrideResponse;
    }

    return !(review?.[question.id]?.overrideResponse as QuestionOption[])?.length;
  }, [question.id, question?.type, review]);

  const onReasonUpdate = useCallback(() => {
    setReview((prevReview) => {
      return {
        ...prevReview,
        [question.id]: {
          ...(prevReview?.[question.id] || {}),
          reason: reasonValue,
        } as QuestionReview,
      };
    });
  }, [question.id, reasonValue, setReview]);

  if (report?.survey?.includeEvaluation) {
    return (
      <ReviewOption
        isSelected={isSelected}
        isEvaluated={isEvaluated}
        onSelect={onToggleSelected}
        optionColor={reviewOption.color}
        label={reviewOption.name}
        isDisabled={!question.includeEvaluation}
      >
        {isSelected && (
          <FieldsContainer onClick={(e) => e.stopPropagation()}>
            <OverrideEvaluation question={question} reviewOption={reviewOption} />
          </FieldsContainer>
        )}
        {!question.includeEvaluation && (
          <Typography variant='srOnly' color={colors.primary[90]} style={{ marginTop: '10px' }}>
            Evaluation is not included for this question
          </Typography>
        )}
      </ReviewOption>
    );
  }

  return (
    <ReviewOption
      isSelected={isSelected}
      isEvaluated={isEvaluated}
      onSelect={onToggleSelected}
      optionColor={reviewOption.color}
      label={reviewOption.name}
    >
      {isSelected && (
        <FieldsContainer onClick={(e) => e.stopPropagation()}>
          <OverrideAnswerField question={question} reviewOption={reviewOption} />
          <ReasonField
            isDisabled={isReasonDisabled}
            value={reasonValue}
            setValue={setReasonValue}
            onBlur={onReasonUpdate}
          />
        </FieldsContainer>
      )}
    </ReviewOption>
  );
};
