import { IconButton, styled } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';
import { CardTitle } from './CardTitle';
import { ReactNode } from 'react';

const HeaderWrapper = styled('div')`
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  onExpandToggle: () => void;
  isExpanded?: boolean;
  title: string;
  chipLabel: string;
  controls?: ReactNode;
  isActive?: boolean;
  setTitleValue?: (value: string) => void;
  onTitleValueChanged?: (newValue: string) => void;
}

export const CardHeader = ({
  onExpandToggle,
  isExpanded,
  title,
  chipLabel,
  controls,
  isActive,
  setTitleValue,
  onTitleValueChanged,
}: Props) => {
  return (
    <HeaderWrapper>
      <TitleWrapper>
        <IconButton
          onClick={onExpandToggle}
          style={{ width: '30px', height: '30px', marginTop: '-2px' }}
        >
          {isExpanded ? <ArrowDownIcon /> : <ArrowRightIcon />}
        </IconButton>
        <CardTitle
          title={title}
          chipLabel={chipLabel}
          isActive={isActive}
          setTitleValue={setTitleValue}
          onTitleValueChanged={onTitleValueChanged}
        />
      </TitleWrapper>

      {controls}
    </HeaderWrapper>
  );
};
