import { FormField } from '../../../../../../../../../components/FormField/FormField';
import { ChecklistItemSelect } from './ChecklistItemSelect';
import { ConditionValueOptions } from './ConditionValueOptions';

interface Props {
  checklistItem?: number | null;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  conditionValue: string;
}

export const ChecklistGoalValue = ({ checklistItem, setFieldValue, conditionValue }: Props) => {
  return (
    <>
      <FormField label='Item' error={''} required>
        <ChecklistItemSelect checklistItem={checklistItem} setFieldValue={setFieldValue} />
      </FormField>
      <FormField label='Goal Value' error={''} required>
        <ConditionValueOptions conditionValue={conditionValue} setFieldValue={setFieldValue} />
      </FormField>
    </>
  );
};
