import { CellContext } from '@tanstack/react-table';

import { useCallback } from 'react';
import { KPISValueResponse, QuestionTableData, SurveyQuestion } from '../../../../../../../types';
import { responsesState } from '../../../../../../../state/UIState';
import { useAtom } from 'jotai';

import { CoreKpiValueCell } from './CoreKpiValueCell/CoreKpiValueCell';

interface Props {
  info: CellContext<Record<string, any>, any>;
  column: string;
  question: SurveyQuestion;
}

export const CoreKpiEditableCell = ({ info, question, column }: Props) => {
  const [, setResponses] = useAtom(responsesState);

  const onValueChanged = useCallback(
    (val: string, rowId: string | number, columnId: string) => {
      setResponses((responses) => ({
        ...responses,
        [question.id]: {
          ...(responses[question.id] as QuestionTableData),
          rows: (responses[question.id] as QuestionTableData).rows.map((row) => {
            if (row.id === rowId) {
              return {
                ...row,
                [columnId]: {
                  value: val,
                  notes: val ? (row?.[columnId] as KPISValueResponse)?.notes || '' : '',
                },
              };
            }
            return row;
          }),
        },
      }));
    },
    [question.id, setResponses]
  );
  const onNotesChanged = useCallback(
    (notes: string, rowId: string | number, columnId: string) => {
      setResponses((responses) => ({
        ...responses,
        [question.id]: {
          ...(responses[question.id] as QuestionTableData),
          rows: (responses[question.id] as QuestionTableData).rows.map((row) => {
            if (row.id === rowId) {
              return {
                ...row,
                [columnId]: {
                  value: (row?.[columnId] as KPISValueResponse).value,
                  notes,
                },
              };
            }
            return row;
          }),
        },
      }));
    },
    [question.id, setResponses]
  );

  const onNotesChange = (notes: string) => {
    onNotesChanged(notes, info.row.original.id, column);
  };

  const onValueChange = (value: string) => {
    onValueChanged(value, info.row.original.id, column);
  };

  const onDeleteNote = () => {
    onNotesChanged('', info.row.original.id, column);
  };

  return (
    <CoreKpiValueCell
      value={info.getValue()?.value}
      notes={info.getValue()?.notes}
      onNotesChange={onNotesChange}
      onValueChange={onValueChange}
      onDeleteNote={onDeleteNote}
      format={info.row.original.kpi_format_column}
    />
  );
};
