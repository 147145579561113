import { styled } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Outlet, useNavigate, useParams } from 'react-router';
import { SurveyEditorSkeletonLoader } from '../../components/SkeletonLoader/SurveyEditor.SkeletonLoader';
import { DEFAULT_SURVEY_TITLE, getDefaultSurveySection } from '../../constants/defaultValues';
import { useDebouncedValue } from '../../hooks/useDebouncedValue';
import {
  fetchSurveyById,
  SURVEYS,
  useCreateSurvey,
  useUpdateSurvey,
} from '../../queries/useSurveys';
import { useTemplateSections } from '../../queries/useTemplates';
import {
  activeQuestionIdState,
  activeSectionIdState,
  activeSurveyIdState,
  activeSurveyState,
  areShowedAllQuestionsState,
  evaluationRulesState,
  evaluationScaleState,
  includeEvaluationState,
  includeQuestionNumbersState,
  includeWeightState,
  isNameModalOpenState,
  responsesState,
  sectionsState,
  survayNameState,
  surveyTemplatesDataState,
} from '../../state/UIState';
import { EVALUATION_SCALE, EvaluationRule, SURVEY_TYPE, SurveyTemplateSection } from '../../types';
import { ProgressHeader } from './components/ProgressHeader';
import { EditSurveyModal } from './components/EditSurveyModal';
import { ROUTES } from '../../constants/routes';
import { getEvaluationRules } from '../../utils/getEvaluationRules';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = styled('div')``;

export const SurveyEditor = () => {
  const [isSurveyLoading, setSurveyIsLoading] = useState(true);
  const [surveyName, setSurveyName] = useAtom(survayNameState);
  const [open, setOpen] = useAtom(isNameModalOpenState);
  const [activeSurveyId, setActiveSurveyId] = useAtom(activeSurveyIdState);
  const [, setActiveSurvey] = useAtom(activeSurveyState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [evaluationRules, setEvaluationRules] = useAtom(evaluationRulesState);
  const [sections, setSections] = useAtom(sectionsState);
  const setResponses = useSetAtom(responsesState);
  const setAreShowedAllQuestions = useSetAtom(areShowedAllQuestionsState);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const createSurvey = useCreateSurvey();
  const updateSurvey = useUpdateSurvey();
  const queryClient = useQueryClient();
  const params = useParams();
  const { isLoading: areTemplateSectionsLoading, data: templateSections } = useTemplateSections();
  const isLoading = isSurveyLoading || areTemplateSectionsLoading;
  const [, setSurveyTemplatesData] = useAtom(surveyTemplatesDataState);
  const navigate = useNavigate();

  const [evaluationScale, setEvaluationScale] = useAtom(evaluationScaleState);
  const [includeEvaluation, setIncludeEvaluation] = useAtom(includeEvaluationState);
  const [includeWeight, setIncludeWeight] = useAtom(includeWeightState);
  const [includeQuestionNumbers, setIncludeQuestionNumbers] = useAtom(includeQuestionNumbersState);

  const debouncedSections = useDebouncedValue(sections, 500);

  const handleConfirm = useCallback(
    async (newName: string, description: string) => {
      handleClose();
      setSurveyName(newName);
      if (!activeSurveyId) {
        await createSurvey.mutateAsync(
          {
            name: newName,
            sections,
            description,
            evaluationRules: evaluationRules,
            type: SURVEY_TYPE.USER,
            includeEvaluation,
            evaluationScale,
            includeWeight,
            includeQuestionNumbers,
          },
          {
            onSuccess: (newSuvey) => {
              setActiveSurveyId(newSuvey.id);
              setActiveSurvey(newSuvey);
              navigate(`/${ROUTES.SURVEYS}/${newSuvey.id}/build`);
            },
          }
        );
      } else {
        await updateSurvey.mutateAsync(
          {
            id: activeSurveyId,
            name: newName,
            description,
            evaluationRules,
            evaluationScale,
            includeEvaluation,
            includeWeight,
            includeQuestionNumbers,
          },
          {
            onSuccess: (newSuvey) => {
              setActiveSurveyId(newSuvey.id);
              setActiveSurvey(newSuvey);
            },
          }
        );
      }
      queryClient.refetchQueries(SURVEYS);
    },
    [
      activeSurveyId,
      createSurvey,
      evaluationRules,
      evaluationScale,
      handleClose,
      includeEvaluation,
      includeQuestionNumbers,
      includeWeight,
      navigate,
      queryClient,
      sections,
      setActiveSurvey,
      setActiveSurveyId,
      setSurveyName,
      updateSurvey,
    ]
  );

  useEffect(() => {
    !params.id && !activeSurveyId && setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const evaluationRulesBackwardsCompatibility = (rules: EvaluationRule[]) => {
    if (!rules?.every?.((rule) => Object.hasOwn(rule, 'color'))) {
      return getEvaluationRules(EVALUATION_SCALE.BINARY);
    }

    return rules;
  };

  useEffect(() => {
    async function fetchSurveyData() {
      let numberOfAttemps = 0;
      const maxNumberOfAttemps = 5;
      async function getFirstValid() {
        try {
          const survey = await fetchSurveyById(Number(params.id));
          setSurveyName(survey?.name ?? '');
          setSections(survey?.sections ?? []);
          setActiveSurveyId(survey?.id ?? null);
          setActiveSurvey(survey ?? null);
          setActiveSectionId(survey?.sections[0]?.id ?? null);
          setActiveQuestionId(survey?.sections[0]?.questions[0]?.id ?? null);
          setEvaluationRules(
            survey?.evaluationRules?.length
              ? evaluationRulesBackwardsCompatibility(survey?.evaluationRules)
              : getEvaluationRules(survey?.evaluationScale || EVALUATION_SCALE.BINARY)
          );
          setEvaluationScale(survey?.evaluationScale || EVALUATION_SCALE.BINARY);
          setIncludeEvaluation(survey?.includeEvaluation || false);
          setIncludeWeight(survey?.includeWeight || false);
          setIncludeQuestionNumbers(survey?.includeQuestionNumbers || false);
          setSurveyIsLoading(false);
        } catch (error) {
          console.log({ error });
          numberOfAttemps++;
          if (numberOfAttemps < maxNumberOfAttemps) {
            getFirstValid();
          }
        }
      }
      if (params.id) {
        getFirstValid();
      } else {
        setSurveyIsLoading(false);
        const defaultSection = getDefaultSurveySection();
        setSections([defaultSection]);
        setActiveSectionId(defaultSection.id);
        setActiveQuestionId(defaultSection.questions[0]?.id);
        setEvaluationRules(getEvaluationRules(EVALUATION_SCALE.BINARY));
        setSurveyName(DEFAULT_SURVEY_TITLE);
        setEvaluationScale(EVALUATION_SCALE.BINARY);
        setIncludeEvaluation(false);
        setIncludeWeight(false);
        setIncludeQuestionNumbers(true);
      }
    }
    fetchSurveyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (activeSurveyId) {
      updateSurvey.mutate({ id: activeSurveyId, sections: debouncedSections });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSections]);

  useEffect(() => {
    if (!templateSections) return;
    //create array of categories where each category has an array of sections
    const categories = templateSections?.reduce((acc, section) => {
      if (!section.category) {
        const uncategorized = acc.find((cat) => cat.name === 'Uncategorized');
        if (uncategorized) {
          uncategorized.sections.push(section);
        } else {
          acc.push({ name: 'Uncategorized', sections: [section] });
        }
        return acc;
      }
      const category = acc.find((cat) => cat.name === section.category?.name);
      if (category) {
        category.sections.push(section);
      } else {
        acc.push({ name: section.category?.name, sections: [section] });
      }
      return acc;
    }, [] as { name: string; sections: SurveyTemplateSection[] }[]);
    setSurveyTemplatesData(categories);
  }, [setSurveyTemplatesData, templateSections]);

  useEffect(() => {
    return () => {
      setActiveSurveyId(null);
      setActiveSectionId(null);
      setActiveQuestionId(null);
      setSections([]);
      setResponses({});
      setAreShowedAllQuestions(true);
      setSurveyName('');
      setActiveSurvey(null);
      setOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = useCallback(() => {
    if (activeSurveyId) {
      handleClose();
    } else {
      navigate(`/${ROUTES.SURVEYS}`);
    }
  }, [activeSurveyId, handleClose, navigate]);

  if (isLoading) return <SurveyEditorSkeletonLoader />;

  return (
    <Wrapper>
      <ProgressHeader />
      <Content>
        <Outlet />
      </Content>
      <EditSurveyModal
        onClose={onCancel}
        isOpen={open}
        title={!activeSurveyId ? 'Create Survey' : 'Edit Survey Settings'}
        surveyName={surveyName}
        onConfirm={handleConfirm}
        confirmText={!activeSurveyId ? 'Create Survey' : 'Save Changes'}
      />
    </Wrapper>
  );
};
