import { styled, useTheme } from '@mui/material';
import { SearchInput } from '../../SearchInput/SearchInput';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useCallback, useMemo, useState } from 'react';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { SortableItem } from './SortableItem';
import { ConfirmDeleteModal } from '../../ConfirmDeleteModal/ConfirmDeleteModal';
import { CategoryItem } from '../../../types';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  items: CategoryItem[];
  handleReorder: (e: DragEndEvent) => void;
  isMoveModeActive: boolean;
  activeCategory: CategoryItem | null;
  handleClick: (item: CategoryItem) => void;
  onRenameCategory: (itemId: number, newName: string) => void;
  selectedCategory: CategoryItem | null;
  onRemoveCategory: (itemId: number) => void;
  deleteModalTitle: string;
  deleteModalNote: string;
}

export const SideSectionList = ({
  items,
  handleReorder,
  isMoveModeActive,
  activeCategory,
  handleClick,
  onRenameCategory,
  selectedCategory,
  onRemoveCategory,
  deleteModalTitle,
  deleteModalNote,
}: Props) => {
  const { colors } = useTheme();

  const [filterValue, setFilterValue] = useState<string>('');
  const [selectedItemIdToDelete, setSelectedItemIdToDelete] = useState<number | null>(null);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const handleOpenConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(true), []);
  const handleCloseConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(false), []);

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = e.target;
      setFilterValue(value);
    },
    []
  );

  const filteredItems = useMemo(() => {
    if (!items) return [];
    if (!filterValue) return items;
    return items.filter((item) => item.name.toLowerCase().includes(filterValue.toLowerCase()));
  }, [items, filterValue]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  function handleOnDeleteClick(id: UniqueIdentifier) {
    setSelectedItemIdToDelete(id as number);
    handleOpenConfirmDeleteModal();
  }

  function handleRemove() {
    onRemoveCategory(selectedItemIdToDelete as number);
    handleCloseConfirmDeleteModal();
  }

  return (
    <Wrapper>
      <SearchInput
        placeholder='Search for a category'
        onChange={onFilterChange}
        value={filterValue}
        style={{ position: 'sticky', top: '66px', zIndex: 10, backgroundColor: colors.primary[5] }}
        onClear={() => setFilterValue('')}
      />
      <DndContext
        sensors={sensors}
        onDragEnd={handleReorder}
        collisionDetection={closestCenter}
        modifiers={[restrictToFirstScrollableAncestor]}
      >
        <SortableContext
          items={items ?? []}
          strategy={verticalListSortingStrategy}
          disabled={!!filterValue || isMoveModeActive}
        >
          {filteredItems.map((item) => (
            <SortableItem
              isActive={activeCategory?.id === item.id}
              key={item.id}
              id={item.id}
              name={item.name}
              value={item.id as number}
              onDeleteCategory={() => handleOnDeleteClick(item.id)}
              onClick={() => handleClick(item)}
              onCategoryRename={(newName) => onRenameCategory(item.id, newName)}
              isDraggable={!filterValue}
              isNew={item.isNew}
              isSelectable={isMoveModeActive}
              isSelected={selectedCategory?.id === item.id}
              isDisabled={activeCategory?.id === item.id}
            />
          ))}
        </SortableContext>
      </DndContext>

      <ConfirmDeleteModal
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleRemove}
        title={deleteModalTitle}
        note={deleteModalNote}
        confirmLabel='Yes, Delete'
        cancelLabel='No, Cancel'
        isOpen={isConfirmDeleteModalOpen}
      />
    </Wrapper>
  );
};
