import { QUESTION_TYPE, QuestionOption, Response, SurveySection } from '../types';

export const getQuestionsWithSubQuestions = (
  section: SurveySection,
  responses: Record<string, Response>
) => {
  return section.questions
    .map((question) => {
      if (question.type === QUESTION_TYPE.SINGLE) {
        const selectedOption = question.options.find(
          (o) => (responses[question.id] as QuestionOption)?.id === o.id
        );

        const subQuestions = selectedOption?.subQuestions || [];

        return [question, ...subQuestions];
      }

      return question;
    })
    .flatMap((question) => question);
};
