import { forwardRef, HTMLAttributes, MouseEvent, useCallback, useState } from 'react';

import { styled, Fade, IconButton } from '@mui/material';
import { EditableTextFieldV2 } from '../../EditableTextField/EditableTextFieldV2';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-gray.svg';
import { ReactComponent as DragIcon } from '../../../assets/icons/drag.svg';
import { Radio } from '../../Radio/Radio';
import { Tooltip } from '../../Tooltip/Tooltip';

const Wrapper = styled('li')`
  list-style: none;
  box-sizing: border-box;
  margin-bottom: -1px;
`;

const ItemWrapper = styled('div')<{ isActive: boolean }>`
  ${({ isActive, theme }) => isActive && `background-color: ${theme.colors.accent[5]};`}
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 0 12px;
  &:hover {
    .controls {
      opacity: 1;
    }
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.accent[5] : theme.colors.gray[20]};
  }
  cursor: pointer;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-grow: 1;
`;

const ControlsWrapper = styled('div')`
  display: flex;
`;
const HoverControlsWrapper = styled('div')`
  opacity: 0;
  display: flex;
`;

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  name: string;
  value: number;
  isActive: boolean;
  onCategoryRename: (newName: string) => void;
  onClickCategory?: () => void;
  onDeleteCategory: () => void;
  isDraggable?: boolean;
  wrapperRef?(node: HTMLLIElement): void;
  isNew?: boolean;
  isSelectable: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
}

const Item = forwardRef<HTMLDivElement, Props>(
  (
    {
      style,
      name,
      wrapperRef,
      onCategoryRename,
      onDeleteCategory,
      onClickCategory,
      isActive,
      isDraggable,
      isNew,
      isSelectable,
      isSelected,
      isDisabled = false,
      ...props
    },
    ref
  ) => {
    const [sectionTitle, setSectionTitle] = useState(name);
    const onRename = useCallback(
      (value: string) => {
        if (value === name || !value) {
          setSectionTitle(name);
          return;
        }
        onCategoryRename(value);
      },
      [name, onCategoryRename]
    );

    const onDeleteSectionClick = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onDeleteCategory();
      },
      [onDeleteCategory]
    );

    return (
      <Wrapper ref={wrapperRef} {...props}>
        <ItemWrapper ref={ref} style={style} isActive={isActive} onClick={onClickCategory}>
          <TitleWrapper>
            {isSelectable && (
              <Radio
                checked={isSelected}
                style={{ visibility: isDisabled ? 'hidden' : 'visible' }}
              />
            )}
            {!isSelectable && (
              <DragIcon style={{ visibility: isDraggable ? 'visible' : 'hidden' }} />
            )}
            <EditableTextFieldV2
              value={sectionTitle}
              onChange={(e) => setSectionTitle(e.target.value)}
              onValueChanged={onRename}
              placeholder='Enter section name'
              focusOnMount={isNew}
            />
          </TitleWrapper>

          <ControlsWrapper>
            <HoverControlsWrapper className='controls'>
              <Tooltip
                title='Delete This Section'
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [65, -13],
                      },
                    },
                  ],
                }}
              >
                <IconButton onClick={onDeleteSectionClick}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </HoverControlsWrapper>
          </ControlsWrapper>
        </ItemWrapper>
      </Wrapper>
    );
  }
);

Item.displayName = 'Item';
export { Item };
