import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import {
  isConfirmChecklistBarActiveState,
  isMoveChecklistModeActiveState,
  selectedChecklistCategoryState,
  selectedChecklistItemState,
} from '../../../state/UIState';
import { useAppConfig } from '../../../queries/useAppConfig';

export const useOnCancelConfirm = () => {
  const setIsActive = useSetAtom(isConfirmChecklistBarActiveState);
  const setIsMoveModeActive = useSetAtom(isMoveChecklistModeActiveState);
  const setSelectedCategory = useSetAtom(selectedChecklistCategoryState);
  const setSelectedItem = useSetAtom(selectedChecklistItemState);

  const { data: appConfigData } = useAppConfig();

  const onCancel = useCallback(() => {
    appConfigData?.data.areCheckListSurveysUpToDate && setIsActive(false);
    setSelectedCategory(null);
    setSelectedItem(null);
    setIsMoveModeActive(false);
  }, [
    appConfigData?.data.areCheckListSurveysUpToDate,
    setIsActive,
    setIsMoveModeActive,
    setSelectedCategory,
    setSelectedItem,
  ]);

  return { onCancel };
};
