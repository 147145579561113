import { styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { ChipsSelector } from '../../../components/ButtonSelector/ChipsSelector';
import { Typography } from '../../../components/Typography/Typography';
import {
  createRequestCompletedProgressState,
  requestNameState,
  selectedFrequencyState,
} from '../../../state/UIState';
import { CREATE_REQUEST_PARAMS, SelectItem, SURVEY_FREQUENCY, SURVEY_STAGE } from '../../../types';
import { useQueryParams } from '../../../hooks/useQueryParams';

const Wrapper = styled('div')``;

export const FrequencyStep = () => {
  const { colors } = useTheme();
  const [, setProgress] = useAtom(createRequestCompletedProgressState);

  const { params, setMultipleParams } = useQueryParams();
  const isInitalStageMode = params[CREATE_REQUEST_PARAMS.MODE] === 'initial';
  const surveyStage = params[CREATE_REQUEST_PARAMS.SURVEY_STAGE];
  const surveyName = params[CREATE_REQUEST_PARAMS.SURVEY_NAME];
  const frequencyFromParams = params[CREATE_REQUEST_PARAMS.FREQUENCY];

  const options: SelectItem[] = useMemo(() => {
    const oneTimeOption = {
      id: SURVEY_FREQUENCY.ONE_TIME,
      value: 'One-time',
    };
    const defaultOptions = [
      {
        id: SURVEY_FREQUENCY.MONTHLY,
        value: 'Monthly',
      },
      {
        id: SURVEY_FREQUENCY.QUARTERLY,
        value: 'Quarterly',
      },
      {
        id: SURVEY_FREQUENCY.ANNUALLY,
        value: 'Annually',
      },
    ];
    if (surveyStage !== SURVEY_STAGE.CORE_KPI) {
      return [oneTimeOption, ...defaultOptions];
    }
    return defaultOptions;
  }, [surveyStage]);

  const [selectedFrequency, setSelectedFrequency] = useAtom(selectedFrequencyState);
  const [, setRequestName] = useAtom(requestNameState);

  useEffect(() => {
    if (!selectedFrequency) return;

    const requestName = `${surveyName} ${selectedFrequency.value}`;

    setMultipleParams([
      {
        param: CREATE_REQUEST_PARAMS.FREQUENCY,
        value: selectedFrequency.id,
      },
      { param: CREATE_REQUEST_PARAMS.REQUEST_NAME, value: requestName },
    ]);
    setProgress((prev) => (prev > 33 ? prev : 66));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedFrequency) {
      const selectedFrequencyFromParams = options.find((o) => o.id === frequencyFromParams);
      const requestName = `${surveyName} ${
        selectedFrequencyFromParams?.value || options[0]?.value
      }`;
      setMultipleParams([
        {
          param: CREATE_REQUEST_PARAMS.FREQUENCY,
          value: selectedFrequencyFromParams?.id || String(options[0].id),
        },
        { param: CREATE_REQUEST_PARAMS.REQUEST_NAME, value: requestName },
      ]);
      setProgress((prev) => (prev > 33 ? prev : 66));
      setSelectedFrequency(selectedFrequencyFromParams || options[0]);
      setRequestName(requestName);
    }
    if (!!selectedFrequency && isInitalStageMode) {
      setProgress(100);
      return;
    }
  }, [
    frequencyFromParams,
    isInitalStageMode,
    options,
    selectedFrequency,
    setMultipleParams,
    setProgress,
    setRequestName,
    setSelectedFrequency,
    surveyName,
  ]);

  const onSelectFrequency = useCallback(
    (item: SelectItem) => {
      if (selectedFrequency?.id === item.id) return;
      setProgress((prev) => (prev > 33 ? prev : 66));
      const requestName = `${surveyName} ${item.value}`;
      setMultipleParams([
        { param: CREATE_REQUEST_PARAMS.FREQUENCY, value: String(item.id) },
        { param: CREATE_REQUEST_PARAMS.REQUEST_NAME, value: requestName },
      ]);
      setSelectedFrequency(item);
      setRequestName(requestName);
    },
    [
      selectedFrequency?.id,
      setProgress,
      surveyName,
      setMultipleParams,
      setSelectedFrequency,
      setRequestName,
    ]
  );

  return (
    <Wrapper>
      <Typography variant='h2' color={colors.primary[90]} style={{ marginBottom: '8px' }}>
        How often do you need the report?
      </Typography>
      <Typography variant='body' color={colors.primary[70]}>
        Companies will receive the request on a regular basis.
      </Typography>
      <Typography variant='body' color={colors.primary[70]} style={{ marginBottom: '32px' }}>
        Stay updated with the latest changes and progress.
      </Typography>
      <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '8px' }}>
        Frequency
      </Typography>
      <ChipsSelector
        options={options}
        onSelect={onSelectFrequency}
        selectedItem={selectedFrequency}
      />
    </Wrapper>
  );
};
