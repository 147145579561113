import { Divider, styled, useTheme } from '@mui/material';
import { QUESTION_TYPE, SurveyQuestion, QuestionOption } from '../../../../../../../../../types';
import { SubQuestionTitle } from './components/SubQuestionTitle';
import { SubQuestionTypeSelector } from './components/SubQuestionTypeSelector';
import { SubQuestionOptionsList } from './components/SubQuestionOptionsList';
import { SubQuestionInstructionsControls } from './components/SubQuestionInstructionsControls';
import { useAtom } from 'jotai';
import {
  activeSubQuestionState,
  activeSurveyState,
} from '../../../../../../../../../state/UIState';
import { SubQuestionEvaluationControls } from './components/SubQuestionEvaluationControls';

import { useMemo } from 'react';
import { SubQuestionActionButtons } from './components/SubQuestionActionButtons';

const Container = styled('div')<{ isActive: boolean }>`
  background: ${({ isActive, theme }) => (isActive ? theme.colors.gray[10] : 'transparent')};
  margin-bottom: 16px;
`;

const Wrapper = styled('div')`
  padding: 16px 24px 0px 24px;
`;

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
`;

interface Props {
  subQuestion: SurveyQuestion;
  order: number;
  questionNum: number;
  option: QuestionOption;
}

export const SubQuestion = ({ subQuestion, order, questionNum, option }: Props) => {
  const [activeSubquestion, setActiveSubquestion] = useAtom(activeSubQuestionState);
  const [activeSurvey] = useAtom(activeSurveyState);

  const { colors } = useTheme();

  const isActive = useMemo(
    () => activeSubquestion?.id === subQuestion?.id,
    [activeSubquestion?.id, subQuestion?.id]
  );

  return (
    <Container onClick={() => setActiveSubquestion(subQuestion)} isActive={isActive}>
      <TitleContainer>
        <SubQuestionTitle
          questionNum={questionNum}
          order={order}
          subQuestion={subQuestion}
          option={option}
        />
        {isActive && <SubQuestionActionButtons subQuestion={subQuestion} option={option} />}
      </TitleContainer>
      <Wrapper>
        <SubQuestionTypeSelector subQuestion={subQuestion} option={option} />
        {(subQuestion?.type === QUESTION_TYPE.SINGLE ||
          subQuestion?.type === QUESTION_TYPE.MULTI) && (
          <SubQuestionOptionsList subQuestion={subQuestion} option={option} />
        )}
        <Divider style={{ marginBottom: '7px', borderColor: colors.primary[20] }} />
        {activeSurvey?.includeEvaluation && (
          <SubQuestionEvaluationControls question={subQuestion} option={option} />
        )}
        {activeSurvey?.includeEvaluation && (
          <Divider style={{ marginBottom: '7px', borderColor: colors.primary[20] }} />
        )}
        <SubQuestionInstructionsControls question={subQuestion} option={option} />
      </Wrapper>
    </Container>
  );
};
