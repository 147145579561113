import { useNavigate, useParams } from 'react-router';
import { useCompanyByExternalId } from '../../queries/useCompanies';
import { CompanyPageSkeletonLoader } from '../../components/SkeletonLoader/CompanyPage.SkeletonLoader';
import { useEffect } from 'react';
import { ROUTES } from '../../constants/routes';
import { styled } from '@mui/material';

const ErrorTextContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const SingleCompanyRedirect = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data: company, isError } = useCompanyByExternalId(params.externalId);

  useEffect(() => {
    if (!company ) return;
    navigate(`/${ROUTES.COMPANIES}/${company.id}/${ROUTES.COMPANY_OVERVIEW}`);
  }, [company, navigate]);

  if (isError) {
    return <ErrorTextContainer>Company not found. Please use &apos;Sync Issuers&apos; in Companies section.</ErrorTextContainer>;
  }

  return <CompanyPageSkeletonLoader />;
};
