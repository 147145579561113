import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import { COMPANY_TYPE, LoadingId, Workflow } from '../types';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { useMemo } from 'react';

export const WORKFLOWS = 'workflows';

async function fetchWorkflows(): Promise<Workflow[]> {
  const { data } = await Http.axios.get<Workflow[]>(`/workflow`);

  return data;
}

export function useWorkflows() {
  return useQuery([WORKFLOWS], () => fetchWorkflows(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

export const useWorkflowsByCompanyType = (companyType?: COMPANY_TYPE | null) => {
  const { data: workflows, isLoading, isError, error } = useWorkflows();

  const workflow = workflows?.find((workflow) => workflow.companyType === companyType);

  return useMemo(
    () => ({
      data: workflow,
      isLoading,
      isError,
      error,
    }),
    [error, isError, isLoading, workflow]
  );
};

async function updateWorkflow(stage: Partial<Workflow>): Promise<Workflow> {
  const { id, ...payload } = stage;
  const { data } = await Http.axios.patch<Partial<Workflow>, Workflow>(`/workflow/${id}`, payload);
  return data;
}

export function useUpdateWorkflow() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateWorkflow, {
    onMutate: async () => {
      startLoading(LoadingId.updateWorkflow);
    },
    onError: (error: Error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update workflow' });
    },

    onSettled: () => {
      queryClient.invalidateQueries(WORKFLOWS);
      stopLoading(LoadingId.updateWorkflow);
    },
  });
}
