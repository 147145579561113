import { QUESTION_TYPE, SurveyQuestion, TReviewOptionConfig } from '../../../../../../../../types';
import { OverrideMultiType } from './OverrideMultiType';
import { OverrideSingleType } from './OverrideSingleType';
import { OverrideTextType } from './OverrideTextType';

interface Props {
  question: SurveyQuestion;
  reviewOption: TReviewOptionConfig;
}

export const OverrideAnswerContent = ({ question, reviewOption }: Props) => {
  if (question.type === QUESTION_TYPE.SINGLE) {
    return <OverrideSingleType question={question} reviewOption={reviewOption} />;
  }

  if (question.type === QUESTION_TYPE.MULTI) {
    return <OverrideMultiType question={question} reviewOption={reviewOption} />;
  }

  if (question.type === QUESTION_TYPE.TEXT) {
    return <OverrideTextType question={question} reviewOption={reviewOption} />;
  }

  return <></>;
};
