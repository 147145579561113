import { styled } from '@mui/material';
import { SingleSelect } from '../../../../../../../../../components/SingleSelect/SingleSelect';
import { useCoreKpiCategories } from '../../../../../../../../../queries/useCoreKpiCategories';
import { useCallback, useMemo } from 'react';
import { CORE_KPI_FORMAT } from '../../../../../../../../../types';

const KpiSelectWrapper = styled('div')`
  flex: 1;
  & .MuiInputBase-root {
    border-radius: 4px 0 0 4px;
  }
`;

interface Props {
  kpi?: number | null;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const KpiSelect = ({ kpi, setFieldValue }: Props) => {
  const { data: coreKpiCategories } = useCoreKpiCategories();

  const kpis = useMemo(() => {
    const kpis = coreKpiCategories
      ?.flatMap((category) => category?.kpis || [])
      ?.filter(
        (kpi) => kpi.format === CORE_KPI_FORMAT.NUMBER || kpi.format === CORE_KPI_FORMAT.PERCENTAGE
      );

    return kpis;
  }, [coreKpiCategories]);

  const kpisOptions = useMemo(() => {
    return (
      kpis?.map((kpi) => ({
        id: kpi?.id,
        value: kpi.name,
      })) || []
    );
  }, [kpis]);

  const selectedKPI = useMemo(() => kpisOptions.find((o) => o.id === kpi), [kpi, kpisOptions]);

  const onChangeKPI = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('kpi', '');
        return;
      }
      setFieldValue('kpi', option?.id);
    },
    [setFieldValue]
  );
  return (
    <KpiSelectWrapper>
      <SingleSelect
        value={selectedKPI || null}
        options={kpisOptions}
        onChange={(e, newValue) => onChangeKPI(newValue)}
        fieldPlaceholder='Select KPI'
      />
    </KpiSelectWrapper>
  );
};
