import { Button, Checkbox, styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { Typography } from '../../../../components/Typography/Typography';
import {
  activeQuestionIdState,
  activeSectionIdState,
  activeSurveyState,
  isApplyingEvaluationRulesModeActiveState,
  isSingleActionMoveModeActiveState,
  sectionState,
  selectedQuestionsState,
} from '../../../../state/UIState';
import { ReactComponent as AddIcon } from '../../../../assets/icons/add-blue.svg';
import { useCallback, useMemo } from 'react';
import { QuestionList } from './QuestionList';
import { QUESTION_TYPE, SurveyQuestion, SurveySection } from '../../../../types';
import {
  APPLY_EVALUATION_RULES_HEADER_CONTROLS,
  HEADER_HEIGHT,
} from '../../../../constants/layoutSizes';
import { getDefaultTableData } from '../../../../constants/defaultValues';
import { generateId } from '../../../../utils/generateId';
import { QuestionsSelectionBar } from './QuestionsSelectionBar';
import { SectionDescription } from './SectionDescription/SectionDescription';

const Wrapper = styled('div')<{ isApplyActive: boolean }>`
  padding: 0 50px 24px 50px;
  flex-grow: 1;
  height: ${({ isApplyActive }) =>
    `calc(100vh - ${
      isApplyActive ? HEADER_HEIGHT + APPLY_EVALUATION_RULES_HEADER_CONTROLS : HEADER_HEIGHT
    }px)`};
  overflow-y: auto;
  transition: height 0.3s ease-in-out;
`;

const SectionWrapper = styled('div')`
  padding: 32px 0;
  max-width: 770px;
  background-color: #f5f8ff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const QuestionsSelector = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 44px;
`;

export const BuildMainContent = () => {
  const [activeSectionId] = useAtom(activeSectionIdState);
  const [activeSection, setActiveSection] = useAtom(sectionState(activeSectionId));
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [isApplyingModeActive] = useAtom(isApplyingEvaluationRulesModeActiveState);
  const [isSingleActionMoveModeActive] = useAtom(isSingleActionMoveModeActiveState);
  const [selectedQuestions, setSelectedQuestions] = useAtom(selectedQuestionsState);
  const [activeSurvey] = useAtom(activeSurveyState);

  const { colors } = useTheme();

  const onAddQuestionClick = useCallback(() => {
    if (!activeSection) return;
    const newQuestion: SurveyQuestion = {
      id: generateId(),
      name: activeSection?.questions?.length
        ? `Question ${activeSection?.questions?.length + 1}`
        : 'Question 1',
      type: QUESTION_TYPE.TEXT,
      options: [],
      tableData: getDefaultTableData(),
      includeInstructions: false,
      includeEvaluation: activeSurvey?.includeEvaluation || false,
      evaluationRules: null,
      instructions: '',
      sectionId: activeSection?.id,
      isActive: true,
      includeConditions: false,
      isRequired: true,
    };
    const updatedActiveSection = {
      ...(activeSection as SurveySection),
      questions: [...(activeSection?.questions || []), newQuestion],
    };
    setActiveSection(updatedActiveSection);
    setActiveQuestionId(newQuestion.id);
  }, [activeSection, activeSurvey?.includeEvaluation, setActiveQuestionId, setActiveSection]);

  const isSelected = useMemo(() => {
    if (!selectedQuestions || !activeSection?.questions) return false;
    return activeSection?.questions.every((question) =>
      selectedQuestions.map((q) => q.id).includes(question.id)
    );
  }, [activeSection?.questions, selectedQuestions]);

  const isIndeterminate = useMemo(() => {
    if (
      !selectedQuestions ||
      !activeSection?.questions ||
      !activeSection?.questions.length ||
      isSelected
    )
      return false;
    return activeSection.questions.some((question) =>
      selectedQuestions.map((q) => q.id).includes(question.id)
    );
  }, [activeSection?.questions, isSelected, selectedQuestions]);

  const onSelectAll = useCallback(() => {
    if (!activeSection?.questions) return;
    setSelectedQuestions((prev) => {
      return isSelected || isIndeterminate
        ? prev.filter((question) => !activeSection.questions.map((q) => q.id).includes(question.id))
        : [...prev, ...activeSection.questions];
    });
  }, [activeSection?.questions, isIndeterminate, isSelected, setSelectedQuestions]);

  return (
    <Wrapper isApplyActive={isApplyingModeActive}>
      <QuestionsSelectionBar />
      <SectionWrapper>
        <Typography variant='h4' color={colors.accent[90]} style={{ padding: '0 56px' }}>
          {activeSection?.name ?? ''}
        </Typography>
        <SectionDescription section={activeSection} setSection={setActiveSection} />
        {Boolean(activeSection?.questions?.length) && (
          <>
            <QuestionsSelector
              style={{
                visibility:
                  !isApplyingModeActive && activeSection?.questions.length ? 'visible' : 'hidden',
              }}
            >
              <Checkbox
                checked={isSelected}
                indeterminate={isIndeterminate}
                disabled={isSingleActionMoveModeActive}
                onClick={onSelectAll}
                style={{ padding: '0px' }}
              />
              <Typography variant='caption' color={colors.primary[70]}>
                QUESTIONS
              </Typography>
            </QuestionsSelector>
            <QuestionList />
          </>
        )}
        {!isApplyingModeActive && (
          <Button
            variant='outlined'
            style={{ height: '32px', margin: '0 32px' }}
            startIcon={<AddIcon style={{ transform: 'scale(0.7)' }} />}
            onClick={onAddQuestionClick}
          >
            Add Question
          </Button>
        )}
      </SectionWrapper>
    </Wrapper>
  );
};
