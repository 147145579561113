import { styled } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { FC, useEffect } from 'react';
import { useDebouncedValue } from '../../../../hooks/useDebouncedValue';
import { useUpdateReport, useUpdateReportWithToken } from '../../../../queries/useReports';
import { activeReportState, evaluationState, responsesState } from '../../../../state/UIState';
import { QuestionnaireMainContent } from './QuestionnaireMainContent';
import { QuestionnaireSideSection } from './QuestionnaireSideSection';
import { internalSurveyState } from '../../../../state/internalSurveyState';
import { REPORT_STATUS } from '../../../../types';

const Wrapper = styled('div')`
  display: flex;
  height: 100%;
`;

interface QuestionnaireStepProps {
  isInternal?: boolean;
  isReview?: boolean;
  withFooter?: boolean;
}

export const QuestionnaireStep: FC<QuestionnaireStepProps> = ({
  isInternal = false,
  isReview = false,
  withFooter = false,
}) => {
  const [response] = useAtom(responsesState);
  const [report] = useAtom(activeReportState);
  const [evaluation] = useAtom(evaluationState);
  const debouncedResponse = useDebouncedValue(response, 500);
  const updateReport = useUpdateReport();
  const updateReportWithToken = useUpdateReportWithToken();
  const debouncedEvaluation = useDebouncedValue(evaluation, 500);

  const setInternalSurvey = useSetAtom(internalSurveyState);

  useEffect(() => {
    setInternalSurvey(isInternal);
  }, [isInternal, setInternalSurvey]);

  useEffect(() => {
    if (Object.keys(response || {})?.length && report && !isReview) {
      if (isInternal) {
        updateReport.mutate({
          id: report.id,
          response: debouncedResponse,
        });
      } else {
        updateReportWithToken.mutate({
          response: debouncedResponse,
          status: REPORT_STATUS.IN_PROGRESS,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedResponse]);

  useEffect(() => {
    if (!evaluation || !report) return;

    if (isInternal) {
      updateReport.mutate({ id: report.id, evaluation: debouncedEvaluation });
    } else {
      updateReportWithToken.mutate({ id: report.id, evaluation: debouncedEvaluation });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEvaluation]);

  return (
    <Wrapper>
      <QuestionnaireSideSection />
      <QuestionnaireMainContent withFooter={withFooter} />
    </Wrapper>
  );
};
