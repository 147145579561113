import { styled } from '@mui/material';
import { useParams } from 'react-router';
import { BuildStep } from './BuildStep/BuildStep';
import { QuestionnaireStep } from './QuestionnaireStep/QuestionnaireStep';
import {
  activeSurveyIdState,
  isApplyingEvaluationRulesModeActiveState,
  isMultiActionMoveModeActiveState,
  isSingleActionMoveModeActiveState,
  rulesToApplyState,
  sectionsState,
  selectedQuestionsState,
  selectedSectionToMoveQuestionsState,
} from '../../../state/UIState';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { SurveySection } from '../../../types';
import { useUpdateSurvey } from '../../../queries/useSurveys';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { ReactComponent as UndoIcon } from '../../../assets/icons/undo-blue.svg';
import { ConfirmActionBar } from '../../../components/ConfirmActionBar/ConfirmActionBar';
import { buildTree, flattenTree } from '../../../utils/treeUtilities';

const Wrapper = styled('div')`
  height: 100%;
`;

export const StepContent = () => {
  const params = useParams();
  const activeStep = params.activeStep;
  const [isApplyingActive, setIsApplyingActive] = useAtom(isApplyingEvaluationRulesModeActiveState);
  const [isSingleActionMoveModeActive, setIsSingleActionMoveModeActive] = useAtom(
    isSingleActionMoveModeActiveState
  );
  const [isMultiActionMoveActive, setIsMultiActionModeActive] = useAtom(
    isMultiActionMoveModeActiveState
  );
  const [selectedSection, setSelectedSection] = useAtom(selectedSectionToMoveQuestionsState);
  const [selectedQuestions, setSelectedQuestions] = useAtom(selectedQuestionsState);
  const [rulesToApply, setRulesToApply] = useAtom(rulesToApplyState);
  const updateSurvey = useUpdateSurvey();
  const [activeSurveyId] = useAtom(activeSurveyIdState);
  const { pushSuccessToast } = useToastMessage();

  const [sections, setSections] = useAtom(sectionsState);

  const onCancel = useCallback(() => {
    setIsApplyingActive(false);
    if (!isMultiActionMoveActive) {
      setSelectedQuestions([]);
    }
    setRulesToApply([]);
    setIsSingleActionMoveModeActive(false);
    setIsMultiActionModeActive(false);
    setSelectedSection(null);
  }, [
    isMultiActionMoveActive,
    setIsApplyingActive,
    setIsMultiActionModeActive,
    setIsSingleActionMoveModeActive,
    setRulesToApply,
    setSelectedQuestions,
    setSelectedSection,
  ]);

  const moveQuestions = useCallback(() => {
    if (!selectedSection) return sections;
    const flatSections = flattenTree(sections);
    const updatedFlatSections = flatSections.map((section) => {
      if (section.id === selectedSection.id) {
        return {
          ...section,
          questions: [
            ...section.questions,
            ...selectedQuestions.map((q) => ({ ...q, sectionId: section.id })),
          ],
        };
      }
      const updatedQuestions = section.questions.filter(
        (question) => !selectedQuestions.map((q) => q.id).includes(question.id)
      );
      return {
        ...section,
        questions: updatedQuestions,
      };
    });
    return buildTree(updatedFlatSections);
  }, [sections, selectedQuestions, selectedSection]);

  const applyRules = useCallback(() => {
    const flatSections = flattenTree(sections);
    const updatedFlatSections = flatSections.map((section) => {
      const updatedQuestions = section.questions.map((question) => {
        if (selectedQuestions.map((q) => q.id).includes(question.id)) {
          return {
            ...question,
            evaluationRules: rulesToApply,
            customEvaluation: true,
          };
        }
        return question;
      });
      return {
        ...section,
        questions: updatedQuestions,
      };
    });
    return buildTree(updatedFlatSections);
  }, [rulesToApply, sections, selectedQuestions]);

  const updateSections = useCallback(() => {
    if (isApplyingActive) return applyRules();
    if (isSingleActionMoveModeActive || isMultiActionMoveActive) return moveQuestions();
    return sections;
  }, [
    applyRules,
    isApplyingActive,
    isMultiActionMoveActive,
    isSingleActionMoveModeActive,
    moveQuestions,
    sections,
  ]);

  const successMessage = useMemo(() => {
    if (isApplyingActive) {
      return `${selectedQuestions.length} questions evaluation criteria were successfully updated.`;
    }
    if (isMultiActionMoveActive || isSingleActionMoveModeActive) {
      return `${
        selectedQuestions.length > 1 ? `${selectedQuestions.length} questions were` : 'Question was'
      } successfully moved to ${selectedSection?.name}`;
    }
    return '';
  }, [
    isApplyingActive,
    isMultiActionMoveActive,
    isSingleActionMoveModeActive,
    selectedQuestions.length,
    selectedSection?.name,
  ]);

  const onConfirm = useCallback(async () => {
    if (!activeSurveyId) return;
    const updatedSections: SurveySection[] = updateSections();
    await updateSurvey.mutateAsync(
      { id: activeSurveyId, sections: updatedSections },
      {
        onSuccess: () => {
          const oldSectionsDeepCopy = JSON.parse(JSON.stringify(sections));
          pushSuccessToast({
            message: successMessage,
            action: () => {
              setSections(oldSectionsDeepCopy);
            },
            actionLabel: 'Undo',
            endIcon: <UndoIcon />,
          });
          setSections(updatedSections);
          onCancel();
          if (isMultiActionMoveActive) {
            setSelectedQuestions([]);
          }
        },
      }
    );
  }, [
    activeSurveyId,
    isMultiActionMoveActive,
    onCancel,
    pushSuccessToast,
    sections,
    setSections,
    setSelectedQuestions,
    successMessage,
    updateSections,
    updateSurvey,
  ]);

  const confirmBarText = useMemo(() => {
    if (isApplyingActive) return `Apply this Criteria to ${selectedQuestions.length} Questions`;
    if (isSingleActionMoveModeActive && !selectedSection)
      return `Select Section to move this Question`;
    if (isMultiActionMoveActive && !selectedSection)
      return `Select Section to move these Questions`;
    if (isSingleActionMoveModeActive) return `Move this Question to ${selectedSection?.name}`;
    if (isMultiActionMoveActive) return `Move these Questions to ${selectedSection?.name}`;
    return '';
  }, [
    isApplyingActive,
    isMultiActionMoveActive,
    isSingleActionMoveModeActive,
    selectedQuestions.length,
    selectedSection,
  ]);

  const confirmText = useMemo(() => {
    if (isApplyingActive) return `Apply`;
    if (isSingleActionMoveModeActive || isMultiActionMoveActive) return `Move`;
    return '';
  }, [isApplyingActive, isMultiActionMoveActive, isSingleActionMoveModeActive]);

  const isDisabled = useMemo(() => {
    if (isApplyingActive) return !selectedQuestions.length;
    if (isSingleActionMoveModeActive || isMultiActionMoveActive) return !selectedSection;
    return false;
  }, [
    isApplyingActive,
    isMultiActionMoveActive,
    isSingleActionMoveModeActive,
    selectedQuestions.length,
    selectedSection,
  ]);

  return (
    <Wrapper>
      <ConfirmActionBar
        text={confirmBarText}
        onCancel={onCancel}
        onConfirm={onConfirm}
        isActive={isApplyingActive || isSingleActionMoveModeActive || isMultiActionMoveActive}
        confirmText={confirmText}
        isConfirmDisabled={isDisabled}
      />

      {activeStep === 'build' && <BuildStep />}
      {activeStep === 'review' && <QuestionnaireStep isReview isInternal />}
    </Wrapper>
  );
};
