import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { Radio } from '../../../components/Radio/Radio';
import { useCallback, useMemo } from 'react';
import { ColumnsTable } from './ColumnsTable';
import { companyColumns, exclusionColumns } from './companyColumns';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { EXPORT_COMPANIES_PARAMS, EXPORT_COMPANIES_TYPE } from '../../../types';
import { useAtom } from 'jotai';
import { exportTypeState } from '../../../state/UIState';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const OptionsWrapper = styled('div')`
  margin-top: 32px;
  margin-bottom: 40px;
`;

const RadioOption = styled('div')<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  background: ${({ isSelected, theme }) => (isSelected ? theme.colors.accent[5] : 'transparent')};
  max-width: 345px;
  border-radius: ${({ isSelected }) => (isSelected ? '8px' : 0)};
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const columnOptions = [
  {
    id: EXPORT_COMPANIES_TYPE.companyDetails,
    label: 'Company Details',
  },
  {
    id: EXPORT_COMPANIES_TYPE.companyExclusions,
    label: 'Company Exclusions',
  },
];

export const ColumnsStep = () => {
  const { colors } = useTheme();
  const { setParam } = useQueryParams();

  const [selectedOption, setSelectedOption] = useAtom(exportTypeState);

  const onOptionClick = useCallback(
    (optionId: EXPORT_COMPANIES_TYPE) => {
      setSelectedOption(optionId);
      setParam({
        param: EXPORT_COMPANIES_PARAMS.EXPORT_TYPE,
        value: optionId,
      });
    },
    [setParam, setSelectedOption]
  );

  const columnsData = useMemo(() => {
    if (selectedOption === EXPORT_COMPANIES_TYPE.companyDetails) return companyColumns;
    if (selectedOption === EXPORT_COMPANIES_TYPE.companyExclusions) return exclusionColumns;

    return null;
  }, [selectedOption]);

  return (
    <Wrapper>
      <Typography variant='h2' color={colors.primary[90]}>
        What do you want to export?
      </Typography>
      <OptionsWrapper>
        {columnOptions.map((option) => {
          const isSelected = option.id === selectedOption;
          return (
            <RadioOption
              key={option.id}
              isSelected={isSelected}
              onClick={() => onOptionClick(option.id)}
            >
              <Radio
                checked={isSelected}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 24,
                  },
                }}
              />
              <Typography variant='subtitle2' color={colors.primary[90]}>
                {option.label}
              </Typography>
            </RadioOption>
          );
        })}
      </OptionsWrapper>
      {columnsData && <ColumnsTable columnsData={columnsData} />}
    </Wrapper>
  );
};
