import { useCallback } from 'react';
import { SurveyQuestion, QuestionOption } from '../../../../../../../../../../types';
import { OptionsList } from '../../../../../components/OptionsList';
import { generateId } from '../../../../../../../../../../utils/generateId';
import { useUpdateSubQuestion } from '../hooks/useUpdateSubquestion';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { SubQuestionOption } from './SubQuestionOption';

interface Props {
  subQuestion: SurveyQuestion;
  option: QuestionOption;
}

export const SubQuestionOptionsList = ({ subQuestion, option }: Props) => {
  const { updateSubQuestion } = useUpdateSubQuestion();

  const onAddOption = useCallback(() => {
    const newOption = {
      id: generateId(),
      name: `Option ${subQuestion.options.length + 1}`,
      justCreated: true,
    };
    const updatedSubQuestion = {
      ...subQuestion,
      options: [...subQuestion.options, newOption],
    };
    updateSubQuestion(updatedSubQuestion, option);
  }, [option, subQuestion, updateSubQuestion]);

  const handleReorder = (e: DragEndEvent) => {
    const { active, over } = e;
    if (over?.id && active.id !== over.id) {
      const oldIndex = subQuestion.options.findIndex((s) => s.id === active.id);
      const newIndex = subQuestion.options.findIndex((s) => s.id === over.id);
      const reorderedOptions = arrayMove(subQuestion.options, oldIndex, newIndex);
      const updatedSubQuestion = {
        ...subQuestion,
        options: reorderedOptions,
      };
      updateSubQuestion(updatedSubQuestion, option);
    }
  };

  return (
    <OptionsList
      handleReorder={handleReorder}
      question={subQuestion}
      onAddOption={onAddOption}
      activeQuestionId={subQuestion.id}
    >
      {subQuestion?.options.map((subOption) => (
        <SubQuestionOption
          key={subOption.id}
          option={subOption}
          type={subQuestion.type}
          showDragIcon
          subQuestion={subQuestion}
          questionOption={option}
        />
      ))}
    </OptionsList>
  );
};
