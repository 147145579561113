import { styled } from '@mui/material';
import { SingleAssessmentHeader } from './components/SingleAssessmentHeader';
import { useParams } from 'react-router';
import { useReportById } from '../../queries/useReports';
import { AssesmentPageSkeletonLoader } from '../../components/SkeletonLoader/AssesmentPage.SkeletonLoader';
import { SingleAssessmentContent } from './components/SingleAssessmentContent';

const Wrapper = styled('div')``;

export const SingleAssessment = () => {
  const params = useParams();
  const { isLoading: isLoading, data: report } = useReportById(Number(params.reportId));

  if (!report || isLoading) return <AssesmentPageSkeletonLoader />;

  return (
    <Wrapper>
      <SingleAssessmentHeader company={report.company} surveyName={report.survey.name} />
      <SingleAssessmentContent
        survey={report.survey}
        response={report.response}
        reportReview={report.review}
        evaluation={report.evaluation}
        evaluationScale={report?.survey.evaluationScale}
      />
    </Wrapper>
  );
};
