import { useMemo } from 'react';
import { COMPANY_TYPE, STEP_TYPE } from '../types';
import { useAllStepsByCompanyType } from './useAllStepsByCompanyType';

export const useAllSteps = (companyType?: COMPANY_TYPE) => {
  const allNotControlledSteps = useAllStepsByCompanyType(COMPANY_TYPE.NOT_CONTROLLED);
  const allControlledSteps = useAllStepsByCompanyType(COMPANY_TYPE.CONTROLLED);

  const allUniqueSteps = useMemo(() => {
    const allSteps = [...(allControlledSteps || []), ...(allNotControlledSteps || [])];

    return [...new Map(allSteps.map((item) => [item.name, item])).values()];
  }, [allControlledSteps, allNotControlledSteps]);

  const allSteps = useMemo(() => {
    if (companyType === COMPANY_TYPE.CONTROLLED) return allControlledSteps;
    if (companyType === COMPANY_TYPE.NOT_CONTROLLED) return allNotControlledSteps;

    return allUniqueSteps;
  }, [allControlledSteps, allNotControlledSteps, allUniqueSteps, companyType]);

  const allUniqueAssessmentSteps = useMemo(() => {
    return allSteps?.filter(
      (step) => step?.type === STEP_TYPE.ASSESSMENT || step?.type === STEP_TYPE.SCORE
    );
  }, [allSteps]);

  return { allSteps, allUniqueAssessmentSteps };
};
