import ContentLoader from 'react-content-loader';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  display: flex;
  flex: 1;
`;

export function RequestGroupsSkeletonLoader(): ReactElement {
  return (
    <SkeletonWrapper>
      <ContentLoader height={530} width={'100%'}>
        <rect width='345' height='34' y='20' rx='4' fill='#EEEFF1' />
        <rect width='240px' height='34' x='369' y='20' rx='4' fill='#EEEFF1' />
        <rect width='100%' height='24' y='70' rx='4' fill='#EEEFF1' />

        <rect y='102' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='143' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='184' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='225' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='266' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='307' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='348' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='389' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='430' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='471' width='100%' height='38' rx='4' fill='#EEEFF1' />
      </ContentLoader>
    </SkeletonWrapper>
  );
}
