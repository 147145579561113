import { Button, Typography, styled, useTheme } from '@mui/material';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info-blue-v2.svg';
import { ReactComponent as RefreshIcon } from '../../../../assets/icons/refresh-blue.svg';
import { useState } from 'react';
import { ImportTableDataModal } from './ImportTableDataModal';
import { QuestionTableData } from '../../../../types';

const DownloadWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.accent[5]};
  padding: 0 16px;
  margin-top: 16px;
`;
const NoteWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  onSynchronize: (uploadedRows: any[][]) => void;
  tableData: QuestionTableData;
}

export const UploadCSV = ({ onSynchronize, tableData }: Props) => {
  const { colors } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <DownloadWrapper>
      <NoteWrapper>
        <InfoIcon />
        <Typography variant='subtitle2' color={colors.accent[80]}>
          Download as a spreadsheet, and upload back with data.
        </Typography>
      </NoteWrapper>
      <Button onClick={handleOpenModal} variant='text' startIcon={<RefreshIcon />}>
        <Typography variant='subtitle2' color={colors.accent[50]}>
          Use Spreadsheet
        </Typography>
      </Button>

      {isModalOpen && (
        <ImportTableDataModal
          onClose={handleCloseModal}
          tableData={tableData}
          onSynchronize={onSynchronize}
        />
      )}
    </DownloadWrapper>
  );
};
