import { Task } from '../../TaskGroup';
import { useMemo, useState } from 'react';
import { REPORT_STATUS } from '../../../../types';
import { RequestStatusCell } from '../../../Table/CellRenderers/RequestStatusCell';
import { AddAnalystRating } from './AddAnalystRating';
import { ReviewAnalystRatingModal } from './ReviewAnalystRatingModal/ReviewAnalystRatingModal';
import { useHasAnalystRating } from './hooks/useHasAnalystRating';
import { useAnalystRatingStatus } from './hooks/useAnalystRatingStatus';
import { AnalystRatingModal } from '../../../../pages/SIngleCompany/components/AnalystRatingModal/AnalystRatingModal';
import { useNotifyUsersForAnalystRating } from './hooks/useNotifyUsersForAnalystRating';

interface Props {
  hasAccess?: boolean;
  isBlocked?: boolean;
  hasEvaluationAccess?: boolean;
  isStepCompleted?: boolean;
}

export const AnalystRatingStep = ({
  hasAccess,
  isBlocked,
  hasEvaluationAccess,
  isStepCompleted,
}: Props) => {
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isAnalystModalOpen, setIsAnalystModalOpen] = useState(false);

  const hasAnalystRating = useHasAnalystRating();
  const onNotifyUsers = useNotifyUsersForAnalystRating();

  const isDisabled = useMemo(() => {
    if (isBlocked) return true;

    if (isStepCompleted) return false;

    if (hasAnalystRating && !hasEvaluationAccess) return true;

    if (!hasAnalystRating && !hasAccess) return true;

    return false;
  }, [hasAccess, hasEvaluationAccess, isBlocked, hasAnalystRating, isStepCompleted]);

  const analystRatingStatus = useAnalystRatingStatus();

  const isHighlighted = useMemo(() => {
    if ((!analystRatingStatus || analystRatingStatus === REPORT_STATUS.REJECTED) && hasAccess)
      return true;

    if (analystRatingStatus === REPORT_STATUS.IN_REVIEW && hasEvaluationAccess) return true;

    return false;
  }, [analystRatingStatus, hasAccess, hasEvaluationAccess]);

  const onTaskNameClick = () => {
    if (hasAnalystRating && analystRatingStatus === REPORT_STATUS.REJECTED) {
      setIsAnalystModalOpen(true);
      return;
    }

    if (hasAnalystRating && analystRatingStatus === REPORT_STATUS.IN_REVIEW) {
      setIsReviewModalOpen(true);
    }
  };

  return (
    <Task
      taskName={`Analyst Rating`}
      isHighlighted={isHighlighted}
      disabled={isDisabled}
      onTaskNameClick={hasAnalystRating ? onTaskNameClick : undefined}
    >
      {!hasAnalystRating && (
        <AddAnalystRating disabled={isDisabled} isStepCompleted={isStepCompleted} />
      )}
      {hasAnalystRating && (
        <>
          <RequestStatusCell status={analystRatingStatus} />
          {analystRatingStatus === REPORT_STATUS.REJECTED ? (
            <AnalystRatingModal
              isOpen={isAnalystModalOpen}
              onClose={() => setIsAnalystModalOpen(false)}
              onSubmit={onNotifyUsers}
            />
          ) : (
            <ReviewAnalystRatingModal
              isOpen={isReviewModalOpen}
              onClose={() => setIsReviewModalOpen(false)}
            />
          )}
        </>
      )}
    </Task>
  );
};
