import { useTheme } from '@mui/material';
import { QUESTION_TYPE, REVIEW_TYPE, SurveyQuestion, TReviewOptionConfig } from '../../../../types';
import { useAtom } from 'jotai';
import { reviewState } from '../../../../state/UIState';
import { ReviewTabOption } from './ReviewTabOption/ReviewTabOption';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useReportById } from '../../../../queries/useReports';
import { EvaluationScore } from './EvaluationScore';
import { ReviewReportTabWrapper } from '../../../../components/ReviewReportTabWrapper';

interface Props {
  question: SurveyQuestion;
}

export const ReviewBox = ({ question }: Props) => {
  const { colors } = useTheme();
  const params = useParams();

  const { data: report } = useReportById(Number(params?.reportId));
  const [review] = useAtom(reviewState);
  const [selectedOption, setSelectedOption] = useState<REVIEW_TYPE | null>(
    review?.[question.id]?.type || null
  );

  const reviewOptionsConfig: TReviewOptionConfig[] = useMemo(() => {
    if (question.type === QUESTION_TYPE.TABLE) {
      return [
        {
          type: REVIEW_TYPE.APPROVE,
          name: 'Approve',
          color: colors.success[40],
        },
      ];
    }

    return [
      {
        type: REVIEW_TYPE.APPROVE,
        name: 'Approve',
        color: colors.success[40],
      },
      {
        type: REVIEW_TYPE.OVERRIDE,
        name: 'Override',
        color: colors.warning[40],
      },
    ];
  }, [colors.success, colors.warning, question.type]);

  const isEvaluated = useMemo(() => {
    if (!review) return false;
    return reviewOptionsConfig.some((rule) => review?.[question.id]?.type === rule.type);
  }, [question.id, review, reviewOptionsConfig]);

  return (
    <ReviewReportTabWrapper>
      {report?.survey?.includeEvaluation && (
        <EvaluationScore
          questionId={question?.id}
          evaluationScale={report?.survey?.evaluationScale}
        />
      )}
      {reviewOptionsConfig?.map((reviewOption) => {
        return (
          <ReviewTabOption
            key={reviewOption.type}
            question={question}
            reviewOption={reviewOption}
            isEvaluated={isEvaluated}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        );
      })}
    </ReviewReportTabWrapper>
  );
};
