import { QuestionOption, SurveyQuestion } from '../../../../../types';
import { useCallback } from 'react';
import { useAtom } from 'jotai';
import {
  activeQuestionIdState,
  activeSectionIdState,
  responsesState,
} from '../../../../../state/UIState';
import { CheckboxOption } from './CheckboxOption';
import { OptionListWrapper } from '../common';
import { useIsQuestionDisabled } from '../useIsQuestionDisabled';

interface Props {
  question: SurveyQuestion;
}

export const MultiOptionsQuestion = ({ question }: Props) => {
  const [responses, setResponses] = useAtom(responsesState);

  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);

  const isQuestionDisabled = useIsQuestionDisabled();

  const onCheckboxClick = useCallback(
    (option: QuestionOption) => {
      if (isQuestionDisabled) return;
      const response = responses[question.id] as QuestionOption[] | null;
      if (response === null) {
        setResponses((oldResponses) => {
          return {
            ...oldResponses,
            [question.id]: [option],
          };
        });
        return;
      }
      if (response?.length === 1 && response[0].id === option.id) {
        setResponses((oldResponses) => {
          return {
            ...oldResponses,
            [question.id]: null,
          };
        });
        return;
      }
      if (response?.some((o) => o.id === option.id)) {
        setResponses((oldResponses) => {
          return {
            ...oldResponses,
            [question.id]: response.filter((o) => o.id !== option.id),
          };
        });
      } else {
        setResponses((oldResponses) => {
          return {
            ...oldResponses,
            [question.id]: response ? [...response, option] : [option],
          };
        });
      }
    },
    [isQuestionDisabled, responses, question.id, setResponses]
  );

  const onChangeNote = useCallback(
    (notes: string, option: QuestionOption) => {
      setResponses((oldResponses) => {
        const response = (oldResponses?.[question.id] as QuestionOption[])?.map((op) => {
          if (op.id === option.id) {
            return {
              ...op,
              notes,
            };
          }
          return op;
        });
        return {
          ...oldResponses,
          [question.id]: response,
        };
      });
    },
    [question.id, setResponses]
  );

  const onDeleteNote = useCallback(
    (option: QuestionOption) => {
      setResponses((oldResponses) => {
        const response = (oldResponses?.[question.id] as QuestionOption[])?.map((op) => {
          if (op.id === option.id) {
            return {
              ...op,
              notes: '',
            };
          }
          return op;
        });
        return {
          ...oldResponses,
          [question.id]: response,
        };
      });
    },
    [question.id, setResponses]
  );

  const onFocus = useCallback(() => {
    setActiveQuestionId(question.id);
    setActiveSectionId(question.sectionId);
  }, [question.id, question.sectionId, setActiveQuestionId, setActiveSectionId]);

  return (
    <OptionListWrapper>
      {question.options.map((option) => {
        const optionResponse = (responses[question.id] as QuestionOption[])?.find(
          (o) => o.id === option.id
        );
        return (
          <CheckboxOption
            key={option.id}
            option={option}
            onCheckboxClick={onCheckboxClick}
            optionResponse={optionResponse}
            onFocus={onFocus}
            onChangeNote={onChangeNote}
            onDeleteNote={onDeleteNote}
            isDisabled={isQuestionDisabled}
          />
        );
      })}
    </OptionListWrapper>
  );
};
