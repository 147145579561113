import { useAtom } from 'jotai';
import { MainContentHeader, MainContentWrapper } from '../../../components/DataCollectionLayout';
import {
  activeExclusionCategoryState,
  isConfirmExclusionsBarActiveState,
  isMoveExclusionModeActiveState,
} from '../../../state/UIState';
import { useCreateExclusionCondition } from '../../../queries/useExclusionCondition';
import { ConditionsList } from './ConditionsList/ConditionsList';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';

export const ExclusionsContent = () => {
  const [isConfirmMoveBarActive] = useAtom(isConfirmExclusionsBarActiveState);
  const [isMoveModeActive] = useAtom(isMoveExclusionModeActiveState);

  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const [activeCategory] = useAtom(activeExclusionCategoryState);

  const { mutate: createCondition } = useCreateExclusionCondition();

  const onAddCondition = () => {
    if (!activeCategory) return;
    const payload = {
      condition: activeCategory?.conditions?.length
        ? `Question ${activeCategory?.conditions?.length + 1}`
        : 'Question 1',
      category: activeCategory.id,
      order: activeCategory?.conditions?.length ? activeCategory?.conditions?.length + 1 : 0,
      isActive: false,
    };

    createCondition(payload, {
      onSuccess: async () => {
        if (!appConfigData?.data) {
          return;
        }
        await updateAppConfig.mutateAsync({
          data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
        });
      },
    });
  };

  return (
    <MainContentWrapper isActive={isConfirmMoveBarActive}>
      <MainContentHeader
        title={activeCategory ? `${activeCategory?.name} Conditions` : ''}
        onClickAdd={onAddCondition}
        isDisabled={isMoveModeActive}
        addCTAText='Add Condition'
      />

      <ConditionsList />
    </MainContentWrapper>
  );
};
