import { Select, SelectChangeEvent, useTheme } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction } from 'react';

interface Props {
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  renderValue?: ((value: string) => React.ReactNode) | undefined;
  children: ReactNode;
}

export const BasicSelect = ({ value, onChange, setIsOpen, renderValue, children }: Props) => {
  const { colors } = useTheme();

  return (
    <Select
      displayEmpty
      value={value}
      onChange={onChange}
      onOpen={() => setIsOpen?.(true)}
      onClose={() => setIsOpen?.(false)}
      renderValue={renderValue}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': { borderColor: colors.primary[30] },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.accent[50]}`,
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.accent[50]}`,
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            border: `1px solid ${colors.primary[30]}`,
            boxShadow: 'none',
            marginTop: '8px',
          },
        },
      }}
    >
      {children}
    </Select>
  );
};
