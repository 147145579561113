import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { activeReportState } from '../../../state/UIState';
import {
  generateTimestampByPeriod,
  getPeriodByFrequencyAndReportDate,
} from '../../../utils/companyUtils';
import {
  CORE_KPI_FORMAT,
  CoreKpiRequestFrequency,
  KPISValueResponse,
  KpisPeriodData,
  QuestionTableData,
  SURVEY_FREQUENCY,
} from '../../../types';
import { KPI_FORMAT_COLUMN_ID, KPI_VALUE_COLUMN_ID } from '../../../constants/defaultValues';
import { useKpiMatrix } from '../../SIngleCompany/hooks/useKpiMatrix';
import { useUpdateCompany } from '../../../queries/useCompanies';

export const useUpdateCompanyKpis = () => {
  const [report] = useAtom(activeReportState);

  const { recalculateQuarterly, recalculateAnnualy } = useKpiMatrix();
  const updateCompany = useUpdateCompany();

  const updateCompanyCoreKpis = useCallback(async () => {
    if (!report || !report.response) return;
    const { kpisData } = report.company;

    const kpisDataFrequency = report.company.kpisDataFrequency || report.request.frequency;
    const period = getPeriodByFrequencyAndReportDate(
      kpisDataFrequency as SURVEY_FREQUENCY,
      report.month,
      report.year,
      true
    );
    const timestamp = generateTimestampByPeriod(
      period,
      report.year,
      kpisDataFrequency as CoreKpiRequestFrequency
    );
    const periodData: KpisPeriodData = {
      period,
      timestamp,
    };
    Object.values(report.response)?.forEach((value) => {
      (value as QuestionTableData)?.rows?.forEach((row, i) => {
        if (i) {
          const format = row[KPI_FORMAT_COLUMN_ID] as CORE_KPI_FORMAT;

          const rawValue = (row[KPI_VALUE_COLUMN_ID] as KPISValueResponse)?.value;

          periodData[parseInt(row.id)] = {
            value: format === CORE_KPI_FORMAT.TEXT ? rawValue : parseFloat(rawValue),
            format: format,
            notes: (row[KPI_VALUE_COLUMN_ID] as KPISValueResponse)?.notes,
          };
        }
      });
    });
    const periodToUpdate = kpisData?.[kpisDataFrequency as CoreKpiRequestFrequency]?.find(
      (data) => data.period === period
    );

    const updatedPeriods = periodToUpdate
      ? kpisData[kpisDataFrequency as CoreKpiRequestFrequency].map((data) => {
          if (data.period === period) {
            return periodData;
          }
          return data;
        })
      : [...kpisData[kpisDataFrequency as CoreKpiRequestFrequency], periodData].sort(
          (a, b) => a.timestamp - b.timestamp
        );
    const updatedKpisData = {
      ...kpisData,
      [kpisDataFrequency as CoreKpiRequestFrequency]: updatedPeriods,
    };
    if (kpisDataFrequency === SURVEY_FREQUENCY.MONTHLY) {
      const [updatedQuarters, updatedQuarterPeriod] = recalculateQuarterly(
        updatedPeriods,
        kpisData[SURVEY_FREQUENCY.QUARTERLY],
        period
      );
      const updatedAnnuals = recalculateAnnualy(
        updatedQuarters,
        kpisData[SURVEY_FREQUENCY.ANNUALLY],
        updatedQuarterPeriod
      );
      updatedKpisData[SURVEY_FREQUENCY.QUARTERLY] = updatedQuarters;
      updatedKpisData[SURVEY_FREQUENCY.ANNUALLY] = updatedAnnuals;
    }
    if (kpisDataFrequency === SURVEY_FREQUENCY.QUARTERLY) {
      const updatedAnnuals = recalculateAnnualy(
        updatedPeriods,
        kpisData[SURVEY_FREQUENCY.ANNUALLY],
        period
      );
      updatedKpisData[SURVEY_FREQUENCY.ANNUALLY] = updatedAnnuals;
    }
    await updateCompany.mutateAsync({
      id: report.company.id,
      kpisData: updatedKpisData,
      kpisDataFrequency: kpisDataFrequency as CoreKpiRequestFrequency,
    });
  }, [recalculateAnnualy, recalculateQuarterly, report, updateCompany]);

  return { updateCompanyCoreKpis };
};
