import { styled } from '@mui/material';

export const OptionListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OptionWrapper = styled('div')`
  display: flex;
  min-height: 18px;
  align-items: flex-start;
  gap: 8px;
  cursor: pointer;
  position: relative;
`;
