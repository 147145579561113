import { SelectItem } from '../types';
import { SingleSelect } from './SingleSelect/SingleSelect';

interface Props {
  selectedType: SelectItem | null;
  sortedUniqueTypes: SelectItem[];
  onChangeTypeFilter: (type: SelectItem | null) => void;
}

export const CompanyTypeFilter = ({
  selectedType,
  sortedUniqueTypes,
  onChangeTypeFilter,
}: Props) => {
  return (
    <SingleSelect
      style={{ width: '200px' }}
      options={sortedUniqueTypes}
      value={selectedType}
      onChange={(_, type) => onChangeTypeFilter(type)}
      disablePortal
      fieldPlaceholder='Filter by Type'
    />
  );
};
