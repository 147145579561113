import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { QuestionTableData } from '../../../../types';
import { useMemo } from 'react';
import {
  HEADER_COLUMN_ID,
  KPI_DEFINITION_COLUMN_ID,
  KPI_FORMAT_COLUMN_ID,
  KPI_METRIC_COLUMN_ID,
  KPI_VALUE_COLUMN_ID,
} from '../../../../constants/defaultValues';
import { TablePreview } from '../../../../components/Table/CellRenderers/TablePreview';
import { CoreKpiValue } from './CoreKpiValue';
import { styled } from '@mui/material';
import { useAtom } from 'jotai';
import { sidebarMenuPinnedState } from '../../../../state/sidebarMenuPinnedState';
import {
  MAIN_SIDEBAR_MENU_EXPANDED_WIDTH,
  MAIN_SIDEBAR_MENU_WIDTH,
} from '../../../../constants/layoutSizes';

const TableWrapper = styled('div')<{ width: string }>`
  max-width: ${({ width }) => `calc(100vw - 80px - ${width})`};
  width: 100%;
`;

interface Props {
  response: QuestionTableData;
  questionId: string;
}

const columnHelper = createColumnHelper<Record<string, any>>();

export const CoreKpiTable = ({ response, questionId }: Props) => {
  const [isSideMenuPinned] = useAtom(sidebarMenuPinnedState);

  const columns = useMemo(
    () => [
      columnHelper.accessor(HEADER_COLUMN_ID, {
        cell: (info) => {
          return <CoreKpiValue info={info} questionId={questionId} />;
        },
        meta: {
          width: '200px',
          minWidth: '200px',
          maxWidth: '200px',
        },
      }),
      columnHelper.accessor(KPI_DEFINITION_COLUMN_ID, {
        cell: (info) => {
          return <CoreKpiValue info={info} questionId={questionId} />;
        },
        meta: {
          width: '300px',
          minWidth: '300px',
          maxWidth: '300px',
        },
      }),
      columnHelper.accessor(KPI_METRIC_COLUMN_ID, {
        cell: (info) => {
          return <CoreKpiValue info={info} questionId={questionId} />;
        },
        meta: {
          width: '100px',
          minWidth: '100px',
          maxWidth: '100px',
        },
      }),
      columnHelper.accessor(KPI_FORMAT_COLUMN_ID, {
        cell: (info) => {
          return <CoreKpiValue info={info} questionId={questionId} />;
        },
        meta: {
          width: '80px',
          minWidth: '80px',
          maxWidth: '80px',
        },
      }),
      columnHelper.accessor(KPI_VALUE_COLUMN_ID, {
        cell: (info) => {
          return <CoreKpiValue info={info} isCoreKpiValue questionId={questionId} />;
        },
        meta: {
          width: '100px',
          minWidth: '100px',
          maxWidth: '100px',
        },
      }),
    ],
    [questionId]
  );

  const tableData = useMemo(() => {
    return (response as QuestionTableData)?.rows ?? [];
  }, [response]);

  const table = useReactTable({
    data: tableData,
    columns,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => String(row.id),
  });

  const tableWidth = useMemo(() => {
    return isSideMenuPinned
      ? `${MAIN_SIDEBAR_MENU_EXPANDED_WIDTH}px`
      : `${MAIN_SIDEBAR_MENU_WIDTH}px`;
  }, [isSideMenuPinned]);

  return (
    <TableWrapper id={questionId} width={tableWidth}>
      <TablePreview table={table} tableStyle={{ minWidth: '1000px' }} />
    </TableWrapper>
  );
};
