import { styled } from '@mui/material';
import { DataCollectionContent, DataCollectionHeader } from '../../components/DataCollectionLayout';
import { MainContent } from './components/MainContent';
import { useEffect, useMemo } from 'react';
import { useAppConfig } from '../../queries/useAppConfig';
import { useAtom } from 'jotai';
import { isConfirmExclusionsBarActiveState } from '../../state/UIState';

import { useOnUpdateExclusionsSurvey } from './hooks/useOnUpdateExclusionsSurvey';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const ExclusionsManagement = () => {
  const { data: appConfigData } = useAppConfig();
  const [isActive, setIsActive] = useAtom(isConfirmExclusionsBarActiveState);
  const { onUpdateSurvey } = useOnUpdateExclusionsSurvey();

  const confirmActionBarText = useMemo(() => {
    if (isActive && !appConfigData?.data.isExclusionSurveyUpToDate)
      return 'Update the Survey to reflect changes in exclusions for all active requests.';
    return '';
  }, [appConfigData?.data.isExclusionSurveyUpToDate, isActive]);

  useEffect(() => {
    if (!appConfigData?.data) return;
    setIsActive(!appConfigData.data.isExclusionSurveyUpToDate);
  }, [appConfigData, setIsActive]);

  return (
    <Wrapper>
      <DataCollectionHeader
        title='Exclusion Management'
        description='Managing investors and their exclusions'
      />
      <DataCollectionContent
        text={confirmActionBarText}
        confirmText={'Update'}
        onCancel={undefined}
        onConfirm={onUpdateSurvey}
        isActive={isActive}
        isConfirmDisabled={false}
      >
        <MainContent />
      </DataCollectionContent>
    </Wrapper>
  );
};
