import { Button, styled, useTheme } from '@mui/material';
import { HEADER_HEIGHT } from '../../constants/layoutSizes';
import { Typography } from '../Typography/Typography';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left-blue.svg';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[20]};
  padding: 0 40px;
`;

const BackButtonWrapper = styled('div')`
  width: 450px;
  display: flex;
  align-items: center;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface Props {
  onBackClick?: () => void;
  title: string;
}

export const HeaderWithBackBtn = ({ onBackClick, title }: Props) => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <BackButtonWrapper>
        {onBackClick && (
          <Button
            onClick={onBackClick}
            variant='text'
            startIcon={<ArrowLeftIcon />}
            style={{ minWidth: 'auto' }}
          ></Button>
        )}
        <Typography variant='h4' color={colors.primary[90]}>
          {title}
        </Typography>
      </BackButtonWrapper>
    </Wrapper>
  );
};
