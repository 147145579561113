import { useAtom } from 'jotai';
import { useHasAccessToTask } from '../../../../hooks/useHasAccessToTask';
import { useScreeningFlowSurveys } from './useScreeningFlowSurveys';
import { activeSurveyState } from '../../../../state/UIState';
import { useMemo } from 'react';

export const useSurveyFlowData = ({ companyId }: { companyId?: number }) => {
  const { nonApprovalAssessmentsStepData } = useScreeningFlowSurveys(companyId);
  const { getHasAccess } = useHasAccessToTask();

  const accessibleAssessments = useMemo(() => {
    return nonApprovalAssessmentsStepData?.filter((data) => {
      const hasAccessToTask = getHasAccess(data.step);
      return hasAccessToTask;
    });
  }, [getHasAccess, nonApprovalAssessmentsStepData]);

  const [activeSurvey] = useAtom(activeSurveyState);

  const currentSurveyIndex = useMemo(() => {
    return accessibleAssessments?.findIndex((survey) => survey?.surveyId === activeSurvey?.id);
  }, [activeSurvey?.id, accessibleAssessments]);

  const nextSurveyData = useMemo(() => {
    if (currentSurveyIndex == -1) return null;
    return accessibleAssessments?.[currentSurveyIndex + 1];
  }, [accessibleAssessments, currentSurveyIndex]);

  const prevSurveyData = useMemo(() => {
    if (currentSurveyIndex == -1) return null;
    return accessibleAssessments?.[currentSurveyIndex - 1];
  }, [accessibleAssessments, currentSurveyIndex]);

  return { nextSurveyData, prevSurveyData };
};
