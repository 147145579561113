import { styled, useTheme } from '@mui/material';
import { Table as TTable, flexRender, Row as Trow } from '@tanstack/react-table';
import { ColumnMeta } from '../../types';
import { ReactComponent as SortAscIcon } from '../../assets/icons/sort-asc.svg';
import { ReactComponent as SortDescIcon } from '../../assets/icons/sort-desc.svg';
import { MouseEvent, useCallback, useState } from 'react';
import { Typography } from '../Typography/Typography';
import { TableRow } from './TableRow';
import { AnimatePresence } from 'framer-motion';
import { TableData } from './Td';

import { ReactComponent as DragIcon } from '../../assets/icons/drag.svg';
import { DndContext, DragEndEvent, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ActionsPopupMenu } from '../ActionsPopupMenu/ActionsPopupMenu';

interface Props {
  table: TTable<any>;
  onRowClick?: (row: Trow<any>) => void;
  actions?: ActionOption[];
  onActionClick?: (actionId: string, row: Trow<any> | null) => void;
  tHeaderStyle?: React.CSSProperties;
  tableStyle?: React.CSSProperties;
  tdStyle?: React.CSSProperties;
  trStyle?: React.CSSProperties;
  thStyle?: React.CSSProperties;
  height?: string;
  disableAnimation?: boolean;
  disableRowHover?: boolean;
  disableLayoutAnimation?: boolean;
  disableLazyLoading?: boolean;
  isDraggable?: boolean;
  onDragEnd?: (e: DragEndEvent) => void;
  tableId?: string;
  maxWidth?: string;
  actionsTdStyle?: React.CSSProperties;
  getTrStyle?: (row: Trow<any>) => React.CSSProperties;
}

interface ActionOption {
  id: string;
  value: string;
  icon: JSX.Element;
  getIsDisabled?: (row: Trow<any> | null) => boolean;
}

const Wrapper = styled('div')<{ height: string; maxWidth?: string }>`
  // viewport width minus sidebar width minus padding
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'calc(100vw - 72px - 80px)')};
  overflow-y: auto;
  overflow-x: auto;
  max-height: ${({ height }) => height};
  height: ${({ height }) => height};
`;
const StyledTable = styled('table')`
  width: 100%;
  border-spacing: 0 0;
  border-collapse: separate;
  min-width: 840px;
  tr td {
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary[5]};
  }
  thead tr:first-of-type {
    position: sticky;
    top: 0;
    z-index: 10;
    th {
      background-color: ${({ theme }) => theme.colors.primary[5]};
    }
  }
`;
const Thead = styled('thead')`
  text-transform: uppercase;
`;
const Tbody = styled('tbody')`
  background-color: ${({ theme }) => theme.colors.primary[0]};
  padding: 0 19px;
`;

const Th = styled('th')<{ width?: string; minWidth?: string; maxWidth?: string }>`
  padding-right: 16px;
  &:first-of-type {
    padding-left: 16px;
  }
  width: ${({ width }) => (width ? width : 'auto')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
`;

const HeaderWrapper = styled('div')<{ isSortable: boolean; align?: 'right' | 'left' }>`
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'flex-start')};
  ${({ isSortable }) => (isSortable ? 'cursor: pointer;' : '')}
`;
const SortIconWrapper = styled('div')<{ align?: 'right' | 'left' }>`
  width: 16px;
  order: ${({ align }) => (align === 'right' ? 1 : 2)};
`;

const HeaderTextWrapper = styled('div')<{ align?: 'right' | 'left' }>`
  order: ${({ align }) => (align === 'right' ? 2 : 1)};
`;

export const Table = ({
  table,
  onRowClick,
  actions,
  onActionClick,
  tHeaderStyle,
  tableStyle,
  tdStyle,
  trStyle,
  thStyle,
  height = '640px',
  disableAnimation = false,
  disableRowHover = false,
  disableLayoutAnimation = false,
  disableLazyLoading = false,
  isDraggable = false,
  onDragEnd = () => '',
  tableId,
  maxWidth,
  actionsTdStyle,
  getTrStyle,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<Trow<any> | null>(null);
  const open = Boolean(anchorEl);
  const { colors } = useTheme();
  const onRowSelect = useCallback(
    (row: Trow<any>) => {
      if (onRowClick) onRowClick(row);
      if (row.getCanSelect()) {
        if (row.original.disabled) return;
        // not allowed unselecting for single select for now.
        if (row.getIsSelected() && !row.getCanMultiSelect()) return;
        const toggleSelected = row.getToggleSelectedHandler();
        toggleSelected(row);
      }
    },
    [onRowClick]
  );

  const handleClick = useCallback((event: MouseEvent<HTMLElement>, row: Trow<any>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setSelectedRow(null);
    }, 300);
  };
  const onMenuItemClick = useCallback(
    (actionId: string) => {
      onActionClick ? onActionClick(actionId, selectedRow) : null;
      handleCloseMenu();
    },
    [onActionClick, selectedRow]
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  return (
    <Wrapper height={height} maxWidth={maxWidth} id={tableId || ''}>
      <DndContext
        sensors={sensors}
        onDragEnd={onDragEnd}
        // collisionDetection={closestCenter}
        // modifiers={[restrictToFirstScrollableAncestor]}
      >
        <SortableContext
          items={table.getRowModel().rows ?? []}
          strategy={verticalListSortingStrategy}
          disabled={!isDraggable}
        >
          <StyledTable style={tableStyle}>
            <Thead style={tHeaderStyle}>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow
                  key={headerGroup.id}
                  disableAnimation={disableAnimation}
                  disableLayoutAnimation={disableLayoutAnimation}
                >
                  {isDraggable && <Th style={thStyle} />}
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      width={(header.column.columnDef.meta as ColumnMeta)?.width}
                      minWidth={(header.column.columnDef.meta as ColumnMeta)?.minWidth}
                      maxWidth={(header.column.columnDef.meta as ColumnMeta)?.maxWidth}
                      style={{
                        ...thStyle,
                        ...(header.column.columnDef.meta as ColumnMeta)?.thStyles,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <HeaderWrapper
                          isSortable={header.column.getCanSort()}
                          onClick={header.column.getToggleSortingHandler()}
                          align={(header.column.columnDef.meta as ColumnMeta)?.alignHeader}
                          style={{
                            width: (header.column.columnDef.meta as ColumnMeta)?.headerWrapperWidth
                              ? (header.column.columnDef.meta as ColumnMeta)?.headerWrapperWidth
                              : 'auto',
                          }}
                        >
                          <SortIconWrapper
                            align={(header.column.columnDef.meta as ColumnMeta)?.alignHeader}
                          >
                            {{
                              asc: <SortAscIcon />,
                              desc: <SortDescIcon />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </SortIconWrapper>
                          <HeaderTextWrapper
                            align={(header.column.columnDef.meta as ColumnMeta)?.alignHeader}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </HeaderTextWrapper>
                        </HeaderWrapper>
                      )}
                    </Th>
                  ))}
                  {actions && <Th style={{ ...thStyle }} />}
                </TableRow>
              ))}
            </Thead>
            <AnimatePresence>
              <Tbody>
                {table.getRowModel().rows.length === 0 && (
                  <TableRow className='no-data'>
                    <TableData
                      colSpan={table.getHeaderGroups()[0].headers.length}
                      disableLazyLoading={disableLazyLoading}
                    >
                      <Typography variant='body' color={colors.primary[70]}>
                        No data was found.
                      </Typography>
                    </TableData>
                  </TableRow>
                )}
                {table.getRowModel().rows.map((row) => (
                  <TableRow
                    disableLayoutAnimation={disableLayoutAnimation}
                    disableHover={disableRowHover}
                    disabled={
                      (row.getCanSelect() || row.getCanMultiSelect()) && row.original.disabled
                    }
                    onClick={() => onRowSelect(row)}
                    key={row.id}
                    selectable={row.getCanSelect() || !!onRowClick}
                    className={
                      row.getIsSelected() && !row.getCanMultiSelect() ? 'selected-row' : ''
                    }
                    style={{ ...trStyle, ...getTrStyle?.(row) }}
                    disableAnimation={disableAnimation}
                    id={row.id}
                  >
                    {isDraggable && (
                      <TableData style={tdStyle}>
                        <DragIcon />
                      </TableData>
                    )}
                    {row.getVisibleCells().map((cell) => (
                      <TableData
                        style={{
                          ...tdStyle,
                          ...(cell.column.columnDef.meta as ColumnMeta)?.tdStyles,
                        }}
                        key={cell.id}
                        width={(cell.column.columnDef.meta as ColumnMeta)?.width}
                        minWidth={(cell.column.columnDef.meta as ColumnMeta)?.minWidth}
                        maxWidth={(cell.column.columnDef.meta as ColumnMeta)?.maxWidth}
                        disableLazyLoading={disableLazyLoading}
                        cellInnerDivHeight={
                          (cell.column.columnDef.meta as ColumnMeta)?.cellInnerDivHeight
                        }
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableData>
                    ))}
                    {actions && (
                      <TableData
                        style={{ ...tdStyle, ...actionsTdStyle }}
                        disableLazyLoading={disableLazyLoading}
                      >
                        <ActionsPopupMenu
                          open={open}
                          anchorEl={anchorEl}
                          actions={actions.map((action) => ({
                            ...action,
                            isDisabled: action?.getIsDisabled?.(selectedRow),
                          }))}
                          onClick={(e) => handleClick(e, row)}
                          onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.stopPropagation();
                            handleCloseMenu();
                          }}
                          onMenuItemClick={onMenuItemClick}
                        />
                      </TableData>
                    )}
                  </TableRow>
                ))}
              </Tbody>
            </AnimatePresence>
          </StyledTable>
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};
