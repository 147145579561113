import { styled, useTheme } from '@mui/material';
import { BarSeriesOption, LineSeriesOption } from 'echarts';
import { ECharts } from 'echarts/core';
import { Typography } from '../../Typography/Typography';
import { useEffect, useMemo, useState } from 'react';

const Container = styled('div')`
  display: flex;
  gap: 24px;
  align-items: center;
  cursor: pointer;
  margin-top: 40px;
`;

const LegendItemWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Indicator = styled('div')<{ color?: string }>`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
`;

type LegendSelectedChangedEvent = {
  type: 'legendselectchanged';
  name: string;
  selected: {
    [name: string]: boolean;
  };
};

type SelectedSeries = Record<string, boolean>;
// type SelectedSeries = BarSeriesOption | LineSeriesOption;

interface Props {
  seriesData: (BarSeriesOption | LineSeriesOption)[];
  chartRef: React.MutableRefObject<ECharts | null>;
  onResetSelection?: () => void;
}

export const Legend = ({ seriesData, chartRef, onResetSelection }: Props) => {
  const { colors } = useTheme();

  const [selectedSeries, setSelectedSeries] = useState<SelectedSeries | null>(null);

  const initialSelected = useMemo(() => {
    return seriesData.reduce(
      (acc, cur) => ({ ...acc, [cur.name as string]: true }),
      {}
    ) as SelectedSeries;
  }, [seriesData]);

  useEffect(() => {
    setSelectedSeries(initialSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesData]);

  const onToggleSelect = (name: string | number) => {
    chartRef?.current?.dispatchAction({
      type: 'legendToggleSelect',
      name: name,
    });
  };

  useEffect(() => {
    const chart = chartRef.current;

    const onSelectChanged = (e: unknown) => {
      setSelectedSeries((e as LegendSelectedChangedEvent).selected);
    };

    chart?.on('legendselectchanged', onSelectChanged);

    return () => {
      chart?.off('legendselectchanged', onSelectChanged);
    };
  }, [chartRef]);

  return (
    <Container>
      {seriesData?.map((data) => {
        const isItemSelected = selectedSeries?.[data.name as string];
        return (
          <LegendItemWrapper
            onClick={() => onToggleSelect(data?.name || '')}
            key={data.name}
            style={{ opacity: isItemSelected ? 1 : 0.3 }}
          >
            <Indicator color={data.color as string} />
            <Typography variant='caption' color={colors.primary[90]}>
              {data.name}
            </Typography>
          </LegendItemWrapper>
        );
      })}
      {typeof onResetSelection === 'function' && (
        <Typography variant='srOnly' color={colors.accent[50]} onClick={onResetSelection}>
          Reset Selection
        </Typography>
      )}
    </Container>
  );
};
