import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { UnassignedCompaniesTable } from './UnassignedCompaniesTable';
import { useAtomValue } from 'jotai';
import { activeRoleState } from '../../../state/UIState';
import { Company, USER_ROLE } from '../../../types';
import { useMemo } from 'react';

const Wrapper = styled('div')`
  padding: 24px 40px;
`;

interface Props {
  companiesData?: Company[];
  isLoading: boolean;
}

export const UnassignedContent = ({ companiesData, isLoading }: Props) => {
  const { colors } = useTheme();
  const activeRole = useAtomValue(activeRoleState);

  const showTitle = useMemo(() => {
    return activeRole !== USER_ROLE.ANALYST;
  }, [activeRole]);

  return (
    <Wrapper>
      {showTitle && (
        <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '20px' }}>
          Pending Assignment
        </Typography>
      )}
      <UnassignedCompaniesTable companiesData={companiesData} isLoading={isLoading} />
    </Wrapper>
  );
};
