import { styled } from '@mui/material';
import { SearchInput } from '../../../../components/SearchInput/SearchInput';
import { Company, Sector, SelectItem, USER_ROLE } from '../../../../types';
import { Table } from '@tanstack/react-table';
import { StageFilter } from './components/StageFilter';
import { SectorsFilter } from '../../../../components/SectorsFilter';
import { CountriesFilter } from '../../../../components/CountriesFilter';
import { AnalystFilter } from '../../../../components/AnalystFilter';
import { ResetFiltersButton } from '../../../../components/ResetFiltersButton/ResetFiltersButton';
import { useCallback, useEffect, useMemo } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  activeRoleState,
  companiesTableColumnFiltersState,
  selectedCompanyAnalystsFilterState,
  selectedCompanyCountriesFilterState,
  selectedCompanySectorsFilterState,
  selectedCompanyStageFilterState,
  selectedCompanyTypeFilterState,
} from '../../../../state/UIState';
import { useCompanies } from '../../../../queries/useCompanies';
import { CompanyTypeFilter } from '../../../../components/CompanyTypeFilter';

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

interface Props {
  table: Table<Company>;
}

export const CompanyFilters = ({ table }: Props) => {
  const { data: companies } = useCompanies();
  const [columnFilters, setColumnFilters] = useAtom(companiesTableColumnFiltersState);
  const [selectedSectors, setSelectedSectors] = useAtom(selectedCompanySectorsFilterState);
  const [selectedCountries, setSelectedCountries] = useAtom(selectedCompanyCountriesFilterState);
  const [selectedAnalysts, setSelectedAnalysts] = useAtom(selectedCompanyAnalystsFilterState);
  const [, setSelectedStage] = useAtom(selectedCompanyStageFilterState);
  const [selectedType, setSelectedType] = useAtom(selectedCompanyTypeFilterState);

  const activeRole = useAtomValue(activeRoleState);

  const sortedUniqueSectors = useMemo(() => {
    const allValues = Array.from(table.getColumn('sectors')?.getFacetedUniqueValues().keys() ?? []);
    const map = allValues.reduce((acc, curr) => {
      curr.forEach((element: Sector) => {
        acc[element.name] = element.name;
      });
      return acc;
    }, {});
    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const sortedUniqueCountries = useMemo(() => {
    const allValues = Array.from(table.getColumn('country')?.getFacetedUniqueValues().keys() ?? []);
    const map = allValues.reduce((acc, curr) => {
      if (!curr) return acc;
      acc[curr] = curr;
      return acc;
    }, {});

    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const sortedUniqueAnalysts = useMemo(() => {
    if (
      activeRole &&
      [USER_ROLE.ANALYST, USER_ROLE.MANAGER, USER_ROLE.COMPLIANCE]?.includes(activeRole)
    )
      return [];
    const allValues = Array.from(table.getColumn('analyst')?.getFacetedUniqueValues().keys() ?? []);
    const unique = allValues.sort().map((value, id) => ({ id, value })) ?? [];
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const sortedUniqueTypes = useMemo(() => {
    const unique = Array.from(table.getColumn('type')?.getFacetedUniqueValues().keys() ?? [])
      .sort()
      .map((value, id) => ({ id, value }));
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const onChangeTypeFilter = useCallback(
    (type: SelectItem | null) => {
      setSelectedType(type);
      table.getColumn('type')?.setFilterValue(type?.value ?? '');
    },
    [setSelectedType, table]
  );

  const onChangeSectorsFilter = useCallback(
    (sectors: SelectItem[]) => {
      setSelectedSectors(sectors);
      table.getColumn('sectors')?.setFilterValue(sectors.map((s) => s.value));
    },
    [setSelectedSectors, table]
  );

  const onChangeCountriesFilter = useCallback(
    (countries: SelectItem[]) => {
      setSelectedCountries(countries);
      table.getColumn('country')?.setFilterValue(countries.map((c) => c.value));
    },
    [setSelectedCountries, table]
  );

  const onChangeAnalystsFilter = useCallback(
    (analysts: SelectItem[]) => {
      setSelectedAnalysts(analysts);
      table.getColumn('analyst')?.setFilterValue(analysts.map((r) => r.value));
    },
    [setSelectedAnalysts, table]
  );

  useEffect(() => {
    if (!columnFilters?.length) {
      setSelectedAnalysts(sortedUniqueAnalysts);
      setSelectedCountries(sortedUniqueCountries);
      setSelectedSectors(sortedUniqueSectors);
    }
  }, [
    columnFilters?.length,
    setSelectedAnalysts,
    setSelectedCountries,
    setSelectedSectors,
    sortedUniqueAnalysts,
    sortedUniqueCountries,
    sortedUniqueSectors,
  ]);

  const onResetFilters = useCallback(() => {
    setSelectedSectors(sortedUniqueSectors);
    setSelectedCountries(sortedUniqueCountries);
    setSelectedAnalysts(sortedUniqueAnalysts);
    setSelectedType(null);
    setSelectedStage(null);
    setColumnFilters([]);
  }, [
    setColumnFilters,
    setSelectedAnalysts,
    setSelectedCountries,
    setSelectedSectors,
    setSelectedStage,
    setSelectedType,
    sortedUniqueAnalysts,
    sortedUniqueCountries,
    sortedUniqueSectors,
  ]);

  const isResetDisabled = useMemo(() => {
    return !columnFilters.length;
  }, [columnFilters.length]);

  if (
    activeRole &&
    [USER_ROLE.ANALYST, USER_ROLE.MANAGER, USER_ROLE.COMPLIANCE]?.includes(activeRole)
  ) {
    return (
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a company'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
        <CompanyTypeFilter
          selectedType={selectedType}
          sortedUniqueTypes={sortedUniqueTypes}
          onChangeTypeFilter={onChangeTypeFilter}
        />
        <SectorsFilter
          sortedUniqueSectors={sortedUniqueSectors}
          onChangeSectorsFilter={onChangeSectorsFilter}
          selectedSectors={selectedSectors}
        />
        <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
      </FiltersWrapper>
    );
  }

  return (
    <FiltersWrapper>
      <SearchInput
        placeholder='Search for a company'
        style={{ width: '345px', marginRight: '12px' }}
        onClear={() => table.getColumn('name')?.setFilterValue('')}
        onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
        value={table.getColumn('name')?.getFilterValue() ?? ''}
      />
      <CompanyTypeFilter
        selectedType={selectedType}
        sortedUniqueTypes={sortedUniqueTypes}
        onChangeTypeFilter={onChangeTypeFilter}
      />
      <StageFilter table={table} />
      <SectorsFilter
        sortedUniqueSectors={sortedUniqueSectors}
        onChangeSectorsFilter={onChangeSectorsFilter}
        selectedSectors={selectedSectors}
      />
      <CountriesFilter
        sortedUniqueCountries={sortedUniqueCountries}
        onChangeCountriesFilter={onChangeCountriesFilter}
        selectedCountries={selectedCountries}
      />
      <AnalystFilter
        sortedUniqueAnalysts={sortedUniqueAnalysts}
        selectedAnalysts={selectedAnalysts}
        onChangeAnalystsFilter={onChangeAnalystsFilter}
      />
      <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
    </FiltersWrapper>
  );
};
