import { Button } from '@mui/material';
import { useAtom } from 'jotai';
import {
  companyExclusionIdsState,
  expandedCategoryState,
  selectedConditionState,
} from '../../state/UIState';
import { useCompanyExclusions } from '../SIngleCompany/hooks/useCompanyExclusions';
import { useCallback, useMemo } from 'react';
import { EXCLUSION_STATUS } from '../../types';
import { useUpdateCompany } from '../../queries/useCompanies';
import { useNavigate, useParams } from 'react-router';
import { useInvalidateExclusionsByCompany } from '../../queries/useExclusionsByCompany';
import { ROUTES } from '../../constants/routes';

export const ControlButtons = () => {
  const [companyExclusionIds, setCompanyExclusionIds] = useAtom(companyExclusionIdsState);
  const [, setExpandedCategory] = useAtom(expandedCategoryState);
  const [, setSelectedConditionState] = useAtom(selectedConditionState);
  const { data: companyExclusions } = useCompanyExclusions();
  const params = useParams();

  const navigate = useNavigate();

  const updateCompany = useUpdateCompany();
  const invalidateExclusionsByCompany = useInvalidateExclusionsByCompany();

  const onResetAndNavigate = useCallback(() => {
    setCompanyExclusionIds([]);
    setExpandedCategory(null);
    setSelectedConditionState(null);
    navigate(`/${ROUTES.COMPANIES}/${params?.id}/${ROUTES.INVESTOR_EXCLUSIONS}`);
  }, [
    navigate,
    params?.id,
    setCompanyExclusionIds,
    setExpandedCategory,
    setSelectedConditionState,
  ]);

  const existingCompanyExclusionIds = useMemo(() => {
    return companyExclusions?.map((exclusion) => exclusion.id) || [];
  }, [companyExclusions]);

  const violatedExistingCompanyIds = useMemo(() => {
    return (
      companyExclusions
        ?.filter((exclusion) => exclusion.status === EXCLUSION_STATUS.VIOLATED)
        ?.map((exclusion) => exclusion.id) || []
    );
  }, [companyExclusions]);

  const nextCTALabel = useMemo(() => {
    if (!companyExclusionIds?.length) return 'Add Exclusion';

    return `Add Exclusion (${companyExclusionIds?.length})`;
  }, [companyExclusionIds?.length]);

  const onAddExclusions = useCallback(async () => {
    if (!companyExclusionIds?.length) return;

    const exclusions = [...existingCompanyExclusionIds, ...companyExclusionIds];
    const violatedExclusionsIds = [...violatedExistingCompanyIds, ...companyExclusionIds];

    await updateCompany.mutateAsync({
      id: Number(params.id),
      exclusions,
      violatedExclusionsIds,
    });
    invalidateExclusionsByCompany(Number(params.id));

    onResetAndNavigate();
  }, [
    companyExclusionIds,
    existingCompanyExclusionIds,
    invalidateExclusionsByCompany,
    onResetAndNavigate,
    params.id,
    updateCompany,
    violatedExistingCompanyIds,
  ]);

  return (
    <>
      <Button variant='outlined' style={{ width: '95px' }} onClick={onResetAndNavigate}>
        Cancel
      </Button>
      <Button
        variant='contained'
        style={{ width: '166px' }}
        disabled={!companyExclusionIds?.length}
        onClick={onAddExclusions}
      >
        {nextCTALabel}
      </Button>
    </>
  );
};
