import { useParams } from 'react-router';

import { useMemo, useState } from 'react';
import { ReactComponent as ApprovedIcon } from '../../../../assets/icons/request-approved.svg';

import { Button, Chip, useTheme } from '@mui/material';
import { CoreKpisCreateRequestModal } from './CoreKpisCreateRequestModal';
import { Task } from '../../TaskGroup';
import { useCompanyById } from '../../../../queries/useCompanies';

interface Props {
  // counterNum: number;
  isBlocked?: boolean;
  hasAccess?: boolean;
  isStepCompleted?: boolean;
  stepId: string | number;
}

export const RecurringKpiStep = ({ isBlocked, hasAccess, stepId, isStepCompleted }: Props) => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const [isCoreKpiCreateRequestModalOpen, setIsCoreKpiCreateRequestModalOpen] = useState(false);

  const { colors } = useTheme();

  const isDisabled =
    !company?.externalEsgContact?.email || isBlocked || (!hasAccess && !isStepCompleted);

  const isCompleted = useMemo(
    () => company?.completedWorkflowSteps?.includes(stepId),
    [company?.completedWorkflowSteps, stepId]
  );

  if (!company) return null;

  return (
    <>
      <Task
        taskName={`Recurring KPIs update`}
        isHighlighted={hasAccess && !isStepCompleted}
        disabled={isDisabled}
        infoText={!company?.externalEsgContact?.email || isBlocked ? 'This step is blocked' : ''}
      >
        {isCompleted ? (
          <Chip
            icon={<ApprovedIcon style={{ marginRight: '4px' }} />}
            style={{
              backgroundColor: colors.success[5],
              width: '97px',
            }}
            label={'Sent'}
          />
        ) : (
          <Button
            onClick={
              company?.externalEsgContact?.email && hasAccess
                ? () => setIsCoreKpiCreateRequestModalOpen(true)
                : undefined
            }
            variant='contained'
            style={{ backgroundColor: isDisabled ? colors.accent[20] : colors.accent[50] }}
            disabled={isDisabled}
          >
            Create Request
          </Button>
        )}
      </Task>

      <CoreKpisCreateRequestModal
        onClose={() => setIsCoreKpiCreateRequestModalOpen(false)}
        company={company}
        isOpen={isCoreKpiCreateRequestModalOpen}
        stepId={stepId}
      />
    </>
  );
};
