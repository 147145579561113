import { useAtom } from 'jotai';
import { selectedChecklistItemState } from '../../../state/UIState';

import { useCallback } from 'react';
import { useOnCancelConfirm } from './useOnCancelConfirm';
import { useMoveChecklistItem } from './useMoveChecklistItem';
import { useMoveChecklistItems } from './useMoveChecklistItems';
import { useUpdateChecklistSurveys } from './useUpdateChecklistSurveys';

export const useConfirmBarActions = () => {
  const [selectedItem] = useAtom(selectedChecklistItemState);

  const { onCancel } = useOnCancelConfirm();

  const { moveChecklistItem } = useMoveChecklistItem();
  const { moveChecklistItems } = useMoveChecklistItems();

  const { onUpdateChecklistSurveys } = useUpdateChecklistSurveys();

  const onConfirm = useCallback(async () => {
    if (selectedItem) return moveChecklistItem();
    return moveChecklistItems();
  }, [moveChecklistItem, moveChecklistItems, selectedItem]);

  return { onCancel, onConfirm, onUpdateChecklistSurveys };
};
