import { Button, styled, useTheme } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left-blue.svg';
import { Typography } from '../../../components/Typography/Typography';
import { ROUTES } from '../../../constants/routes';

interface Props {
  name: string;
}

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SingleRequestGroupHeader: FC<Props> = ({ name }) => {
  const navigate = useNavigate();
  const { colors } = useTheme();

  return (
    <Wrapper>
      <Button
        onClick={() => navigate(`/${ROUTES.REQUESTS}`)}
        variant='text'
        startIcon={<ArrowLeftIcon />}
        style={{ minWidth: 'auto' }}
      ></Button>
      <Typography variant='h3' color={colors.primary[90]}>
        {name}
      </Typography>
    </Wrapper>
  );
};
