import { useMemo } from 'react';
import { flattenTree } from '../../../../utils/treeUtilities';
import { QuestionTableData, Response, Survey } from '../../../../types';
import { Typography } from '../../../../components/Typography/Typography';
import { styled, useTheme } from '@mui/material';
import { CoreKpiTable } from './CoreKpiTable';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 40px 40px 40px;
`;

const SectionWrapper = styled('div')`
  margin-bottom: 20px;
`;

const TableWrapper = styled('div')`
  width: 100%;
`;

interface Props {
  survey: Survey;
  response: Record<string, Response>;
}

export const CoreKpiSingleAssessment = ({ survey, response }: Props) => {
  const { colors } = useTheme();

  const coreKpiAssessmentData = useMemo(() => {
    const sections = flattenTree(survey.sections);
    return sections.map((section) => section);
  }, [survey.sections]);

  return (
    <Wrapper>
      {coreKpiAssessmentData?.map((section) => {
        return (
          <SectionWrapper key={section.id}>
            <Typography variant='body' color={colors.primary[90]} style={{ marginBottom: '20px' }}>
              {section.name}
            </Typography>
            <TableWrapper>
              <CoreKpiTable
                response={response[section.questions[0].id] as QuestionTableData}
                questionId={section.questions[0].id}
              />
            </TableWrapper>
          </SectionWrapper>
        );
      })}
    </Wrapper>
  );
};
