import { Checkbox, Divider, IconButton, Menu, MenuItem, styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  activeQuestionIdState,
  activeSectionIdState,
  activeSurveyState,
  isApplyingEvaluationRulesModeActiveState,
  isSingleActionMoveModeActiveState,
  questionState,
  sectionState,
  selectedQuestionsState,
} from '../../../../state/UIState';
import { QUESTION_TYPE, SurveyQuestion } from '../../../../types';
import { ReactComponent as MoreIcon } from '../../../../assets/icons/more-horizontal.svg';
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/duplicate.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as MoveIcon } from '../../../../assets/icons/corner-arrow.svg';
import { QuestionInstructionsControls } from './QuestionInstructionsControls';
import { QuestionOptionList } from './QuestionOptionList/QuestionOptionList';
import { QuestionTypeSelector } from './QuestionTypeSelector';
import { Typography } from '../../../../components/Typography/Typography';
import { ReactComponent as DragIcon } from '../../../../assets/icons/drag.svg';
import { generateId } from '../../../../utils/generateId';
import { EditableTextareaField } from '../../../../components/EditableTextField/EditableTextareaField';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { ConditionsControls } from './ConditionsControls';
import { QuestionEvaluationControls } from './QuestionEvaluationControls';

interface Props {
  question: SurveyQuestion;
  isDraggable: boolean;
  isDragging: boolean;
  order: number;
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap | undefined;
}

const Wrapper = styled('div')<{ isActive: boolean; cursor?: string }>`
  position: relative;
  padding: 16px 24px 1px 40px;
  flex-direction: column;
  gap: 20px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  /* cursor: ${({ cursor }) => cursor}; */
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  border-left: 4px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.accent[10] : 'transparent')};
`;
const DragWrapper = styled('div')`
  position: relative;
  padding: 0 32px;
`;
const QuestionNameWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  align-items: center;
  margin-bottom: 14px;
`;
export const Question = ({
  question,
  isDraggable,
  isDragging,
  order,
  listeners,
  attributes,
}: Props) => {
  const [activeSectionId] = useAtom(activeSectionIdState);
  const [activeSection, setSection] = useAtom(sectionState(activeSectionId));
  const [activeQuestionId, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [activeQuestion, setQuestion] = useAtom(questionState(activeQuestionId));
  const [isApplyingModeActive] = useAtom(isApplyingEvaluationRulesModeActiveState);
  const [isSingleActionMoveModeActive, setIsSingleActionMoveModeActive] = useAtom(
    isSingleActionMoveModeActiveState
  );
  const [selectedQuestions, setSelectedQuestions] = useAtom(selectedQuestionsState);
  const [questionTitle, setQuestionTitle] = useState(question.name);
  const [showDragIcon, setShowDragIcon] = useState(false);
  const { colors } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const open = Boolean(anchorEl);

  const isActive = !isDragging && activeQuestionId === question.id;

  const [activeSurvey] = useAtom(activeSurveyState);

  const options = useMemo(() => {
    return [
      {
        id: 'DUPLICATE',
        value: 'Duplicate Question',
        icon: <DuplicateIcon />,
      },
      {
        id: 'MOVE',
        value: 'Move Question to...',
        icon: <MoveIcon />,
      },
      {
        id: 'DELETE',
        value: 'Delete Question',
        icon: <DeleteIcon />,
      },
    ];
  }, []);

  const onQuestionRename = useCallback(
    (value: string) => {
      if (value === activeQuestion?.name || !value) {
        setQuestionTitle(activeQuestion?.name || '');
        return;
      }
      if (!activeQuestion) return;
      const updatedQuestion = {
        ...activeQuestion,
        name: value,
      };
      setQuestion(updatedQuestion);
    },
    [activeQuestion, setQuestion]
  );

  const deleteQuestion = useCallback(() => {
    if (!activeSection) return;
    const updatedSection = {
      ...activeSection,
      questions: activeSection?.questions.filter((q) => q.id !== question.id),
    };
    setSection(updatedSection);

    if (activeQuestionId === question.id && activeSection?.questions.length) {
      setActiveQuestionId(activeSection?.questions[0].id);
    }
  }, [activeSection, setSection, activeQuestionId, question.id, setActiveQuestionId]);

  const duplicateQuestion = useCallback(() => {
    if (!activeSection) return;
    const questionCopy = {
      ...question,
      id: generateId(),
      name: `${question.name} copy`,
      options: question.options.map((o) => ({
        ...o,
        id: generateId(),
        subQuestions: o.subQuestions?.length
          ? o.subQuestions?.map((subQuestion) => ({
              ...subQuestion,
              id: generateId(),
              options: subQuestion?.options?.length
                ? subQuestion.options.map((o) => ({
                    ...o,
                    id: generateId(),
                  }))
                : [],
            }))
          : undefined,
      })),
    };
    const updatedSection = {
      ...activeSection,
      questions: [...activeSection.questions, questionCopy],
    };
    setSection(updatedSection);
    setActiveQuestionId(questionCopy.id);
  }, [activeSection, question, setSection, setActiveQuestionId]);

  const moveQuestion = useCallback(() => {
    setIsSingleActionMoveModeActive(true);
    setSelectedQuestions([question]);
  }, [question, setIsSingleActionMoveModeActive, setSelectedQuestions]);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = useCallback(
    (e: MouseEvent<HTMLElement>, id: string) => {
      e.stopPropagation();
      handleCloseMenu();
      switch (id) {
        case 'DUPLICATE':
          duplicateQuestion();
          return;
        case 'MOVE':
          moveQuestion();
          return;
        case 'DELETE':
          deleteQuestion();
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [deleteQuestion, duplicateQuestion, moveQuestion]
  );

  useEffect(() => {
    if (activeQuestionId === question.id) {
      isDraggable && setShowDragIcon(true);
      const top = wrapperRef?.current?.getBoundingClientRect()?.top;
      if (top && (top > window.innerHeight || top < 0)) {
        wrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setShowDragIcon(false);
    }
  }, [activeQuestionId, isDraggable, question.id]);

  const onMouseEnter = useCallback(() => {
    if (activeQuestionId === question.id) {
      return;
    }
    setShowDragIcon(true);
  }, [activeQuestionId, question.id]);

  const onMouseLeave = useCallback(() => {
    if (activeQuestionId === question.id) {
      return;
    }
    setShowDragIcon(false);
  }, [activeQuestionId, question.id]);

  const onQuestionClick = useCallback(() => {
    if (isApplyingModeActive || isSingleActionMoveModeActive) return;
    setActiveQuestionId(question.id);
  }, [isApplyingModeActive, isSingleActionMoveModeActive, question.id, setActiveQuestionId]);

  const onSelectQuestion = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!selectedQuestions.map((q) => q.id).includes(question.id)) {
        setSelectedQuestions([...selectedQuestions, question]);
      } else {
        setSelectedQuestions(selectedQuestions.filter((q) => q.id !== question.id));
      }
    },
    [question, selectedQuestions, setSelectedQuestions]
  );

  const isSelected = useMemo(() => {
    if (isApplyingModeActive) {
      return isActive ? false : selectedQuestions.map((q) => q.id).includes(question.id);
    }
    if (isSingleActionMoveModeActive && selectedQuestions.map((q) => q.id).includes(question.id)) {
      return true;
    }
    return selectedQuestions.map((q) => q.id).includes(question.id);
  }, [
    isActive,
    isApplyingModeActive,
    isSingleActionMoveModeActive,
    question.id,
    selectedQuestions,
  ]);

  return (
    <DragWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {Boolean(showDragIcon && !isApplyingModeActive) && (
        <div {...listeners} {...attributes}>
          <DragIcon style={{ position: 'absolute', left: '12px', top: '5', cursor: 'pointer' }} />
        </div>
      )}

      <Wrapper ref={wrapperRef} onClick={onQuestionClick} isActive={isActive}>
        {!(isActive && isApplyingModeActive) && (
          <Checkbox
            checked={isSelected}
            disabled={isSingleActionMoveModeActive}
            style={{ position: 'absolute', left: '8px', top: '17px', padding: '0px' }}
            onClick={onSelectQuestion}
          />
        )}

        <QuestionNameWrapper>
          <Typography as='span' variant='subtitle2' color={colors.primary[70]}>
            {`${order}. `}
          </Typography>
          <EditableTextareaField
            value={questionTitle}
            onChange={(e) => setQuestionTitle(e.target.value)}
            onValueChanged={onQuestionRename}
            placeholder='Enter question'
          />
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
            style={{ borderRadius: '4px', marginTop: '5px' }}
          >
            <MoreIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              handleCloseMenu();
            }}
            PaperProps={{ style: { width: '175px' } }}
          >
            {options.map((c) => (
              <MenuItem
                key={c.id}
                onClick={(e) => handleSelectOption(e, c.id)}
                style={{ width: '100%' }}
              >
                {c.icon}
                <Typography variant='body' color={colors.primary[90]} style={{ marginLeft: '4px' }}>
                  {c.value}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </QuestionNameWrapper>
        {isActive && <QuestionTypeSelector />}
        {isActive &&
          (question?.type === QUESTION_TYPE.SINGLE || question?.type === QUESTION_TYPE.MULTI) && (
            <QuestionOptionList question={question} questionNum={order} />
          )}
        {isActive && question?.type === QUESTION_TYPE.SINGLE && (
          <>
            <Divider style={{ marginBottom: '7px', borderColor: colors.primary[20] }} />
            <ConditionsControls question={question} />
          </>
        )}
        {isActive && <Divider style={{ marginBottom: '7px', borderColor: colors.primary[20] }} />}
        {isActive && activeSurvey?.includeEvaluation && (
          <QuestionEvaluationControls question={question} />
        )}
        {isActive && activeSurvey?.includeEvaluation && (
          <Divider style={{ marginBottom: '7px', borderColor: colors.primary[20] }} />
        )}
        {isActive && <QuestionInstructionsControls question={question} />}
      </Wrapper>
    </DragWrapper>
  );
};
