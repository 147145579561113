import { useCallback } from 'react';
import {
  QUESTION_TYPE,
  SurveyQuestion,
  QuestionOption,
  SelectItem,
  QUESTION_MANDATORY_OPTIONS,
} from '../../../../../../../../../../types';
import { TypeSelector } from '../../../../../../components/TypeSelector';
import { useUpdateSubQuestion } from '../hooks/useUpdateSubquestion';
import {
  getDefaultQuestionOption,
  getDefaultSingleTypeQuestionOptions,
  getDefaultTableData,
} from '../../../../../../../../../../constants/defaultValues';

interface Props {
  subQuestion: SurveyQuestion;
  option: QuestionOption;
}

export const SubQuestionTypeSelector = ({ subQuestion, option }: Props) => {
  const { updateSubQuestion } = useUpdateSubQuestion();

  const onChangeQuestionMandatory = useCallback(
    (item: SelectItem) => {
      if (!subQuestion) return;
      const updatedSubQuestion = {
        ...subQuestion,
        isRequired: item.id === QUESTION_MANDATORY_OPTIONS.REQUIRED,
      };
      updateSubQuestion(updatedSubQuestion, option);
    },
    [option, subQuestion, updateSubQuestion]
  );

  const onClick = useCallback(
    (item: SelectItem) => {
      if (!subQuestion) return;

      const updatedSubQuestion = {
        ...subQuestion,
        type: item.id as QUESTION_TYPE,
        tableData: subQuestion.tableData ? subQuestion.tableData : getDefaultTableData(),
      };
      if (item.id === QUESTION_TYPE.SINGLE) {
        updatedSubQuestion.options = getDefaultSingleTypeQuestionOptions();
      }
      if (item.id === QUESTION_TYPE.MULTI) {
        updatedSubQuestion.options = [getDefaultQuestionOption()];
      }

      updateSubQuestion(updatedSubQuestion, option);
    },
    [option, subQuestion, updateSubQuestion]
  );
  return (
    <TypeSelector
      onChangeType={onClick}
      onChangeQuestionMandatory={onChangeQuestionMandatory}
      isRequired={!!subQuestion?.isRequired}
      questionType={subQuestion?.type}
      isSubQuestion
    />
  );
};
