import { Button, useTheme } from '@mui/material';
import { useState } from 'react';
import { AnalystRatingModal } from '../../../../pages/SIngleCompany/components/AnalystRatingModal/AnalystRatingModal';
import { useNotifyUsersForAnalystRating } from './hooks/useNotifyUsersForAnalystRating';

interface Props {
  disabled?: boolean;
  isStepCompleted?: boolean;
}

export const AddAnalystRating = ({ disabled, isStepCompleted }: Props) => {
  const [isAnalystModalOpen, setIsAnalystModalOpen] = useState(false);
  const { colors } = useTheme();

  const onNotifyUsers = useNotifyUsersForAnalystRating();

  return (
    <>
      <Button
        onClick={() => (isStepCompleted || disabled ? undefined : setIsAnalystModalOpen(true))}
        variant='contained'
        style={{ backgroundColor: disabled ? colors.accent[20] : colors.accent[50] }}
        disabled={disabled}
      >
        Proceed to Form
      </Button>

      <AnalystRatingModal
        isOpen={isAnalystModalOpen}
        onClose={() => setIsAnalystModalOpen(false)}
        onSubmit={onNotifyUsers}
      />
    </>
  );
};
