import { DndContext, DragEndEvent, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Button, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../components/Typography/Typography';
import { QUESTION_TYPE, SurveyQuestion } from '../../../../../../types';
import { ReactNode, useMemo } from 'react';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

interface Props {
  handleReorder: (e: DragEndEvent) => void;
  question: SurveyQuestion;
  onAddOption: () => void;
  children: ReactNode;
  activeQuestionId: string | null;
}

export const OptionsList = ({
  handleReorder,
  question,
  onAddOption,
  children,
  activeQuestionId,
}: Props) => {
  const { colors } = useTheme();

  const isSingleQuestion = useMemo(() => question.type === QUESTION_TYPE.SINGLE, [question.type]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );
  return (
    <Wrapper>
      <DndContext sensors={sensors} onDragEnd={handleReorder}>
        <SortableContext items={question?.options}>{children}</SortableContext>
      </DndContext>
      {activeQuestionId === question.id && (
        <Button
          onClick={onAddOption}
          variant='text'
          style={{
            padding: '0',
            marginLeft: isSingleQuestion ? 0 : '11px',
            width: '80px',
          }}
        >
          <Typography variant='subtitle1' color={colors.accent[50]}>
            + Add Option
          </Typography>
        </Button>
      )}
    </Wrapper>
  );
};
