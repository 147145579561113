import { Button, styled, useTheme } from '@mui/material';
import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-blue-small.svg';
import { CSVLink } from 'react-csv';
import { QuestionRow } from './QuestionsTable';
import {
  KPISValueResponse,
  QUESTION_TYPE,
  QuestionOption,
  QuestionTableData,
} from '../../../types';
import { KPI_VALUE_COLUMN_ID } from '../../../constants/defaultValues';

const Wrapper = styled('div')`
  position: relative;
`;

interface Props {
  data: QuestionRow[];
  surveyName: string;
  showOverrideColumn?: boolean;
  hasEvaluation?: boolean;
}

export const ExportButton: FC<Props> = ({
  data,
  surveyName,
  showOverrideColumn,
  hasEvaluation,
}) => {
  const { colors } = useTheme();

  const csvQuestionsData = useMemo(() => {
    const filteredData = data.filter((row) => row.type !== QUESTION_TYPE.TABLE);

    const cols = ['Question', 'Answer'];

    if (showOverrideColumn) {
      cols.push('Override');
      cols.push('Overridden By');
    }

    if (hasEvaluation) {
      cols.push('Evaluation Score');
    }

    const result = [cols];

    filteredData.forEach((row) => {
      if (row.type === QUESTION_TYPE.SINGLE) {
        const csvCommonRow = [row.question, (row.answer as QuestionOption)?.name ?? ''];

        if (showOverrideColumn) {
          csvCommonRow.push((row.overrideAnswer as QuestionOption)?.name ?? '');
          csvCommonRow.push(row.overriddenBy || '');
        }

        if (hasEvaluation) {
          csvCommonRow.push(
            row.evaluationScore !== undefined && row.evaluationScore !== null
              ? String(row.evaluationScore)
              : ''
          );
        }

        result.push(csvCommonRow);
      } else if (row.type === QUESTION_TYPE.MULTI) {
        const csvCommonRow = [
          row.question,
          (row.answer as QuestionOption[])?.map((option) => option.name).join(', ') ?? '',
        ];

        if (showOverrideColumn) {
          csvCommonRow.push(
            (row.overrideAnswer as QuestionOption[])?.map((option) => option.name).join(', ') ?? ''
          );
          csvCommonRow.push(row.overriddenBy || '');
        }
        if (hasEvaluation) {
          csvCommonRow.push(
            row.evaluationScore !== undefined && row.evaluationScore !== null
              ? String(row.evaluationScore)
              : ''
          );
        }

        result.push(csvCommonRow);
      } else if (row.type === QUESTION_TYPE.YES_NO) {
        result.push([row.question, (row.answer as QuestionOption)?.name ?? '']);
      } else {
        const csvCommonRow = [row.question, (row.answer as string) ?? ''];

        if (showOverrideColumn) {
          csvCommonRow.push((row.overrideAnswer as string) ?? '');
          csvCommonRow.push(row.overriddenBy || '');
        }

        if (hasEvaluation) {
          csvCommonRow.push(
            row.evaluationScore !== undefined && row.evaluationScore !== null
              ? String(row.evaluationScore)
              : ''
          );
        }

        result.push(csvCommonRow);
      }
    });
    return result;
  }, [data, hasEvaluation, showOverrideColumn]);

  const getFileName = (name: string) => {
    return name
      .split(' ')
      .join('-')
      .replace(/[.,/#!$%^&*;:{}=_`~()]/g, '')
      .toLowerCase()
      .trim();
  };

  const fileName = useMemo(() => getFileName(surveyName), [surveyName]);

  const tableTypeQuestionsData = useMemo(() => {
    const filteredData = data.filter((row) => row.type === QUESTION_TYPE.TABLE);
    const result: { fileName: string; data: string[][]; id: string }[] = [];
    filteredData.forEach((row) => {
      const { columns, rows } = (row?.answer as QuestionTableData) || {};
      const csvData: string[][] = [];
      rows?.forEach((row) => {
        const csvRow: string[] = [];
        columns.forEach((column) => {
          if (column === KPI_VALUE_COLUMN_ID) {
            csvRow.push((row[column] as KPISValueResponse)?.value ?? '');
            return;
          }
          csvRow.push((row[column] as string) ?? '');
        });
        csvData.push(csvRow);
      });
      const item = {
        id: row.id,
        fileName: getFileName(row.question),
        data: csvData,
      };
      result.push(item);
    });
    return result;
  }, [data]);

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const button = document.getElementById(`${fileName}-questions`);
      if (button) {
        button.click();
      }
      tableTypeQuestionsData.forEach((item) => {
        const button = document.getElementById(`${item.id}-${item.fileName}`);
        if (button) {
          button.click();
        }
      });
    },
    [fileName, tableTypeQuestionsData]
  );

  return (
    <Wrapper>
      <Button
        variant='text'
        startIcon={<DownloadIcon />}
        style={{ minWidth: 'auto' }}
        onClick={onClick}
      >
        <Typography variant='subtitle2' color={colors.accent[50]}>
          Export as CSV
        </Typography>
      </Button>
      <CSVLink data={csvQuestionsData} filename={fileName}>
        <Button
          variant='text'
          id={`${fileName}-questions`}
          style={{ visibility: 'hidden', width: '0', position: 'absolute' }}
        />
      </CSVLink>
      {tableTypeQuestionsData.map((item) => (
        <CSVLink key={item.fileName} data={item.data} filename={`${item.fileName}.csv`}>
          <Button
            variant='text'
            id={`${item.id}-${item.fileName}`}
            style={{ visibility: 'hidden', width: '0', position: 'absolute' }}
          />
        </CSVLink>
      ))}
    </Wrapper>
  );
};
