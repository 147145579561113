import { styled } from '@mui/material';
import { KpisMatrix } from './KpisMatrix/KpisMatrix';
import { useCoreKpiCategories } from '../../../../queries/useCoreKpiCategories';
import { KpisBarChart } from './KpisBarChart/KpisBarChart';

const Wrapper = styled('div')``;

export const PerformanceTab = () => {
  useCoreKpiCategories();

  return (
    <Wrapper>
      <KpisBarChart />
      <KpisMatrix />
    </Wrapper>
  );
};
