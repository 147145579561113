import { styled } from '@mui/material';
import { useReportById, useUpdateReport } from '../../queries/useReports';
import { useParams } from 'react-router';
import { useAtom } from 'jotai';
import {
  activeQuestionIdState,
  activeReportState,
  activeSectionIdState,
  evaluationState,
  responsesState,
  reviewState,
  sectionsState,
} from '../../state/UIState';
import { useEffect } from 'react';
import { SurveyEditorSkeletonLoader } from '../../components/SkeletonLoader/SurveyEditor.SkeletonLoader';
import { ReviewSideSection } from './ReviewSideSection/ReviewSideSection';
import { ReviewMainContent } from './ReviewMainContent/ReviewMainContent';
import { useDebouncedValue } from '../../hooks/useDebouncedValue';
import { ReviewReportHeader } from './ReviewReportHeader';

const Wrapper = styled('div')`
  display: flex;
  height: 100%;
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Review = () => {
  const params = useParams();

  const { data: report, isLoading } = useReportById(Number(params?.reportId));
  const [activeReport, setActiveReport] = useAtom(activeReportState);
  const [, setSections] = useAtom(sectionsState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [review, setReview] = useAtom(reviewState);
  const [, setResponse] = useAtom(responsesState);
  const updateReport = useUpdateReport();
  const debouncedReview = useDebouncedValue(review, 500);
  const [evaluation, setEvaluation] = useAtom(evaluationState);
  const debouncedEvaluation = useDebouncedValue(evaluation, 500);

  useEffect(() => {
    if (!report) return;
    setActiveReport(report);
    setSections(report?.survey?.sections ?? []);
    setActiveSectionId(report?.survey?.sections[0]?.id ?? null);
    setActiveQuestionId(report?.survey?.sections[0].questions[0]?.id ?? null);
    setReview(report?.review);
    setResponse(report?.response);
    setEvaluation(report.evaluation);

    return () => {
      setActiveReport(null);
      setActiveQuestionId(null);
      setSections([]);
      setActiveSectionId(null);
      setResponse({});
      setEvaluation({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.id]);

  useEffect(() => {
    if (review && activeReport) {
      updateReport.mutate({ id: activeReport.id, review: debouncedReview });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedReview]);

  useEffect(() => {
    if (evaluation && activeReport) {
      updateReport.mutate({ id: activeReport.id, evaluation: debouncedEvaluation });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEvaluation]);

  if (isLoading) return <SurveyEditorSkeletonLoader />;

  return (
    <Container>
      <ReviewReportHeader />
      <Wrapper>
        <ReviewSideSection />
        <ReviewMainContent />
      </Wrapper>
    </Container>
  );
};
