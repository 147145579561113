import { styled, useTheme } from '@mui/material';
import { ACTION_BAR_HEIGHT } from '../../constants/layoutSizes';
import { ReactElement } from 'react';

const ActionsWrapper = styled('div')<{ bgColor: string }>`
  display: flex;
  align-items: center;
  gap: 16px;
  height: ${ACTION_BAR_HEIGHT}px;
  padding-left: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  z-index: 10;
  border-top: 1px solid ${({ theme }) => theme.colors.primary[30]};
`;

interface Props {
  bgColor?: string;
  children: ReactElement;
}

export const ActionButtonsFooter = ({ bgColor, children }: Props) => {
  const { colors } = useTheme();
  return <ActionsWrapper bgColor={bgColor || colors.primary[5]}>{children}</ActionsWrapper>;
};
