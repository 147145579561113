import { useCallback } from 'react';
import { useAtom } from 'jotai';
import {
  activeKpiCategoryState,
  selectedCoreKpiCategoryState,
  selectedCoreKpiState,
} from '../../../state/UIState';
import { useUpdateCoreKpi } from '../../../queries/useCoreKpis';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { getMaxOrderNum } from '../../../utils/getMaxOrderNum';
import { ReactComponent as UndoIcon } from '../../../assets/icons/undo-blue.svg';
import { useOnCancelConfirm } from './useOnCancelConfirm';

export const useMoveSingleKpi = () => {
  const [selectedCategory] = useAtom(selectedCoreKpiCategoryState);
  const [selectedKpi] = useAtom(selectedCoreKpiState);
  const [activeCoreKpiCategory] = useAtom(activeKpiCategoryState);
  const updateCoreKpi = useUpdateCoreKpi();
  const { pushSuccessToast } = useToastMessage();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const { onCancel } = useOnCancelConfirm();

  const moveSingleKpi = useCallback(async () => {
    const maxOrderNum = getMaxOrderNum(selectedCategory?.kpis);

    await updateCoreKpi.mutateAsync(
      {
        id: selectedKpi?.id,
        category: selectedCategory?.id,
        order: (maxOrderNum || 0) + 1,
      },
      {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isCoreKpiSurveyUpToDate: false },
          });
          pushSuccessToast({
            message: `${selectedKpi?.name}  was successfully moved to ${selectedCategory?.name}.`,
            action: () => {
              updateCoreKpi.mutate({
                id: selectedKpi?.id,
                category: activeCoreKpiCategory?.id,
                order: selectedKpi?.order,
              });
            },
            actionLabel: 'Undo',
            endIcon: <UndoIcon />,
          });
        },
      }
    );
    onCancel();
  }, [
    activeCoreKpiCategory?.id,
    appConfigData?.data,
    onCancel,
    pushSuccessToast,
    selectedCategory?.id,
    selectedCategory?.kpis,
    selectedCategory?.name,
    selectedKpi?.id,
    selectedKpi?.name,
    selectedKpi?.order,
    updateAppConfig,
    updateCoreKpi,
  ]);

  return { moveSingleKpi };
};
