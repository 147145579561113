import { Button, Fade, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';
import { ReactComponent as UploadIcon } from '../../../../../assets/icons/upload-blue.svg';
import { ReactComponent as CalendarIcon } from '../../../../../assets/icons/calendar-blue.svg';
import {
  ColumnFiltersState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  CORE_KPI_FORMAT,
  CompanyKpisData,
  CoreKpiRequestFrequency,
  KpisMatrixData,
  SURVEY_FREQUENCY,
  SelectItem,
} from '../../../../../types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCoreKpiCategories } from '../../../../../queries/useCoreKpiCategories';
import { Table } from '../../../../../components/Table/Table';
import { SearchInput } from '../../../../../components/SearchInput/SearchInput';
import { Multiselect } from '../../../../../components/Multiselect/Multiselect';
import { useKpiMatrix } from '../../../hooks/useKpiMatrix';
import { useAtom } from 'jotai';
import {
  activeKpiMatrixTableCellState,
  companyKpiRequestFrequencyState,
  companyKpisDataState,
  initialSelectedKpisMatrixState,
  selectedFrequencyPreviewState,
  selectedKpisMatrixRowsState,
} from '../../../../../state/UIState';
import { KpisMatrixTimeframeModal } from '../../KpisMatrixTimeframeModal';
import { ROUTES } from '../../../../../constants/routes';
import { useNavigate, useParams } from 'react-router';
import { KpiPeriodValueCell } from './KpiPeriodValueCell';
import { KPI_MATRIX_TABLE_ID } from '../../../../../constants/constants';
import { useAppConfig } from '../../../../../queries/useAppConfig';
import { Tooltip } from '../../../../../components/Tooltip/Tooltip';
import { ReactComponent as BarChartIcon } from '../../../../../assets/icons/barChartIcon.svg';
import { useBarChartColor } from '../useBarChartColor';

const Wrapper = styled('div')`
  padding: 0 40px 20px 40px;
`;
const ControlsWrapper = styled('div')`
  margin: 20px 0 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LeftControlGroup = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const RightControlGroup = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const ButtonGroup = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const HeaderCellWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 30px;
`;

const KpiNameWrapper = styled('div')<{ isDisabled?: boolean; borderColor: string }>`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  padding-left: 16px;
  border-left: ${({ borderColor }) => `4px solid ${borderColor}`};
  height: 100%;
`;

const ChartIconWrapper = styled('div')`
  width: 24px;
`;

const columnHelper = createColumnHelper<KpisMatrixData>();

export const KpisMatrix = () => {
  const navigate = useNavigate();
  const { id: companyId } = useParams<{ id: string }>();
  const { colors } = useTheme();
  const [isTimeframeModalOpen, setIsTimeframeModalOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedCategories, setSelectedCategories] = useState<SelectItem[]>([]);
  const [selectedMetrics, setSelectedMetrics] = useState<SelectItem[]>([]);
  const { onUpdateMatrixCell, getTableData } = useKpiMatrix();
  const { data: coreKpiCategories } = useCoreKpiCategories();
  const [companyKpiRequestFrequency] = useAtom(companyKpiRequestFrequencyState);
  const [selectedFrequencyPreview] = useAtom(selectedFrequencyPreviewState);

  const [selectedKpis, setSelectedKpis] = useAtom(selectedKpisMatrixRowsState);
  const [, setInitialSelectedKpis] = useAtom(initialSelectedKpisMatrixState);
  const isKpisSelectionDisabled = selectedKpis?.length === 3;

  const [companyKpisData] = useAtom(companyKpisDataState);

  const getBarChartColor = useBarChartColor();

  const { data: appConfig } = useAppConfig();

  const data = useMemo(() => {
    if (!companyKpisData || !coreKpiCategories || !appConfig?.data?.coreKpiCategoriesOrder?.length)
      return [];
    return getTableData(
      selectedFrequencyPreview,
      companyKpisData,
      coreKpiCategories,
      appConfig?.data?.coreKpiCategoriesOrder
    );
  }, [
    appConfig?.data?.coreKpiCategoriesOrder,
    companyKpisData,
    coreKpiCategories,
    getTableData,
    selectedFrequencyPreview,
  ]);

  useEffect(() => {
    if (!selectedKpis && data?.length) {
      const firstThreeKpis = data
        ?.filter((kpi) => kpi?.format !== CORE_KPI_FORMAT.TEXT)
        ?.map((kpi) => kpi.id)
        ?.slice(0, 3);
      setSelectedKpis(firstThreeKpis);
      setInitialSelectedKpis(firstThreeKpis);
    }
  }, [data, selectedKpis, setInitialSelectedKpis, setSelectedKpis]);

  const [activeCell, setActiveCell] = useAtom(activeKpiMatrixTableCellState);

  const onDataCellChange = useCallback(
    async (value: string, kpiId: number, period: string, format: CORE_KPI_FORMAT) => {
      await onUpdateMatrixCell({
        newValue: format === CORE_KPI_FORMAT.TEXT ? value : Number(value),
        kpiId: kpiId as number,
        period,
        activeFrequency: selectedFrequencyPreview,
        format,
      });
    },
    [onUpdateMatrixCell, selectedFrequencyPreview]
  );

  const onSelectKpis = useCallback(
    (kpiId: number, kpiFormat: CORE_KPI_FORMAT) => {
      if (kpiFormat === CORE_KPI_FORMAT.TEXT) return;
      setSelectedKpis((prev) => {
        if (prev?.includes(kpiId)) {
          return prev?.filter((prevKpiId) => prevKpiId !== kpiId);
        }
        if (isKpisSelectionDisabled) return prev;
        return [...(prev || []), kpiId];
      });
    },
    [isKpisSelectionDisabled, setSelectedKpis]
  );

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <KpiNameWrapper
              isDisabled={
                (isKpisSelectionDisabled && !selectedKpis?.includes(info.row.original.id)) ||
                info.row.original.format === CORE_KPI_FORMAT.TEXT
              }
              onClick={() => onSelectKpis(info.row.original.id, info.row.original.format)}
              borderColor={getBarChartColor(selectedKpis?.indexOf(info.row.original.id))}
            >
              <ChartIconWrapper>
                <BarChartIcon />
              </ChartIconWrapper>
              <Typography variant='subtitle2' color={colors.primary[90]}>
                {info.getValue()}
              </Typography>
            </KpiNameWrapper>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            KPI
          </Typography>
        ),
        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          cellInnerDivHeight: '100%',
          tdStyles: {
            position: 'sticky',
            left: 0,
            zIndex: 1,
            paddingLeft: 0,
            height: '100%',
          },
          thStyles: {
            position: 'sticky',
            left: 0,
            zIndex: 1,
          },
        },
        enableSorting: true,
      }),
      columnHelper.accessor('categoryName', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <Typography variant='body' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Category
          </Typography>
        ),
        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 140,
            zIndex: 1,
          },
          thStyles: {
            position: 'sticky',
            left: 140,
            zIndex: 1,
          },
        },
        filterFn: (row: any, columnId, selectedCategories) => {
          const value: string = row.getValue(columnId) || '';
          return selectedCategories.includes(value);
        },
        enableColumnFilter: true,
        enableSorting: true,
      }),
      columnHelper.accessor('description', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <Typography variant='body' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Definition
          </Typography>
        ),
        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 280,
            zIndex: 1,
          },
          thStyles: {
            position: 'sticky',
            left: 280,
            zIndex: 1,
          },
        },
        enableSorting: true,
      }),
      columnHelper.accessor('metric', {
        cell: (info) => (
          <Tooltip
            title={info.getValue()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <Typography variant='body' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </Tooltip>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Metric
          </Typography>
        ),
        meta: {
          width: '140px',
          maxWidth: '140px',
          minWidth: '140px',
          tdStyles: {
            position: 'sticky',
            left: 420,
            borderRight: `1px solid ${colors.primary[30]}`,
            paddingRight: '16px',
            zIndex: 1,
          },
          thStyles: {
            position: 'sticky',
            left: 420,
            borderRight: `1px solid ${colors.primary[30]}`,
            paddingRight: '16px',
            zIndex: 1,
          },
        },
        filterFn: (row: any, columnId, selectedCategories) => {
          const value: string = row.getValue(columnId) || '';
          return selectedCategories.includes(value);
        },
        enableColumnFilter: true,
        enableSorting: true,
      }),
    ];

    if (
      !companyKpisData ||
      !companyKpisData?.[companyKpiRequestFrequency as CoreKpiRequestFrequency]?.length ||
      !coreKpiCategories
    )
      return cols;

    const periodCols = companyKpisData[selectedFrequencyPreview]
      .map((item) => item.period)
      .flatMap((period, i, arr) =>
        columnHelper.accessor(period.toString(), {
          // eslint-disable-next-line react/display-name
          cell: (info) => {
            return (
              <KpiPeriodValueCell
                isActive={activeCell === `${period}-${info.row.original.id}`}
                info={info}
                onDataCellChange={onDataCellChange}
                period={period}
                setActiveCell={setActiveCell}
                companyKpiRequestFrequency={companyKpiRequestFrequency}
                selectedFreqiuencyPreview={selectedFrequencyPreview}
              />
            );
          },
          header: () => (
            <HeaderCellWrapper>
              <Typography variant='overline' color={colors.primary[70]}>
                {period}
              </Typography>
            </HeaderCellWrapper>
          ),
          meta: {
            width: i === arr.length - 1 ? '100%' : '140px',
            maxWidth: i === arr.length - 1 ? 'none' : '140px',
            minWidth: '140px',
            alignHeader: 'right',
            headerWrapperWidth: '124px',
          },
          enableSorting: true,
        })
      );
    return [...cols, ...periodCols];
  }, [
    activeCell,
    colors.primary,
    companyKpiRequestFrequency,
    companyKpisData,
    coreKpiCategories,
    getBarChartColor,
    isKpisSelectionDisabled,
    onDataCellChange,
    onSelectKpis,
    selectedFrequencyPreview,
    selectedKpis,
    setActiveCell,
  ]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const sortedUniqueCategories = useMemo(() => {
    const allValues = Array.from(
      table.getColumn('categoryName')?.getFacetedUniqueValues().keys() ?? []
    );
    const unique = allValues.sort().map((value, id) => ({ id, value })) ?? [];
    setSelectedCategories(unique);
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, data]);

  const onChangeCategoriesFilter = useCallback(
    (categories: SelectItem[]) => {
      setSelectedCategories(categories);
      table.getColumn('categoryName')?.setFilterValue(categories.map((c) => c.value));
    },
    [table]
  );

  const sortedUniqueMetrics = useMemo(() => {
    const allValues = Array.from(table.getColumn('metric')?.getFacetedUniqueValues().keys() ?? []);
    const unique = allValues.sort().map((value, id) => ({ id, value })) ?? [];
    setSelectedMetrics(unique);
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, data]);

  const onChangeMetricsFilter = useCallback(
    (metrics: SelectItem[]) => {
      setSelectedMetrics(metrics);
      table.getColumn('metric')?.setFilterValue(metrics.map((c) => c.value));
    },
    [table]
  );

  return (
    <Wrapper>
      <ControlsWrapper>
        <LeftControlGroup>
          <SearchInput
            placeholder='Search for a KPI'
            style={{ width: '320px' }}
            onClear={() => table.getColumn('name')?.setFilterValue('')}
            onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
            value={table.getColumn('name')?.getFilterValue() ?? ''}
          />
          <Multiselect
            style={{ width: '206px' }}
            options={sortedUniqueCategories}
            value={selectedCategories}
            onChange={(_, val) => {
              onChangeCategoriesFilter(val as SelectItem[]);
            }}
            disablePortal
            optionName='Category'
            optionsName='Categories'
            fieldPlaceholder='Filter by Category'
            labelTypographyStyle={{ maxWidth: '85px' }}
            sx={{
              '.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
                paddingRight: '50px',
              },
            }}
          />
          <Multiselect
            style={{ width: '206px' }}
            options={sortedUniqueMetrics}
            value={selectedMetrics}
            onChange={(_, val) => {
              onChangeMetricsFilter(val as SelectItem[]);
            }}
            disablePortal
            optionName='Metric'
            fieldPlaceholder='Filter by Metric'
            labelTypographyStyle={{ maxWidth: '85px' }}
            sx={{
              '.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
                paddingRight: '50px',
              },
            }}
          />
        </LeftControlGroup>
        <RightControlGroup>
          <ButtonGroup>
            <Button
              startIcon={<CalendarIcon />}
              variant='outlined'
              color='primary'
              style={{ height: '32px' }}
              onClick={() => setIsTimeframeModalOpen(true)}
            >
              Manage Timeframe
            </Button>
            <Button
              onClick={() => navigate(`/${ROUTES.UPLOAD_COMPANY_KPIS}/${companyId}/1`)}
              startIcon={<UploadIcon />}
              variant='outlined'
              color='primary'
              style={{ height: '32px' }}
            >
              Upload Data
            </Button>
          </ButtonGroup>
        </RightControlGroup>
      </ControlsWrapper>
      <Table
        table={table}
        tableStyle={{ width: 'auto', minWidth: 'auto', height: '100%' }}
        tdStyle={{ paddingLeft: '16px', paddingRight: '0' }}
        thStyle={{ paddingLeft: '16px', paddingRight: '0' }}
        disableRowHover
        disableAnimation
        disableLayoutAnimation
        tableId={KPI_MATRIX_TABLE_ID}
        height='calc(100vh - 374px)'
        getTrStyle={(row) => {
          const isRowSelected = selectedKpis?.includes(row.original.id);

          return {
            opacity: isRowSelected || !isKpisSelectionDisabled ? 1 : 0.2,
            backgroundColor: isRowSelected ? colors.accent[10] : '#FFF',
          };
        }}
      />

      <KpisMatrixTimeframeModal
        onClose={() => setIsTimeframeModalOpen(false)}
        startYear={(companyKpisData as CompanyKpisData)?.[SURVEY_FREQUENCY.ANNUALLY]?.[0]?.period}
        endYear={
          companyKpisData?.[SURVEY_FREQUENCY.ANNUALLY]?.[
            companyKpisData?.[SURVEY_FREQUENCY.ANNUALLY]?.length - 1
          ]?.period || ''
        }
        isOpen={isTimeframeModalOpen}
      />
    </Wrapper>
  );
};
