import { QuestionOption, Response, SurveyQuestion } from '../../../../../../../types';
import { OptionListWrapper } from '../common';
import { RadioOption } from './RadioOption';

interface Props {
  question: SurveyQuestion;
  responses: Record<string, Response>;
}

export const SingleQuestion = ({ question, responses }: Props) => {
  return (
    <OptionListWrapper>
      {question.options.map((option) => {
        const selectedOption =
          (responses[question.id] as QuestionOption)?.id === option.id
            ? responses[question.id]
            : null;
        return (
          <RadioOption
            key={option.id}
            isChecked={(responses[question.id] as QuestionOption)?.id === option.id}
            selectedOption={selectedOption as QuestionOption}
            option={option}
          />
        );
      })}
    </OptionListWrapper>
  );
};
