import { styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';

import { QUESTION_TYPE, QuestionOption, Response } from '../../../types';
import { AnswerOption } from './AnswerOption';
import { AnswerText } from './AnswerText';

interface Props {
  answer: Response;
  type: QUESTION_TYPE;
  isOverridden?: boolean;
  isOverrideAnswer?: boolean;
  overrideReason?: string;
}

const OptionsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const AnswerCell = ({
  answer,
  type,
  isOverridden,
  isOverrideAnswer,
  overrideReason,
}: Props) => {
  const { colors } = useTheme();
  if (!answer) return null;

  if (type === QUESTION_TYPE.SINGLE)
    return (
      <AnswerOption
        text={(answer as QuestionOption).name}
        isFlagged={(answer as QuestionOption).isFlagged}
        notes={(answer as QuestionOption).notes}
        answerId={(answer as QuestionOption).id}
        isOverridden={isOverridden}
        isOverrideAnswer={isOverrideAnswer}
        overrideReason={overrideReason}
      />
    );
  if (type === QUESTION_TYPE.MULTI)
    return (
      <OptionsWrapper>
        {(answer as QuestionOption[]).map((option) => (
          <AnswerOption
            key={option.id}
            text={option.name}
            isFlagged={option.isFlagged}
            notes={option.notes}
            answerId={option.id}
            isOverridden={isOverridden}
            isOverrideAnswer={isOverrideAnswer}
            overrideReason={overrideReason}
          />
        ))}
      </OptionsWrapper>
    );
  if (type === QUESTION_TYPE.TEXT)
    return (
      <AnswerText
        answer={answer as string}
        isOverridden={isOverridden}
        isOverrideAnswer={isOverrideAnswer}
        overrideReason={overrideReason}
      />
    );
  if (type === QUESTION_TYPE.YES_NO) {
    return (
      <Typography variant='body' color={colors.primary[90]}>
        {(answer as QuestionOption).name}
      </Typography>
    );
  }
  return null;
};
