import { styled } from '@mui/material';
import { ChecklistHeader } from './components/ChecklistHeader/ChecklistHeader';
import { MainContent } from './components/MainContent';
import { DataCollectionContent } from '../../components/DataCollectionLayout';
import { useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import {
  isConfirmChecklistBarActiveState,
  isMoveChecklistModeActiveState,
  selectedChecklistCategoryState,
  selectedChecklistItemState,
  selectedChecklistItemsState,
} from '../../state/UIState';
import { useAppConfig } from '../../queries/useAppConfig';
import { useConfirmBarActions } from './hooks/useConfirmBarActions';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Checklist = () => {
  const [isActive, setIsActive] = useAtom(isConfirmChecklistBarActiveState);
  const [isMoveModeActive] = useAtom(isMoveChecklistModeActiveState);
  const [selectedCategory] = useAtom(selectedChecklistCategoryState);
  const [selectedItem] = useAtom(selectedChecklistItemState);
  const [selectedItems] = useAtom(selectedChecklistItemsState);

  const { data: appConfigData } = useAppConfig();

  const { onCancel, onConfirm, onUpdateChecklistSurveys } = useConfirmBarActions();

  useEffect(() => {
    if (!appConfigData?.data) return;
    setIsActive(!appConfigData.data.areCheckListSurveysUpToDate);
  }, [appConfigData, setIsActive]);

  const confirmActionBarText = useMemo(() => {
    if (isMoveModeActive && selectedItem && selectedCategory)
      return `Move this item to ${selectedCategory?.name}`;
    if (isMoveModeActive && selectedItem) return `Select Category to move this item`;
    if (isMoveModeActive && selectedItems.length >= 1 && selectedCategory)
      return `Move these items to ${selectedCategory?.name}`;
    if (isMoveModeActive && selectedItems.length >= 1) return `Select Category to move these items`;
    if (isActive && !appConfigData?.data.areCheckListSurveysUpToDate)
      return 'Update the surveys to reflect changes in checklist items for all active requests.';
    return '';
  }, [
    appConfigData?.data.areCheckListSurveysUpToDate,
    isActive,
    isMoveModeActive,
    selectedCategory,
    selectedItem,
    selectedItems.length,
  ]);

  return (
    <Wrapper>
      <ChecklistHeader />
      <DataCollectionContent
        text={confirmActionBarText}
        confirmText={isMoveModeActive ? 'Move' : 'Update'}
        onCancel={isMoveModeActive ? onCancel : undefined}
        onConfirm={isMoveModeActive ? onConfirm : onUpdateChecklistSurveys}
        isActive={isActive}
        isConfirmDisabled={isMoveModeActive && !selectedCategory}
      >
        <MainContent />
      </DataCollectionContent>
    </Wrapper>
  );
};
