import { styled, useTheme } from '@mui/material';
import { CHECKLIST_OPTION, SelectItem } from '../../../../../../../../../types';
import { Radio } from '../../../../../../../../../components/Radio/Radio';
import { Typography } from '../../../../../../../../../components/Typography/Typography';

const OptionWrapper = styled('div')`
  display: flex;
  gap: 4px;
  cursor: pointer;
`;

const goalValuesOptions: SelectItem[] = [
  { value: CHECKLIST_OPTION.YES, id: CHECKLIST_OPTION.YES },
  { value: CHECKLIST_OPTION.NO, id: CHECKLIST_OPTION.NO },
  { value: CHECKLIST_OPTION.NA, id: CHECKLIST_OPTION.NA },
];

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  conditionValue: string;
}

export const ConditionValueOptions = ({ setFieldValue, conditionValue }: Props) => {
  const { colors } = useTheme();

  return (
    <>
      {goalValuesOptions.map((option) => {
        const isChecked = option.value === conditionValue;

        return (
          <OptionWrapper
            key={option.id}
            onClick={() => setFieldValue('conditionValue', option.value)}
          >
            <Radio
              checked={isChecked}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                },
              }}
            />
            <Typography variant='body' color={colors.primary[90]}>
              {option.value}
            </Typography>
          </OptionWrapper>
        );
      })}
    </>
  );
};
