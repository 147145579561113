import { Report } from '../types';

export const getAvgEvaluationScore = (report?: Report) => {
  const evaluation = report?.evaluation;

  if (!evaluation) return null;

  const evaluationScores = Object.values(evaluation)?.map(
    (questionEvaluation) => questionEvaluation.weight || 0
  );

  if (!evaluationScores?.length) return null;

  const avg = evaluationScores.reduce((a, b) => a + b) / evaluationScores.length;
  return Math.round(avg);
};
