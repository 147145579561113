import { Dispatch, SetStateAction } from 'react';
import { EditableNumericCell } from '../../../../../../../../../components/EditableNumericCell/EditableNumericCell';
import { CORE_KPI_FORMAT } from '../../../../../../../../../types';
import { EditableTextCell } from '../../../../../../../../../components/EditableTextCell/EditableTextCell';

interface Props {
  value: string;
  onValueChange: (value: string) => void;
  format: CORE_KPI_FORMAT;
  inputValue: string;
  setShowInput: Dispatch<SetStateAction<boolean>>;
  setInputValue: Dispatch<SetStateAction<string>>;
  withBorder?: boolean;
}

export const CoreKpiInput = ({
  value,
  format,
  onValueChange,
  setShowInput,
  inputValue,
  setInputValue,
  withBorder,
}: Props) => {
  if (format === CORE_KPI_FORMAT.TEXT) {
    return (
      <EditableTextCell
        value={value}
        onValueChanged={onValueChange}
        autofocus
        style={{ height: '30px' }}
        onBlur={() => setShowInput(false)}
        inputValue={inputValue}
        setInputValue={setInputValue}
        maxLength={32}
        withBorder={withBorder}
        placeholder='Enter value'
      />
    );
  }

  return (
    <EditableNumericCell
      value={value}
      onValueChanged={onValueChange}
      inputPadding='0 4px'
      format={format}
      inputStyles={{ paddingRight: '20px' }}
      autofocus
      style={{ height: '30px' }}
      inputValue={inputValue}
      setInputValue={setInputValue}
      onBlur={() => setShowInput(false)}
      withBorder={withBorder}
      placeholder='Enter value'
    />
  );
};
