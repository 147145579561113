import { styled, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { Typography } from '../Typography/Typography';

const TextareaElement = styled('textarea')`
  width: 100%;
  border: none;
  resize: none;
  background-color: transparent;
  outline: 0px none transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-family: ${({ theme }) => theme.text.font.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.primary[90]};
  border: 1px solid ${({ theme }) => theme.colors.primary[30]};
  padding: 4px 14px;
  border-radius: 4px;
  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    font-family: ${({ theme }) => theme.text.font.fontFamilyRegular};
    color: ${({ theme }) => theme.colors.primary[70]};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.accent[50]};
  }
`;

export type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  maxLength?: number;
};

const Wrapper = styled('div')`
  position: relative;
`;

export const Textarea: FC<Props> = ({ maxLength, onChange, value, ...rest }) => {
  const { colors } = useTheme();
  const [textAreaValue, setTextAreaValue] = useState<string>((value as string) || '');
  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (maxLength && event.target.value.length > maxLength) {
        event.target.value = event.target.value.substr(0, maxLength);
      }
      setTextAreaValue(event.target.value);
      onChange && onChange(event);
    },
    [maxLength, onChange]
  );

  const limitLabel = maxLength ? `${textAreaValue.length}/${maxLength}` : '';

  return (
    <Wrapper>
      {!!maxLength && (
        <Typography
          variant='srOnly'
          color={colors.primary[90]}
          style={{ position: 'absolute', right: 0, top: '-20px' }}
        >
          {limitLabel}
        </Typography>
      )}
      <TextareaElement value={textAreaValue} onChange={onChangeHandler} {...rest} />
    </Wrapper>
  );
};
