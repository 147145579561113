import { REQUEST_STEP, WorkflowStep } from '../../../types';
import { ChecklistQuarterlyStep } from './ChecklistQuarterlyStep';
import { RecurringKpiStep } from './RecurringKpiStep/RecurringKpiStep';
import { YearlyAssessment } from './YearlyAssessment';

interface Props {
  step: WorkflowStep;
  hasAccess?: boolean;
  isBlocked?: boolean;
  isStepCompleted?: boolean;
}

export const RequestStep = ({ step, hasAccess, isBlocked, isStepCompleted }: Props) => {
  if (step.requestStep === REQUEST_STEP.RECURRING_KPI_UPDATE) {
    return (
      <RecurringKpiStep
        hasAccess={hasAccess}
        stepId={step?.id}
        isBlocked={isBlocked}
        isStepCompleted={isStepCompleted}
      />
    );
  }

  if (step.requestStep === REQUEST_STEP.CHECKLIST_QUARTERLY) {
    return (
      <ChecklistQuarterlyStep
        hasAccess={hasAccess}
        stepId={step?.id}
        isBlocked={isBlocked}
        isStepCompleted={isStepCompleted}
      />
    );
  }

  if (step.requestStep === REQUEST_STEP.YEARLY_ASSESSMENT) {
    return (
      <YearlyAssessment
        hasAccess={hasAccess}
        stepId={step?.id}
        isBlocked={isBlocked}
        isStepCompleted={isStepCompleted}
      />
    );
  }
  return <></>;
};
