import { TextField, styled } from '@mui/material';
import { Modal } from '../../../../../../../components/Modal/Modal';
import { FormField } from '../../../../../../../components/FormField/FormField';
import { Objective, SURVEY_FREQUENCY } from '../../../../../../../types';
import { ChipsSelectorV2 } from '../../../../../../../components/ButtonSelector/ChipsSelectorV2';
import { useFormik } from 'formik';
import { ObjectivePayload } from '../../../../../../../queries/useObjectives';
import { ObjectiveSchema as validationSchema } from './ObjectiveSchema';
import { useCallback, useMemo } from 'react';

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 132px;
`;

const frequencyOptions = [
  { id: SURVEY_FREQUENCY.QUARTERLY, value: 'Quarterly' },
  { id: SURVEY_FREQUENCY.ANNUALLY, value: 'Annually' },
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  objective?: Objective | null;
  onSubmit: (payload: Partial<Objective>) => void;
  title: string;
  confirmBtnText: string;
}

export const AddEditObjectiveModal = ({
  isOpen,
  onClose,
  title,
  confirmBtnText,
  objective,
  onSubmit,
}: Props) => {
  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    dirty,
  } = useFormik<Partial<ObjectivePayload>>({
    initialValues: {
      name: objective?.name ?? '',
      frequency: objective?.frequency ?? SURVEY_FREQUENCY.QUARTERLY,
    },
    validationSchema,
    onSubmit: (values) => {
      onClose();
      onSubmit(values);
    },
  });

  const selectedFrequency = useMemo(
    () => frequencyOptions.find((o) => o.id === values.frequency),
    [values.frequency]
  );

  const onChangeFrequency = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('frequency', '');
        return;
      }
      setFieldValue('frequency', option?.id);
    },
    [setFieldValue]
  );

  const handleError = (field: keyof ObjectivePayload) => (touched[field] ? errors[field] : '');

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      confirmBtnText={confirmBtnText}
      onClose={onClose}
      onConfirm={handleSubmit}
      isConfirmDisabled={!isValid || !dirty}
    >
      <FormWrapper>
        <FormField label='Name' error={handleError('name')} required>
          <TextField
            name='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant='outlined'
          />
        </FormField>
        <FormField label='Request progress updates' error={handleError('frequency')} required>
          <ChipsSelectorV2
            options={frequencyOptions}
            selectedItem={selectedFrequency || frequencyOptions[0]}
            onSelect={onChangeFrequency}
          />
        </FormField>
      </FormWrapper>
    </Modal>
  );
};
