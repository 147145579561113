import { useMemo } from 'react';
import { HEADER_HEIGHT } from '../../constants/layoutSizes';
import { CompaniesPageContent } from './components/CompaniesPageContent';
import { useIssuersPendingEvaluation } from '../../hooks/useIssuersPendingEvaluation';

export const PendingEvaluation = () => {
  const { isLoading, companiesData } = useIssuersPendingEvaluation();

  const companiesNum = useMemo(() => {
    return companiesData?.length || 0;
  }, [companiesData?.length]);

  return (
    <CompaniesPageContent
      title='Pending Evaluation'
      headerHeight={HEADER_HEIGHT}
      companiesData={companiesData}
      isLoading={isLoading}
      companiesNum={companiesNum}
      showCompaniesNum
    />
  );
};
