import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useCompanyById, useUpdateCompany } from '../../../queries/useCompanies';
import { Report, SURVEY_FREQUENCY, Survey } from '../../../types';
import { useCreateRequest } from '../../../queries/useRequests';
import { useQueryClient } from 'react-query';
import { ROUTES } from '../../../constants/routes';
import { REPORTS } from '../../../queries/useReports';
import { SURVEYS } from '../../../queries/useSurveys';

export const useInvestorExclusionClick = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const updateCompany = useUpdateCompany();
  const createRequest = useCreateRequest();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onInvestorExclusionsScreeningClickHandler = useCallback(
    async (investorExclusionScreeningReport?: Report, exlusionScreeningSurvey?: Survey) => {
      if (!company || !exlusionScreeningSurvey) return;

      if (!company?.exclusionScreeningSurvey || !investorExclusionScreeningReport) {
        await updateCompany.mutateAsync({
          id: company.id,
          exclusionScreeningSurvey: exlusionScreeningSurvey.id,
        });

        await createRequest.mutate(
          {
            companyId: company.id,
            frequency: SURVEY_FREQUENCY.ONE_TIME,
            surveyId: exlusionScreeningSurvey.id,
            name: exlusionScreeningSurvey.name,
          },
          {
            onSuccess: (request) => {
              navigate(`/${ROUTES.QUESTIONNAIRES}/${request.reports[0].id}/progress`);
              queryClient.refetchQueries(`${REPORTS}-${request.company.id}`);
              queryClient.refetchQueries(SURVEYS);
            },
          }
        );
        return;
      }
    },
    [company, createRequest, navigate, queryClient, updateCompany]
  );

  return { onInvestorExclusionsScreeningClickHandler };
};
