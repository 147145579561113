import { styled } from '@mui/material';
import { AnalystRatingReviewOption } from './AnalystRatingReviewOption/AnalystRatingReviewOption';

import { useReviewOptionsConfig } from '../useReviewOptionsConfig';
import { useIsAnalystRatingReviewed } from '../useIsAnalystRatingReviewed';

const ReviewContainer = styled('div')`
  width: 57%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AnalystRatingReview = () => {
  const reviewOptionsConfig = useReviewOptionsConfig();

  const isReviewed = useIsAnalystRatingReviewed();

  return (
    <ReviewContainer>
      {reviewOptionsConfig?.map((reviewOptionConfig) => {
        return (
          <AnalystRatingReviewOption
            reviewOptionConfig={reviewOptionConfig}
            key={reviewOptionConfig.type}
            isEvaluated={isReviewed}
          />
        );
      })}
    </ReviewContainer>
  );
};
