import { Dispatch, SetStateAction, useCallback } from 'react';
import { ConfirmDeleteModal } from '../../../../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { useParams } from 'react-router';
import { useDeleteKeyTask } from '../../../../../../../queries/useKeyTasks';
import { KeyTask } from '../../../../../../../types';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  activeKeyTask: KeyTask | null;
  setActiveKeyTask: Dispatch<SetStateAction<KeyTask | null>>;
}

export const DeleteKeyTask = ({
  isModalOpen,
  setIsModalOpen,
  activeKeyTask,
  setActiveKeyTask,
}: Props) => {
  const params = useParams();

  const deleteKeyTask = useDeleteKeyTask(Number(params.id));

  const onDeleteKeyTask = useCallback(() => {
    if (!activeKeyTask?.id) return;

    setIsModalOpen(false);
    deleteKeyTask.mutate(activeKeyTask.id);
    setActiveKeyTask(null);
  }, [activeKeyTask?.id, deleteKeyTask, setActiveKeyTask, setIsModalOpen]);

  return (
    <ConfirmDeleteModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title='Delete the key task?'
      onConfirm={onDeleteKeyTask}
      note='This will permanently delete all progress related to this key task.'
      confirmLabel='Yes, delete'
      cancelLabel='No, cancel'
    />
  );
};
