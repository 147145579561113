import { TextField, useTheme } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { Typography } from '../Typography/Typography';
import { Modal } from '../Modal/Modal';

interface Props {
  title: string;
  message: string;
  value: string;
  onConfirm: (value: string) => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  placeholder?: string;
  hideCancel?: boolean;
  isOpen: boolean;
}

export const PromptModal: FC<Props> = ({
  title,
  message,
  onConfirm,
  onCancel,
  value,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  placeholder = 'Enter value',
  hideCancel,
  isOpen,
}) => {
  const { colors } = useTheme();
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
    setInputValue(value);
  }, [value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onCheckEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onConfirm(inputValue);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={onCancel}
      cancelBtnText={cancelText}
      confirmBtnText={confirmText}
      onConfirm={() => onConfirm(inputValue)}
      isConfirmDisabled={!inputValue}
      hideCancel={hideCancel}
    >
      <Typography variant='caption' color={colors.primary[90]} style={{ paddingBottom: '10px' }}>
        {message}
      </Typography>
      <TextField
        value={inputValue}
        onChange={onChange}
        onKeyDown={onCheckEnter}
        placeholder={placeholder}
        style={{ width: '100%' }}
        inputRef={inputRef}
      />
    </Modal>
  );
};
