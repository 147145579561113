import * as yup from 'yup';
import { requiredMsg } from '../../../../../../../../utils/validationMessages';
import { KEY_TASK_GOAL_TYPE } from '../../../../../../../../types';

export const KeyTaskKPISchema = yup.object().shape({
  goalType: yup.string().required(requiredMsg('Goal Type')),
  conditionValue: yup.string().required(requiredMsg('Goal Value')),
  conditionOperator: yup.string().required(requiredMsg('Goal Value')),
  kpi: yup.string().when('goalType', {
    is: KEY_TASK_GOAL_TYPE.KPI,
    then: (schema) => schema.required(requiredMsg('KPI')),
    otherwise: (schema) => schema.notRequired(),
  }),
  checklistItem: yup.string().when('goalType', {
    is: KEY_TASK_GOAL_TYPE.CHECKLIST,
    then: (schema) => schema.required(requiredMsg('Checklist item')),
    otherwise: (schema) => schema.notRequired(),
  }),
});
