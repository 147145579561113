import { Chip, styled, useTheme } from '@mui/material';
import { COMPANY_STAGE } from '../../../types';
import { ReactComponent as TargetIcon } from '../../../assets/icons/target-yellow.svg';
import { ReactComponent as EsgCompleteIcon } from '../../../assets/icons/checkbox.svg';
import { ReactElement, useCallback, useMemo } from 'react';
import { Typography } from '../../Typography/Typography';

interface Props {
  stage: COMPANY_STAGE;
  chipStyle?: React.CSSProperties;
}

const Wrapper = styled('div')`
  display: flex;
  gap: 4px;
`;

export const CompanyStageCell = ({ stage, chipStyle }: Props) => {
  const { colors } = useTheme();

  const renderIcon = useCallback(() => {
    switch (stage) {
      case COMPANY_STAGE.SCREENING:
        return <TargetIcon style={{ marginRight: '4px' }} />;
      case COMPANY_STAGE.ESG_COMPLETE:
        return <EsgCompleteIcon style={{ marginRight: '4px' }} />;
      default:
        return null;
    }
  }, [stage]);

  const bgColor = useMemo(() => {
    switch (stage) {
      case COMPANY_STAGE.SCREENING:
        return colors.warning[5];
      case COMPANY_STAGE.ESG_COMPLETE:
        return colors.success[5];
    }
  }, [colors, stage]);
  return (
    <Wrapper>
      <Chip
        icon={renderIcon() as ReactElement}
        style={{
          backgroundColor: bgColor,
          width: '130px',
          ...chipStyle,
        }}
        label={
          <Typography variant='body' color={colors.primary[90]}>
            {stage}
          </Typography>
        }
      />
    </Wrapper>
  );
};
