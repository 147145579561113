import { useAtom } from 'jotai';
import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { SurveySection } from '../../../types';
import {
  activeQuestionIdState,
  activeSectionIdState,
  sectionReviewedStatsState,
  sectionState,
} from '../../../state/UIState';
import { IconButton, styled, useTheme } from '@mui/material';
import { CircularProgress } from '../../../components/CircularProgress/CircularProgress';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';

const Wrapper = styled('div')<{ isActive: boolean; size: 'sm' | 'lg' }>`
  height: ${({ size }) => (size === 'sm' ? 30 : 40)}px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  ${({ isActive, theme }) => isActive && `background-color: ${theme.colors.accent[5]};`}
  cursor: pointer;
`;

const TitleWrapper = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: hidden;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const RightSectionWrapper = styled('div')`
  display: flex;
  align-items: center;
  min-width: 60px;
  justify-content: flex-end;
`;
const SubsectionCircle = styled('div')`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.primary[30]};
  background-color: ${({ theme }) => theme.colors.primary[30]};
`;
const SubsectionCircleWrapper = styled('div')`
  width: 16px;
  display: flex;
  justify-content: center;
`;
const UpLine = styled('div')<{ size: 'sm' | 'lg' }>`
  position: absolute;
  top: 0;
  left: 31px;
  width: 2px;
  height: ${({ size }) => (size === 'sm' ? 10 : 13)}px;
  background-color: ${({ theme }) => theme.colors.primary[30]};
`;
const DownLine = styled('div')<{ size: 'sm' | 'lg' }>`
  position: absolute;
  bottom: 0;
  left: 31px;
  width: 2px;
  height: ${({ size }) => (size === 'sm' ? 10 : 13)}px;
  background-color: ${({ theme }) => theme.colors.primary[30]};
`;

type Props = {
  section: SurveySection;
  isLastSubsection?: boolean;
};

export const ReviewSectionItem: FC<Props> = ({ section, isLastSubsection }) => {
  const [activeSectionId, setActiveSectionId] = useAtom(activeSectionIdState);
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [, setSection] = useAtom(sectionState(section.id));
  const [sectionReviewedStats] = useAtom(sectionReviewedStatsState(section.id));

  const { colors } = useTheme();

  const { percentage, completed, allQuestions } = sectionReviewedStats;

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => setWidth(percentage), 200);
  }, [percentage]);

  const onClick = useCallback(() => {
    setActiveSectionId(section.id);
    section.questions.length && setActiveQuestionId(section.questions[0].id);
  }, [section.id, section.questions, setActiveQuestionId, setActiveSectionId]);

  const onExpandCollapseSectionClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      const updatedSection = { ...section, collapsed: !section.collapsed };
      setSection(updatedSection);
    },
    [section, setSection]
  );

  const isActive = section.id === activeSectionId;

  const hasTopLine = useMemo(() => {
    if (section.depth === 0) return false;
    return true;
  }, [section]);

  const hasBottomLine = useMemo(() => {
    if (section.depth === 0 && section.collapsed) return false;
    if (section.depth === 0 && !section.subSections.length) return false;
    if (section.depth > 0 && isLastSubsection) return false;
    return true;
  }, [isLastSubsection, section.collapsed, section.depth, section.subSections]);

  return (
    <Wrapper
      key={section.id}
      isActive={isActive}
      size={section.depth === 0 ? 'lg' : 'sm'}
      onClick={onClick}
    >
      {hasTopLine && <UpLine size={section.depth === 0 ? 'lg' : 'sm'} />}
      {hasBottomLine && <DownLine size={section.depth === 0 ? 'lg' : 'sm'} />}
      <TitleWrapper>
        {section.depth === 0 ? (
          <CircularProgress
            value={width}
            size={16}
            thickness={6}
            wrapperStyle={{ marginTop: '-2px' }}
          />
        ) : (
          <SubsectionCircleWrapper>
            <SubsectionCircle />
          </SubsectionCircleWrapper>
        )}
        <Typography
          variant={section.depth === 0 ? 'body' : 'caption'}
          color={section.depth === 0 ? colors.primary[90] : colors.primary[70]}
        >
          {section.name}
        </Typography>
      </TitleWrapper>
      <RightSectionWrapper>
        {section?.depth === 0 && (
          <Typography variant='numbers' color={colors.primary[70]} style={{ width: '100%' }}>
            {completed}/{allQuestions}
          </Typography>
        )}
        {Boolean(section.subSections.length > 0 && section?.depth === 0) && (
          <IconButton
            onClick={onExpandCollapseSectionClick}
            style={{ width: '30px', height: '30px' }}
          >
            {!section.collapsed ? <ArrowDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        )}
      </RightSectionWrapper>
    </Wrapper>
  );
};
