import { FormField } from '../../../../../../../components/FormField/FormField';
import { SurveyQuestion, TReviewOptionConfig } from '../../../../../../../types';
import { styled } from '@mui/material';
import { OverrideAnswerContent } from './OverrideAnswerContent/OverrideAnswerContent';

const ContentContainer = styled('div')`
  margin-top: 4px;
`;

interface Props {
  question: SurveyQuestion;
  reviewOption: TReviewOptionConfig;
}

export const OverrideAnswerField = ({ question, reviewOption }: Props) => {
  return (
    <FormField label={'Your Answer'} required style={{ marginBottom: '16px' }}>
      <ContentContainer>
        <OverrideAnswerContent question={question} reviewOption={reviewOption} />
      </ContentContainer>
    </FormField>
  );
};
