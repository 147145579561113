import { Button, styled, useTheme } from '@mui/material';
import { Typography } from '../../components/Typography/Typography';
import { Outlet, useParams } from 'react-router';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings-blue.svg';
import { useState } from 'react';
import { ROUTES } from '../../constants/routes';
import { doNothingFunc } from '../../utils/doNothingFunc';
import { PageTabs } from '../../components/PageTabs';

const TabsWrapper = styled('div')`
  position: relative;
`;

const tabs = [
  { label: 'Overview', path: ROUTES.COMPANY_OVERVIEW },
  { label: 'Performance', path: ROUTES.COMPANY_PERFORMANCE },
  { label: 'Checklist', path: ROUTES.COMPANY_CHECKLIST },
];

export const ControlledCompany = () => {
  const { colors } = useTheme();
  const params = useParams();

  const [value, setValue] = useState(params.activeTab || ROUTES.COMPANY_OVERVIEW);

  return (
    <TabsWrapper>
      <Button
        variant='text'
        startIcon={<SettingsIcon style={{ marginTop: '-2px' }} />}
        style={{
          minWidth: 'auto',
          position: 'absolute',
          right: '32px',
          top: '5px',
          zIndex: 1,
        }}
        onClick={doNothingFunc}
      >
        <Typography variant='subtitle2' color={colors.accent[50]}>
          Manage View
        </Typography>
      </Button>
      <PageTabs tabValue={value} setTabValue={setValue} tabs={tabs} />
      <Outlet />
    </TabsWrapper>
  );
};
