import { styled, useTheme } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { PieChart } from '../Charts/PieChart/PieChart';
import { Typography } from '../Typography/Typography';
import { esgRiskLevelColors } from '../../theme/colors';
import { ChartDataItem, Company, PieChartData, RISK_LEVEL, USER_ROLE } from '../../types';
import { useAtom, useAtomValue } from 'jotai';
import { activeRoleState, filteredCompaniesIdsState } from '../../state/UIState';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ChartListWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 1rem;
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

interface Props {
  companies: Company[];
  isIssuers?: boolean;
}

export const ComposureCharts: FC<Props> = ({ companies, isIssuers }) => {
  const { colors } = useTheme();

  const [filteredCompaniesIds] = useAtom(filteredCompaniesIdsState);
  const [filteredCompanies, setFilteredCompanies] = useState(companies);
  const activeRole = useAtomValue(activeRoleState);

  useEffect(() => {
    setFilteredCompanies(companies.filter((company) => filteredCompaniesIds.includes(company.id)));
  }, [companies, filteredCompaniesIds]);

  const chartsData: PieChartData[] = useMemo(() => {
    const riskLevelData = filteredCompanies.reduce<
      Record<string, ChartDataItem & { color: string }>
    >(
      (acc, company) => {
        const riskLevelKey = company.esgRiskLevel?.toLowerCase() as Lowercase<RISK_LEVEL>;

        if (!riskLevelKey) return acc;

        if (!acc[riskLevelKey]) {
          acc[riskLevelKey] = {
            name: company.esgRiskLevel,
            value: 0,
            id: riskLevelKey,
            color: esgRiskLevelColors[riskLevelKey],
          };
        } else {
          acc[riskLevelKey].value += 1;
        }
        return acc;
      },
      {
        high: { name: 'High', value: 0, id: 'high', color: esgRiskLevelColors.high },
        medium: { name: 'Medium', value: 0, id: 'medium', color: esgRiskLevelColors.medium },
        low: { name: 'Low', value: 0, id: 'low', color: esgRiskLevelColors.low },
      }
    );

    const filteredEsgRiskLevelData = [
      riskLevelData['high'],
      riskLevelData['medium'],
      riskLevelData['low'],
    ].filter((item) => item.value > 0);

    const esgRiskLevelColorSchema = filteredEsgRiskLevelData.map((item) => item.color);

    const esgRiskLevelData = filteredEsgRiskLevelData.map(({ id, name, value }) => ({
      id,
      name,
      value,
    }));

    const esgRisCompaniesNum = esgRiskLevelData.reduce(function (a, b) {
      return a + b.value;
    }, 0);

    const companiesBySector = filteredCompanies.reduce<Record<string, ChartDataItem>>(
      (acc, company) => {
        company.sectors.forEach((sector) => {
          if (!acc[sector.name]) {
            acc[sector.name] = { name: sector.name, value: 1, id: sector.name };
          } else {
            acc[sector.name].value += 1;
          }
        });
        return acc;
      },
      {}
    );
    const companiesBySectorData = Object.values(companiesBySector);

    const companiesByGeography = filteredCompanies.reduce<Record<string, ChartDataItem>>(
      (acc, company) => {
        if (!company.country) return acc;

        if (!acc[company.country]) {
          acc[company.country] = {
            name: company.country,
            value: 1,
            id: company?.country,
          };
        } else {
          acc[company.country].value += 1;
        }
        return acc;
      },
      {}
    );

    const companiesByGeographyData = Object.values(companiesByGeography);

    const companiesLabel = isIssuers ? 'Issuers' : 'Companies';

    return [
      {
        title: `${companiesLabel} by ESG Risk Level`,
        data: esgRiskLevelData,
        colorSchema: esgRiskLevelColorSchema,
        totalLabel: companiesLabel,
        totalValue: esgRisCompaniesNum,
      },
      {
        title: `${companiesLabel} by SASB Sector`,
        data: companiesBySectorData,
        totalLabel: 'Sectors',
        totalValue: companiesBySectorData.length,
      },
      {
        title: `${companiesLabel} by Geography`,
        data: companiesByGeographyData,
        totalLabel: 'Regions',
        totalValue: companiesByGeographyData.length,
      },
    ];
  }, [filteredCompanies, isIssuers]);

  const showTitle = useMemo(() => activeRole !== USER_ROLE.ANALYST, [activeRole]);

  return (
    <Wrapper>
      {showTitle && (
        <TitleWrapper>
          <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '9px' }}>
            Composure
          </Typography>
        </TitleWrapper>
      )}
      <ChartListWrapper>
        {chartsData.map((item) => (
          <PieChart
            key={item.title}
            data={item.data}
            title={item.title}
            chartColors={item.colorSchema}
            totalLabel={item.totalLabel}
            totalValue={item.totalValue}
          />
        ))}
      </ChartListWrapper>
    </Wrapper>
  );
};
