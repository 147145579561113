import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { SurveysTable } from './SurveysTable';

const Wrapper = styled('div')`
  padding: 24px 40px;
`;

export const SurveysContent = () => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <Typography variant='h4' color={colors.primary[90]}>
        All Surveys
      </Typography>
      <SurveysTable />
    </Wrapper>
  );
};
