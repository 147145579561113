import { useMemo } from 'react';
import { useWorkflowStages } from '../../../../../../queries/useWorkflowStages';
import { SelectItem, WorkflowStep } from '../../../../../../types';
import { STEP_TYPE_LABELS } from '../../../../../../constants/constants';

export const useBlockingSteps = (currentStep: WorkflowStep) => {
  const { data: stages } = useWorkflowStages();

  const allSteps = useMemo(() => {
    return stages
      ?.flatMap((stage) => stage.steps)
      ?.filter((s) => {
        const stepBlockingIds = s?.blockingSteps?.map((blockingStep) => blockingStep.id);
        return s.id !== currentStep?.id && !stepBlockingIds?.includes(currentStep?.id);
      });
  }, [currentStep, stages]);

  const stepGroups = useMemo(
    () =>
      allSteps?.map((step) => {
        return {
          id: step.id,
          value: step.name,
          group: STEP_TYPE_LABELS[step.type],
        } as SelectItem;
      }),
    [allSteps]
  );

  return { allSteps, stepGroups };
};
