import { Fade, IconButton, styled, useTheme } from '@mui/material';
import { FC, useCallback } from 'react';
import { Typography } from '../../../../../components/Typography/Typography';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/add-gray.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right.svg';
import { QUESTION_TYPE, SurveyTemplateSection } from '../../../../../types';
import {
  activeSectionIdState,
  isApplyingEvaluationRulesModeActiveState,
  isMultiActionMoveModeActiveState,
  isSingleActionMoveModeActiveState,
  sectionsState,
  selectedQuestionsState,
  surveyTemplatesDataState,
} from '../../../../../state/UIState';
import { useAtom } from 'jotai';
import { TemplateQuestionList } from './TemplateQuestionList';
import { generateId } from '../../../../../utils/generateId';
import { Tooltip } from '../../../../../components/Tooltip/Tooltip';

interface Props {
  sections: SurveyTemplateSection[];
}

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ControlsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const SectionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const TemplateSectionList: FC<Props> = ({ sections: templateSections }) => {
  const { colors } = useTheme();
  const [, setSurveyTemplateData] = useAtom(surveyTemplatesDataState);
  const [, setSections] = useAtom(sectionsState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);
  const [isApplyingModeActive] = useAtom(isApplyingEvaluationRulesModeActiveState);
  const [isSingleActionMoveModeActive] = useAtom(isSingleActionMoveModeActiveState);
  const [isMultiActionMoveModeActive] = useAtom(isMultiActionMoveModeActiveState);
  const [selectedQuestions] = useAtom(selectedQuestionsState);

  const onExpandOrCollapse = useCallback(
    (section: SurveyTemplateSection) => {
      setSurveyTemplateData((prevCategories) => {
        return prevCategories.map((category) => {
          return category.name === section.category?.name ||
            (category.name === 'Uncategorized' && !section.category)
            ? {
                ...category,
                sections: category.sections.map((s) => {
                  return s.id === section.id
                    ? {
                        ...s,
                        isExpanded: !s.isExpanded,
                      }
                    : s;
                }),
              }
            : category;
        });
      });
    },
    [setSurveyTemplateData]
  );

  const onAddSectionToSurvey = useCallback(
    (section: SurveyTemplateSection) => {
      const newSectionId = generateId();
      setSections((prevSections) => {
        return [
          ...prevSections,
          {
            id: newSectionId,
            name: section.name,
            depth: 0,
            parentId: null,
            questions: section.questions.map((question) => ({
              id: generateId(),
              includeInstructions: question.includeInstructions,
              includeEvaluation: question.includeEvaluation,
              evaluationRules: question.evaluationRules || null,
              instructions: question.instructions,
              isActive: true,
              name: question.name,
              type: question.type,
              tableData: question.tableData,
              options:
                question.options?.map((option) => ({
                  id: generateId(),
                  name: option.name,
                })) || [],
              sectionId: newSectionId,
              isRequired: question.type === QUESTION_TYPE.TABLE ? false : true,
            })),
            subSections: [],
            isExpanded: false,
          },
        ];
      });
      setActiveSectionId(newSectionId);
    },
    [setActiveSectionId, setSections]
  );

  return (
    <Wrapper>
      {templateSections.map((section) => (
        <SectionWrapper key={section.id}>
          <ControlsWrapper>
            <TitleWrapper>
              <IconButton
                style={{ width: '30px', height: '30px' }}
                onClick={() => onExpandOrCollapse(section)}
              >
                {section.isExpanded ? <ArrowDownIcon /> : <ArrowRightIcon />}
              </IconButton>
              <Typography variant='subtitle2' color={colors.primary[90]}>
                {section.name}
              </Typography>
            </TitleWrapper>
            <Tooltip
              title='Add Section to Survey'
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [70, -13],
                    },
                  },
                ],
              }}
            >
              <IconButton
                onClick={() => onAddSectionToSurvey(section)}
                style={{
                  visibility:
                    isApplyingModeActive ||
                    isSingleActionMoveModeActive ||
                    isMultiActionMoveModeActive ||
                    selectedQuestions.length
                      ? 'hidden'
                      : 'visible',
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </ControlsWrapper>
          {!section.isExpanded ? (
            <Typography variant='body' color={colors.primary[70]} style={{ paddingLeft: '32px' }}>
              {section.questions.length === 1
                ? '1 question'
                : `${section.questions.length} questions`}
            </Typography>
          ) : (
            <TemplateQuestionList questions={section.questions} />
          )}
        </SectionWrapper>
      ))}
    </Wrapper>
  );
};
