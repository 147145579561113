import { AddEditKeyTaskModal } from './AddEditKeyTaskModal/AddEditKeyTaskModal';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { KeyTaskPayload, useCreateKeyTask } from '../../../../../../../queries/useKeyTasks';
import { useParams } from 'react-router';
import {
  KEY_TASK_CONDITION_OPERATOR,
  KEY_TASK_GOAL_TYPE,
  REPORT_STATUS,
} from '../../../../../../../types';

interface Props {
  objectiveId: number;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setExpandedObjectiveId: Dispatch<SetStateAction<number | null>>;
}

export const AddKeyTask = ({
  objectiveId,
  isModalOpen,
  setIsModalOpen,
  setExpandedObjectiveId,
}: Props) => {
  const params = useParams();

  const createKeyTask = useCreateKeyTask(Number(params.id));

  const onSubmit = useCallback(
    async (payload: Partial<KeyTaskPayload>) => {
      const conditionOperator =
        payload.goalType === KEY_TASK_GOAL_TYPE.KPI
          ? payload.conditionOperator
          : KEY_TASK_CONDITION_OPERATOR.EQUAL;

      await createKeyTask.mutateAsync({
        objective: objectiveId,
        status: REPORT_STATUS.NOT_STARTED,
        progress: 0,
        ...payload,
        conditionOperator,
      });

      setExpandedObjectiveId(objectiveId);
    },
    [createKeyTask, objectiveId, setExpandedObjectiveId]
  );

  return (
    <AddEditKeyTaskModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title='Add Key Task'
      confirmBtnText='Add Key Task'
      onSubmit={onSubmit}
    />
  );
};
