import { styled } from '@mui/material';
import { SideSection } from './SideSection';
import { KpisContent } from './KpisContent';
import { useSortedCoreKpiCategories } from '../hooks/useSortedCoreKpiCategories';
import { CoreKpisSkeletonLoader } from '../../../components/SkeletonLoader/CoreKpis.SkeletonLoader';

const Wrapper = styled('div')`
  display: flex;
`;

export const MainContent = () => {
  const { isLoading } = useSortedCoreKpiCategories();
  if (isLoading) {
    return <CoreKpisSkeletonLoader />;
  }
  return (
    <Wrapper>
      <SideSection />
      <KpisContent />
    </Wrapper>
  );
};
