import { styled, useTheme } from '@mui/material';
import { QuestionOption } from '../../../../../../../types';
import { OptionWrapper } from '../common';
import { Checkbox } from '../../../../../../../components/Checkbox/Checkbox';
import { NotesBoxPopup } from '../../../../../../../components/NotesBoxPopup/NotesBoxPopup';
import { Typography } from '../../../../../../../components/Typography/Typography';

interface Props {
  option: QuestionOption;
  selectedOption?: QuestionOption;
  isChecked?: boolean;
}

const TextWrapper = styled('div')`
  position: relative;
`;

export const CheckboxOption = ({ option, selectedOption, isChecked }: Props) => {
  const { colors } = useTheme();

  return (
    <OptionWrapper>
      <Checkbox
        sx={{ '&.Mui-checked': { color: colors.primary[60] } }}
        checked={isChecked}
        disabled
      />
      <NotesBoxPopup
        notes={selectedOption?.notes || ''}
        popupPlacement='right-start'
        popupOffset={{ mainAxis: 8, crossAxis: -8 }}
      >
        <TextWrapper>
          <Typography
            variant='caption'
            color={colors.primary[70]}
            style={{ background: selectedOption?.notes ? colors.warning[20] : 'transparent' }}
          >
            {option.name}
          </Typography>
        </TextWrapper>
      </NotesBoxPopup>
    </OptionWrapper>
  );
};
