import { useAtom } from 'jotai';
import { useCallback } from 'react';
import {
  activeKpiCategoryState,
  kpisTableRowSelectionState,
  selectedCoreKpiCategoryState,
  selectedCoreKpisState,
} from '../../../state/UIState';
import { useUpdateCoreKpis } from '../../../queries/useCoreKpis';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { getMaxOrderNum } from '../../../utils/getMaxOrderNum';
import { useOnCancelConfirm } from './useOnCancelConfirm';
import { ReactComponent as UndoIcon } from '../../../assets/icons/undo-blue.svg';

export const useMoveKpis = () => {
  const [selectedCategory] = useAtom(selectedCoreKpiCategoryState);
  const [selectedKpis] = useAtom(selectedCoreKpisState);
  const [activeCoreKpiCategory] = useAtom(activeKpiCategoryState);
  const [, setRowSelection] = useAtom(kpisTableRowSelectionState);
  const updateCoreKpis = useUpdateCoreKpis();
  const { pushSuccessToast } = useToastMessage();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const { onCancel } = useOnCancelConfirm();

  const moveKpis = useCallback(async () => {
    const maxOrderNum = getMaxOrderNum(selectedCategory?.kpis);
    const payload = {
      data: selectedKpis.map((k, i) => ({
        id: k.id,
        category: selectedCategory?.id,
        order: maxOrderNum + i + 1,
      })),
    };
    await updateCoreKpis.mutateAsync(payload, {
      onSuccess: async () => {
        if (!appConfigData?.data) {
          return;
        }
        await updateAppConfig.mutateAsync({
          data: { ...appConfigData?.data, isCoreKpiSurveyUpToDate: false },
        });
        pushSuccessToast({
          message: `${selectedKpis.length} KPIs were successfully moved to ${selectedCategory?.name}.`,
          action: () => {
            const payload = {
              data: selectedKpis.map((k) => ({
                id: k.id,
                category: activeCoreKpiCategory?.id,
                order: k.order,
              })),
            };
            updateCoreKpis.mutateAsync(payload);
          },
          actionLabel: 'Undo',
          endIcon: <UndoIcon />,
        });
      },
    });
    setRowSelection({});
    onCancel();
  }, [
    activeCoreKpiCategory?.id,
    appConfigData?.data,
    onCancel,
    pushSuccessToast,
    selectedCategory?.id,
    selectedCategory?.kpis,
    selectedCategory?.name,
    selectedKpis,
    setRowSelection,
    updateAppConfig,
    updateCoreKpis,
  ]);

  return { moveKpis };
};
