import { Switch, styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';

const SwitchWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

interface Props {
  isActive: boolean;
  onToggleActive: () => void;
}

export const IsActiveSwitch = ({ isActive, onToggleActive }: Props) => {
  const { colors } = useTheme();

  return (
    <SwitchWrapper>
      <Switch checked={isActive} onChange={onToggleActive} />
      <Typography variant='body' color={colors.primary[80]}>
        Active
      </Typography>
    </SwitchWrapper>
  );
};
