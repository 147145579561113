import { useAtom } from 'jotai';
import { WorkflowStage, WorkflowStep } from '../../../../types';
import { StageCard } from '../../components/StageCard/StageCard';
import { activePortCoStageState } from '../../../../state/UIState';
import { useCallback } from 'react';
import { styled } from '@mui/material';
import { AddStep } from '../../components/AddStep/AddStep';
import { PortCoStepsList } from './PortCoStepsList/PortCoStepsList';
import { useDeleteStage, useUpdateStage } from '../../../../queries/useWorkflowStages';
import { useCreateStep } from '../../../../queries/useWorkflowSteps';
import { useAppConfig, useUpdateAppConfig } from '../../../../queries/useAppConfig';

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

interface Props {
  stage: WorkflowStage;
  isDraggable: boolean;
}

export const PortCoStageCard = ({ stage, isDraggable }: Props) => {
  const [activeStage, setActiveStage] = useAtom(activePortCoStageState);

  const { mutate: updateStage } = useUpdateStage();
  const { mutate: deleteStage } = useDeleteStage();
  const { mutateAsync: createStep } = useCreateStep();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const onCardClick = useCallback(() => {
    setActiveStage(stage);
  }, [setActiveStage, stage]);

  const onTitleChanged = (val: string) => {
    updateStage(
      { id: stage.id, name: val },
      {
        onSuccess: async () => {
          if (!appConfigData) return;

          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isControlledWorkflowUpToDate: false },
          });
        },
      }
    );
  };

  const onDeleteStage = useCallback(
    (id: number) => {
      deleteStage(id, {
        onSuccess: async () => {
          if (!appConfigData) return;

          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isControlledWorkflowUpToDate: false },
          });
        },
      });
    },
    [appConfigData, deleteStage, updateAppConfig]
  );

  const onAddStep = useCallback(
    async (step: Partial<WorkflowStep> | null) => {
      if (!step) return;

      const payload = {
        type: step.type,
        name: step.name,
        survey: step?.survey?.id,
        requestStep: step.requestStep,
        companyInfoStep: step.companyInfoStep,
        stage: stage.id,
        order: stage?.steps?.length ? stage?.steps.length + 1 : 1,
        accessRoles: step?.accessRoles?.map((role) => role.id),
        blockingSteps: step.blockingSteps?.map((step) => step.id),
        includeBlockingSteps: step?.includeBlockingSteps,
      };

      const createdStep = await createStep(payload, {
        onSuccess: async () => {
          if (!appConfigData) return;

          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isControlledWorkflowUpToDate: false },
          });
        },
      });

      return createdStep;
    },
    [appConfigData, createStep, stage.id, stage?.steps.length, updateAppConfig]
  );

  return (
    <StageCard
      stage={stage}
      activeStage={activeStage}
      onCardClick={onCardClick}
      isDraggable={isDraggable}
      onTitleChanged={onTitleChanged}
      onDeleteStage={onDeleteStage}
    >
      <ContentWrapper>
        <PortCoStepsList steps={stage?.steps} stageId={stage.id} />
        <AddStep stageId={stage.id} onAddStep={onAddStep} />
      </ContentWrapper>
    </StageCard>
  );
};
