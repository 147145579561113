import { styled } from '@mui/material';

export const KpiBarChartContainer = styled('div')`
  background-color: #fff;
  padding: 32px;
`;

export const KpiBarChartWrapper = styled('div')`
  padding: 20px 40px;
`;
