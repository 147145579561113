import { styled } from '@mui/material';
import { motion } from 'framer-motion';

const ItemLabel = styled('p')<{ isActive?: boolean }>`
  font-size: ${({ theme }) => theme.text.size.small}px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.text.font.fontFamilyMedium};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.accent[50] : theme.colors.primary[80]};
  white-space: nowrap;
`;

const LabelWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

interface Props {
  isActive?: boolean;
  icon?: JSX.Element;
  title: string;
}

export const MenuItemLabel = ({ isActive, icon, title }: Props) => {
  return (
    <LabelWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <ItemLabel isActive={isActive}>{title}</ItemLabel>
      {icon && <IconWrapper>{icon}</IconWrapper>}
    </LabelWrapper>
  );
};
