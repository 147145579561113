import { Button, styled } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Textarea } from '../../Textarea/Textarea';

const ButtonsWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 8px;
`;

interface Props {
  notes: string;
  onNotesChange?: (notes: string) => void;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
}

export const EditNote = ({ notes, onNotesChange, setIsEditMode }: Props) => {
  const [currentValue, setCurrentValue] = useState(notes);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentValue(e.target.value);
  };

  const onSave = () => {
    onNotesChange?.(currentValue);
    setIsEditMode(false);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  return (
    <>
      <Textarea onChange={handleChange} value={currentValue} />
      <ButtonsWrapper>
        <Button
          variant='outlined'
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          onClick={(e) => {
            e.stopPropagation();
            onSave();
          }}
          disabled={!currentValue}
        >
          Save
        </Button>
      </ButtonsWrapper>
    </>
  );
};
