import { useAtomValue } from 'jotai';
import { activeRoleState } from '../state/UIState';
import { WorkflowStep } from '../types';

export const useHasAccessToTask = () => {
  const activeRole = useAtomValue(activeRoleState);

  const getHasAccess = (step?: WorkflowStep) => {
    if (!activeRole || !step) return false;
    const accessRoles = step.accessRoles?.map((role) => role.name);

    return !accessRoles?.length || accessRoles?.includes(activeRole);
  };

  return { getHasAccess };
};
