import ContentLoader from 'react-content-loader';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';
import { PAGE_HEADER_HEIGHT } from '../../constants/layoutSizes';

const ContentWrapper = styled('div')`
  display: flex;
`;
const LeftSectionLoader = styled('div')`
  padding: 39px 40px;
  width: 340px;
  border-right: 1px solid rgb(235, 241, 244);
  height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
`;

const RightSectionLoader = styled('div')`
  padding: 24px 50px;
  flex-grow: 1;
  height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
`;

export function CoreKpisSkeletonLoader(): ReactElement {
  return (
    <ContentWrapper>
      <LeftSectionLoader>
        <ContentLoader height={684} width={'100%'}>
          <rect width='100' height='26' rx='4' fill='#EEEFF1' />
          <rect width='26' height='26' x='233' fill='#EEEFF1' />
          <rect width='100%' height='26' y='34' fill='#EEEFF1' />
          <rect width='100%' height='26' y='68' fill='#EEEFF1' />
          <rect width='100%' height='26' y='102' fill='#EEEFF1' />
          <rect width='100%' height='26' y='136' fill='#EEEFF1' />
          <rect width='100%' height='26' y='170' fill='#EEEFF1' />
          <rect width='100%' height='26' y='204' fill='#EEEFF1' />
          <rect width='100%' height='26' y='238' fill='#EEEFF1' />
          <rect width='100%' height='26' y='272' fill='#EEEFF1' />
          <rect width='100%' height='26' y='306' fill='#EEEFF1' />
          <rect width='100%' height='26' y='340' fill='#EEEFF1' />
          <rect width='100%' height='26' y='374' fill='#EEEFF1' />
        </ContentLoader>
      </LeftSectionLoader>
      <RightSectionLoader>
        <ContentLoader height={684} width={'100%'}>
          <rect width='200px' height='36' rx='4' fill='#EEEFF1' />
          <rect width='100%' height='24' y='70' rx='4' fill='#EEEFF1' />
          <rect y='102' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='143' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='184' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='225' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='266' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='307' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='348' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='389' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='430' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='471' width='100%' height='38' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </RightSectionLoader>
    </ContentWrapper>
  );
}
