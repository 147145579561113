import { ReactComponent as HighGreen } from '../../../assets/icons/high-green.svg';
import { ReactComponent as Medium } from '../../../assets/icons/medium.svg';
import { ReactComponent as LowRed } from '../../../assets/icons/low-red.svg';

interface Props {
  evaluationRangeScore?: number | null;
}

export const EvaluationRangeScoreIcon = ({ evaluationRangeScore }: Props) => {
  if (!evaluationRangeScore) return null;

  if (evaluationRangeScore < 3) return <LowRed />;
  if (evaluationRangeScore >= 3 && evaluationRangeScore <= 5) return <Medium />;
  return <HighGreen />;
};
