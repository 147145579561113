import { styled, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import { useReportById } from '../../../../../../queries/useReports';
import { ReviewOption } from '../../../../../../components/ReviewOption';
import { useMemo } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { evaluationState, reviewState, userState } from '../../../../../../state/UIState';
import {
  EvaluationRule,
  QuestionReview,
  SurveyQuestion,
  TReviewOptionConfig,
} from '../../../../../../types';

const OverrideScoreWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  question: SurveyQuestion;
  reviewOption: TReviewOptionConfig;
}

export const OverrideEvaluation = ({ question, reviewOption }: Props) => {
  const params = useParams();

  const { colors } = useTheme();

  const { data: report } = useReportById(Number(params?.reportId));
  const [evaluation, setEvaluation] = useAtom(evaluationState);
  const [, setReview] = useAtom(reviewState);
  const user = useAtomValue(userState);

  const evaluationRules = useMemo(() => {
    if (question.includeEvaluation) return report?.survey?.evaluationRules;
    return null;
  }, [question.includeEvaluation, report?.survey?.evaluationRules]);

  const isEvaluated = useMemo(() => {
    if (!evaluation) return false;
    return (
      evaluationRules?.some((rule) => evaluation[question.id]?.weight === rule.weight) || false
    );
  }, [evaluation, evaluationRules, question.id]);

  const onSelectRule = (rule: EvaluationRule) => {
    const newEvaluation = { ...evaluation, [question.id]: rule };
    setEvaluation(newEvaluation);

    setReview((oldResponses) => {
      return {
        ...oldResponses,
        [question.id]: {
          type: reviewOption.type,
          name: reviewOption.name,
          reviewedBy: user,
          reviewDate: new Date().toISOString(),
        } as QuestionReview,
      };
    });
  };

  return (
    <OverrideScoreWrapper>
      {evaluationRules?.map((rule) => (
        <ReviewOption
          key={rule.weight}
          isSelected={evaluation?.[question.id] && evaluation[question.id].weight === rule.weight}
          isEvaluated={isEvaluated}
          onSelect={() => onSelectRule(rule)}
          label={rule.name}
          optionColor={colors[rule?.color][40]}
        />
      ))}
    </OverrideScoreWrapper>
  );
};
