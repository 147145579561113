import { useMutation } from 'react-query';
import { Http } from '../services/Http';
import { useToastMessage } from '../hooks/useToastMessage';

const NO_EMAIL_FOR_COMPANY_ERR = 'No email found for this company';

async function sendReportAgain(reportId: number) {
  await Http.axios.post(`/report/${reportId}/send-email-invite`);
}

export function useSendReportAgain() {
  const { pushErrorToast, pushSuccessToast, pushInfoToast } = useToastMessage();

  return useMutation(sendReportAgain, {
    onError: (error: any) => {
      console.error({ error });
      if (error?.response?.data?.message === NO_EMAIL_FOR_COMPANY_ERR) {
        pushInfoToast({ message: 'Email not sent. You need to assign ESG contact first.' });
        return;
      }
      pushErrorToast({ message: 'Failed to send email' });
    },
    onSuccess: () => {
      pushSuccessToast({ message: 'Email Sent' });
    },
  });
}
