import { ButtonBase, styled } from '@mui/material';
import { HTMLMotionProps, motion } from 'framer-motion';

const Container = styled('div')<HTMLMotionProps<'div'>>`
  display: flex;
`;

const Button = styled(ButtonBase)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const IconWrapper = styled('div')`
  padding: 14px;
`;

interface Props {
  onClick?: () => void;
  icon: JSX.Element;
  isExpanded: boolean;
  labelContent: React.ReactNode;
}

export const MenuItemBase = ({ onClick, icon, isExpanded, labelContent }: Props) => {
  return (
    <Container
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.1 }}
      exit={{ opacity: 0 }}
    >
      <Button
        TouchRippleProps={{ color: 'white' }}
        style={{ borderRadius: '8px' }}
        onClick={onClick}
      >
        <IconWrapper>{icon}</IconWrapper>
        {isExpanded && labelContent}
      </Button>
    </Container>
  );
};
