import { useParams } from 'react-router';
import { useCompanyById, useUpdateCompany } from '../../../../../queries/useCompanies';
import { useNotifyUsers } from '../../../../../queries/useNotifyUsers';
import { useAnalystRatingStep } from './useAnalystRatingStep';

export const useNotifyUsersForAnalystRating = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));

  const updateCompany = useUpdateCompany();

  const { mutate: notifyUsers } = useNotifyUsers();

  const analystRatingStep = useAnalystRatingStep();

  const onNotifyUsers = async () => {
    if (!company) return;

    if (analystRatingStep?.notifyUsers?.length && analystRatingStep?.isApprovalRequired) {
      notifyUsers({
        companyId: company.id,
        userIds: analystRatingStep?.notifyUsers?.map((user) => user.id),
      });
    }

    if (!analystRatingStep?.isApprovalRequired && analystRatingStep) {
      updateCompany.mutate({
        id: company.id,
        completedWorkflowSteps: [...(company?.completedWorkflowSteps || []), analystRatingStep.id],
      });
    }
  };

  return onNotifyUsers;
};
