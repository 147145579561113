import { ButtonBase, styled, useTheme } from '@mui/material';
import { SearchInput } from '../../../../components/SearchInput/SearchInput';
import { ObjectivesAccordion } from './ObjectivesAccordion/ObjectivesAccordion';
import { Typography } from '../../../../components/Typography/Typography';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { AddEditObjectiveModal } from './ObjectivesAccordion/components/AddEditObjectiveModal/AddEditObjectiveModal';
import { ObjectivePayload, useCreateObjective } from '../../../../queries/useObjectives';
import { useParams } from 'react-router';
import { useCompanyById } from '../../../../queries/useCompanies';
import { REPORT_STATUS, SelectItem } from '../../../../types';
import { Multiselect } from '../../../../components/Multiselect/Multiselect';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 40px;
`;
const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 8px;
`;

export const Objectives = () => {
  const { colors } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();

  const { data: company } = useCompanyById(Number(params.id));

  const [companyObjectives, setCompanyObjectives] = useState(company?.objectives || []);
  const [searchValue, setSearchValue] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState<SelectItem[]>([]);
  const [expandedObjectiveId, setExpandedObjectiveId] = useState<number | null>(null);

  useEffect(() => {
    if (!company?.objectives?.length) return;
    setCompanyObjectives(company?.objectives || []);
  }, [company?.objectives]);

  const createObjective = useCreateObjective(Number(params.id));

  const onSubmit = useCallback(
    (payload: Partial<ObjectivePayload>) => {
      createObjective.mutate({
        company: Number(params.id),
        status: REPORT_STATUS.NOT_STARTED,
        progress: 0,
        ...payload,
      });
    },
    [createObjective, params.id]
  );

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setSearchValue(value);
      if (!value) {
        setCompanyObjectives(company?.objectives || []);
        return;
      }

      const filteredObjectives =
        company?.objectives?.filter((objective) => {
          return objective?.name?.toLowerCase()?.includes(value?.toLowerCase());
        }) || [];
      setCompanyObjectives(filteredObjectives);
    },
    [company?.objectives]
  );

  const onClearSearch = useCallback(() => {
    setSearchValue('');
    setCompanyObjectives(company?.objectives || []);
  }, [company?.objectives]);

  const uniqueStatuses = useMemo(() => {
    const allStatuses = company?.objectives?.map((objective) => objective.status) || [];
    const uniqueStatuses = [...new Set(allStatuses)];

    const statusItems =
      uniqueStatuses?.map((status, i) => ({
        value: status,
        id: i,
      })) || [];

    if (!selectedStatuses?.length) setSelectedStatuses(statusItems);

    return statusItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.objectives]);

  const onChangeStatusFilter = useCallback(
    (items: SelectItem[]) => {
      setSelectedStatuses(items);

      if (!items?.length) {
        setCompanyObjectives(company?.objectives || []);
        return;
      }

      const filteredObjectives =
        company?.objectives?.filter(
          (objective) => items.find((item) => item.value === objective.status) || false
        ) || [];

      setCompanyObjectives(filteredObjectives);
    },
    [company?.objectives]
  );

  return (
    <Wrapper>
      <TitleWrapper>
        <Typography variant='h4' color={colors.primary[90]}>
          Objectives
        </Typography>
        <ButtonBase onClick={() => setIsModalOpen(true)}>
          <Typography variant='srOnly' color={colors.accent[50]}>
            + Add Objective
          </Typography>
        </ButtonBase>
      </TitleWrapper>
      {Boolean(company?.objectives?.length) && (
        <FiltersWrapper>
          <SearchInput
            placeholder='Search for a objective'
            style={{ width: '345px', marginRight: '12px' }}
            onClear={onClearSearch}
            onChange={onSearch}
            value={searchValue}
          />
          <Multiselect
            style={{ width: '240px' }}
            options={uniqueStatuses}
            value={selectedStatuses}
            onChange={(_, val) => {
              onChangeStatusFilter(val as SelectItem[]);
            }}
            disablePortal
            optionsName='Statuses'
            fieldPlaceholder='Filter by Statuses'
          />
        </FiltersWrapper>
      )}
      {companyObjectives?.length ? (
        companyObjectives?.map((objective) => (
          <ObjectivesAccordion
            key={objective.name}
            objective={objective}
            expandedObjectiveId={expandedObjectiveId}
            setExpandedObjectiveId={setExpandedObjectiveId}
          />
        ))
      ) : (
        <Typography variant='body' color={colors.primary[70]}>
          No data was found
        </Typography>
      )}
      {isModalOpen && (
        <AddEditObjectiveModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title='Add Objective'
          confirmBtnText='Add Objective'
          onSubmit={onSubmit}
        />
      )}
    </Wrapper>
  );
};
