import { Dispatch, SetStateAction, useMemo } from 'react';
import { SidebarMenu } from '../../../components/SidebarMenu/SidebarMenu';
import { useOktaAuth } from '@okta/okta-react';
import { useAtomValue } from 'jotai';
import { activeRoleState } from '../../../state/UIState';
import { USER_ROLE } from '../../../types';
import { useMenuItems } from './useMenuItems';
import { useAnalystMenuItems } from './useAnalystMenuItems';
import { useManagerComplianceMenuItems } from './useManagerComplianceMenuItems';

interface Props {
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  isExpanded: boolean;
  onPinToggle: () => void;
  isPinned: boolean;
}

export const EsgCompassSidebarMenu = ({
  setIsExpanded,
  isExpanded,
  onPinToggle,
  isPinned,
}: Props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const activeRole = useAtomValue(activeRoleState);

  const analystMenuItems = useAnalystMenuItems();
  const managerComplianceMenuItems = useManagerComplianceMenuItems();
  const commonMenuItems = useMenuItems();

  const menuItems = useMemo(() => {
    if (activeRole === USER_ROLE.ANALYST) {
      return analystMenuItems;
    }

    if (activeRole === USER_ROLE.MANAGER || activeRole === USER_ROLE.COMPLIANCE) {
      return managerComplianceMenuItems;
    }

    return commonMenuItems;
  }, [activeRole, analystMenuItems, commonMenuItems, managerComplianceMenuItems]);

  const username = authState?.idToken?.claims?.name || '';

  const onUserAvatarClick = () => oktaAuth.signOut();

  return (
    <SidebarMenu
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      onPinToggle={onPinToggle}
      isPinned={isPinned}
      menuItems={menuItems}
      username={username}
      activeRole={activeRole}
      onUserAvatarClick={onUserAvatarClick}
    />
  );
};
