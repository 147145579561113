import { FC, useMemo, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { Table } from '../../../components/Table/Table';
import {
  EVALUATION_SCALE,
  EvaluationRule,
  QUESTION_TYPE,
  QuestionOption,
  QuestionReview,
  Response,
  REVIEW_TYPE,
  Survey,
  SurveyQuestion,
} from '../../../types';

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { Button, styled, useTheme } from '@mui/material';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { flattenTree } from '../../../utils/treeUtilities';
import { ExportButton } from './ExportButton';
import { AssessmentAnswerCell } from './AssessmentAnswerCell';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { EvaluationScorePreview } from '../../../components/EvaluationScorePreview/EvaluationScorePreview';
import { ColorPalette } from '../../../theme/structure';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 40px 40px 40px;
`;

const FiltersWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FiltersContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const CellWrapper = styled('div')`
  width:  600px
  min-width: 600px;
  &&& p {
    white-space: normal;
    text-overflow: clip;
  }
`;

interface Props {
  survey: Survey;
  response: Record<string, Response>;
  reportReview: Record<string, QuestionReview>;
  evaluation: Record<string, EvaluationRule>;
  evaluationScale?: EVALUATION_SCALE;
}
export interface QuestionRow {
  id: string;
  question: string;
  answer: Response;
  overrideAnswer?: Response;
  overrideReason?: string;
  type: QUESTION_TYPE;
  overriddenBy?: string;
  evaluationColor?: keyof ColorPalette;
  evaluationLabel?: string;
  evaluationScore?: number | null;
}

const columnHelper = createColumnHelper<QuestionRow>();

export const QuestionsTable: FC<Props> = ({
  survey,
  response,
  reportReview,
  evaluation,
  evaluationScale,
}) => {
  const { colors } = useTheme();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const theme = useTheme();

  const questions = useMemo(() => {
    const sections = flattenTree(survey.sections);
    return sections.map((section) => section.questions).flat();
  }, [survey.sections]);

  const [showFlaggedOnly, setShowFlaggedOnly] = useState(false);

  const reportQuestions = useMemo(() => {
    if (showFlaggedOnly) {
      const flaggedQuestions = questions.filter((question) => {
        const validResponse =
          reportReview?.[question?.id]?.overrideResponse || response[question.id];

        if (question.type === QUESTION_TYPE.SINGLE) {
          return (validResponse as QuestionOption)?.isFlagged;
        }

        if (question.type === QUESTION_TYPE.MULTI) {
          return (validResponse as QuestionOption[])?.some((option) => option.isFlagged);
        }

        return false;
      });

      return flaggedQuestions;
    }

    return questions;
  }, [questions, reportReview, response, showFlaggedOnly]);

  const data = useMemo<QuestionRow[]>(() => {
    const formatQuestion = (question: SurveyQuestion, order: string) => ({
      id: question.id,
      question: `${order}. ${question.name}`,
      answer: response[question.id],
      overrideAnswer: reportReview?.[question.id]?.overrideResponse,
      overriddenBy:
        reportReview?.[question.id]?.type === REVIEW_TYPE.OVERRIDE
          ? reportReview?.[question?.id]?.reviewedBy?.fullName
          : '',
      overrideReason:
        reportReview?.[question?.id]?.type === REVIEW_TYPE.OVERRIDE
          ? reportReview?.[question?.id]?.reason
          : '',
      type: question.type,
      evaluationScore: evaluation?.[question?.id]?.weight,
      evaluationColor: evaluation?.[question?.id]?.color,
      evaluationLabel: evaluation?.[question?.id]?.name,
    });
    return reportQuestions
      .map((question, i) => {
        if (question.type === QUESTION_TYPE.SINGLE) {
          const selectedOption = question.options.find(
            (o) => (response[question.id] as QuestionOption)?.id === o.id
          );

          const subQuestions = selectedOption?.subQuestions || [];

          const formattedSubQuestions = subQuestions.map((subQuestion, index) =>
            formatQuestion(subQuestion, `${i + 1}.${index + 1}`)
          );

          return [formatQuestion(question, `${i + 1}`), ...formattedSubQuestions];
        }
        return formatQuestion(question, `${i + 1}`);
      })
      .flatMap((q) => q);
  }, [reportQuestions, response, reportReview, evaluation]);

  const onToggleFlaggedOnly = () => {
    setShowFlaggedOnly((prev) => !prev);
  };

  const commonColumns = useMemo(
    () => [
      columnHelper.accessor('question', {
        cell: (info) => (
          <CellWrapper>
            <Typography variant='body' color={colors.primary[90]}>
              {info.getValue()}
            </Typography>
          </CellWrapper>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Question
          </Typography>
        ),
        meta: {
          width: '35%',
        },
      }),
      columnHelper.accessor('answer', {
        cell: (info) => (
          <AssessmentAnswerCell
            answer={info.getValue()}
            type={info.row.original.type}
            overrideAnswer={info.row.original.overrideAnswer}
            overrideReason={info.row.original.overrideReason}
          />
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Answer
          </Typography>
        ),
        meta: {
          width: '45%',
        },
      }),
    ],
    [colors.primary]
  );

  const overriddenByColumn = columnHelper.accessor('overriddenBy', {
    cell: (info) => <AvatarCell name={info.getValue() || ''} />,
    header: () => (
      <Typography variant='overline' color={colors.primary[70]}>
        Overridden By
      </Typography>
    ),
    meta: {
      width: '5%',
    },
  });

  const evaluationScoreColumn = columnHelper.accessor('evaluationLabel', {
    cell: (info) => (
      <EvaluationScorePreview
        color={colors[info.row.original.evaluationColor || 'success'][40]}
        label={info.getValue() || ''}
        evaluationScale={evaluationScale}
        score={info.row.original.evaluationScore || undefined}
      />
    ),
    header: () => (
      <Typography variant='overline' color={colors.primary[70]}>
        Evaluation Score
      </Typography>
    ),
    meta: {
      width: '15%',
    },
  });

  const hasOverriddenAnswers = useMemo(() => {
    if (!reportReview) return false;
    return Object.values(reportReview)?.some((review) => review?.reviewedBy);
  }, [reportReview]);

  const hasEvaluation = useMemo(() => {
    if (!evaluation) return false;
    return Object.values(evaluation)?.some((questionEval) => questionEval?.name);
  }, [evaluation]);

  const columns = useMemo(() => {
    const cols = commonColumns;

    if (hasEvaluation) {
      cols.push(evaluationScoreColumn);
    }

    if (hasOverriddenAnswers) {
      cols.push(overriddenByColumn);
    }

    return cols;
  }, [
    commonColumns,
    evaluationScoreColumn,
    hasEvaluation,
    hasOverriddenAnswers,
    overriddenByColumn,
  ]);

  const table = useReactTable({
    data: data ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (question) => String(question.id),
  });

  const showOverrideColumn = useMemo(
    () =>
      reportReview &&
      Object.values(reportReview)?.some((review) => Boolean(review?.overrideResponse)),
    [reportReview]
  );

  return (
    <Wrapper>
      <FiltersWrapper>
        <FiltersContainer>
          <SearchInput
            placeholder='Search for a question'
            style={{ width: '345px', marginRight: '24px' }}
            onClear={() => table.getColumn('question')?.setFilterValue('')}
            onChange={(e) => table.getColumn('question')?.setFilterValue(e.target.value)}
            value={table.getColumn('question')?.getFilterValue() ?? ''}
          />
          <Button
            variant='outlined'
            onClick={onToggleFlaggedOnly}
            style={{
              fontWeight: theme.text.weight.regular,
              color: showFlaggedOnly ? theme.colors.accent[50] : theme.colors.primary[90],
              background: showFlaggedOnly ? theme.colors.accent[5] : 'transparent',
              borderColor: showFlaggedOnly ? theme.colors.accent[50] : theme.colors.primary[30],
              height: '32px',
            }}
          >
            Flagged Only
          </Button>
        </FiltersContainer>
        <ExportButton
          surveyName={survey.name}
          data={data}
          showOverrideColumn={showOverrideColumn}
          hasEvaluation={hasEvaluation}
        />
      </FiltersWrapper>
      <Table
        table={table}
        height='auto'
        trStyle={{ height: 'auto' }}
        tdStyle={{
          paddingTop: '10px',
          paddingBottom: '10px',
          verticalAlign: 'baseline',
        }}
        disableAnimation={true}
      />
    </Wrapper>
  );
};
