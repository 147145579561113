import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import {
  isConfirmBarActiveState,
  isMoveModeActiveState,
  selectedCoreKpiCategoryState,
  selectedCoreKpiState,
} from '../../../state/UIState';
import { useAppConfig } from '../../../queries/useAppConfig';

export const useOnCancelConfirm = () => {
  const setIsActive = useSetAtom(isConfirmBarActiveState);
  const setIsMoveModeActive = useSetAtom(isMoveModeActiveState);
  const setSelectedCategory = useSetAtom(selectedCoreKpiCategoryState);
  const setSelectedKpi = useSetAtom(selectedCoreKpiState);

  const { data: appConfigData } = useAppConfig();

  const onCancel = useCallback(() => {
    appConfigData?.data.isCoreKpiSurveyUpToDate && setIsActive(false);
    setSelectedCategory(null);
    setSelectedKpi(null);
    setIsMoveModeActive(false);
  }, [
    appConfigData?.data.isCoreKpiSurveyUpToDate,
    setIsActive,
    setIsMoveModeActive,
    setSelectedCategory,
    setSelectedKpi,
  ]);

  return { onCancel };
};
