import { IconButton, styled, useTheme } from '@mui/material';
import {
  APPLY_EVALUATION_RULES_HEADER_CONTROLS,
  PAGE_HEADER_HEIGHT,
} from '../../../constants/layoutSizes';
import { Typography } from '../../Typography/Typography';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-blue.svg';
import { ReactNode } from 'react';
import { Tooltip } from '../../Tooltip/Tooltip';

const Wrapper = styled('div')<{ isActive: boolean }>`
  padding: 0 28px 24px 28px;
  width: 380px;
  max-width: 380px;
  height: ${({ isActive }) =>
    `calc(100vh - ${
      isActive ? PAGE_HEADER_HEIGHT + APPLY_EVALUATION_RULES_HEADER_CONTROLS : PAGE_HEADER_HEIGHT
    }px)`};
  border-right: 1px solid ${({ theme }) => theme.colors.primary[20]};
  overflow-y: auto;
`;

const SideHeaderWrapper = styled('div')`
  padding: 24px 0 12px 0;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.primary[5]};
  z-index: 10;
`;
const SectionControlWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  isActive: boolean;
  title: string;
  onAddCategory: () => void;
  children: ReactNode;
}

export const DataCollectionSideSection = ({ isActive, title, onAddCategory, children }: Props) => {
  const { colors } = useTheme();

  return (
    <Wrapper isActive={isActive}>
      <SideHeaderWrapper>
        <SectionControlWrapper>
          <Typography variant='h4' color={colors.primary[90]}>
            {title}
          </Typography>
          <Tooltip
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [55, -13],
                  },
                },
              ],
            }}
            title='Add Category'
          >
            <IconButton onClick={onAddCategory} style={{ marginBottom: '-2px' }}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </SectionControlWrapper>
      </SideHeaderWrapper>
      {children}
    </Wrapper>
  );
};
