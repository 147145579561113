import { styled, useTheme } from '@mui/material';
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';
import { RequestProgressCell } from '../../../components/Table/CellRenderers/RequestProgressCell';
import { RequestStatusCell } from '../../../components/Table/CellRenderers/RequestStatusCell';
import { Table } from '../../../components/Table/Table';
import { Typography } from '../../../components/Typography/Typography';
import {
  Report,
  SelectItem,
  REPORT_STATUS,
  EvaluationRule,
  EVALUATION_SCALE,
} from '../../../types';
import { getMonthLabel } from '../../../utils/formatters';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { SendReportAgainCTA } from './SendReportAgainCTA';
import { getNumberOfFlaggedAnswers } from '../../../utils/getNumberOfFlaggedAnswers';
import { FlagsIndicator } from '../../../components/FlagsIndicator/FlagsIndicator';
import { getAvgEvaluationScore } from '../../../utils/getAvgEvaluationScore';
import { EvaluationScorePreview } from '../../../components/EvaluationScorePreview/EvaluationScorePreview';

interface Props {
  reports: Report[];
}

const Wrapper = styled('div')`
  padding: 20px 40px;
`;

const SectionHeader = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`;

const SendAgainContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

type SendAgainTableData = {
  reportId: number;
  status: REPORT_STATUS;
};

interface ResponseTableData {
  id: number;
  surveyName: string;
  status: REPORT_STATUS;
  requestGroupName: string;
  month: number;
  year: number;
  progress: number;
  sendAgain: SendAgainTableData;
  flags: number;
  avgEvaluationScoreRule?: EvaluationRule | null;
  evaluationScale?: EVALUATION_SCALE;
}

const columnHelper = createColumnHelper<ResponseTableData>();

export const ResponsesTable: FC<Props> = ({ reports }) => {
  const { colors } = useTheme();
  const [date, setDate] = useState<Date | null>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<SelectItem | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<SelectItem | null>(null);
  const navigate = useNavigate();

  const [data, setData] = useState<ResponseTableData[]>(() => []);

  useEffect(() => {
    const responseTableData: ResponseTableData[] = reports.map((report) => {
      const flagsNum = getNumberOfFlaggedAnswers(report);
      const avgEvalScore = getAvgEvaluationScore(report);
      const evaluationRules = report?.survey.evaluationRules;
      const avgRule = evaluationRules?.find((rule) => rule.weight === avgEvalScore);

      return {
        id: report.id,
        surveyName: report.survey.name,
        status: report.status,
        requestGroupName: report?.request?.name ?? '',
        month: report.month,
        year: report.year,
        progress: report.progress,
        sendAgain: {
          reportId: report.id,
          status: report.status,
        },
        flags: flagsNum || 0,
        avgEvaluationScoreRule: avgRule,
        evaluationScale: report.survey.evaluationScale,
      };
    });
    if (!date) {
      setData(responseTableData);
      return;
    }
    const selectedMonth = date.getMonth() + 1;
    const selectedYear = date.getFullYear();
    const filteredData = responseTableData.filter(
      (report) => report.month === selectedMonth && report.year === selectedYear
    );
    setData(filteredData);
  }, [date, reports]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('surveyName', {
        cell: (info) => (
          <Typography
            variant='body'
            color={
              [REPORT_STATUS.APPROVED, REPORT_STATUS.IN_REVIEW, REPORT_STATUS.REJECTED].includes(
                info.row.original.status
              )
                ? colors.accent[50]
                : colors.primary[90]
            }
          >
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Survey
          </Typography>
        ),
        meta: {
          width: '23%',
        },
        filterFn: 'equalsString',
        enableColumnFilter: true,
      }),
      columnHelper.accessor('status', {
        cell: (info) => <RequestStatusCell status={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Status
          </Typography>
        ),
        meta: {
          width: '9%',
        },
        filterFn: 'equalsString',
        enableColumnFilter: true,
      }),
      columnHelper.accessor('avgEvaluationScoreRule', {
        cell: (info) =>
          info.getValue() ? (
            <EvaluationScorePreview
              color={colors[info.getValue()?.color || 'success'][40]}
              evaluationScale={info.row.original.evaluationScale}
              label={info.getValue()?.name || ''}
              score={info.getValue()?.weight}
            />
          ) : null,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Evaluation Score
          </Typography>
        ),
        meta: {
          width: '7%',
        },
      }),
      columnHelper.accessor('flags', {
        cell: (info) => <FlagsIndicator flagsNum={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Flags
          </Typography>
        ),
        meta: {
          width: '7%',
        },
      }),
      columnHelper.accessor('requestGroupName', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Request
          </Typography>
        ),
        meta: {
          width: '17%',
        },
      }),
      columnHelper.accessor('month', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {`${getMonthLabel(info.getValue() as number)} ${info.row.original.year}`}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Month
          </Typography>
        ),
        meta: {
          width: '12%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('progress', {
        cell: (info) => <RequestProgressCell progress={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Coverage Progress
          </Typography>
        ),
        size: 180,
        meta: {
          width: '12%',
        },
        sortDescFirst: false,
      }),
      columnHelper.accessor('sendAgain', {
        cell: (info) => {
          return (
            (info.getValue().status === REPORT_STATUS.IN_PROGRESS ||
              info.getValue().status === REPORT_STATUS.NOT_STARTED) && (
              <SendAgainContainer>
                <SendReportAgainCTA reportId={info.getValue().reportId} />
              </SendAgainContainer>
            )
          );
        },
        header: () => <div />,
        size: 180,
        meta: {
          width: '20%',
        },
      }),
    ],
    [colors]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const sortedUniqueSurveys = useMemo(
    () =>
      Array.from(table.getColumn('surveyName')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((value, id) => ({ id, value })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, data]
  );
  const onChangeSurveyFilter = useCallback(
    (survey: SelectItem | null) => {
      setSelectedSurvey(survey);
      table.getColumn('surveyName')?.setFilterValue(survey?.value ?? '');
    },
    [table]
  );

  const sortedUniqueStatuses = useMemo(
    () =>
      Array.from(table.getColumn('status')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((value, id) => ({ id, value })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, data]
  );

  const onChangeStatusFilter = useCallback(
    (status: SelectItem | null) => {
      setSelectedStatus(status);
      table.getColumn('status')?.setFilterValue(status?.value ?? '');
    },
    [table]
  );

  const onRowClick = useCallback(
    (row: Row<ResponseTableData>) => {
      const reportId = row.original.id;
      const status = row.original.status;
      if (status === REPORT_STATUS.NOT_STARTED || status === REPORT_STATUS.IN_PROGRESS) return;

      if (status === REPORT_STATUS.APPROVED) {
        navigate(`/${ROUTES.ASSESSMENTS}/${reportId}`);
        return;
      }

      navigate(`/${ROUTES.REPORTS}/${reportId}`);
    },
    [navigate]
  );

  return (
    <Wrapper>
      <SectionHeader>
        <Typography variant='h4' color={colors.primary[90]}>
          Responses
        </Typography>
        <DatePicker
          selectedDate={date?.toISOString() ?? null}
          setSelectedDate={setDate}
          views={['year', 'month']}
          clearable
        />
      </SectionHeader>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a request'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('requestGroupName')?.setFilterValue('')}
          onChange={(e) => table.getColumn('requestGroupName')?.setFilterValue(e.target.value)}
          value={table.getColumn('requestGroupName')?.getFilterValue() ?? ''}
        />
        <SingleSelect
          style={{ width: '200px' }}
          options={sortedUniqueStatuses}
          value={selectedStatus}
          onChange={(_, status) => onChangeStatusFilter(status)}
          disablePortal
          fieldPlaceholder='Filter by Status'
        />
        <SingleSelect
          style={{ width: '200px' }}
          options={sortedUniqueSurveys}
          value={selectedSurvey}
          onChange={(_, survey) => onChangeSurveyFilter(survey)}
          disablePortal
          fieldPlaceholder='Filter by Survey'
        />
      </FiltersWrapper>
      <Table table={table} height='auto' onRowClick={onRowClick} />
    </Wrapper>
  );
};
