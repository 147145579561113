import { useMutation } from 'react-query';
import { Http } from '../services/Http';
import { Objective, LoadingId, REPORT_STATUS, SURVEY_FREQUENCY } from '../types';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { useInvalidateCompanyById } from './useCompanies';

export interface ObjectivePayload {
  id?: number;
  name: string;
  frequency: SURVEY_FREQUENCY;
  company: number;
  status: REPORT_STATUS;
  progress: number;
}

async function createObjective(payload: Partial<ObjectivePayload>): Promise<Objective> {
  const { data } = await Http.axios.post<Partial<ObjectivePayload>, Objective>(
    `/objective`,
    payload
  );
  return data;
}

export function useCreateObjective(companyId: number) {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  const invalidateCompanyById = useInvalidateCompanyById();

  return useMutation(createObjective, {
    onMutate: async () => {
      startLoading(LoadingId.createObjective);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create objective' });
    },
    onSettled: () => {
      invalidateCompanyById(companyId);
      stopLoading(LoadingId.createObjective);
    },
  });
}

async function updateObjective(objective: Partial<ObjectivePayload>): Promise<Objective> {
  const { id, ...payload } = objective;
  const { data } = await Http.axios.patch<Partial<ObjectivePayload>, Objective>(
    `/objective/${id}`,
    payload
  );
  return data;
}

export function useUpdateObjective(companyId: number) {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const invalidateCompanyById = useInvalidateCompanyById();

  return useMutation(updateObjective, {
    onMutate: async () => {
      startLoading(LoadingId.updateObjective);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update objective' });
    },
    onSettled: () => {
      invalidateCompanyById(companyId);
      stopLoading(LoadingId.updateObjective);
    },
  });
}

async function deleteObjective(id: number): Promise<number> {
  const { data } = await Http.axios.delete<number>(`/objective/${id}`);
  return data;
}

export function useDeleteObjective(companyId: number) {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const invalidateCompanyById = useInvalidateCompanyById();

  return useMutation(deleteObjective, {
    onMutate: async () => {
      startLoading(LoadingId.deleteObjective);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to delete objective' });
    },
    onSettled: () => {
      invalidateCompanyById(companyId);
      stopLoading(LoadingId.deleteObjective);
    },
  });
}
