import { Button, styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';

import { useCallback } from 'react';
import { ROUTES } from '../../../constants/routes';
import { useNavigate } from 'react-router';

const MainSection = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ControllsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SurveysHeader = () => {
  const { colors } = useTheme();

  const navigate = useNavigate();

  const onCreateSurveyClick = useCallback(() => {
    navigate(`/${ROUTES.CREATE_SURVEY}/build`);
  }, [navigate]);

  return (
    <MainSection>
      <TitleWrapper>
        <Typography variant='h3' color={colors.primary[90]}>
          Surveys
        </Typography>
        <Typography variant='body' color={colors.primary[70]}>
          ESG survey evaluates sustainability via ESG practices.
        </Typography>
      </TitleWrapper>
      <ControllsWrapper>
        <Button
          onClick={onCreateSurveyClick}
          startIcon={<AddIcon />}
          variant='contained'
          style={{ width: '179px' }}
        >
          Create Survey
        </Button>
      </ControllsWrapper>
    </MainSection>
  );
};
