import ContentLoader from 'react-content-loader';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')``;
const HeaderWrapper = styled('div')`
  padding: 0 40px;
  height: 140px;
`;

const TwoColumnLoader = styled('div')`
  padding: 0 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const FourColumnLoader = styled('div')`
  padding: 0 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
`;

const SectionLoader = styled('div')`
  padding: 0 40px;
`;

export function CompanyPageSkeletonLoader(): ReactElement {
  return (
    <SkeletonWrapper>
      <HeaderWrapper>
        <ContentLoader height={70} width={'100%'}>
          <rect width='100' height='30' y='20' rx='4' fill='#EEEFF1' />
          <rect width='100' height='30' y='20' x='120' fill='#EEEFF1' />
        </ContentLoader>
        <ContentLoader height={70} width={'100%'}>
          <rect width='250' height='40' y='0' fill='#EEEFF1' />
          <rect width='90' height='36' x='282' y='2' fill='#EEEFF1' />
          <rect width='90' height='36' x='380' y='2' fill='#EEEFF1' />
          <rect width='90' height='36' x='478' y='2' fill='#EEEFF1' />
        </ContentLoader>
      </HeaderWrapper>
      <TwoColumnLoader>
        <ContentLoader height={234} width={'100%'}>
          <rect width='100%' height='210' y='24' rx='4' fill='#EEEFF1' />
        </ContentLoader>
        <ContentLoader height={234} width={'100%'}>
          <rect width='100%' height='210' y='24' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </TwoColumnLoader>
      <TwoColumnLoader>
        <ContentLoader height={226} width={'100%'}>
          <rect width='100%' height='210' y='16' rx='4' fill='#EEEFF1' />
        </ContentLoader>
        <ContentLoader height={226} width={'100%'}>
          <rect width='100%' height='210' y='16' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </TwoColumnLoader>
      <SectionLoader>
        <ContentLoader height={62} width={'100%'}>
          <rect width='250px' height='34' y='28' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </SectionLoader>
      <FourColumnLoader>
        <ContentLoader height={274} width={'100%'}>
          <rect width='100%' height='266' y='8' rx='4' fill='#EEEFF1' />
        </ContentLoader>
        <ContentLoader height={274} width={'100%'}>
          <rect width='100%' height='266' y='8' rx='4' fill='#EEEFF1' />
        </ContentLoader>
        <ContentLoader height={274} width={'100%'}>
          <rect width='100%' height='266' y='8' rx='4' fill='#EEEFF1' />
        </ContentLoader>
        <ContentLoader height={274} width={'100%'}>
          <rect width='100%' height='266' y='8' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </FourColumnLoader>
      <SectionLoader>
        <ContentLoader height={62} width={'100%'}>
          <rect width='120px' height='34' y='28' rx='4' fill='#EEEFF1' />
          <rect width='120px' height='34' x='128' y='28' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </SectionLoader>
      <SectionLoader>
        <ContentLoader height={250} width={'100%'}>
          <rect width='345' height='34' y='20' rx='4' fill='#EEEFF1' />
          <rect width='240px' height='34' x='369' y='20' rx='4' fill='#EEEFF1' />
          <rect width='240px' height='34' x='621' y='20' rx='4' fill='#EEEFF1' />
          <rect width='100%' height='24' y='70' rx='4' fill='#EEEFF1' />

          <rect y='102' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='143' width='100%' height='38' rx='4' fill='#EEEFF1' />
          <rect y='184' width='100%' height='38' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </SectionLoader>
    </SkeletonWrapper>
  );
}
