import { styled } from '@mui/material';
import { WorkflowStep } from '../../../types';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ReactNode } from 'react';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

interface Props {
  steps: WorkflowStep[];
  children: ReactNode;
  handleReorder: (event: DragEndEvent) => void;
}

export const StepsList = ({ steps, children, handleReorder }: Props) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  return (
    <Wrapper>
      <DndContext sensors={sensors} onDragEnd={handleReorder} collisionDetection={closestCenter}>
        <SortableContext items={steps ?? []} strategy={verticalListSortingStrategy}>
          {children}
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};
