import { Button, styled, useTheme } from '@mui/material';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info-blue.svg';
import { ReactComponent as SquareArrowIcon } from '../../../assets/icons/square-arrow-up.svg';
import { Typography } from '../../../components/Typography/Typography';

const InfoContent = styled('div')`
  padding: 8px 12px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.accent[10]};
  border-radius: 2px;
  align-items: center;
`;

export const InfoBox = () => {
  const { colors } = useTheme();

  const onButtonClick = () => {
    window.open(
      'https://starcier.notion.site/Upload-List-of-Companies-d1892e905ed34fdab946a00c8951949c',
      '_blank'
    );
  };

  return (
    <InfoContent>
      <InfoIcon />
      <Typography variant='body' color={colors.accent[60]} style={{ marginLeft: '8px' }}>
        Having trouble importing a spreadsheet?
      </Typography>
      <Button
        onClick={onButtonClick}
        startIcon={<SquareArrowIcon />}
        variant='outlined'
        style={{ height: '32px', marginLeft: '16px' }}
      >
        View Instructions
      </Button>
    </InfoContent>
  );
};
