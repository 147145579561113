import { Button } from '@mui/material';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router';
import { companyKpisDataState, uploadedCompanyKpisFileState } from '../../../state/UIState';
import { useUpdateCompany } from '../../../queries/useCompanies';
import { useCallback, useMemo } from 'react';
import { ROUTES } from '../../../constants/routes';

export const ControlButtons = () => {
  const { activeStep: activeStepParam, companyId } = useParams<{
    activeStep: string;
    companyId: string;
  }>();
  const activeStep = Number(activeStepParam);
  const navigate = useNavigate();
  const [file, setFile] = useAtom(uploadedCompanyKpisFileState);
  const [companyKpisData] = useAtom(companyKpisDataState);
  const updateCompany = useUpdateCompany();

  const isNextDisabled = useMemo(() => {
    if (activeStep === 1) {
      return !file;
    }
    return false;
  }, [activeStep, file]);

  const resetState = useCallback(() => {
    setFile(null);
  }, [setFile]);

  const onCancel = useCallback(() => {
    navigate(`/${ROUTES.COMPANIES}/${companyId}/${ROUTES.COMPANY_PERFORMANCE}`);
    resetState();
  }, [companyId, navigate, resetState]);

  const onNext = useCallback(async () => {
    if (activeStep === 1) {
      navigate(`${2}`);
      return;
    }
    if (activeStep === 2) {
      if (!companyKpisData || !companyId) return;
      await updateCompany.mutateAsync({ id: Number(companyId), kpisData: companyKpisData });
      navigate(`/${ROUTES.COMPANIES}/${companyId}/${ROUTES.COMPANY_PERFORMANCE}`);
      resetState();
      return;
    }
  }, [activeStep, companyId, companyKpisData, navigate, resetState, updateCompany]);
  return (
    <>
      <Button variant='outlined' style={{ width: '95px' }} onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant='contained'
        style={{ width: '95px' }}
        disabled={isNextDisabled}
        onClick={onNext}
      >
        {activeStep === 1 ? 'Next' : 'Upload'}
      </Button>
    </>
  );
};
