import { ColorPalette } from '../theme/structure';
import { EVALUATION_SCALE, EvaluationRule } from '../types';

const evaluationBinaryRules: EvaluationRule[] = [
  {
    name: 'The company has not presented sufficient evidence or didn’t respond',
    weight: 0,
    color: 'error',
  },
  {
    name: 'The company has presented a sufficient response',
    weight: 1,
    color: 'success',
  },
];

const evaluationRangeRules: EvaluationRule[] = Array.from({ length: 7 }, (v, k) => k + 1)?.map(
  (item) => {
    let color = 'success' as keyof ColorPalette;

    if (item < 3) {
      color = 'error';
    }

    if (item >= 3 && item <= 5) {
      color = 'warning';
    }

    return {
      name: String(item),
      weight: item,
      color,
    };
  }
);

export const getEvaluationRules = (evaluationScale?: EVALUATION_SCALE) => {
  if (!evaluationScale) return [];
  if (evaluationScale === EVALUATION_SCALE.RANGE) return evaluationRangeRules;

  return evaluationBinaryRules;
};
