import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import { Exclusion } from '../types';
import { useCallback } from 'react';

const COMPANY_EXCLUSIONS = 'company-exclusions';

async function fetchCompanyExclusions(companyId: number): Promise<Exclusion[]> {
  const { data } = await Http.axios.get<Exclusion[]>(`/exclusion/company/${companyId}`);
  const sorted = data?.sort((a, b) =>
    a?.condition?.category?.name?.localeCompare(b?.condition?.category?.name)
  );
  return sorted;
}

export function useExclusionsByCompany(companyId: number) {
  return useQuery(`${COMPANY_EXCLUSIONS}-${companyId}`, () => fetchCompanyExclusions(companyId), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

export function useInvalidateExclusionsByCompany() {
  const queryClient = useQueryClient();

  return useCallback(
    (companyId: number) => queryClient.invalidateQueries(`${COMPANY_EXCLUSIONS}-${companyId}`),
    [queryClient]
  );
}
