export enum ROUTES {
  PORTFOLIO = 'portfolio',
  SURVEYS = 'surveys',
  EXCLUSIONS = 'exclusions',
  REPORTS = 'reports',
  COMPANIES = 'companies',
  REQUESTS = 'requests',
  UNASSIGNED_COMPANIES = 'unassigned-companies',
  CREATE_REQUEST = 'create-request',
  CREATE_SURVEY = 'create-survey',
  PORTAL = 'esg-portal',
  LOGIN = 'login',
  NEW_PASSWORD_REQUIRED = 'new-password-required',
  UPLOAD_COMPANIES = 'uplaod-companies',
  UPLOAD_COMPANY_KPIS = 'uplaod-company-kpis',
  ASSESSMENTS = 'assessments',
  INVESTOR_EXCLUSIONS = 'investor-exclusions',
  QUESTIONNAIRES = 'questionnaires',
  CORE_KPIS = 'core-kpis',
  DEFAULT_SURVEYS = 'default-surveys',
  CORE_EXCLUSIONS = 'core-exclusions',
  PERMISSIONS = 'permissions',
  COMPANY_OVERVIEW = 'company-overview',
  COMPANY_PERFORMANCE = 'company-performance',
  COMPANY_CHECKLIST = 'company-checklist',
  CHECKLIST = 'checklist',
  USER_MANAGEMENT = 'user-management',
  ISSUERS = 'issuers',
  EXPORT_COMPANIES = 'export-companies',
  ADD_EXCLUSIONS = 'add-exclusions',
  PENDING_EVALUATION = 'pending-evaluation',
  EXTERNAL_ID = 'external-id',
  WORKFLOWS = 'workflows',
  CONTROL_WORKFLOW = 'port-co-workflow',
  NOT_CONTROL_WORKFLOW = 'issuers-workflow',
  REVIEW_REPORT = 'review-report',
  COMPANIES_PAGE = 'companies-page',
  WORKFLOW_PREVIEW = 'workflow-preview',
}
