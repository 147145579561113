import { useMutation } from 'react-query';
import { Http } from '../services/Http';
import {
  KEY_TASK_CONDITION_OPERATOR,
  KEY_TASK_GOAL_TYPE,
  LoadingId,
  KeyTask,
  REPORT_STATUS,
} from '../types';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { useInvalidateCompanyById } from './useCompanies';

export interface KeyTaskPayload {
  id?: number;
  goalType: KEY_TASK_GOAL_TYPE;
  objective: number;
  kpi?: number | null;
  checklistItem?: number | null;
  conditionOperator: KEY_TASK_CONDITION_OPERATOR;
  conditionValue: string;
  status: REPORT_STATUS;
  progress: number;
}

async function createKeyTask(payload: Partial<KeyTaskPayload>): Promise<KeyTask> {
  const { data } = await Http.axios.post<Partial<KeyTaskPayload>, KeyTask>(`/key-task`, payload);
  return data;
}

export function useCreateKeyTask(companyId: number) {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  const invalidateCompanyById = useInvalidateCompanyById();

  return useMutation(createKeyTask, {
    onMutate: async () => {
      startLoading(LoadingId.createKeyTask);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create key task' });
    },
    onSettled: () => {
      invalidateCompanyById(companyId);
      stopLoading(LoadingId.createKeyTask);
    },
  });
}

async function updateKeyTask(keyTask: Partial<KeyTaskPayload>): Promise<KeyTask> {
  const { id, ...payload } = keyTask;
  const { data } = await Http.axios.patch<Partial<KeyTaskPayload>, KeyTask>(
    `/key-task/${id}`,
    payload
  );
  return data;
}

export function useUpdateKeyTask(companyId: number) {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const invalidateCompanyById = useInvalidateCompanyById();

  return useMutation(updateKeyTask, {
    onMutate: async () => {
      startLoading(LoadingId.updateKeyTask);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update key task' });
    },
    onSettled: () => {
      invalidateCompanyById(companyId);
      stopLoading(LoadingId.updateKeyTask);
    },
  });
}

async function deleteKeyTask(id: number): Promise<number> {
  const { data } = await Http.axios.delete<number>(`/key-task/${id}`);
  return data;
}

export function useDeleteKeyTask(companyId: number) {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const invalidateCompanyById = useInvalidateCompanyById();

  return useMutation(deleteKeyTask, {
    onMutate: async () => {
      startLoading(LoadingId.deleteKeyTask);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to delete key task' });
    },
    onSettled: () => {
      invalidateCompanyById(companyId);
      stopLoading(LoadingId.deleteKeyTask);
    },
  });
}
