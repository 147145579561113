import { styled } from '@mui/material';
import { NoteBox } from './NoteBox/NoteBox';
import { CSSProperties, Dispatch, SetStateAction, useState } from 'react';
import {
  FloatingFocusManager,
  OffsetOptions,
  Placement,
  Strategy,
  autoUpdate,
  flip,
  offset,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from '@floating-ui/react';

const NotesBoxWrapper = styled('div')`
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  outline: none;
`;

interface Props {
  onChangeNotes?: (notes: string) => void;
  notes: string;
  setIsDeleteModalOpen?: Dispatch<SetStateAction<boolean>>;
  withActionButtons?: boolean;
  containerStyle?: CSSProperties;
  children: React.ReactNode;
  popupPlacement?: Placement;
  popupOffset?: OffsetOptions;
  popupStrategy?: Strategy;
  isDisabled?: boolean;
}

export const NotesBoxPopup = ({
  notes,
  setIsDeleteModalOpen,
  onChangeNotes,
  withActionButtons,
  children,
  popupPlacement = 'bottom',
  popupOffset = {},
  containerStyle = {},
  popupStrategy = 'absolute',
  isDisabled,
}: Props) => {
  const [isNotesBoxShown, setIsNotesBoxShown] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: popupPlacement,
    strategy: popupStrategy,
    open: isNotesBoxShown,
    onOpenChange: setIsNotesBoxShown,
    whileElementsMounted: autoUpdate,
    middleware: [flip({ fallbackAxisSideDirection: 'end' }), offset(popupOffset)],
  });

  const hover = useHover(context, { delay: { close: 300 } });
  const focus = useFocus(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus]);

  return (
    <div ref={refs.setReference} {...getReferenceProps()} style={containerStyle}>
      {children}
      {isNotesBoxShown && (
        <FloatingFocusManager context={context} modal={false}>
          <NotesBoxWrapper
            onClick={(e) => e.stopPropagation()}
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 14 }}
            {...getFloatingProps()}
          >
            <NoteBox
              notes={notes}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              withActionButtons={withActionButtons}
              onNotesChange={onChangeNotes}
              isDisabled={isDisabled}
            />
          </NotesBoxWrapper>
        </FloatingFocusManager>
      )}
    </div>
  );
};
