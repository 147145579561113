import { useFormik } from 'formik';
import { Modal } from '../../../components/Modal/Modal';
import { CHECKLIST_ITEM_FREQUENCY, ChecklistItem } from '../../../types';
import { ChecklistItemPayload } from '../../../queries/useChecklistItems';
import { TextField, styled } from '@mui/material';
import { FormField } from '../../../components/FormField/FormField';
import { ChipsSelectorV2 } from '../../../components/ButtonSelector/ChipsSelectorV2';
import { Textarea } from '../../../components/Textarea/Textarea';
import { useCallback, useMemo } from 'react';
import { ChecklistItemSchema as validationSchema } from '../schemas/ChecklistItemSchema';

interface Props {
  title: string;
  onClose: () => void;
  onSubmit: (payload: Partial<ChecklistItemPayload>) => void;
  confirmBtnText: string;
  checklistItem?: ChecklistItem | null;
}

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const frequencyOptions = [
  {
    id: CHECKLIST_ITEM_FREQUENCY.QUARTERLY,
    value: 'Quarterly',
  },
  {
    id: CHECKLIST_ITEM_FREQUENCY.ANNUALLY,
    value: 'Annually',
  },
];

export const EditCreateChecklistItem = ({
  title,
  onClose,
  onSubmit,
  confirmBtnText,
  checklistItem,
}: Props) => {
  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    dirty,
  } = useFormik<Partial<ChecklistItemPayload>>({
    initialValues: {
      name: checklistItem?.name ?? '',
      instructions: checklistItem?.instructions ?? '',
      frequency: checklistItem?.frequency ?? CHECKLIST_ITEM_FREQUENCY.QUARTERLY,
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleError = (field: keyof ChecklistItemPayload) => (touched[field] ? errors[field] : '');

  const selectedFrequency = useMemo(
    () => frequencyOptions.find((o) => o.id === values.frequency),
    [values.frequency]
  );

  const onChangeFrequency = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('frequency', '');
        return;
      }
      setFieldValue('frequency', option?.id);
    },
    [setFieldValue]
  );

  return (
    <Modal
      isOpen={true}
      title={title}
      onClose={onClose}
      onConfirm={handleSubmit}
      cancelBtnText='Cancel'
      confirmBtnText={confirmBtnText}
      isConfirmDisabled={!isValid || !dirty}
      contentContainerStyle={{ paddingBottom: '16px' }}
    >
      <FormWrapper>
        <FormField label={'Name'} error={handleError('name')} required>
          <TextField
            name={'name'}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant='outlined'
          />
        </FormField>
        <FormField label={'Frequency'} error={handleError('frequency')} required>
          <ChipsSelectorV2
            options={frequencyOptions}
            selectedItem={selectedFrequency || frequencyOptions[0]}
            onSelect={onChangeFrequency}
          />
        </FormField>
        <FormField label={'Instructions'} error={handleError('instructions')}>
          <Textarea
            style={{ height: '100px' }}
            name='instructions'
            value={values.instructions ?? ''}
            onChange={handleChange('instructions')}
            placeholder='Type instructions for respondents'
            maxLength={800}
            onBlur={handleBlur}
          />
        </FormField>
      </FormWrapper>
    </Modal>
  );
};
