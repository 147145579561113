import { useAnalystRatingStep } from './useAnalystRatingStep';

export const useIsAnalyticsStepCompleted = () => {
  const analyticsStep = useAnalystRatingStep();

  const getIsStepCompleted = (approvedBy: number[]) => {
    if (analyticsStep?.requiredUsersApproval?.length) {
      const requiredUsersApprovalIds = analyticsStep?.requiredUsersApproval?.map((user) => user.id);

      return requiredUsersApprovalIds?.every((id) => approvedBy?.includes(id));
    }

    return approvedBy?.length;
  };

  return getIsStepCompleted;
};
