import { useSortable } from '@dnd-kit/sortable';
import { ExclusionCondition } from '../../../../../types';
import { CSS } from '@dnd-kit/utilities';
import { styled } from '@mui/material';
import { ConditionCard } from './ConditionCard/ConditionCard';

const SortableWrapper = styled('div')``;

interface Props {
  condition: ExclusionCondition;
  isDraggable: boolean;
  conditionOrder: number;
}

export const Condition = ({ condition, isDraggable, conditionOrder }: Props) => {
  const { setNodeRef, transform, transition, attributes, listeners, isDragging } =
    useSortable(condition);
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableWrapper
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ ...style, opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 2 : 1 }}
    >
      <ConditionCard
        condition={condition}
        conditionOrder={conditionOrder}
        isDragging={isDragging}
        isDraggable={isDraggable}
      />
    </SortableWrapper>
  );
};
