import { FC, useCallback } from 'react';
import { SurveyQuestion, QuestionOption } from '../../../../../../../../../../types';

import { useUpdateSubQuestion } from '../hooks/useUpdateSubquestion';
import { EvaluationControls } from '../../../../../../components/EvaluationControls';

interface Props {
  question: SurveyQuestion;
  option: QuestionOption;
}

export const SubQuestionEvaluationControls: FC<Props> = ({ question, option }) => {
  const { updateSubQuestion } = useUpdateSubQuestion();

  const toggleCustomEvaluation = useCallback(() => {
    const updatedSubQuestion = {
      ...question,
      includeEvaluation: !question.includeEvaluation,
    };
    updateSubQuestion(updatedSubQuestion, option);
  }, [option, question, updateSubQuestion]);

  return <EvaluationControls question={question} toggleCustomEvaluation={toggleCustomEvaluation} />;
};
