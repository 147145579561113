import { COMPANY_INFO_STEP, WorkflowStep } from '../../../types';
import { AnalystRatingStep } from './AnalystRatingStep/AnalystRatingStep';
import { CompanyInformationStep } from './CompanyInformationStep';
import { ESGContactStep } from './ESGContactStep';

interface Props {
  step: WorkflowStep;
  hasAccess?: boolean;
  isBlocked?: boolean;
  hasEvaluationAccess?: boolean;
  isStepCompleted?: boolean;
}

export const CompanyInfoStep = ({
  step,
  hasAccess,
  isBlocked,
  hasEvaluationAccess,
  isStepCompleted,
}: Props) => {
  if (step.companyInfoStep === COMPANY_INFO_STEP.COMPANY_INFORMATION) {
    return (
      <CompanyInformationStep
        hasAccess={hasAccess}
        isBlocked={isBlocked}
        isStepCompleted={isStepCompleted}
      />
    );
  }

  if (step.companyInfoStep === COMPANY_INFO_STEP.ANALYST_RATING) {
    return (
      <AnalystRatingStep
        hasAccess={hasAccess}
        isBlocked={isBlocked}
        hasEvaluationAccess={hasEvaluationAccess}
        isStepCompleted={isStepCompleted}
      />
    );
  }

  if (step.companyInfoStep === COMPANY_INFO_STEP.ESG_CONTACT) {
    return (
      <ESGContactStep
        hasAccess={hasAccess}
        isBlocked={isBlocked}
        isStepCompleted={isStepCompleted}
      />
    );
  }
  return <></>;
};
