import { styled, useTheme } from '@mui/material';
import { CHECKLIST_OPTION } from '../../../../../types';
import { Typography } from '../../../../../components/Typography/Typography';

const ItemWrapper = styled('div')<{ hasNotes?: boolean }>`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled('div')<{ hasNotes?: boolean }>`
  background: ${({ hasNotes, theme }) => (hasNotes ? theme.colors.warning[20] : 'transparent')};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 4px;
`;

const IconWrapper = styled('div')<{ isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg path {
    fill: ${({ isOpen, theme }) => (isOpen ? theme.colors.accent[50] : theme.colors.primary[70])};
  }
`;

interface Props {
  icon: JSX.Element;
  checklistOption: CHECKLIST_OPTION;
  isDropdownOpen?: boolean;
  hasNotes?: boolean;
}

export const SelectItem = ({ icon, checklistOption, isDropdownOpen, hasNotes }: Props) => {
  const { colors } = useTheme();

  return (
    <ItemWrapper>
      <TextWrapper hasNotes={hasNotes}>
        <IconWrapper isOpen={isDropdownOpen}>{icon}</IconWrapper>
        <Typography variant='body' color={isDropdownOpen ? colors.accent[50] : colors.primary[90]}>
          {checklistOption}
        </Typography>
      </TextWrapper>
    </ItemWrapper>
  );
};
