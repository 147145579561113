import { Chip, styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';
import { EditableTextFieldV2 } from '../../EditableTextField/EditableTextFieldV2';

const Wrapper = styled('div')`
  display: flex;
  gap: 8px;
  && .read-write-input .MuiInputBase-root .MuiInputBase-input {
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary[90]};
  }
`;

interface Props {
  title: string;
  chipLabel: string;
  isActive?: boolean;
  setTitleValue?: (value: string) => void;
  onTitleValueChanged?: (newValue: string) => void;
}

export const CardTitle = ({
  title,
  chipLabel,
  isActive,
  setTitleValue,
  onTitleValueChanged,
}: Props) => {
  const { colors } = useTheme();

  const placeholder = 'Enter stage title';

  return (
    <Wrapper>
      {!onTitleValueChanged ? (
        <Typography variant='h5' color={colors.primary[90]}>
          {title}
        </Typography>
      ) : (
        <EditableTextFieldV2
          value={title}
          onChange={(e) => setTitleValue?.(e.target.value)}
          onValueChanged={onTitleValueChanged}
          placeholder={placeholder}
          inputProps={{
            size: title.length || placeholder.length,
          }}
        />
      )}

      <Chip
        style={{
          backgroundColor: isActive ? colors.accent[10] : colors.primary[20],
          height: '26px',
        }}
        label={
          <Typography variant='caption' color={colors.primary[90]}>
            {chipLabel}
          </Typography>
        }
      />
    </Wrapper>
  );
};
