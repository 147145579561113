import { useNavigate, useParams } from 'react-router';
import { useCompanyById } from '../../../queries/useCompanies';
import { Task } from '../TaskGroup';
import { Button, Chip, useTheme } from '@mui/material';
import { ReactComponent as ApprovedIcon } from '../../../assets/icons/request-approved.svg';
import { ROUTES } from '../../../constants/routes';
import { SURVEY_FREQUENCY } from '../../../types';
import { useMemo } from 'react';

interface Props {
  isBlocked?: boolean;
  hasAccess?: boolean;
  isStepCompleted?: boolean;
  stepId: string | number;
}

export const YearlyAssessment = ({ isBlocked, hasAccess, stepId, isStepCompleted }: Props) => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));

  const { colors } = useTheme();

  const navigate = useNavigate();

  const isDisabled =
    !company?.externalEsgContact?.email || isBlocked || (!hasAccess && !isStepCompleted);
  const isCompleted = useMemo(
    () => company?.completedWorkflowSteps?.includes(stepId),
    [company?.completedWorkflowSteps, stepId]
  );

  if (!company) return null;

  return (
    <Task
      taskName={`Yearly Assessment`}
      isHighlighted={hasAccess && !isStepCompleted}
      disabled={isDisabled}
      infoText={!company?.externalEsgContact?.email || isBlocked ? 'This step is blocked' : ''}
    >
      {isCompleted ? (
        <Chip
          icon={<ApprovedIcon style={{ marginRight: '4px' }} />}
          style={{
            backgroundColor: colors.success[5],
            width: '97px',
          }}
          label={'Sent'}
        />
      ) : (
        <Button
          onClick={
            company?.externalEsgContact?.email && hasAccess
              ? () =>
                  navigate(
                    `/${ROUTES.CREATE_REQUEST}/1?companyId=${company.id}&&mode=initial&&frequency=${SURVEY_FREQUENCY.ANNUALLY}&&stepId=${stepId}`
                  )
              : undefined
          }
          variant='contained'
          style={{ backgroundColor: isDisabled ? colors.accent[20] : colors.accent[50] }}
          disabled={isDisabled}
        >
          Create Request
        </Button>
      )}
    </Task>
  );
};
