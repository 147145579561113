import {
  CHECKLIST_ITEM_FREQUENCY,
  ChecklistDataItem,
  QuestionOption,
  Report,
  Survey,
} from '../types';

export const getFormattedChecklistData = (
  survey: Survey | null,
  frequency: CHECKLIST_ITEM_FREQUENCY,
  report?: Report | null
) => {
  if (!survey) return [];
  const sectionItems = survey.sections.map((section) => {
    return section.questions?.map((question) => {
      const res = report?.response?.[question?.id];
      return {
        id: question.id,
        categoryName: section.name,
        itemName: question.name,
        checklistOption: {
          name: (res as QuestionOption)?.name || '',
          updatedPeriod: report?.updated_at || '',
        },
        frequency,
        notes: (res as QuestionOption)?.notes || '',
      };
    });
  });

  return (
    (sectionItems
      ?.flatMap((item) => item)
      ?.filter((item) => Boolean(item)) as ChecklistDataItem[]) || []
  );
};
