import { ButtonBase, styled, useTheme } from '@mui/material';

import { FC, useCallback } from 'react';
import { Typography } from '../Typography/Typography';
import { SelectItem } from '../../types';

const Wrapper = styled('div')`
  height: 42px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

const ActionsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const ActionItem = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

interface Props {
  actions: SelectItem[];
  onSelectAction: (actionId: string) => void;
  selectedItemsLength: number;
  isActive: boolean;
  isDisabled?: boolean;
  style?: React.CSSProperties;
}

export const SelectionBar: FC<Props> = ({
  actions,
  onSelectAction,
  selectedItemsLength,
  isActive,
  isDisabled,
  style,
}) => {
  const { colors } = useTheme();

  const handleSelectAction = useCallback(
    (actionId: string) => {
      onSelectAction(actionId);
    },
    [onSelectAction]
  );
  return (
    <Wrapper
      style={{
        visibility: isActive ? 'visible' : 'hidden',
        backgroundColor: isDisabled ? colors.primary[70] : colors.primary[80],
        ...style,
      }}
    >
      <Typography variant='body' color='white'>{`${selectedItemsLength} ${
        selectedItemsLength > 1 ? 'items' : 'item'
      } selected`}</Typography>
      <ActionsWrapper>
        {actions.map((action) => (
          <ButtonBase
            key={action.id}
            TouchRippleProps={{ color: 'black' }}
            style={{ borderRadius: '8px' }}
            onClick={() => handleSelectAction(action.id as string)}
            disableRipple={isDisabled}
          >
            <ActionItem style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
              {action.icon}
              <Typography variant='body' color='white'>
                {action.value}
              </Typography>
            </ActionItem>
          </ButtonBase>
        ))}
      </ActionsWrapper>
    </Wrapper>
  );
};
