import { useCallback } from 'react';
import { getFormattedChecklistData } from '../../../utils/getFormattedChecklistData';
import { CHECKLIST_ITEM_FREQUENCY, ChecklistData } from '../../../types';
import { useUpdateCompany } from '../../../queries/useCompanies';
import { useAtom } from 'jotai';
import { activeReportState } from '../../../state/UIState';

export const useUpdateCompanyChecklistData = () => {
  const updateCompany = useUpdateCompany();
  const [report] = useAtom(activeReportState);

  const updateCompanyChecklistData = useCallback(async () => {
    if (!report) return;

    const checklistFrequency = report.request.frequency as unknown as CHECKLIST_ITEM_FREQUENCY;

    const formattedChecklistData = getFormattedChecklistData(
      report.survey,
      checklistFrequency,
      report
    );

    const newChecklistData = {
      ...(report.company.checklistData || {}),
      [checklistFrequency]: formattedChecklistData,
    };

    await updateCompany.mutateAsync({
      id: report.company.id,
      checklistData: newChecklistData as ChecklistData,
    });
  }, [report, updateCompany]);

  return { updateCompanyChecklistData };
};
