import { useCallback, useEffect, useMemo } from 'react';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import {
  useCoreKpiCategories,
  useCreateCoreKpiCategory,
  useUpdateCoreKpiCategory,
} from '../../../queries/useCoreKpiCategories';
import { CoreKpiCategory } from '../../../types';
import { useAtom } from 'jotai';
import { activeKpiCategoryState, sortedKpiCategoriesState } from '../../../state/UIState';

export function useSortedCoreKpiCategories() {
  const { data: appConfig, isLoading: isConfigLoading } = useAppConfig();
  const { data: coreKpiCategories, isLoading: areCategoriesLoading } = useCoreKpiCategories();
  const [sortedItems, setSortedItems] = useAtom(sortedKpiCategoriesState);
  const [, setActiveCategory] = useAtom(activeKpiCategoryState);
  const updatePageConfig = useUpdateAppConfig();
  const createCoreKpiCategory = useCreateCoreKpiCategory();
  const updateCoreKpiCategory = useUpdateCoreKpiCategory();
  useEffect(() => {
    if (!coreKpiCategories || !appConfig || isConfigLoading || areCategoriesLoading) {
      return;
    }
    const { coreKpiCategoriesOrder } = appConfig.data;

    const sorted = coreKpiCategoriesOrder.map((id) =>
      coreKpiCategories.find((item) => item.id === id)
    );
    const filteredItems = sorted.filter((item) => !!item) as CoreKpiCategory[];
    setSortedItems(filteredItems);

    setActiveCategory((prev) => {
      const prevActive = coreKpiCategories?.find((category) => category.id === prev?.id);
      return prevActive || filteredItems[0];
    });
  }, [
    coreKpiCategories,
    appConfig,
    setSortedItems,
    isConfigLoading,
    areCategoriesLoading,
    setActiveCategory,
  ]);

  const onReorder = useCallback(
    async (newOrder: CoreKpiCategory[]) => {
      if (!appConfig?.data) {
        return;
      }
      const oldOrder = [...sortedItems];
      setSortedItems(newOrder);
      await updatePageConfig.mutateAsync(
        {
          data: {
            ...appConfig?.data,
            coreKpiCategoriesOrder: newOrder.map((item) => item.id),
            isCoreKpiSurveyUpToDate: false,
          },
        },
        {
          onError: () => {
            setSortedItems(oldOrder);
          },
        }
      );
    },
    [appConfig?.data, setSortedItems, sortedItems, updatePageConfig]
  );

  const onAddCategory = useCallback(async () => {
    if (!appConfig?.data) {
      return;
    }
    const newCategory = await createCoreKpiCategory.mutateAsync({ name: 'New Category' });
    const newOrder = [...sortedItems, { ...newCategory, isNew: true }];

    await updatePageConfig.mutateAsync(
      {
        data: {
          ...appConfig?.data,
          coreKpiCategoriesOrder: newOrder.map((item) => item.id),
          isCoreKpiSurveyUpToDate: false,
        },
      },
      {
        onSuccess: () => {
          setSortedItems(newOrder);
          setActiveCategory(newCategory);
        },
      }
    );
  }, [
    appConfig?.data,
    createCoreKpiCategory,
    setActiveCategory,
    setSortedItems,
    sortedItems,
    updatePageConfig,
  ]);

  const onRemoveCategory = useCallback(
    async (id: number) => {
      if (!appConfig?.data) {
        return;
      }
      const oldOrder = [...sortedItems];
      const newOrder = sortedItems.filter((item) => item.id !== id);
      await updateCoreKpiCategory.mutateAsync({ id, isDeleted: true });
      await updatePageConfig.mutateAsync(
        {
          data: {
            ...appConfig?.data,
            coreKpiCategoriesOrder: newOrder.map((item) => item.id),
            isCoreKpiSurveyUpToDate: false,
          },
        },
        {
          onError: () => {
            setSortedItems(oldOrder);
          },
          onSuccess: () => {
            setActiveCategory(newOrder[0] ? newOrder[0] : null);
          },
        }
      );
    },
    [
      appConfig?.data,
      setActiveCategory,
      setSortedItems,
      sortedItems,
      updateCoreKpiCategory,
      updatePageConfig,
    ]
  );

  const onRenameCategory = useCallback(
    async (id: number, name: string) => {
      await updateCoreKpiCategory.mutateAsync(
        { id, name },
        {
          onSuccess: async () => {
            if (!appConfig?.data) {
              return;
            }
            await updatePageConfig.mutateAsync({
              data: {
                ...appConfig?.data,
                isCoreKpiSurveyUpToDate: false,
              },
            });
          },
        }
      );
    },
    [appConfig?.data, updateCoreKpiCategory, updatePageConfig]
  );

  const value = useMemo(() => {
    return {
      items: sortedItems as CoreKpiCategory[],
      isLoading: isConfigLoading || areCategoriesLoading,
      onReorder,
      onAddCategory,
      onRemoveCategory,
      onRenameCategory,
    };
  }, [
    areCategoriesLoading,
    isConfigLoading,
    onAddCategory,
    onRemoveCategory,
    onReorder,
    sortedItems,
    onRenameCategory,
  ]);

  return value;
}
