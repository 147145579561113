import { styled, useTheme } from '@mui/material';
import { Radio } from './Radio/Radio';
import { Typography } from './Typography/Typography';
import { ReactNode } from 'react';

const Wrapper = styled('div')<{ isSelected: boolean; isEvaluated: boolean; isDisabled?: boolean }>`
  padding: 16px;
  background-color: ${({ theme, isSelected, isEvaluated }) =>
    isSelected ? '#F5F8FF' : isEvaluated ? theme.colors.primary[0] : theme.colors.primary[5]};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

const RuleWrapper = styled('div')<{ isDisabled?: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  display: flex;
  gap: 12px;
`;

interface Props {
  isSelected?: boolean;
  isEvaluated?: boolean;
  onSelect?: () => void;
  optionColor: string;
  label: string;
  children?: ReactNode;
  isDisabled?: boolean;
}

export const ReviewOption = ({
  isSelected = false,
  isEvaluated = false,
  onSelect,
  optionColor,
  label,
  children,
  isDisabled,
}: Props) => {
  const { colors } = useTheme();

  return (
    <Wrapper
      isSelected={isSelected}
      isEvaluated={isEvaluated}
      onClick={!isDisabled ? onSelect : undefined}
      isDisabled={isDisabled}
    >
      <RuleWrapper isDisabled={isDisabled}>
        <Radio
          checked={!!isSelected}
          disabled={isDisabled}
          sx={{
            color: optionColor,
            opacity: !isEvaluated || isSelected ? 1 : 0.3,
            '&.Mui-checked': {
              color: optionColor,
            },
            '& .MuiSvgIcon-root': {
              fontSize: 20,
            },
          }}
        />
        <Typography variant='body' color={colors.primary[70]}>
          {label}
        </Typography>
      </RuleWrapper>
      {children}
    </Wrapper>
  );
};
