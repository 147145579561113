import { useParams } from 'react-router';
import { useExclusionsByCompany } from '../../../queries/useExclusionsByCompany';
import { useCompanyById } from '../../../queries/useCompanies';
import { useMemo } from 'react';
import { CompanyExclusion } from '../../../types';
import { getFormattedCompanyExclusions } from '../../../utils/getFormattedCompanyExclusions';
import { useExternalInvestors } from '../../../queries/useInvestors';

export const useCompanyExclusions = () => {
  const params = useParams();
  const { isLoading: areCompanyExclusionsLoading, data: exclusions } = useExclusionsByCompany(
    Number(params.id)
  );

  const { isLoading: isLoadingCompany, data: company } = useCompanyById(Number(params.id));

  const { data: investors } = useExternalInvestors();

  const companyExclusions: CompanyExclusion[] = useMemo(() => {
    if (!company || !exclusions?.length) return [];
    return getFormattedCompanyExclusions(exclusions, company, investors);
  }, [company, exclusions, investors]);

  return {
    isLoading: areCompanyExclusionsLoading || isLoadingCompany,
    data: companyExclusions,
  };
};
