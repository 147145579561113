import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { QuestionInput } from './QuestionInput';
import { QUESTION_TYPE, SurveyQuestion } from '../../../../types';
import { useAtom } from 'jotai';
import { activeQuestionIdState, activeSurveyState } from '../../../../state/UIState';
import { useEffect, useMemo, useRef } from 'react';
import { EvaluateTabs } from './EvaluateTabs/EvaluateTabs';

const Wrapper = styled('div')`
  display: flex;
  margin-bottom: 80px;
  justify-content: space-between;
  align-items: flex-start;
`;
const QuestionWrapper = styled('div')<{ isTableQuestion: boolean; hasInstructions: boolean }>`
  width: ${({ isTableQuestion, hasInstructions }) =>
    isTableQuestion && !hasInstructions ? 'auto' : '45%'};
  flex: ${({ isTableQuestion, hasInstructions }) =>
    isTableQuestion && !hasInstructions ? '1' : '0 1 auto'};
`;

const RequiredAsterisk = styled('span')`
  color: ${({ theme }) => theme.colors.error[40]};
  margin-left: 4px;
`;

interface Props {
  onClick: (question: SurveyQuestion) => void;
  question: SurveyQuestion;
  order: string;
}

export const QuestionContent = ({ onClick, question, order }: Props) => {
  const { colors } = useTheme();
  const [activeQuestionId] = useAtom(activeQuestionIdState);
  const isActive = activeQuestionId === question.id;
  const [activeSurvey] = useAtom(activeSurveyState);

  const wrapperRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (isActive) {
      const top = wrapperRef?.current?.getBoundingClientRect()?.top;
      if (top && (top > window.innerHeight || top < 0)) {
        wrapperRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }, [isActive]);

  const questionNum = useMemo(() => {
    if (activeSurvey?.includeQuestionNumbers) return `${order}.`;

    return '';
  }, [activeSurvey?.includeQuestionNumbers, order]);

  return (
    <Wrapper onClick={() => onClick(question)} ref={wrapperRef}>
      <QuestionWrapper
        isTableQuestion={question.type === QUESTION_TYPE.TABLE}
        hasInstructions={question.includeInstructions && Boolean(question.instructions)}
      >
        <Typography
          variant='h5'
          color={colors.primary[90]}
          style={{ marginBottom: '16px', maxWidth: '700px', fontSize: 18 }}
        >
          {`${questionNum} ${question.name}`}
          {question.isRequired && <RequiredAsterisk>*</RequiredAsterisk>}
        </Typography>
        <QuestionInput question={question} />
      </QuestionWrapper>
      <EvaluateTabs question={question} />
    </Wrapper>
  );
};
