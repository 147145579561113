import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import { COMPANY_TYPE, LoadingId, WorkflowStage } from '../types';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { useWorkflows } from './useWorkflows';
import { useAtomValue } from 'jotai';
import { activeWorkflowCompanyType } from '../state/UIState';

export const WORKFLOW_STAGES = 'workflowStages';

export type WorkflowStagePayload = {
  id?: string | number;
  name: string;
  order: number;
  workflow: number;
};

async function fetchWorkflowStages(companyType: COMPANY_TYPE | null): Promise<WorkflowStage[]> {
  const { data } = await Http.axios.get<WorkflowStage[]>(
    `/workflow-stage?companyType=${companyType}`
  );

  const sortedStages = data.sort((a, b) => a.order - b.order);

  return sortedStages?.map((stage) => {
    return {
      ...stage,
      steps: stage?.steps?.sort((a, b) => a?.order - b?.order) || [],
    };
  });
}

export function useWorkflowStages() {
  const companyType = useAtomValue(activeWorkflowCompanyType);

  return useQuery([WORKFLOW_STAGES, companyType], () => fetchWorkflowStages(companyType), {
    enabled: Boolean(companyType),
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function createStage(
  payload: Partial<WorkflowStagePayload>,
  workflowId?: number
): Promise<WorkflowStage | null> {
  if (!workflowId) return null;

  const { data } = await Http.axios.post<Partial<WorkflowStagePayload>, WorkflowStage>(
    `/workflow-stage `,
    { ...payload, workflow: workflowId }
  );
  return data;
}

export function useCreateStage() {
  const { startLoading, stopLoading } = useLoadingBar();
  const queryClient = useQueryClient();
  const { pushErrorToast } = useToastMessage();
  const { data: workflows } = useWorkflows();
  const companyType = useAtomValue(activeWorkflowCompanyType);

  const workflowId = workflows?.find((workflow) => workflow.companyType === companyType)?.id;

  return useMutation((payload: Partial<WorkflowStagePayload>) => createStage(payload, workflowId), {
    onMutate: async () => {
      startLoading(LoadingId.createStage);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create stage' });
    },
    onSettled: () => {
      queryClient.invalidateQueries([WORKFLOW_STAGES, companyType]);
      stopLoading(LoadingId.createStage);
    },
  });
}

async function updateStage(stage: Partial<WorkflowStagePayload>): Promise<WorkflowStage> {
  const { id, ...payload } = stage;
  const { data } = await Http.axios.patch<Partial<WorkflowStagePayload>, WorkflowStage>(
    `/workflow-stage/${id}`,
    payload
  );
  return data;
}

export function useUpdateStage() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  const companyType = useAtomValue(activeWorkflowCompanyType);

  return useMutation(updateStage, {
    onMutate: async (payload) => {
      const prevStages = queryClient.getQueryData([
        WORKFLOW_STAGES,
        companyType,
      ]) as WorkflowStage[];

      const updatedStages = prevStages?.map((stage) => {
        if (stage.id === payload.id) {
          return {
            ...stage,
            ...payload,
          };
        }

        return stage;
      });

      queryClient.setQueryData([WORKFLOW_STAGES, companyType], updatedStages);
      startLoading(LoadingId.updateStage);

      return { prevStages };
    },
    onError: (error: Error, _variables, context) => {
      console.error({ error });
      queryClient.setQueryData([WORKFLOW_STAGES, companyType], context?.prevStages);
      pushErrorToast({ message: 'Failed to update stage' });
    },
    onSettled: () => {
      queryClient.invalidateQueries([WORKFLOW_STAGES, companyType]);
      stopLoading(LoadingId.updateStage);
    },
  });
}

async function updateStages(payload: {
  data: Partial<WorkflowStagePayload>[];
}): Promise<WorkflowStage[]> {
  const { data } = await Http.axios.patch<
    { data: Partial<WorkflowStagePayload>[] },
    WorkflowStage[]
  >(`/workflow-stage/bulk`, payload);
  return data;
}

export function useUpdateStages() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const companyType = useAtomValue(activeWorkflowCompanyType);

  return useMutation(updateStages, {
    onMutate: async (payload) => {
      const prevStages = queryClient.getQueryData([
        WORKFLOW_STAGES,
        companyType,
      ]) as WorkflowStage[];

      const sortedStages = payload.data;

      const updatedStages = sortedStages?.map((sortedStage) => {
        return prevStages?.find((prevStage) => prevStage?.id === sortedStage?.id);
      });

      queryClient.setQueryData([WORKFLOW_STAGES, companyType], updatedStages);
      startLoading(LoadingId.updateStages);

      return { prevStages };
    },
    onError: (error: Error, _variables, context) => {
      console.error({ error });
      queryClient.setQueryData([WORKFLOW_STAGES, companyType], context?.prevStages);
      pushErrorToast({ message: 'Failed to update stages' });
    },
    onSettled: () => {
      queryClient.invalidateQueries([WORKFLOW_STAGES, companyType]);
      stopLoading(LoadingId.updateStages);
    },
  });
}

async function deleteStage(id: string | number): Promise<number> {
  const { data } = await Http.axios.delete<number>(`/workflow-stage/${id}`);
  return data;
}

export function useDeleteStage() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const companyType = useAtomValue(activeWorkflowCompanyType);

  return useMutation(deleteStage, {
    onMutate: async () => {
      startLoading(LoadingId.deleteStage);
    },
    onError: (error: Error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to delete stage' });
    },
    onSettled: () => {
      queryClient.invalidateQueries([WORKFLOW_STAGES, companyType]);
      stopLoading(LoadingId.deleteStage);
    },
  });
}
