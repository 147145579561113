import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { activeSurveyState, responsesState } from '../../../../state/UIState';
import { flattenTree } from '../../../../utils/treeUtilities';
import { QUESTION_TYPE, QuestionOption, SurveyQuestion } from '../../../../types';

export const useIsSubmitDisabled = () => {
  const [response] = useAtom(responsesState);
  const [activeSurvey] = useAtom(activeSurveyState);

  return useMemo(() => {
    //check if all required questions and subquestions are answered
    if (!activeSurvey || !response) return true;
    const allSections = flattenTree(activeSurvey.sections);
    const allQuestions = allSections.reduce((acc: SurveyQuestion[], section) => {
      const subQuestions: SurveyQuestion[] = [];
      for (const question of section.questions) {
        if (
          question.type === QUESTION_TYPE.SINGLE &&
          question.includeConditions &&
          response[question.id]
        ) {
          const option = response[question.id] as QuestionOption;
          option?.subQuestions && subQuestions.push(...option.subQuestions);
        }
      }
      return [...acc, ...section.questions, ...subQuestions];
    }, []);
    const requiredQuestions = allQuestions.filter((question) => question.isRequired);
    const answeredQuestions = requiredQuestions.filter((question) => response[question.id]);
    return requiredQuestions.length !== answeredQuestions.length;
  }, [response, activeSurvey]);
};
