import { Multiselect } from './Multiselect/Multiselect';
import { SelectItem } from '../types';

interface Props {
  sortedUniqueSectors: SelectItem[];
  onChangeSectorsFilter: (sectors: SelectItem[]) => void;
  selectedSectors: SelectItem[];
}

export const SectorsFilter = ({
  sortedUniqueSectors,
  onChangeSectorsFilter,
  selectedSectors,
}: Props) => {
  return (
    <Multiselect
      style={{ width: '220px' }}
      options={sortedUniqueSectors}
      value={selectedSectors}
      onChange={(_, val) => {
        onChangeSectorsFilter(val as SelectItem[]);
      }}
      disablePortal
      optionName='Sector'
      fieldPlaceholder='Filter by Sectors'
    />
  );
};
