import { Company, CompanyExclusion, EXCLUSION_STATUS, Exclusion, ExternalInvestor } from '../types';

export const getFormattedCompanyExclusions = (
  exclusions: Exclusion[],
  company: Company,
  externalInvestors?: ExternalInvestor[]
): CompanyExclusion[] => {
  return exclusions.map((exclusion) => {
    const isViolated = company?.violatedExclusionsIds?.includes(exclusion.id);

    const exclusionInvestor = externalInvestors?.find(
      (investor) => investor?.id === Number(exclusion?.externalInvestorId)
    );

    const funds = exclusion.externalFunds.map((fund) => {
      if (!fund.name) {
        const externalFund = exclusionInvestor?.investorFunds?.find?.(
          (f) => f?.fundId === fund?.id
        );
        return {
          id: fund.id,
          name: externalFund?.fundName || '',
        };
      }

      return fund;
    });

    return {
      id: exclusion.id,
      text: exclusion.exclusion,
      condition: exclusion.condition.condition,
      category: exclusion?.condition?.category?.name,
      status: isViolated ? EXCLUSION_STATUS.VIOLATED : EXCLUSION_STATUS.SATISFIED,
      investor: exclusion?.externalInvestorId,
      funds,
      company: company,
      updated_at: exclusion.updated_at,
      created_at: exclusion.created_at,
      investorName: exclusion.externalInvestorName || exclusionInvestor?.investorCloaked || '',
    };
  });
};
