import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { ActionsPopupMenu } from '../../../../../../../../../components/ActionsPopupMenu/ActionsPopupMenu';
import { ReactComponent as DeleteIcon } from '../../../../../../../../../assets/icons/delete.svg';
import { ReactComponent as DuplicateIcon } from '../../../../../../../../../assets/icons/duplicate.svg';
import {
  ExclusionPayload,
  useCreateExclusion,
  useUpdateExclusion,
} from '../../../../../../../../../queries/useExclusions';
import { useAppConfig, useUpdateAppConfig } from '../../../../../../../../../queries/useAppConfig';
import { Exclusion } from '../../../../../../../../../types';
import { useAtom } from 'jotai';
import {
  activeConditionIdState,
  activeExclusionCategoryState,
  activeExclusionIdState,
} from '../../../../../../../../../state/UIState';

const options = [
  {
    id: 'DUPLICATE',
    value: 'Duplicate Exclusion',
    icon: <DuplicateIcon />,
  },
  {
    id: 'DELETE',
    value: 'Delete Exclusion',
    icon: <DeleteIcon />,
  },
];

interface Props {
  exclusion: Exclusion;
}

export const ActionMenu = ({ exclusion }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeConditionId] = useAtom(activeConditionIdState);
  const [activeExclusionId, setActiveExclusionId] = useAtom(activeExclusionIdState);
  const [activeCategory] = useAtom(activeExclusionCategoryState);

  const { mutate: createExclusion } = useCreateExclusion();
  const { mutate: editExclusion } = useUpdateExclusion(activeCategory?.id);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const activeCondition = useMemo(
    () => activeCategory?.conditions?.find((condition) => condition.id === activeConditionId),
    [activeCategory?.conditions, activeConditionId]
  );

  const duplicateExclusion = useCallback(() => {
    if (!activeConditionId) return;

    const payload: Partial<ExclusionPayload> = {
      isActive: exclusion.isActive,
      externalFunds: exclusion.externalFunds,
      externalInvestorId: exclusion.externalInvestorId,
      externalInvestorName: exclusion.externalInvestorName,
      exclusion: exclusion.exclusion,
      isDeleted: false,
      order: activeCondition?.exclusions?.length ? activeCondition?.exclusions?.length + 1 : 0,
      condition: activeConditionId,
    };
    createExclusion(payload, {
      onSuccess: async () => {
        if (!appConfigData?.data) {
          return;
        }
        await updateAppConfig.mutateAsync({
          data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
        });
      },
    });
  }, [
    activeCondition?.exclusions?.length,
    activeConditionId,
    appConfigData?.data,
    createExclusion,
    exclusion.exclusion,
    exclusion.externalFunds,
    exclusion.externalInvestorId,
    exclusion.externalInvestorName,
    exclusion.isActive,
    updateAppConfig,
  ]);

  const deleteExclusion = useCallback(() => {
    if (!activeConditionId) return;

    editExclusion(
      { id: exclusion.id, isDeleted: true, condition: activeConditionId },
      {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
          });
        },
      }
    );

    if (activeExclusionId === exclusion.id && activeCondition?.exclusions.length) {
      setActiveExclusionId(activeCondition?.exclusions[0].id);
    }
  }, [
    activeCondition?.exclusions,
    activeConditionId,
    activeExclusionId,
    appConfigData?.data,
    editExclusion,
    exclusion.id,
    setActiveExclusionId,
    updateAppConfig,
  ]);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = useCallback(
    (id: string) => {
      handleCloseMenu();
      switch (id) {
        case 'DUPLICATE':
          duplicateExclusion();
          return;
        case 'DELETE':
          deleteExclusion();
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [deleteExclusion, duplicateExclusion]
  );

  return (
    <ActionsPopupMenu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      actions={options}
      onClick={(e) => handleClick(e)}
      onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        handleCloseMenu();
      }}
      onMenuItemClick={onMenuItemClick}
      menuStyles={{
        transform: 'translate(25px, 5px)',
      }}
    />
  );
};
