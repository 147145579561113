import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/material';
import { FC } from 'react';

interface Props {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  id: string;
}

const ToolbarWrapper = styled('div')`
  margin-bottom: 12px;
  background-color: #fff;
`;

const StyledRichText = styled(ReactQuill)`
  margin-bottom: 0;
  border: 1px solid rgba(161, 171, 187, 0.5);
  border-radius: 4px;
  background-color: #fff;
  .ql-container.ql-snow {
    border: none;
    min-height: 200px;
  }
  .ql-editor.ql-blank::before {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .ql-editor.ql-blank {
    min-height: 200px;
    cursor: text;
  }
  .ql-editor {
    color: #161717;
    ol,
    ul {
      padding: 8px 0 4px 16px;
    }
    p,
    ol,
    ul {
      font-family: PPNeueMontreal-Regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
    h1 {
      font-family: PPNeueMontreal-Medium;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    h2 {
      font-family: PPNeueMontreal-Medium;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
    a {
      color: #0f62fe;
      text-decoration: none;
    }
  }
`;

interface ToolbarProps {
  id: string;
}

export const QuillToolbar = ({ id }: ToolbarProps) => (
  <div id={id}>
    <span className='ql-formats'>
      <select className='ql-header' defaultValue='3'>
        <option value='1'>Heading</option>
        <option value='2'>Subheading</option>
        <option value='3'>Normal</option>
      </select>
    </span>
    <span className='ql-formats'>
      <button className='ql-bold' />
      <button className='ql-italic' />
      <button className='ql-underline' />
      <button className='ql-strike' />
    </span>
    <span className='ql-formats'>
      <button className='ql-list' value='ordered' />
      <button className='ql-list' value='bullet' />
    </span>
    <span className='ql-formats'>
      <button className='ql-link' />
      <button className='ql-image' />
    </span>
  </div>
);

export const RichText: FC<Props> = (props) => {
  const modules = {
    toolbar: {
      container: `#${props.id}`,
    },
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'script',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
  ];

  return (
    <>
      <ToolbarWrapper>
        <QuillToolbar id={props.id} />
      </ToolbarWrapper>
      <StyledRichText modules={modules} formats={formats} {...props} />
    </>
  );
};
