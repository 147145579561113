import { IconButton, styled, TextField } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatDate } from '../../utils/formatters';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as CalendarBlueIcon } from '../../assets/icons/calendar-blue.svg';
import { ReactComponent as ClearIcon } from '../../assets/icons/close-gray.svg';
import { FC, KeyboardEvent, useMemo, useState } from 'react';

interface Props {
  selectedDate: string | null;
  setSelectedDate: (newDate: Date | null) => void;
  views?: ('year' | 'day' | 'month')[];
  clearable?: boolean;
  width?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  shouldDisableYear?: (date: Date) => boolean;
}

const Wrapper = styled('div')<{ isActive: boolean; clearable: boolean; width?: string }>`
  .custom-date-picker {
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, isActive }) =>
        isActive ? theme.colors.accent[50] : theme.colors.primary[30]};
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, isActive }) =>
        isActive ? theme.colors.accent[50] : theme.colors.primary[30]};
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.primary[30]};
    }
    .MuiInputBase-root {
      height: 36px;
      width: ${({ clearable, width }) => (width ? width : clearable ? '160px' : '140px')};
      padding-left: 0;
      padding-right: 0;
    }
    .MuiInputBase-root.Mui-disabled {
      background-color: ${({ theme }) => theme.colors.primary[5]};
    }
    .MuiMonthPicker-root {
      width: auto;
      margin: 0;
    }
    .MuiInputBase-input {
      font-family: ${({ theme }) => theme.text.font.fontFamilyRegular};
      line-height: 18px;
      margin-left: -10px;
      color: ${({ theme, isActive }) =>
        isActive ? theme.colors.accent[50] : theme.colors.primary[90]};
      caret-color: transparent;
    }
    .MuiButtonBase-root {
      padding: 6px;
      margin: 6px;
    }
    svg {
      margin-top: -2px;
    }
  }
`;

export const DatePicker: FC<Props> = ({
  selectedDate,
  setSelectedDate,
  views,
  clearable = false,
  width,
  disabled = false,
  disableFuture = false,
  shouldDisableYear,
}) => {
  const [isActive, setIsActive] = useState(false);
  const onKeyDown = (e: KeyboardEvent) => {
    e.preventDefault();
  };

  const inputFormat = useMemo(() => {
    if (!views) {
      return 'MMM d, yyyy';
    }
    if (views?.includes('month')) {
      return 'MMM yyyy';
    }
    if (views?.includes('year')) {
      return 'yyyy';
    }
  }, [views]);

  const placeholder = useMemo(() => {
    if (!views) {
      return 'Select Date';
    }
    if (views?.includes('month')) {
      return 'Select Month';
    }
    if (views?.includes('year')) {
      return 'Select Year';
    }
  }, [views]);

  const viewToOpen = useMemo(() => {
    if (!views) {
      return 'day';
    }
    if (views?.includes('month')) {
      return 'month';
    }
    return 'year';
  }, [views]);

  return (
    <Wrapper isActive={isActive} clearable={clearable} width={width}>
      <MuiDatePicker
        openTo={viewToOpen}
        open={isActive}
        onOpen={() => setIsActive(true)}
        onClose={() => setIsActive(false)}
        views={views}
        disabled={disabled}
        disableFuture={disableFuture}
        shouldDisableYear={shouldDisableYear}
        className='custom-date-picker'
        value={selectedDate}
        onChange={(newValue) => {
          setSelectedDate(newValue as unknown as Date);
        }}
        components={{
          OpenPickerIcon: isActive && !disabled ? CalendarBlueIcon : CalendarIcon,
        }}
        PopperProps={{ className: 'custom-datepicker-popup' }}
        InputAdornmentProps={{ position: 'start' }}
        InputProps={{ value: formatDate(selectedDate as string), placeholder: 'Select Date' }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={params?.InputProps?.value}
              onKeyDown={onKeyDown}
              onClick={() => setIsActive(true)}
              inputProps={{ ...params.inputProps, placeholder }}
              InputProps={{
                ...params.InputProps,
                endAdornment:
                  clearable && selectedDate ? (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDate(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : null,
              }}
            />
          );
        }}
        inputFormat={inputFormat}
      />
    </Wrapper>
  );
};
