import { styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { Typography } from '../../../../components/Typography/Typography';
import { ACTION_BAR_HEIGHT, HEADER_HEIGHT } from '../../../../constants/layoutSizes';
import {
  sectionsState,
  activeSurveyState,
  activeReportState,
  areShowedAllQuestionsState,
  responsesState,
  notCompletedSectionsState,
} from '../../../../state/UIState';
import { QUESTION_TYPE, QuestionOption, QuestionTableData, SURVEY_STAGE } from '../../../../types';
import { useMemo } from 'react';
import { buildTree, flattenTree } from '../../../../utils/treeUtilities';
import { CoreKpisSurveyUpoadArea } from './CoreKpisSurveyUpoadArea';
import { getPeriodByFrequencyAndReportDate } from '../../../../utils/companyUtils';
import { isTableQuestionAnswered } from '../../../../queries/useRequestGroups';
import { QuestionnaireSections } from './QuestionnaireSections';

const Wrapper = styled('div')<{ withFooter?: boolean }>`
  padding: 24px 40px;
  flex-grow: 1;
  height: ${({ withFooter }) =>
    withFooter
      ? `calc(100vh - ${HEADER_HEIGHT}px - ${ACTION_BAR_HEIGHT}px)`
      : `calc(100vh - ${HEADER_HEIGHT}px)`};
  overflow-y: auto;
  padding-bottom: 60px;
  & > div:last-child > div:last-child {
    border-bottom: none;
  }
`;

const PeriodLabelWrapper = styled('div')`
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.accent[10]};
  margin-top: 20px;
  margin-bottom: 38px;
`;

interface Props {
  withFooter?: boolean;
}

export const QuestionnaireMainContent = ({ withFooter }: Props) => {
  const [activeSurvey] = useAtom(activeSurveyState);
  const [activeReport] = useAtom(activeReportState);
  const [areShowedAllQuestions] = useAtom(areShowedAllQuestionsState);
  const [responses] = useAtom(responsesState);
  const [sectionsWithAllQuestions] = useAtom(sectionsState);
  const [notCompletedSections] = useAtom(notCompletedSectionsState);
  const { colors } = useTheme();

  const sectionsWithOnlyUnansweredQuestions = useMemo(() => {
    if (!activeSurvey) return [];
    const flatSections = flattenTree(notCompletedSections);
    const flatSectionsWithUnansweredQuestions = flatSections.map((section) => {
      const unansweredQuestions = section.questions.filter((question) => {
        if (question.type === QUESTION_TYPE.TABLE) {
          const tableData = question.tableData as QuestionTableData;
          const readOnlyColumns = tableData?.readOnlyColumns ?? [];
          return !isTableQuestionAnswered(
            responses[question.id] as QuestionTableData,
            readOnlyColumns
          );
        }

        let hasUnansweredSubQuestions = false;

        if (question.type === QUESTION_TYPE.SINGLE) {
          const selectedOption = question.options.find(
            (o) => (responses[question.id] as QuestionOption)?.id === o.id
          );

          const subQuestions = selectedOption?.subQuestions || [];

          hasUnansweredSubQuestions = Boolean(
            subQuestions?.filter(
              (subQuestion) =>
                responses[subQuestion.id] === null || responses[subQuestion.id] === undefined
            )?.length
          );
        }

        return (
          responses[question.id] === null ||
          responses[question.id] === undefined ||
          hasUnansweredSubQuestions
        );
      });
      return {
        ...section,
        questions: unansweredQuestions,
      };
    });
    return buildTree(flatSectionsWithUnansweredQuestions);
  }, [activeSurvey, notCompletedSections, responses]);

  const sections = areShowedAllQuestions
    ? sectionsWithAllQuestions
    : sectionsWithOnlyUnansweredQuestions;

  const isCoreKpisSurvey = activeSurvey?.stage === SURVEY_STAGE.CORE_KPI;

  const periodLabel = useMemo(() => {
    if (!activeReport) return '';
    return getPeriodByFrequencyAndReportDate(
      activeReport.request.frequency,
      activeReport.month,
      activeReport.year
    );
  }, [activeReport]);

  return (
    <Wrapper withFooter={withFooter}>
      <Typography variant='h2' color={colors.primary[90]}>
        {activeSurvey?.name}
      </Typography>
      {periodLabel && (
        <PeriodLabelWrapper>
          <Typography variant='body' color={colors.accent[60]}>
            {`Provide data for the period: ${periodLabel}`}
          </Typography>
        </PeriodLabelWrapper>
      )}
      {isCoreKpisSurvey && <CoreKpisSurveyUpoadArea />}
      <QuestionnaireSections sections={sections} />
    </Wrapper>
  );
};
