import { CSVLink } from 'react-csv';

import { Button, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-blue.svg';
import { COMPANY_CSV_HEADERS } from '../../../constants/defaultValues';

export const DownloadCSVButton = () => {
  const { colors } = useTheme();

  const data: string[][] = [];
  return (
    <CSVLink data={data} headers={COMPANY_CSV_HEADERS} filename={'companies.csv'}>
      <Button variant='text' startIcon={<DownloadIcon />} style={{ minWidth: 'auto' }}>
        <Typography variant='subtitle2' color={colors.accent[50]}>
          Download Company Template
        </Typography>
      </Button>
    </CSVLink>
  );
};
