import { useAtom } from 'jotai';
import { QuestionTableData, SurveyQuestion } from '../../../../../../types';
import { responsesState } from '../../../../../../state/UIState';
import { useEffect, useMemo } from 'react';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import {
  HEADER_COLUMN_ID,
  HEADER_ROW_ID,
  KPI_DEFINITION_COLUMN_ID,
  KPI_FORMAT_COLUMN_ID,
  KPI_METRIC_COLUMN_ID,
  KPI_VALUE_COLUMN_ID,
} from '../../../../../../constants/defaultValues';
import { TableReadOnlyCell } from '../TableReadOnlyCell';
import { CoreKpiEditableCell } from './CoreKpiEditableCell/CoreKpiEditableCell';
import { TableInput } from '../../TableInput';

const columnHelper = createColumnHelper<Record<string, any>>();

interface Props {
  question: SurveyQuestion;
}

export const CoreKpiTableQuestion = ({ question }: Props) => {
  const [responses, setResponses] = useAtom(responsesState);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor(HEADER_COLUMN_ID, {
        cell: (info) => <TableReadOnlyCell info={info} />,
        meta: {
          width: '198px',
          minWidth: '198px',
        },
      }),
      columnHelper.accessor(KPI_DEFINITION_COLUMN_ID, {
        cell: (info) => <TableReadOnlyCell info={info} isTextTruncated />,
        meta: {
          width: '270px',
          minWidth: '270px',
        },
      }),
      columnHelper.accessor(KPI_METRIC_COLUMN_ID, {
        cell: (info) => <TableReadOnlyCell info={info} />,
        meta: {
          width: '140px',
          minWidth: '140px',
        },
      }),
      columnHelper.accessor(KPI_FORMAT_COLUMN_ID, {
        cell: (info) => <TableReadOnlyCell info={info} />,
        meta: {
          width: '140px',
          minWidth: '140px',
        },
      }),
      columnHelper.accessor(KPI_VALUE_COLUMN_ID, {
        cell: (info) => {
          return info.row.original.id !== HEADER_ROW_ID ? (
            <CoreKpiEditableCell info={info} column={KPI_VALUE_COLUMN_ID} question={question} />
          ) : (
            <TableReadOnlyCell info={info} />
          );
        },
        meta: {
          width: '640px',
          minWidth: '640px',
        },
      }),
    ];
  }, [question]);

  useEffect(() => {
    setResponses((responses) =>
      !responses[question.id]
        ? {
            ...responses,
            [question.id]: JSON.parse(JSON.stringify(question.tableData)) as QuestionTableData,
          }
        : { ...responses }
    );
  }, [question.id, question.tableData, setResponses]);

  const tableData = useMemo(() => {
    return (responses[question.id] as QuestionTableData)?.rows ?? [];
  }, [question.id, responses]);

  const table = useReactTable({
    data: tableData,
    columns,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => String(row.id),
  });

  return (
    <div>
      <TableInput table={table} tdStyle={{ verticalAlign: 'top' }} />
    </div>
  );
};
