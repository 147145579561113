import { GOVERNANCE_SCORE_TYPE, SelectItem } from '../../types';
import { SingleSelect } from '../SingleSelect/SingleSelect';

interface Props {
  selectedType: SelectItem | null;
  onChangeTypeFilter: (item: SelectItem | null) => void;
}

export const GovernanceScoreFilter = ({ selectedType, onChangeTypeFilter }: Props) => {
  const governanceScoreTypes = [
    { id: GOVERNANCE_SCORE_TYPE.GOOD, value: GOVERNANCE_SCORE_TYPE.GOOD },
    { id: GOVERNANCE_SCORE_TYPE.BAD, value: GOVERNANCE_SCORE_TYPE.BAD },
  ];

  return (
    <SingleSelect
      style={{ width: '250px' }}
      options={governanceScoreTypes}
      value={selectedType}
      onChange={(_, type) => onChangeTypeFilter(type)}
      disablePortal
      fieldPlaceholder='Filter by Governance Score'
    />
  );
};
