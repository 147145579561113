import { useAtom } from 'jotai';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  activeQuestionIdState,
  activeSectionIdState,
  responsesState,
} from '../../../../state/UIState';
import { QUESTION_TYPE, QuestionOption, SurveyQuestion } from '../../../../types';

import { QuestionContent } from './QuestionContent';

interface Props {
  question: SurveyQuestion;
  order: number;
}

export const QuestionnaireQuestion: FC<Props> = ({ question, order }) => {
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);

  const [responses] = useAtom(responsesState);

  const [subQuestions, setSubQuestions] = useState<SurveyQuestion[]>([]);

  const onClick = useCallback(
    (question: SurveyQuestion) => {
      setActiveQuestionId(question.id);
      setActiveSectionId(question.sectionId);
    },
    [setActiveQuestionId, setActiveSectionId]
  );

  useEffect(() => {
    if (question?.type !== QUESTION_TYPE.SINGLE) return;

    const selectedOption = question.options.find(
      (o) => (responses[question.id] as QuestionOption)?.id === o.id
    );

    setSubQuestions(selectedOption?.subQuestions || []);
  }, [question.id, question.options, question?.type, responses]);

  if (!question.isActive) return null;

  return (
    <>
      <QuestionContent onClick={onClick} question={question} order={String(order)} />
      {subQuestions.map((subQuestion, i) => (
        <QuestionContent
          key={subQuestion.id}
          onClick={onClick}
          question={subQuestion}
          order={`${order}.${i + 1}`}
        />
      ))}
    </>
  );
};
