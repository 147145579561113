import { Button, styled, useTheme } from '@mui/material';
import { ReactComponent as SendIcon } from '../../../assets/icons/envelope.svg';
import { Typography } from '../../../components/Typography/Typography';
import { useSendReportAgain } from '../../../queries/useSendReportAgain';

const IconWrapper = styled('div')<{ isDisabled?: boolean }>`
  path {
    fill: ${({ theme, isDisabled }) =>
      isDisabled ? theme.colors.primary[40] : theme.colors.accent[50]};
  }
`;

interface Props {
  reportId: number;
}

export const SendReportAgainCTA = ({ reportId }: Props) => {
  const { colors } = useTheme();

  const { mutate, isLoading } = useSendReportAgain();

  const onSendAgain: React.MouseEventHandler<HTMLButtonElement> | undefined = (e) => {
    e.stopPropagation();
    mutate(reportId);
  };

  const isDisabled = isLoading;

  return (
    <Button
      disabled={isDisabled}
      variant='text'
      startIcon={
        <IconWrapper isDisabled={isDisabled}>
          <SendIcon />
        </IconWrapper>
      }
      style={{ minWidth: 'auto' }}
      onClick={onSendAgain}
    >
      <Typography
        variant='subtitle2'
        color={isDisabled ? colors.primary[40] : colors.accent[50]}
        style={{ marginBottom: '-1px' }}
      >
        Send Again
      </Typography>
    </Button>
  );
};
