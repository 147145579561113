import { TextField, styled } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { CORE_KPI_FORMAT } from '../../../../../../../../../types';
import { useMemo } from 'react';

const FieldWrapper = styled('div')`
  flex: 1;
  & .MuiInputBase-root {
    border-radius: 0 4px 4px 0;
  }
`;

interface Props {
  conditionValue?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  selectedKPIFormat?: CORE_KPI_FORMAT;
}

export const ConditionValue = ({ conditionValue, setFieldValue, selectedKPIFormat }: Props) => {
  const suffix = useMemo(() => {
    if (selectedKPIFormat === CORE_KPI_FORMAT.PERCENTAGE) {
      return '%';
    }
    return '';
  }, [selectedKPIFormat]);

  return (
    <FieldWrapper>
      <NumericFormat
        name='conditionValue'
        allowNegative={false}
        thousandSeparator=','
        thousandsGroupStyle='thousand'
        decimalScale={2}
        fixedDecimalScale
        suffix={suffix}
        value={conditionValue || ''}
        onValueChange={(values) => {
          if (!values.floatValue) return setFieldValue('conditionValue', '');
          setFieldValue('conditionValue', String(values.floatValue));
        }}
        customInput={TextField}
        style={{ width: '100%', height: '100%' }}
      />
    </FieldWrapper>
  );
};
