import { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import {
  createRequestCompletedProgressState,
  selectedCompaniesState,
} from '../../../../state/UIState';

import { SortingState, ColumnFiltersState } from '@tanstack/react-table';
import { CREATE_REQUEST_PARAMS, Company, SURVEY_STAGE } from '../../../../types';

import { useCompanies } from '../../../../queries/useCompanies';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { SelectableCompaniesTable } from '../../../../components/SelectableCompaniesTable/SelectableCompaniesTable';

export const CompaniesTable = () => {
  const { isLoading, data: companies } = useCompanies();

  const [, setProgress] = useAtom(createRequestCompletedProgressState);
  const [selectedCompanies, setSelectedCompanies] = useAtom(selectedCompaniesState);

  const { params, setParam } = useQueryParams();
  const companyIds = params[CREATE_REQUEST_PARAMS.COMPANY_IDS] as string[];

  const [rowSelection, setRowSelection] = useState(() =>
    selectedCompanies.reduce((acc: Record<number, boolean>, company) => {
      acc[company.id] = true;
      return acc;
    }, {})
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const surveyStage = params[CREATE_REQUEST_PARAMS.SURVEY_STAGE];

  const data = useMemo(() => {
    if (!companies) return [];
    return companies.map((company) => ({
      ...company,
      disabled: surveyStage === SURVEY_STAGE.CORE_KPI && company.isRecurringKpiAssigned,
    }));
  }, [companies, surveyStage]);

  useEffect(() => {
    if (!selectedCompanies?.length) {
      const companiesFromParams = data?.filter((company) =>
        companyIds?.includes(String(company.id))
      );

      setRowSelection(() =>
        companiesFromParams.reduce((acc: Record<number, boolean>, company) => {
          acc[company.id] = true;
          return acc;
        }, {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!companies) return;
    const items = Object.keys(rowSelection);
    items.length === 0 ? setProgress(66) : setProgress(100);
    const newCompanies = Object.keys(rowSelection)
      .map((id) => companies.find((company) => company.id === Number(id)))
      .filter(Boolean) as Company[];
    const filteredNewCompanies = newCompanies.filter(
      (company) => surveyStage !== SURVEY_STAGE.CORE_KPI || !company.isRecurringKpiAssigned
    );
    setSelectedCompanies(filteredNewCompanies);
    const companyIds = filteredNewCompanies.map((c) => c.id);
    setParam({ param: CREATE_REQUEST_PARAMS.COMPANY_IDS, value: companyIds });
  }, [companies, rowSelection, setParam, setProgress, setSelectedCompanies, surveyStage]);

  return (
    <SelectableCompaniesTable
      companiesTableData={data}
      disabledText='Assigned'
      sorting={sorting}
      setSorting={setSorting}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      selectedCompanies={selectedCompanies}
      isLoading={isLoading || !companies}
      tableHeight='calc(100vh - 468px)'
    />
  );
};
