import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { useSortedExclusionCategories } from '../../../hooks/useSortedExclusionCategories';
import { ExclusionCategory } from '../../../types';
import { CategoriesList } from './CategoriesList/CategoriesList';
import { useAtom } from 'jotai';
import { expandedCategoryState, selectedConditionState } from '../../../state/UIState';

const Wrapper = styled('div')`
  background-color: ${({ theme }) => theme.colors.primary[0]};
  width: 432px;
  border-right: 1px solid ${({ theme }) => theme.colors.primary[20]};
  padding-top: 24px;
  height: 100%;
  overflow: hidden;
`;

const TitleWrapper = styled('div')`
  padding: 0 24px 16px 24px;
`;

export const SideSection = () => {
  const { colors } = useTheme();
  const [filterValue, setFilterValue] = useState<string>('');

  const [selectedCondition, setSelectedCondition] = useAtom(selectedConditionState);
  const [expandedCategory, setExpandedCategory] = useAtom(expandedCategoryState);

  const { items } = useSortedExclusionCategories();

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = e.target;
      setFilterValue(value);
    },
    []
  );

  const activeCategories: ExclusionCategory[] = useMemo(() => {
    if (!items?.length) return [];
    return items
      ?.filter((item) =>
        item.conditions?.some(
          (condition) =>
            condition?.isActive && condition?.exclusions?.some((exclusion) => exclusion?.isActive)
        )
      )
      ?.map((category) => {
        return {
          ...category,
          conditions: category.conditions
            ?.filter(
              (condition) =>
                condition.isActive &&
                condition?.exclusions?.some((exclusion) => exclusion?.isActive)
            )
            ?.map((condition) => {
              return {
                ...condition,
                exclusions: condition?.exclusions?.filter((exclusion) => exclusion?.isActive),
              };
            }),
        };
      });
  }, [items]);

  const categoriesList = useMemo(() => {
    if (!filterValue) return activeCategories;

    return (
      activeCategories?.filter((category) =>
        category.name?.toLowerCase().includes(filterValue?.toLowerCase())
      ) || []
    );
  }, [activeCategories, filterValue]);

  useEffect(() => {
    if (!selectedCondition && !expandedCategory) {
      setExpandedCategory(categoriesList?.[0] || null);
      setSelectedCondition(categoriesList?.[0]?.conditions?.[0] || null);
    }
  }, [categoriesList, expandedCategory, selectedCondition, setExpandedCategory, setSelectedCondition]);

  return (
    <Wrapper>
      <TitleWrapper>
        <Typography variant='h4' color={colors.primary[90]}>
          Conditions
        </Typography>
        <SearchInput
          placeholder='Search for a category'
          onChange={onFilterChange}
          value={filterValue}
          style={{ marginTop: '20px' }}
          onClear={() => setFilterValue('')}
        />
      </TitleWrapper>
      <CategoriesList categoriesList={categoriesList} />
    </Wrapper>
  );
};
