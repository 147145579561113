import { FC } from 'react';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-gray-circle.svg';
import { Typography } from '../Typography/Typography';
import { IconButton, styled, useTheme } from '@mui/material';

const UploadedFileWrapper = styled('div')`
  padding: 0 16px;
  height: 62px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary[20]};
`;
const DetailsWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const NameWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

interface Props {
  file: File;
  onClear: (file: File) => void;
  style?: React.CSSProperties;
}

export const FileItem: FC<Props> = ({ file, onClear, style }) => {
  const { colors } = useTheme();

  const getSizeLabel = (size: number) => {
    const sizeInKB = size / 1024;
    const sizeInMB = sizeInKB / 1024;
    if (sizeInMB > 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB > 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${size} Bytes`;
    }
  };
  return (
    <UploadedFileWrapper style={style}>
      <DetailsWrapper>
        <NameWrapper>
          <FileIcon />
          <Typography variant='srOnly' color={colors.primary[90]}>
            {file.name}
          </Typography>
        </NameWrapper>
        <Typography variant='caption' color={colors.primary[70]}>
          {getSizeLabel(file.size)}
        </Typography>
      </DetailsWrapper>
      <IconButton onClick={() => onClear(file)}>
        <CloseIcon />
      </IconButton>
    </UploadedFileWrapper>
  );
};
