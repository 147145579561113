import { Button } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as AddIcon } from '../../../../assets/icons/add-blue.svg';
import { AddStepModal } from './AddStepModal/AddStepModal';
import { WorkflowStep } from '../../../../types';

interface Props {
  stageId: number;
  onAddStep: (step: Partial<WorkflowStep> | null) => Promise<WorkflowStep | undefined>;
}

export const AddStep = ({ stageId, onAddStep }: Props) => {
  const [isAddStepModalOpen, setIsAddStepModalOpen] = useState(false);

  return (
    <>
      <Button
        variant='outlined'
        startIcon={<AddIcon width={'10px'} />}
        onClick={() => {
          setIsAddStepModalOpen(true);
        }}
      >
        Add Step
      </Button>
      {isAddStepModalOpen && (
        <AddStepModal
          isOpen={isAddStepModalOpen}
          setIsOpen={setIsAddStepModalOpen}
          stageId={stageId}
          onAddStep={onAddStep}
        />
      )}
    </>
  );
};
