import { Tab, Tabs, styled, useTheme } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Typography } from '../Typography/Typography';
import { TabPanel, a11yProps } from '../TabPanel/TabPanel';
import { ReviewTabConfig } from '../../types';

const Wrapper = styled('div')`
  width: 500px;
`;

interface Props {
  tabsConfig: ReviewTabConfig[];
}

export const ReviewReportTabs = ({ tabsConfig }: Props) => {
  const { colors } = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (_: ChangeEvent<object>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Wrapper>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='simple tabs example'
        sx={{
          minHeight: '0',
          height: '42px',
          padding: '0 16px',
          borderBottom: tabsConfig?.length ? `1px solid ${colors.primary[30]}` : 'none',
          '.MuiTabs-flexContainer': { height: '100%', gap: '38px', textTransform: 'none' },
        }}
      >
        {tabsConfig.map((tabConfig, index) => {
          return (
            <Tab
              key={index}
              className={`mui-custom-tab`}
              sx={{
                '&&.mui-custom-tab.Mui-selected p': {
                  color: colors.primary[90],
                },
              }}
              label={
                <Typography variant='subtitle2' color={colors.primary[70]}>
                  {tabConfig.tab}
                </Typography>
              }
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>

      {tabsConfig?.map((tabConfig, i) => {
        return (
          <TabPanel value={value} index={i} key={i}>
            {tabConfig.tabComponent}
          </TabPanel>
        );
      })}
    </Wrapper>
  );
};
