import { useSortable } from '@dnd-kit/sortable';
import { styled } from '@mui/material';
import { Exclusion } from '../../../../../../../../types';
import { CSS } from '@dnd-kit/utilities';
import { ExclusionContent } from './ExclusionContent';

const SortableWrapper = styled('div')``;

interface Props {
  exclusion: Exclusion;
  exclusionOrder: number;
  isDraggable: boolean;
}

export const ExclusionItem = ({ exclusion, exclusionOrder, isDraggable }: Props) => {
  const { setNodeRef, transform, transition, attributes, listeners, isDragging } =
    useSortable(exclusion);
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableWrapper
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ ...style, opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 2 : 1 }}
    >
      <ExclusionContent
        exclusion={exclusion}
        exclusionOrder={exclusionOrder}
        isDragging={isDragging}
        isDraggable={isDraggable}
      />
    </SortableWrapper>
  );
};
