import { styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { Typography } from '../../../components/Typography/Typography';
import { activeReportState } from '../../../state/UIState';

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 46px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export const CompletedStep = () => {
  const { colors } = useTheme();
  const [report] = useAtom(activeReportState);

  return (
    <Wrapper>
      <Typography
        variant='h4'
        color={colors.primary[80]}
        style={{ width: '600px', textAlign: 'center', marginTop: '186px' }}
      >
        Congrats! The {report?.request.name} report was successfully submitted.
      </Typography>
    </Wrapper>
  );
};
