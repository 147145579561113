import { MouseEvent, useCallback, useState } from 'react';
import { ActionsPopupMenu } from '../../../../components/ActionsPopupMenu/ActionsPopupMenu';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { DeleteStepModal } from './DeleteStepModal';
import { WorkflowStep } from '../../../../types';
import { DUE_DILIGENCE_SURVEY_NAME } from '../../../../constants/constants';
import { useFindDependentSteps } from './useFindDependentSteps';

const options = [
  {
    id: 'DELETE',
    value: 'Delete',
    icon: <DeleteIcon />,
  },
];

interface Props {
  onDeleteStep: (stepId: number | string) => void;
  step: WorkflowStep;
}

export const StepActionMenu = ({ onDeleteStep, step }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const dependentSteps = useFindDependentSteps();

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const onMenuItemClick = useCallback((id: string) => {
    handleCloseMenu();
    if (id === 'DELETE') {
      setIsDeleteModalOpen(true);
      return;
    }

    throw new Error('Unknown option');
  }, []);

  const onConfirmDelete = () => {
    onDeleteStep(step.id);

    const dependentStep = dependentSteps?.find((dependentStep) =>
      dependentStep?.blockingSteps?.some((s) => s.id === step.id)
    );

    if (dependentStep) {
      onDeleteStep(dependentStep.id);
    }
    setIsDeleteModalOpen(false);
  };

  if (step.name === DUE_DILIGENCE_SURVEY_NAME) return null;

  return (
    <>
      <ActionsPopupMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        actions={options}
        onClick={(e) => handleClick(e)}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseMenu();
        }}
        onMenuItemClick={onMenuItemClick}
        menuStyles={{
          transform: 'translate(5px, 0)',
          width: '158px',
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      />
      <DeleteStepModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        onDeleteStep={onConfirmDelete}
      />
    </>
  );
};
