import { useMemo } from 'react';
import {
  DUE_DILIGENCE_SURVEY_NAME,
  SASB_ASSESSMENT_NAME,
} from '../../../../../../constants/constants';
import { COMPANY_INFO_STEP, WorkflowStep } from '../../../../../../types';

export const useIsBlockingStepsDisabled = (step: WorkflowStep) => {
  return useMemo(
    () =>
      step.name === DUE_DILIGENCE_SURVEY_NAME ||
      step.name === SASB_ASSESSMENT_NAME ||
      step.companyInfoStep === COMPANY_INFO_STEP.COMPANY_INFORMATION ||
      step.companyInfoStep === COMPANY_INFO_STEP.ESG_CONTACT ||
      step.companyInfoStep === COMPANY_INFO_STEP.ANALYST_RATING,
    [step.companyInfoStep, step.name]
  );
};
