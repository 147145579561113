import { BarSeriesOption } from 'echarts';
import { BarLineChart } from '../../../../../components/Charts/BarCharts/BarLineChart';
import { styled } from '@mui/material';
import { useAtom } from 'jotai';
import {
  companyKpisDataState,
  initialSelectedKpisMatrixState,
  selectedFrequencyPreviewState,
  selectedKpisMatrixRowsState,
} from '../../../../../state/UIState';
import { useMemo } from 'react';
import { useCoreKpiCategories } from '../../../../../queries/useCoreKpiCategories';
import { useBarChartColor } from '../useBarChartColor';
import { FrequencySelector } from './FrequencySelector';
import { KpiBarChartContainer, KpiBarChartWrapper } from './UI';
import { NoKpisSelected } from './NoKpisSelected';

const FrequencyWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const KpisBarChart = () => {
  const [selectedKpis, setSelectedKpis] = useAtom(selectedKpisMatrixRowsState);
  const [initialSelectedKpis] = useAtom(initialSelectedKpisMatrixState);
  const [companyKpisData] = useAtom(companyKpisDataState);
  const [selectedFrequencyPreview] = useAtom(selectedFrequencyPreviewState);
  const { data: coreKpiCategories } = useCoreKpiCategories();

  const getBarChartColor = useBarChartColor();

  const selectedCoreKpis = useMemo(() => {
    const allKpis = coreKpiCategories?.flatMap((category) => category?.kpis);

    return (
      selectedKpis
        ?.map((kpiId) => {
          return allKpis?.find((kpi) => kpi?.id === kpiId);
        })
        ?.filter((kpi) => !!kpi) || []
    );
  }, [coreKpiCategories, selectedKpis]);

  const selectedFrequencyKpisData = useMemo(() => {
    return selectedCoreKpis?.map((coreKpi) => {
      if (!coreKpi) return null;
      const data = companyKpisData?.[selectedFrequencyPreview]?.map((period) => {
        const periodKpi = period[coreKpi.id];
        return [period.period, periodKpi?.value || 0];
      });
      return {
        ...coreKpi,
        formattedPeriodData: data,
      };
    });
  }, [companyKpisData, selectedCoreKpis, selectedFrequencyPreview]);

  const series: BarSeriesOption[] = useMemo(() => {
    return selectedFrequencyKpisData?.map((kpiData, i) => {
      return {
        name: kpiData?.name,
        type: 'bar',
        barGap: 0.2,
        color: getBarChartColor(i),
        data: kpiData?.formattedPeriodData,
      };
    });
  }, [getBarChartColor, selectedFrequencyKpisData]);

  const onReset = () => {
    setSelectedKpis(initialSelectedKpis);
  };

  if (!series?.length) return <NoKpisSelected />;

  return (
    <KpiBarChartWrapper>
      <KpiBarChartContainer>
        <FrequencyWrapper>
          <FrequencySelector />
        </FrequencyWrapper>
        <BarLineChart seriesData={series} showLegend onResetSelection={onReset} />
      </KpiBarChartContainer>
    </KpiBarChartWrapper>
  );
};
