import ContentLoader from 'react-content-loader';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')``;
const HeaderWrapper = styled('div')`
  padding: 0 40px;
  height: 71px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
`;
const ContentWrapper = styled('div')`
  display: flex;
`;
const LeftSectionLoader = styled('div')`
  padding: 39px 40px;
  width: 340px;
  border-right: 1px solid rgb(235, 241, 244);
  height: calc(100vh - 71px);
`;

const RightSectionLoader = styled('div')`
  padding: 24px 50px;
  flex-grow: 1;
  height: calc(100vh - 71px);
`;

export function SurveyEditorSkeletonLoader(): ReactElement {
  return (
    <SkeletonWrapper>
      <HeaderWrapper>
        <ContentLoader height={71} width={'100%'}>
          <rect width='345' height='34' y='19' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </HeaderWrapper>
      <ContentWrapper>
        <LeftSectionLoader>
          <ContentLoader height={684} width={'100%'}>
            <rect width='100' height='26' rx='4' fill='#EEEFF1' />
            <rect width='26' height='26' x='233' fill='#EEEFF1' />
            <rect width='100%' height='26' y='34' fill='#EEEFF1' />
            <rect width='100%' height='26' y='68' fill='#EEEFF1' />
            <rect width='100%' height='26' y='102' fill='#EEEFF1' />
            <rect width='100%' height='26' y='136' fill='#EEEFF1' />
            <rect width='100%' height='26' y='170' fill='#EEEFF1' />
            <rect width='100%' height='26' y='204' fill='#EEEFF1' />
            <rect width='100%' height='26' y='238' fill='#EEEFF1' />
            <rect width='100%' height='26' y='272' fill='#EEEFF1' />
            <rect width='100%' height='26' y='306' fill='#EEEFF1' />
            <rect width='100%' height='26' y='340' fill='#EEEFF1' />
            <rect width='100%' height='26' y='374' fill='#EEEFF1' />
          </ContentLoader>
        </LeftSectionLoader>
        <RightSectionLoader>
          <ContentLoader height={684} width={'100%'}>
            <rect width='200px' height='36' rx='4' fill='#EEEFF1' />
            <rect width='736px' height='200' y='52' rx='4' fill='#EEEFF1' />
            <rect width='736px' height='200' y='268' rx='4' fill='#EEEFF1' />
            <rect width='736px' height='200' y='484' rx='4' fill='#EEEFF1' />
          </ContentLoader>
        </RightSectionLoader>
      </ContentWrapper>
    </SkeletonWrapper>
  );
}
