import { useAtom } from 'jotai';
import { selectedCoreKpiState } from '../../../state/UIState';

import { useCallback } from 'react';
import { useOnCancelConfirm } from './useOnCancelConfirm';
import { useMoveSingleKpi } from './useMoveSingleKpi';
import { useMoveKpis } from './useMoveKpis';
import { useUpdateKpiSurvey } from './useUpdateKpiSurvey';

export const useConfirmBarActions = () => {
  const [selectedKpi] = useAtom(selectedCoreKpiState);

  const { onCancel } = useOnCancelConfirm();

  const { moveSingleKpi } = useMoveSingleKpi();
  const { moveKpis } = useMoveKpis();

  const { onUpdateKpiSurvey } = useUpdateKpiSurvey();

  const onConfirm = useCallback(async () => {
    if (selectedKpi) return moveSingleKpi();
    return moveKpis();
  }, [moveKpis, moveSingleKpi, selectedKpi]);

  return { onCancel, onConfirm, onUpdateKpiSurvey };
};
