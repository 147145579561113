import { styled, useTheme } from '@mui/material';
import { ChipsSelectorV2 } from '../ButtonSelector/ChipsSelectorV2';
import { Typography } from '../Typography/Typography';
import { SelectItem } from '../../types';

const Wrapper = styled('div')`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const options = [
  { id: 'yes', value: 'Yes' },
  { id: 'no', value: 'No' },
];

interface Props {
  selectedActivePosition: SelectItem | null;
  onChangeActivePositionFilter: (selectedItem: SelectItem | null) => void;
}

export const ActivePositionFilter = ({
  onChangeActivePositionFilter,
  selectedActivePosition,
}: Props) => {
  const { colors } = useTheme();

  return (
    <Wrapper>
      <Typography variant='srOnly' color={colors.primary[90]}>
        Active Position:
      </Typography>
      <ChipsSelectorV2
        options={options}
        selectedItem={selectedActivePosition}
        onSelect={onChangeActivePositionFilter}
      />
    </Wrapper>
  );
};
