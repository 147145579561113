import { styled } from '@mui/material';
import { StagesManagement } from '../components/StagesManagement';

import { useCallback } from 'react';

import { useCreateStage, useWorkflowStages } from '../../../queries/useWorkflowStages';
import { COMPANY_TYPE } from '../../../types';
import { ConfirmActionBar } from '../../../components/ConfirmActionBar/ConfirmActionBar';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { useUpdateWorkflow, useWorkflows } from '../../../queries/useWorkflows';
import { IssuersStagesList } from './IssuersStagesList/IssuersStagesList';

const Wrapper = styled('div')``;

export const IssuersWorkflow = () => {
  const { data: stages } = useWorkflowStages();
  const { mutate: createStage } = useCreateStage();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const { data: workflows } = useWorkflows();
  const { mutateAsync: updateWorkflow } = useUpdateWorkflow();

  const onUpdateWorkflows = async () => {
    const workflowId = workflows?.find(
      (workflow) => workflow.companyType === COMPANY_TYPE.NOT_CONTROLLED
    )?.id;

    const configStages = stages?.map((stage) => {
      return {
        ...stage,
        steps: stage?.steps?.map((step) => {
          if (step?.survey) {
            return {
              ...step,
              survey: {
                id: step?.survey?.id,
                name: step?.survey?.name,
              },
            };
          }
          return step;
        }),
      };
    });

    updateWorkflow(
      {
        id: workflowId,
        config: { stages: configStages || [] },
      },
      {
        onSuccess: async () => {
          if (!appConfigData) return;

          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isNotControlledWorkflowUpToDate: true },
          });
        },
      }
    );
  };

  const onAddStage = useCallback(() => {
    const payload = {
      name: `Stage ${(stages?.length || 0) + 1}`,
      order: (stages?.length || 0) + 1,
    };

    createStage(payload, {
      onSuccess: async () => {
        if (!appConfigData) return;

        await updateAppConfig.mutateAsync({
          data: { ...appConfigData?.data, isNotControlledWorkflowUpToDate: false },
        });
      },
    });
  }, [appConfigData, createStage, stages?.length, updateAppConfig]);

  if (!stages) return null;

  return (
    <Wrapper>
      <ConfirmActionBar
        text='Update the Workflow to reflect changes for all new companies.'
        confirmText='Update'
        onConfirm={onUpdateWorkflows}
        isActive={!appConfigData?.data?.isNotControlledWorkflowUpToDate}
      />
      <StagesManagement onAddStage={onAddStage}>
        <IssuersStagesList stages={stages} />
      </StagesManagement>
    </Wrapper>
  );
};
