import { useMemo } from 'react';
import { useReportsByCompanyId } from '../queries/useReports';
import { useCompanyById } from '../queries/useCompanies';

export const useExclusionDueDiligenceReport = (companyId?: number) => {
  const { data: companyReports } = useReportsByCompanyId(companyId);

  const { data: company } = useCompanyById(companyId);

  return useMemo(() => {
    if (!company?.exclusionDueDiligenceSurvey || !companyReports) return;
    const report = companyReports.find(
      (report) => report.survey.id === company.exclusionDueDiligenceSurvey?.id
    );
    return report;
  }, [company?.exclusionDueDiligenceSurvey, companyReports]);
};
