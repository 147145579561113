import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { RequestGroupsTable } from './RequestGroupsTable';

const Wrapper = styled('div')`
  padding: 24px 40px;
`;
const RequestsSectionWrapper = styled('div')`
  margin-bottom: 40px;
`;

export const RequestsContent = () => {
  const { colors } = useTheme();

  return (
    <Wrapper>
      <RequestsSectionWrapper>
        <Typography variant='h4' color={colors.primary[90]}>
          Awaiting Approval
        </Typography>
        <RequestGroupsTable />
      </RequestsSectionWrapper>
    </Wrapper>
  );
};
