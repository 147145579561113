import { Chip, styled } from '@mui/material';
import { Sector } from '../../../types';

interface Props {
  sectors: Sector[];
}

const Wrapper = styled('div')`
  display: flex;
  gap: 4px;
`;

export const SectorsCell = ({ sectors }: Props) => {
  return (
    <Wrapper>
      {sectors.map((s) => (
        <Chip key={s.id} label={s.name} />
      ))}
    </Wrapper>
  );
};
