import { useMemo } from 'react';
import { useReportsByCompanyId } from '../queries/useReports';
import { useCompanyById } from '../queries/useCompanies';

export const useSASBReport = (companyId?: number) => {
  const { data: companyReports } = useReportsByCompanyId(companyId);

  const { data: company } = useCompanyById(companyId);

  const SASBReport = useMemo(() => {
    if (!company?.industry?.survey || !companyReports) return;
    const report = companyReports.find(
      (report) => report.survey.id === company.industry?.survey?.id
    );
    return report;
  }, [company?.industry?.survey, companyReports]);

  return SASBReport;
};
