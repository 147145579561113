import { styled } from '@mui/material';
import { useAtom } from 'jotai';
import { Fragment, useEffect, useState } from 'react';
import {
  notReviewedSectionsState,
  reportReviewCompletedPercentageState,
  sectionsState,
} from '../../../state/UIState';
import { SurveySection } from '../../../types';
import { flattenTree } from '../../../utils/treeUtilities';
import { ReviewSectionItem } from './ReviewSectionItem';
import { SidebarSectionsWithProgressBar } from '../../../components/SidebarSectionsWithProgressBar/SidebarSectionsWithProgressBar';

const SectionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ReviewSideSection = () => {
  const [completedPercentage] = useAtom(reportReviewCompletedPercentageState);
  const [allSections] = useAtom(sectionsState);
  const [notReviewedSections] = useAtom(notReviewedSectionsState);
  const [showAllSections, setShowAllSections] = useState(true);
  const buttonLabel = showAllSections ? 'Show Unevaluated' : 'Show All';
  const sections = showAllSections ? allSections : notReviewedSections;

  useEffect(() => {
    if (!notReviewedSections.length) setShowAllSections(true);
  }, [allSections, notReviewedSections.length]);

  function renderSubsections(section: SurveySection): JSX.Element | null {
    const [, ...flatSubsections] = flattenTree([section]);
    if (flatSubsections && flatSubsections.length) {
      return (
        <Fragment>
          {flatSubsections.map((subsection, i) => (
            <ReviewSectionItem
              key={subsection.id}
              section={subsection}
              isLastSubsection={i === flatSubsections.length - 1}
            />
          ))}
        </Fragment>
      );
    }

    return null;
  }

  return (
    <SidebarSectionsWithProgressBar
      completedPercentage={completedPercentage}
      setShowAllSections={setShowAllSections}
      showAllSectionsCTADisabled={!notReviewedSections.length}
      showSectionsButtonText={buttonLabel}
    >
      {sections.map((section) => (
        <SectionWrapper key={section.id}>
          <ReviewSectionItem key={section.id} section={section} />
          {!section.collapsed && renderSubsections(section)}
        </SectionWrapper>
      ))}
    </SidebarSectionsWithProgressBar>
  );
};
