import { useCallback, useEffect, useMemo } from 'react';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';

import { ChecklistCategory } from '../../../types';
import { useAtom } from 'jotai';
import {
  activeChecklistCategoryState,
  sortedChecklistCategoriesState,
} from '../../../state/UIState';
import {
  useChecklistCategories,
  useCreateChecklistCategory,
  useUpdateChecklistCategory,
} from '../../../queries/useChecklistCategories';

export function useSortedChecklistCategories() {
  const { data: appConfig, isLoading: isConfigLoading } = useAppConfig();
  const { data: checklistCategories, isLoading: areCategoriesLoading } = useChecklistCategories();
  const [sortedItems, setSortedItems] = useAtom(sortedChecklistCategoriesState);
  const [, setActiveCategory] = useAtom(activeChecklistCategoryState);
  const updatePageConfig = useUpdateAppConfig();
  const createChecklistCategory = useCreateChecklistCategory();
  const updateChecklistCategory = useUpdateChecklistCategory();
  useEffect(() => {
    if (!checklistCategories || !appConfig || isConfigLoading || areCategoriesLoading) {
      return;
    }
    const { checkListCategoriesOrder } = appConfig.data;

    const sorted = checkListCategoriesOrder.map((id) =>
      checklistCategories.find((item) => item.id === id)
    );
    const filteredItems = sorted.filter((item) => !!item) as ChecklistCategory[];
    setSortedItems(filteredItems);

    setActiveCategory((prev) => {
      const prevActive = checklistCategories?.find((category) => category.id === prev?.id);
      return prevActive || filteredItems[0];
    });
  }, [
    appConfig,
    setSortedItems,
    isConfigLoading,
    areCategoriesLoading,
    setActiveCategory,
    checklistCategories,
  ]);

  const onReorder = useCallback(
    async (newOrder: ChecklistCategory[]) => {
      if (!appConfig?.data) {
        return;
      }
      const oldOrder = [...sortedItems];
      setSortedItems(newOrder);
      await updatePageConfig.mutateAsync(
        {
          data: {
            ...appConfig?.data,
            checkListCategoriesOrder: newOrder.map((item) => item.id),
            areCheckListSurveysUpToDate: false,
          },
        },
        {
          onError: () => {
            setSortedItems(oldOrder);
          },
        }
      );
    },
    [appConfig?.data, setSortedItems, sortedItems, updatePageConfig]
  );

  const onAddCategory = useCallback(async () => {
    if (!appConfig?.data) {
      return;
    }
    const newCategory = await createChecklistCategory.mutateAsync({ name: 'New Category' });
    const newOrder = [...sortedItems, { ...newCategory, isNew: true }];

    await updatePageConfig.mutateAsync(
      {
        data: {
          ...appConfig?.data,
          checkListCategoriesOrder: newOrder.map((item) => item.id),
          areCheckListSurveysUpToDate: false,
        },
      },
      {
        onSuccess: () => {
          setSortedItems(newOrder);
          setActiveCategory(newCategory);
        },
      }
    );
  }, [
    appConfig?.data,
    createChecklistCategory,
    setActiveCategory,
    setSortedItems,
    sortedItems,
    updatePageConfig,
  ]);

  const onRemoveCategory = useCallback(
    async (id: number) => {
      if (!appConfig?.data) {
        return;
      }
      const oldOrder = [...sortedItems];
      const newOrder = sortedItems.filter((item) => item.id !== id);
      await updateChecklistCategory.mutateAsync({ id, isDeleted: true });
      await updatePageConfig.mutateAsync(
        {
          data: {
            ...appConfig?.data,
            checkListCategoriesOrder: newOrder.map((item) => item.id),
            areCheckListSurveysUpToDate: false,
          },
        },
        {
          onError: () => {
            setSortedItems(oldOrder);
          },
          onSuccess: () => {
            setActiveCategory(newOrder[0] ? newOrder[0] : null);
          },
        }
      );
    },
    [
      appConfig?.data,
      setActiveCategory,
      setSortedItems,
      sortedItems,
      updateChecklistCategory,
      updatePageConfig,
    ]
  );

  const onRenameCategory = useCallback(
    async (id: number, name: string) => {
      await updateChecklistCategory.mutateAsync(
        { id, name },
        {
          onSuccess: async () => {
            if (!appConfig?.data) {
              return;
            }
            await updatePageConfig.mutateAsync({
              data: {
                ...appConfig?.data,
                areCheckListSurveysUpToDate: false,
              },
            });
          },
        }
      );
    },
    [appConfig?.data, updateChecklistCategory, updatePageConfig]
  );

  const value = useMemo(() => {
    return {
      items: sortedItems as ChecklistCategory[],
      isLoading: isConfigLoading || areCategoriesLoading,
      onReorder,
      onAddCategory,
      onRemoveCategory,
      onRenameCategory,
    };
  }, [
    areCategoriesLoading,
    isConfigLoading,
    onAddCategory,
    onRemoveCategory,
    onReorder,
    sortedItems,
    onRenameCategory,
  ]);

  return value;
}
