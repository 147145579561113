import { useParams } from 'react-router';
import { useCompanyById } from '../../../queries/useCompanies';
import { COMPANY_STAGE } from '../../../types';
import { useAllStepsByCompanyType } from '../../../hooks/useAllStepsByCompanyType';
import { useMemo } from 'react';

export const useIsInScreeningFlow = () => {
  const params = useParams();

  const { data: company } = useCompanyById(Number(params.id));

  const allSteps = useAllStepsByCompanyType(company?.type);

  const isAllComplete = useMemo(() => {
    if (!allSteps?.length) return false;
    return allSteps?.every((step) => company?.completedWorkflowSteps?.includes(step.id));
  }, [allSteps, company?.completedWorkflowSteps]);

  return company?.stage !== COMPANY_STAGE.ESG_COMPLETE && !isAllComplete;
};
