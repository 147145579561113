import { useCallback } from 'react';
import { QUESTION_TYPE, QuestionOption as TQuestionOption } from '../../../../../../../types';
import { useAtom } from 'jotai';
import {
  activeQuestionIdState,
  activeSurveyState,
  questionState,
} from '../../../../../../../state/UIState';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { QuestionOption } from './QuestionOption';

interface Props {
  option: TQuestionOption;
  showDragIcon: boolean;
}

export const MultiOption = ({ option, showDragIcon }: Props) => {
  const [activeQuestionId] = useAtom(activeQuestionIdState);
  const [question, setQuestion] = useAtom(questionState(activeQuestionId));
  const { setNodeRef, transform, transition, attributes, listeners } = useSortable(option);

  const [activeSurvey] = useAtom(activeSurveyState);

  const onUpdateFlag = useCallback(() => {
    if (!question) return;

    const updatedQuestion = {
      ...question,
      options: question.options.map((o) => {
        if (o.id === option.id) {
          return {
            ...o,
            isFlagged: !option.isFlagged,
          };
        }

        return o;
      }),
    };

    setQuestion(updatedQuestion);
  }, [option.id, option.isFlagged, question, setQuestion]);

  const onUpdateWeight = useCallback(
    (weight: number) => {
      if (!question || !activeSurvey?.includeWeight) return;

      const updatedQuestion = {
        ...question,
        options: question.options.map((o) => {
          if (o.id === option.id) {
            return {
              ...o,
              weight,
            };
          }

          return o;
        }),
      };

      setQuestion(updatedQuestion);
    },
    [activeSurvey?.includeWeight, option.id, question, setQuestion]
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <QuestionOption
        option={option}
        type={QUESTION_TYPE.MULTI}
        showDragIcon={showDragIcon}
        onUpdateFlag={onUpdateFlag}
        onUpdateWeight={onUpdateWeight}
      />
    </div>
  );
};
