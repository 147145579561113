import { useParams } from 'react-router';
import { CompanyPayload, useCompanyById, useUpdateCompany } from '../../../queries/useCompanies';
import { useChecklistQuarterlySurvey } from '../../../hooks/useChecklistQuarterlySurvey';
import { useQueryClient } from 'react-query';
import { useCreateRequest } from '../../../queries/useRequests';
import { Button, Chip, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { SURVEY_FREQUENCY } from '../../../types';
import { REPORTS } from '../../../queries/useReports';
import { Task } from '../TaskGroup';
import { ReactComponent as ApprovedIcon } from '../../../assets/icons/request-approved.svg';

interface Props {
  // counterNum: number;
  isBlocked?: boolean;
  isStepCompleted?: boolean;
  hasAccess?: boolean;
  stepId: string | number;
}

export const ChecklistQuarterlyStep = ({
  isBlocked,
  hasAccess,
  stepId,
  isStepCompleted,
}: Props) => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));

  const checklistQuarterlySurvey = useChecklistQuarterlySurvey();

  const updateCompany = useUpdateCompany();
  const queryClient = useQueryClient();

  const createRequest = useCreateRequest();

  const { colors } = useTheme();

  const isDisabled =
    !company?.externalEsgContact?.email || isBlocked || (!hasAccess && !isStepCompleted);

  const onCreateChecklistQuarterlyReport = useCallback(async () => {
    if (!company || !checklistQuarterlySurvey || company?.isQuarterlyChecklistAssigned) return;

    createRequest.mutate(
      {
        companyId: company.id,
        frequency: SURVEY_FREQUENCY.QUARTERLY,
        surveyId: checklistQuarterlySurvey.id,
        name: checklistQuarterlySurvey.name,
        isExternal: true,
      },
      {
        onSuccess: () => {
          const companyPayload: Partial<CompanyPayload> = {
            id: company.id,
            isQuarterlyChecklistAssigned: true,
            completedWorkflowSteps: [...(company?.completedWorkflowSteps || []), stepId],
          };
          updateCompany.mutate(companyPayload, {
            onSuccess: () => {
              queryClient.refetchQueries(`${REPORTS}-${company.id}`);
            },
          });
        },
      }
    );
    return;
  }, [company, checklistQuarterlySurvey, createRequest, stepId, updateCompany, queryClient]);

  const isCompleted = useMemo(
    () => company?.completedWorkflowSteps?.includes(stepId),
    [company?.completedWorkflowSteps, stepId]
  );

  if (!company) return null;

  return (
    <>
      <Task
        taskName={`Checklist Quarterly`}
        isHighlighted={hasAccess && !isStepCompleted}
        disabled={isDisabled}
        infoText={!company?.externalEsgContact?.email || isBlocked ? 'This step is blocked' : ''}
      >
        {isCompleted ? (
          <Chip
            icon={<ApprovedIcon style={{ marginRight: '4px' }} />}
            style={{
              backgroundColor: colors.success[5],
              width: '97px',
            }}
            label={'Sent'}
          />
        ) : (
          <Button
            onClick={onCreateChecklistQuarterlyReport}
            variant='contained'
            style={{ backgroundColor: isDisabled ? colors.accent[20] : colors.accent[50] }}
            disabled={isDisabled}
          >
            Create Request
          </Button>
        )}
      </Task>
    </>
  );
};
