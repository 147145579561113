import { useTheme } from '@mui/material';
import { REVIEW_TYPE, TReviewOptionConfig } from '../../../../../types';
import { useMemo } from 'react';

export const useReviewOptionsConfig = () => {
  const { colors } = useTheme();

  const reviewOptionsConfig: TReviewOptionConfig[] = useMemo(
    () => [
      {
        type: REVIEW_TYPE.APPROVE,
        name: 'Approve',
        color: colors.success[40],
      },
      {
        type: REVIEW_TYPE.OVERRIDE,
        name: 'Override',
        color: colors.warning[40],
      },
      {
        type: REVIEW_TYPE.REJECT,
        name: 'Reject',
        color: colors.error[40],
      },
    ],
    [colors.error, colors.success, colors.warning]
  );

  return reviewOptionsConfig;
};
