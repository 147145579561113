import { FC } from 'react';
import { EvaluationRule, SurveyQuestion } from '../../../../../types';
import { useAtom } from 'jotai';
import { evaluationState } from '../../../../../state/UIState';
import { ReviewOption } from '../../../../../components/ReviewOption';
import { useTheme } from '@mui/material';

interface Props {
  rule: EvaluationRule;
  question: SurveyQuestion;
  isEvaluated: boolean;
}

export const EvaluationOption: FC<Props> = ({ rule, question, isEvaluated }) => {
  const [evaluation, setEvaluation] = useAtom(evaluationState);
  const isSelected = evaluation?.[question.id] && evaluation[question.id].weight === rule.weight;

  const { colors } = useTheme();

  const onSelectRule = () => {
    if (evaluation?.[question.id] && evaluation[question.id].weight === rule.weight) {
      const newEvaluation = { ...evaluation };
      delete newEvaluation[question.id];
      setEvaluation(newEvaluation);
      return;
    }
    const newEvaluation = { ...evaluation, [question.id]: rule };
    setEvaluation(newEvaluation);
  };

  return (
    <ReviewOption
      isSelected={isSelected}
      isEvaluated={isEvaluated}
      onSelect={onSelectRule}
      label={rule.name}
      optionColor={colors[rule?.color][40]}
    />
  );
};
