import { useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';

interface Props {
  email: string;
}

export const DeleteModalWarning = ({ email }: Props) => {
  const { colors } = useTheme();
  return (
    <Typography variant='body' color={colors.warning[50]}>
      <Typography variant='subtitle2' color={colors.accent[50]} as={'span'}>
        {email}{' '}
      </Typography>
      access will be removed.
    </Typography>
  );
};
