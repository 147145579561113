import { Checkbox, ClickAwayListener, styled, useTheme } from '@mui/material';
import { Role, SelectItem, User } from '../../../../../../types';
import { useUsers } from '../../../../../../queries/useUsers';
import { Typography } from '../../../../../../components/Typography/Typography';
import { Multiselect } from '../../../../../../components/Multiselect/Multiselect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UserAvatar } from '../../../../../../components/UserAvatar/UserAvatar';
import { RequirementOptions } from './RequirementOptions';
import { useLoadingBar } from '../../../../../../hooks/useLoadingBar';

const Container = styled('div')`
  margin-top: 12px;
`;

const OptionContentWrapper = styled('div')`
  display: flex;
`;

const OptionWrapper = styled('li')`
  &&&.MuiAutocomplete-option {
    padding: 6px 6px 6px 0;
    height: 40px;
    margin: 4px 0;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
const TitleOptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface Props {
  selectedNotifyUsers?: User[];
  onUsersSelect: (users: User[]) => void;
  approvalRoles: Role[];
  requiredUsersApproval: User[];
  onUpdateRequiredUsersApproval: (ids: number[]) => void;
}

export const NotifyUsersSelect = ({
  selectedNotifyUsers,
  onUsersSelect,
  approvalRoles,
  requiredUsersApproval,
  onUpdateRequiredUsersApproval,
}: Props) => {
  const { colors } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading } = useLoadingBar();

  const { data: users } = useUsers();

  const options = useMemo(() => {
    return (
      users
        ?.filter((user) => approvalRoles?.map((r) => r?.id)?.includes(user?.role?.id))
        ?.map((u) => ({
          id: u?.id,
          value: u?.fullName,
          icon: <UserAvatar size='medium' name={u?.fullName} />,
        })) || []
    );
  }, [approvalRoles, users]);

  const selectedNotifyUserItems = useMemo(() => {
    if (!selectedNotifyUsers?.length) return [];
    const initUserIds = selectedNotifyUsers?.map((user) => user.id);
    return options?.filter((option) => initUserIds?.includes(option.id));
  }, [options, selectedNotifyUsers]);

  const notifyUsers = useMemo(() => {
    if (!selectedNotifyUsers?.length) return options;

    return selectedNotifyUserItems;
  }, [selectedNotifyUsers?.length, options, selectedNotifyUserItems]);

  useEffect(() => {
    if (!users?.length) return;
    const initUserIds = selectedNotifyUsers?.map((user) => user.id);
    const notifyUsersIds = notifyUsers?.map((user) => user.id);
    const isUsersTheSame = initUserIds?.length
      ? initUserIds?.every((userId) => notifyUsersIds?.includes(userId))
      : false;

    if (isUsersTheSame) return;
    const selectedUsers = users?.filter((u) =>
      notifyUsers?.map((user) => user?.id)?.includes(u?.id)
    );
    onUsersSelect(selectedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalRoles]);

  const onSelectUsers = useCallback(
    (selectedUserItems: SelectItem[]) => {
      if (!users?.length) return;

      const selectedUsers = users?.filter((u) =>
        selectedUserItems?.map((user) => user.id)?.includes(u.id)
      );

      onUsersSelect(selectedUsers);
    },
    [onUsersSelect, users]
  );

  return (
    <Container>
      <Typography variant='srOnly' color={colors.primary[90]} style={{ marginBottom: '4px' }}>
        Notify
      </Typography>
      <ClickAwayListener
        onClickAway={(e) => {
          if ((e.target as HTMLElement).tagName === 'BODY') return;
          setIsOpen(false);
        }}
      >
        <div>
          <Multiselect
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            style={{ width: '400px' }}
            options={options}
            value={selectedNotifyUserItems}
            onChange={(e, val) => {
              if (isLoading) return;
              onSelectUsers(val as SelectItem[]);
            }}
            disablePortal={false}
            optionsName='selected'
            fieldPlaceholder='Select people'
            onBlurCapture={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            renderOption={(props, option, { selected }) => {
              return (
                <OptionWrapper key={option.id} {...props}>
                  <OptionContentWrapper>
                    {(option.id as number) >= 0 && <Checkbox checked={selected} />}
                    <TitleOptionWrapper>
                      {option?.icon && option?.icon}
                      <Typography variant='body' color={colors.primary[90]}>
                        {option?.value ?? ''}
                      </Typography>
                    </TitleOptionWrapper>
                  </OptionContentWrapper>
                  {selected && (
                    <RequirementOptions
                      requiredUsersApproval={requiredUsersApproval}
                      userId={option.id as number}
                      onUpdateRequiredUsersApproval={onUpdateRequiredUsersApproval}
                    />
                  )}
                </OptionWrapper>
              );
            }}
          />
        </div>
      </ClickAwayListener>
    </Container>
  );
};
