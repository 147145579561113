import { TextField, styled } from '@mui/material';
import { Modal } from '../../../../../../../../components/Modal/Modal';
import { FormField } from '../../../../../../../../components/FormField/FormField';
import { ChipsSelectorV2 } from '../../../../../../../../components/ButtonSelector/ChipsSelectorV2';
import {
  KEY_TASK_CONDITION_OPERATOR,
  KEY_TASK_GOAL_TYPE,
  KeyTask,
} from '../../../../../../../../types';
import { KeyTaskPayload } from '../../../../../../../../queries/useKeyTasks';
import { useFormik } from 'formik';
import { KeyTaskKPISchema as validationSchema } from './KeyTaskSchema';
import { useCallback, useMemo } from 'react';
import { KpiGoalValue } from './KpiGoalValue/KpiGoalValue';
import { ChecklistGoalValue } from './ChecklistGoalValue/ChecklistGoalValue';

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const goalTypeOptions = [
  { id: KEY_TASK_GOAL_TYPE.KPI, value: 'KPI' },
  { id: KEY_TASK_GOAL_TYPE.CHECKLIST, value: 'Checklist' },
  { id: KEY_TASK_GOAL_TYPE.OTHER, value: 'Other' },
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  keyTask?: KeyTask | null;
  onSubmit: (payload: Partial<KeyTaskPayload>) => void;
  title: string;
  confirmBtnText: string;
}

export const AddEditKeyTaskModal = ({
  isOpen,
  onClose,
  title,
  confirmBtnText,
  onSubmit,
  keyTask,
}: Props) => {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    dirty,
    handleBlur,
    handleChange,
  } = useFormik<Partial<KeyTaskPayload>>({
    initialValues: {
      goalType: keyTask?.goalType || KEY_TASK_GOAL_TYPE.KPI,
      conditionValue: keyTask?.conditionValue || '',
      conditionOperator: keyTask?.conditionOperator || KEY_TASK_CONDITION_OPERATOR.EQUAL,
      checklistItem: keyTask?.checklistItem?.id || null,
      kpi: keyTask?.kpi?.id || null,
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      onSubmit(values);
      resetForm();
      onClose();
    },
  });

  const selectedGoalType = useMemo(
    () => goalTypeOptions.find((o) => o.id === values.goalType),
    [values.goalType]
  );

  const onChangeGoalType = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('goalType', '');
        return;
      }
      setFieldValue('goalType', option?.id);
    },
    [setFieldValue]
  );

  const handleError = (field: keyof KeyTaskPayload) => (touched[field] ? errors[field] : '');

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      confirmBtnText={confirmBtnText}
      onClose={onClose}
      onConfirm={handleSubmit}
      isConfirmDisabled={!isValid || !dirty}
      contentContainerStyle={{ height: '337px' }}
    >
      <FormWrapper>
        <FormField label='Goal Type' error={''} required>
          <ChipsSelectorV2
            options={goalTypeOptions}
            selectedItem={selectedGoalType || goalTypeOptions[0]}
            onSelect={onChangeGoalType}
          />
        </FormField>
        {values.goalType === KEY_TASK_GOAL_TYPE.KPI && (
          <FormField
            label='Goal Value'
            error={
              handleError('conditionValue') ||
              handleError('conditionOperator') ||
              handleError('kpi')
            }
            required
          >
            <KpiGoalValue
              kpi={values.kpi}
              conditionValue={values.conditionValue}
              conditionOperator={values.conditionOperator}
              setFieldValue={setFieldValue}
            />
          </FormField>
        )}
        {values.goalType === KEY_TASK_GOAL_TYPE.CHECKLIST && (
          <ChecklistGoalValue
            checklistItem={values.checklistItem}
            setFieldValue={setFieldValue}
            conditionValue={values?.conditionValue || ''}
          />
        )}
        {values.goalType === KEY_TASK_GOAL_TYPE.OTHER && (
          <FormField label='Goal' error={handleError('conditionValue')} required>
            <TextField
              name='conditionValue'
              value={values.conditionValue}
              onChange={handleChange}
              onBlur={handleBlur}
              variant='outlined'
              placeholder='What do you want to achieve?'
            />
          </FormField>
        )}
      </FormWrapper>
    </Modal>
  );
};
