import { useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { FC } from 'react';

import { SingleQuestion } from './SingleQuestion/SingleQuestion';
import { MultiQuestion } from './MultiQuestion/MultiQuestion';
import { TableQuestion } from './TableQuestion/TableQuestion';
import { Typography } from '../../../../../../components/Typography/Typography';
import { QUESTION_TYPE, QuestionOption, SurveyQuestion } from '../../../../../../types';
import { responsesState } from '../../../../../../state/UIState';
import { YesNoInput } from '../../../../../../components/YesNoInput/YesNoInput';

interface Props {
  question: SurveyQuestion;
  isCoreKpi?: boolean;
}

export const QuestionResponse: FC<Props> = ({ question, isCoreKpi }) => {
  const [responses] = useAtom(responsesState);
  const { colors } = useTheme();

  if (!responses[question.id])
    return (
      <Typography variant='subtitle1' color={colors.primary[70]}>
        {(responses[question.id] as string | null) ??
          'No response received. The response automatically evaluated as zero-score points.'}
      </Typography>
    );

  switch (question.type) {
    case QUESTION_TYPE.TEXT:
      return (
        <Typography variant='subtitle1' color={colors.primary[70]}>
          {(responses[question.id] as string | null) ??
            'No response received. The response automatically evaluated as zero-score points.'}
        </Typography>
      );
    case QUESTION_TYPE.SINGLE:
      return <SingleQuestion question={question} responses={responses} />;
    case QUESTION_TYPE.MULTI:
      return <MultiQuestion question={question} responses={responses} />;
    case QUESTION_TYPE.TABLE:
      return <TableQuestion question={question} isCoreKpi={isCoreKpi} />;
    case QUESTION_TYPE.YES_NO:
      return (
        <YesNoInput
          question={question}
          response={responses[question.id] as QuestionOption | null}
        />
      );
    default:
      return null;
  }
};
