import { FC, useMemo } from 'react';
import { SurveyQuestion } from '../../../../../types';
import { useAtom } from 'jotai';
import { activeSurveyState, evaluationState } from '../../../../../state/UIState';
import { EvaluationOption } from './EvaluationOption';
import { ReviewReportTabWrapper } from '../../../../../components/ReviewReportTabWrapper';

interface Props {
  question: SurveyQuestion;
}

export const EvaluationBox: FC<Props> = ({ question }) => {
  const [activeSurvey] = useAtom(activeSurveyState);
  const [evaluation] = useAtom(evaluationState);

  const evaluationRules = useMemo(() => {
    if (question.includeEvaluation) return activeSurvey?.evaluationRules;
    return null;
  }, [activeSurvey?.evaluationRules, question.includeEvaluation]);

  const isEvaluated = useMemo(() => {
    if (!evaluation) return false;
    return (
      evaluationRules?.some((rule) => evaluation[question.id]?.weight === rule.weight) || false
    );
  }, [evaluation, evaluationRules, question.id]);

  return (
    <ReviewReportTabWrapper>
      {evaluationRules?.map((rule) => {
        return (
          <EvaluationOption
            isEvaluated={isEvaluated}
            key={rule.weight}
            rule={rule}
            question={question}
          />
        );
      })}
    </ReviewReportTabWrapper>
  );
};
