import { styled, useTheme } from '@mui/material';
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { RequestStatusCell } from '../../../components/Table/CellRenderers/RequestStatusCell';
import { Table } from '../../../components/Table/Table';
import { Typography } from '../../../components/Typography/Typography';
import {
  EVALUATION_SCALE,
  EvaluationRule,
  Report,
  REPORT_STATUS,
  REVIEW_TYPE,
} from '../../../types';
import { formatDate } from '../../../utils/formatters';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { flattenTree } from '../../../utils/treeUtilities';
import { QuestionRow } from '../../SingleAssessment/components/QuestionsTable';
import { ExportButton } from '../../SingleAssessment/components/ExportButton';
import { FlagsIndicator } from '../../../components/FlagsIndicator/FlagsIndicator';
import { getNumberOfFlaggedAnswers } from '../../../utils/getNumberOfFlaggedAnswers';
import { getAvgEvaluationScore } from '../../../utils/getAvgEvaluationScore';
import { EvaluationScorePreview } from '../../../components/EvaluationScorePreview/EvaluationScorePreview';

interface Props {
  reports: Report[];
}

const Wrapper = styled('div')`
  padding: 20px 40px;
`;

const SectionHeader = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`;
const ExportButtonWrapper = styled('div')`
  text-align: right;
`;

interface AssessmentsTableData {
  id: number;
  hasOverride: boolean;
  surveyName: string;
  status: REPORT_STATUS;
  dateCreated: string;
  exportData: QuestionRow[];
  flags: number;
  avgEvaluationScoreRule?: EvaluationRule | null;
  evaluationScale?: EVALUATION_SCALE;
  hasEvaluation?: boolean;
}

const columnHelper = createColumnHelper<AssessmentsTableData>();

export const AssessmentsTable: FC<Props> = ({ reports }) => {
  const { colors } = useTheme();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [data, setData] = useState<AssessmentsTableData[]>(() => []);
  const navigate = useNavigate();

  useEffect(() => {
    const assessmentsTableData: AssessmentsTableData[] = reports.map((report) => {
      const sections = flattenTree(report.survey.sections);
      const questions = sections.map((section) => section.questions).flat();
      const exportData: QuestionRow[] = questions.map((question) => ({
        id: question.id,
        question: question.name,
        answer: report.response ? report.response[question.id] : null,
        overrideAnswer: report?.review?.[question?.id]?.overrideResponse
          ? report.review[question.id].overrideResponse
          : null,
        overriddenBy:
          report?.review?.[question?.id]?.type === REVIEW_TYPE.OVERRIDE
            ? report?.review?.[question?.id]?.reviewedBy?.fullName
            : '',
        type: question.type,
        evaluationScore: report?.evaluation?.[question?.id]?.weight
          ? report.evaluation[question.id].weight
          : null,
      }));

      const flagsNum = getNumberOfFlaggedAnswers(report);
      const avgEvalScore = getAvgEvaluationScore(report);
      const evaluationRules = report?.survey.evaluationRules;
      const avgRule = evaluationRules?.find((rule) => rule.weight === avgEvalScore);

      return {
        id: report.id,
        hasOverride:
          report?.review &&
          Boolean(
            Object.values(report?.review)?.some?.((review) => Boolean(review?.overrideResponse))
          ),
        surveyName: report.survey.name,
        status: report.status,
        dateCreated: report.created_at,
        exportData,
        flags: flagsNum || 0,
        avgEvaluationScoreRule: avgRule,
        evaluationScale: report.survey.evaluationScale,
        hasEvaluation:
          report?.evaluation &&
          Boolean(
            Object.values(report?.evaluation)?.some?.((evaluation) => Boolean(evaluation?.name))
          ),
      };
    });
    setData(assessmentsTableData);
  }, [reports]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('surveyName', {
        cell: (info) => (
          <Typography variant='body' color={colors.accent[50]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Survey
          </Typography>
        ),
        meta: {
          width: '10%',
        },
      }),
      columnHelper.accessor('status', {
        cell: (info) => <RequestStatusCell status={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Status
          </Typography>
        ),
        meta: {
          width: '10%',
        },
      }),
      columnHelper.accessor('avgEvaluationScoreRule', {
        cell: (info) =>
          info.getValue() ? (
            <EvaluationScorePreview
              color={colors[info.getValue()?.color || 'success'][40]}
              evaluationScale={info.row.original.evaluationScale}
              label={info.getValue()?.name || ''}
              score={info.getValue()?.weight}
            />
          ) : null,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Evaluation Score
          </Typography>
        ),
        meta: {
          width: '10%',
        },
      }),
      columnHelper.accessor('flags', {
        cell: (info) => <FlagsIndicator flagsNum={info.getValue()} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Flags
          </Typography>
        ),
        meta: {
          width: '10%',
        },
      }),
      columnHelper.accessor('dateCreated', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue() ? formatDate(info.getValue() as string) : null}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Date Created
          </Typography>
        ),
        meta: {
          width: '20%',
          minWidth: '100px',
        },
        sortingFn: 'datetime',
      }),
      columnHelper.accessor('exportData', {
        cell: (info) => {
          return (
            <ExportButtonWrapper onClick={(e) => e.stopPropagation()}>
              <ExportButton
                data={info.getValue() as QuestionRow[]}
                surveyName={info.row.original.surveyName}
                showOverrideColumn={info.row.original.hasOverride}
                hasEvaluation={info.row.original.hasEvaluation}
              />
            </ExportButtonWrapper>
          );
        },
        header: () => null,
        meta: {
          width: '10%',
        },
      }),
    ],
    [colors]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const onRowClick = useCallback(
    (row: Row<AssessmentsTableData>) => {
      const reportId = row.original.id;
      const status = row.original.status;
      if (status === REPORT_STATUS.NOT_STARTED || status === REPORT_STATUS.IN_PROGRESS) return;
      navigate(`/${ROUTES.ASSESSMENTS}/${reportId}`);
    },
    [navigate]
  );

  return (
    <Wrapper>
      <SectionHeader>
        <Typography variant='h4' color={colors.primary[90]}>
          Assessments
        </Typography>
      </SectionHeader>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a request'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('surveyName')?.setFilterValue('')}
          onChange={(e) => table.getColumn('surveyName')?.setFilterValue(e.target.value)}
          value={table.getColumn('surveyName')?.getFilterValue() ?? ''}
        />
      </FiltersWrapper>
      <Table table={table} height='auto' onRowClick={onRowClick} disableLazyLoading />
    </Wrapper>
  );
};
