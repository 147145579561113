import { styled } from '@mui/material';
import { PortfolioHeader } from './PortfolioHeader';
import { ReactNode } from 'react';

const Wrapper = styled('div')``;

const ContentWrapper = styled('div')`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

interface Props {
  title: string;
  children: ReactNode;
}

export const PortfolioLayout = ({ title, children }: Props) => {
  return (
    <Wrapper>
      <PortfolioHeader title={title} />
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};
