import { FC, useCallback, useMemo, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { Table } from '../../../components/Table/Table';

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
  ColumnFiltersState,
  Row,
} from '@tanstack/react-table';
import { styled, useTheme } from '@mui/material';
import { REPORT_STATUS, Report, Request, SURVEY_FREQUENCY, SelectItem } from '../../../types';
import { CompanyCell } from '../../../components/Table/CellRenderers/CompanyCell';
import { RequestStatusCell } from '../../../components/Table/CellRenderers/RequestStatusCell';
import { RequestProgressCell } from '../../../components/Table/CellRenderers/RequestProgressCell';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { formatDate, getLastDateOfCurrentPeriod } from '../../../utils/formatters';
import { getNumberOfFlaggedAnswers } from '../../../utils/getNumberOfFlaggedAnswers';
import { FlagsIndicator } from '../../../components/FlagsIndicator/FlagsIndicator';
import { SendReportAgainCTA } from '../../SIngleCompany/components/SendReportAgainCTA';
import { getPeriodByFrequencyAndReportDate } from '../../../utils/companyUtils';

interface Props {
  requests: Request[];
  selectedDate: Date | null;
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 54px;
`;
const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

const SendAgainContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

interface ExtendedReport extends Report {
  flags: number;
  reportTitle: string;
}

const columnHelper = createColumnHelper<Partial<ExtendedReport>>();

export const RequestsTable: FC<Props> = ({ requests, selectedDate }) => {
  const { colors } = useTheme();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedStatus, setSelectedStatus] = useState<SelectItem | null>(null);
  const navigate = useNavigate();

  const frequency = useMemo(() => requests[0].frequency, [requests]);

  const reports = useMemo(() => {
    const result = requests
      .map((request) => request.reports)
      .flat()
      .map((report) => {
        const reportSurvey = requests?.find((request) =>
          request.reports.find((r) => r.id === report.id)
        )?.survey;
        const flagsNum = getNumberOfFlaggedAnswers({ ...report, survey: reportSurvey } as Report);
        return {
          ...report,
          flags: flagsNum || 0,
          reportTitle: `${report?.company?.name} Response`,
        };
      });
    if (!selectedDate) return result;
    const selectedMonth = selectedDate?.getMonth() + 1;
    const selectedYear = selectedDate?.getFullYear();
    return result.filter((report) => {
      if (requests[0].frequency === SURVEY_FREQUENCY.ANNUALLY) return report.year === selectedYear;
      return report.month === selectedMonth && report.year === selectedYear;
    });
  }, [requests, selectedDate]);

  const onRowClick = useCallback(
    (row: Row<ExtendedReport>) => {
      const reportId = row.original.id;
      const status = row.original.status;
      if (status === REPORT_STATUS.NOT_STARTED || status === REPORT_STATUS.IN_PROGRESS) return;

      if (status === REPORT_STATUS.APPROVED) {
        navigate(`/${ROUTES.ASSESSMENTS}/${reportId}`);
        return;
      }

      navigate(`/${ROUTES.REPORTS}/${reportId}`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('reportTitle', {
        cell: (info) => (
          <Typography
            variant='subtitle2'
            color={
              [REPORT_STATUS.APPROVED, REPORT_STATUS.IN_REVIEW, REPORT_STATUS.REJECTED].includes(
                info.row.original.status as REPORT_STATUS
              )
                ? colors.accent[50]
                : colors.primary[90]
            }
          >
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Report
          </Typography>
        ),
        meta: {
          width: '20%',
          minWidth: '130px',
        },
        sortingFn: (rowA: any, rowB: any, columnId: any): number =>
          rowA.getValue(columnId).name > rowB.getValue(columnId).name ? 1 : -1,
        sortDescFirst: false,
        filterFn: (row: any, columnId, term) =>
          (row.getValue(columnId).name as string).toLowerCase().includes(term.toLowerCase()),
      }),
      columnHelper.accessor('company', {
        cell: (info) => (
          <CompanyCell
            name={info.getValue()?.name as string}
            logo={info.getValue()?.logoUrl as string}
            labelColor={colors.accent[50]}
            onClick={(e) => {
              e.stopPropagation();
              const prevRouteName = location.pathname?.split('/')?.[1];
              navigate(`/${ROUTES.COMPANIES}/${info.getValue()?.id}/${ROUTES.COMPANY_OVERVIEW}`, {
                state: { prevRoute: location.pathname, prevRouteName: prevRouteName || '' },
              });
            }}
          />
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Company
          </Typography>
        ),
        meta: {
          width: '20%',
          minWidth: '130px',
        },
        sortingFn: (rowA: any, rowB: any, columnId: any): number =>
          rowA.getValue(columnId).name > rowB.getValue(columnId).name ? 1 : -1,
        sortDescFirst: false,
        filterFn: (row: any, columnId, term) =>
          (row.getValue(columnId).name as string).toLowerCase().includes(term.toLowerCase()),
      }),
      columnHelper.accessor('status', {
        cell: (info) => <RequestStatusCell status={info.getValue() as REPORT_STATUS} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Status
          </Typography>
        ),
        meta: {
          width: '15%',
        },
        filterFn: 'equalsString',
        enableColumnFilter: true,
      }),
      columnHelper.accessor('flags', {
        cell: (info) => <FlagsIndicator flagsNum={info.getValue() || 0} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Flags
          </Typography>
        ),
        meta: {
          width: '10%',
        },
      }),
      columnHelper.accessor('month', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {getPeriodByFrequencyAndReportDate(
              frequency,
              info.getValue() as number,
              info.row.original.year as number
            )}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Reporting Period
          </Typography>
        ),
        meta: {
          width: '15%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('created_at', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {formatDate(getLastDateOfCurrentPeriod(frequency, info.getValue() as string) ?? '')}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Due
          </Typography>
        ),
        meta: {
          width: '15%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('progress', {
        cell: (info) => <RequestProgressCell progress={(info.getValue() as number) ?? 0} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Reporting Progress
          </Typography>
        ),
        size: 180,
        meta: {
          width: '30%',
        },
        sortDescFirst: false,
      }),
      columnHelper.display({
        id: 'sendAgain',
        cell: (info) => {
          return (
            (info.row.original.status === REPORT_STATUS.IN_PROGRESS ||
              info.row.original.status === REPORT_STATUS.NOT_STARTED) && (
              <SendAgainContainer>
                <SendReportAgainCTA reportId={info.row.original.id as number} />
              </SendAgainContainer>
            )
          );
        },
        header: () => <div />,
        size: 180,
        meta: {
          width: '10%',
        },
      }),
    ],
    [colors.accent, colors.primary, frequency, navigate]
  );

  const table = useReactTable({
    data: reports ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const sortedUniqueStatuses = useMemo(
    () =>
      Array.from(table.getColumn('status')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((value, id) => ({ id, value })),
    [table]
  );
  const onChangeStatusFilter = useCallback(
    (status: SelectItem | null) => {
      setSelectedStatus(status);
      table.getColumn('status')?.setFilterValue(status?.value ?? '');
    },
    [table]
  );

  return (
    <Wrapper>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a company'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('company')?.setFilterValue('')}
          onChange={(e) => table.getColumn('company')?.setFilterValue(e.target.value)}
          value={table.getColumn('company')?.getFilterValue() ?? ''}
        />
        <SingleSelect
          style={{ width: '200px' }}
          options={sortedUniqueStatuses}
          value={selectedStatus}
          onChange={(_, status) => onChangeStatusFilter(status)}
          disablePortal
          fieldPlaceholder='Filter by Status'
        />
      </FiltersWrapper>
      <Table table={table} onRowClick={onRowClick} height='570px' />
    </Wrapper>
  );
};
