import ContentLoader from 'react-content-loader';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  padding: 24px 40px;
`;

const ThreeColumnLoader = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
`;

export function PortfolioPageSkeletonLoader(): ReactElement {
  return (
    <SkeletonWrapper>
      <ContentLoader height={85} width={'100%'}>
        <rect width='100%' height='85' fill='#EEEFF1' />
      </ContentLoader>

      <ContentLoader height={62} width={'100%'}>
        <rect width='250px' height='34' y='28' rx='4' fill='#EEEFF1' />
      </ContentLoader>
      <ThreeColumnLoader>
        <ContentLoader height={357} width={'100%'}>
          <rect width='100%' height='333' y='24' rx='4' fill='#EEEFF1' />
        </ContentLoader>
        <ContentLoader height={357} width={'100%'}>
          <rect width='100%' height='333' y='24' rx='4' fill='#EEEFF1' />
        </ContentLoader>
        <ContentLoader height={357} width={'100%'}>
          <rect width='100%' height='333' y='24' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </ThreeColumnLoader>

      <ContentLoader height={62} width={'100%'}>
        <rect width='250px' height='34' y='28' rx='4' fill='#EEEFF1' />
      </ContentLoader>
      <ContentLoader height={250} width={'100%'}>
        <rect width='345' height='34' y='20' rx='4' fill='#EEEFF1' />
        <rect width='240px' height='34' x='369' y='20' rx='4' fill='#EEEFF1' />
        <rect width='240px' height='34' x='621' y='20' rx='4' fill='#EEEFF1' />
        <rect width='100%' height='24' y='70' rx='4' fill='#EEEFF1' />

        <rect y='102' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='143' width='100%' height='38' rx='4' fill='#EEEFF1' />
        <rect y='184' width='100%' height='38' rx='4' fill='#EEEFF1' />
      </ContentLoader>
    </SkeletonWrapper>
  );
}
