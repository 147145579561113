import { useTheme } from '@mui/material';
import { FC } from 'react';

import { Modal } from '../Modal/Modal';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  note?: string;
  infoContent?: React.ReactNode;
  title: string;
  isOpen?: boolean;
  isInfoContentWarning?: boolean;
}

export const ConfirmDeleteModal: FC<Props> = ({
  onClose,
  cancelLabel,
  confirmLabel,
  onConfirm,
  note,
  title,
  isOpen,
  isInfoContentWarning,
  infoContent,
}) => {
  const { colors } = useTheme();

  return (
    <Modal
      title={title}
      isOpen={isOpen || false}
      onClose={onClose}
      onConfirm={onConfirm}
      infoText={note}
      confirmBtnText={confirmLabel || 'Confirm'}
      cancelBtnText={cancelLabel || 'Cancel'}
      confirmBtnColor={colors.error[40]}
      contentContainerStyle={{ paddingBottom: '16px' }}
      isInfoContentWarning={isInfoContentWarning}
      infoContent={infoContent}
    />
  );
};
