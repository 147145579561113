import { styled } from '@mui/material';
import { IsActiveSwitch } from '../../../../IsActiveSwitch';
import { ExclusionCondition } from '../../../../../../../types';
import { ActionMenu } from './ActionMenu';
import { useCallback } from 'react';
import { useUpdateExclusionCondition } from '../../../../../../../queries/useExclusionCondition';
import { useAtom } from 'jotai';
import { activeExclusionCategoryState } from '../../../../../../../state/UIState';
import { useAppConfig, useUpdateAppConfig } from '../../../../../../../queries/useAppConfig';

const ControlsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;

interface Props {
  condition: ExclusionCondition;
}

export const ConditionControls = ({ condition }: Props) => {
  const { mutate: editCondition } = useUpdateExclusionCondition();
  const [activeCategory] = useAtom(activeExclusionCategoryState);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const onToggleActive = useCallback(() => {
    if (!activeCategory) return;

    editCondition(
      { id: condition.id, isActive: !condition.isActive, category: activeCategory.id },
      {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
          });
        },
      }
    );
  }, [
    activeCategory,
    appConfigData?.data,
    condition.id,
    condition.isActive,
    editCondition,
    updateAppConfig,
  ]);
  
  return (
    <ControlsWrapper>
      <IsActiveSwitch isActive={condition.isActive} onToggleActive={onToggleActive} />
      <ActionMenu conditionId={condition.id} />
    </ControlsWrapper>
  );
};
