import { styled } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { LoadingBar } from '../../components/LoadingBar/LoadingBar';
import { SurveyEditorSkeletonLoader } from '../../components/SkeletonLoader/SurveyEditor.SkeletonLoader';
import { SERVER_ERRORS } from '../../constants/serverErrors';
import { fetchReportByToken } from '../../queries/useReports';
import {
  activeQuestionIdState,
  activeReportState,
  activeSectionIdState,
  activeSurveyIdState,
  activeSurveyState,
  areShowedAllQuestionsState,
  evaluationState,
  isNameModalOpenState,
  responsesState,
  sectionsState,
  survayNameState,
} from '../../state/UIState';
import { InvalidToken } from './components/InvalidToken';
import { ProgressHeader } from './components/ProgressHeader';
import { Http } from '../../services/Http';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const LoadingBarWrapper = styled('div')`
  width: 100%;
  position: absolute;
  top: 0;
`;

const Content = styled('div')``;

export const Questionnaire = () => {
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [, setSurveyName] = useAtom(survayNameState);
  const [, setOpen] = useAtom(isNameModalOpenState);
  const [, setActiveSurveyId] = useAtom(activeSurveyIdState);
  const [, setActiveSurvey] = useAtom(activeSurveyState);
  const [, setActiveReport] = useAtom(activeReportState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [, setSections] = useAtom(sectionsState);
  const [, setResponses] = useAtom(responsesState);
  const [, setEvaluation] = useAtom(evaluationState);
  const setAreShowedAllQuestions = useSetAtom(areShowedAllQuestionsState);
  const [queryParams] = useSearchParams();

  useEffect(() => {
    if (queryParams.get('token')) {
      Http.axios.setHeader('x-amz-report-token', `${queryParams.get('token')}`);
      fetchReportByToken(queryParams.get('token'))
        .then(async (report) => {
          if (!report) return;
          setActiveReport(report);
          const { survey } = report;
          setActiveSurvey(survey);
          setSurveyName(survey?.name ?? '');
          setSections(survey?.sections ?? []);
          setActiveSurveyId(survey?.id ?? null);
          setActiveSectionId(survey?.sections[0].id ?? null);
          setActiveQuestionId(survey?.sections[0].questions[0]?.id ?? null);
          setIsLoading(false);
          setEvaluation(report.evaluation);
          if (report?.response) setResponses(report.response);
        })
        .catch((e) => {
          if (e.response.data.message === SERVER_ERRORS.INVALID_TOKEN) {
            setIsLoading(false);
            setIsInvalidToken(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setActiveSurveyId(null);
      setActiveSectionId(null);
      setActiveQuestionId(null);
      setSections([]);
      setResponses({});
      setAreShowedAllQuestions(true);
      setSurveyName('');
      setOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <SurveyEditorSkeletonLoader />;
  if (isInvalidToken) return <InvalidToken />;

  return (
    <Wrapper>
      <LoadingBarWrapper>
        <LoadingBar />
      </LoadingBarWrapper>
      <ProgressHeader isInternal={false} />
      <Content>
        <Outlet />
      </Content>
    </Wrapper>
  );
};
