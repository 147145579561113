import { useQuery } from 'react-query';
import { Http } from '../services/Http';
import { Sector } from '../types';

const SECTORS = 'sectors';

async function fetchSectors(): Promise<Sector[]> {
  const { data } = await Http.axios.get<Sector[]>(`/sector`);
  return data;
}

export function useSectors() {
  return useQuery(SECTORS, () => fetchSectors(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}
