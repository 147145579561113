import { styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { StepIdicator } from '../StepIndicator/StepIndicator';
import { Step } from '../../types';

const Wrapper = styled('div')`
  background-color: ${({ theme }) => theme.colors.primary[0]};
  width: 340px;
  border-right: 1px solid ${({ theme }) => theme.colors.primary[20]};
  padding: 24px;
`;
const StepIndicatorsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;
`;

interface Props {
  text: string;
  progress: number;
  steps: Step[];
  activeStep: number;
  onClickStepNavigate: (step: number) => void;
}

export const StepperLayoutSideProgress = ({
  text,
  progress,
  steps,
  activeStep,
  onClickStepNavigate,
}: Props) => {
  const { colors } = useTheme();

  const onStepClick = (step: number, isClickable: boolean) => {
    if (step === activeStep || !isClickable) return;
    onClickStepNavigate(step);
  };

  return (
    <Wrapper>
      <Typography variant='body' color={colors.primary[70]} style={{ marginBottom: '8px' }}>
        {text}
      </Typography>
      <ProgressBar progress={progress} color={colors.accent[40]} />
      <StepIndicatorsWrapper>
        {steps.map(({ step, text, isClickable }) => (
          <StepIdicator
            key={step}
            text={text}
            step={step}
            isActive={activeStep === step}
            isCompleted={activeStep > step}
            style={{ cursor: isClickable && activeStep !== step ? 'pointer' : 'default' }}
            onClick={() => onStepClick(step, isClickable)}
          />
        ))}
      </StepIndicatorsWrapper>
    </Wrapper>
  );
};
