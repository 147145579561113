import { styled } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';

interface Props {
  instructions: string;
}
const InstructionsWrapper = styled('div')`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  border: 1px solid ${({ theme }) => theme.colors.primary[20]};
  border-radius: 2px;
  display: flex;
  gap: 8px;
`;
const IconWrapper = styled('div')`
  margin-top: 1px;
`;
const InstructionsTextWrapper = styled('div')`
  color: ${({ theme }) => theme.colors.primary[90]};
  ol,
  ul {
    padding: 8px 0 4px 16px;
  }
  p,
  ol,
  ul {
    font-family: ${({ theme }) => theme.text.font.fontFamilyRegular};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  h1 {
    font-family: ${({ theme }) => theme.text.font.fontFamilyMedium};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  h2 {
    font-family: ${({ theme }) => theme.text.font.fontFamilyMedium};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  a {
    color: ${({ theme }) => theme.colors.accent[50]};
    text-decoration: none;
  }
  img {
    max-width: 100%;
    object-fit: contain;
  }
`;

export const QuestionInstructions: FC<Props> = ({ instructions }) => {
  return (
    <InstructionsWrapper>
      <IconWrapper>
        <InfoIcon />
      </IconWrapper>
      <InstructionsTextWrapper dangerouslySetInnerHTML={{ __html: instructions }} />
    </InstructionsWrapper>
  );
};
