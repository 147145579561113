import { IconButton, styled } from '@mui/material';
import { ReactComponent as DuplicateIcon } from '../../../../../../../../../../assets/icons/duplicate.svg';
import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { activeQuestionIdState, questionState } from '../../../../../../../../../../state/UIState';
import { SurveyQuestion, QuestionOption } from '../../../../../../../../../../types';
import { generateId } from '../../../../../../../../../../utils/generateId';
import { ReactComponent as CloseIcon } from '../../../../../../../../../../assets/icons/close-gray.svg';

const ActionButton = styled(IconButton)`
  padding: 4px;
`;

interface Props {
  option: QuestionOption;
  subQuestion: SurveyQuestion;
}

export const SubQuestionActionButtons = ({ option, subQuestion }: Props) => {
  const [activeQuestionId] = useAtom(activeQuestionIdState);
  const [question, setQuestion] = useAtom(questionState(activeQuestionId));

  const deleteSubQuestion = useCallback(() => {
    if (!activeQuestionId || !question) return;

    const updatedQuestion = {
      ...question,
      options: question?.options.map((o) => {
        if (o.id === option.id) {
          return {
            ...o,
            subQuestions: o?.subQuestions?.filter((s) => {
              return s.id !== subQuestion?.id;
            }),
          };
        }
        return o;
      }),
    };
    setQuestion(updatedQuestion);
  }, [activeQuestionId, question, setQuestion, option.id, subQuestion?.id]);

  const duplicateSubQuestion = useCallback(() => {
    if (!activeQuestionId || !question) return;
    const subQuestionCopy = {
      ...subQuestion,
      id: generateId(),
      name: `${subQuestion.name} copy`,
      options: subQuestion.options.map((o) => ({ ...o, id: generateId() })),
    };

    const updatedQuestion = {
      ...question,
      options: question?.options.map((o) => {
        if (o.id === option.id) {
          return {
            ...o,
            subQuestions: [...(o?.subQuestions || []), subQuestionCopy],
          };
        }
        return o;
      }),
    };
    setQuestion(updatedQuestion);
  }, [activeQuestionId, question, subQuestion, setQuestion, option.id]);

  return (
    <>
      <ActionButton onClick={duplicateSubQuestion}>
        <DuplicateIcon />
      </ActionButton>
      <ActionButton onClick={deleteSubQuestion}>
        <CloseIcon />
      </ActionButton>
    </>
  );
};
