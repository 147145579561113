import { RISK_LEVEL } from '../../../types';
import { ReactComponent as HighGreen } from '../../../assets/icons/high-green.svg';
import { ReactComponent as Medium } from '../../../assets/icons/medium.svg';
import { ReactComponent as LowRed } from '../../../assets/icons/low-red.svg';

interface Props {
  esgOpportunitiesLevel?: RISK_LEVEL;
}

export const EsgOpportunitiesIcon = ({ esgOpportunitiesLevel }: Props) => {
  if (esgOpportunitiesLevel === RISK_LEVEL.HIGH) return <HighGreen />;
  if (esgOpportunitiesLevel === RISK_LEVEL.MEDIUM) return <Medium />;

  return <LowRed />;
};
