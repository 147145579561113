import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { impersonateRoleState, isUserLoadingState, userState } from '../state/UIState';
import { fetchUserByEmail, useCreateUser } from '../queries/useUsers';
import { useOktaAuth } from '@okta/okta-react';
import { USER_ROLE } from '../types';

const useInitUser = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const setIsLoadingUser = useSetAtom(isUserLoadingState);
  const setUserState = useSetAtom(userState);
  const setImpersonateRole = useSetAtom(impersonateRoleState);
  const createUser = useCreateUser();

  const login = async () => oktaAuth.signInWithRedirect();

  useEffect(() => {
    if (!authState?.idToken?.claims?.email) return;

    const initUser = async () => {
      const email = authState?.idToken?.claims?.email;
      const user = await fetchUserByEmail((email as string) ?? '');
      if (user) {
        setUserState(user);
        const impersonateRole = localStorage.getItem('impersonate');
        if (impersonateRole && user.role.name === USER_ROLE.ADMIN) {
          setImpersonateRole(impersonateRole as USER_ROLE);
        }
        setIsLoadingUser(false);
      }
      if (!user) {
        const newUser = await createUser.mutateAsync({
          fullName: authState?.idToken?.claims?.name ?? '',
          email: authState?.idToken?.claims?.email ?? '',
        });
        setUserState(newUser);
        setIsLoadingUser(false);
      }
    };

    initUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.idToken?.claims?.email]);

  if (!authState) {
    return null;
  }

  if (!authState.isAuthenticated) {
    login();
  }
};

export default useInitUser;
