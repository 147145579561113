import { useParams } from 'react-router';
import { useCompanyById } from '../../../../../queries/useCompanies';
import { useMemo } from 'react';
import { REPORT_STATUS, REVIEW_TYPE } from '../../../../../types';
import { useAnalystRatingStep } from './useAnalystRatingStep';
import { useAtomValue } from 'jotai';
import { userState } from '../../../../../state/UIState';
import { useHasAnalystRating } from './useHasAnalystRating';

export const useAnalystRatingStatus = () => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));

  const analystRatingStep = useAnalystRatingStep();
  const user = useAtomValue(userState);

  const hasAnalystRating = useHasAnalystRating();

  const isAnalystCompleted = useMemo(() => {
    if (!analystRatingStep) return;
    return company?.completedWorkflowSteps?.includes(analystRatingStep.id);
  }, [analystRatingStep, company?.completedWorkflowSteps]);

  const analystRatingStatus: REPORT_STATUS | null = useMemo(() => {
    if (isAnalystCompleted) return REPORT_STATUS.SUBMITTED;

    if (company?.analystRatingReview?.type === REVIEW_TYPE.REJECT) return REPORT_STATUS.REJECTED;

    if (company?.analystRatingApprovedBy?.includes(user?.id || 0)) return REPORT_STATUS.SUBMITTED;

    if (hasAnalystRating && analystRatingStep?.isApprovalRequired) return REPORT_STATUS.IN_REVIEW;

    return null;
  }, [
    analystRatingStep?.isApprovalRequired,
    company?.analystRatingApprovedBy,
    company?.analystRatingReview?.type,
    isAnalystCompleted,
    hasAnalystRating,
    user?.id,
  ]);

  return analystRatingStatus;
};
