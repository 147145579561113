/* istanbul ignore file */
import * as yup from 'yup';
import { atLeastOneMsg, requiredMsg } from '../../../utils/validationMessages';

export const RequestGroupSchema = yup.object().shape({
  name: yup.string().required(requiredMsg('Name')),
  frequency: yup.string().required(requiredMsg('Frequency')),
  survey: yup.number().required(requiredMsg('Survey')),
  companyIds: yup.array().of(yup.number()).min(1, atLeastOneMsg('company')),
});
