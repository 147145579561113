import { STEP_TYPE } from '../types';

export const DISTRIBUTION_OVER_TIME_YEARS_NUMBER = 7;
export const INVESTOR_EXCLUSIONS_SURVEY_ID = 612;
export const DUE_DILIGENCE_SURVEY_NAME = 'Investor Exclusions Due Diligence';
export const SASB_ASSESSMENT_NAME = 'Investment Specific Assessment';

export const KPI_MATRIX_TABLE_ID = 'kpi-matrix-table';

export const STEP_TYPE_LABELS = {
  [STEP_TYPE.ASSESSMENT]: 'Assessment',
  [STEP_TYPE.REQUEST]: 'Request',
  [STEP_TYPE.COMPANY_INFO]: 'Company Information',
  [STEP_TYPE.SCORE]: 'Score',
};
