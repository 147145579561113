import { FocusEvent, MouseEvent, useCallback, useEffect, useRef } from 'react';
import { styled } from '@mui/material';

const Textarea = styled('textarea')`
  width: 100%;
  border: none;
  resize: none;
  background-color: transparent;
  outline: 0px none transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-family: ${({ theme }) => theme.text.font.fontFamilyRegular};
  color: ${({ theme }) => theme.colors.primary[90]};
  border: none;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    font-family: ${({ theme }) => theme.text.font.fontFamilyRegular};
    color: ${({ theme }) => theme.colors.primary[70]};
  }
  &:focus {
    background-color: ${({ theme }) => theme.colors.accent[10]};
  }
`;

export const Wrapper = styled('div')``;

export type ReadWriteFieldProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  onValueChanged: (newValue: string) => void;
  onClick?: (event: MouseEvent<HTMLTextAreaElement>) => void;
};

export function EditableTextareaField(props: ReadWriteFieldProps) {
  const { onValueChanged, onClick, onChange, value, ...rest } = props;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const triggerChangeIfValueChanged = useCallback(
    (newValue: string) => {
      if (newValue !== props.defaultValue) {
        onValueChanged(newValue);
      }
    },
    [onValueChanged, props.defaultValue]
  );

  const adjustHeight = () => {
    if (!textAreaRef.current) return;
    textAreaRef.current.style.height = 'auto'; // Reset the height to auto
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set the height to the scroll height
  };

  useEffect(() => {
    adjustHeight(); // Adjust the height initially when the component mounts
  }, []);

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      adjustHeight();
      onChange?.(event);
    },
    [onChange]
  );

  const onBlur = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      triggerChangeIfValueChanged(event.target.value);
    },
    [triggerChangeIfValueChanged]
  );

  const onEnterPressed = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      (event.target as HTMLTextAreaElement).blur();
    }
  }, []);

  const onClickHandler = useCallback(
    (event: MouseEvent<HTMLTextAreaElement>) => {
      onClick?.(event);
      if (!(event.target as HTMLTextAreaElement)?.select) return;
      (event.target as HTMLTextAreaElement)?.focus();
      (event.target as HTMLTextAreaElement)?.select();
    },
    [onClick]
  );

  return (
    <Textarea
      ref={textAreaRef}
      onClick={onClickHandler}
      value={value}
      onChange={onChangeHandler}
      {...rest}
      className={'read-write-input'}
      onBlur={onBlur}
      onKeyPress={onEnterPressed}
      rows={1}
    />
  );
}
