import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../../../../components/Typography/Typography';
import { Exclusion } from '../../../../../../../../../types';
import { IsActiveSwitch } from '../../../../../../IsActiveSwitch';
import { ActionMenu } from './ActionMenu';
import { useCallback } from 'react';
import { useUpdateExclusion } from '../../../../../../../../../queries/useExclusions';
import { useAtom } from 'jotai';
import {
  activeConditionIdState,
  activeExclusionCategoryState,
} from '../../../../../../../../../state/UIState';
import { useAppConfig, useUpdateAppConfig } from '../../../../../../../../../queries/useAppConfig';

const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ControlsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: -5px;
`;

interface Props {
  exclusion: Exclusion;
  exclusionOrder: number;
}

export const ExclusionHeader = ({ exclusion, exclusionOrder }: Props) => {
  const { colors } = useTheme();

  const [activeConditionId] = useAtom(activeConditionIdState);
  const [activeCategory] = useAtom(activeExclusionCategoryState);

  const { mutate: editExclusion } = useUpdateExclusion(activeCategory?.id);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();

  const onToggleActive = useCallback(() => {
    if (!activeCategory || !activeConditionId) return;

    editExclusion(
      { id: exclusion.id, isActive: !exclusion.isActive, condition: activeConditionId },
      {
        onSuccess: async () => {
          if (!appConfigData?.data) {
            return;
          }
          await updateAppConfig.mutateAsync({
            data: { ...appConfigData?.data, isExclusionSurveyUpToDate: false },
          });
        },
      }
    );
  }, [
    activeCategory,
    activeConditionId,
    appConfigData?.data,
    editExclusion,
    exclusion.id,
    exclusion.isActive,
    updateAppConfig,
  ]);

  return (
    <HeaderWrapper>
      <Typography variant='h5' color={colors.primary[80]}>
        {`Exclusion ${exclusionOrder}`}
      </Typography>
      <ControlsWrapper>
        <IsActiveSwitch isActive={exclusion.isActive} onToggleActive={onToggleActive} />
        <ActionMenu exclusion={exclusion} />
      </ControlsWrapper>
    </HeaderWrapper>
  );
};
