import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import {
  esgOpportunitiesLevelOverrideState,
  esgRiskLevelOverrideState,
  selectedReviewOptionState,
} from '../../../../../state/UIState';
import { REVIEW_TYPE } from '../../../../../types';

export const useIsAnalystRatingReviewed = () => {
  const selectedReviewOption = useAtomValue(selectedReviewOptionState);
  const esgRiskLevelOverride = useAtomValue(esgRiskLevelOverrideState);
  const esgOpportunitiesLevelOverride = useAtomValue(esgOpportunitiesLevelOverrideState);

  return useMemo(() => {
    if (selectedReviewOption === REVIEW_TYPE.OVERRIDE) {
      return Boolean(esgRiskLevelOverride || esgOpportunitiesLevelOverride);
    }

    return Boolean(selectedReviewOption);
  }, [esgOpportunitiesLevelOverride, esgRiskLevelOverride, selectedReviewOption]);
};
