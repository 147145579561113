import { styled } from '@mui/material';

import { useParams } from 'react-router';

import { QuestionnaireStep } from '../../SurveyEditor/components/QuestionnaireStep/QuestionnaireStep';
import { CompletedStep } from './CompletedStep';
import { ACTION_BAR_HEIGHT, HEADER_HEIGHT } from '../../../constants/layoutSizes';

import { FooterButtons } from './FooterButtons';
import { InternalQuestionnaireFooter } from './InternalQuestionnaireFooter';

const Wrapper = styled('div')<{ withFooter?: boolean }>`
  height: ${({ withFooter }) =>
    withFooter
      ? `calc(100vh - ${HEADER_HEIGHT}px - ${ACTION_BAR_HEIGHT}px)`
      : `calc(100vh - ${HEADER_HEIGHT}px)`};
`;

const Footer = ({ isInternal = false }: { isInternal?: boolean }) => {
  if (isInternal) {
    return <InternalQuestionnaireFooter />;
  }

  return <FooterButtons />;
};

export const QuestionnaireContent = ({ isInternal = false }: { isInternal?: boolean }) => {
  const params = useParams();

  const activeStep = params.activeStep;

  return (
    <Wrapper withFooter={activeStep === 'progress'}>
      {activeStep === 'progress' && <QuestionnaireStep isInternal={isInternal} withFooter />}
      {activeStep === 'done' && <CompletedStep />}
      {activeStep === 'progress' && <Footer isInternal={isInternal} />}
    </Wrapper>
  );
};
