import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { DownloadCSVButton } from './DownloadCSVButton';
import { UploadArea } from './UploadArea';
import { InfoBox } from './InfoBox';

const Wrapper = styled('div')``;
const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InfoBoxWrapper = styled('div')`
  margin: 20px 0 40px 0;
`;

export const UploadStep = () => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <HeaderWrapper>
        <Typography variant='h4' color={colors.primary[90]}>
          Upload CSV File
        </Typography>
      </HeaderWrapper>
      <InfoBoxWrapper>
        <InfoBox />
      </InfoBoxWrapper>
      <DownloadCSVButton />
      <UploadArea />
    </Wrapper>
  );
};
