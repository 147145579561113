import { Button, styled, useTheme } from '@mui/material';
import { AddNoteCTA } from './AddNoteCTA';
import { CSSProperties, ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Textarea } from '../Textarea/Textarea';
import {
  FloatingFocusManager,
  OffsetOptions,
  Placement,
  Strategy,
  autoUpdate,
  flip,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

const AddNoteContainer = styled('div')`
  width: 420px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 12px;
  z-index: 1;
`;

const ButtonsWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 8px;
`;

interface Props {
  setShowAddNotesCTA?: Dispatch<SetStateAction<boolean>>;
  showAddNotesCTA?: boolean;
  onNotesChange: (notes: string) => void;
  setIsAddNotesShown?: (val: boolean) => void;
  popupWrapperStyles?: CSSProperties;
  popupStrategy?: Strategy;
  popupPlacement?: Placement;
  popupOffset?: OffsetOptions;
}

export const AddNote = ({
  setShowAddNotesCTA = () => '',
  showAddNotesCTA = true,
  onNotesChange,
  setIsAddNotesShown,
  popupStrategy = 'absolute',
  popupPlacement = 'bottom',
  popupWrapperStyles = {},
  popupOffset = {},
}: Props) => {
  const { colors } = useTheme();
  const [showAddNotes, setShowAddNotes] = useState(false);

  const [currentValue, setCurrentValue] = useState('');

  const onAddNotesCTAClick = (isOpen: boolean, e: Event) => {
    e.stopPropagation();
    setShowAddNotes(isOpen);
    setShowAddNotesCTA(!isOpen);
    setIsAddNotesShown?.(isOpen);
  };

  const { refs, floatingStyles, context } = useFloating({
    placement: popupPlacement,
    strategy: popupStrategy,
    open: showAddNotes,
    onOpenChange: onAddNotesCTAClick,
    whileElementsMounted: autoUpdate,
    middleware: [offset(popupOffset), flip({ fallbackAxisSideDirection: 'end' })],
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentValue(e.target.value);
  };

  const onSave = () => {
    onNotesChange(currentValue);
    setShowAddNotesCTA(false);
    setShowAddNotes(false);
    setCurrentValue('');
    setIsAddNotesShown?.(false);
  };

  const onCancel = () => {
    setShowAddNotesCTA(true);
    setShowAddNotes(false);
    setIsAddNotesShown?.(false);
  };

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {showAddNotesCTA && !showAddNotes && <AddNoteCTA />}
      </div>
      {showAddNotes && (
        <FloatingFocusManager context={context} modal={false}>
          <AddNoteContainer
            onClick={(e) => e.stopPropagation()}
            className='Popover'
            ref={refs.setFloating}
            style={{ ...popupWrapperStyles, ...floatingStyles }}
            {...getFloatingProps()}
          >
            <Textarea
              style={{ borderColor: colors.accent[50] }}
              onChange={handleChange}
              value={currentValue}
            />
            <ButtonsWrapper>
              <Button
                variant='outlined'
                onClick={(e) => {
                  e.stopPropagation();
                  onCancel();
                }}
              >
                Cancel
              </Button>

              <Button
                variant='contained'
                onClick={(e) => {
                  e.stopPropagation();
                  onSave();
                }}
                disabled={!currentValue}
              >
                Save
              </Button>
            </ButtonsWrapper>
          </AddNoteContainer>
        </FloatingFocusManager>
      )}
    </>
  );
};
