import { styled } from '@mui/material';
import { SideSection } from './SideSection';
import { ExclusionsContent } from './ExclusionsContent';
import { InvestorExclusionsSkeletonLoader } from '../../../components/SkeletonLoader/InvestorExclusions.SkeletonLoader';
import { useSortedExclusionCategories } from '../../../hooks/useSortedExclusionCategories';

const Wrapper = styled('div')`
  display: flex;
`;

export const MainContent = () => {
  const { isLoading } = useSortedExclusionCategories();

  if (isLoading) return <InvestorExclusionsSkeletonLoader />;

  return (
    <Wrapper>
      <SideSection />
      <ExclusionsContent />
    </Wrapper>
  );
};
