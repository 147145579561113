import { styled, useTheme } from '@mui/material';
import { Typography } from '../../components/Typography/Typography';
import { WorkflowTabs } from './WorkflowTabs';
import { Outlet } from 'react-router';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled('div')`
  padding: 16px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Workflows = () => {
  const { colors } = useTheme();

  return (
    <Container>
      <HeaderContainer>
        <Typography variant='h3' color={colors.primary[90]}>
          Workflows
        </Typography>
        <Typography variant='body' color={colors.primary[70]}>
          Set up onboarding workflows for new companies
        </Typography>
      </HeaderContainer>
      <WorkflowTabs />
      <Outlet />
    </Container>
  );
};
