import { esgWeights } from '../mockedData/mockedData';
import {
  CoreKpiRequestFrequency,
  DistributionOverTime,
  DistributionOverTimeWithAverage,
  KpisPeriodData,
  SURVEY_FREQUENCY,
} from '../types';

export const getEsgDistributionOverTimeWithAverage: (
  distributionOverTime: DistributionOverTime[]
) => DistributionOverTimeWithAverage[] = (distributionOverTime) => {
  return distributionOverTime.map((data) => {
    const esgScore =
      data.socialScore * esgWeights.socialScore +
      data.governanceScore * esgWeights.governanceScore +
      data.environmentalScore * esgWeights.environmentalScore;
    const combinedEsgScore =
      esgScore * esgWeights.esgScore +
      data.controversies * esgWeights.controversies +
      data.msciRatings * esgWeights.msciRatings +
      data.trucost * esgWeights.trucost +
      data.sustainalytics * esgWeights.sustainalytics;
    return {
      ...data,
      esgScore: Math.round(esgScore),
      combinedEsgScore: Math.round(combinedEsgScore),
    };
  });
};

export const generateTimestampByPeriod = (
  period: string,
  year: number,
  frequency: CoreKpiRequestFrequency
): number => {
  switch (frequency) {
    case SURVEY_FREQUENCY.MONTHLY:
      switch (period.split(' ')[0]) {
        case 'Jan':
          return new Date(`${year}-01-01`).getTime();
        case 'Feb':
          return new Date(`${year}-02-01`).getTime();
        case 'Mar':
          return new Date(`${year}-03-01`).getTime();
        case 'Apr':
          return new Date(`${year}-04-01`).getTime();
        case 'May':
          return new Date(`${year}-05-01`).getTime();
        case 'Jun':
          return new Date(`${year}-06-01`).getTime();
        case 'Jul':
          return new Date(`${year}-07-01`).getTime();
        case 'Aug':
          return new Date(`${year}-08-01`).getTime();
        case 'Sep':
          return new Date(`${year}-09-01`).getTime();
        case 'Oct':
          return new Date(`${year}-10-01`).getTime();
        case 'Nov':
          return new Date(`${year}-11-01`).getTime();
        case 'Dec':
          return new Date(`${year}-12-01`).getTime();
        default:
          return 0;
      }
    case SURVEY_FREQUENCY.QUARTERLY:
      switch (period.split(' ')[0]) {
        case 'Q1':
          return new Date(`${year}-01-01`).getTime();
        case 'Q2':
          return new Date(`${year}-04-01`).getTime();
        case 'Q3':
          return new Date(`${year}-07-01`).getTime();
        case 'Q4':
          return new Date(`${year}-10-01`).getTime();
        default:
          return 0;
      }
    case SURVEY_FREQUENCY.ANNUALLY:
      return new Date(`${year}-01-01`).getTime();
  }
};

export const generateMonthlyDataForGivenYear = (year: number): KpisPeriodData[] => {
  return [
    { period: `Jan ${year}`, timestamp: new Date(`${year}-01-01`).getTime() },
    { period: `Feb ${year}`, timestamp: new Date(`${year}-02-01`).getTime() },
    { period: `Mar ${year}`, timestamp: new Date(`${year}-03-01`).getTime() },
    { period: `Apr ${year}`, timestamp: new Date(`${year}-04-01`).getTime() },
    { period: `May ${year}`, timestamp: new Date(`${year}-05-01`).getTime() },
    { period: `Jun ${year}`, timestamp: new Date(`${year}-06-01`).getTime() },
    { period: `Jul ${year}`, timestamp: new Date(`${year}-07-01`).getTime() },
    { period: `Aug ${year}`, timestamp: new Date(`${year}-08-01`).getTime() },
    { period: `Sep ${year}`, timestamp: new Date(`${year}-09-01`).getTime() },
    { period: `Oct ${year}`, timestamp: new Date(`${year}-10-01`).getTime() },
    { period: `Nov ${year}`, timestamp: new Date(`${year}-11-01`).getTime() },
    { period: `Dec ${year}`, timestamp: new Date(`${year}-12-01`).getTime() },
  ];
};
export const generateQuaterlyDataForGivenYear = (year: number): KpisPeriodData[] => {
  return [
    { period: `Q1 ${year}`, timestamp: new Date(`${year}-01-01`).getTime() },
    { period: `Q2 ${year}`, timestamp: new Date(`${year}-04-01`).getTime() },
    { period: `Q3 ${year}`, timestamp: new Date(`${year}-07-01`).getTime() },
    { period: `Q4 ${year}`, timestamp: new Date(`${year}-10-01`).getTime() },
  ];
};

export const generateAnnualDataForGivenYear = (year: number): KpisPeriodData => {
  return { period: year.toString(), timestamp: new Date(`${year}-01-01`).getTime() };
};

export const getPeriodByFrequencyAndReportDate = (
  frequency: SURVEY_FREQUENCY,
  month: number,
  year: number,
  shortMonth?: boolean
) => {
  switch (frequency) {
    case SURVEY_FREQUENCY.ONE_TIME:
      return '';
    case SURVEY_FREQUENCY.MONTHLY:
      switch (month) {
        case 1:
          return `${shortMonth ? 'Dec' : 'December'} ${year - 1}`;
        case 2:
          return `${shortMonth ? 'Jan' : 'January'} ${year}`;
        case 3:
          return `${shortMonth ? 'Feb' : 'February'} ${year}`;
        case 4:
          return `${shortMonth ? 'Mar' : 'March'} ${year}`;
        case 5:
          return `${shortMonth ? 'Apr' : 'April'} ${year}`;
        case 6:
          return `${shortMonth ? 'May' : 'May'} ${year}`;
        case 7:
          return `${shortMonth ? 'Jun' : 'June'} ${year}`;
        case 8:
          return `${shortMonth ? 'Jul' : 'July'} ${year}`;
        case 9:
          return `${shortMonth ? 'Aug' : 'August'} ${year}`;
        case 10:
          return `${shortMonth ? 'Sep' : 'September'} ${year}`;
        case 11:
          return `${shortMonth ? 'Oct' : 'October'} ${year}`;
        case 12:
          return `${shortMonth ? 'Nov' : 'November'} ${year}`;
        default:
          return '';
      }
    case SURVEY_FREQUENCY.QUARTERLY:
      switch (month) {
        case 1:
        case 2:
        case 3:
          return `Q4 ${year - 1}`;
        case 4:
        case 5:
        case 6:
          return `Q1 ${year}`;
        case 7:
        case 8:
        case 9:
          return `Q2 ${year}`;
        case 10:
        case 11:
        case 12:
          return `Q3 ${year}`;
        default:
          return '';
      }
    case SURVEY_FREQUENCY.ANNUALLY:
      return `${year - 1}`;
    default:
      return '';
  }
};
