import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { CompaniesTable } from './CompaniesTable';

const Wrapper = styled('div')``;

export const CompaniesStep = () => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <Typography variant='h2' color={colors.primary[90]} style={{ marginBottom: '8px' }}>
        Who will be your respondents?
      </Typography>
      <Typography variant='body' color={colors.primary[70]}>
        The companies you select here will receive the request.
      </Typography>
      <Typography variant='body' color={colors.primary[70]} style={{ marginBottom: '32px' }}>
        Choose at least one to continue.
      </Typography>
      <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '8px' }}>
        Companies
      </Typography>
      <CompaniesTable />
    </Wrapper>
  );
};
