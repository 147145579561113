import { CSVLink } from 'react-csv';

import { Button, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-blue.svg';
import { useParams } from 'react-router';
import { useCompanyById } from '../../../queries/useCompanies';
import { useKpiMatrix } from '../../SIngleCompany/hooks/useKpiMatrix';
import { useMemo } from 'react';
import { CoreKpiRequestFrequency } from '../../../types';
import { COMPANY_KPIS_CSV_HEADERS } from '../../../constants/defaultValues';
import { useCoreKpiCategories } from '../../../queries/useCoreKpiCategories';
import { useAppConfig } from '../../../queries/useAppConfig';

export const DownloadCSVButton = () => {
  const { colors } = useTheme();
  const { companyId } = useParams();
  const { data: company } = useCompanyById(Number(companyId));
  const { getTableData } = useKpiMatrix();
  const { data: coreKpiCategories } = useCoreKpiCategories();

  const { data: appConfig } = useAppConfig();

  const periodCols = useMemo(() => {
    if (!company) return [];
    return company.kpisData[company.kpisDataFrequency as CoreKpiRequestFrequency].map(
      (item) => item.period
    );
  }, [company]);

  const headers = useMemo(() => {
    const baseHeaders = COMPANY_KPIS_CSV_HEADERS.map((header) => header.label);
    return [...baseHeaders, ...periodCols];
  }, [periodCols]);

  const csvData = useMemo(() => {
    if (!company || !coreKpiCategories || !appConfig?.data?.coreKpiCategoriesOrder?.length)
      return [];
    const kpisTabledata = getTableData(
      company.kpisDataFrequency as CoreKpiRequestFrequency,
      company.kpisData,
      coreKpiCategories,
      appConfig?.data?.coreKpiCategoriesOrder
    );
    const baseKeys = COMPANY_KPIS_CSV_HEADERS.map((header) => header.key);
    const keys = [...baseKeys, ...periodCols];
    return kpisTabledata.map((item) => {
      const row = keys.map((key) => {
        if (typeof item[key] === 'object') {
          return item[key].value || item[key].value === 0 ? item[key].value : '';
        }
        return item[key] || item[key] === 0 ? item[key] : '';
      });
      return row;
    });
  }, [
    appConfig?.data?.coreKpiCategoriesOrder,
    company,
    coreKpiCategories,
    getTableData,
    periodCols,
  ]);

  return (
    <CSVLink data={csvData} headers={headers} filename={`${company?.name}_kpis.csv`}>
      <Button variant='text' startIcon={<DownloadIcon />} style={{ minWidth: 'auto' }}>
        <Typography variant='subtitle2' color={colors.accent[50]}>
          Download Actual Data in CSV
        </Typography>
      </Button>
    </CSVLink>
  );
};
