import { styled, useTheme } from '@mui/material';
import { FC } from 'react';
import { Typography } from '../../../components/Typography/Typography';

interface Props {
  label: string;
  value: number;
  percentage?: number;
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const NumbersWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const ProgressKpi: FC<Props> = ({ label, value, percentage }) => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <Typography variant='subtitle2' color={colors.primary[90]}>
        {label}
      </Typography>
      <NumbersWrapper>
        <Typography
          style={{ fontSize: '32px', fontWeight: '600' }}
          variant='numbers'
          color={colors.primary[80]}
        >
          {value}
        </Typography>
        {Boolean(percentage) && (
          <Typography
            style={{ fontSize: '20px', fontWeight: '700' }}
            variant='numbers'
            color={colors.primary[60]}
          >
            {percentage?.toFixed(0)}%
          </Typography>
        )}
      </NumbersWrapper>
    </Wrapper>
  );
};
