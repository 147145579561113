import { useMutation, useQueryClient } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';
import { ExclusionCategory, ExclusionCondition, LoadingId } from '../types';
import { EXCLUSION_CATEGORIES } from './useExclusionCategories';

export interface ExclusionConditionPayload {
  id: number;
  condition: string;
  isActive: boolean;
  isDeleted: boolean;
  order: number;
  category: number;
}

export const EXCLUSION_CONDITIONS = 'exclusionConditions';

async function createExclusionCondition(
  payload: Partial<ExclusionConditionPayload>
): Promise<ExclusionConditionPayload> {
  const { data } = await Http.axios.post<
    Partial<ExclusionConditionPayload>,
    ExclusionConditionPayload
  >(`/exclusion-condition`, payload);
  return data;
}

export function useCreateExclusionCondition() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  return useMutation(createExclusionCondition, {
    onMutate: async () => {
      startLoading(LoadingId.createExclusionCondition);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create exclusion condition' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(EXCLUSION_CATEGORIES);
      stopLoading(LoadingId.createExclusionCondition);
    },
  });
}

async function updateExclusionCondition(
  exclusionCondition: Partial<ExclusionConditionPayload>
): Promise<ExclusionConditionPayload> {
  const { id, ...payload } = exclusionCondition;
  const { data } = await Http.axios.patch<
    Partial<ExclusionConditionPayload>,
    ExclusionConditionPayload
  >(`/exclusion-condition/${id}`, payload);
  return data;
}

export function useUpdateExclusionCondition() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateExclusionCondition, {
    onMutate: async (payload) => {
      const prevExclusionCategories = queryClient.getQueryData(
        EXCLUSION_CATEGORIES
      ) as ExclusionCategory[];

      const category = prevExclusionCategories?.find(
        (category) => category.id === payload.category
      );
      const condition = category?.conditions?.find((condition) => condition.id === payload.id);

      const updatedCondition = {
        ...condition,
        ...payload,
      };

      const updatedCategories = prevExclusionCategories?.map((prevCategory) => {
        if (prevCategory.id === category?.id) {
          return {
            ...category,
            conditions: category.conditions.map((prevCondition) => {
              if (prevCondition.id === condition?.id) {
                return updatedCondition;
              }
              return prevCondition;
            }),
          };
        }

        return prevCategory;
      });

      queryClient.setQueryData(EXCLUSION_CATEGORIES, updatedCategories);

      startLoading(LoadingId.updateExclusionCondition);

      return { prevExclusionCategories };
    },
    onError: (error: Error, _variables, context) => {
      console.error({ error });
      queryClient.setQueryData(EXCLUSION_CATEGORIES, context?.prevExclusionCategories);
      pushErrorToast({ message: 'Failed to update exclusion condition' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(EXCLUSION_CATEGORIES);
      stopLoading(LoadingId.updateExclusionCondition);
    },
  });
}

async function updateExclusionConditions(payload: {
  data: Partial<ExclusionConditionPayload>[];
}): Promise<ExclusionCondition[]> {
  const { data } = await Http.axios.patch<
    { data: Partial<ExclusionConditionPayload>[] },
    ExclusionCondition[]
  >(`/exclusion-condition/bulk`, payload);
  return data;
}

export function useUpdateExclusionConditions() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateExclusionConditions, {
    onMutate: async () => {
      startLoading(LoadingId.updateExclusionConditions);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update exclusion conditions' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(EXCLUSION_CATEGORIES);
      stopLoading(LoadingId.updateExclusionConditions);
    },
  });
}
