import { styled } from '@mui/material';
import { useParams } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { PortCoWorkflow } from './PortCoWorkflow/PortCoWorkflow';
import { IssuersWorkflow } from './IssuersWorkflow/IssuersWorkflow';

const Wrapper = styled('div')``;

export const WorkflowTabsContent = () => {
  const { activeTab } = useParams();
  return (
    <Wrapper>
      {activeTab === ROUTES.CONTROL_WORKFLOW && <PortCoWorkflow />}
      {activeTab === ROUTES.NOT_CONTROL_WORKFLOW && <IssuersWorkflow />}
    </Wrapper>
  );
};
