import styled from '@emotion/styled';
import { ReactComponent as StarcierLogo } from '../../assets/icons/starcier.svg';
import { ReactComponent as EsgCompassText } from '../../assets/icons/esg-compass.svg';
import { ReactComponent as PrivacyImg } from '../../assets/icons/privacy.svg';
import { Typography } from '../../components/Typography/Typography';
import { useTheme } from '@mui/material';
import { FC } from 'react';

const Wrapper = styled('div')`
  padding: 34px 62px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Content = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const MainSection = styled('div')`
  height: 256px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 146px;
`;

interface UnauthorizedProps {
  showHeader?: boolean;
}

export const Unauthorized: FC<UnauthorizedProps> = ({ showHeader = true }) => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      {showHeader && (
        <Header>
          <StarcierLogo style={{ transform: 'scale(0.9)' }} />
          <EsgCompassText />
        </Header>
      )}
      <Content>
        <MainSection>
          <PrivacyImg />
          <Typography variant='h1' style={{ marginTop: '32px' }} color={colors.primary[80]}>
            Access Denied
          </Typography>
          <Typography variant='h4' style={{ marginTop: '16px' }} color={colors.primary[80]}>
            You do not have the necessary permissions to view this content.
          </Typography>
          <Typography variant='h4' color={colors.primary[80]}>
            Please contact your administrator to request access.
          </Typography>
        </MainSection>
      </Content>
    </Wrapper>
  );
};
