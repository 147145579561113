import { Switch, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';
import { RichText } from '../../../../../components/RichTextEditor/RichTextEditor';
import { SurveySection } from '../../../../../types';
import { useCallback } from 'react';

const Container = styled('div')`
  padding-bottom: 20px;
  margin: 0 56px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
`;

const SwitchWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const RichTextWrapper = styled('div')`
  margin-top: 20px;
`;

interface Props {
  section: SurveySection | null;
  setSection: (section: SurveySection) => void;
}

export const SectionDescription = ({ section, setSection }: Props) => {
  const { colors } = useTheme();

  const toggleDescription = useCallback(() => {
    if (!section) return;
    const updatedSection = {
      ...section,
      includeDescription: !section?.includeDescription,
    };
    setSection(updatedSection);
  }, [section, setSection]);

  const onDescriptionChange = useCallback(
    (value: string) => {
      if (!section) return;

      const updatedSection = {
        ...section,
        description: value,
      };
      setSection(updatedSection);
    },
    [section, setSection]
  );

  if (!section) return null;

  return (
    <Container>
      <SwitchWrapper>
        <Switch checked={!!section?.includeDescription} onChange={toggleDescription} />
        <Typography variant='body' color={colors.primary[80]}>
          Add Description
        </Typography>
      </SwitchWrapper>
      {section?.includeDescription && (
        <RichTextWrapper>
          <RichText
            onChange={onDescriptionChange}
            value={section.description || ''}
            id='section-description-editor-toolbar'
          />
        </RichTextWrapper>
      )}
    </Container>
  );
};
