import { IconButton, styled } from '@mui/material';
import {
  MAIN_SIDEBAR_MENU_EXPANDED_WIDTH,
  MAIN_SIDEBAR_MENU_WIDTH,
} from '../../constants/layoutSizes';
import { ReactComponent as StarcierLogo } from '../../assets/icons/starcier.svg';
import { ReactComponent as PinIcon } from '../../assets/icons/pin-icon.svg';
import { ReactComponent as PinnedIcon } from '../../assets/icons/pinned-icon.svg';
import { MenuItem, MenuItemDropdown } from './MenuItem';
import { Dispatch } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { SetStateAction } from 'jotai';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { EsgCompassLogo } from '../EsgCompassLogo/EsgCompassLogo';
import { TMenuItem, USER_ROLE } from '../../types';

const Wrapper = styled(motion.div)`
  position: absolute;
  height: 100vh;
  border-right: 1px solid ${({ theme }) => theme.colors.primary[30]};
  background-color: ${({ theme }) => theme.colors.primary[0]};
  display: flex;
  flex-direction: column;
  padding: 18px 8px 40px 8px;
  z-index: 99;
  overflow: hidden;
`;

const MenuItemsWrapper = styled('div')`
  padding-top: 16px;
  flex: 1;
`;

const ESGLogo = styled('div')<{ isExpanded?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
  height: 28px;
`;

const LogoWrapper = styled('div')`
  display: flex;
  width: 100%;
  padding-right: 4px;
  align-items: center;
`;

const StarcierWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SetupItemWrapper = styled('div')<{ hasTopBorder?: boolean }>`
  padding-top: 4px;
  border-top: ${({ theme, hasTopBorder }) =>
    hasTopBorder ? `1px solid ${theme.colors.primary[30]}` : 'none'};
`;

const UserContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const UserAvatarWrapper = styled('div')`
  padding: 8px;
`;

const UserInfo = styled('div')`
  display: flex;
  flex-direction: column;
`;

const UserName = styled('p')`
  font-size: ${({ theme }) => theme.text.size.small}px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.text.font.fontFamilyMedium};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.colors.primary[80]};
  text-wrap: nowrap;
`;

const UserRole = styled('p')`
  font-size: ${({ theme }) => theme.text.size.extraSmall}px;
  text-transform: uppercase;
  line-height: 18px;
  font-family: ${({ theme }) => theme.text.font.fontFamilySemiBold};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  color: ${({ theme }) => theme.colors.primary[70]};
  text-wrap: nowrap;
`;

interface Props {
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  isExpanded: boolean;
  onPinToggle: () => void;
  isPinned: boolean;
  menuItems: TMenuItem[] | null;
  setupMenuItem?: TMenuItem;
  username: string;
  activeRole: string | null;
  onUserAvatarClick: () => void;
}

export const SidebarMenu = ({
  setIsExpanded,
  isExpanded,
  onPinToggle,
  isPinned,
  menuItems,
  username,
  activeRole,
  onUserAvatarClick,
}: Props) => {
  const controls = useAnimation();

  const onHoverStart = () => {
    if (isPinned) return controls.stop();
    setIsExpanded(true);
    controls.start({
      width: `${MAIN_SIDEBAR_MENU_EXPANDED_WIDTH}px`,
      transition: { duration: 0.3 },
    });
  };

  const onHoverEnd = () => {
    if (isPinned) return controls.stop();
    setIsExpanded(false);
    controls.start({ width: `${MAIN_SIDEBAR_MENU_WIDTH}px`, transition: { duration: 0.3 } });
  };

  return (
    <Wrapper
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      animate={controls}
      style={{
        width: isPinned ? `${MAIN_SIDEBAR_MENU_EXPANDED_WIDTH}px` : `${MAIN_SIDEBAR_MENU_WIDTH}px`,
      }}
    >
      <ESGLogo>
        <LogoWrapper>
          {!isExpanded ? (
            <StarcierWrapper>
              <StarcierLogo style={{ transform: 'scale(0.9)' }} />
            </StarcierWrapper>
          ) : (
            <EsgCompassLogo />
          )}
        </LogoWrapper>
        {isExpanded && (
          <IconButton onClick={onPinToggle}>{isPinned ? <PinnedIcon /> : <PinIcon />}</IconButton>
        )}
      </ESGLogo>

      <MenuItemsWrapper>
        {menuItems?.map((item) => {
          if (item?.subItems?.length) {
            return (
              <SetupItemWrapper key={item.id} hasTopBorder={item.hasTopBorder}>
                <MenuItemDropdown item={item} isExpanded={isExpanded} />
              </SetupItemWrapper>
            );
          }
          return item?.showForRoles?.includes(activeRole as USER_ROLE) || !item?.showForRoles ? (
            <MenuItem item={item} key={item.id} isExpanded={isExpanded} />
          ) : null;
        })}
      </MenuItemsWrapper>
      <UserContainer>
        <UserAvatarWrapper onClick={onUserAvatarClick}>
          <UserAvatar name={username ?? ''} />
        </UserAvatarWrapper>
        {isExpanded && (
          <UserInfo>
            <UserName>{username}</UserName>
            <UserRole>{activeRole ?? ''}</UserRole>
          </UserInfo>
        )}
      </UserContainer>
    </Wrapper>
  );
};