import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  styled,
  AccordionProps,
  AccordionDetailsProps,
} from '@mui/material';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/arrow-right.svg';
import { Objective } from '../../../../../types';
import { ObjectiveSummary } from './components/ObjectiveSummary/ObjectiveSummary';
import { KeyTasksTable } from './components/KeyTasksTable/KeyTasksTable';

const Wrapper = styled('div')`
  overflow-x: auto;
`;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))`
  min-width: 1400px;

  &:before {
    display: none;
  }
`;

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))`
  flex-direction: row-reverse;
  border: none;
  background-color: ${({ theme }) => theme.colors.accent[10]};
  border-radius: 10px;
  padding: 10px 16px 16px 16px;
  align-items: flex-end;

  &.Mui-expanded {
    border-radius: 10px 10px 0 0;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    margin-bottom: 4px;
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
`;

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))`
  padding: 0;
`;

interface Props {
  objective: Objective;
  expandedObjectiveId: number | null;
  setExpandedObjectiveId: Dispatch<SetStateAction<number | null>>;
}

export const ObjectivesAccordion = ({
  objective,
  expandedObjectiveId,
  setExpandedObjectiveId,
}: Props) => {
  const hasKeyTasks = useMemo(() => {
    return Boolean(objective?.keyTasks?.length);
  }, [objective?.keyTasks?.length]);

  const handleChange = (id: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    if (!hasKeyTasks) return;
    setExpandedObjectiveId(newExpanded ? id : null);
  };

  return (
    <Wrapper>
      <Accordion
        expanded={expandedObjectiveId === objective?.id}
        onChange={handleChange(objective.id)}
      >
        <AccordionSummary
          expandIcon={hasKeyTasks ? <ArrowIcon /> : null}
          style={{ cursor: hasKeyTasks ? 'pointer' : 'default' }}
          sx={{
            '& .MuiAccordionSummary-content': {
              margin: hasKeyTasks ? '0 0 0 16px' : ' 0 0 0 24px',
            },
            '&.Mui-expanded': {
              borderRadius: hasKeyTasks ? '10px 10px 0 0' : '10px',
            },
          }}
        >
          <ObjectiveSummary objective={objective} setExpandedObjectiveId={setExpandedObjectiveId} />
        </AccordionSummary>

        {hasKeyTasks && (
          <AccordionDetails>
            <KeyTasksTable keyTasks={objective?.keyTasks} />
          </AccordionDetails>
        )}
      </Accordion>
    </Wrapper>
  );
};
