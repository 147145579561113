import { useQuery } from 'react-query';
import { Http } from '../services/Http';
import { SurveyTemplateSection } from '../types';

const TEMPLATE_SECTIONS = 'templateSections';

async function fetchTemplateSections(): Promise<SurveyTemplateSection[]> {
  const { data } = await Http.axios.get<SurveyTemplateSection[]>(`/template-section`);
  return data;
}

export function useTemplateSections() {
  return useQuery(TEMPLATE_SECTIONS, () => fetchTemplateSections(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}
