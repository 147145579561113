import { Dispatch, SetStateAction, useCallback } from 'react';
import { AddEditObjectiveModal } from '../AddEditObjectiveModal/AddEditObjectiveModal';
import { ObjectivePayload, useUpdateObjective } from '../../../../../../../queries/useObjectives';
import { useParams } from 'react-router';
import { Objective, REPORT_STATUS } from '../../../../../../../types';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  objective: Objective;
}

export const EditObjectiveModal = ({ isModalOpen, setIsModalOpen, objective }: Props) => {
  const params = useParams();

  const editObjective = useUpdateObjective(Number(params.id));

  const onSubmit = useCallback(
    (payload: Partial<ObjectivePayload>) => {
      editObjective.mutate({
        id: objective.id,
        company: Number(params.id),
        status: REPORT_STATUS.NOT_STARTED,
        progress: 0,
        ...payload,
      });
    },
    [editObjective, objective.id, params.id]
  );

  return (
    <AddEditObjectiveModal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title='Edit Objective'
      confirmBtnText='Edit Objective'
      onSubmit={onSubmit}
      objective={objective}
    />
  );
};
