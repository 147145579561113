import { useCallback, useMemo } from 'react';
import {
  QUESTION_TYPE,
  SurveyQuestion,
  QuestionOption as TQuestionOption,
} from '../../../../../../../../types';
import { useAtom } from 'jotai';
import {
  activeQuestionIdState,
  activeSurveyState,
  questionState,
} from '../../../../../../../../state/UIState';
import { ButtonBase, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../../../components/Typography/Typography';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SubQuestion } from './SubQuestion/SubQuestion';
import { generateId } from '../../../../../../../../utils/generateId';
import { getDefaultTableData } from '../../../../../../../../constants/defaultValues';
import { QuestionOption } from '../QuestionOption';

interface Props {
  option: TQuestionOption;
  showDragIcon: boolean;
  questionNum: number;
  question: SurveyQuestion;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const OptionContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const OptionWrapper = styled('div')`
  flex: 1;
`;

const AddSubquestion = styled('div')`
  border-radius: 2px;
  height: 24px;
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 6px;
  margin-top: 5px;
`;

const SubQuestionsContainer = styled('div')<{ hasSubQuestions?: boolean }>`
  flex: 1;
  border-left: ${({ theme, hasSubQuestions }) =>
    hasSubQuestions ? `1px solid ${theme.colors.primary[20]}` : 'none'};
  padding-left: ${({ hasSubQuestions }) => (hasSubQuestions ? '16px' : 0)};
`;

const AddSubquestionButton = styled(ButtonBase)`
  display: flex;
`;

export const SingleOption = ({ option, showDragIcon, questionNum, question }: Props) => {
  const [activeQuestionId] = useAtom(activeQuestionIdState);
  const [activeQuestion, setQuestion] = useAtom(questionState(activeQuestionId));
  const { setNodeRef, transform, transition, attributes, listeners, isDragging } =
    useSortable(option);

  const [activeSurvey] = useAtom(activeSurveyState);

  const includeConditions = useMemo(
    () => activeQuestion?.includeConditions && question?.id === activeQuestionId,
    [activeQuestion?.includeConditions, activeQuestionId, question?.id]
  );

  const { colors } = useTheme();

  const onUpdateFlag = useCallback(() => {
    if (!activeQuestion) return;

    const updatedQuestion = {
      ...activeQuestion,
      options: activeQuestion.options.map((o) => {
        if (o.id === option.id) {
          return {
            ...o,
            isFlagged: !option.isFlagged,
          };
        }
        return {
          ...o,
          isFlagged: false,
        };
      }),
    };

    setQuestion(updatedQuestion);
  }, [option.id, option.isFlagged, activeQuestion, setQuestion]);

  const onUpdateWeight = useCallback(
    (weight: number) => {
      if (!activeSurvey?.includeWeight || !activeQuestion) return;

      const updatedQuestion = {
        ...activeQuestion,
        options: activeQuestion.options.map((o) => {
          if (o.id === option.id) {
            return {
              ...o,
              weight,
            };
          }
          return o;
        }),
      };

      setQuestion(updatedQuestion);
    },
    [activeQuestion, activeSurvey?.includeWeight, option.id, setQuestion]
  );

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const onAddSubQuestionClick = useCallback(() => {
    if (!activeQuestion) return;
    const newSubQuestion: SurveyQuestion = {
      id: generateId(),
      sectionId: activeQuestion.sectionId,
      name: option?.subQuestions?.length
        ? `Question ${option?.subQuestions?.length + 1}`
        : 'Question 1',
      type: QUESTION_TYPE.TEXT,
      options: [],
      tableData: getDefaultTableData(),
      includeInstructions: false,
      includeEvaluation: activeSurvey?.includeEvaluation || false,
      evaluationRules: null,
      instructions: '',
      isRequired: true,
    };

    const updatedQuestion = {
      ...activeQuestion,
      options: activeQuestion.options.map((o) => {
        if (o.id === option.id) {
          return {
            ...o,
            subQuestions: [...(o?.subQuestions || []), newSubQuestion],
          };
        }
        return {
          ...o,
        };
      }),
    };

    setQuestion(updatedQuestion);
  }, [
    activeQuestion,
    option?.subQuestions?.length,
    option.id,
    activeSurvey?.includeEvaluation,
    setQuestion,
  ]);

  return (
    <Container ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <OptionContainer>
        {includeConditions && (
          <Typography variant='subtitle2' color={colors.primary[40]} style={{ lineHeight: 1.2 }}>
            if
          </Typography>
        )}
        <OptionWrapper>
          <QuestionOption
            option={option}
            type={QUESTION_TYPE.SINGLE}
            showDragIcon={showDragIcon}
            onUpdateFlag={onUpdateFlag}
            onUpdateWeight={onUpdateWeight}
          />
        </OptionWrapper>
      </OptionContainer>
      {includeConditions && (
        <OptionContainer
          style={{
            alignItems: option?.subQuestions?.length ? 'flex-start' : 'center',
            marginBottom: '24px',
            marginTop: '4px',
          }}
        >
          <Typography
            variant='subtitle2'
            color={colors.primary[40]}
            style={{ lineHeight: option?.subQuestions?.length ? 1.9 : 1.2 }}
          >
            then
          </Typography>
          <SubQuestionsContainer
            hasSubQuestions={Boolean(option?.subQuestions?.length && !isDragging)}
          >
            {!isDragging && (
              <div>
                {option?.subQuestions?.map((subquestion, i) => (
                  <SubQuestion
                    key={subquestion.id}
                    subQuestion={subquestion}
                    order={i + 1}
                    questionNum={questionNum}
                    option={option}
                  />
                ))}
              </div>
            )}
            <AddSubquestion>
              <AddSubquestionButton onClick={onAddSubQuestionClick}>
                <Typography variant='srOnly' color={colors.accent[50]} style={{ lineHeight: 1.2 }}>
                  + Add Question
                </Typography>
              </AddSubquestionButton>
            </AddSubquestion>
          </SubQuestionsContainer>
        </OptionContainer>
      )}
    </Container>
  );
};
