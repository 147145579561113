import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { useCallback, useMemo, useState } from 'react';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { CheckAll } from './CheckAll';
import { useAtom } from 'jotai';
import { selectedConditionState } from '../../../state/UIState';
import { ExclusionsList } from './ExclusionsList/ExclusionsList';

const Container = styled('div')`
  padding: 24px 40px;
  width: 100%;
  overflow: hidden;
  height: 100%;
`;

export const MainContent = () => {
  const [filterValue, setFilterValue] = useState<string>('');
  const { colors } = useTheme();

  const [selectedCondition] = useAtom(selectedConditionState);

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = e.target;
      setFilterValue(value);
    },
    []
  );

  const categoryExclusions = useMemo(() => {
    return selectedCondition?.exclusions || [];
  }, [selectedCondition?.exclusions]);

  const exclusions = useMemo(() => {
    if (!filterValue) return categoryExclusions;

    return categoryExclusions?.filter((exclusion) =>
      exclusion?.exclusion?.toLowerCase()?.includes(filterValue?.toLowerCase())
    );
  }, [categoryExclusions, filterValue]);

  return (
    <Container>
      <Typography variant='h4' color={colors.primary[90]}>
        Select Exclusion
      </Typography>
      <SearchInput
        placeholder='Search for an exclusion'
        onChange={onFilterChange}
        value={filterValue}
        style={{ marginTop: '20px', width: '345px' }}
        onClear={() => setFilterValue('')}
      />
      {Boolean(exclusions?.length) && <CheckAll />}

      <ExclusionsList exclusions={exclusions} />
    </Container>
  );
};
