import { Navigate, Route, Routes, useNavigate } from 'react-router';
import { ROUTES } from './constants/routes';
import { Companies } from './pages/Companies/Companies';
import { CreateRequestStepper } from './pages/CreateRequest/CreateRequestStepper';
import { Portfolio } from './pages/Portfolio/Portfolio';
import { Layout } from './pages/layout/Layout';
import { Requests } from './pages/Requests/Requests';
import { Surveys } from './pages/Surveys/Surveys';
import { SingleRequestGroup } from './pages/SingleRequestGroup/SingleRequestGroup';
import { SurveyEditor } from './pages/SurveyEditor/SurveyEditor';
import { StepContent as SurveyEditorContent } from './pages/SurveyEditor/components/StepContent';
import { StepContent as CreateRequestStepContent } from './pages/CreateRequest/components/StepContent';
import { StepContent as UploadCompaniesContent } from './pages/UploadCompanies/components/StepContent';
import { StepContent as UploadCompanyKpisContent } from './pages/UploadCompanyKpis/components/StepContent';
import { Questionnaire } from './pages/Questionnaire/Questionnaire';
import { QuestionnaireContent } from './pages/Questionnaire/components/QuestionnaireContent';
// import { Evaluation } from './pages/Evaluation/Evaluation';
import { UploadCompaniesStepper } from './pages/UploadCompanies/UploadCompaniesStepper';
import { SingleAssessment } from './pages/SingleAssessment/SingleAssessment';
import { InternalQuestionnaire } from './pages/Questionnaire/InternalQuestionnaire';
import { CompanyExclusions } from './pages/CompanyExclusions/CompanyExclusions';
import { CoreKpis } from './pages/CoreKpis/CoreKpis';
import { UploadCompanyKpisStepper } from './pages/UploadCompanyKpis/UploadCompanyKpisStepper';
import { TabsContent } from './pages/SIngleCompany/TabsContent';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';
import { SecureRoutes } from './SecureRoutes';
import { Checklist } from './pages/Checklist/Checklist';
import { UserManagement } from './pages/UserManagement/UserManagement';
import { Issuers } from './pages/Issuers/Issuers';
import RolesGuard from './components/RolesGuard/RolesGuard';
import { USER_ROLE } from './types';
import { ExportCompaniesStepper } from './pages/ExportCompanies/ExportCompaniesStepper';
import { StepContent as ExportCompaniesStepContent } from './pages/ExportCompanies/components/StepContent';
import { ExclusionsManagement } from './pages/ExclusionsManagement/ExclusionsManagement';
import { AddCompanyExclusions } from './pages/AddCompanyExclusions/AddCompanyExclusions';
import { PendingEvaluation } from './pages/Companies/PendingEvaluation';
import { SingleCompanyRedirect } from './pages/SingleCompanyRedirect/SingleCompanyRedirect';
import { Workflows } from './pages/Workflows/Workflows';
import { WorkflowTabsContent } from './pages/Workflows/WorkflowTabsContent';
import { SingleCompany } from './pages/SIngleCompany/SingleCompany';
import { Review } from './pages/Review/Review';
import { WorkflowPreview } from './pages/WorkflowPreview/WorkflowPreview';

const oktaAuth = new OktaAuth({
  issuer: `https://${process.env.REACT_APP_OKTA_BASE_URL}/oauth2/default`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
});

function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = () => {
    navigate(`/${ROUTES.ISSUERS}`);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path={'/login/callback'} element={<LoginCallback />} />
        <Route path={'/'} element={<Layout />}>
          <Route element={<SecureRoutes />}>
            <Route index element={<Navigate replace to={`${ROUTES.ISSUERS}`} />} />
            <Route
              path={ROUTES.PORTFOLIO}
              element={
                <RolesGuard
                  allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.COMPLIANCE, USER_ROLE.MANAGER]}
                >
                  <Portfolio />
                </RolesGuard>
              }
            />
            <Route
              path={ROUTES.REQUESTS}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <Requests />
                </RolesGuard>
              }
            />
            <Route
              path={`${ROUTES.REQUESTS}/:requestGroupId`}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <SingleRequestGroup />
                </RolesGuard>
              }
            />
            <Route
              path={`${ROUTES.REPORTS}/:reportId`}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.MANAGER,
                    USER_ROLE.ANALYST,
                  ]}
                >
                  <Review />
                </RolesGuard>
              }
            />
            <Route
              path={`${ROUTES.QUESTIONNAIRES}/:reportId`}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <InternalQuestionnaire />
                </RolesGuard>
              }
            >
              <Route path={`:activeStep`} element={<QuestionnaireContent isInternal />} />
            </Route>
            <Route
              path={`${ROUTES.ASSESSMENTS}/:reportId`}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <SingleAssessment />
                </RolesGuard>
              }
            />
            <Route
              path={ROUTES.SURVEYS}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <Surveys />
                </RolesGuard>
              }
            />
            <Route
              path={`${ROUTES.SURVEYS}/:id`}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <SurveyEditor />
                </RolesGuard>
              }
            >
              <Route path={`:activeStep`} element={<SurveyEditorContent />} />
            </Route>
            <Route
              path={`${ROUTES.INVESTOR_EXCLUSIONS}/`}
              element={
                <RolesGuard
                  allowedRoles={[USER_ROLE.ADMIN, USER_ROLE.COMPLIANCE, USER_ROLE.MANAGER]}
                >
                  <ExclusionsManagement />
                </RolesGuard>
              }
            />
            <Route
              path={ROUTES.COMPANIES}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <Companies />
                </RolesGuard>
              }
            />
            <Route
              path={ROUTES.PENDING_EVALUATION}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <PendingEvaluation />
                </RolesGuard>
              }
            />
            <Route
              path={`${ROUTES.COMPANIES}/:id`}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <SingleCompany />
                </RolesGuard>
              }
            >
              <Route path={`:activeTab`} element={<TabsContent />} />
            </Route>
            <Route
              path={`${ROUTES.COMPANIES}/${ROUTES.EXTERNAL_ID}/:externalId`}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <SingleCompanyRedirect />
                </RolesGuard>
              }
            />
            <Route
              path={`${ROUTES.COMPANIES}/:id/${ROUTES.INVESTOR_EXCLUSIONS}`}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <CompanyExclusions />
                </RolesGuard>
              }
            />
            <Route
              path={`${ROUTES.COMPANIES}/:id/${ROUTES.ADD_EXCLUSIONS}`}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <AddCompanyExclusions />
                </RolesGuard>
              }
            />
            <Route
              path={ROUTES.CREATE_REQUEST}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <CreateRequestStepper />
                </RolesGuard>
              }
            >
              <Route index element={<Navigate replace to={`/${ROUTES.CREATE_REQUEST}/1`} />} />
              <Route path={`:activeStep`} element={<CreateRequestStepContent />} />
            </Route>
            <Route
              path={ROUTES.EXPORT_COMPANIES}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <ExportCompaniesStepper />
                </RolesGuard>
              }
            >
              <Route index element={<Navigate replace to={`/${ROUTES.EXPORT_COMPANIES}/1`} />} />
              <Route path={`:activeStep`} element={<ExportCompaniesStepContent />} />
            </Route>
            <Route path={ROUTES.UPLOAD_COMPANIES} element={<UploadCompaniesStepper />}>
              <Route index element={<Navigate replace to={`/${ROUTES.UPLOAD_COMPANIES}/1`} />} />
              <Route path={`:activeStep`} element={<UploadCompaniesContent />} />
            </Route>
            <Route
              path={`${ROUTES.UPLOAD_COMPANY_KPIS}/:companyId`}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <UploadCompanyKpisStepper />
                </RolesGuard>
              }
            >
              <Route index element={<Navigate replace to={`/${ROUTES.UPLOAD_COMPANY_KPIS}/1`} />} />
              <Route path={`:activeStep`} element={<UploadCompanyKpisContent />} />
            </Route>
            <Route
              path={ROUTES.CREATE_SURVEY}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <SurveyEditor />
                </RolesGuard>
              }
            >
              <Route index element={<Navigate replace to={`/${ROUTES.CREATE_SURVEY}/build`} />} />
              <Route path={`:activeStep`} element={<SurveyEditorContent />} />
            </Route>
            <Route
              path={ROUTES.CORE_KPIS}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <CoreKpis />
                </RolesGuard>
              }
            />
            <Route
              path={ROUTES.CHECKLIST}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <Checklist />
                </RolesGuard>
              }
            />
            <Route
              path={ROUTES.ISSUERS}
              element={
                <RolesGuard
                  allowedRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.COMPLIANCE,
                    USER_ROLE.ANALYST,
                    USER_ROLE.MANAGER,
                  ]}
                >
                  <Issuers />
                </RolesGuard>
              }
            />
            <Route
              path={ROUTES.USER_MANAGEMENT}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <UserManagement />
                </RolesGuard>
              }
            />
            <Route
              path={`${ROUTES.WORKFLOWS}`}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <Workflows />
                </RolesGuard>
              }
            >
              <Route path={`:activeTab`} element={<WorkflowTabsContent />} />
            </Route>
            <Route
              path={`${ROUTES.WORKFLOW_PREVIEW}/:companyType`}
              element={
                <RolesGuard allowedRoles={[USER_ROLE.ADMIN]}>
                  <WorkflowPreview />
                </RolesGuard>
              }
            />
            <Route path='*' element={<Navigate replace to={`${ROUTES.PORTFOLIO}`} />} />
          </Route>
        </Route>
        <Route path={ROUTES.PORTAL} element={<Questionnaire />}>
          <Route path={`:activeStep`} element={<QuestionnaireContent />} />
        </Route>
      </Routes>
    </Security>
  );
}

export default App;
