import { styled } from '@mui/material';
import { useAtom } from 'jotai';
import {
  isMoveModeActiveState,
  isConfirmBarActiveState,
  kpisTableRowSelectionState,
  selectedCoreKpisState,
} from '../../../state/UIState';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-white.svg';
import { ReactComponent as MoveIcon } from '../../../assets/icons/corner-arrow-white.svg';
import { useCallback, useMemo, useState } from 'react';
import { ConfirmDeleteModal } from '../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { useUpdateCoreKpis } from '../../../queries/useCoreKpis';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { SelectionBar } from '../../../components/SelectionBar/SelectionBar';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';

const Wrapper = styled('div')``;

export const KpisSelectionBar = () => {
  const [selectedKpis] = useAtom(selectedCoreKpisState);
  const [, setIsConfirmMoveBarActive] = useAtom(isConfirmBarActiveState);
  const [isMoveModeActive] = useAtom(isMoveModeActiveState);
  const [, setIsBulkMoveModeActive] = useAtom(isMoveModeActiveState);
  const [, setRowSelection] = useAtom(kpisTableRowSelectionState);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const handleOpenConfirmDeleteModal = () => setIsConfirmDeleteModalOpen(true);
  const handleCloseConfirmDeleteModal = () => setIsConfirmDeleteModalOpen(false);
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const { pushSuccessToast } = useToastMessage();
  const updateCoreKpis = useUpdateCoreKpis();

  const actions = useMemo(() => {
    return [
      {
        id: 'MOVE',
        value: 'Move KPIs to...',
        icon: <MoveIcon />,
      },
      {
        id: 'DELETE',
        value: 'Delete KPIs',
        icon: <DeleteIcon />,
      },
      {
        id: 'CANCEL',
        value: 'Cancel',
      },
    ];
  }, []);

  const onBulkMove = useCallback(() => {
    setIsConfirmMoveBarActive(true);
    setIsBulkMoveModeActive(true);
  }, [setIsBulkMoveModeActive, setIsConfirmMoveBarActive]);

  const handleDeleteKpis = useCallback(async () => {
    const payload = {
      data: selectedKpis.map((k) => ({
        id: k.id,
        isDeleted: true,
      })),
    };
    await updateCoreKpis.mutateAsync(payload, {
      onSuccess: async () => {
        if (!appConfigData?.data) {
          return;
        }
        await updateAppConfig.mutateAsync({
          data: { ...appConfigData?.data, isCoreKpiSurveyUpToDate: false },
        });
        pushSuccessToast({
          message: `${selectedKpis.length} KPIs were successfully deleted.`,
        });
      },
    });
    setRowSelection({});
    handleCloseConfirmDeleteModal();
  }, [
    appConfigData?.data,
    pushSuccessToast,
    selectedKpis,
    setRowSelection,
    updateAppConfig,
    updateCoreKpis,
  ]);

  const handleSelectAction = useCallback(
    (actionId: string) => {
      if (isMoveModeActive) return;
      switch (actionId) {
        case 'MOVE':
          onBulkMove();
          return;
        case 'DELETE':
          handleOpenConfirmDeleteModal();
          return;
        case 'CANCEL':
          setRowSelection({});
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [isMoveModeActive, onBulkMove, setRowSelection]
  );
  return (
    <Wrapper>
      <SelectionBar
        actions={actions}
        isDisabled={isMoveModeActive}
        selectedItemsLength={selectedKpis.length}
        isActive={selectedKpis.length > 0}
        onSelectAction={handleSelectAction}
      />

      <ConfirmDeleteModal
        onClose={() => {
          handleCloseConfirmDeleteModal();
        }}
        onConfirm={handleDeleteKpis}
        title='Delete the KPIs?'
        note='This KPIs are active and used in Surveys & Company Profile.Any modifications you make will have a global effect.'
        confirmLabel='Yes, Delete'
        cancelLabel='No, Cancel'
        isOpen={isConfirmDeleteModalOpen}
      />
    </Wrapper>
  );
};
