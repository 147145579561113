import { Dispatch, FocusEvent, SetStateAction, memo, useCallback, useEffect, useMemo } from 'react';
import { styled, useTheme } from '@mui/material';
import { EditableTextFieldV2 } from '../EditableTextField/EditableTextFieldV2';
import { NumericFormat, useNumericFormat } from 'react-number-format';

import { CORE_KPI_FORMAT } from '../../types';

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
`;

interface EditableCellProps {
  value: string;
  onValueChanged: (value: string) => void;
  inputPadding?: string;
  disabled?: boolean;
  autofocus?: boolean;
  onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
  style?: React.CSSProperties;
  format?: CORE_KPI_FORMAT;
  inputStyles?: React.CSSProperties;
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  withBorder?: boolean;
  placeholder?: string;
}

// eslint-disable-next-line react/display-name
export const EditableNumericCell = memo(
  ({
    value,
    onValueChanged,
    inputPadding,
    disabled = false,
    autofocus = false,
    onBlur,
    style,
    format = CORE_KPI_FORMAT.NUMBER,
    inputStyles = {},
    inputValue,
    setInputValue,
    withBorder,
    placeholder,
  }: EditableCellProps) => {
    const { text, colors } = useTheme();

    useEffect(() => {
      if (!value) return;
      setInputValue(value);
    }, [setInputValue, value]);

    const suffix = useMemo(() => {
      if (format === CORE_KPI_FORMAT.PERCENTAGE) return '%';
      return '';
    }, [format]);

    const { removeFormatting } = useNumericFormat({
      allowNegative: false,
      thousandSeparator: ',',
      thousandsGroupStyle: 'thousand',
      suffix,
      decimalScale: 2,
      fixedDecimalScale: true,
    });

    const onValueChange = useCallback(
      (val: string) => {
        const unformattedValue = removeFormatting?.(val);
        onValueChanged(unformattedValue || '');
      },
      [onValueChanged, removeFormatting]
    );

    return (
      <Wrapper style={style}>
        <NumericFormat
          allowNegative={false}
          thousandSeparator=','
          thousandsGroupStyle='thousand'
          decimalScale={2}
          fixedDecimalScale
          suffix={suffix}
          value={inputValue}
          placeholder={placeholder}
          onValueChange={(values) => {
            setInputValue(values.value);
          }}
          withBorder={withBorder}
          onValueChanged={onValueChange}
          customInput={EditableTextFieldV2}
          inputStyle={{ textAlign: 'right', paddingRight: '4px', ...inputStyles }}
          font={text.font.fontInconsolataRegular}
          fontSize='16px'
          style={{ width: '100%', height: '100%' }}
          focusOnMount={autofocus}
          wrapperStyle={{ width: '100%', height: '30px' }}
          inputProps={{
            style: {
              padding: inputPadding ? inputPadding : '0 8px',
              lineHeight: '20px',
              fontFamily: text.font.fontInconsolataRegular,
              color: colors.primary[90],
            },
          }}
          disabled={disabled}
          onBlur={(e: FocusEvent<HTMLInputElement, Element>) => onBlur && onBlur(e)}
        />
      </Wrapper>
    );
  }
);
