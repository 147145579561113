import { useState } from 'react';
import { Company } from '../../../../types';
import { AssignEmailCellContent } from '../AssignEmailCellContent/AssignEmailCellContent';
import { AnalystModal } from './AnalystModal';
import { ConfirmDeleteModal } from '../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { DeleteModalWarning } from '../AssignEmailCellContent/DeleteModalWarning';
import { useUpdateCompany } from '../../../../queries/useCompanies';

interface Props {
  company: Company;
}

export const AssignAnalystCell = ({ company }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const updateCompany = useUpdateCompany();

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const onDeleteAnalyst = () => {
    onDeleteModalClose();
    updateCompany.mutate({ id: company.id, analyst: null });
  };

  return (
    <>
      <AssignEmailCellContent
        isAssigned={Boolean(company?.analyst)}
        onOpenEditModal={onOpenModal}
        onOpenModal={onOpenModal}
        assignedEmail={company?.analyst}
        unassignedEmailText='Assign ESG Analyst'
        onOpenDeleteModal={() => setIsDeleteModalOpen(true)}
      />
      {isModalOpen && (
        <AnalystModal
          isOpen
          company={company}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        title='Delete ESG Analyst?'
        onConfirm={onDeleteAnalyst}
        infoContent={<DeleteModalWarning email={company.analyst} />}
        isInfoContentWarning
      />
    </>
  );
};
