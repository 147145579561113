import { useAtomValue } from 'jotai';
import { activeRoleState } from '../../../state/UIState';
import { WorkflowStep } from '../../../types';

export const useHasAccessToEvaluate = () => {
  const activeRole = useAtomValue(activeRoleState);

  const getHasEvaluationAccess = (step: WorkflowStep) => {
    if (!activeRole) return false;
    const approvalRoles = step.approvalRoles?.map((role) => role.name);

    return !approvalRoles?.length || approvalRoles?.includes(activeRole);
  };

  return { getHasEvaluationAccess };
};
