import { styled, Switch, useTheme } from '@mui/material';
import { FC } from 'react';
import { SurveyQuestion } from '../../../../../types';
import { Typography } from '../../../../../components/Typography/Typography';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/info.svg';
import { RichText } from '../../../../../components/RichTextEditor/RichTextEditor';

interface Props {
  question: SurveyQuestion;
  toggleInstructions: () => void;
  onInstructionsChange: (val: string) => void;
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
const SwitchWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: -9px;
  margin-bottom: 16px;
`;

export const InstructionsControls: FC<Props> = ({
  question,
  toggleInstructions,
  onInstructionsChange,
}) => {
  const { colors } = useTheme();

  return (
    <Wrapper>
      <SwitchWrapper>
        <Switch checked={question.includeInstructions} onChange={toggleInstructions} />
        <Typography variant='body' color={colors.primary[80]}>
          Include question instructions
        </Typography>
        <InfoIcon />
      </SwitchWrapper>
      {question.includeInstructions && (
        <RichText
          onChange={onInstructionsChange}
          value={question.instructions}
          id={`instructions-editor-toolbar-${question.id}`}
        />
      )}
    </Wrapper>
  );
};
