import {
  EVALUATION_SCALE,
  EvaluationRule,
  QuestionReview,
  Response,
  SURVEY_STAGE,
  Survey,
} from '../../../types';
import { CoreKpiSingleAssessment } from './CoreKpiSingleAssessment/CoreKpiSingleAssessment';
import { QuestionsTable } from './QuestionsTable';

interface Props {
  survey: Survey;
  response: Record<string, Response>;
  reportReview: Record<string, QuestionReview>;
  evaluation: Record<string, EvaluationRule>;
  evaluationScale?: EVALUATION_SCALE;
}

export const SingleAssessmentContent = ({
  survey,
  response,
  reportReview,
  evaluation,
  evaluationScale,
}: Props) => {
  if (survey?.stage === SURVEY_STAGE.CORE_KPI)
    return <CoreKpiSingleAssessment survey={survey} response={response} />;

  return (
    <QuestionsTable
      survey={survey}
      response={response}
      reportReview={reportReview}
      evaluation={evaluation}
      evaluationScale={evaluationScale}
    />
  );
};
