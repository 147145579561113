import { ROUTES } from '../constants/routes';
import { COMPANY_TYPE } from '../types';

export const getCompanyTypeBasedOnPath = (path: string) => {
  if (path === ROUTES.CONTROL_WORKFLOW) {
    return COMPANY_TYPE.CONTROLLED;
  }

  if (path === ROUTES.NOT_CONTROL_WORKFLOW) {
    return COMPANY_TYPE.NOT_CONTROLLED;
  }

  return null;
};
