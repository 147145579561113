import React, { forwardRef } from 'react';

import { Action, Props as ActionProps } from './Action';
import { ReactComponent as DragIcon } from '../../../../../../assets/icons/drag.svg';

const Handle = forwardRef<HTMLDivElement, ActionProps>((props, ref) => {
  return (
    <Action ref={ref} cursor='grab' data-cypress='draggable-handle' {...props}>
      <DragIcon />
    </Action>
  );
});

Handle.displayName = 'Handle';

export { Handle };
