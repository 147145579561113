import { ReactComponent as FlagOutlined } from '../../../../assets/icons/flag-grey.svg';
import { ReactComponent as FlagFilled } from '../../../../assets/icons/flag-red.svg';
import { ButtonBase, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';

const Container = styled(ButtonBase)`
  display: flex;
  align-items: center;
`;

interface Props {
  isFlagged?: boolean;
  onToggleFlag?: () => void;
}

export const FlagOption = ({ isFlagged, onToggleFlag }: Props) => {
  const { colors } = useTheme();
  return (
    <Container onClick={onToggleFlag}>
      {isFlagged ? <FlagFilled /> : <FlagOutlined />}

      <Typography variant='srOnly' color={colors.accent[50]} style={{ marginLeft: '4px' }}>
        {isFlagged ? 'Unflag' : 'Flag'}
      </Typography>
    </Container>
  );
};
