import { Button, styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { SearchInput } from '../../../../../components/SearchInput/SearchInput';
import { Typography } from '../../../../../components/Typography/Typography';
import {
  APPLY_EVALUATION_RULES_HEADER_CONTROLS,
  HEADER_HEIGHT,
} from '../../../../../constants/layoutSizes';
import {
  filteredSurveyTemplatesDataState,
  isApplyingEvaluationRulesModeActiveState,
  surveyTemplatesDataState,
} from '../../../../../state/UIState';
import { SurveyTemplateSection } from '../../../../../types';
import { TemplateCategoryList } from './TemplateCategoryList';

export const Wrapper = styled('div')<{ isApplyActive: boolean }>`
  position: relative;
  width: 432px;
  max-width: 432px;
  height: ${({ isApplyActive }) =>
    `calc(100vh - ${
      isApplyActive ? HEADER_HEIGHT + APPLY_EVALUATION_RULES_HEADER_CONTROLS : HEADER_HEIGHT
    }px)`};
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-left: 1px solid ${({ theme }) => theme.colors.primary[30]};
  padding: 24px 16px;
  transition: height 0.3s ease-in-out;
`;

export const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TemplateSideMenu = () => {
  const { colors } = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [areExpanded, setAreExpanded] = useState(false);
  const [surveyTemplateData, setSurveyTemplateData] = useAtom(surveyTemplatesDataState);
  const [, setFilteredSurveyTemplateData] = useAtom(filteredSurveyTemplatesDataState);
  const [isApplyingModeActive] = useAtom(isApplyingEvaluationRulesModeActiveState);

  const expandCollapsLabel = areExpanded ? 'Collapse All' : 'Expand All';

  useEffect(() => {
    if (!searchQuery) {
      setFilteredSurveyTemplateData(surveyTemplateData);
      return;
    }
    //search template data by section name and question name
    // if there is a match in section name keep whole section with all questions and don't filter questions
    // if there is no match in section name filter questions by question name
    const filteredData = surveyTemplateData.map((category) => {
      const newSections: SurveyTemplateSection[] = [];
      for (let i = 0; i < category.sections.length; i++) {
        const section = category.sections[i];
        if (section.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          newSections.push(section);
          continue;
        }
        const newQuestions = section.questions.filter((question) => {
          return question.name.toLowerCase().includes(searchQuery.toLowerCase());
        });
        if (newQuestions.length > 0) {
          newSections.push({ ...section, questions: newQuestions });
        }
      }
      return { ...category, sections: newSections };
    });
    //remove categories with no sections
    const filteredDataWithSections = filteredData.filter((category) => {
      return category.sections.length > 0;
    });
    setFilteredSurveyTemplateData(filteredDataWithSections);
  }, [searchQuery, setFilteredSurveyTemplateData, surveyTemplateData]);

  const onExpandAll = useCallback(() => {
    setSurveyTemplateData((prevCategories) => {
      return prevCategories.map((category) => {
        return {
          ...category,
          sections: category.sections.map((section) => {
            return {
              ...section,
              isExpanded: !areExpanded,
            };
          }),
        };
      });
    });
    setAreExpanded((prev) => !prev);
  }, [areExpanded, setSurveyTemplateData]);

  return (
    <Wrapper isApplyActive={isApplyingModeActive}>
      <TitleWrapper>
        <Typography variant='h4' color={colors.primary[90]}>
          Question Templates
        </Typography>
        <Button variant='text' onClick={onExpandAll}>
          <Typography variant='subtitle1' color={colors.accent[50]}>
            {expandCollapsLabel}
          </Typography>
        </Button>
      </TitleWrapper>
      <SearchInput
        placeholder='Search for a question, category, etc'
        style={{ marginBottom: '16px' }}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <TemplateCategoryList />
    </Wrapper>
  );
};
