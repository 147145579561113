import {
  Autocomplete,
  Checkbox,
  TextField,
  PopperProps,
  AutocompleteRenderOptionState,
  ChipProps,
  SxProps,
  FilterOptionsState,
  useTheme,
  AutocompleteRenderGroupParams,
} from '@mui/material';
import {
  FC,
  SyntheticEvent,
  ReactNode,
  JSXElementConstructor,
  HTMLAttributes,
  CSSProperties,
  useCallback,
  useMemo,
} from 'react';
import { ReactComponent as ClearIcon } from '../../assets/icons/close-gray.svg';
import styled from '@mui/material/styles/styled';
import { SelectItem } from '../../types';
import { Typography } from '../Typography/Typography';

export interface MultiselectProps {
  options: SelectItem[];
  selectedTextMaxWidth?: string;
  value: SelectItem[];
  hideClearIcon?: boolean;
  onChange: (e: SyntheticEvent, value: SelectItem[] | undefined, reason: string) => void;
  width?: string;
  fullWidth?: boolean;
  limitTags?: number;
  style?: CSSProperties;
  labelTypographyStyle?: CSSProperties;
  generateLabel?: (number: number) => string;
  renderTags?: (values: SelectItem[]) => JSX.Element;
  fieldPlaceholder?: string;
  openOnfocus?: boolean;
  fieldName?: string; // for Formik
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disablePortal?: boolean;
  open?: boolean;
  optionName?: string;
  optionsName?: string;
  onOpen?: () => void;
  onClose?: () => void;
  inputVariant?: 'outlined' | 'filled' | 'standard';
  getLimitTagsText?: (more: number) => ReactNode;
  PopperComponent?: JSXElementConstructor<PopperProps>;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: SelectItem,
    state: AutocompleteRenderOptionState
  ) => ReactNode;
  openOnFocus?: boolean;
  autofocus?: boolean;
  autocomplete?: boolean;
  componentsProps?: {
    clearIndicator?: object;
    paper?: object;
    popper?: object;
    popupIndicator?: object;
  };
  ChipProps?: ChipProps;
  sx?: SxProps;
  isOptionEqualToValue?: (option: SelectItem, value: SelectItem) => boolean;
  ListboxProps?: HTMLAttributes<HTMLUListElement>;
  inputValue?: string;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: string) => void;
  autoSelect?: boolean;
  disableClearable?: boolean;
  filterOptions?: (options: SelectItem[], state: FilterOptionsState<SelectItem>) => SelectItem[];
  onKeyDown?: (e: React.KeyboardEvent) => void;
  alwaysShowPlaceholder?: boolean;
  onBlurCapture?: React.FocusEventHandler<HTMLDivElement> | undefined;
  renderGroup?: ((params: AutocompleteRenderGroupParams) => ReactNode) | undefined;
  groupBy?: ((option: SelectItem) => string) | undefined;
  noPaddingOnOption?: boolean;
  isDisabled?: boolean;
}

const StyleContainer = styled('div')`
  & .MuiAutocomplete-popup {
    z-index: 999999 !important;
  }

  &&& .MuiInputBase-root.Mui-focused {
    z-index: 1000;
    p {
      color: ${({ theme }) => theme.colors.accent[50]};
    }
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 0 0 9px;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 6px 4px 6px 6px;
  }
  .MuiAutocomplete-listbox {
    padding: 4px 8px;
  }
  .MuiFormControl-root.MuiTextField-root:hover .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    visibility: visible;
  }
  .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    visibility: hidden;
  }
`;

export const StyledTag = styled('span')`
  display: flex;
  align-items: baseline;
  padding: 0;
  padding-left: 0.5rem;
  font-size: 0.875rem;
`;

const SelectedText = styled('div')<{ maxWidth?: string }>`
  display: inline-block;
  max-width: ${({ maxWidth }) => maxWidth || '6rem'};
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const OptionWrapper = styled('li')`
  &&&.MuiAutocomplete-option {
    padding: 6px 6px 6px 0;
    height: 32px;
    margin: 4px 0;
    border-radius: 4px;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
const TitleOptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const TOGGLE_ALL_NONE_ID = -1;

export const Multiselect: FC<MultiselectProps> = (props) => {
  const {
    fieldPlaceholder,
    alwaysShowPlaceholder: showPlaceholder,
    fieldName,
    sx,
    selectedTextMaxWidth,
    generateLabel,
    inputVariant = 'outlined',
    autofocus = false,
    openOnfocus = true,
    optionName,
    optionsName,
    renderTags,
    options,
    onChange,
    hideClearIcon = false,
    labelTypographyStyle,
    noPaddingOnOption,
    isDisabled,
    ...rest
  } = props;
  const { colors } = useTheme();
  const allSelected = props.value?.length === props.options.length;

  const toggleAllNoneOption = useMemo(
    () => ({
      id: TOGGLE_ALL_NONE_ID,
      value: allSelected ? 'Deselect All' : 'Select All',
    }),
    [allSelected]
  );
  const optionsPlusToggleOption = [toggleAllNoneOption, ...options];

  const renderDefaultTags = useCallback(
    (options: SelectItem[]) => {
      if (!options?.length) return <></>;

      let text;
      if (allSelected)
        text = optionsName
          ? `All ${optionsName}`
          : optionName
          ? `All ${optionName}s`
          : 'All Selected';
      else if (options.length === 1) {
        text = options[0].value;
      } else {
        text = `${options.length} ${
          options.length > 1
            ? optionsName
              ? optionsName
              : optionName
              ? `${optionName}s`
              : 'options'
            : optionName
            ? optionName
            : 'option'
        }`;
      }

      return (
        <StyledTag>
          <SelectedText maxWidth={selectedTextMaxWidth}>
            <Typography variant='body' color={colors.primary[90]} style={labelTypographyStyle}>
              {generateLabel ? generateLabel(options.length) : text}
            </Typography>
          </SelectedText>
        </StyledTag>
      );
    },
    [
      allSelected,
      colors.primary,
      generateLabel,
      labelTypographyStyle,
      optionName,
      optionsName,
      selectedTextMaxWidth,
    ]
  );

  const handleChange = useCallback(
    (e: SyntheticEvent, onChangeOptions: SelectItem[] | undefined, reason: string) => {
      if (onChangeOptions?.find((opt) => opt.id === TOGGLE_ALL_NONE_ID)) {
        if (allSelected) onChange(e, [], reason);
        else onChange(e, options, reason);
      } else {
        onChange(e, onChangeOptions, reason);
      }
    },
    [allSelected, onChange, options]
  );

  return (
    <StyleContainer>
      <Autocomplete
        clearIcon={!hideClearIcon ? <ClearIcon /> : null}
        autoHighlight
        renderTags={renderTags ? renderTags : renderDefaultTags}
        getOptionLabel={function (option): string {
          return option?.value ?? '';
        }}
        disableCloseOnSelect
        disabled={isDisabled}
        openOnFocus={openOnfocus}
        options={optionsPlusToggleOption}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            autoFocus={autofocus}
            {...params}
            fullWidth
            variant={inputVariant}
            name={fieldName}
            placeholder={props.value?.length && !showPlaceholder ? '' : fieldPlaceholder}
            onKeyDown={props.onKeyDown}
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <OptionWrapper
              style={!rest.disablePortal && !noPaddingOnOption ? { padding: '12px' } : {}}
              {...props}
              key={option.id}
            >
              {(option.id as number) >= 0 && <Checkbox checked={selected} />}
              <TitleOptionWrapper>
                {option?.icon && option?.icon}
                <Typography variant='body' color={colors.primary[90]}>
                  {option?.value ?? ''}
                </Typography>
              </TitleOptionWrapper>
            </OptionWrapper>
          );
        }}
        disablePortal
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        multiple
        ChipProps={props.ChipProps ?? { size: 'small', sx: { margin: 0 } }}
        sx={{
          "& button[title='Clear'], & button[title='Open'], & button[title='Close']": {
            visibility: 'visible',
            color: colors.primary[60],
          },
          '& .MuiAutocomplete-tag': {
            maxHeight: '21px',
          },
          ...sx,
        }}
        {...rest}
      />
    </StyleContainer>
  );
};
