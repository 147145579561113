import { Button, styled, useTheme } from '@mui/material';
import {
  ACTION_BAR_HEIGHT,
  SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH,
} from '../../../constants/layoutSizes';
import { ActionButtonsFooter } from '../../../components/ActionButtonsFooter/ActionButtonsFooter';
import { useCallback, useState } from 'react';
import { useAtom } from 'jotai';
import { activeReportState } from '../../../state/UIState';
import {
  LoadingId,
  REPORT_STATUS,
} from '../../../types';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { useUpdateReportWithToken } from '../../../queries/useReports';
import { useNavigate } from 'react-router';
import { useIsSubmitDisabled } from './hooks/useIsSubmitDisabled';

const ActionsWrapper = styled('div')`
  margin-left: ${SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH}px;
  position: relative;
  height: ${ACTION_BAR_HEIGHT}px;
`;

export const FooterButtons = () => {
  const [activeReport] = useAtom(activeReportState);
  const [isLoading, setIsLoading] = useState(false);
  const { startLoading, stopLoading } = useLoadingBar();
  const navigate = useNavigate();
  const { colors } = useTheme();

  const updateReportWithToken = useUpdateReportWithToken();

  const onSubmit = useCallback(async () => {
    if (!activeReport) return;
    setIsLoading(true);
    startLoading(LoadingId.completeReport);

    await updateReportWithToken.mutateAsync(
      { status: REPORT_STATUS.IN_REVIEW },
      {
        onSuccess: () => {
          navigate(`/esg-portal/done`);
        },
        onSettled: () => {
          setIsLoading(false);
          stopLoading(LoadingId.completeReport);
        },
      }
    );

    // await sendEvaluationInvites.mutateAsync(activeReport.id);
  }, [activeReport, startLoading, updateReportWithToken, navigate, stopLoading]);

  const isSubmitDisabled = useIsSubmitDisabled();

  return (
    <ActionsWrapper>
      <ActionButtonsFooter bgColor={colors.primary[0]}>
        <Button
          variant='contained'
          onClick={onSubmit}
          disabled={isLoading || isSubmitDisabled}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Submit Report
        </Button>
      </ActionButtonsFooter>
    </ActionsWrapper>
  );
};
