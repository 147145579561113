import { styled } from '@mui/material/styles';
import { ForwardedRef, ReactNode, CSSProperties, ReactElement } from 'react';
import React from 'react';
import { theme } from '../../theme';

interface Props {
  variant: keyof typeof variants;
  color: string;
  children: ReactNode | string;
  as?: keyof HTMLElementTagNameMap;
  className?: string;
  testId?: string;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => void;
}

const { size, lineHeight, weight, letterSpace, font } = theme.text;

export const variants = {
  h1: {
    fontSize: size.extraExtraLarge,
    lineHeight: lineHeight.extraExtraLarge,
    fontWeight: weight.medium,
    letterSpacing: letterSpace.regular,
    fontFamily: font.fontFamilyMedium,
  },
  h2: {
    fontSize: size.extraLarge,
    lineHeight: lineHeight.extraLarge,
    fontWeight: weight.regular,
    letterSpacing: letterSpace.regular,
  },
  h3: {
    fontSize: size.large,
    lineHeight: lineHeight.large,
    fontWeight: weight.medium,
    letterSpacing: letterSpace.regular,
    fontFamily: font.fontFamilyMedium,
  },
  h4: {
    fontSize: size.mediumLarge,
    lineHeight: lineHeight.mediumLarge,
    fontWeight: weight.medium,
    letterSpacing: letterSpace.regular,
    fontFamily: font.fontFamilyMedium,
  },
  h5: {
    fontSize: size.medium,
    lineHeight: lineHeight.medium,
    fontWeight: weight.medium,
    letterSpacing: letterSpace.regular,
    fontFamily: font.fontFamilyMedium,
  },
  subtitle1: {
    fontSize: size.mediumSmall,
    lineHeight: lineHeight.medium,
    fontWeight: weight.regular,
    letterSpacing: letterSpace.regular,
  },
  subtitle2: {
    fontSize: size.mediumSmall,
    lineHeight: lineHeight.mediumSmall,
    fontWeight: weight.medium,
    letterSpacing: letterSpace.large,
    fontFamily: font.fontFamilyMedium,
  },
  body: {
    fontSize: size.mediumSmall,
    lineHeight: lineHeight.mediumSmall,
    fontWeight: weight.regular,
    letterSpacing: letterSpace.regular,
  },
  caption: {
    fontSize: size.small,
    lineHeight: lineHeight.small,
    fontWeight: weight.regular,
    letterSpacing: letterSpace.regular,
  },
  srOnly: {
    fontSize: size.small,
    lineHeight: lineHeight.small,
    fontWeight: weight.medium,
    letterSpacing: letterSpace.regular,
    fontFamily: font.fontFamilyMedium,
  },
  overline: {
    fontSize: size.extraSmall,
    lineHeight: lineHeight.small,
    fontWeight: weight.semiBold,
    letterSpacing: letterSpace.regular,
    fontFamily: font.fontFamilySemiBold,
  },
  tabLabel: {
    fontSize: size.small,
    lineHeight: lineHeight.small,
    fontWeight: weight.semiBold,
    letterSpacing: letterSpace.regular,
    fontFamily: font.fontFamilySemiBold,
  },
  numbers: {
    fontSize: size.mediumSmall,
    lineHeight: lineHeight.mediumSmall,
    fontWeight: weight.regular,
    letterSpacing: letterSpace.regular,
    fontFamily: font.fontInconsolataRegular,
  },
};

const StyledTypography = styled('p')<Props>`
  color: ${({ color }) => color};
  ${({ variant }) => variants[variant]}
`;
interface PropsWithRef extends Props {
  ref: ForwardedRef<HTMLParagraphElement | null>;
}
const StyledTypographyWithRef = styled(StyledTypography)<PropsWithRef>``;

// eslint-disable-next-line react/display-name
export const TypographyWithRef = React.forwardRef<HTMLParagraphElement | null, Props>(
  (props, ref) => {
    return (
      <StyledTypographyWithRef as='div' variant={props.variant} color={props.color} ref={ref}>
        {props.children}
      </StyledTypographyWithRef>
    );
  }
);

export function Typography({
  variant,
  color,
  children,
  as,
  testId = '',
  style,
  onClick,
}: Props): ReactElement {
  return (
    <StyledTypography
      onClick={onClick}
      variant={variant}
      color={color}
      as={as}
      data-testid={testId}
      style={style}
    >
      {children}
    </StyledTypography>
  );
}
