import { styled, useTheme } from '@mui/material';
import { WorkflowStep } from '../../../../types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ReactComponent as DragIcon } from '../../../../assets/icons/drag.svg';
import { Typography } from '../../../../components/Typography/Typography';
import { CardContent } from './CardContent/CardContent';
import { WorkflowStepPayload } from '../../../../queries/useWorkflowSteps';
import { StepActionMenu } from './StepActionMenu';

const DragWrapper = styled('div')`
  position: relative;
  padding: 0 25px;
`;

const SortableWrapper = styled('div')``;

const CardContainer = styled('div')<{ isActive: boolean }>`
  background-color: #fff;
  padding: 16px 16px 16px 20px;
  border-radius: 4px;
  border-left: ${({ theme, isActive }) =>
    `4px solid ${isActive ? theme.colors.accent[30] : theme.colors.primary[30]}`};
  cursor: pointer;
`;

const CardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  step: WorkflowStep;
  activeStep: WorkflowStep | null;
  onCardClick: () => void;
  onUpdateStep: (id: number | string, stepPayload: Partial<WorkflowStepPayload>) => void;
  onDeleteStep: (id: number | string) => void;
}

export const StepCard = ({ step, activeStep, onCardClick, onUpdateStep, onDeleteStep }: Props) => {
  const [showDragIcon, setShowDragIcon] = useState(false);

  const { colors } = useTheme();

  const { setNodeRef, transform, transition, attributes, listeners, isDragging } =
    useSortable(step);
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const wrapperRef = useRef<null | HTMLDivElement>(null);

  const onMouseEnter = useCallback(() => {
    if (activeStep?.id === step.id) {
      return;
    }
    setShowDragIcon(true);
  }, [activeStep?.id, step.id]);

  const onMouseLeave = useCallback(() => {
    if (activeStep?.id === step.id) {
      return;
    }
    setShowDragIcon(false);
  }, [activeStep?.id, step.id]);

  const isActive = useMemo(() => activeStep?.id === step?.id, [activeStep?.id, step?.id]);

  return (
    <SortableWrapper
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ ...style, opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 2 : 1 }}
    >
      <DragWrapper ref={wrapperRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {showDragIcon && (
          <DragIcon style={{ position: 'absolute', left: '7px', top: '5', cursor: 'pointer' }} />
        )}
        <CardContainer isActive={isActive}>
          <CardHeader onClick={onCardClick}>
            <Typography variant='subtitle2' color={colors.primary[90]}>
              {step.name}
            </Typography>
            <StepActionMenu onDeleteStep={onDeleteStep} step={step} />
          </CardHeader>
          {isActive && !isDragging && <CardContent step={step} onUpdateStep={onUpdateStep} />}
        </CardContainer>
      </DragWrapper>
    </SortableWrapper>
  );
};
