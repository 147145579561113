import ContentLoader from 'react-content-loader';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 580px;
`;

const MainContentLoader = styled('div')`
  display: flex;
  flex-grow: 1;
  padding: 16px 32px;
`;

const ActionButtonsLoader = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px 32px;
  gap: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary[30]};
`;

export function RequestGroupEditModalSkeletonLoader(): ReactElement {
  return (
    <SkeletonWrapper>
      <MainContentLoader>
        <ContentLoader height={464} width={'100%'}>
          <rect width='110' height='28' fill='#EEEFF1' />
          <rect width='100%' height='68' y='58' fill='#EEEFF1' />

          <rect width='100' height='14' y='140' fill='#EEEFF1' />
          <rect width='100%' height='32' y='158' fill='#EEEFF1' />

          <rect width='100' height='14' y='206' fill='#EEEFF1' />
          <rect width='100%' height='32' y='224' fill='#EEEFF1' />

          <rect width='100' height='14' y='272' fill='#EEEFF1' />
          <rect width='100%' height='32' y='290' fill='#EEEFF1' />

          <rect width='100' height='14' y='338' fill='#EEEFF1' />
          <rect width='100%' height='32' y='356' fill='#EEEFF1' />
        </ContentLoader>
      </MainContentLoader>
      <ActionButtonsLoader>
        <ContentLoader height={36} width={'100%'}>
          <rect width='100%' height='36' rx='4' fill='#EEEFF1' />
        </ContentLoader>
        <ContentLoader height={36} width={'100%'}>
          <rect width='100%' height='36' rx='4' fill='#EEEFF1' />
        </ContentLoader>
      </ActionButtonsLoader>
    </SkeletonWrapper>
  );
}
