import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { ExternallyExclusionsSavePayload } from '../types';
import { useMutation } from 'react-query';
import { useUpdateCompany } from './useCompanies';

async function saveExclusionsExternally(
  token: string | undefined,
  payload: ExternallyExclusionsSavePayload
) {
  if (!token) {
    return;
  }
  const { data } = await axios.post(
    `/IssuerExclusions`,
    {
      ...payload,
    },
    {
      baseURL: process.env.REACT_APP_SVP_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SVP_API_SUBSCRIPTION_KEY,
      },
    }
  );

  return data.data;
}

export const useExternallySaveExclusions = (companyId: number) => {
  const auth = useOktaAuth();
  const token = auth.authState?.accessToken?.accessToken;
  const updateCompany = useUpdateCompany();

  return useMutation(
    (payload: ExternallyExclusionsSavePayload) => saveExclusionsExternally(token, payload),
    {
      onError: (error) => {
        console.error({ error });
      },
      onSuccess: () => {
        updateCompany.mutate({ id: companyId, areExclusionsExternallySaved: true });
      },
    }
  );
};
