import { Modal } from '../../../../components/Modal/Modal';
import { FormField } from '../../../../components/FormField/FormField';
import { useCallback } from 'react';
import { Textarea } from '../../../../components/Textarea/Textarea';
import { useParams } from 'react-router';
import { CompanyPayload, useCompanyById, useUpdateCompany } from '../../../../queries/useCompanies';
import { useFormik } from 'formik';
import { AnalystRatingSchema } from './AnalystRatingSchema';
import { ESGRiskLevelSelector } from '../ESGRiskLevelSelector';
import { ESGOpportunitiesSelector } from '../ESGOpportunitiesSelector';
import { useAtomValue } from 'jotai';
import { userState } from '../../../../state/UIState';
import { COMPANY_STAGE } from '../../../../types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  confirmCTAText?: string;
  onSubmit?: () => void;
}

export const AnalystRatingModal = ({ isOpen, onClose, title, confirmCTAText, onSubmit }: Props) => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const updateCompany = useUpdateCompany();

  const user = useAtomValue(userState);

  const { handleChange, values, setFieldValue, isValid, handleSubmit, dirty } = useFormik<
    Partial<CompanyPayload>
  >({
    initialValues: {
      esgRiskLevel: company?.esgRiskLevel,
      esgOpportunitiesLevel: company?.esgOpportunitiesLevel,
      analystRatingNotes: company?.analystRatingNotes || '',
    },
    validationSchema: AnalystRatingSchema,
    onSubmit: async (values) => {
      await updateCompany.mutateAsync({
        id: company?.id,
        esgRiskLevel: values.esgRiskLevel,
        esgOpportunitiesLevel: values.esgOpportunitiesLevel,
        analystRatingNotes: values.analystRatingNotes,
        analystRatingReview: null,
        analyst: company?.stage === COMPANY_STAGE.SCREENING ? user?.fullName : company?.analyst,
      });
      onSubmit?.();
      onClose();
    },
  });

  const onChangeEsgRiskLevel = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('esgRiskLevel', '');
        return;
      }
      setFieldValue('esgRiskLevel', option?.id);
    },
    [setFieldValue]
  );
  const onChangeEsgOpportunitiesLevel = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('esgOpportunitiesLevel', '');
        return;
      }
      setFieldValue('esgOpportunitiesLevel', option?.id);
    },
    [setFieldValue]
  );

  return (
    <Modal
      isOpen={isOpen}
      title={title || 'Analyst Rating'}
      onClose={onClose}
      onConfirm={() => handleSubmit()}
      confirmBtnText={confirmCTAText || 'Submit Form'}
      cancelBtnText='Cancel'
      isConfirmDisabled={!isValid || !dirty}
      contentContainerStyle={{ padding: '16px 32px 32px 32px' }}
    >
      <ESGRiskLevelSelector
        esgRiskLevel={values.esgRiskLevel}
        onChangeEsgRiskLevel={onChangeEsgRiskLevel}
      />
      <ESGOpportunitiesSelector
        esgOpportunitiesLevel={values.esgOpportunitiesLevel}
        onChangeEsgOpportunitiesLevel={onChangeEsgOpportunitiesLevel}
      />

      <FormField label='Notes'>
        <Textarea
          maxLength={300}
          value={values?.analystRatingNotes || ''}
          name='analystRatingNotes'
          onChange={handleChange}
          style={{ height: 120 }}
        />
      </FormField>
    </Modal>
  );
};
