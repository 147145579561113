import { styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { CompaniesLastUpdate } from '../CompaniesLastUpdate/CompaniesLastUpdate';

const HeaderWrapper = styled('div')`
  padding: 24px 40px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 28px;
`;

interface Props {
  title: string;
}

export const PortfolioHeader = ({ title }: Props) => {
  const { colors } = useTheme();

  return (
    <HeaderWrapper>
      <TitleWrapper>
        <Typography variant='h3' color={colors.primary[90]}>
          {title}
        </Typography>
      </TitleWrapper>
      <CompaniesLastUpdate />
    </HeaderWrapper>
  );
};
