import { styled } from '@mui/material';
import { useParams } from 'react-router';
import { ColumnsStep } from './ColumnsStep';
import { CompaniesStep } from './CompaniesStep';
import {
  EXPORT_COMPANIES_PARAMS,
  EXPORT_COMPANIES_TYPE,
  ExportCompanyColumn,
  ExportCompanyExclusionsColumn,
} from '../../../types';
import { useAtom } from 'jotai';
import { exportColumnsState, exportCompaniesState, exportTypeState } from '../../../state/UIState';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useEffect, useMemo } from 'react';
import { companyColumns, exclusionColumns } from './companyColumns';
import { useCompanies } from '../../../queries/useCompanies';

const Wrapper = styled('div')`
  padding: 24px 40px;
  height: 100%;
  overflow: auto;
`;

export const StepContent = () => {
  const params = useParams();
  const activeStep = Number(params.activeStep);
  const { data: companies } = useCompanies();

  const { params: queryParams } = useQueryParams();

  const columnsFromParams = queryParams[EXPORT_COMPANIES_PARAMS.COLUMNS] as string[];
  const companyIds = queryParams[EXPORT_COMPANIES_PARAMS.COMPANY_IDS] as string[];
  const exportTypeFromParams = queryParams[
    EXPORT_COMPANIES_PARAMS.EXPORT_TYPE
  ] as EXPORT_COMPANIES_TYPE;

  const [, setSelectedColumns] = useAtom(exportColumnsState);
  const [, setSelectedCompanies] = useAtom(exportCompaniesState);
  const [, setSelectedType] = useAtom(exportTypeState);

  const columnsToUse: (ExportCompanyColumn | ExportCompanyExclusionsColumn)[] = useMemo(() => {
    if (exportTypeFromParams === EXPORT_COMPANIES_TYPE.companyDetails) return companyColumns;
    if (exportTypeFromParams === EXPORT_COMPANIES_TYPE.companyExclusions) return exclusionColumns;

    return [];
  }, [exportTypeFromParams]);

  useEffect(() => {
    const columns = columnsToUse?.filter((column) => columnsFromParams?.includes(column.value));
    setSelectedColumns(columns?.length ? columns : columnsToUse);

    const companiesFromParams = companies?.filter((company) =>
      companyIds?.includes?.(String(company.id))
    );
    setSelectedCompanies(companiesFromParams || []);
    setSelectedType(exportTypeFromParams || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  return (
    <Wrapper>
      {activeStep === 1 && <ColumnsStep />}
      {activeStep === 2 && <CompaniesStep />}
    </Wrapper>
  );
};
