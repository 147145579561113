import { IconButton, styled, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useLayoutEffect, useRef, useState } from 'react';
import { Typography } from '../../Typography/Typography';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';

const Wrapper = styled('div')<{ isTruncated: boolean }>`
  display: flex;

  & > div > p {
    white-space: ${({ isTruncated }) => (isTruncated ? 'normal' : 'wrap')};
    overflow: ${({ isTruncated }) => (isTruncated ? 'hidden' : 'visible')};
    text-overflow: ${({ isTruncated }) => (isTruncated ? 'ellipsis' : 'unset')};
    display: ${({ isTruncated }) => (isTruncated ? '-webkit-box' : 'inline')};
    -webkit-line-clamp: ${({ isTruncated }) => (isTruncated ? 4 : 'none')};
    -webkit-box-orient: ${({ isTruncated }) => (isTruncated ? 'vertical' : 'unset')};
    line-height: 20px;
    width: 100%;
  }
`;
const TextWrapper = styled('div')<{ isTruncated: boolean; withActionButtons?: boolean }>`
  width: ${({ withActionButtons }) => (withActionButtons ? '228px' : '100%')};
  height: ${({ isTruncated }) => (isTruncated ? '80px' : 'auto')};
`;

const ActionButtonsWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const ActionButton = styled(IconButton)<{ isDisabled?: boolean }>`
  padding: 0;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

interface Props {
  notes: string;
  withActionButtons?: boolean;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  isDisabled?: boolean;
}

export const PreviewNote = ({
  notes,
  withActionButtons,
  setIsDeleteModalOpen,
  setIsEditMode,
  isDisabled,
}: Props) => {
  const { colors } = useTheme();

  const ref = useRef<HTMLDivElement>(null);

  const [isTruncated, setIsTruncated] = useState(false);
  const [hasTruncatedText, setHasTruncatedText] = useState(false);

  useLayoutEffect(() => {
    const { offsetHeight, scrollHeight } = ref.current || {};

    if (offsetHeight && scrollHeight && scrollHeight > 80) {
      setIsTruncated(true);
      setHasTruncatedText(true);
    } else {
      setIsTruncated(false);
      setHasTruncatedText(false);
    }
  }, [ref, notes]);

  return (
    <>
      <Wrapper isTruncated={isTruncated}>
        <TextWrapper ref={ref} isTruncated={isTruncated} withActionButtons={withActionButtons}>
          <Typography variant='subtitle1' color={colors.primary[90]}>
            {notes}
          </Typography>
        </TextWrapper>
        {withActionButtons && (
          <ActionButtonsWrapper>
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();
                if (isDisabled) return;
                setIsEditMode(true);
              }}
              isDisabled={isDisabled}
            >
              <EditIcon />
            </ActionButton>
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();
                if (isDisabled) return;
                setIsDeleteModalOpen(true);
              }}
              isDisabled={isDisabled}
            >
              <DeleteIcon />
            </ActionButton>
          </ActionButtonsWrapper>
        )}
      </Wrapper>
      {hasTruncatedText && (
        <Typography
          variant='srOnly'
          color={colors.accent[50]}
          style={{ marginTop: '8px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            setIsTruncated((prev) => !prev);
          }}
        >
          {isTruncated ? 'Show more' : 'Show less'}
        </Typography>
      )}
    </>
  );
};
