import { Button, styled, useTheme } from '@mui/material';
import { FC, useCallback } from 'react';
import { SelectItem } from '../../types';

type Props = {
  options: SelectItem[];
  selectedItems: SelectItem[];
  onSelect: (items: SelectItem[]) => void;
  chipHeight?: string;
};

const Wrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

const ButtonChip = styled('div')<{ isSelected?: boolean }>`
  .MuiButton-outlined {
    border: 1px solid
      ${({ theme, isSelected }) =>
        isSelected ? theme.colors.accent[50] : theme.colors.primary[30]};
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.accent[50] : theme.colors.primary[90]};
  }
  .MuiButton-outlined:hover {
    border: 1px solid ${({ theme }) => theme.colors.accent[50]};
    color: ${({ theme }) => theme.colors.accent[50]};
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.accent[5] : 'transparent'};
  }
`;

export const ChipsSelectorMultiselect: FC<Props> = ({
  options,
  selectedItems,
  onSelect,
  chipHeight,
}) => {
  const { accent } = useTheme().colors;
  const isSelected = useCallback(
    (item: SelectItem) => selectedItems.some((i) => i.id === item.id),
    [selectedItems]
  );
  const onSelectItem = useCallback(
    (item: SelectItem) => {
      if (isSelected(item)) {
        onSelect(selectedItems.filter((i) => i.id !== item.id));
      } else {
        onSelect([...selectedItems, item]);
      }
    },
    [selectedItems, onSelect, isSelected]
  );

  return (
    <Wrapper>
      {options.map((o) => (
        <ButtonChip key={o.id} isSelected={isSelected(o)}>
          <Button
            startIcon={o.icon ? o.icon : null}
            variant='outlined'
            onClick={() => onSelectItem(o)}
            style={{
              backgroundColor: isSelected(o) ? accent[5] : 'transparent',
              height: chipHeight ? chipHeight : 'auto',
            }}
          >
            {o.value}
          </Button>
        </ButtonChip>
      ))}
    </Wrapper>
  );
};
