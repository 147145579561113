import { useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';

interface Props {
  assignedUser: string;
}

export const InfoContent = ({ assignedUser }: Props) => {
  const { colors } = useTheme();
  return (
    <Typography variant='body' color={colors.accent[60]}>
      <Typography variant='subtitle2' color={colors.accent[60]} as={'span'}>
        {assignedUser}{' '}
      </Typography>
      access will be removed.
    </Typography>
  );
};
