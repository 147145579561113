import { Chip, styled, useTheme } from '@mui/material';
import { ReactComponent as UnassignedIcon } from '../../../assets/icons/close-gray-circle-small.svg';
import { ReactComponent as AssignedIcon } from '../../../assets/icons/checked-blue-round.svg';
import { ReactElement, useCallback, useMemo } from 'react';
import { Typography } from '../../Typography/Typography';

interface Props {
  isAssigned: boolean;
  chipStyle?: React.CSSProperties;
}

const Wrapper = styled('div')`
  display: flex;
  gap: 4px;
`;

export const SurveyStatusCell = ({ isAssigned, chipStyle }: Props) => {
  const { colors } = useTheme();

  const renderIcon = useCallback(() => {
    switch (isAssigned) {
      case true:
        return <AssignedIcon style={{ marginRight: '4px' }} />;
      case false:
        return <UnassignedIcon style={{ marginRight: '4px' }} />;
      default:
        return null;
    }
  }, [isAssigned]);

  const bgColor = useMemo(() => {
    switch (isAssigned) {
      case true:
        return colors.accent[1];
      case false:
        return colors.gray[20];
    }
  }, [colors, isAssigned]);
  return (
    <Wrapper>
      <Chip
        icon={renderIcon() as ReactElement}
        style={{
          backgroundColor: bgColor,
          ...chipStyle,
        }}
        label={
          <Typography variant='body' color={colors.primary[90]}>
            {isAssigned ? 'Assigned' : 'Unassigned'}
          </Typography>
        }
      />
    </Wrapper>
  );
};
