import { AssignedCellContent } from './AssignedCellContent';
import { UnassignedCellContent } from './UnassignedCellContent';

interface Props {
  isAssigned?: boolean;
  onOpenModal: () => void;
  onOpenEditModal: () => void;
  assignedEmail: string;
  unassignedEmailText: string;
  onOpenDeleteModal: () => void;
}

export const AssignEmailCellContent = ({
  isAssigned,
  onOpenModal,
  onOpenEditModal,
  assignedEmail,
  unassignedEmailText,
  onOpenDeleteModal,
}: Props) => {
  if (isAssigned) {
    return (
      <AssignedCellContent
        onOpenEditModal={onOpenEditModal}
        text={assignedEmail}
        onOpenDeleteModal={onOpenDeleteModal}
      />
    );
  }

  return <UnassignedCellContent onOpenModal={onOpenModal} text={unassignedEmailText} />;
};
