import { styled, useTheme } from '@mui/material';
import { ReactComponent as HighGreen } from '../../../assets/icons/high-green.svg';
import { ReactComponent as LowRed } from '../../../assets/icons/low-red.svg';
import { Typography } from '../../../components/Typography/Typography';
import { getGovernanceScoreLabel, getIsGoodScore } from '../../../utils/governanceScoreUtils';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  governanceScore?: number | null;
}

export const GovernanceScore = ({ governanceScore }: Props) => {
  const { colors } = useTheme();

  const isGoodScore = getIsGoodScore(governanceScore);

  const label = getGovernanceScoreLabel(governanceScore);

  const Icon = isGoodScore ? HighGreen : LowRed;

  return (
    <Wrapper>
      <Icon />
      <Typography variant='h3' color={colors.primary[90]}>
        {label}
      </Typography>
    </Wrapper>
  );
};
