import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../../../../../../components/Typography/Typography';
import { EditableTextareaField } from '../../../../../../../../../../components/EditableTextField/EditableTextareaField';
import { useCallback, useState } from 'react';
import { SurveyQuestion, QuestionOption } from '../../../../../../../../../../types';
import { useUpdateSubQuestion } from '../hooks/useUpdateSubquestion';

const NameContainer = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 4px;
`;

interface Props {
  questionNum: number;
  order: number;
  subQuestion: SurveyQuestion;
  option: QuestionOption;
}

export const SubQuestionTitle = ({ questionNum, order, subQuestion, option }: Props) => {
  const { colors } = useTheme();

  const [subQuestionTitle, setSubQuestionTitle] = useState(subQuestion.name);

  const { updateSubQuestion } = useUpdateSubQuestion();

  const onQuestionRename = useCallback(
    (value: string) => {
      if (value === subQuestion?.name || !value) {
        setSubQuestionTitle(subQuestion?.name || '');
        return;
      }
      if (!subQuestion) return;

      const updatedSubQuestion = {
        ...subQuestion,
        name: value,
      };

      updateSubQuestion(updatedSubQuestion, option);
    },
    [subQuestion, option, updateSubQuestion]
  );

  return (
    <NameContainer>
      <Typography as='span' variant='subtitle2' color={colors.primary[70]}>
        {`${questionNum}.${order}`}
      </Typography>
      <EditableTextareaField
        value={subQuestionTitle}
        onChange={(e) => setSubQuestionTitle(e.target.value)}
        onValueChanged={onQuestionRename}
        placeholder='Enter question'
      />
    </NameContainer>
  );
};
