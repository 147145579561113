import { useCallback, useMemo } from 'react';
import { StepperLayout } from '../../components/StepperLayout/StepperLayout';
import { Outlet, useNavigate, useParams } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { useAtom } from 'jotai';
import { exportCompaniesProgressState } from '../../state/UIState';
import { useQueryParams } from '../../hooks/useQueryParams';
import { ControlButtons } from './components/ControlButtons';
import { useResetExportCompaniesState } from './hooks/useResetExportCompaniesState';

export const ExportCompaniesStepper = () => {
  const navigate = useNavigate();
  const [progress] = useAtom(exportCompaniesProgressState);
  const params = useParams();
  const activeStep = Number(params.activeStep);
  const resetState = useResetExportCompaniesState();

  const { navigateAndPreserveParams } = useQueryParams();

  const steps = useMemo(() => {
    return [
      {
        step: 1,
        text: 'Columns',
        isClickable: true,
      },
      {
        step: 2,
        text: 'Companies',
        isClickable: false,
      },
    ];
  }, []);

  const onClick = useCallback(() => {
    resetState();
    navigate(`/${ROUTES.COMPANIES}`);
  }, [navigate, resetState]);

  const onStepClick = (step: number) => {
    navigateAndPreserveParams(`/${ROUTES.EXPORT_COMPANIES}/${step}`);
  };

  return (
    <StepperLayout
      onBackClick={onClick}
      headerTitle='Export Table'
      progressBarText='Complete all steps to export a table.'
      progress={progress}
      steps={steps}
      activeStep={activeStep}
      onClickStepNavigate={onStepClick}
      controlButtons={<ControlButtons />}
    >
      <Outlet />
    </StepperLayout>
  );
};
