import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';
import { Typography } from '../Typography/Typography';

interface FormFieldProps {
  children: ReactNode;
  label: string;
  error?: string;
  style?: CSSProperties;
  required?: boolean;
}

const FieldWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ChildrenWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Asterisk = styled('span')`
  color: ${({ theme }) => theme.colors.error[40]};
`;

export function FormField({ children, error, label, style, required }: FormFieldProps) {
  const { colors } = useTheme();

  return (
    <FieldWrapper data-testid={'form-field'} style={style}>
      <Typography as={'label'} variant={'srOnly'} color={colors.primary[90]} className='Label'>
        {label}
        {required && <Asterisk> *</Asterisk>}
      </Typography>

      <ChildrenWrapper>
        {children}
        {error && (
          <Typography variant={'caption'} color={colors.error[40]}>
            {error}
          </Typography>
        )}
      </ChildrenWrapper>
    </FieldWrapper>
  );
}
