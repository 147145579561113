import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { SearchInput } from '../../../../../components/SearchInput/SearchInput';
import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';
import { WorkflowStep } from '../../../../../types';

interface Props {
  steps: Partial<WorkflowStep>[];
  selectedStep: Partial<WorkflowStep> | null;
  setSelectedStep: Dispatch<SetStateAction<Partial<WorkflowStep> | null>>;
}

const Container = styled('div')`
  margin-top: 16px;
`;

const ListContainer = styled('div')`
  height: 176px;
  overflow: auto;
  margin-top: 12px;
`;

const ListItem = styled('div')<{ isActive?: boolean }>`
  background: ${({ theme, isActive }) => (isActive ? theme.colors.accent[5] : 'transparent')};
  padding: 5px 8px;
  margin-bottom: 4px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.accent[5]};
  }
`;

export const StepsList = ({ steps, selectedStep, setSelectedStep }: Props) => {
  const { colors } = useTheme();

  const [filterValue, setFilterValue] = useState<string>('');
  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = e.target;
      setFilterValue(value);
    },
    []
  );

  const stepsList = useMemo(() => {
    if (!filterValue) return steps;

    return steps?.filter((s) => s?.name?.toLowerCase()?.includes(filterValue?.toLowerCase()));
  }, [filterValue, steps]);

  const onSelectStep = useCallback(
    (step: Partial<WorkflowStep>) => {
      setSelectedStep(step);
    },
    [setSelectedStep]
  );

  return (
    <Container>
      <SearchInput
        placeholder='Search for a step'
        onChange={onFilterChange}
        value={filterValue}
        onClear={() => setFilterValue('')}
      />
      <ListContainer>
        {!stepsList?.length && (
          <Typography variant='body' color={colors.primary[90]}>
            No available steps
          </Typography>
        )}
        {stepsList?.map((step) => {
          return (
            <ListItem
              key={step.id}
              onClick={() => onSelectStep(step)}
              isActive={selectedStep?.id === step?.id}
            >
              <Typography variant='body' color={colors.primary[90]}>
                {step.name}
              </Typography>{' '}
            </ListItem>
          );
        })}
      </ListContainer>
    </Container>
  );
};
