import { styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface Props {
  groupName: string;
  children: React.ReactNode;
}

export const TaskGroup = ({ groupName, children }: Props) => {
  const { colors } = useTheme();
  return (
    <Container>
      <Typography variant='srOnly' color={colors.primary[70]} style={{ marginBottom: 16 , textTransform:'uppercase'}}>
        {groupName}
      </Typography>
      {children}
    </Container>
  );
};
