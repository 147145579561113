import { Tooltip as MuiTooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(45, 45, 45, 0.9)',
    fontSize: '0.8rem',
    lineHeight: 1.4,
  },
}));

export const Tooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <StyledTooltip {...props}>
      <div>{children}</div>
    </StyledTooltip>
  );
};
