import { Button, styled, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { Typography } from '../../../components/Typography/Typography';
import { ROUTES } from '../../../constants/routes';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';

const MainSection = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const ControllsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const RequestsHeader = () => {
  const navigate = useNavigate();

  const { colors } = useTheme();

  return (
    <MainSection>
      <TitleWrapper>
        <Typography variant='h3' color={colors.primary[90]}>
          Requests
        </Typography>
        <Typography variant='body' color={colors.primary[70]}>
          Requests to respond to ESG questionnaires ask companies to disclose sustainability
          information.
        </Typography>
      </TitleWrapper>

      <ControllsWrapper>
        <Button
          startIcon={<AddIcon />}
          variant='contained'
          style={{ width: '179px' }}
          onClick={() => navigate(`/${ROUTES.CREATE_REQUEST}/1`)}
        >
          Create Request
        </Button>
      </ControllsWrapper>
    </MainSection>
  );
};
