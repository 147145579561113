import { useAtom } from 'jotai';
import { FC, useCallback } from 'react';
import { questionState } from '../../../../state/UIState';
import { SurveyQuestion } from '../../../../types';
import { InstructionsControls } from './components/InstructionControls';

interface Props {
  question: SurveyQuestion;
}

export const QuestionInstructionsControls: FC<Props> = ({ question }) => {
  const [, setQuestion] = useAtom(questionState(question.id));

  const toggleInstructions = useCallback(() => {
    const updatedQuestion = {
      ...question,
      includeInstructions: !question.includeInstructions,
    };
    setQuestion(updatedQuestion);
  }, [question, setQuestion]);

  const onInstructionsChange = useCallback(
    (value: string) => {
      const updatedQuestion = {
        ...question,
        instructions: value,
      };
      setQuestion(updatedQuestion);
    },
    [question, setQuestion]
  );

  return (
    <InstructionsControls
      question={question}
      onInstructionsChange={onInstructionsChange}
      toggleInstructions={toggleInstructions}
    />
  );
};
