import { useMemo } from 'react';
import { COMPANY_INFO_STEP, STEP_TYPE, WorkflowStep } from '../../../../../../types';

export const useIsApprovalDisabled = (step: WorkflowStep) => {
  return useMemo(
    () =>
      Boolean(step.requestStep) ||
      step.companyInfoStep === COMPANY_INFO_STEP.COMPANY_INFORMATION ||
      step.companyInfoStep === COMPANY_INFO_STEP.ESG_CONTACT ||
      step.type === STEP_TYPE.SCORE,
    [step.companyInfoStep, step.requestStep, step.type]
  );
};
