import { styled } from '@mui/material';
import { SideSection } from './SideSection';
import { ChecklistContent } from './ChecklistContent';
import { useSortedChecklistCategories } from '../hooks/useSortedChecklistCategories';
import { CoreKpisSkeletonLoader } from '../../../components/SkeletonLoader/CoreKpis.SkeletonLoader';

const Wrapper = styled('div')`
  display: flex;
`;

export const MainContent = () => {
  const { isLoading } = useSortedChecklistCategories();

  if (isLoading) {
    return <CoreKpisSkeletonLoader />;
  }
  return (
    <Wrapper>
      <SideSection />
      <ChecklistContent />
    </Wrapper>
  );
};
