import { styled, TextField } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { UserSchema as validationSchema } from '../schemas/UserSchema';
import { FormField } from '../../../components/FormField/FormField';
import { User, UserPayload } from '../../../types';
import { Modal } from '../../../components/Modal/Modal';
import { useUserRoles } from '../../../queries/useUsers';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';

interface Props {
  onClose: () => void;
  user?: User | null;
  onSubmit: (payload: Partial<UserPayload>) => void;
  title: string;
  confirmBtnText: string;
}

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CreateEditUsersModal: FC<Props> = ({
  onClose,
  user,
  onSubmit,
  title,
  confirmBtnText,
}) => {
  const { data: roles } = useUserRoles();

  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    dirty,
    setTouched,
  } = useFormik<Partial<UserPayload>>({
    initialValues: {
      fullName: user?.fullName ?? '',
      email: user?.email ?? '',
      roleId: user?.role?.id ?? undefined,
    },
    validationSchema,
    onSubmit: (values) => {
      onClose();
      onSubmit(values);
    },
  });

  const handleError = (field: keyof UserPayload) => (touched[field] ? errors[field] : '');

  const roleOptions = useMemo(() => {
    return roles?.map((role) => ({ id: role.id, value: role.name })) ?? [];
  }, [roles]);

  const selectedRole = useMemo(
    () => roleOptions.find((o) => o.id === values.roleId),
    [values.roleId, roleOptions]
  );

  const onChangeRole = useCallback(
    (option: any) => {
      if (!option) {
        setFieldValue('roleId', '');
        return;
      }
      setFieldValue('roleId', option?.id);
    },
    [setFieldValue]
  );

  return (
    <Modal
      isOpen={true}
      title={title}
      onClose={onClose}
      onConfirm={handleSubmit}
      cancelBtnText='Cancel'
      confirmBtnText={confirmBtnText}
      isConfirmDisabled={!isValid || !dirty}
      contentContainerStyle={{ paddingBottom: '16px' }}
    >
      <FormWrapper>
        <FormField label={'Full Name'} error={handleError('fullName')} required>
          <TextField
            name={'fullName'}
            value={values.fullName}
            onChange={handleChange}
            onBlur={handleBlur}
            variant='outlined'
          />
        </FormField>
        <FormField label={'Email'} error={handleError('email')} required>
          <TextField
            name={'email'}
            value={values.email ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!!user}
            variant='outlined'
          />
        </FormField>
        <FormField label={'Role'} error={handleError('roleId')} required>
          <SingleSelect
            options={roleOptions}
            value={selectedRole}
            onChange={(_, option) => onChangeRole(option)}
            onBlur={() => setTouched({ ...touched, roleId: true })}
          />
        </FormField>
      </FormWrapper>
    </Modal>
  );
};
