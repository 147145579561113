import { useEffect } from 'react';
import { useExternalInvestors } from '../../../queries/useInvestors';
import { useExternallySaveExclusions } from '../../../queries/useExternallySaveExclusions';
import { useCompanyById } from '../../../queries/useCompanies';
import { useParams } from 'react-router';
import {
  ConditionModel,
  ExcludedInvestorFunds,
  ExternallyExclusionsSavePayload,
} from '../../../types';
import { useCompanyExclusions } from './useCompanyExclusions';
import { useIsInScreeningFlow } from './useIsInScreeningFlow';

export const useSaveExternallyExclusions = () => {
  const params = useParams();
  const isInScreeningFlow = useIsInScreeningFlow();
  const { data: investors } = useExternalInvestors();
  const { mutate: saveExternallyExclusions } = useExternallySaveExclusions(Number(params.id));
  const { data: company } = useCompanyById(Number(params.id));

  const { data: exclusions } = useCompanyExclusions();

  useEffect(() => {
    if (isInScreeningFlow || !company || !exclusions || !investors) return;
    if (company.areExclusionsExternallySaved || !company?.externalId) return;

    const conditionModels: ConditionModel[] = exclusions?.map((exclusion) => {
      const investor = investors?.find((inv) => String(inv?.id) === exclusion?.investor);
      return {
        conditionId: String(exclusion?.id),
        conditionName: exclusion?.condition,
        excludedInvestorFunds: exclusion?.funds
          .map((fund) => {
            return {
              investorName: exclusion.investorName || '',
              investorId: investor?.investorCloakedId || '',
              fundName: fund.name || '',
              fundId: String(fund?.id || ''),
            };
          })
          ?.filter((fund) => Boolean(fund)) as ExcludedInvestorFunds[],
      };
    });

    const payload: ExternallyExclusionsSavePayload = {
      issuerId: String(company?.externalId),
      issuerName: String(company.name),
      conditionModels: conditionModels,
    };

    saveExternallyExclusions(payload);
  }, [company, exclusions, investors, isInScreeningFlow, saveExternallyExclusions]);
};
