import { useSortedCoreKpiCategories } from '../hooks/useSortedCoreKpiCategories';
import { useAtom } from 'jotai';
import {
  activeKpiCategoryState,
  isConfirmBarActiveState,
  isMoveModeActiveState,
  kpisTableRowSelectionState,
  selectedCoreKpiCategoryState,
} from '../../../state/UIState';
import {
  DataCollectionSideSection,
  SideSectionList,
} from '../../../components/DataCollectionLayout';
import { useCallback } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { CategoryItem, CoreKpiCategory } from '../../../types';

export const SideSection = () => {
  const { onAddCategory, items, onReorder, onRemoveCategory, onRenameCategory } =
    useSortedCoreKpiCategories();
  const [isConfirmMoveBarActive] = useAtom(isConfirmBarActiveState);
  const [isMoveModeActive] = useAtom(isMoveModeActiveState);
  const [activeCategory, setActiveCategory] = useAtom(activeKpiCategoryState);
  const [selectedKpiCategory, setSelectedKpiCategory] = useAtom(selectedCoreKpiCategoryState);
  const [, setRowSelection] = useAtom(kpisTableRowSelectionState);

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (items && over?.id && active.id !== over.id) {
        const oldIndex = items.findIndex((s) => s.id === active.id);
        const newIndex = items.findIndex((s) => s.id === over.id);
        const reorderedCategories = arrayMove(items, oldIndex, newIndex);
        onReorder(reorderedCategories);
      }
    },
    [items, onReorder]
  );

  function handleClick(item: CategoryItem) {
    if (isMoveModeActive && item.id === activeCategory?.id) {
      return;
    }
    if (isMoveModeActive) {
      setSelectedKpiCategory(item as CoreKpiCategory);
      return;
    }
    setActiveCategory(item as CoreKpiCategory);
    setRowSelection({});
  }

  return (
    <DataCollectionSideSection
      title='KPI Categories'
      isActive={isConfirmMoveBarActive}
      onAddCategory={onAddCategory}
    >
      <SideSectionList
        items={items}
        handleReorder={handleReorder}
        handleClick={handleClick}
        activeCategory={activeCategory}
        onRenameCategory={onRenameCategory}
        onRemoveCategory={onRemoveCategory}
        selectedCategory={selectedKpiCategory}
        isMoveModeActive={isMoveModeActive}
        deleteModalTitle='Delete the KPI category?'
        deleteModalNote='All KPIs inside will be lost.'
      />
    </DataCollectionSideSection>
  );
};
