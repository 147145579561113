import { ColorPalette } from './structure';

export const colors: ColorPalette = {
  accent: {
    90: '#001350',
    80: '#03207C',
    70: '#003599',
    60: '#0E4BBF',
    50: '#0F62FE',
    40: '#4F8CFF',
    30: '#82ADFF',
    20: '#B5CEFD',
    10: '#DBE7FF',
    5: '#EBF2FF',
    1: '#F5F8FF',
  },
  primary: {
    90: '#161717',
    80: '#1E2326',
    70: '#4E5459',
    60: '#797F86',
    50: '#9CA2A7',
    40: '#C3CAD0',
    30: '#D8DEE3',
    20: '#EBF1F4',
    10: '#F3F7F9',
    5: '#F9FBFC',
    0: '#FFFFFF',
  },
  success: {
    50: '#037330',
    40: '#08BD50',
    30: '#A3E0BC',
    20: '#C1EDD3',
    10: '#D7FAE5',
    5: '#EDFCF3',
  },
  error: {
    50: '#AD1212',
    40: '#FF4F4F',
    30: '#FF8282',
    20: '#F6B6B6',
    10: '#FFDBDB',
    5: '#FFF3F3',
  },
  warning: {
    50: '#705907',
    40: '#FFC90C',
    30: '#E6CA68',
    20: '#EFDB92',
    10: '#FFF7DB',
    5: '#FFFBEE',
  },
  gray: {
    50: '#D8D8D8',
    40: '#EAEAEA',
    30: '#F1F1F1',
    20: '#F5F6F6',
    10: '#F8F8F9',
  },
};

export const esgScoreHeatmapColors = {
  laggard: colors.error[20],
  underperf: '#FACEA5',
  avgPerf: colors.warning[20],
  outperf: '#C2E0A3',
  leader: '#A3E0BC',
};

export const esgRiskLevelColors = {
  low: colors.success[20],
  medium: '#FDEDB4',
  high: colors.error[10],
};
