import { styled } from '@mui/material';
import { CSSProperties, Dispatch, SetStateAction, useState } from 'react';
import { PreviewNote } from './PreviewNote';
import { EditNote } from './EditNote';

const Container = styled('div')<{ isEditMode?: boolean }>`
  width: ${({ isEditMode }) => (isEditMode ? '420px' : '300px')};
  padding: 10px 16px;
  background-color: #fff;
  border: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  border-radius: 4px;
  min-height: 80px;
`;

interface Props {
  notes: string;
  setIsDeleteModalOpen?: Dispatch<SetStateAction<boolean>>;
  withActionButtons?: boolean;
  onNotesChange?: (notes: string) => void;
  containerStyles?: CSSProperties;
  isDisabled?: boolean;
}

export const NoteBox = ({
  notes,
  withActionButtons,
  onNotesChange,
  containerStyles,
  setIsDeleteModalOpen = () => '',
  isDisabled,
}: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);

  if (!notes) return null;

  return (
    <Container style={containerStyles} isEditMode={isEditMode}>
      {isEditMode ? (
        <EditNote notes={notes} onNotesChange={onNotesChange} setIsEditMode={setIsEditMode} />
      ) : (
        <PreviewNote
          notes={notes}
          withActionButtons={withActionButtons}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setIsEditMode={setIsEditMode}
          isDisabled={isDisabled}
        />
      )}
    </Container>
  );
};
