import { ROUTES } from '../../../constants/routes';
import { TMenuItem, USER_ROLE } from '../../../types';
import { ReactComponent as IssuersIcon } from '../../../assets/icons/sidebarMenuIcons/issuers.svg';
import { ReactComponent as IssuersActiveIcon } from '../../../assets/icons/sidebarMenuIcons/issuers-active.svg';
import { useIssuersPendingEvaluation } from '../../../hooks/useIssuersPendingEvaluation';
import { ReactComponent as SetupIcon } from '../../../assets/icons/sidebarMenuIcons/setup.svg';
import { ReactComponent as SetupActiveIcon } from '../../../assets/icons/sidebarMenuIcons/setup-active.svg';
import { useMemo } from 'react';

export const useAnalystMenuItems = () => {
  const { companiesData } = useIssuersPendingEvaluation();

  const analystMenuItems: TMenuItem[] = useMemo(
    () => [
      {
        title: 'Issuers',
        icon: <IssuersIcon />,
        iconActive: <IssuersActiveIcon />,
        id: 'issuers-setup',
        showForRoles: [USER_ROLE.ANALYST],
        hasTopBorder: true,
        subItems: [
          {
            title: 'Evaluated Issuers',
            route: ROUTES.ISSUERS,
            id: ROUTES.ISSUERS,
            showForRoles: [USER_ROLE.ANALYST],
          },
          {
            title: 'Pending Evaluation',
            route: ROUTES.PENDING_EVALUATION,
            id: ROUTES.PENDING_EVALUATION,
            showForRoles: [USER_ROLE.ANALYST],
            badgeNum: companiesData?.length || 0,
          },
        ],
      },
      {
        title: 'Setup',
        icon: <SetupIcon />,
        iconActive: <SetupActiveIcon />,
        id: 'setup',
        showForRoles: [USER_ROLE.ANALYST],
        hasTopBorder: true,
        subItems: [
          {
            title: 'Companies',
            route: ROUTES.COMPANIES,
            id: ROUTES.COMPANIES,
            showForRoles: [USER_ROLE.ANALYST],
          },
        ],
      },
    ],
    [companiesData?.length]
  );

  return analystMenuItems;
};
