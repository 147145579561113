import { useParams } from 'react-router';
import { Task } from '../TaskGroup';
import { useCompanyById } from '../../../queries/useCompanies';
import { useState } from 'react';
import { Button, Chip, useTheme } from '@mui/material';
import { ReactComponent as ApprovedIcon } from '../../../assets/icons/request-approved.svg';
import { EditCompanyModal } from '../../EditCompanyModal/EditCompanyModal';

interface Props {
  hasAccess?: boolean;
  isBlocked?: boolean;
  isStepCompleted?: boolean;
}

export const CompanyInformationStep = ({ isBlocked, hasAccess, isStepCompleted }: Props) => {
  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { colors } = useTheme();

  const isFormFilled = !!company?.industry || !!company?.sectors?.length;

  const isDisabled = isBlocked || !hasAccess;
  return (
    <Task
      taskName={`Enter Company Information`}
      isHighlighted={hasAccess && !isStepCompleted}
      onTaskNameClick={isFormFilled ? () => setIsModalOpen(true) : undefined}
      disabled={isDisabled}
    >
      {isFormFilled ? (
        <Chip
          icon={<ApprovedIcon style={{ marginRight: '4px' }} />}
          style={{
            backgroundColor: colors.success[5],
            width: '97px',
          }}
          label='Completed'
        />
      ) : (
        <Button
          onClick={() => setIsModalOpen(true)}
          variant='contained'
          style={{ backgroundColor: isDisabled ? colors.accent[20] : colors.accent[50] }}
          disabled={isDisabled}
        >
          Proceed to Form
        </Button>
      )}
      <EditCompanyModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Task>
  );
};
