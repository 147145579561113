import { IconButton, styled, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { Typography } from '../../../../../components/Typography/Typography';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/add-blue.svg';
import { SortableTree } from './SortableTree/SortableTree';
import { useAtom } from 'jotai';
import {
  activeSectionIdState,
  isApplyingEvaluationRulesModeActiveState,
  sectionsState,
} from '../../../../../state/UIState';
import { SurveySection } from '../../../../../types';
import {
  APPLY_EVALUATION_RULES_HEADER_CONTROLS,
  HEADER_HEIGHT,
} from '../../../../../constants/layoutSizes';
import { generateId } from '../../../../../utils/generateId';

const Wrapper = styled('div')<{ isApplyActive: boolean }>`
  padding: 24px 8px;
  width: 340px;
  height: ${({ isApplyActive }) =>
    `calc(100vh - ${
      isApplyActive ? HEADER_HEIGHT + APPLY_EVALUATION_RULES_HEADER_CONTROLS : HEADER_HEIGHT
    }px)`};
  border-right: 1px solid ${({ theme }) => theme.colors.primary[20]};
  overflow-y: auto;
`;

const SideHeaderWrapper = styled('div')`
  padding: 0 8px 12px 32px;
`;
const SectionControlWrapper = styled('div')`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BuildSideSection = () => {
  const { colors } = useTheme();
  const [, setSections] = useAtom(sectionsState);
  const [, setActiveSectionId] = useAtom(activeSectionIdState);
  const [isApplyingModeActive] = useAtom(isApplyingEvaluationRulesModeActiveState);

  const onAddSection = useCallback(() => {
    const timestampId = generateId();
    setSections((prev) => {
      const newSection: SurveySection = {
        id: timestampId,
        name: `Section ${prev.length + 1}`,
        questions: [],
        subSections: [],
        depth: 0,
        parentId: null,
        collapsed: true,
      };
      setActiveSectionId(newSection.id);
      return [...prev, newSection];
    });
  }, [setActiveSectionId, setSections]);
  return (
    <Wrapper isApplyActive={isApplyingModeActive}>
      <SideHeaderWrapper>
        <SectionControlWrapper>
          <Typography variant='overline' color={colors.primary[60]}>
            SECTIONS
          </Typography>
          <IconButton
            onClick={onAddSection}
            style={{ visibility: isApplyingModeActive ? 'hidden' : 'visible' }}
          >
            <AddIcon />
          </IconButton>
        </SectionControlWrapper>
      </SideHeaderWrapper>
      <SortableTree />
    </Wrapper>
  );
};
