import { useMemo } from 'react';

import { useCompanyById } from '../../../../queries/useCompanies';
import { AssessmentStepData, STEP_TYPE } from '../../../../types';
import { useWorkflowsByCompanyType } from '../../../../queries/useWorkflows';
import { useReportsByCompanyId } from '../../../../queries/useReports';
import { useSASBReport } from '../../../../hooks/useSASBReport';
import {
  DUE_DILIGENCE_SURVEY_NAME,
  INVESTOR_EXCLUSIONS_SURVEY_ID,
  SASB_ASSESSMENT_NAME,
} from '../../../../constants/constants';
import { useSASBSurvey } from '../../../../hooks/useSASBSurvey';

export const useScreeningFlowSurveys = (companyId?: number) => {
  const { data: company } = useCompanyById(companyId);

  const { data: workflow } = useWorkflowsByCompanyType(company?.type);
  const { data: companyReports } = useReportsByCompanyId(companyId);

  const SASBSurvey = useSASBSurvey(companyId);
  const SASBReport = useSASBReport(companyId);

  const allAssessmentsSteps = useMemo(() => {
    return workflow?.config.stages
      ?.flatMap((stage) => stage.steps)
      ?.filter((step) => step.type === STEP_TYPE.ASSESSMENT || step.type === STEP_TYPE.SCORE);
  }, [workflow?.config.stages]);

  const allAssessmentsStepsData = useMemo(() => {
    return (
      (allAssessmentsSteps
        ?.map((step) => {
          if (step?.name === SASB_ASSESSMENT_NAME) {
            if (!SASBSurvey) return null;
            return {
              surveyId: SASBSurvey?.id,
              surveyName: SASBSurvey?.name,
              companyId: company?.id,
              report: SASBReport,
              step: step,
            };
          }

          if (step?.name === DUE_DILIGENCE_SURVEY_NAME) {
            if (!company?.exclusionDueDiligenceSurvey) return null;
            return {
              surveyId: company?.exclusionDueDiligenceSurvey?.id,
              surveyName: company?.exclusionDueDiligenceSurvey?.name,
              companyId: company?.id,
              report: companyReports?.find(
                (report) => report?.survey?.id === company?.exclusionDueDiligenceSurvey?.id
              ),
              step: step,
            };
          }

          if (!step?.survey) return null;

          return {
            surveyId: step?.survey?.id,
            surveyName: step?.survey?.name,
            companyId: company?.id,
            report: companyReports?.find((report) => report?.survey?.id === step?.survey?.id),
            step: step,
          };
        })
        ?.filter((s) => Boolean(s)) as AssessmentStepData[]) || []
    );
  }, [
    SASBReport,
    SASBSurvey,
    allAssessmentsSteps,
    company?.exclusionDueDiligenceSurvey,
    company?.id,
    companyReports,
  ]);

  const nonApprovalAssessmentsStepData = useMemo(
    () =>
      allAssessmentsStepsData?.filter(
        (stepData) =>
          !stepData?.step?.isApprovalRequired &&
          stepData?.surveyId !== INVESTOR_EXCLUSIONS_SURVEY_ID
      ),
    [allAssessmentsStepsData]
  );

  return { allAssessmentsStepsData, nonApprovalAssessmentsStepData, allAssessmentsSteps };
};
