import { useEffect, useMemo, useState } from 'react';
import { Typography } from '../../../../components/Typography/Typography';
import { Table } from '../../../../components/Table/Table';
import { useAtom } from 'jotai';
import {
  createRequestCompletedProgressState,
  requestNameState,
  selectedFrequencyState,
  selectedSurveyState,
} from '../../../../state/UIState';

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getFacetedUniqueValues,
} from '@tanstack/react-table';
import { styled, useTheme } from '@mui/material';
import { CREATE_REQUEST_PARAMS, Survey } from '../../../../types';
import { SearchInput } from '../../../../components/SearchInput/SearchInput';
import { useSurveys } from '../../../../queries/useSurveys';
import { SurveysSkeletonLoader } from '../../../../components/SkeletonLoader/Surveys.SkeletonLoader';
import { useSearchParams } from 'react-router-dom';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { CREATE_REQUEST_FOOTER, HEADER_HEIGHT } from '../../../../constants/layoutSizes';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`;
const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

const CellWrapper = styled('div')`
  display: flex;
  max-width: 350px;
`;

const columnHelper = createColumnHelper<Survey>();

export const SelectableSurveysTable = () => {
  const { colors } = useTheme();
  const [, setProgress] = useAtom(createRequestCompletedProgressState);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { setMultipleParams, params } = useQueryParams();

  const selectedSurveyIdFromParams = params[CREATE_REQUEST_PARAMS.SURVEY_ID]
    ? Number(params[CREATE_REQUEST_PARAMS.SURVEY_ID])
    : null;
  const [selectedSurvey, setSelectedSurvey] = useAtom(selectedSurveyState);
  const [selectedFrequency] = useAtom(selectedFrequencyState);
  const [, setRequestName] = useAtom(requestNameState);

  const [rowSelection, setRowSelection] = useState(() =>
    selectedSurveyIdFromParams ? { [selectedSurveyIdFromParams]: true } : {}
  );
  const [queryParams] = useSearchParams();
  const isInitalStageMode = params[CREATE_REQUEST_PARAMS.MODE] === 'initial';

  const { isLoading, data: surveys } = useSurveys();
  const [data, setData] = useState<Survey[]>(surveys || []);

  useEffect(() => {
    if (!surveys) return;
    setData(surveys);
    const selectedSurveyFromParams = surveys.find((s) => s.id === selectedSurveyIdFromParams);
    setSelectedSurvey(selectedSurveyFromParams || null);
    setRequestName(selectedSurveyFromParams?.name || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveys]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.accent[50]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Survey
          </Typography>
        ),
        meta: {
          width: '30%',
        },
        sortingFn: (rowA: any, rowB: any, columnId: any): number =>
          rowA.getValue(columnId).name > rowB.getValue(columnId).name ? 1 : -1,
        sortDescFirst: false,
      }),
      columnHelper.accessor('description', {
        cell: (info) => (
          <CellWrapper>
            <Typography variant='body' color={colors.primary[70]}>
              {info.getValue()}
            </Typography>
          </CellWrapper>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Description
          </Typography>
        ),
        meta: {
          width: '70%',
        },
      }),
    ],
    [colors.accent, colors.primary]
  );

  useEffect(() => {
    const selectedRows = Object.keys(rowSelection);
    setProgress(() => {
      if (!selectedSurvey?.id) return 0;
      return isInitalStageMode ? 50 : 33;
    });

    if (!selectedRows.length) return;

    const [id] = selectedRows;
    const survey = surveys?.find((survey) => survey.id === Number(id));
    if (!survey) return;
    const requestName = `${survey.name} ${selectedFrequency?.value || ''}`;
    setMultipleParams([
      { param: CREATE_REQUEST_PARAMS.SURVEY_ID, value: String(survey.id) },
      { param: CREATE_REQUEST_PARAMS.REQUEST_NAME, value: requestName },
      { param: CREATE_REQUEST_PARAMS.SURVEY_STAGE, value: survey.stage },
      { param: CREATE_REQUEST_PARAMS.SURVEY_NAME, value: survey.name },
    ]);
    setSelectedSurvey(survey);
    setRequestName(requestName);
  }, [
    isInitalStageMode,
    params,
    queryParams,
    rowSelection,
    selectedFrequency?.value,
    selectedSurvey,
    setMultipleParams,
    setProgress,
    setRequestName,
    setSelectedSurvey,
    surveys,
  ]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (survey) => String(survey.id),
  });

  if (isLoading || !surveys) return <SurveysSkeletonLoader />;

  return (
    <Wrapper>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a survey'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
      </FiltersWrapper>
      <Table
        table={table}
        height={`calc(100vh - ${HEADER_HEIGHT}px - ${CREATE_REQUEST_FOOTER}px - 232px)`}
      />
    </Wrapper>
  );
};
