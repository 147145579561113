import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';
import { Typography } from '../Typography/Typography';
import { ToastMessageType, ToastProps } from '../../types';
import { Button, IconButton, Snackbar } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-gray.svg';
import { theme } from '../../theme';

interface ExtendedToastProps extends ToastProps {
  type: ToastMessageType;
  isOpen: boolean;
  handleClose: () => void;
}

interface ContentProps {
  type: ToastMessageType;
}
const MESSAGE_TYPES = {
  [ToastMessageType.success]: {
    background: theme.colors.success[10],
    borderColor: theme.colors.success[40],
    textColor: theme.colors.success[50],
  },
  [ToastMessageType.warning]: {
    background: theme.colors.warning[10],
    borderColor: theme.colors.warning[40],
    textColor: theme.colors.warning[50],
  },
  [ToastMessageType.error]: {
    background: theme.colors.error[10],
    borderColor: theme.colors.error[40],
    textColor: theme.colors.error[50],
  },
  [ToastMessageType.info]: {
    background: theme.colors.accent[10],
    borderColor: theme.colors.accent[50],
    textColor: theme.colors.accent[60],
  },
};

const Wrapper = styled('div')<ContentProps>`
  width: 480px;
  z-index: 1300;
  border-radius: 2px;
  min-height: 56px;
  background-color: ${({ type }) => MESSAGE_TYPES[type].background};
  border: 1px solid ${({ type }) => MESSAGE_TYPES[type].borderColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px;
`;

const CloseIconWrapper = styled('div')`
  margin-bottom: -2px;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ToastMessage = ({
  type,
  message,
  isOpen,
  handleClose,
  action,
  actionLabel,
  startIcon,
  endIcon,
}: ExtendedToastProps): ReactElement => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{ zIndex: 100005 }}
    >
      <Wrapper type={type}>
        <Typography variant='body' color={MESSAGE_TYPES[type].textColor}>
          {message}
        </Typography>
        <ButtonWrapper>
          {Boolean(action && actionLabel) && (
            <Button
              endIcon={endIcon}
              startIcon={startIcon}
              variant='outlined'
              style={{ height: '32px' }}
              onClick={() => {
                action && action();
                handleClose();
              }}
            >
              {actionLabel}
            </Button>
          )}
          <CloseIconWrapper onClick={handleClose}>
            <IconButton>
              <CloseIcon />
            </IconButton>
          </CloseIconWrapper>
        </ButtonWrapper>
      </Wrapper>
    </Snackbar>
  );
};
