import { WorkflowStep } from '../../../../../types';
import { StepCardSwitch } from './components/StepCardSwitch';

interface Props {
  onToggle: (e: React.ChangeEvent<HTMLInputElement>, shouldExportCSV: boolean) => void;
  step: WorkflowStep;
}

export const ExportExcelSwitch = ({ onToggle, step }: Props) => {
  return (
    <StepCardSwitch
      checked={step.shouldSendExcelReport}
      onChange={onToggle}
      label='Export Excel'
      description='Export assessment as Excel via endpoint'
    />
  );
};
