import { IconButton, styled, useTheme } from '@mui/material';
import { useState } from 'react';
import { Typography } from '../../../../components/Typography/Typography';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';

const Wrapper = styled('div')<{ isSelected?: boolean }>`
  background: ${({ theme, isSelected }) => (isSelected ? theme.colors.gray[20] : 'transparent')};
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ActionIconsWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const IconBtn = styled(IconButton)`
  padding: 4px;
`;

interface Props {
  onOpenEditModal: () => void;
  text: string;
  onOpenDeleteModal: () => void;
}

export const AssignedCellContent = ({ onOpenEditModal, text, onOpenDeleteModal }: Props) => {
  const { colors } = useTheme();
  const [isSelected, setIsSelected] = useState(false);

  const onOpenModal: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onOpenEditModal();
  };

  const onDeleteModalOpen: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onOpenDeleteModal();
  };

  return (
    <Wrapper
      isSelected={isSelected}
      onMouseEnter={() => setIsSelected(true)}
      onMouseLeave={() => setIsSelected(false)}
    >
      <Typography variant='subtitle1' color={colors.primary[90]}>
        {text}
      </Typography>
      {isSelected && (
        <ActionIconsWrapper>
          <IconBtn onClick={onOpenModal}>
            <EditIcon />
          </IconBtn>
          <IconBtn onClick={onDeleteModalOpen}>
            <DeleteIcon />
          </IconBtn>
        </ActionIconsWrapper>
      )}
    </Wrapper>
  );
};
