import { useSurveys } from '../queries/useSurveys';
import { useMemo } from 'react';
import { SURVEY_STAGE } from '../types';

export const useChecklistQuarterlySurvey = () => {
  const { data: surveys } = useSurveys();

  const checklistQuarterlySurvey = useMemo(() => {
    if (!surveys) return null;
    return surveys?.find((survey) => survey.stage === SURVEY_STAGE.CHECKLIST_QUARTERLY) || null;
  }, [surveys]);

  return checklistQuarterlySurvey;
};
