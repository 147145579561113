import { useCallback, useMemo } from 'react';
import {
  CORE_KPI_FORMAT,
  CompanyKpisData,
  CoreKpiCategory,
  CoreKpiRequestFrequency,
  KpisMatrixData,
  KpisPeriodData,
  SURVEY_FREQUENCY,
} from '../../../types';
import { useAtom } from 'jotai';
import { companyKpisDataState } from '../../../state/UIState';
import {
  generateAnnualDataForGivenYear,
  generateMonthlyDataForGivenYear,
  generateQuaterlyDataForGivenYear,
} from '../../../utils/companyUtils';

interface CellPayload {
  newValue: number | string | null;
  kpiId: number;
  period: string;
  format: CORE_KPI_FORMAT;
  activeFrequency: CoreKpiRequestFrequency;
}

export function useKpiMatrix() {
  const [, setCompanyKpisData] = useAtom(companyKpisDataState);

  const getQarterPeriodInputs = useCallback(
    (updatedPeriod: string, monthlyData: KpisPeriodData[]): [KpisPeriodData[], string] => {
      const [month, year] = updatedPeriod.split(' ');
      let filtererMonthlyItems: KpisPeriodData[] = [];
      let quaterToUpdate = '';
      const firstQuarterMonths = ['Jan', 'Feb', 'Mar'];
      const secondQuarterMonths = ['Apr', 'May', 'Jun'];
      const thirdQuarterMonths = ['Jul', 'Aug', 'Sep'];
      const fourthQuarterMonths = ['Oct', 'Nov', 'Dec'];
      if (firstQuarterMonths.includes(month)) {
        quaterToUpdate = `Q1 ${year}`;
        filtererMonthlyItems = monthlyData.filter(
          (item) =>
            firstQuarterMonths.includes(item.period.split(' ')[0]) &&
            item.period.split(' ')[1] === year
        );
      }
      if (secondQuarterMonths.includes(month)) {
        quaterToUpdate = `Q2 ${year}`;
        filtererMonthlyItems = monthlyData.filter(
          (item) =>
            secondQuarterMonths.includes(item.period.split(' ')[0]) &&
            item.period.split(' ')[1] === year
        );
      }
      if (thirdQuarterMonths.includes(month)) {
        quaterToUpdate = `Q3 ${year}`;
        filtererMonthlyItems = monthlyData.filter(
          (item) =>
            thirdQuarterMonths.includes(item.period.split(' ')[0]) &&
            item.period.split(' ')[1] === year
        );
      }
      if (fourthQuarterMonths.includes(month)) {
        quaterToUpdate = `Q4 ${year}`;
        filtererMonthlyItems = monthlyData.filter(
          (item) =>
            fourthQuarterMonths.includes(item.period.split(' ')[0]) &&
            item.period.split(' ')[1] === year
        );
      }
      return [filtererMonthlyItems, quaterToUpdate];
    },
    []
  );

  const getNewPeriodValue = useCallback(
    (filtererPeriodItems: KpisPeriodData[], kpiId: number, format: CORE_KPI_FORMAT) => {
      let newValue: number | null;

      if (format === CORE_KPI_FORMAT.TEXT) {
        return null;
      }

      if (
        filtererPeriodItems.every(
          (item) => !item[kpiId] || (!item[kpiId].value && item[kpiId].value !== 0)
        )
      ) {
        newValue = null;
      } else if (format === CORE_KPI_FORMAT.PERCENTAGE) {
        const avg =
          filtererPeriodItems.reduce(
            (acc, item) => acc + (item[kpiId] ? Number(item[kpiId].value) ?? 0 : 0),
            0
          ) / filtererPeriodItems.filter((item) => item[kpiId] && item[kpiId].value).length;
        newValue = parseFloat(avg.toFixed(2));
      } else {
        newValue = filtererPeriodItems.reduce(
          (acc, item) => acc + (item[kpiId] ? Number(item[kpiId].value) ?? 0 : 0),
          0
        );
      }
      return newValue;
    },
    []
  );

  const recalculateQuarterlyDataWhenKpiChange = useCallback(
    (
      monthlyData: KpisPeriodData[],
      quaterlyData: KpisPeriodData[],
      updatedPeriod: string,
      format: CORE_KPI_FORMAT,
      kpiId: number
    ): [KpisPeriodData[], string] => {
      const [filtererMonthlyItems, quaterToUpdate] = getQarterPeriodInputs(
        updatedPeriod,
        monthlyData
      );
      const newQuarterValue = getNewPeriodValue(filtererMonthlyItems, kpiId, format);
      const updatedQuaterlyData = quaterlyData.map((item) => {
        if (item.period === quaterToUpdate) {
          return { ...item, [kpiId]: { value: newQuarterValue, format } };
        }
        return item;
      });
      return [updatedQuaterlyData, quaterToUpdate];
    },
    [getNewPeriodValue, getQarterPeriodInputs]
  );

  const recalculateQuarterly = useCallback(
    (
      monthlyData: KpisPeriodData[],
      quaterlyData: KpisPeriodData[],
      updatedPeriod: string
    ): [KpisPeriodData[], string] => {
      const [filtererMonthlyItems, quaterToUpdate] = getQarterPeriodInputs(
        updatedPeriod,
        monthlyData
      );
      let updatedQuater: Partial<KpisPeriodData>;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { period, timestamp, ...rest } = monthlyData.find(
        (item) => item.period === updatedPeriod
      ) as KpisPeriodData;
      const kpiIds = Object.keys(rest);
      kpiIds?.forEach((kpiIdKey) => {
        const kpiId = parseInt(kpiIdKey);
        const newQuarterValue = getNewPeriodValue(filtererMonthlyItems, kpiId, rest[kpiId].format);
        updatedQuater = {
          ...updatedQuater,
          [kpiId]: { value: newQuarterValue, format: rest[kpiId].format },
        };
      });

      const updatedQuaterlyData = quaterlyData.map((item) => {
        if (item.period === quaterToUpdate) {
          const { period, timestamp } = item;
          return { period, timestamp, ...updatedQuater };
        }
        return item;
      });
      return [updatedQuaterlyData, quaterToUpdate];
    },
    [getNewPeriodValue, getQarterPeriodInputs]
  );

  const recalculateAnnualDataWhenKpiChange = useCallback(
    (
      quarterlyData: KpisPeriodData[],
      annualData: KpisPeriodData[],
      updatedPeriod: string,
      format: CORE_KPI_FORMAT,
      kpiId: number
    ): KpisPeriodData[] => {
      const [, yearToUpdate] = updatedPeriod.split(' ');
      const filtererQuarterItems: KpisPeriodData[] = quarterlyData.filter(
        (item) => item.period.split(' ')[1] === yearToUpdate
      );

      const newAnnualValue = getNewPeriodValue(filtererQuarterItems, kpiId, format);
      const updatedAnnualData = annualData.map((item) => {
        if (item.period === yearToUpdate) {
          return { ...item, [kpiId]: { value: newAnnualValue, format } };
        }
        return item;
      });
      return updatedAnnualData;
    },
    [getNewPeriodValue]
  );

  const recalculateAnnualy = useCallback(
    (
      quarterlyData: KpisPeriodData[],
      annualData: KpisPeriodData[],
      updatedPeriod: string
    ): KpisPeriodData[] => {
      const [, yearToUpdate] = updatedPeriod.split(' ');
      const filteredQuarterItems: KpisPeriodData[] = quarterlyData.filter(
        (item) => item.period.split(' ')[1] === yearToUpdate
      );

      let updatedAnnualData: Partial<KpisPeriodData>;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { period, timestamp, ...rest } = filteredQuarterItems.find(
        (item) => item.period === updatedPeriod
      ) as KpisPeriodData;
      const kpiIds = Object.keys(rest);
      kpiIds?.forEach((kpiIdKey) => {
        const kpiId = parseInt(kpiIdKey);
        const newAnnualValue = getNewPeriodValue(filteredQuarterItems, kpiId, rest[kpiId].format);
        updatedAnnualData = {
          ...updatedAnnualData,
          [kpiId]: { value: newAnnualValue, format: rest[kpiId].format },
        };
      });

      const updatedAnnuallyData = annualData.map((item) => {
        if (item.period === yearToUpdate) {
          const { period, timestamp } = item;
          return { period, timestamp, ...updatedAnnualData };
        }
        return item;
      });
      return updatedAnnuallyData;
    },
    [getNewPeriodValue]
  );

  const onUpdateMatrixCell = useCallback(
    async (payload: CellPayload) => {
      const { newValue, kpiId, period, activeFrequency, format } = payload;
      setCompanyKpisData((kpisData) => {
        if (!kpisData) {
          return kpisData;
        }
        const periodDataToUpdate = kpisData[activeFrequency];
        const updatedPeriodData = periodDataToUpdate.map((item) => {
          if (item.period === period) {
            return { ...item, [kpiId]: { value: newValue, format } };
          }
          return item;
        });
        const updatedKpisData = { ...kpisData, [activeFrequency]: updatedPeriodData };
        if (activeFrequency === SURVEY_FREQUENCY.MONTHLY) {
          const [updatedQuaterlyData, updatedQuarterPeriod] = recalculateQuarterlyDataWhenKpiChange(
            updatedPeriodData,
            updatedKpisData[SURVEY_FREQUENCY.QUARTERLY],
            period,
            format,
            kpiId
          );
          updatedKpisData[SURVEY_FREQUENCY.QUARTERLY] = updatedQuaterlyData;
          updatedKpisData[SURVEY_FREQUENCY.ANNUALLY] = recalculateAnnualDataWhenKpiChange(
            updatedQuaterlyData,
            updatedKpisData[SURVEY_FREQUENCY.ANNUALLY],
            updatedQuarterPeriod,
            format,
            kpiId
          );
        }
        if (activeFrequency === SURVEY_FREQUENCY.QUARTERLY) {
          updatedKpisData[SURVEY_FREQUENCY.ANNUALLY] = recalculateAnnualDataWhenKpiChange(
            updatedPeriodData,
            updatedKpisData[SURVEY_FREQUENCY.ANNUALLY],
            period,
            format,
            kpiId
          );
        }

        return updatedKpisData;
      });
    },
    [recalculateAnnualDataWhenKpiChange, recalculateQuarterlyDataWhenKpiChange, setCompanyKpisData]
  );

  const onTimeframeChange = useCallback(
    (newStartYear: number) => {
      setCompanyKpisData((kpisData) => {
        if (!kpisData) return kpisData;
        const currentStartYear = parseInt(kpisData[SURVEY_FREQUENCY.ANNUALLY][0].period);
        let updatedKpisData: CompanyKpisData = { ...kpisData };
        if (newStartYear < currentStartYear) {
          const missinigYears = Array.from(
            { length: currentStartYear - newStartYear },
            (_, i) => currentStartYear - i - 1
          ).reverse();
          const missingMonthlyData = missinigYears.flatMap((year) =>
            generateMonthlyDataForGivenYear(year)
          );
          const missingQuaterlyData = missinigYears.flatMap((year) =>
            generateQuaterlyDataForGivenYear(year)
          );
          const missingAnnualData = missinigYears.map((year) =>
            generateAnnualDataForGivenYear(year)
          );
          const updatedMonthlyData = [...missingMonthlyData, ...kpisData[SURVEY_FREQUENCY.MONTHLY]];
          const updatedQuaterlyData = [
            ...missingQuaterlyData,
            ...kpisData[SURVEY_FREQUENCY.QUARTERLY],
          ];
          const updatedAnnualData = [...missingAnnualData, ...kpisData[SURVEY_FREQUENCY.ANNUALLY]];
          updatedKpisData = {
            [SURVEY_FREQUENCY.MONTHLY]: updatedMonthlyData,
            [SURVEY_FREQUENCY.QUARTERLY]: updatedQuaterlyData,
            [SURVEY_FREQUENCY.ANNUALLY]: updatedAnnualData,
          };
        } else if (newStartYear > currentStartYear) {
          //trim sufficiant years
          const updatedMonthlyData = kpisData[SURVEY_FREQUENCY.MONTHLY].filter((item) => {
            const [, year] = item.period.split(' ');
            return parseInt(year) >= newStartYear;
          });
          const updatedQuaterlyData = kpisData[SURVEY_FREQUENCY.QUARTERLY].filter((item) => {
            const [, year] = item.period.split(' ');
            return parseInt(year) >= newStartYear;
          });
          const updatedAnnualData = kpisData[SURVEY_FREQUENCY.ANNUALLY].filter(
            ({ period: year }) => parseInt(year) >= newStartYear
          );
          updatedKpisData = {
            [SURVEY_FREQUENCY.MONTHLY]: updatedMonthlyData,
            [SURVEY_FREQUENCY.QUARTERLY]: updatedQuaterlyData,
            [SURVEY_FREQUENCY.ANNUALLY]: updatedAnnualData,
          };
        }
        // const updatedKpisData = { ...kpisData };

        return updatedKpisData;
      });
    },
    [setCompanyKpisData]
  );

  const getTableData = useCallback(
    (
      frequency: CoreKpiRequestFrequency,
      companyKpisData: CompanyKpisData,
      coreKpiCategories: CoreKpiCategory[],
      coreKpiCategoriesOrder: number[]
    ) => {
      if (!companyKpisData || !coreKpiCategories) return [];
      const result: KpisMatrixData[] = [];
      const sortedCoreKpiCategories = coreKpiCategoriesOrder.map((id) =>
        coreKpiCategories.find((item) => item.id === id)
      );
      sortedCoreKpiCategories?.forEach((category) => {
        category?.kpis?.forEach((kpi) => {
          const singleKpiData: KpisMatrixData = {
            ...kpi,
            categoryName: category.name,
          };
          companyKpisData[frequency as CoreKpiRequestFrequency]?.forEach((periodData) => {
            singleKpiData[periodData.period] = {
              value:
                periodData[kpi.id]?.value || periodData[kpi.id]?.value === 0
                  ? periodData[kpi.id]?.value
                  : null,
              notes: periodData[kpi.id]?.notes,
            };
          });

          result.push(singleKpiData);
        });
      });
      return result;
    },
    []
  );

  const value = useMemo(() => {
    return {
      onUpdateMatrixCell,
      onTimeframeChange,
      recalculateQuarterly,
      recalculateAnnualy,
      getTableData,
    };
  }, [
    onUpdateMatrixCell,
    onTimeframeChange,
    recalculateQuarterly,
    recalculateAnnualy,
    getTableData,
  ]);

  return value;
}
