import { numericFormatter } from 'react-number-format';
import { SURVEY_FREQUENCY } from '../types';

export const formatterDate = new Intl.DateTimeFormat(navigator.language, {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

export function formatDate(dateAsISOString: string) {
  if (!dateAsISOString) {
    return '';
  }
  return formatterDate.format(new Date(dateAsISOString));
}

const formatterNumberShort = new Intl.NumberFormat(navigator.language, {
  compactDisplay: 'short',
  currency: 'USD',
  notation: 'compact',
});
export function formatNumberShort(value: number) {
  return formatterNumberShort.format(value);
}

const formatterUSDShort = new Intl.NumberFormat(navigator.language, {
  compactDisplay: 'short',
  currency: 'USD',
  notation: 'compact',
  style: 'currency',
});
export function formatUSDShort(value: number) {
  return formatterUSDShort.format(value);
}

export const getMonthLabel = (month: number) => {
  switch (month) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return '';
  }
};

export const transformToNumber = (formattedValue: string) => {
  const cleanValue = formattedValue.replace(/,/g, '');
  return parseFloat(cleanValue);
};

export const formatNumber = (value: string | number, options?: { suffix?: '%' }) => {
  if (!value && value !== 0) {
    return '';
  }

  return numericFormatter(String(value), {
    allowNegative: false,
    thousandSeparator: ',',
    thousandsGroupStyle: 'thousand',
    suffix: options?.suffix,
    decimalScale: 2,
    fixedDecimalScale: true,
  });
};

export function getLastDateOfCurrentPeriod(period: SURVEY_FREQUENCY, date: string) {
  const dateObj = new Date(date);
  let lastDate;

  switch (period) {
    case 'monthly':
      lastDate = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0, 23, 59, 59);
      break;
    case 'quarterly':
      lastDate = new Date(
        dateObj.getFullYear(),
        Math.floor(dateObj.getMonth() / 3) * 3 + 3,
        0,
        23,
        59,
        59
      );
      break;
    case 'annually':
      lastDate = new Date(dateObj.getFullYear() + 1, 0, 0, 23, 59, 59);
      break;
    case 'one_time':
      return null;
      break;
    default:
      throw new Error('Invalid period');
  }
  return lastDate.toISOString();
}
