import { useMutation } from 'react-query';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';

async function generateExcelReport(reportId: number) {
  await Http.axios.post(`/report/generate-excel-report/${reportId} `);
}

export function useGenerateExcelReport() {
  const { pushErrorToast } = useToastMessage();

  return useMutation(generateExcelReport, {
    onError: () => {
      pushErrorToast({ message: 'Failed to generate excel report' });
    },
  });
}
