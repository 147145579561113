import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import { LoadingId, Role, User, UserPayload } from '../types';
import { AxiosError } from 'axios';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { SERVER_ERRORS } from '../constants/serverErrors';

export const USERS = 'users';
export const ROLES = 'roles';

export async function fetchUserByEmail(email: string): Promise<User | null> {
  try {
    const response = await Http.axios.get<User>(`user/email/${email}`);
    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 404) return null;
    throw error;
  }
}

export function useUserById(email: string) {
  return useQuery(`${USERS}-${email}`, () => fetchUserByEmail(email), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function createUser(payload: Partial<UserPayload>): Promise<User> {
  const { data } = await Http.axios.post<Partial<UserPayload>, User>(`/user`, payload);
  return data;
}

export function useCreateUser() {
  return useMutation(createUser, {
    onError: (error) => {
      console.error({ error });
    },
  });
}

async function fetchUsers(): Promise<User[]> {
  const { data } = await Http.axios.get<User[]>(`/user`);
  data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  return data;
}

export function useUsers() {
  return useQuery(USERS, () => fetchUsers(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

export async function fetchUserRoles(): Promise<Role[]> {
  const { data } = await Http.axios.get<Role[]>(`/role`);
  data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  return data;
}

export function useUserRoles() {
  return useQuery(ROLES, () => fetchUserRoles(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function deleteUser(id: number): Promise<number | { message: string }> {
  const { data } = await Http.axios.delete<number | { message: string }>(`/user/${id}`);
  return data;
}

export function useDeleteUser() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast, pushSuccessToast } = useToastMessage();

  return useMutation(deleteUser, {
    onMutate: async () => {
      startLoading(LoadingId.deleteUser);
    },
    onError: () => {
      pushErrorToast({ message: 'Failed to delete user' });
    },
    onSuccess: (response) => {
      if ((response as { message: string })?.message === SERVER_ERRORS.THERE_MUST_BE_ONE_ADMIN) {
        pushErrorToast({ message: SERVER_ERRORS.THERE_MUST_BE_ONE_ADMIN });
        return;
      }
      pushSuccessToast({ message: 'User deleted successfully' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(USERS);
      stopLoading(LoadingId.deleteUser);
    },
  });
}

async function updateUser(user: Partial<UserPayload>): Promise<User | { message: string }> {
  const { id, ...payload } = user;
  const { data } = await Http.axios.patch<Partial<UserPayload>, User>(`/user/${id}`, payload);
  return data;
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast, pushSuccessToast } = useToastMessage();

  return useMutation(updateUser, {
    onMutate: async () => {
      startLoading(LoadingId.updateUser);
    },
    onError: () => {
      pushErrorToast({ message: 'Failed to update user' });
    },
    onSuccess: (response) => {
      if ((response as { message: string })?.message === SERVER_ERRORS.THERE_MUST_BE_ONE_ADMIN) {
        pushErrorToast({ message: SERVER_ERRORS.THERE_MUST_BE_ONE_ADMIN });
        return;
      }
      pushSuccessToast({ message: 'User updated successfully' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(USERS);
      stopLoading(LoadingId.updateUser);
    },
  });
}
