import { styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-blue.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { useMemo } from 'react';

const InfoWrapper = styled('div')<{ isInfoContentWarning?: boolean }>`
  padding: 12px;
  background-color: ${({ theme, isInfoContentWarning }) =>
    isInfoContentWarning ? theme.colors.warning[5] : theme.colors.accent[10]};
  border-radius: 2px;
  display: flex;
  gap: 8px;
  margin-bottom: 14px;
  border: ${({ theme, isInfoContentWarning }) =>
    isInfoContentWarning ? `1px solid ${theme.colors.warning[40]}` : 'none'};
`;

const InfoMessageWrapper = styled('div')``;

interface Props {
  infoText?: string;
  infoContent?: React.ReactNode;
  isInfoContentWarning?: boolean;
}

export const InfoContent = ({ infoText, infoContent, isInfoContentWarning }: Props) => {
  const { colors } = useTheme();

  const Icon = useMemo(() => {
    if (isInfoContentWarning) return WarningIcon;

    return InfoIcon;
  }, [isInfoContentWarning]);

  if (!infoText && !infoContent) return null;

  if (infoContent) {
    return (
      <InfoWrapper isInfoContentWarning={isInfoContentWarning}>
        <Icon />
        <InfoMessageWrapper>{infoContent}</InfoMessageWrapper>
      </InfoWrapper>
    );
  }

  return (
    <InfoWrapper isInfoContentWarning={isInfoContentWarning}>
      <Icon />
      <InfoMessageWrapper>
        <Typography
          variant='body'
          color={isInfoContentWarning ? colors.warning[50] : colors.accent[60]}
        >
          {infoText}
        </Typography>
      </InfoMessageWrapper>
    </InfoWrapper>
  );
};
