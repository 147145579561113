import { ButtonBase, Typography, useTheme } from '@mui/material';

interface Props {
  onOpenModal: () => void;
  text: string;
}

export const UnassignedCellContent = ({ onOpenModal, text }: Props) => {
  const { colors } = useTheme();

  const onModalOpen: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onOpenModal();
  };

  return (
    <ButtonBase onClick={onModalOpen}>
      <Typography variant={'subtitle2'} color={colors.accent[50]}>
        {text}
      </Typography>
    </ButtonBase>
  );
};
