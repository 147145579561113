import { Multiselect } from './Multiselect/Multiselect';
import { SelectItem } from '../types';

interface Props {
  sortedUniqueCountries: SelectItem[];
  onChangeCountriesFilter: (countries: SelectItem[]) => void;
  selectedCountries: SelectItem[];
}

export const CountriesFilter = ({
  sortedUniqueCountries,
  onChangeCountriesFilter,
  selectedCountries,
}: Props) => {
  return (
    <Multiselect
      style={{ width: '220px' }}
      options={sortedUniqueCountries}
      value={selectedCountries}
      onChange={(_, val) => {
        onChangeCountriesFilter(val as SelectItem[]);
      }}
      disablePortal
      optionsName='Countries'
      fieldPlaceholder='Filter by Geography'
    />
  );
};
