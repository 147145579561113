import { useCallback, useMemo, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { Table } from '../../../components/Table/Table';
import {
  Request,
  RequestGroup,
  REPORT_STATUS,
  SelectItem,
  REQUEST_GROUP_TYPE,
} from '../../../types';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getFacetedUniqueValues,
  Row,
  CellContext,
} from '@tanstack/react-table';
import { styled, useTheme } from '@mui/material';
import { CompaniesCell } from '../../../components/Table/CellRenderers/CompaniesCell';
import { formatDate } from '../../../utils/formatters';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';
import { useNavigate } from 'react-router';
import { useDeleteRequestGroup, useRequestGroups } from '../../../queries/useRequestGroups';
import { RequestGroupsSkeletonLoader } from '../../../components/SkeletonLoader/RequestGroups.SkeletonLoader';
import { EditRequestGroupModal } from './EditRequestGroupModal';
import { ConfirmDeleteModal } from '../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { ROUTES } from '../../../constants/routes';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`;

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

interface RequestGroupsTableData extends RequestGroup {
  name: string;
}

const columnHelper = createColumnHelper<RequestGroupsTableData>();

export const RequestGroupsTable = () => {
  const { colors } = useTheme();
  const [sorting, setSorting] = useState<SortingState>([]);
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<SelectItem | null>(null);
  const deleteRequestGroup = useDeleteRequestGroup();

  const [selectedRequestGroup, setSelectedRequestGroup] = useState<RequestGroup | null>(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const handleOpenConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(true), []);
  const handleCloseConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(false), []);

  const handleDeleteRequestGroup = useCallback(() => {
    if (!selectedRequestGroup) return;
    handleCloseConfirmDeleteModal();
    deleteRequestGroup.mutate(selectedRequestGroup.id);
  }, [selectedRequestGroup, handleCloseConfirmDeleteModal, deleteRequestGroup]);

  const onSurveyClick = useCallback((surveyId: number) => {
    window.open(`/${ROUTES.SURVEYS}/${surveyId}/review`);
  }, []);

  const actions = useMemo(() => {
    return [
      {
        id: 'EDIT',
        value: 'Edit',
        icon: <EditIcon />,
        getIsDisabled: (row: Row<RequestGroup> | null) =>
          row?.original.type === REQUEST_GROUP_TYPE.CORE_KPI,
      },
      {
        id: 'DELETE',
        value: 'Delete',
        icon: <DeleteIcon />,
        getIsDisabled: (row: Row<RequestGroup> | null) =>
          row?.original.type === REQUEST_GROUP_TYPE.CORE_KPI,
      },
    ];
  }, []);

  const handleSelectAction = useCallback(
    (actionId: string, row: Row<RequestGroup> | null) => {
      if (!row) return;
      switch (actionId) {
        case 'EDIT':
          handleOpenEditModal();
          setSelectedRequestGroup(row.original);
          return;
        case 'DELETE':
          handleOpenConfirmDeleteModal();
          setSelectedRequestGroup(row.original);
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [handleOpenConfirmDeleteModal]
  );

  const { isLoading, data: requestGroups } = useRequestGroups();

  const data = useMemo(() => {
    if (!requestGroups) return [];
    return requestGroups.map((requestGroup) => ({
      ...requestGroup,
      name: requestGroup.requests[0].name,
    }));
  }, [requestGroups]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.accent[50]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Request Name
          </Typography>
        ),
        filterFn: 'includesString',
        enableColumnFilter: true,
        meta: {
          width: '20%',
          minWidth: '235px',
        },
      }),
      columnHelper.display({
        id: 'responses',
        cell: (props: CellContext<RequestGroupsTableData, unknown>) => {
          const reports = (props.row.getValue('requests') as Request[])
            .map((request) => request.reports)
            .flat();
          return (
            <Typography variant='subtitle2' color={colors.primary[90]}>
              {reports.filter((report) => report.status === REPORT_STATUS.APPROVED).length}/
              {reports.length}
            </Typography>
          );
        },
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Responses
          </Typography>
        ),
        meta: {
          width: '11%',
        },
      }),
      columnHelper.accessor('requests', {
        cell: (info) => (
          <CompaniesCell
            companies={info.getValue().map((r) => ({
              name: r.company.name as string,
              logoUrl: r.company.logoUrl as string,
              id: r.company.id,
            }))}
          />
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Companies
          </Typography>
        ),
        meta: {
          width: '20%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('survey', {
        cell: (info) => (
          <Typography
            variant='body'
            color={colors.accent[50]}
            onClick={(e) => {
              e.stopPropagation();
              onSurveyClick(info.getValue().id);
            }}
          >
            {info.getValue()?.name}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Survey
          </Typography>
        ),
        meta: {
          width: '20%',
          minWidth: '100px',
        },
        filterFn: (row: any, columnId, selectedFilters) => {
          const value: string = row.getValue(columnId).name;
          return selectedFilters.includes(value);
        },
        sortingFn: (rowA: any, rowB: any, columnId: any): number =>
          rowA.getValue(columnId).name > rowB.getValue(columnId).name ? 1 : -1,
        sortDescFirst: false,
        enableColumnFilter: true,
      }),
      columnHelper.accessor('frequency', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()[0].toLocaleUpperCase() + info.getValue().slice(1)}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Frequency
          </Typography>
        ),
        meta: {
          width: '12%',
          minWidth: '100px',
        },
      }),
      columnHelper.accessor('created_at', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {formatDate(info.getValue())}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Created
          </Typography>
        ),
        meta: {
          width: '50%',
          minWidth: '100px',
        },
        sortingFn: 'datetime',
      }),
    ],
    [colors.accent, colors.primary, onSurveyClick]
  );

  const table = useReactTable({
    data: data ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (request) => String(request.id),
  });

  const sortedUniqueSurveys = useMemo(
    () => {
      const allValues = Array.from(
        table.getColumn('survey')?.getFacetedUniqueValues().keys() ?? []
      );
      const map = allValues.reduce((acc, curr) => {
        acc[curr?.name] = curr?.name;
        return acc;
      }, {});

      const unique =
        Object.keys(map)
          .sort()
          .map((value, id) => ({ id, value })) ?? [];
      return unique;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, requestGroups]
  );
  const onChangeSurveyFilter = useCallback(
    (survey: SelectItem | null) => {
      setSelectedSurvey(survey);
      table.getColumn('survey')?.setFilterValue(survey?.value ?? '');
    },
    [table]
  );

  const onRowClick = useCallback(
    (row: Row<RequestGroup>) => {
      const requestGroupId = row.id;
      navigate(`/requests/${requestGroupId}`);
    },
    [navigate]
  );

  if (isLoading || !requestGroups) return <RequestGroupsSkeletonLoader />;

  return (
    <Wrapper>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a request'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
        <SingleSelect
          style={{ width: '200px' }}
          options={sortedUniqueSurveys}
          value={selectedSurvey}
          onChange={(_, survey) => onChangeSurveyFilter(survey)}
          disablePortal
          fieldPlaceholder='Select Survey'
        />
      </FiltersWrapper>
      <Table
        table={table}
        onRowClick={onRowClick}
        actions={actions}
        onActionClick={handleSelectAction}
      />

      {isEditModalOpen && (
        <EditRequestGroupModal onClose={handleCloseEditModal} requestGroup={selectedRequestGroup} />
      )}

      <ConfirmDeleteModal
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleDeleteRequestGroup}
        title='Delete Request Group?'
        note='Deleting this request group will also delete all associated requests and incomplete reports.'
        confirmLabel='Yes, Delete'
        cancelLabel='No, Cancel'
        isOpen={isConfirmDeleteModalOpen}
      />
    </Wrapper>
  );
};
