import { styled, useTheme } from '@mui/material';
import { Multiselect } from '../../../../../components/Multiselect/Multiselect';
import { Typography } from '../../../../../components/Typography/Typography';
import { useUserRoles } from '../../../../../queries/useUsers';
import { useCallback, useMemo, useState } from 'react';
import { Role, SelectItem, USER_ROLE } from '../../../../../types';

const Container = styled('div')``;

interface Props {
  initialRoles?: Role[];
  onRolesSelect: (roles: Role[]) => void;
  label?: string;
}

export const RolesSelect = ({ initialRoles, onRolesSelect, label }: Props) => {
  const { colors } = useTheme();

  const initRoles = useMemo(() => {
    return initialRoles?.map((role) => ({
      id: role.id,
      value: role.name,
    }));
  }, [initialRoles]);

  const [selectedRoles, setSelectedRoles] = useState<SelectItem[]>(initRoles || []);

  const { data: roles } = useUserRoles();

  const options = useMemo(() => {
    if (!roles?.length) return [];
    return roles
      ?.filter((role) => role.name !== USER_ROLE.EXTERNAL_USER)
      ?.map((role) => {
        return {
          id: role?.id,
          value: role?.name,
        };
      });
  }, [roles]);

  const onSelectRoles = useCallback(
    (selectedRoleItems: SelectItem[]) => {
      setSelectedRoles(selectedRoleItems);
      if (!roles?.length) return;

      const selectedRoles = roles?.filter((r) =>
        selectedRoleItems?.map((role) => role.id)?.includes(r.id)
      );

      onRolesSelect(selectedRoles);
    },
    [roles, onRolesSelect]
  );

  return (
    <Container>
      {label && (
        <Typography variant='srOnly' color={colors.primary[90]} style={{ marginBottom: '4px' }}>
          {label}
        </Typography>
      )}
      <Multiselect
        style={{ width: '400px' }}
        options={options}
        value={selectedRoles}
        onChange={(_, val) => {
          onSelectRoles(val as SelectItem[]);
        }}
        disablePortal={false}
        optionsName='selected'
        fieldPlaceholder='Select roles'
      />
    </Container>
  );
};
